import { type ProvideBookableClassSlotsInput } from '@lingoda/graphql';
import { createDate, formatDate } from '@lingoda/dates';

interface GetWidgetClassSlotsParams {
    lessonId: number;
    moduleId: number;
}

export const getWidgetClassSlotsInput = ({
    lessonId,
    moduleId,
}: GetWidgetClassSlotsParams): ProvideBookableClassSlotsInput => {
    return {
        lessonIds: [lessonId],
        moduleIds: [moduleId],
        includeCurrent: false,
        slotsType: 'timeSlotsFiltered',
        exactTimeSlots: [],
        classTypes: ['group'],
        skipFilteringOutGeneratedClasses: true,
        dates: [formatDate(createDate())],
    };
};

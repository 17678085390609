import { Box, Typography, colorsPalette, styled } from '@lingoda/ui';
import BubbleArrowImage from './bubble-arrow.svg?component';
import Icon from './vocabulary-trainer.svg?component';

interface Props {
    text: string;
}

export const BubbleSpeechProgress = ({ text }: Props) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Box marginRight={2} display="flex" justifyContent="center" alignItems="center">
                <Icon />
            </Box>
            <BubbleArrowImage color={colorsPalette.primarySolid[8]} />
            <Bubble>
                <Typography variant="body2">{text}</Typography>
            </Bubble>
        </Box>
    );
};

const Bubble = styled(Box)(({ theme }) => ({
    background: colorsPalette.primarySolid[8],
    color: colorsPalette.blackAlpha[70],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(4),
    maxWidth: 200,
}));

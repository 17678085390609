import { type MouseEvent } from 'react';
import { type Achievement, type ChecklistItem } from '@lingoda/graphql';
import { Box, Paper, Typography } from '@lingoda/ui';
import { AchievementProgress } from './AchievementProgress';

interface Props {
    achievement: Achievement;
    checklist: ReadonlyArray<ChecklistItem>;
    isClaiming: boolean;
    isChecklistLoading?: boolean;
    onClaim: (event: MouseEvent) => Promise<void>;
}

export const Layout = ({ achievement, ...props }: Props) => {
    const { name } = achievement;

    return (
        <Paper sx={{ padding: { xs: 2, sm: 3 } }}>
            <Typography variant="h3">{name}</Typography>
            <Box sx={{ marginTop: 3 }}>
                <AchievementProgress achievement={achievement} {...props} />
            </Box>
        </Paper>
    );
};

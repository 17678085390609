import {
    type ClaimAttendanceAchievementMutation,
    type MutationHookOptions,
    useClaimAttendanceAchievementMutation,
} from '@lingoda/graphql';
import { useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';

interface ClaimRewardOptions {
    onCompleted?: MutationOptions['onCompleted'];
}

export const useClaimReward = ({ onCompleted }: ClaimRewardOptions = {}) => {
    const [claimRewardMutation, { loading }] = useClaimAttendanceAchievementMutation({
        onCompleted,
    });

    const addEvent = useUiEvent();

    const claimReward = async (claimId: string) => {
        const result = await claimRewardMutation({
            variables: {
                claimId,
            },
        });

        void addEvent(actionClick('ClaimReward'));

        return result.data?.claimAttendanceAchievement;
    };

    return [claimReward, loading] as const;
};

type MutationOptions = MutationHookOptions<ClaimAttendanceAchievementMutation>;

import { createSelector } from 'reselect';
import {
    studentExercisesPath,
    studentFlashcardsPath,
    studentGrammarItemPath,
    studentGrammarPath,
    studentHomeworkPath,
    studentPracticePath,
    studentQuizNextPath,
    studentQuizPath,
    studentVocabularyPath,
} from '@lingoda/router';
import { SubscriptionStatus, currentSubscriptionSelector } from '@lingoda/subscriptions';

export const selfLearningRoutes = [
    { path: studentExercisesPath(undefined as never), matchPrefix: true },
    { path: studentFlashcardsPath(undefined as never), matchPrefix: true },
    { path: studentGrammarItemPath(undefined as never), matchPrefix: true },
    { path: studentGrammarPath(undefined as never), matchPrefix: true },
    { path: studentHomeworkPath(undefined as never), matchPrefix: true },
    { path: studentPracticePath(undefined as never), matchPrefix: true },
    { path: studentQuizPath(undefined as never), matchPrefix: true },
    { path: studentVocabularyPath(undefined as never), matchPrefix: true },
    { path: studentQuizNextPath(undefined as never), matchPrefix: true },
];

export const selfLearningAccessSelector = createSelector(
    currentSubscriptionSelector,
    (subscription) => Boolean(subscription && subscription.status !== SubscriptionStatus.Cancelled),
);

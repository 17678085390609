import type { RequestsState } from '@lingoda/core';
import { requests } from '@lingoda/core';
import type { State as DialogState } from '@lingoda/dialogs';
import { reducers as dialogs } from '@lingoda/dialogs';
import type { RouteState } from '@lingoda/router';
import { route } from '@lingoda/router';
import type { SectionsState } from '@lingoda/sections';
import { reducers as sections } from '@lingoda/sections';

export interface GlobalState extends GenericObject<unknown> {
    dialogs: DialogState;
    requests: RequestsState;
    route: RouteState;
    sections: SectionsState;
}

const reducers = {
    dialogs,
    requests,
    route,
    sections,
};

export default reducers;

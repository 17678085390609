import { Avatar, Box } from '@lingoda/ui';

interface AvatarProps {
    src?: string;
    firstName: string;
}

type AvatarWithId = AvatarProps & { id: number | string };

const stackSpace = -5;
interface Props {
    avatars: AvatarWithId[] | undefined;
    teacher: AvatarProps | undefined;
}

export const ClassTileParticipants = ({ avatars, teacher }: Props) => (
    <Box display="flex" alignItems="center">
        {teacher ? (
            <Box mr={0.5}>
                <Avatar size={40} src={teacher.src}>
                    {teacher.firstName}
                </Avatar>
            </Box>
        ) : null}

        {avatars?.map(({ src, id, firstName }, index) => (
            <Box key={id} position="relative" left={stackSpace * index}>
                <Avatar size={28} src={src} borderVariant="stacked">
                    {firstName}
                </Avatar>
            </Box>
        ))}
    </Box>
);

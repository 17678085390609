export interface ConfigWindow extends Window {
    lgConfig: Config;
}

declare const window: ConfigWindow;

export interface Config {
    releaseId: string;
    deployEnvironment: string;
    sentryFrontendDsn: string;
    kinesisCognitoIdentityId: string;
    kinesisEndpoint: string;
    kinesisRegion: string;
    matomoUrl: string;
    stripePublishable: string;
    facebookAppId: string;
    brazeApiKey: string;
    brazeEndpoint: string;
    mercurePublicUrl: string;
    googleTagManagerId: string;
    googleRecaptchaV3SiteKey: string;
    classroomStatisticsApiKey: string;
    classroomStatisticsEndpoint: string;
    domains: {
        en_TEACHER: string;
        STUDENT: string;
        CLASSROOM: string;
    };
}

export const config: Config = window.lgConfig;

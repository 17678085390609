import { makeActionTrackable } from '@lingoda/core';
import type { Date } from '@lingoda/dates';
import type { LessonId } from '@lingoda/lessons';
import type { StudentId } from '@lingoda/students';

interface Payload {
    before?: Date;
    after?: Date;
    lessonId?: LessonId;
    studentId?: StudentId;
    limit?: number;
    clearCache?: boolean;
}

const FETCH_APPOINTMENTS = 'appointment/FETCH_APPOINTMENTS';

const actionCreator = (payload?: Payload) => ({
    type: FETCH_APPOINTMENTS,
    payload,
});
actionCreator.toString = () => FETCH_APPOINTMENTS;

export default makeActionTrackable(actionCreator);

import type { Recurrence } from '@lingoda/dates';
import { createRecordFactory } from '@lingoda/utils';

export type ProductId = number;

export type ProductCredits = Pick<Product, 'individualCredits' | 'groupCredits'>;

export enum ProductCreditType {
    Private = 'Private',
    Group = 'Group',
}

export interface Product {
    readonly id: ProductId;
    readonly individualCredits: number;
    readonly isNonRecurring: boolean;
    readonly groupCredits: number;
    readonly type: string;
    readonly isTrial: boolean;
    readonly canUseCoupon: boolean;
    readonly moduleId: number;
    readonly recurrenceInterval?: Recurrence;
    readonly creditsValidityInterval?: Recurrence;
    readonly rolloverProductId: number;
}

export default createRecordFactory<Product>({
    id: undefined,
    individualCredits: undefined,
    isNonRecurring: undefined,
    groupCredits: undefined,
    type: undefined,
    isTrial: undefined,
    canUseCoupon: undefined,
    moduleId: undefined,
    recurrenceInterval: undefined,
    creditsValidityInterval: undefined,
    rolloverProductId: undefined,
});

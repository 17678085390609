import { Box, Stack, Typography, colorsPalette } from '@lingoda/ui';

interface ListElementProps {
    icon?: React.ReactNode;
    title: string;
    content: string;
}
export const ListElement = ({ icon, title, content }: ListElementProps) => {
    return (
        <Stack direction="row" alignItems="center" spacing={3}>
            {icon}
            <Box>
                <Typography variant="subtitle1" mb={0.5}>
                    {title}
                </Typography>
                <Typography variant="body2" htmlColor={colorsPalette.blackAlpha[70]}>
                    {content}
                </Typography>
            </Box>
        </Stack>
    );
};

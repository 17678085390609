import { useEffect } from 'react';
import { captureError } from '@lingoda/monitor';
import { useSelector } from '@lingoda/hooks';
import { currentVisitorTimezoneSelector, userSelector } from '@lingoda/auth';
import { createDate, format, getTimezone } from '@lingoda/dates';
import { getBrowserTimeZone } from '../logic/getBrowserTimeZone';

export const useTimeCheck = () => {
    const user = useSelector(userSelector);
    const userStoreTimeZone = useSelector(currentVisitorTimezoneSelector);

    useEffect(() => {
        if (!user?.now) {
            return;
        }

        const data = getBrowserTimeOutOfSyncErrorData(user.now, userStoreTimeZone);

        if (!data.isTimeSync) {
            captureError(
                new BrowserTimeOutOfSyncError(`The browser time is out of sync with the API time.`),
                {
                    data,
                },
            );
        }
    }, [user, userStoreTimeZone]);
};

export const getBrowserTimeOutOfSyncErrorData = (apiTime: Date, userStoreTimeZone?: string) => {
    const userDefaultTimezone = getTimezone();
    const userTimeZoneOffset = format('OOOO', createDate());

    const browserTime = new Date();
    const browserApiTimeDifferenceMs = Math.abs(browserTime.getTime() - apiTime.getTime());

    const isTimeSync = browserApiTimeDifferenceMs < MAX_TIME_DIFFERENCE_MS;

    const data = {
        isTimeSync,
        MAX_TIME_DIFFERENCE_MS,
        browserTimeUTC: browserTime.toISOString(),
        apiTimeUTC: apiTime?.toISOString(),
        browserApiTimeDifferenceMs,
        browserTimeZoneFixed: getBrowserTimeZone(),
        getBrowserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userDate: createDate().toString(),
        userDefaultTimezone,
        userStoreTimeZone,
        userTimeZoneOffset,
    };

    return data;
};

export class BrowserTimeOutOfSyncError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'BrowserTimeOutOfSyncError';
    }
}

export class TryingToGenerateZoomMeetingTooEarly extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'TryingToGenerateZoomMeetingTooEarly';
    }
}

const MAX_TIME_DIFFERENCE_MS = 15 * 60 * 1000;
export const TRYING_TO_GENERATE_ZOOM_MEETING_TOO_EARLY_ERROR_MESSAGE =
    'Trying to generate Zoom meeting too early';

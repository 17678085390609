import { Typography } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { Entry, Title } from './Entry';
import waitingImg from './assets/frank-waiting.svg?url';

export const EntryEmpty = () => {
    return (
        <Entry bgImage={waitingImg} disabled>
            <Title mb={0.5}>{trans('entry-empty-title', {}, 'quiz-practice-dashboard')}</Title>

            <Typography>{trans('entry-empty-subtitle', {}, 'quiz-practice-dashboard')}</Typography>
        </Entry>
    );
};

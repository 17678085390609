import { memo } from 'react';
import {
    addMonths,
    endOfMonth,
    format,
    isSameOrAfter,
    isSameOrBefore,
    startOfMonth,
} from '@lingoda/dates';
import { capitalize } from '@lingoda/utils';
import { colorsPalette, styled } from '../../theme';
import { NavigateAfter, NavigateBefore } from '../../icons/lingodaIcons';
import { Box } from '../../layout/Box';
import { Typography } from '../../layout/Typography';

interface NavigatorProps {
    showArrows: boolean;
}

interface DatePickerNavigatorProps extends NavigatorProps {
    currentDate: Date;
    maxDate?: Date;
    minDate?: Date;
    changeShownDate: (value: Date) => void;
    isRange: boolean;
}

const IconWrapper = styled(Box, {
    name: 'NavigateIcon',
    shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean | undefined }>(({ disabled, theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: disabled ? colorsPalette.blackSolid[20] : colorsPalette.blackAlpha[70],
    cursor: disabled ? '' : 'pointer',
    pointerEvents: disabled ? 'none' : undefined,
}));

const NavigatorWrapper = styled(Box, {
    name: 'NavigatorWrapper',
    shouldForwardProp: (prop) => prop !== 'showArrows',
})<NavigatorProps>(({ showArrows, theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: showArrows ? 'space-between' : 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(1),
}));

const formatMonth = format('LLLL yyyy');

const DatePickerNavigatorComponent = ({
    isRange = false,
    currentDate,
    maxDate,
    minDate,
    changeShownDate,
    showArrows,
}: DatePickerNavigatorProps) => {
    const prevMonth = () => {
        changeShownDate(addMonths(currentDate, -1));
    };
    const nextMonth = () => {
        changeShownDate(addMonths(currentDate, 1));
    };

    const disablePrev = minDate ? isSameOrAfter(minDate, startOfMonth(currentDate)) : false;
    const disableNext = maxDate ? isSameOrBefore(maxDate, endOfMonth(currentDate)) : false;

    return (
        <NavigatorWrapper showArrows={showArrows}>
            {showArrows && (
                <IconWrapper disabled={disablePrev} data-cy="datepicker-previous-date">
                    <NavigateBefore onClick={prevMonth} />
                </IconWrapper>
            )}
            <Box display="flex" justifyContent={isRange ? 'space-around' : 'center'} width="100%">
                <Typography variant="h6" htmlColor={colorsPalette.blackSolid[100]}>
                    {capitalize(formatMonth(currentDate))}
                </Typography>
                {isRange && (
                    <Typography variant="h6" htmlColor={colorsPalette.blackSolid[100]}>
                        {capitalize(formatMonth(addMonths(currentDate, 1)))}
                    </Typography>
                )}
            </Box>
            {showArrows && (
                <IconWrapper disabled={disableNext} data-cy="datepicker-next-date">
                    <NavigateAfter onClick={nextMonth} />
                </IconWrapper>
            )}
        </NavigatorWrapper>
    );
};

export const DatePickerNavigator = memo(DatePickerNavigatorComponent);

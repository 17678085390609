import { httpGet } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type { TransactionSchema } from './common';

export interface TransactionResponse {
    data: TransactionSchema;
}

export const getLastTransaction = (): Promise<TransactionResponse> =>
    httpGet(getUrl('api_transactions_show_last'));

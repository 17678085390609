import { Box } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { useResetResultsMutation } from '@lingoda/graphql';
import { showApolloErrorToast } from '@lingoda/toasts';
import { useToggleState } from '@lingoda/hooks';
import { goTo, studentQuizNextPath } from '@lingoda/router';
import { ResetDialog } from '../../ResetDialog';
import { ButtonCta, Entry, LastReview, Title } from './Entry';
import finishImg from './assets/frank-finishes.svg?url';

interface EntryCompleteProps {
    lastReview?: string | Date;
}

export const EntryComplete = ({ lastReview }: EntryCompleteProps) => {
    const [resetResults, resetMeta] = useResetResultsMutation({
        onCompleted: () => {
            goTo(studentQuizNextPath());
        },
        onError: showApolloErrorToast,
    });

    const handleReset = async () => {
        const { data } = await resetResults();

        return !!data?.quizEngineResetResult;
    };

    return <Layout lastReview={lastReview} onReset={handleReset} isResetting={resetMeta.loading} />;
};

interface LayoutProps {
    lastReview?: string | Date;
    isResetting?: boolean;
    onReset?: () => Promise<boolean>;
}

const Layout = ({ lastReview, onReset, isResetting }: LayoutProps) => {
    const [resetDialogIsOpen, openResetDialog, closeResetDialog] = useToggleState();

    return (
        <Entry
            contentSx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            bgImage={finishImg}
        >
            <Box>
                <Title mb={0.5}>
                    {trans('entry-complete-title', {}, 'quiz-practice-dashboard')}
                </Title>

                {lastReview && <LastReview date={lastReview} />}
            </Box>

            <Box mt={2}>
                <ButtonCta onClick={openResetDialog}>
                    {trans('entry-complete-cta', {}, 'quiz-practice-dashboard')}
                </ButtonCta>

                <ResetDialog
                    isOpen={resetDialogIsOpen}
                    isLoading={isResetting}
                    onConfirm={onReset}
                    onClose={closeResetDialog}
                />
            </Box>
        </Entry>
    );
};

export { Layout as EntryCompleteLayout };

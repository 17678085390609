import { useMemo, useState } from 'react';
import type { ScaleFunction } from '@lingoda/ui';
import { Box, Stack, Tooltip, Typography, colorsPalette, getLinearScale } from '@lingoda/ui';
import { trans, transChoice } from '@lingoda/i18n';
import { useElementSize } from '@lingoda/hooks';
import StarIcon from './star.svg?component';
import { getBarBackgroundColor, goalWasReached } from './utils';

export interface ChartItem {
    id: string;
    attendance: number;
    goal: number | null;
    shortLabel: string;
    longLabel: string;
}

interface ChartProps {
    items: ChartItem[];
    page: number;
}

export const Chart = ({ items, page }: ChartProps) => {
    const [goalSvgRef, setGoalSvgRef] = useState<HTMLDivElement>();
    const width = useElementSize(goalSvgRef, 'offsetWidth');

    const { bars, lines } = useMemo(() => {
        const scaleX = getLinearScale(
            0,
            items.length - 1,
            BAR_WITH_CAPTION_WIDTH / 2,
            width - BAR_WITH_CAPTION_WIDTH / 2,
        );
        const scaleY = getLinearScale(
            0,
            Math.max(MAX_GOAL_VALUE, ...items.map(({ attendance: value }) => value)),
            BAR_HEIGHT_MIN,
            BAR_HEIGHT_MAX,
        );
        const bars = itemsToBars(items, scaleY, page);
        const lines = itemsToLines(items, scaleX, scaleY);

        return { bars, lines };
    }, [items, page, width]);

    return (
        <Box position="relative" height={GRAPH_HEIGHT}>
            <Box ref={setGoalSvgRef}>
                <svg
                    viewBox={`0 0 ${width} ${GRAPH_HEIGHT - CAPTION_HEIGHT}`}
                    width={width}
                    height={GRAPH_HEIGHT - CAPTION_HEIGHT}
                >
                    <g transform={`translate(0 ${GRAPH_HEIGHT - CAPTION_HEIGHT}) scale(1 -1)`}>
                        {lines.map(({ id, ...line }) => (
                            <line key={id} {...line} stroke="#F38401" strokeWidth={LINE_WIDTH} />
                        ))}
                    </g>
                </svg>
            </Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
            >
                {bars.map(
                    (
                        { id, attendance, goal, height, background, shortLabel, longLabel },
                        index,
                    ) => (
                        <Stack key={id} justifyContent="flex-end" alignItems="center">
                            <Box
                                visibility={goalWasReached(goal, attendance) ? 'visible' : 'hidden'}
                            >
                                <StarIcon />
                            </Box>
                            <Box marginTop="4px">
                                <Tooltip
                                    arrow
                                    placement="top"
                                    title={
                                        <Stack direction="column" alignItems="center">
                                            <Box>
                                                {transChoice(
                                                    'weekly-progress-bar-tooltip-attendance',
                                                    attendance,
                                                    { attendance },
                                                    'student-common',
                                                )}
                                            </Box>
                                            <Box>
                                                {isCurrentWeek(page, index)
                                                    ? trans(
                                                          'weekly-progress-bar-tooltip-this-week',
                                                          {},
                                                          'student-common',
                                                      )
                                                    : longLabel}
                                            </Box>
                                        </Stack>
                                    }
                                >
                                    <Box
                                        sx={{
                                            width: `${BAR_WIDTH}px`,
                                            height: `${height}px`,
                                            background,
                                            borderTopLeftRadius: attendance
                                                ? `${BAR_WIDTH / 2}px`
                                                : '4px',
                                            borderTopRightRadius: attendance
                                                ? `${BAR_WIDTH / 2}px`
                                                : '4px',
                                            borderBottomLeftRadius: '4px',
                                            borderBottomRightRadius: '4px',
                                        }}
                                    />
                                </Tooltip>
                            </Box>
                            <Typography
                                variant="body3"
                                htmlColor={colorsPalette.blackAlpha[70]}
                                sx={{
                                    marginTop: '8px',
                                    width: BAR_WITH_CAPTION_WIDTH,
                                    textAlign: 'center',
                                }}
                            >
                                {shortLabel}
                            </Typography>
                        </Stack>
                    ),
                )}
            </Stack>
        </Box>
    );
};

const MAX_GOAL_VALUE = 5;
const BAR_WIDTH = 24;
const BAR_WITH_CAPTION_WIDTH = 40;
const CAPTION_HEIGHT = 24;
const BAR_HEIGHT_MIN = 8;
const BAR_HEIGHT_MAX = 108;
export const GRAPH_HEIGHT = 160;
const LINE_WIDTH = 1;

const isCurrentWeek = (page: number, index: number) => page === 0 && index === 3;

const itemsToLines = (items: ChartItem[], scaleX: ScaleFunction, scaleY: ScaleFunction) =>
    items
        .map(({ id, goal }, i) => ({
            id,
            x: scaleX(i),
            y: goal ? scaleY(goal) + LINE_WIDTH : undefined,
        }))
        .map((point, i, points) => {
            const rightPoint = i < points.length - 1 ? points[i + 1] : undefined;

            return [
                ...(point.y
                    ? [
                          {
                              id: `${point.id}-goal`,
                              x1: point.x - BAR_WIDTH / 2,
                              y1: point.y,
                              x2: point.x + BAR_WIDTH / 2,
                              y2: point.y,
                          },
                      ]
                    : []),
                ...(point.y && rightPoint?.y
                    ? [
                          {
                              id: `${point.id}-trend`,
                              x1: point.x + BAR_WIDTH / 2,
                              y1: point.y,
                              x2: rightPoint.x - BAR_WIDTH / 2,
                              y2: rightPoint.y,
                          },
                      ]
                    : []),
            ];
        })
        .flat();

const itemsToBars = (items: ChartItem[], scaleY: ScaleFunction, page: number) =>
    items.map((item, index) => ({
        ...item,
        height: scaleY(item.attendance),
        background: getBarBackgroundColor(item.attendance, item.goal, page, index, items.length),
    }));

import { lazy } from 'react';
import { hasFeature } from '@lingoda/feature-flags';
import { FeatureFlag } from '@lingoda/graphql';
import { type RouteConfigExtended, TabValue, studentQuizNextPath } from '@lingoda/router';
import { NotFoundPage } from '@lingoda/fallbacks';

const QuizPage = lazy(() => import('./pages/QuizPage'));

const QuizPageRoute = () => {
    const Route = hasFeature(FeatureFlag.QuizEngine) ? QuizPage : NotFoundPage;

    return <Route />;
};

export const quizRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: QuizPageRoute,
        path: studentQuizNextPath.raw,
        label: 'Vocab Trainer',
        config: {
            hideHeader: true,
            hideFooter: true,
            hideDixa: true,
            hideMobileBottomNavbar: true,
            tab: TabValue.Practice,
            hideNotifications: true,
        },
    },
];

import { type Breakpoint, type CSSObject, handleBreakpoints } from '@mui/system';
import type { Theme } from '../theme';

export type BreakPointsAdjustedProp<T extends number | string> = T | Partial<Record<Breakpoint, T>>;

/**
 * Helps to adjust responsive style rules into CSS:
 * breakPointsAdjustedProp(theme, { xs: 10, md: 20 }, (value: number) => ({
 *   width: value,
 * })): {
 *   "@media (min-width: xs)": {
 *     width: 10
 *   },
 *   "@media (min-width: md)": {
 *     width: 20
 *   }
 * }
 */
export const breakPointsAdjustedProp = <T extends number | string>(
    theme: Theme,
    prop: BreakPointsAdjustedProp<T>,
    styleFromPropValue: (value: T, breakpoint?: Breakpoint) => CSSObject,
): CSSObject => {
    if (typeof prop === 'object') {
        return handleBreakpoints({ theme }, prop, styleFromPropValue);
    }

    return styleFromPropValue(prop);
};

export const getBreakPointStyleProp = <T extends number | string>(
    prop: BreakPointsAdjustedProp<T> | undefined,
    breakpoint: Breakpoint,
    styleFromPropValue?: (value: T | undefined) => string | undefined,
) => {
    if (typeof prop === 'object') {
        const propValue = prop[breakpoint];
        if (propValue === undefined) {
            return undefined;
        }

        return styleFromPropValue ? styleFromPropValue(propValue) : propValue;
    }

    if (prop === undefined) {
        return undefined;
    }

    return styleFromPropValue ? styleFromPropValue(prop) : prop;
};

import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import { leadFlowPath } from '@lingoda/router';

const LeadConversionFlowPage = lazy(() => import('./pages/LeadConversionFlowPage'));

const leadConversionFlowRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: LeadConversionFlowPage,
        path: leadFlowPath.raw,
        label: 'LeadConversionFlow',
        config: {
            hideNavigation: true,
            tab: undefined,
        },
    },
];

export default leadConversionFlowRoutes;

export const routeLabelsForPartiallySupportedLangs = [
    'Login', // loginPath
    'SetPasswordForNewAccount', // setPasswordForNewAccountPath
    'ChooseSectionCurriculum', // registerPath
    'RegisterSection', //  directRegisterSectionPath / m2mRegisterSectionPath / registerSectionSprintPath / teamsRegisterSectionPath
    'M2MSRegisterLoader', // m2mSignupSectionPath
    'Purchase', // packagePurchasePath
    'Checkout', // studentM2MCheckoutPath / studentCreditsCheckoutPath / studentSprintCheckoutPath
    'MarketingQuestionnaire', // onboardingSurveyPath
    'WelcomeToLingoda', // onboardingWelcomePath
    'SprintSignupLoader', // sprintSignupPath
    'SprintPurchase', // sprintPackagePurchasePath
    'TeamsSignupLoader', // teamsSignupPath
    'TeamsPurchase', // studentTeamsPackagePurchasePath
    'CompleteAccountWelcomePage', // completeAccountWelcomePath
    'CompleteAccountSetPasswordPage', // completeAccountSetPasswordPath
];

import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Box, Button, Divider, Hidden, Paper, Stack, Typography, useUiEvent } from '@lingoda/ui';
import { useCreateOrBuyAdditionalCredits } from '@lingoda/purchases';
import { goTo, studentCreditsCheckoutPath } from '@lingoda/router';
import type { Currency } from '@lingoda/currency';
import { useBookWithoutPaymentAvailable } from '@lingoda/no-credit-card-trial';
import { userCurrencySelector } from '@lingoda/currency';
import { useSelector, useToggleState } from '@lingoda/hooks';
import { ClassType } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { actionClick } from '@lingoda/analytics';
import { ConfirmBookInTrialModal } from './ConfirmBookInTrialModal';
import CreditsInput from './CreditsInput';
import PurchaseTotal from './PurchaseTotal';

interface Props {
    onCreditsInfoClick: () => void;
}

const BuyCredits = ({ onCreditsInfoClick }: Props) => {
    const addEvent = useUiEvent();

    const defaultCurrency = useSelector(userCurrencySelector);
    const [currency, setCurrency] = useState<Currency>(defaultCurrency);
    const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] = useToggleState(false);

    const [updatePurchase, purchase, isUpdatingPurchase] = useCreateOrBuyAdditionalCredits();
    const { bookWithoutPaymentAvailable, loading: isSubscriptionLoading } =
        useBookWithoutPaymentAvailable();

    const [privateCredits, setPrivateCredits] = useState<number>(0);
    const [groupCredits, setGroupCredits] = useState<number>(0);
    const totalCredits = privateCredits + groupCredits;

    const debouncedUpdatePurchase = useMemo(
        () =>
            debounce(updatePurchase, 500, {
                leading: true,
            }),
        [updatePurchase],
    );

    useEffect(() => {
        // A purchase can't have 0 credits, so if the user removes all credits, only the UI gets updated and updatePurchase can't be called
        if (totalCredits > 0) {
            const variables = {
                id: purchase?.id || '',
                groupCredits,
                privateCredits,
                currency: currency,
            };
            debouncedUpdatePurchase(variables);
        }
        // The dep list is missing purchase.id. This is a "dirty" approach to avoid performing an unnecessary extra request when purchase is created.
        // https://lingoda.atlassian.net/browse/LW-19910
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupCredits, privateCredits, currency, totalCredits, debouncedUpdatePurchase]);

    const isLoading = isUpdatingPurchase || isSubscriptionLoading;
    const isPurchaseReady = !isLoading && totalCredits > 0 && !!purchase;
    const confirmStatus = isPurchaseReady ? 'enabled' : isLoading ? 'loading' : 'disabled';

    const goToCheckout = () => {
        if (isPurchaseReady) {
            void addEvent(actionClick('GoToCheckoutClick'), {
                metadata: { groupCredits, privateCredits },
            });
            goTo(studentCreditsCheckoutPath({ purchaseId: purchase.id }));
        }
    };

    const handleContinue = () => {
        if (isPurchaseReady && bookWithoutPaymentAvailable) {
            return openConfirmModal();
        }

        goToCheckout();
    };

    return (
        <Paper elevation={2}>
            <Stack p={3} gap={2}>
                <Typography variant="h1">
                    {trans('credits-store-title', {}, 'student-store')}
                </Typography>
                <Stack gap={1} alignItems="flex-start">
                    <Typography variant="body1">
                        {trans('credits-store-description', {}, 'student-store')}
                    </Typography>
                    <Hidden mdUp>
                        <Button
                            variant="text"
                            sx={{
                                padding: 0,
                                display: 'inline',
                                textAlign: 'left',
                                fontSize: 'inherit',
                            }}
                            onClick={onCreditsInfoClick}
                            disableRipple
                        >
                            {trans('credits-store-types-info-title', {}, 'student-store')}
                        </Button>
                    </Hidden>
                </Stack>

                <Stack spacing={3}>
                    <Stack spacing={2}>
                        <CreditsInput
                            type={ClassType.Group}
                            credits={groupCredits}
                            onCreditsChange={setGroupCredits}
                        />
                        <CreditsInput
                            type={ClassType.Individual}
                            credits={privateCredits}
                            onCreditsChange={setPrivateCredits}
                        />
                    </Stack>
                    <Divider />
                    <PurchaseTotal
                        isLoading={isLoading}
                        totalPrice={totalCredits ? purchase?.total.amountInMajor : 0}
                        currency={currency}
                        onChangeCurrency={setCurrency}
                    />

                    <Box alignSelf="flex-end">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleContinue}
                            loading={isLoading}
                            disabled={!isPurchaseReady}
                        >
                            {trans('credits-store-checkout-btn', {}, 'student-store')}
                        </Button>
                    </Box>
                </Stack>
            </Stack>
            <ConfirmBookInTrialModal
                isOpen={isConfirmModalOpen}
                onConfirm={goToCheckout}
                onClose={closeConfirmModal}
                confirmStatus={confirmStatus}
            />
        </Paper>
    );
};

export default BuyCredits;

import { type PropsWithChildren, forwardRef } from 'react';
import { RouterLink } from '@lingoda/router';
import { useResolution } from '../../hooks';
import { Grid } from '../GridNext';
import { Typography } from '../Typography';
import { withCursorInteractiveStyles } from '../../utils';
import { colorsPalette, styled } from '../../theme';
import { AvatarFallback } from '../AvatarFallback';
import { MeetingCardDetails, type MeetingCardDetailsProps } from './MeetingCardDetails';
import type { SxProps, Theme } from '../../theme';

export interface MeetingCardProps extends MeetingCardDetailsProps {
    title?: string;
    titleSuffix?: string;
    avatarSrc?: string;
    avatarAlt?: string;
    linkTo?: string;
    secondColumn?: React.ReactNode;
    onClick?: () => void;
}

export const MeetingCard = ({
    title,
    titleSuffix,
    subtitle,
    avatarSrc,
    avatarAlt,
    startDate,
    endDate,
    linkTo,
    participants,
    hasHost,
    isActive,
    children,
    showTime,
    secondColumn,
    onClick,
}: PropsWithChildren<MeetingCardProps>) => {
    const isMobile = useResolution('phone');

    return (
        <StyledGridContainer
            container
            spacing={0}
            flexWrap="nowrap"
            p={{ xs: 2, sm: 3 }}
            gap={{ xs: 2, sm: 3 }}
            onClick={onClick}
            isClickable={!!linkTo}
            {...(linkTo && {
                component: RouterLinkSlot,
                to: linkTo,
                target: isMobile ? '_self' : '_blank',
            })}
        >
            <AvatarContainer xs="auto" p={0} container spacing={0}>
                <AvatarFallback src={avatarSrc} alt={avatarAlt} size={{ xs: 48, sm: 56 }} />
            </AvatarContainer>

            <Grid container flexDirection="column" spacing={0.5} flexShrink={1} p={0}>
                <Grid>
                    {title && (
                        <Typography
                            variant="subtitle1"
                            htmlColor={colorsPalette.blackSolid[100]}
                            display="inline-block"
                            mr={0.5}
                            sx={{ overflowWrap: 'anywhere' }}
                        >
                            {title}
                        </Typography>
                    )}
                    {titleSuffix && (
                        <Typography
                            variant="body1"
                            htmlColor={colorsPalette.blackSolid[100]}
                            paragraph={false}
                            component="span"
                            display="inline-block"
                        >
                            {titleSuffix}
                        </Typography>
                    )}
                </Grid>
                <MeetingCardDetails
                    subtitle={subtitle}
                    startDate={startDate}
                    endDate={endDate}
                    participants={participants}
                    hasHost={hasHost}
                    isActive={isActive}
                    showTime={showTime}
                />
                {children}
            </Grid>

            {secondColumn}
        </StyledGridContainer>
    );
};

interface StyledContainerProps {
    isClickable: boolean;
}

const StyledGridContainer = styled(Grid, {
    shouldForwardProp: (prop) => !['isClickable'].includes(String(prop)),
})<StyledContainerProps>(({ isClickable }) => ({
    textDecoration: 'inherit',
    color: 'inherit',
    ...withCursorInteractiveStyles(isClickable)({
        '&:first-of-type': {
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
        },
        '&:last-of-type': {
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
        },
        '&:not(:last-child)': {
            borderBottom: `1px solid ${colorsPalette.blackAlpha[8]}`,
        },
        'a:not(:last-child) > &': {
            borderBottom: `1px solid ${colorsPalette.blackAlpha[8]}`,
        },
    }),
}));

const AvatarContainer = styled(Grid)(({ theme }) => ({
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
    },
}));

interface RouterLinkSlotProps {
    sx: SxProps<Theme>;
    ownerState: GenericObject<unknown>;
}

const RouterLinkSlot = forwardRef<HTMLAnchorElement, RouterLinkSlotProps>(
    ({ ownerState, sx, ...passProps }, ref) => <RouterLink {...passProps} ref={ref} />,
);

RouterLinkSlot.displayName = 'RouterLinkSlot';

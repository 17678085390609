/**
 * Stores state between loads in persistent storage
 */

import { useCallback, useState } from 'react';
import { type AllowedTypes, getStorageValue, setStorageValue } from '@lingoda/utils';

export const usePersistentState = <T extends AllowedTypes>(key: string, initial?: T) => {
    const storageKey = getStorageKey(key);

    const [localState, setLocalState] = useState<T | undefined>(() => {
        return getStorageValue(storageKey) || initial;
    });

    const setValue = useCallback(
        (nextValue: T) => {
            setStorageValue(storageKey, nextValue);
            setLocalState(nextValue);
        },
        [storageKey],
    );

    return [localState, setValue] as const;
};

export const getStorageKey = (key: string) => `usePersistentState_${key}`;

import { SvgIcon } from '../layout/SvgIcon';
import { colorsPaletteThirdParty } from '../theme';
import type { SvgIconProps } from '../layout/SvgIcon';

const TwitterIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        viewBox="0 0 16 16"
        htmlColorDefault={colorsPaletteThirdParty.twitterSolid[100]}
        {...props}
    >
        <path d="M5.032 14.327c6.038 0 9.34-4.999 9.34-9.333a9.33 9.33 0 0 0-.01-.424A6.672 6.672 0 0 0 16 2.871a6.55 6.55 0 0 1-1.885.517 3.293 3.293 0 0 0 1.443-1.814 6.587 6.587 0 0 1-2.084.796 3.28 3.28 0 0 0-2.396-1.037A3.282 3.282 0 0 0 7.88 5.361a9.324 9.324 0 0 1-6.767-3.427A3.277 3.277 0 0 0 2.13 6.313a3.26 3.26 0 0 1-1.486-.41v.042A3.282 3.282 0 0 0 3.276 9.16a3.29 3.29 0 0 1-1.482.056 3.286 3.286 0 0 0 3.066 2.279 6.589 6.589 0 0 1-4.077 1.404c-.265 0-.526-.015-.783-.046a9.297 9.297 0 0 0 5.032 1.473" />
    </SvgIcon>
);

export default TwitterIcon;

import { createSelector } from 'reselect';
import { STORE_KEY } from './constants';
import type { PaymentMethod } from './models';

const paymentMethodsStateSelector = (state: GlobalState) => state[STORE_KEY];

export const paymentMethodsSelector = createSelector(
    paymentMethodsStateSelector,
    (paymentMethods: PaymentMethod[]): ReadonlyArray<PaymentMethod> =>
        Object.values(paymentMethods),
);

export const paymentMethodsStoredSelector = createSelector(
    paymentMethodsSelector,
    (paymentMethods) => paymentMethods.filter(({ stored }) => stored),
);

export const paymentMethodsNewSelector = createSelector(paymentMethodsSelector, (paymentMethods) =>
    paymentMethods.filter(({ stored }) => !stored),
);

export const paymentMethodDefaultSelector = createSelector(
    paymentMethodsNewSelector,
    paymentMethodsStoredSelector,
    (paymentMethodsNew, paymentMethodsStored) =>
        paymentMethodsStored.find((paymentMethod) => paymentMethod.default) || paymentMethodsNew[0],
);

export const storedPaymentMethodByIdSelector = (
    selector: (state: unknown, props: unknown) => string | undefined,
) =>
    createSelector(paymentMethodsStoredSelector, selector, (paymentMethods, id) =>
        Object.values(paymentMethods).find((item) => item.id === id),
    );

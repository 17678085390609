import { useMemo, useState } from 'react';
import type { TextFieldProps } from '@lingoda/ui';
import { Box, IconButton, colorsPalette } from '@lingoda/ui';
import InputAdornment from '@lingoda/ui/fields/InputAdornment';
import { PasswordIcon } from '@lingoda/ui/icons/PasswordIcon';
import TextField from '../TextField';
import type { FieldAttributes } from '../../utils';

type Props = TextFieldProps &
    FieldAttributes<unknown> & {
        autoComplete: 'current-password' | 'new-password';
        reveal?: boolean;
    };

export const PasswordTextField: React.FC<Props> = ({ reveal, ...props }) => {
    const [revealPassword, setRevealPassword] = useState(false);
    const type = useMemo(
        () => (!reveal ? 'password' : revealPassword ? 'text' : 'password'),
        [reveal, revealPassword],
    );

    return (
        <Box display="flex" alignItems="center" position="relative">
            <TextField
                InputProps={
                    reveal
                        ? {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      <IconButton
                                          sx={{ mr: -1.5 }}
                                          aria-label="Reveal Password"
                                          onClick={() => setRevealPassword(!revealPassword)}
                                      >
                                          <PasswordIcon
                                              stroke={
                                                  revealPassword
                                                      ? colorsPalette.primarySolid[100]
                                                      : colorsPalette.blackSolid[100]
                                              }
                                          />
                                      </IconButton>
                                  </InputAdornment>
                              ),
                          }
                        : undefined
                }
                type={type}
                {...props}
            />
        </Box>
    );
};

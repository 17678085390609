import { trans } from '@lingoda/i18n';
import { Box, DangerousHtml, DialogConfirm, Typography, useResolution } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import type { StepCommonProps } from '../types';

const StyledList = styled(Box)(({ theme }) => ({
    '& ul': {
        paddingLeft: theme.spacing(3),
        margin: theme.spacing(0.5, 0, 0, 0),
    },
}));

interface Props extends StepCommonProps {
    onConfirm: () => void;
}

const InformationStep = ({ isOpen, closeDialog, onConfirm }: Props) => {
    const isMobile = useResolution('phone');

    return (
        <DialogConfirm
            isOpen={isOpen}
            title={trans('subscription-pause-take-a-break', {}, 'student-common')}
            dismissLabel={trans('btn-cancel', {}, 'public-common')}
            onDismiss={closeDialog}
            confirmLabel={trans('subscription-pause-understand-terms', {}, 'student-common')}
            onConfirm={onConfirm}
            onClose={closeDialog}
            maxWidth="md"
            confirmStatus="enabled"
            scrollable={isMobile}
        >
            <Box maxWidth={584} pb={{ xs: 2, sm: 0 }}>
                <Typography variant="body1">
                    {trans('subscription-pause-header', {}, 'student-common')}
                </Typography>
                <StyledList>
                    <DangerousHtml>
                        {trans('subscription-pause-rules', {}, 'student-common')}
                    </DangerousHtml>
                </StyledList>
            </Box>
        </DialogConfirm>
    );
};

export default InformationStep;

import { injectAsyncReducer } from '@lingoda/core';
import {
    APPOINTMENTS_STORE_KEY,
    CLASSES_STORE_KEY,
    STUDENT_APPOINTMENTS_STORE_KEY,
} from './constants';
import appointmentReducers from './reducers/appointmentReducers';
import classReducers from './reducers/classReducers';
import studentAppointmentsReducers from './reducers/studentAppointmentsReducers';
import initSideEffects from './side-effects';

export * from './actions';
export * from './components';
export * from './constants';
export * from './helpers';
export * from './hooks';
export * from './models';
export { default as appointmentReducers } from './reducers/appointmentReducers';
export { default as classReducers } from './reducers/classReducers';
export { default as studentAppointmentsReducers } from './reducers/studentAppointmentsReducers';
export * from './selectors';
export * from './transformers';
export * from './utils';

injectAsyncReducer(STUDENT_APPOINTMENTS_STORE_KEY, studentAppointmentsReducers);
injectAsyncReducer(APPOINTMENTS_STORE_KEY, appointmentReducers);
injectAsyncReducer(CLASSES_STORE_KEY, classReducers);
initSideEffects();

import pick from 'lodash/pick';
import { getStorageValue, removeStorageValue, setStorageValue } from '@lingoda/utils';
import { formatTzIndependentDay, parseTzIndependentDay } from '@lingoda/dates';
import { type BookingFilter } from '../models';
import { DEFAULT_BOOKING_FILTER } from '../constants';
import { getValidDatesFromTimeSlots } from './getValidDatesFromTimeSlots';
import { getValidRangeDate } from './getValidRangeDate';

interface LocalBookingFilter extends Omit<BookingFilter, 'dates'> {
    dates: string[];
    exactDate: string | null;
}

const KEY = 'booking_filter';

// getStorageValue is async on RN unfortunately
let current: Partial<LocalBookingFilter> | undefined = undefined;
const pickedKeys = Object.keys(DEFAULT_BOOKING_FILTER) as (keyof typeof DEFAULT_BOOKING_FILTER)[];

const parseLocalFilter = (localFilter: Partial<LocalBookingFilter>): Partial<BookingFilter> => {
    let dates: Date[] = [];
    let exactDate: Date | null | undefined = undefined;

    const timeSlots = localFilter.timeSlots || DEFAULT_BOOKING_FILTER.timeSlots;

    if (localFilter.exactDate !== undefined) {
        const date = localFilter.exactDate ? parseTzIndependentDay(localFilter.exactDate) : null;
        exactDate = date ? getValidRangeDate(date, timeSlots) : null;
    }

    if (localFilter.dates) {
        dates = getValidDatesFromTimeSlots(
            localFilter.dates.map((date) => parseTzIndependentDay(date)),
            timeSlots,
        );
    } else if (exactDate) {
        dates = [exactDate];
    }

    return {
        ...localFilter,
        dates: dates,
    };
};

const formatLocalFilter = (bookingFilter: Partial<BookingFilter>): Partial<LocalBookingFilter> => {
    const { dates, ...rest } = bookingFilter;

    return {
        ...rest,
        dates: bookingFilter.dates?.map((value) => formatTzIndependentDay(value)),
    };
};

void (async () => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const localFilter = (await getStorageValue(KEY)) as Partial<LocalBookingFilter> | undefined;
    const filteredFilter = localFilter ? pick(localFilter, pickedKeys) : undefined;
    current = filteredFilter;
})();

export const getStoredBookingFilter = () => (current ? parseLocalFilter(current) : undefined);

export const setStoredBookingFilter = (bookingFilter: Partial<BookingFilter>) => {
    const filteredFilter = pick(bookingFilter, pickedKeys);
    const localFilter = formatLocalFilter(filteredFilter);
    setStorageValue(KEY, localFilter);
    current = localFilter;
};

export const clearStoredBookingFilter = () => {
    removeStorageValue(KEY);
    current = undefined;
};

export const updateStoredBookingFilter = (filterChanges: Partial<BookingFilter>) => {
    setStoredBookingFilter({ ...getStoredBookingFilter(), ...filterChanges });
};

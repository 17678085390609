import { useSelector } from '@lingoda/hooks';
import { currentSectionSelector } from '@lingoda/auth';
import { studentModuleSelector } from '@lingoda/modules';
import { trans } from '@lingoda/i18n';

type UseStudentSectionTrans = (
    transKey: string,
    segment: string,
    params?: Parameters<typeof trans>[1],
) => string;

export const useStudentSectionTrans: UseStudentSectionTrans = (
    transKey,
    segment = '',
    params = {},
) => {
    const sectionName = useSelector(currentSectionSelector);
    const moduleName = useSelector(studentModuleSelector);

    if (!moduleName?.name || !sectionName) {
        return '';
    }

    return trans(
        /* @ignore */ `${transKey}-${sectionName}`,
        { ...params, sectionName, studentModuleName: moduleName.name },
        segment,
    );
};

import { handleActionsImmutably } from '@lingoda/core';
import { addProductVariants } from '../actions';
import { productVariantFactory } from '../models';
import type { ProductVariant } from '../models';

export type State = Readonly<GenericObject<ProductVariant>>;

type ProductVariantsAction = ReturnType<typeof addProductVariants>;

export default handleActionsImmutably<State, ProductVariantsAction['payload']>(
    {
        [`${addProductVariants}`]: (state, { payload }: ProductVariantsAction) =>
            payload.reduce((result, productVariant) => {
                result[productVariant.id] = productVariantFactory(productVariant);

                return result;
            }, state),
    },
    {},
);

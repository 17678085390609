import { useEffect } from 'react';
import type { Date } from '@lingoda/dates';
import { diffInUnits } from '@lingoda/dates';
import { trans, transChoice } from '@lingoda/i18n';
import { subscriptionsStorePath } from '@lingoda/router';
import { localRoundHourSelector } from '@lingoda/time';
import { Button, NotificationBar } from '@lingoda/ui';
import { useDispatch, useSelector } from '@lingoda/hooks';
import {
    fetchPaymentMethods,
    paymentMethodsSelector,
    paymentMethodsStoredSelector,
} from '@lingoda/payments';
import { userCurrencySelector } from '@lingoda/currency';
import { isSprintSubscriptionSelector } from '@lingoda/subscriptions';

const diffInDays = diffInUnits('days');

interface Props {
    expiresAt: Date;
}

export const AccountCloseNotificationBar = ({ expiresAt }: Props) => {
    const dispatch = useDispatch();

    const now = useSelector(localRoundHourSelector);
    const countdownDays = diffInDays(expiresAt, now);

    const paymentMethodsStored = useSelector(paymentMethodsStoredSelector);
    const paymentMethods = useSelector(paymentMethodsSelector);
    const isSprinter = useSelector(isSprintSubscriptionSelector);

    const userCurrency = useSelector(userCurrencySelector);

    useEffect(() => {
        if (userCurrency && !paymentMethods.length) {
            dispatch(fetchPaymentMethods(userCurrency));
        }
    }, [userCurrency, dispatch, paymentMethods.length]);

    const canReactivate = paymentMethodsStored.length > 0 && !isSprinter;

    return (
        <NotificationBar
            title={transChoice(
                'account-close-notification-title',
                countdownDays,
                { days: countdownDays },
                'student-common',
            )}
            actions={
                canReactivate
                    ? [
                          <Button
                              color="primary"
                              variant="contained"
                              key="0"
                              size="small"
                              href={subscriptionsStorePath()}
                          >
                              {trans('btn-reactivate-account', {}, 'student-common')}
                          </Button>,
                      ]
                    : []
            }
        />
    );
};

import { Box, Button, useResolution } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import { trans } from '@lingoda/i18n';

interface Props {
    current: number;
    totalSteps: number;
    onClose: () => void;
    onNext: () => void;
}

export const Toolbar = ({ current, totalSteps, onClose, onNext }: Props) => {
    const isPhablet = useResolution('phablet');

    return (
        <>
            <Box textAlign="center" mt={3} py={1.2} color={colorsPalette.blackAlpha[70]}>
                {current + 1} / {totalSteps}
            </Box>
            <Box position="absolute" right={0} bottom={0}>
                {current === totalSteps - 1 ? (
                    <Button color="primary" variant="contained" onClick={onClose}>
                        {isPhablet
                            ? trans('btn-acknowledge', {}, 'public-common')
                            : trans('finish', {}, 'student-onboarding')}
                    </Button>
                ) : (
                    <Button color="primary" variant="contained" onClick={onNext}>
                        {trans('btn-next', {}, 'student-notification')}
                    </Button>
                )}
            </Box>
        </>
    );
};

import { type BookingFilter, isBaseFilterDefaultSelector } from '@lingoda/booking';
import { type FilterProps } from '@lingoda/booking-filter';
import type { TimeFormat } from '@lingoda/dates';
import { useSelector } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { Box } from '@lingoda/ui';
import FilterButton from '@lingoda/ui/buttons/FilterButton';
import { FilterList } from '@lingoda/ui/icons';
import { BookingFilterModal } from '../BookingFilter';

export interface BookingFilterMobileProps extends FilterProps<BookingFilter> {
    onReset: () => void;
    isModalOpen: boolean;
    toggleModal: () => void;
    timeFormat: TimeFormat;
}

export const BookingFilterMobile = ({
    value,
    onChange,
    onReset,
    isModalOpen,
    toggleModal,
    timeFormat,
}: BookingFilterMobileProps) => {
    const isBaseFilterDefault = useSelector(isBaseFilterDefaultSelector);

    return (
        <>
            <Box m={-1} display="inline-flex">
                <FilterButton
                    variant="outlined"
                    onClick={toggleModal}
                    startIcon={<FilterList />}
                    color={isBaseFilterDefault ? 'secondary' : 'primary'}
                >
                    {trans('filter-btn', {}, 'student-common')}
                </FilterButton>
            </Box>
            <BookingFilterModal
                value={value}
                onChange={onChange}
                open={isModalOpen}
                onClose={toggleModal}
                onReset={onReset}
                timeFormat={timeFormat}
            />
        </>
    );
};

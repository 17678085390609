import { useEffect } from 'react';
import useForceRender from './useForceRender';

/**
 * @deprecated Use `useIntervalUpdateState` instead, because it only rerender on state update.
 * Remove when `useIntervalUpdateState` added.
 */
export const useForceRenderInterval = (timeoutMs: number) => {
    const rerender = useForceRender();

    useEffect(() => {
        const timerId = setInterval(() => {
            rerender();
        }, timeoutMs);

        return () => clearTimeout(timerId);
    }, [timeoutMs, rerender]);
};

import { memo } from 'react';
import { Avatar, Box, BoxPerf, Grid, Hidden } from '@lingoda/ui';
import { ClassInfo } from './ClassInfo';
import { TeacherParticipant } from './TeacherParticipant';
import type { AvatarProps, AvatarWithId } from './common';

const stackSpace = -5;

interface Props {
    avatars: AvatarWithId[] | undefined;
    teacher: AvatarProps | undefined;
    classTypeInfo: string;
}

const ClassListItemParticipantsComponent = ({ avatars, classTypeInfo, teacher }: Props) => (
    <>
        <Hidden lgUp>
            <Grid container direction="column" spacing={1}>
                <ClassInfo hasTeacher={!!teacher} classTypeInfo={classTypeInfo} />
            </Grid>
        </Hidden>
        <Grid container direction="row" spacing={1} alignItems="center" flexWrap="nowrap">
            <TeacherParticipant teacher={teacher} />
            <Grid item>
                <Grid container direction="column">
                    <Hidden lgDown>
                        <ClassInfo hasTeacher={!!teacher} classTypeInfo={classTypeInfo} />
                    </Hidden>
                    {avatars && (
                        <Grid item>
                            <BoxPerf>
                                {avatars.map(({ src, id, firstName }, index) => (
                                    <Box key={id} position="relative" left={stackSpace * index - 2}>
                                        <Hidden lgDown>
                                            <Avatar size={22} src={src} borderVariant="stacked">
                                                {firstName}
                                            </Avatar>
                                        </Hidden>
                                        <Hidden lgUp>
                                            <Avatar size={28} src={src} borderVariant="stacked">
                                                {firstName}
                                            </Avatar>
                                        </Hidden>
                                    </Box>
                                ))}
                            </BoxPerf>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </>
);

export const ClassListItemParticipants = memo(ClassListItemParticipantsComponent);

import {
    type ComponentProps,
    type ComponentRef,
    type ElementType,
    type ForwardedRef,
    type ReactNode,
} from 'react';
import { Box, styled } from '@lingoda/ui';
import { ShortcutBadge } from '../ShortcutBadge';
import { useShortcutListener } from './utils/useShortcutListener';

export type ShortcutControlProps<R extends ElementType> = Omit<ComponentProps<R>, 'component'> & {
    component: R;
    // 👇 workaround because `forwardRef` can't return generic typed component
    innerRef?: ForwardedRef<ComponentRef<R>>;
    shortcutKey: string;
    onShortcutKeyUp?: () => void;
    shortcutControls?: 'enabled' | 'hidden' | 'disabled' | 'none';
    badge?: ReactNode;
    badgePos?: BadgePos;
    badgeSx?: ComponentProps<typeof Badge>['sx'];
    size?: ControlSize;
};

const ShortcutControlRoot = <R extends ElementType>(props: ShortcutControlProps<R>) => {
    const {
        component: ComponentRoot,
        innerRef,
        children,
        shortcutKey,
        onShortcutKeyUp,
        shortcutControls,
        badge,
        badgePos = 'start',
        badgeSx,
        ...passProps
    } = props;

    const shortcuts = processShortcutControlsProp(shortcutControls);

    const { actionRef, rippleRef } = useShortcutListener({
        shortcutKey,
        onKeyUp: onShortcutKeyUp,
        disabled: !shortcuts.enabled,
    });

    const badgeNode = shortcuts.visible && (
        <Badge
            aria-hidden
            pos={badgePos}
            sx={badgeSx}
            size={passProps.size}
            className={shortcutControlClasses.badge}
        >
            {badge || shortcutKey}
        </Badge>
    );

    return (
        <ComponentRoot
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(passProps as any)}
            ref={innerRef}
            action={actionRef}
            touchRippleRef={rippleRef}
            aria-keyshortcuts={shortcuts.enabled ? shortcutKey : undefined}
        >
            <Box width="100%" display="flex" alignItems="center">
                {badgePos === 'start' && badgeNode}
                <Label flexGrow={1} component="span">
                    {children}
                </Label>
                {badgePos === 'end' && badgeNode}
            </Box>
        </ComponentRoot>
    );
};

export const ShortcutControl = styled(ShortcutControlRoot)((props) => {
    const { theme, shortcutControls, badgePos = 'start' } = props;

    const paddingX = 2;
    const shortcuts = processShortcutControlsProp(shortcutControls);
    const badgePosition = shortcuts.visible ? badgePos : undefined;

    return {
        fontWeight: 600,
        paddingRight: theme.spacing(paddingX),
        paddingLeft: theme.spacing(paddingX),

        ...(badgePosition === 'start' && {
            paddingRight: theme.spacing(paddingX + BADGE_CFG.minSize + BADGE_CFG.margin),
        }),
        ...(badgePosition === 'end' && {
            paddingLeft: theme.spacing(paddingX + BADGE_CFG.minSize + BADGE_CFG.margin),
        }),
    };
}) as typeof ShortcutControlRoot;
// 👆 required to preserve generic nature of root component

export const processShortcutControlsProp = (shortcutControls = 'disabled') => {
    return {
        enabled: shortcutControls === 'enabled' || shortcutControls === 'hidden',
        visible: shortcutControls === 'enabled' || shortcutControls === 'disabled',
    };
};

const Badge = styled(ShortcutBadge, {
    shouldForwardProp: (name) => name !== 'pos',
})<{ pos: BadgePos; size?: ControlSize }>(({ pos, size = 'medium', theme }) => {
    return {
        flexShrink: 0,
        minWidth: theme.spacing(BADGE_CFG.minSize),
        minHeight: theme.spacing(BADGE_CFG.minSize),

        ...(pos === 'start' && {
            marginRight: theme.spacing(BADGE_CFG.margin),
        }),
        ...(pos === 'end' && {
            marginLeft: theme.spacing(BADGE_CFG.margin),
        }),

        ...(size === 'medium' && {
            marginTop: -4,
            marginBottom: -4,
        }),
        ...(size === 'large' && {
            marginTop: -1,
            marginBottom: -1,
        }),
    };
});

const Label = styled(Box)(() => ({
    display: 'inline-flex',
    justifyContent: 'center',
    wordBreak: 'break-word',
}));

type BadgePos = 'start' | 'end';
type ControlSize = 'small' | 'medium' | 'large';

const BADGE_CFG = {
    minSize: 3.5,
    margin: 1.5,
};

export const shortcutControlClasses = {
    badge: 'ShortcutControl-badge',
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { createDate, isEqual, startOf } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { useToggleState } from '@lingoda/hooks';
import { Box } from '../../layout/Box';
import { styled } from '../../theme';
import { useResolution } from '../../hooks';
import { Popper, type PopperProps } from '../../utils/Popper';
import { ClickAwayListener } from '../../utils/ClickAwayListener';
import { DialogConfirm } from '../../feedback/dialogs';
import { DatePicker, type DatePickerDotColor } from '../DatePicker';
import { CalendarInput } from './CalendarInput';

export { type DatePickerDotColor };

export interface DateInputProps {
    name: string;
    label?: string;
    mobileTitle?: string;
    mobileConfirmLabel?: string;
    format?: string;
    selectedDate?: Date;
    onChange: (value: Date) => void;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    scheduledDays?: Date[];
    loading?: boolean;
    dotColor?: DatePickerDotColor;
    popperMaxWidth?: number;
}

export const DateInput = ({
    name,
    label = trans('date-label', {}, 'public-common'),
    mobileTitle,
    mobileConfirmLabel = trans('done', {}, 'public-common'),
    format: formatStr,
    selectedDate,
    minDate,
    maxDate,
    disabled,
    scheduledDays,
    onChange,
    loading,
    dotColor = 'info',
    popperMaxWidth = 328,
}: DateInputProps) => {
    const isMobile = useResolution('phone');

    const [userDate, setUserDate] = useState<Date | undefined>(selectedDate);
    const [isOpen, openDatePicker, closeDatePicker] = useToggleState(false);
    const [anchorElement, setAnchorElement] = useState<PopperProps['anchorEl'] | null>(null);

    const handleMobileDialogClose = () => {
        if (isMobile && userDate && (!selectedDate || !isEqual(userDate, selectedDate))) {
            onChange(userDate);
        }
        closeDatePicker();
    };

    const showNewDatePicker = () => {
        if (isMobile) {
            const initialDate = selectedDate || minDate || createDate();
            setUserDate(startOf('day', initialDate));
        }
        openDatePicker();
    };

    const toggleDatePicker = () => {
        if (isOpen) {
            closeDatePicker();
        } else {
            showNewDatePicker();
        }
    };

    const handleInputClick = (ev: React.MouseEvent<HTMLDivElement>) => {
        const popperRef = ev.currentTarget as unknown as PopperProps['anchorEl'];
        if (isOpen) {
            setAnchorElement(null);
        } else {
            setAnchorElement(popperRef);
        }
        toggleDatePicker();
    };

    const handleDateChange = useCallback(
        ([newDate]: Date[]) => {
            setUserDate(newDate);
            if (!isMobile) {
                onChange(newDate);
                closeDatePicker();
            }
        },
        [isMobile, closeDatePicker, onChange],
    );

    useEffect(() => {
        if (selectedDate) {
            setUserDate(startOf('day', selectedDate));
        }
    }, [selectedDate]);

    const dates = useMemo(() => (userDate ? [userDate] : []), [userDate]);

    return (
        <Box>
            <CalendarInput
                name={name}
                label={label}
                format={formatStr}
                selectedDate={selectedDate}
                disabled={disabled || loading}
                onClick={handleInputClick}
            />
            {isMobile && (
                <DialogConfirm
                    isOpen={isOpen}
                    onClose={closeDatePicker}
                    onConfirm={handleMobileDialogClose}
                    confirmLabel={mobileConfirmLabel}
                    title={mobileTitle}
                    scrollableContent={false}
                >
                    <DatePicker
                        dates={dates}
                        scheduledDays={scheduledDays}
                        onChange={handleDateChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        loading={loading}
                        dotColor={dotColor}
                    />
                </DialogConfirm>
            )}
            {!isMobile && isOpen && (
                <ClickAwayListener onClickAway={closeDatePicker}>
                    <PopperStyled
                        open={isOpen}
                        placement="bottom-start"
                        anchorEl={anchorElement}
                        maxWidth={popperMaxWidth}
                        modifiers={[
                            { name: 'arrow', enabled: false },
                            { name: 'flip', enabled: true },
                        ]}
                    >
                        <DatePicker
                            dates={dates}
                            scheduledDays={scheduledDays}
                            onChange={handleDateChange}
                            minDate={minDate}
                            maxDate={maxDate}
                            loading={loading}
                            dotColor={dotColor}
                        />
                    </PopperStyled>
                </ClickAwayListener>
            )}
        </Box>
    );
};

interface PopperStyledProps {
    maxWidth: number;
}

const PopperStyled = styled(Popper, {
    shouldForwardProp: (prop) => prop !== 'maxWidth',
})<PopperStyledProps>(({ theme, maxWidth }) => ({
    zIndex: theme.zIndex.tooltip,
    borderRadius: 4,
    boxShadow: theme.shadows[3],
    background: theme.palette.common.white,
    padding: theme.spacing(3, 2, 2),
    maxWidth,
}));

import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useSticky } from '@lingoda/hooks';
import { useTheme } from '@lingoda/ui/hooks';
import { colorsPalette } from '@lingoda/ui/theme';
import { useHeaderContext } from './HeaderContext';

interface Props {
    headerTheme: 'dark' | 'light';
    height?: number;
}

const lightLink = colorsPalette.whiteSolid[100];
const darkLink = colorsPalette.blackSolid[100];
const lightBackground = 'transparent';

export const HeaderContextInitializer = (props: PropsWithChildren<Props>) => {
    const { setHeaderContext } = useHeaderContext();
    const sticky = useSticky();
    const [headerHeight, setHeaderHeight] = useState(0);
    const theme = useTheme();

    const headerTheme = useMemo(() => {
        const darkThemeBackground = props.headerTheme === 'dark' || sticky;
        const darkThemeLink = darkThemeBackground || theme.breakpoints.up('md');

        return {
            linkColor: darkThemeLink ? darkLink : lightLink,
            visibleLinks: darkThemeBackground ? darkLink : lightLink,
            backgroundColor: darkThemeBackground ? lightLink : lightBackground,
            height: props.headerTheme === 'dark' ? headerHeight : 0,
            rawHeight: headerHeight,
            setHeight: setHeaderHeight,
            sticky,
        };
    }, [headerHeight, props.headerTheme, sticky, theme.breakpoints]);

    useEffect(() => {
        setHeaderHeight(props.height || 0);
    }, [props.height]);

    useEffect(() => {
        setHeaderContext(headerTheme);
    }, [headerTheme, setHeaderContext]);

    return <>{props.children}</>;
};

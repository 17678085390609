import { memo } from 'react';
import { trans } from '@lingoda/i18n';
import { BoxPerf, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';

interface Props {
    lessonTitle: string;
    chapterName: string;
    moduleName: string;
    lessonLabel: string;
    classTypeInfo: string;
    hasTeacher: boolean;
}

const ClassTileInfoComponent = ({
    moduleName,
    chapterName,
    lessonTitle,
    lessonLabel,
    classTypeInfo,
    hasTeacher,
}: Props) => {
    const subtitle = [moduleName, chapterName, lessonLabel].filter(Boolean).join(' • ');

    return (
        <BoxPerf className="display-block mb-1">
            <BoxPerf className="display-block mb-2">
                <BoxPerf className="display-block mb-1">
                    <Typography
                        variant="h3"
                        data-cy="classTileInfo_lessonTitle"
                        htmlColor={colorsPalette.blackSolid[100]}
                    >
                        {lessonTitle}
                    </Typography>
                </BoxPerf>
                <Typography
                    variant="overline"
                    data-cy="classTileInfo_subtitle"
                    htmlColor={colorsPalette.blackAlpha[70]}
                >
                    {subtitle}
                </Typography>
            </BoxPerf>
            <Typography
                variant="overline"
                data-cy="classInfo_classTypeInfo"
                htmlColor={colorsPalette.blackAlpha[70]}
            >
                {classTypeInfo}
            </Typography>
            {!hasTeacher && (
                <BoxPerf className="mt-1">
                    <Typography variant="overline" htmlColor={colorsPalette.blackAlpha[70]}>
                        {trans('teacher-will-be-assigned', {}, 'student-common')}
                    </Typography>
                </BoxPerf>
            )}
        </BoxPerf>
    );
};

export const ClassTileInfo = memo(ClassTileInfoComponent);

import { httpDelete, httpGet, httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type { SectionName } from '@lingoda/sections';
import type { PaymentMethodsSchema, PaymentSchema } from './common';

export interface PaymentMethodsResponse {
    data: PaymentMethodsSchema[];
}

interface ConsentData {
    marketing_communication?: boolean;
    privacy_policy: boolean;
}

interface UserAttributes {
    email: string;
    consent?: ConsentData;
}

export interface PaymentApiAttributes {
    paymentMethod: string;
    redirectUrl: string;
    order: string;
    currency: string;
    user?: UserAttributes;
    agreements?: string[];
    nameOnCard?: string;
}

export type UpdatePaymentOrderApiAttributes = Pick<PaymentApiAttributes, 'currency'>;

export interface UpdatePaymentApiAttributes
    extends Pick<PaymentApiAttributes, 'currency' | 'paymentMethod' | 'order'> {
    section: SectionName;
}

export type AddPaymentApiAttributes = Pick<
    PaymentApiAttributes,
    'currency' | 'paymentMethod' | 'nameOnCard'
> &
    Pick<Partial<PaymentApiAttributes>, 'redirectUrl'>;

export type SetDefaultPaymentMethodAttributes = Pick<PaymentApiAttributes, 'paymentMethod'>;

export type PaymentSubscriptionApiAttributes = {
    paymentMethod: string;
    redirectUrl?: string;
    agreements?: string[];
};

export interface PaymentResponse {
    data: PaymentSchema;
}

export interface SuccessPaymentReponse {
    data: Pick<PaymentSchema, 'success'>;
}

export interface StripeResponse {
    redirectUrl: string;
}

export type SubmitPurchasePaymentData = {
    purchaseId: string;
    paymentMethod: string;
    redirectUrl: string;
    agreementsId: string[];
    nameOnCard?: string;
};

export interface SubmitPurchasePaymentResponse {
    success: boolean;
    url: string;
    secret: string;
}

export const getPaymentMethods = (currency: string): Promise<PaymentMethodsResponse> =>
    httpGet(getUrl('api_payments_methods', { currency }));

export const createPaymentMethod = (payload: AddPaymentApiAttributes): Promise<PaymentResponse> =>
    httpPost(getUrl('payment_method_create'), payload);

export const setDefaultPaymentMethod = (
    payload: SetDefaultPaymentMethodAttributes,
): Promise<SuccessPaymentReponse> => httpPost(getUrl('payment_method_change_default'), payload);

export const submitPaymentSubscription = (
    payload: PaymentSubscriptionApiAttributes,
): Promise<PaymentResponse> => httpPost(getUrl('api_payments_subscription_create'), payload);

export const deletePaymentContract = (reference: string): Promise<void> =>
    httpDelete(getUrl('api_payment_contract_delete', { reference }));

export const submitPurchasePayment = (
    payload: SubmitPurchasePaymentData,
): Promise<HttpResponse<SubmitPurchasePaymentResponse, never>> =>
    httpPost(getUrl('api_payments_purchase'), payload);

import type { AuthTokenStatus } from '@lingoda/graphql';

export const wasAnyCalendarEverConnected = (calendarStatuses: (AuthTokenStatus | null)[]) => {
    return calendarStatuses.some(wasCalendarEverConnected);
};

const wasCalendarEverConnected = (status: AuthTokenStatus | null) =>
    status === 'active' || status === 'expired';

export const isAnyCalendarConnected = (calendarStatuses: (AuthTokenStatus | null)[]) => {
    return calendarStatuses.some(isCalendarConnected);
};

const isCalendarConnected = (status: AuthTokenStatus | null) => status === 'active';

import { Box, colorsPalette, styled } from '@lingoda/ui';

export const ShortcutBadge = styled(Box)(({ theme }) => {
    return {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0, 0.625, 0, 0.625),
        border: `1px solid`,
        boxShadow: '0px 1px 0px 0px currentColor',
        backgroundColor: colorsPalette.blackSolid[3],
    };
});

ShortcutBadge.defaultProps = {
    component: 'span',
};

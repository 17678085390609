import { createRecordFactory } from '@lingoda/utils';
import type { AppointmentExpanded } from './appointmentExpanded';
import type { ClassExpanded } from './classExpanded';

export interface AppointmentWithClassExpanded extends AppointmentExpanded {
    class?: ClassExpanded;
}

export const AppointmentWithClassExpandedFactory =
    createRecordFactory<AppointmentWithClassExpanded>({
        class: undefined,
        classId: undefined,
        endDate: undefined,
        feedbackId: undefined,
        needsMissedClassFeedback: undefined,
        id: undefined,
        isActive: undefined,
        isCancellable: undefined,
        isCompleted: undefined,
        moduleId: undefined,
        startDate: undefined,
        createdAt: undefined,
        status: undefined,
        statusByStudent: undefined,
        studentId: undefined,
    });

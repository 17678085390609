import IconButton from '../../buttons/IconButton';
import { CloseIcon16 } from '../../icons/Close';
import { Box, type BoxProps, Typography } from '../../layout';

export interface SlidePopupHeaderProps {
    onClose: () => void;
    title: string;
    sx?: BoxProps['sx'];
}

export const SlidePopupHeader = ({ title, onClose, sx }: SlidePopupHeaderProps) => {
    return (
        <Box sx={sx}>
            <Typography variant="subtitle1" pr={3}>
                {title}
            </Typography>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: (theme) => theme.spacing(1),
                    right: (theme) => theme.spacing(1),
                }}
                onClick={onClose}
                aria-label="Close popup"
                role="button"
            >
                <CloseIcon16 />
            </IconButton>
        </Box>
    );
};

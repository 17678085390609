import type { LearningUnitSchema } from '@lingoda/api';
import { createRecordFactory } from '@lingoda/utils';

export type LearningUnit = LearningUnitSchema;

export const LearningUnitFactory = createRecordFactory<LearningUnit>({
    id: undefined,
    icon: undefined,
    name: undefined,
    color: undefined,
    moduleId: undefined,
    sectionId: undefined,
    lessonIds: [],
    position: undefined,
    chapter: undefined,
});

import { parse } from 'qs';
import type { IParseOptions, ParsedQs } from 'qs';

export type ParsedQsWithKnownKeys<T extends object = Record<string, unknown>> = Record<
    keyof T,
    ParsedQs['key']
>;

export const getQueryParams = <T extends object = Record<string, unknown>>(
    locationSearch: string,
    options?: IParseOptions,
) => parse(locationSearch, { ignoreQueryPrefix: true, ...options }) as ParsedQsWithKnownKeys<T>;

import { Translate, trans } from '@lingoda/i18n';
import { ShortcutBadge } from '@lingoda/quiz-ui';
import { Typography, colorsPalette } from '@lingoda/ui';

export const FlipShortcutTip = () => {
    return (
        <Typography htmlColor={colorsPalette.blackAlpha[70]} mt={4}>
            <Translate
                id="click-card-to-flip"
                params={{
                    shortcutBadge: (
                        <ShortcutBadge color={colorsPalette.blackSolid[20]}>
                            {trans('shortcut-spacebar', {}, 'student-flashcard')}
                        </ShortcutBadge>
                    ),
                }}
                domain="student-flashcard"
            />
        </Typography>
    );
};

import { colorsPalette, styled } from '@lingoda/ui/theme';

// This is wrapper to format the revocation form, which is a marked element included the translated text of the Terms and Revocation dialogs
export const RevocationFormWrapper = styled('div')({
    ['#revocation-form']: {
        border: `1px solid ${colorsPalette.blackSolid[100]}`,
        padding: '1rem',
    },
    // To ensure that the ___ lines are seamlessly cut off if there isn't enough width:
    ['#revocation-form > #revocation-inner']: {
        overflow: 'hidden',
    },
});

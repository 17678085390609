import { useOrientationLessonQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { StudentStatus } from '@lingoda/lessons';
import { studentPreferencesSelector } from '@lingoda/students';
import { useOrientationClassEnabled } from '../useOrientationClassEnabled';

export const shouldShowOrientationClassBanner = (
    isStudentInOcModule: boolean | undefined,
    showOrientationClassBannerUserPreferences: boolean | undefined,
    orientationLessonStudentStatus: string | undefined,
) =>
    !!orientationLessonStudentStatus &&
    orientationLessonStudentStatus !== StudentStatus.Skipped &&
    !!isStudentInOcModule &&
    (showOrientationClassBannerUserPreferences ?? true);

export const useShouldShowOrientationClassBanner = () => {
    const preferences = useSelector(studentPreferencesSelector);
    const { isOrientationClassEnabled } = useOrientationClassEnabled();
    const { data } = useOrientationLessonQuery();

    return shouldShowOrientationClassBanner(
        isOrientationClassEnabled,
        preferences?.showOrientationClassBanner,
        data?.orientationLesson?.studentStatus,
    );
};

import { SnackbarProvider } from '@lingoda/ui';
import { toastsVariantsMap } from '@lingoda/toasts';
import { BookingFeedbackPopup } from '@lingoda/student-booking';
import {
    PersonalFeedbackSatisfactionPopup,
    PracticeSatisfactionSurveyPopup,
} from '@lingoda/learning';
import { AchievementSnackbarAlert } from '@lingoda/student-achievements';

export const StudentSnackbarProvider = ({ children }: React.PropsWithChildren<unknown>) => {
    return (
        <SnackbarProvider maxSnack={3} Components={variantsMap}>
            {children}
        </SnackbarProvider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const variantsMap: Record<string, React.JSXElementConstructor<any>> = {
    ...toastsVariantsMap,
    bookingFeedback: BookingFeedbackPopup,
    personalFeedbackSatisfaction: PersonalFeedbackSatisfactionPopup,
    practiceSatisfaction: PracticeSatisfactionSurveyPopup,
    achievement: AchievementSnackbarAlert,
};

import { UserDisplayedWidgetClassEnum } from '@lingoda/graphql';
import { AnnouncementId } from '@lingoda/students';

export const mapAnnouncementIdToWidgetClass = (id: AnnouncementId) => {
    const map: { [key in AnnouncementId]: UserDisplayedWidgetClassEnum | null } = {
        [AnnouncementId.lingobitesBanner]: UserDisplayedWidgetClassEnum.LingobitesBanner,
    };

    return map[id] ?? null;
};

import { Form, TextField, addFormErrors, withForm } from '@lingoda/forms';
import { Button } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { trans } from '@lingoda/i18n';
import type { FormErrors, FormProps } from '@lingoda/forms';
import { validate } from './schema';
import type * as React from 'react';

interface OwnProps {
    onSubmit: ({ plainPassword }: NewPasswordFormValues) => Promise<unknown>;
}

export interface NewPasswordFormValues {
    plainPassword: string;
    plainPasswordRe: string;
}

type Props = OwnProps & FormProps<NewPasswordFormValues>;

const NewPasswordFormComponent: React.FC<Props> = ({ isSubmitting }) => (
    <Form noValidate>
        <Grid container spacing={2} direction="column">
            <Grid>
                <TextField
                    name="plainPassword"
                    type="password"
                    label={trans('new-password-label', {}, 'student-common')}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid>
                <TextField
                    name="plainPasswordRe"
                    type="password"
                    label={trans('repeat-new-password-label', {}, 'student-common')}
                    fullWidth
                    variant="outlined"
                />
            </Grid>
            <Grid marginTop={2}>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                    disabled={isSubmitting}
                >
                    {trans('btn-save', {}, 'public-common')}
                </Button>
            </Grid>
        </Grid>
    </Form>
);

export const NewPasswordForm = withForm<OwnProps, NewPasswordFormValues>({
    handleSubmit: (values, formikBag) => {
        formikBag.props.onSubmit(values).catch((errors: FormErrors<NewPasswordFormValues>) => {
            addFormErrors(errors, formikBag);
            formikBag.setSubmitting(false);
        });
    },
    validate,
    mapPropsToValues: () => ({
        plainPassword: '',
        plainPasswordRe: '',
    }),
})(NewPasswordFormComponent);

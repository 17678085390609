import { type MouseEvent } from 'react';
import { type Achievement } from '@lingoda/graphql';
import { Alert, type AlertProps, Box, colorsPalette } from '@lingoda/ui';
import { ToastAlert } from '@lingoda/toasts';
import { AchievementSlug } from '../../models';
import { getIsClaimable } from '../../utils/getIsClaimable';
import { AchievementBadge } from '../AchievementBadge';
import { ContentClaimed } from './ContentClaimed';
import { ContentProgress } from './ContentProgress';
import { Header } from './Header';

interface Props extends Omit<AlertProps, 'severity' | 'variant' | 'onClose'> {
    achievement: Achievement;
    isClaiming: boolean;
    loading?: boolean;
    onClaiming: (event: MouseEvent) => void;
    onClose?: () => void;
    variant?: AlertProps['variant'] | 'toast';
}

export const Layout = ({
    achievement,
    isClaiming,
    loading,
    onClaiming,
    onClose,
    title,
    ...props
}: Props) => {
    const { achieved, currentProgress, value, name, claimed, progressLabel, achievedLabel } =
        achievement;

    const isClaimable = getIsClaimable(achievement);
    const label = achieved ? achievedLabel : progressLabel;

    const { Component, variant, dataCy } =
        props.variant === 'toast'
            ? { Component: ToastAlert, variant: undefined, dataCy: 'achievement-toast' }
            : { Component: Alert, variant: props.variant, dataCy: 'achievement-alert' };

    return (
        <Component
            {...props}
            variant={variant}
            severity="promotion"
            icon={false}
            sx={{ '& .MuiAlert-message': { width: '100%' } }}
            data-cy={dataCy}
        >
            {title && <Header onClose={onClose} title={title} />}
            <Box
                sx={({ spacing }) => ({
                    border: `1px solid ${colorsPalette.purpleSolid[12]}`,
                    borderRadius: spacing(1.5),
                    marginTop: 2,
                    padding: 1.5,
                })}
            >
                <Box display="flex" width="100%">
                    <Box sx={{ marginRight: 1.5 }}>
                        <AchievementBadge
                            badgeSlug={AchievementSlug.ONBOARDING_CHECKLIST}
                            active={claimed}
                            name={name}
                            width={56}
                            height={56}
                            loading={loading}
                        />
                    </Box>
                    {claimed ? (
                        <ContentClaimed label={label} name={name} />
                    ) : (
                        <ContentProgress
                            currentProgress={currentProgress}
                            isClaimable={isClaimable}
                            isClaiming={isClaiming}
                            label={label}
                            onClaiming={onClaiming}
                            value={value}
                        />
                    )}
                </Box>
            </Box>
        </Component>
    );
};

import { getProductVariants } from '@lingoda/api';
import { addTrackerCallback } from '@lingoda/core';
import { addModules } from '@lingoda/modules';
import { addSections } from '@lingoda/sections';
import { addProductVariants, addProducts, fetchProductVariants } from './actions';

export default () => {
    addTrackerCallback(fetchProductVariants, ({ payload }, store) =>
        getProductVariants(payload).then(({ data, included }) => {
            if (included) {
                if (included.products) {
                    store.dispatch(addProducts(included.products));
                }

                if (included.modules) {
                    store.dispatch(addModules(included.modules));
                }

                if (included.sections) {
                    store.dispatch(addSections(included.sections));
                }
            }
            store.dispatch(addProductVariants(data));

            return data;
        }),
    );
};

import type { ClassType } from '@lingoda/graphql';
import { createRecordFactory } from '@lingoda/utils';

export type SectionClassLeadTimeSchema = GenericKeyObject<ClassType, string>;

export type SectionDateLeadTimes = GenericKeyObject<ClassType, string>;

export const SectionDateLeadTimesFactory = createRecordFactory<SectionClassLeadTimeSchema>({
    trial: undefined,
    group: undefined,
    individual: undefined,
});

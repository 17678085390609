import { type Language, LanguageFluency } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';

export const getTranslatedTeacherLanguages = (spokenLanguages?: ReadonlyArray<Language>) => {
    return spokenLanguages
        ?.map(({ language, fluency }) => {
            const languageLabel = trans(/* @ignore */ `i-speak-${language}`, {}, 'student-common');

            return `${languageLabel} - ${getFluencyLabel(fluency)}`;
        })
        .join(', ');
};

const getFluencyLabel = (fluency: LanguageFluency) => {
    return fluency === LanguageFluency.Native ? trans('native', {}, 'student-common') : fluency;
};

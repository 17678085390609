import type { StudentId } from '@lingoda/students';
import type { AppointmentId, ClassId } from '../constants';

export const resolveAppointmentId = (
    id: AppointmentId,
): { classId: ClassId; studentId: StudentId } => {
    const [classId, studentId] = id.split(':');

    return {
        classId: classId,
        studentId: parseInt(studentId, 10),
    };
};

export const composeAppointmentId = (classId: ClassId, studentId: StudentId): AppointmentId => {
    return `${classId}:${studentId}`;
};

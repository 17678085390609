import { Dialog } from '@lingoda/ui';
import { useStepper } from '@lingoda/hooks';
import { BasicInfoStep } from './components/BasecInfoStep/BasicInfoStep';
import { SelectionStep } from './components/SelectionStep/SelectionStep';

interface PauseSprintRolloverSubscriptionDialogProps {
    onClose: () => void;
    checkClassesDuringPause: (endDate: Date) => boolean;
    handleConfirmPause: (pauseEndDate: Date) => void;
    pauseStartDate: Date;
}

export const PauseSprintRolloverSubscriptionDialog = ({
    onClose,
    checkClassesDuringPause,
    handleConfirmPause,
    pauseStartDate,
}: PauseSprintRolloverSubscriptionDialogProps) => {
    const { currentStep, next, previous } = useStepper({ steps: 2 });

    const steps = [
        <BasicInfoStep
            key="basicInfoStep"
            onClose={onClose}
            onDismiss={onClose}
            onConfirm={next}
        />,
        <SelectionStep
            key="selectionStep"
            onClose={onClose}
            onDismiss={previous}
            onConfirm={handleConfirmPause}
            pauseStartDate={pauseStartDate}
            checkClassesDuringPause={checkClassesDuringPause}
        />,
    ];

    return (
        <Dialog onClose={onClose} maxWidth="sm" fullWidth isOpen>
            {steps[currentStep]}
        </Dialog>
    );
};

import { type CurriculumId, type SectionName } from '@lingoda/sections';
import { type ResourceOwnersSchema } from '@lingoda/api';
import { CurvedLineStyled, Legal, RegisterFormWrapperStyled } from '../../common';
import { SocialOptions } from './SocialOptions';
import { Login } from './Login';
import Form from './Form';
import type { FormValues } from './Form';

interface Props {
    curriculumId?: CurriculumId;
    disabled: boolean;
    handleClick: () => void;
    handleSubmit: (values: FormValues) => Promise<unknown>;
    resourceOwners?: ResourceOwnersSchema[];
    section: SectionName;
}

export const FormContainer = ({
    curriculumId,
    disabled,
    handleClick,
    handleSubmit,
    resourceOwners,
    section,
}: Props) => {
    return (
        <RegisterFormWrapperStyled>
            <CurvedLineStyled />
            <Form
                curriculumId={curriculumId}
                disabled={disabled}
                onSubmit={handleSubmit}
                section={section}
            />
            <Login />
            <SocialOptions
                disabled={disabled}
                handleClick={handleClick}
                resourceOwners={resourceOwners}
            />
            <Legal />
        </RegisterFormWrapperStyled>
    );
};

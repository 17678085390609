import { Skeleton, Stack } from '@lingoda/ui';
import { GRAPH_HEIGHT } from './Chart';

export const ChartSkeleton = () => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end"
            height={GRAPH_HEIGHT}
        >
            {[2, 4, 3, 5].map((id) => (
                <Skeleton
                    key={id}
                    variant="rectangular"
                    width={24}
                    height={id * 20}
                    sx={{ marginLeft: 1, marginRight: 1 }}
                />
            ))}
        </Stack>
    );
};

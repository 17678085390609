import { objectDiff } from '@lingoda/utils';
import { getValidDatesFromTimeSlots } from './getValidDatesFromTimeSlots';
import type { BookingFilter } from '../models';

export const getValidBookingFilter = (bookingFilter: BookingFilter): BookingFilter => {
    const validDates = getValidDatesFromTimeSlots(bookingFilter.dates, bookingFilter.timeSlots);

    return {
        ...bookingFilter,
        dates: validDates,
    };
};

export const isBookingFilterValid = (bookingFilter: BookingFilter): boolean => {
    const validatedFilter = getValidBookingFilter(bookingFilter);
    const filterDiff = objectDiff(bookingFilter, validatedFilter);

    return !filterDiff;
};

import { getLearningUnits } from '@lingoda/api';
import { addTrackerCallback } from '@lingoda/core';
import { studentIdSelector } from '@lingoda/students';
import { addLearningUnits, fetchLearningUnits } from './actions';

export default () => {
    addTrackerCallback(fetchLearningUnits, async (action, store) => {
        const studentId = studentIdSelector(store.getState());

        if (!studentId) {
            return;
        }

        const result = await getLearningUnits(studentId, action.payload);

        store.dispatch(addLearningUnits(result.data));

        return result;
    });
};

import { DOMEvent, Span, styled } from '@lingoda/ui';
import { getUrlLang } from '@lingoda/env';
import { Translate } from '@lingoda/i18n';
import { useToggleState } from '@lingoda/hooks';
import { PrivacyDialog, RevocationDialog, TermsDialog } from '../dialogs';

export const TermsAndPrivacy = () => {
    const [openPrivacy, handleOpenPrivacy, handleClosePrivacy] = useToggleState(false);
    const [openTerms, handleOpenTerms, handleCloseTerms] = useToggleState(false);
    const [openRevocation, handleCloseRevocation] = useToggleState(false);

    const termsLinkCallback = (e: Event) => {
        e.preventDefault();
        handleOpenTerms();
    };

    const privacyLinkCallback = (e: Event) => {
        e.preventDefault();
        handleOpenPrivacy();
    };

    return (
        <LinksSpan variant="body1">
            <DOMEvent
                events={[
                    {
                        selector: '[rel="termsLink"]',
                        eventType: 'click',
                        callback: termsLinkCallback,
                    },
                    {
                        selector: '[rel="privacyLink"]',
                        eventType: 'click',
                        callback: privacyLinkCallback,
                    },
                ]}
            >
                <Translate
                    id="form-label-signup-terms-and-conditions"
                    params={{
                        imprintHref: `https://www.lingoda.com/${getUrlLang(
                            window.location.href,
                        )}/imprint/`,
                    }}
                    domain="public-common"
                />
            </DOMEvent>
            <TermsDialog isOpen={openTerms} onClose={handleCloseTerms} />
            <PrivacyDialog isOpen={openPrivacy} onClose={handleClosePrivacy} />
            <RevocationDialog isOpen={openRevocation} onClose={handleCloseRevocation} />
        </LinksSpan>
    );
};

const LinksSpan = styled(Span)({
    a: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

import { type PropsWithChildren, StrictMode, useMemo } from 'react';
import ContextProviders from 'components/ContextProviders';
import ErrorBoundary from 'components/ErrorBoundary';
import { type History, Router, goTo, logoutPath, renderRoutes } from '@lingoda/router';
import { GraphQLProvider, getGraphQlClient } from '@lingoda/graphql-client';
import { withProfiler } from '@lingoda/monitor';
import { Suspense } from '@lingoda/ui';
import { getDashLang } from '@lingoda/env';
import routes from './routes';

interface Props {
    history: History;
}

const Root = (props: PropsWithChildren<Props>) => {
    const gqlClient = useMemo(
        () =>
            getGraphQlClient({
                onUnauthorized: () => goTo(logoutPath()),
            }),
        [],
    );

    return (
        <StrictMode>
            <GraphQLProvider client={gqlClient}>
                <ErrorBoundary>
                    <Router history={props.history}>
                        <ContextProviders locale={getDashLang()}>
                            <Suspense variant="linear">{renderRoutes(routes)}</Suspense>
                        </ContextProviders>
                    </Router>
                </ErrorBoundary>
            </GraphQLProvider>
        </StrictMode>
    );
};

export default withProfiler(Root, { name: 'StudentRoot' });

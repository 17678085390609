import { type Reference, type TypePolicy } from '@apollo/client';
import { ExerciseType } from '@lingoda/graphql';

const PracticeItem: TypePolicy = {
    keyFields: ['lesson', ['id']],
    fields: {
        quiz: (_, { readField }) =>
            readField<Reference[]>('exercises')?.find(
                (item) => readField('type', item) === ExerciseType.Quiz,
            ) || null,

        homeworks: (_, { readField }) =>
            readField<Reference[]>('exercises')?.filter(
                (item) => readField('type', item) === ExerciseType.Homework,
            ) || [],
        pendingHomeworks: (_, { readField }) =>
            readField<Reference[]>('homeworks')?.filter(
                (item) => readField('score', item) === null,
            ),
        homeworksScore: (_, { readField }) => {
            const allCount = readField<Reference[]>('homeworks')?.length || 0;
            const incompleteCount = readField<Reference[]>('pendingHomeworks')?.length || 0;

            return allCount ? (allCount - incompleteCount) / allCount : 0;
        },

        isCompleted: (_, { readField }) =>
            readField<Reference[]>('exercises')?.every((item) => readField('score', item) !== null),
    },
};

export default PracticeItem;

import { forwardRef, useMemo } from 'react';
import { colorsPalette, styled } from '../../theme';
import { Box } from '../Box';
import CircularProgress from '../../feedback/CircularProgress';
import { Tooltip } from '../Tooltip';
import { Checkmark, Checkmark16 } from '../../icons/lingodaIcons';
import { Fade } from '../../transitions';
import { useShowCheckmark } from './useShowCheckmark';
import type { ReactElement } from 'react';

export interface Props {
    /**
     * Current value
     */
    completed: number;
    /**
     * Max value
     */
    total: number;
    /**
     * Tooltip content.
     * If `undefined` `Tooltip` component will be not rendered
     */
    tooltipTitle?: string;
    /**
     * The size of the widget
     * @default sm
     */
    size?: 'xs' | 'sm' | 'md';
    /**
     * The widget label type
     * @default ratio
     */
    labelType?: 'percentage' | 'ratio' | 'children';
    /**
     * Should we show checkmark when progress is full
     * @default false
     */
    checkmarkOnFull?: boolean;
    variant?: 'classic' | 'modern' | 'bright' | 'positive';
}

export const ProgressCircle = forwardRef<HTMLElement, React.PropsWithChildren<Props>>(
    (
        {
            tooltipTitle,
            completed,
            total,
            labelType = 'children',
            size = 'sm',
            checkmarkOnFull = false,
            variant = 'classic',
            children,
            // passing props from Tooltip to root element
            ...props
        },
        ref,
    ) => {
        const processValue = (completed * 100) / total;
        const isFullyCompleted = completed === total;
        const shouldShowCheckmark = isFullyCompleted && checkmarkOnFull;

        const processTitle = useMemo(() => {
            switch (labelType) {
                case 'ratio':
                    return `${completed}/${total}`;
                case 'percentage':
                    return `${Math.floor(processValue)}%`;
                default:
                    return '';
            }
        }, [labelType, completed, total, processValue]);

        const { showCheckmark, showAnimation } = useShowCheckmark(shouldShowCheckmark);

        const showTooltip = !!tooltipTitle;
        const showProgressTitle = !!processTitle && size !== 'xs' && !showCheckmark;
        const thickness = thicknessMap[variant];
        const CheckIcon = size === 'xs' ? StyledCheck16Icon : StyledCheck24Icon;
        const StyledCircularProgress = circularProgressMap[variant];

        return (
            <TooltipWrapper tooltipTitle={tooltipTitle}>
                <StyledWrapper
                    {...props}
                    ref={ref}
                    className={classes[size]}
                    showTooltip={showTooltip}
                >
                    {showCheckmark ? (
                        <StyledCheckIconStaticWrapper className={classes[size]}>
                            <Fade in appear={showAnimation} timeout={{ enter: 100 }}>
                                <StyledCheckIconWrapper
                                    className={
                                        showAnimation
                                            ? `checkmark-animated ${classes[size]}`
                                            : classes[size]
                                    }
                                >
                                    <CheckIcon />
                                </StyledCheckIconWrapper>
                            </Fade>
                        </StyledCheckIconStaticWrapper>
                    ) : (
                        <StyledCircularProgress
                            variant="determinate"
                            color={isFullyCompleted ? 'primary' : 'secondary'}
                            value={Math.ceil(processValue)}
                            size="100%"
                            thickness={thickness}
                            boxSize={size}
                        />
                    )}
                    {showProgressTitle && <ContentWrapper>{processTitle}</ContentWrapper>}
                    {children && <ContentWrapper>{children}</ContentWrapper>}
                </StyledWrapper>
            </TooltipWrapper>
        );
    },
);

ProgressCircle.displayName = 'ProgressCircle';

const TooltipWrapper = ({
    tooltipTitle,
    children,
}: Pick<Props, 'tooltipTitle'> & { children: ReactElement }) =>
    tooltipTitle ? (
        <Tooltip title={tooltipTitle} display="inline-flex">
            {children}
        </Tooltip>
    ) : (
        children
    );

const PREFIX = 'ProgressCircle';
const classes = {
    sm: `${PREFIX}-sm`,
    md: `${PREFIX}-md`,
    xs: `${PREFIX}-xs`,
};

type CircularProgressCustomProps = { boxSize: NonNullable<Props['size']> };
const shouldForwardProp = (prop: string) => prop !== 'boxSize';

const StyledCircularProgressClassic = styled(CircularProgress, {
    shouldForwardProp,
})<CircularProgressCustomProps>(({ thickness = 0, boxSize }) => ({
    '&.MuiCircularProgress-colorPrimary': {
        background: colorsPalette.blackAlpha[4],
        borderRadius: '50%',
    },
    '&.MuiCircularProgress-colorSecondary': {
        borderRadius: '50%',
        background: colorsPalette.blackAlpha[4],
        boxShadow: `inset 0px 0px 0px ${getBorderThickness(thickness, boxSize)}px ${
            colorsPalette.blackAlpha[4]
        }`,
    },
}));

const StyledCircularProgressModern = styled(CircularProgress, {
    shouldForwardProp,
})<CircularProgressCustomProps>(({ thickness = 0, boxSize }) => ({
    '&.MuiCircularProgress-colorPrimary': {
        borderRadius: '50%',
        color: colorsPalette.positiveCustom.dark,
        boxShadow: `inset 0px 0px 0px ${getBorderThickness(thickness, boxSize)}px ${
            colorsPalette.blackAlpha[16]
        }`,
    },
    '&.MuiCircularProgress-colorSecondary': {
        borderRadius: '50%',
        color: colorsPalette.positiveCustom.dark,
        boxShadow: `inset 0px 0px 0px ${getBorderThickness(thickness, boxSize)}px ${
            colorsPalette.blackAlpha[16]
        }`,
    },
}));

const StyledCircularProgressBright = styled(CircularProgress, {
    shouldForwardProp,
})<CircularProgressCustomProps>(({ thickness = 0, boxSize }) => ({
    borderRadius: '50%',
    color: '#7E0EEC',
    boxShadow: `inset 0px 0px 0px ${getBorderThickness(thickness, boxSize)}px ${
        colorsPalette.blackAlpha[8]
    }`,

    circle: {
        strokeLinecap: 'round',
    },
}));

const StyledCircularProgressPositive = styled(StyledCircularProgressBright, {
    shouldForwardProp,
})<CircularProgressCustomProps>(() => ({
    color: colorsPalette.positiveCustom.dark,
}));

const getBorderThickness = (thickness: number, boxSize: CircularProgressCustomProps['boxSize']) => {
    switch (boxSize) {
        case 'xs':
            return { 2: 1.1, 3: 1.6, 5: 2.8 }[thickness] ?? 0;
        case 'sm':
            return thickness;
        case 'md':
            return { 2: 3.3, 3: 5, 5: 6.5 }[thickness] ?? 0;
    }
};

const StyledSizes = styled(Box)(({ theme }) => ({
    [`&.${classes.xs}`]: {
        width: 24,
        height: 24,
    },
    [`&.${classes.sm}`]: {
        width: 44,
        height: 44,
        ...theme.typography.body3,
    },
    [`&.${classes.md}`]: {
        width: 56,
        height: 56,
        ...theme.typography.body3,
    },
}));

const StyledWrapper = styled(StyledSizes, { shouldForwardProp: (prop) => prop !== 'showTooltip' })<{
    showTooltip: boolean;
}>(({ showTooltip }) => ({
    position: 'relative',
    borderRadius: '50%',
    display: 'inline-flex',
    ...(showTooltip && { cursor: 'pointer' }),
}));

const ContentWrapper = styled(Box)(() => ({
    borderRadius: '50%',
    position: 'absolute',
    inset: 0,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledCheck16Icon = styled(Checkmark16)({
    color: colorsPalette.whiteSolid[100],
});

const StyledCheck24Icon = styled(Checkmark)({
    color: colorsPalette.whiteSolid[100],
});

const StyledCheckIconStaticWrapper = styled(StyledSizes)({
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: `2px solid ${colorsPalette.positiveCustom.dark}`,
});

const StyledCheckIconWrapper = styled(StyledSizes)({
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colorsPalette.positiveCustom.dark,
    borderRadius: '50%',
    position: 'absolute',

    '&.checkmark-animated': {
        animation: 'spin 1.1s ease-in-out',

        '@keyframes spin': {
            '0%': {
                transform: 'rotate(-75deg) scale(0.3)',
            },
            '18%': {
                transform: 'rotate(30deg) scale(1.4)',
            },
            '37%': {
                transform: 'rotate(-10deg) scale(0.87)',
            },
            '55%': {
                transform: 'rotate(3deg) scale(1.05)',
            },
            '75%': {
                transform: 'rotate(1deg) scale(0.98)',
            },
            '100%': {
                transform: 'rotate(0deg) scale(1)',
            },
        },
    },
});

const thicknessMap = {
    classic: 2,
    modern: 3,
    bright: 5,
    positive: 5,
} as const;

const circularProgressMap = {
    classic: StyledCircularProgressClassic,
    modern: StyledCircularProgressModern,
    bright: StyledCircularProgressBright,
    positive: StyledCircularProgressPositive,
} as const;

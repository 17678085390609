import type { StudentId } from '@lingoda/students';
import { AppointmentStatus } from '../models';
import type { AppointmentExpanded } from '../models';

// NOTE: for GraphQL classes use @lingoda/classes/utils/isClassBooked instead
export const isStudentAppointmentActive = (
    appointment: AppointmentExpanded,
    studentId: StudentId,
) =>
    appointment.studentId === studentId &&
    appointment.status !== AppointmentStatus.CANCELLED &&
    appointment.status !== AppointmentStatus.DELETED;

import type { SetDefaultPaymentMethodAttributes } from '@lingoda/api';
import { createAction, makeActionTrackable } from '@lingoda/core';

interface Props {
    currency: string;
}

export default makeActionTrackable(
    createAction<SetDefaultPaymentMethodAttributes & Props>('payment/SET_DEFAULT_PAYMENT_METHOD'),
);

import { v4 as uuidV4 } from 'uuid';
import { makeVar, useReactiveVar } from '@apollo/client';
import { type Popup, type PopupVariant } from './types';

const popupsVar = makeVar<ReadonlyArray<Popup>>([]);

export const usePopups = () => {
    const popups = useReactiveVar(popupsVar);

    return { popups };
};

export const showPopup = (variant: PopupVariant) => {
    const newPopup = createPopup(variant);

    popupsVar([...popupsVar(), newPopup]);

    return newPopup;
};

export const hidePopup = (popup: Popup) => {
    popupsVar(popupsVar().filter((item) => item.id !== popup.id));
};

export const markPopupAsDisplayed = (popup: Popup) => {
    const updatedPopups = popupsVar().map((item) =>
        item.id === popup.id ? { ...item, status: 'displayed' as const } : item,
    );
    popupsVar(updatedPopups);
};

const createPopup = (variant: PopupVariant): Readonly<Popup> => {
    return {
        id: uuidV4(),
        variant,
        status: 'pending',
    };
};

import { injectAsyncReducer } from '@lingoda/core';
import { NOTIFICATIONS_STORE_KEY, SHOW_NOTIFICATIONS_STORE_KEY } from './constants';
import notificationReducers from './reducers/notificationReducers';
import showNotificationReducers from './reducers/showNotificationReducers';
import initSideEffects from './side-effects';

export * from './actions';
export * from './constants';
export * from './models';
export * from './selectors';
export * from './utils';
export * from './types';
export * from './hooks';

injectAsyncReducer(NOTIFICATIONS_STORE_KEY, notificationReducers);
injectAsyncReducer(SHOW_NOTIFICATIONS_STORE_KEY, showNotificationReducers);

initSideEffects();

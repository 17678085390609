import { Typography } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { trans } from '@lingoda/i18n';
import { NewPasswordForm, type NewPasswordFormValues } from '../../forms';

interface InitialStepProps {
    email: string;
    onFormSubmit: (values: NewPasswordFormValues) => Promise<unknown>;
    title: string;
}

export const InitialStep = ({ email, onFormSubmit, title }: InitialStepProps) => {
    return (
        <Grid container rowSpacing={4}>
            <Grid xs={12}>
                <Typography variant="h2" marginBottom={2}>
                    {title}
                </Typography>
                <Typography>{trans('user-email', { email }, 'public-common')}</Typography>
            </Grid>
            <Grid xs={12} sx={{ padding: 0 }}>
                <NewPasswordForm onSubmit={onFormSubmit} />
            </Grid>
        </Grid>
    );
};

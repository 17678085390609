import { Avatar, Box, Typography } from '@lingoda/ui';
import { PersonGroupIcon24 } from '@lingoda/ui/icons/PersonGroup';
import { trans } from '@lingoda/i18n';
import type { ClassDetailsTileClassFragment } from '@lingoda/graphql';

type Participant = ClassDetailsTileClassFragment['students'][number];

interface ParticipantsProps {
    isBeforeClass?: boolean;
    participants: readonly Participant[];
}

export const Participants = ({ isBeforeClass = true, participants }: ParticipantsProps) => (
    <Box marginTop={3} flex={1} display="flex" alignItems="flex-start" flexDirection="row">
        <Box display="inline-flex" flexShrink={0} width={({ spacing }) => spacing(6)} pt={0.25}>
            <PersonGroupIcon24 />
        </Box>
        <Box display="flex" flexGrow={1} flexWrap="wrap" alignItems="center" my={-0.75}>
            <Box display="flex" flexGrow={1} py={0.75} mr={2}>
                <Typography variant="body1">
                    {getMessage(isBeforeClass, participants.length)}
                </Typography>
            </Box>
            <Box display="flex" py={0.75}>
                {participants?.map(({ id, shortName, photo }, index, array) => {
                    const notLast = index < array.length - 1;

                    return (
                        <Box
                            key={id}
                            mr={{
                                xs: notLast ? -0.75 : undefined,
                                sm: notLast ? -0.5 : undefined,
                            }}
                        >
                            <Avatar size={28} src={photo?.urls.thumbnail} borderVariant="stacked">
                                {shortName}
                            </Avatar>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    </Box>
);

const getMessage = (isBeforeClass: boolean, participantsAmount: number) => {
    if (isBeforeClass) {
        return participantsAmount === 1
            ? trans(
                  'participants-amount-singular',
                  { amount: participantsAmount },
                  'student-booking',
              )
            : trans(
                  'participants-amount-plural',
                  { amount: participantsAmount },
                  'student-booking',
              );
    }

    return participantsAmount === 1
        ? trans('participants-amount-singular', { amount: participantsAmount }, 'student-booking')
        : trans(
              'post-class-participants-amount',
              { amount: (participantsAmount || 1) - 1 },
              'student-booking',
          );
};

import type { ClassesClassSchema, IncludedResponseAttributes } from '@lingoda/api';
import { createAction, makeActionTrackable } from '@lingoda/core';
import type { Class } from '../models';

export default makeActionTrackable(
    createAction<{
        wrappedClassItems: Class[];
        classItems: ClassesClassSchema[];
        included: IncludedResponseAttributes;
    }>('class/HANDLE_CLASSES_RESPONSE'),
);

import { useEffect, useState } from 'react';
import {
    type InAppMessage,
    changeUser,
    getUser,
    initialize,
    openSession,
    showInAppMessage,
    subscribeToInAppMessage,
    wipeData,
} from '@braze/web-sdk';
import { userUidSelector } from '@lingoda/auth';
import { useSelector } from '@lingoda/hooks';
import { config } from '@lingoda/config';

let braze = false;

export const initBraze = () => {
    const { brazeApiKey, brazeEndpoint } = config;

    if (!brazeApiKey || !brazeEndpoint) {
        return false;
    }

    braze = initialize(brazeApiKey, {
        baseUrl: brazeEndpoint,
    });

    if (braze) {
        subscribeToInAppMessage(function (inAppMessage) {
            if (inAppMessage) {
                const extras = (inAppMessage as InAppMessage).extras;

                // filter to 'sbe' if location is defined
                if (!extras?.location || extras?.location === 'sbe') {
                    showInAppMessage(inAppMessage);
                }
            }
        });

        openSession();
    }

    return braze;
};

export const useBrazeSdk = () => {
    const userUid = useSelector(userUidSelector);
    const [isSdkInitialized, setSdkInitialized] = useState<boolean>(!!braze);

    useEffect(() => {
        if (!braze) {
            setSdkInitialized(!!initBraze());
        }
    }, []);

    useEffect(() => {
        if (isSdkInitialized && userUid) {
            getUser()?.getUserId((id) => {
                if (id !== userUid) {
                    wipeData();
                    changeUser(userUid);
                }
            });
        }
    }, [isSdkInitialized, userUid]);

    return { isSdkInitialized, userUid };
};

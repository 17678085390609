import { addLeadTime, asyncMinDate, fetchErrorLeadTime } from '@lingoda/booking';
import { setCurrentSection } from '@lingoda/router';
import type { Action, Middleware } from 'redux';

const triggerAsyncMinDateActions = (action: Action) =>
    typeof action === 'object' &&
    (action.type === addLeadTime.toString() ||
        action.type === fetchErrorLeadTime.toString() ||
        action.type === setCurrentSection.toString());

const middleware: Middleware =
    (store) =>
    (next) =>
    <A extends Action>(action: A) => {
        const result = next(action);
        if (triggerAsyncMinDateActions(action)) {
            store.dispatch(asyncMinDate());
        }

        return result;
    };

export default middleware;

import { useMemo } from 'react';
import { Checkbox, FormWrapper, TextField } from '@lingoda/forms';
import { useSubmitPracticeSatisfactionFeedbackMutation } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { showApolloErrorToast } from '@lingoda/toasts';
import { Box, Button, SlidePopupHeader, Stack, Typography } from '@lingoda/ui';

interface Props {
    onClose: () => void;
    onSubmitComplete: () => void;
}

export const Step2 = ({ onClose, onSubmitComplete }: Props) => {
    const [submitFeedback] = useSubmitPracticeSatisfactionFeedbackMutation({
        onCompleted: () => {
            onSubmitComplete();
        },
        onError: showApolloErrorToast,
    });

    const initialState = useMemo(() => {
        return { [QUESTION_KEY]: [], comment: '' };
    }, []);

    const mappedAnswers = useMemo(() => {
        return ANSWERS.map(({ translatedAnswer, value }) => (
            <Checkbox key={value} name={QUESTION_KEY} value={value} label={translatedAnswer} />
        ));
    }, []);

    const handleSubmit = (form: { [QUESTION_KEY]: string[]; comment: string }) => {
        void submitFeedback({ variables: { feedback: [...form[QUESTION_KEY], form.comment] } });
    };

    return (
        <FormWrapper initialValues={initialState} onSubmit={handleSubmit}>
            {({ submitForm }) => (
                <Stack spacing={4}>
                    <Stack spacing={3}>
                        <SlidePopupHeader
                            title={trans(
                                'what-would-make-the-practice-materials-better',
                                {},
                                'practice-satisfaction-survey',
                            )}
                            onClose={onClose}
                        />
                        <Stack>{mappedAnswers}</Stack>
                        <TextField
                            name="comment"
                            label={trans('other-comments', {}, 'student-common')}
                        />
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography>
                            {trans('step-of-max-steps', { step: 2, maxSteps: 2 }, 'student-common')}
                        </Typography>
                        <Button color="primary" variant="contained" onClick={submitForm}>
                            {trans('submit-feedback', {}, 'student-common')}
                        </Button>
                    </Box>
                </Stack>
            )}
        </FormWrapper>
    );
};

const QUESTION_KEY = 'what_were_you_looking_for';

const ANSWERS = [
    {
        value: 'easier_to_find',
        translatedAnswer: trans('easier-to-find', {}, 'practice-satisfaction-survey'),
    },
    {
        value: 'easier_to_use',
        translatedAnswer: trans('easier-to-use', {}, 'practice-satisfaction-survey'),
    },
    {
        value: 'more_effective_at_helping_me_learn',
        translatedAnswer: trans(
            'more-effective-at-helping-me-learn',
            {},
            'practice-satisfaction-survey',
        ),
    },
    {
        value: 'more_engaging_and_fun_to_use',
        translatedAnswer: trans('more-engaging-and-fun-to-use', {}, 'practice-satisfaction-survey'),
    },
    {
        value: 'more_practice_materials',
        translatedAnswer: trans('more-practice-materials', {}, 'practice-satisfaction-survey'),
    },
    {
        value: 'more_guidance_on_when_to_do_practice_activities',
        translatedAnswer: trans(
            'more-guidance-on-when-to-do-practice_activities',
            {},
            'practice-satisfaction-survey',
        ),
    },
    {
        value: 'i_did_not_know_about_the_practice_materials',
        translatedAnswer: trans(
            'i-did-not-know-about-the-practice-materials',
            {},
            'practice-satisfaction-survey',
        ),
    },
];

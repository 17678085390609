import { useSelector } from '@lingoda/hooks';
import { studentNotificationsSelector, studentPreferencesSelector } from '@lingoda/students';
import { SubscriptionStatus, useUserSubscriptionsQuery } from '@lingoda/graphql';

export const useShouldShowBookingTour = () => {
    const studentPreferences = useSelector(studentPreferencesSelector);
    const notifications = useSelector(studentNotificationsSelector);
    const { data: subscriptionsData } = useUserSubscriptionsQuery();

    if (!subscriptionsData) {
        return false;
    }

    return (
        !studentPreferences?.isProductTourFinished &&
        subscriptionsData.subscriptions.currentSubscription?.status === SubscriptionStatus.Active &&
        !!notifications.startTourCourse &&
        notifications.startTourCourse !== 'denied' &&
        notifications.startTourCourse !== null
    );
};

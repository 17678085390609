import { type ComponentType, type FC } from 'react';
import getDisplayName from 'react-display-name';
import { useRequestTracker } from '../hooks/useRequestTracker';

type TrackRequest = ReturnType<typeof useRequestTracker>;

export interface RequestTrackerProps {
    trackRequest: TrackRequest;
}

/**
 * Higher order component for easier request tracking
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const withRequestTracker = <P extends {}>(
    Component: ComponentType<RequestTrackerProps & P>,
) => {
    const WrapComponent: FC<P> = (props) => {
        const trackRequest = useRequestTracker();

        return <Component {...props} trackRequest={trackRequest} />;
    };

    WrapComponent.displayName = `withRequestTracker(${getDisplayName(Component)})`;

    return WrapComponent as ComponentType<Omit<P, 'trackRequest'>>;
};

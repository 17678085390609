import { sectionNameSelector } from '@lingoda/auth';
import { WeeklyProgressDocument, useSetWeeklyGoalMutation } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { trans, transChoice } from '@lingoda/i18n';
import { showToast } from '@lingoda/toasts';
import { type SetGoalDialogProps } from '../types';
import { Layout } from './Layout';

const SetGoalDialogWeeklyClasses = ({
    hideDialog,
    closable = false,
    notifyAfterSubmit = false,
    currentGoal,
}: SetGoalDialogProps) => {
    const section = useSelector(sectionNameSelector);
    const [setWeeklyGoal, { loading }] = useSetWeeklyGoalMutation({
        refetchQueries: [WeeklyProgressDocument],
        onCompleted: () => {
            hideDialog();
            if (notifyAfterSubmit) {
                showToast({
                    variant: 'success',
                    title: currentGoal
                        ? trans('weekly-progress-goal-updated-title', {}, 'student-common')
                        : trans('weekly-progress-goal-set-title', {}, 'student-common'),
                    message: trans('weekly-progress-goal-updated-message', {}, 'student-common'),
                });
            }
        },
    });

    const handleConfirm = (value: number) => {
        void setWeeklyGoal({
            variables: {
                value,
            },
        });
    };

    const handleSaveGoal = (selectedValue: number) => handleConfirm(selectedValue);
    const handleDeleteGoal = () => handleConfirm(0);

    const title = currentGoal
        ? trans('weekly-progress-adjust-my-goal', {}, 'student-common')
        : section
          ? getTitle()[section]
          : '';

    return (
        <Layout
            closable={closable}
            currentGoal={currentGoal}
            hideDialog={hideDialog}
            loading={loading}
            onDeleteGoal={handleDeleteGoal}
            onSaveGoal={handleSaveGoal}
            options={getOptions()}
            title={title}
        />
    );
};

const getTitle = (): Record<SectionName, string> => ({
    english: trans('set-goal-title-english', {}, 'student-common'),
    german: trans('set-goal-title-german', {}, 'student-common'),
    french: trans('set-goal-title-french', {}, 'student-common'),
    spanish: trans('set-goal-title-spanish', {}, 'student-common'),
    italian: trans('set-goal-title-italian', {}, 'student-common'),
});

const getOptionLeft = (count: number, countSuffix = '') =>
    `${count}${countSuffix} ${transChoice(
        'set-goal-classes-per-week',
        count,
        { count },
        'student-common',
    )}`;

const getOptions = () => [
    {
        value: 1,
        left: getOptionLeft(1),
        right: trans('set-goal-maintain', {}, 'student-common'),
    },
    {
        value: 2,
        left: getOptionLeft(2),
        right: trans('set-goal-steady', {}, 'student-common'),
    },
    {
        value: 3,
        left: getOptionLeft(3),
        right: trans('set-goal-serious', {}, 'student-common'),
    },
    {
        value: 4,
        left: getOptionLeft(4),
        right: trans('set-goal-intense', {}, 'student-common'),
    },
    {
        value: 5,
        left: getOptionLeft(5, '+'),
        right: trans('set-goal-unstoppable', {}, 'student-common'),
    },
];

export default SetGoalDialogWeeklyClasses;

import { createSelector } from 'reselect';
import { STORE_KEY } from './constants';
import { isLessonCompleted, isLessonNotTaken, isLessonSkipped } from './models';
import { isLessonLocked } from './utils';
import type { Lesson } from './models';
import type { State } from './reducers';

export const lessonsStateSelector = (state: GlobalState) => state[STORE_KEY] as State;

export const lessonsSelector = createSelector(
    lessonsStateSelector,
    (lessons): ReadonlyArray<Lesson> => Object.values(lessons),
);

export const lessonsByIdsSelector = (lessonIds: number[] | undefined = []) =>
    createSelector(lessonsStateSelector, (lessons) =>
        lessonIds.map((lessonId) => lessons[lessonId]).filter((lesson) => lesson !== undefined),
    );

export const lessonByIdSelector = createSelector(
    lessonsStateSelector,
    (_: unknown, lessonId: number) => lessonId,
    (lessons, lessonId) => lessons[lessonId],
);

export const skippedLessonsByIdsSelector = (lessonIds: number[] | undefined) =>
    createSelector(lessonsByIdsSelector(lessonIds), (lessons) =>
        lessons.filter(isLessonSkipped).map((lesson) => lesson.id),
    );

export const orientationLessonSelector = createSelector(lessonsSelector, (lessons) =>
    lessons.find((lesson) => lesson.isOrientation),
);

export const notTakenLessonsByIdsSelector = (lessonIds: number[] | undefined) =>
    createSelector(lessonsByIdsSelector(lessonIds), (lessons) =>
        lessons.filter(isLessonNotTaken).map((lesson) => lesson.id),
    );

export const completedLessonsByIdsSelector = (lessonIds: number[] | undefined) =>
    createSelector(lessonsByIdsSelector(lessonIds), (lessons) =>
        lessons.filter(isLessonCompleted).map((lesson) => lesson.id),
    );

export const areAllLessonsLockedByIdsSelector = (lessonIds: number[] | undefined) =>
    createSelector(lessonsByIdsSelector(lessonIds), (lessons) => lessons.every(isLessonLocked));

import { Translate } from '@lingoda/i18n';
import { SettingsPages, resolvePath, studentSettingsPath } from '@lingoda/router';
import { Alert } from '@lingoda/ui';

interface Props {
    icon?: false;
}
export const VocabItemSameLanguageAlert = ({ icon }: Props) => {
    return (
        <Alert icon={icon} severity="info" variant="outlined" size="compact">
            <Translate
                id="change-site-language"
                domain="student-common"
                params={{
                    studentSettingsUrl: resolvePath(
                        studentSettingsPath({
                            page: SettingsPages.TimeAndLanguage,
                        }),
                    ),
                }}
            />
        </Alert>
    );
};

import { useCallback } from 'react';
import { type Cache, useApolloClient as useGQLClient } from '@apollo/client';

interface ClearOptions extends Cache.EvictOptions {
    triggerGC?: boolean;
}

export const useCacheCleaner = () => {
    const gqlClient = useGQLClient();

    const clearCache = useCallback(
        ({ triggerGC, ...options }: ClearOptions) => {
            gqlClient.cache.evict(options);
            if (triggerGC) {
                gqlClient.cache.gc();
            }
        },
        [gqlClient],
    );

    return [clearCache];
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

const CheckRound = (props: SvgIconProps) => (
    <SvgIcon {...props} className="check-round-icon">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4983 8.43944C16.8079 8.71463 16.8357 9.18869 16.5606 9.49827L10.7083 16.0821L7.4794 12.9685C7.18123 12.6809 7.1726 12.2061 7.46012 11.908C7.74764 11.6098 8.22243 11.6012 8.5206 11.8887L10.625 13.918L15.4394 8.50173C15.7146 8.19214 16.1887 8.16426 16.4983 8.43944Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.5 12C2.5 14.5196 3.50089 16.9359 5.28249 18.7175C7.06408 20.4991 9.48044 21.5 12 21.5C14.5196 21.5 16.9359 20.4991 18.7175 18.7175C20.4991 16.9359 21.5 14.5196 21.5 12C21.5 9.48044 20.4991 7.06408 18.7175 5.28249C16.9359 3.50089 14.5196 2.5 12 2.5C9.48044 2.5 7.06408 3.50089 5.28249 5.28249C3.50089 7.06408 2.5 9.48044 2.5 12ZM4.22183 19.7782C2.15893 17.7153 1 14.9174 1 12C1 9.08262 2.15893 6.28473 4.22183 4.22183C6.28473 2.15893 9.08262 1 12 1C14.9174 1 17.7153 2.15893 19.7782 4.22183C21.8411 6.28473 23 9.08262 23 12C23 14.9174 21.8411 17.7153 19.7782 19.7782C17.7153 21.8411 14.9174 23 12 23C9.08262 23 6.28473 21.8411 4.22183 19.7782Z"
        />
    </SvgIcon>
);

export default CheckRound;

import { useEffect } from 'react';
import { Box, Button, Hidden, Img, Stack, Typography, colorsPalette, styled } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { useDispatch } from '@lingoda/hooks';
import { updatePreferences } from '@lingoda/students';
import { UserDisplayedWidgetClassEnum } from '@lingoda/graphql';
import { useMarkWidgetAsDisplayed } from '@lingoda/banner';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import backgroundRunning from './background-girl-running.svg?url';

interface Props {
    onDismiss?: () => void;
    active?: boolean;
}

export const SetGoalBanner = ({ onDismiss, active }: Props) => {
    const dispatch = useDispatch();
    const { markAsDisplayed } = useMarkWidgetAsDisplayed();

    useEffect(() => {
        if (active) {
            markAsDisplayed(UserDisplayedWidgetClassEnum.GoalSettingV2Banner);
        }
    }, [markAsDisplayed, active]);

    const updateStudentPreferences = () => {
        dispatch(updatePreferences({ dismissedWidgets: { goalSettingV2Banner: true } }));
        onDismiss?.();
    };

    const handleConfirmClick = () => {
        dispatch(showDialog(DialogComponent.SetGoalWeeklyClasses, { closable: true }));
    };

    const handleDismissClick = updateStudentPreferences;

    return (
        <BannerContainer>
            <Stack spacing={4} alignItems={{ xs: 'center', md: 'flex-start' }}>
                <Stack
                    spacing={2}
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                    textAlign={{ xs: 'center', md: 'left' }}
                >
                    <Hidden mdUp>
                        <Box maxWidth={300}>
                            <Img src={backgroundRunning} />
                        </Box>
                    </Hidden>
                    <Title variant="h1">
                        {trans('set-goal-banner-title', {}, 'student-achievements')}
                    </Title>
                    <Description variant="body2">
                        {trans('set-goal-banner-body', {}, 'student-achievements')}
                    </Description>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent={{ xs: 'center', md: 'flex-start' }}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleConfirmClick}
                        sx={{ minWidth: 150 }}
                    >
                        {trans('set-goal-banner-btn', {}, 'student-achievements')}
                    </Button>
                    <Button color="primary" variant="text" onClick={handleDismissClick}>
                        {trans('set-goal-banner-skip-btn', {}, 'student-achievements')}
                    </Button>
                </Stack>
            </Stack>
        </BannerContainer>
    );
};

const Title = styled(Typography)(({ theme }) => ({
    width: '100%',
    maxWidth: 700,
    [theme.breakpoints.down('lg')]: {
        maxWidth: 650,
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: 430,
        textAlign: 'center',
        ...theme.typography.h2,
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
}));

const BannerContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    padding: theme.spacing(8, 7),
    paddingRight: '35%',
    background: `url(${backgroundRunning}), ${colorsPalette.purpleSolid[12]}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right',
    backgroundSize: 'contain',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3),
        background: colorsPalette.purpleSolid[12],
        justifyContent: 'center',
    },
}));

const Description = styled(Typography)(({ theme }) => ({
    width: '100%',
    maxWidth: 800,
    [theme.breakpoints.down('lg')]: {
        maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
        textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
}));

import { getValidRangeDate } from './getValidRangeDate';
import type { BookingFilter } from '../models';

export const getValidDatesFromTimeSlots = (
    dates: Date[],
    slots: BookingFilter['timeSlots'],
): Date[] => {
    const datesSet = new Set<number>();

    return dates.reduce<Date[]>((allDates, date) => {
        const validDate = getValidRangeDate(date, slots);

        if (!datesSet.has(validDate.getTime())) {
            allDates.push(validDate);
            datesSet.add(validDate.getTime());
        }

        return allDates;
    }, []);
};

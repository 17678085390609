import { dvw, styled } from '@lingoda/ui';
import laptopAvif from './laptop.avif';
import laptopRetinaAvif from './laptop@2x.avif';
import laptopRetinaPng from './laptop@2x.png';
import laptopPng from './laptop.png';
import laptopRetinaWebp from './laptop@2x.webp';
import laptopWebp from './laptop.webp';

export const Image = () => {
    return (
        <picture>
            <source
                srcSet={laptopRetinaAvif}
                media="(min-resolution: 192dpi)"
                type="image/avif"
            ></source>
            <source srcSet={laptopAvif} type="image/avif"></source>
            <source
                srcSet={laptopRetinaWebp}
                media="(min-resolution: 192dpi)"
                type="image/webp"
            ></source>
            <source srcSet={laptopWebp} type="image/webp"></source>
            <source
                srcSet={laptopRetinaPng}
                media="(min-resolution: 192dpi)"
                type="image/png"
            ></source>
            <source srcSet={laptopPng} type="image/png"></source>
            <ImageStyled alt="" height={437} src={laptopPng} width={537} />
        </picture>
    );
};

const ImageStyled = styled('img')(({ theme }) => ({
    aspectRatio: '537 / 437',
    height: 'auto',
    width: '100%',
    [theme.breakpoints.down(991)]: {
        width: dvw('calc(100% + calc(calc(100dvw - 748px) / 2) + 24px)'),
    },
    [theme.breakpoints.down(767)]: {
        width: dvw('calc(100% + calc(calc(100dvw - 588px) / 2) + 24px)'),
    },
    [theme.breakpoints.down(588)]: {
        width: dvw('calc(calc(100% + 24px) + 33.33%)'),
    },
}));

import { Box, Typography } from '@lingoda/ui';
import type { PropsWithChildren } from 'react';

export const StepComponent = ({ title, children }: PropsWithChildren<{ title: string }>) => {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                {title}
            </Typography>
            <Box>{children}</Box>
        </>
    );
};

import { useState } from 'react';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { isNonNullable } from '@lingoda/utils';
import { DialogConfirm } from '../../feedback/dialogs';
import { TimeSlotPickerMenu } from './TimeSlotPickerMenu';

export interface TimeSlotPickerDialogProps {
    title?: string;
    value?: number;
    onChange: (newValue: number) => void;
    onClose: () => void;
    disabled?: boolean;
    disabledSlots?: number[];
    scheduledSlots?: number[];
    loading?: boolean;
    timeFormat: TimeFormat;
    isOpen: boolean;
    confirmLabel?: string;
}

export const TimeSlotPickerDialog = ({
    title,
    value,
    disabled,
    disabledSlots,
    scheduledSlots,
    loading,
    timeFormat,
    isOpen,
    onChange,
    onClose,
    confirmLabel = trans('done', {}, 'public-common'),
}: TimeSlotPickerDialogProps) => {
    const [draftValue, setDraftValue] = useState<number | undefined>(value);

    const handleOnClose = () => {
        if (isNonNullable(draftValue) && draftValue !== value) {
            onChange(draftValue);
        }
        onClose();
    };

    return (
        <DialogConfirm
            isOpen={isOpen}
            title={title || trans('time-label', {}, 'public-common')}
            onClose={onClose}
            onConfirm={handleOnClose}
            confirmLabel={confirmLabel}
            scrollableContent={false}
        >
            <TimeSlotPickerMenu
                timeFormat={timeFormat}
                value={draftValue}
                disabledSlots={disabledSlots}
                scheduledSlots={scheduledSlots}
                onChange={setDraftValue}
                disabled={disabled || loading}
            />
        </DialogConfirm>
    );
};

import { useCallback } from 'react';
import { type Achievement } from '@lingoda/graphql';
import { enqueueSnackbar, useResolution } from '@lingoda/ui';

export const useAchievementSnackbar = () => {
    const isMobile = useResolution('phone');

    // NOTE: call enqueueSnackbar() with 2 args (message, options)
    // to enable proper type mapping of custom props based on 'variant' value
    const show = useCallback(
        ({ message, achievement }: { message: string; achievement: Achievement }) => {
            enqueueSnackbar(message, {
                variant: 'achievement',
                achievement,
                transitionDuration: { enter: 500, exit: 500 },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: isMobile ? 'center' : 'right',
                },
                persist: false,
                autoHideDuration: 5000,
            });
        },
        [isMobile],
    );

    return { show };
};

import { createSelector } from 'reselect';
import { CLASSES_STORE_KEY } from '../constants';
import type { Class } from '../models';

export const classesStateSelector = (state: GlobalState) => state[CLASSES_STORE_KEY];

export const classesSelector = createSelector(
    classesStateSelector,
    (classes: Class[]): ReadonlyArray<Class> => Object.values(classes),
);

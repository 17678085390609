import { useCallback, useState } from 'react';

const useToggleState = (defaultValue = false) => {
    const [state, setState] = useState(defaultValue);

    const toggleOn = useCallback(() => {
        setState(true);
    }, [setState]);

    const toggleOff = useCallback(() => {
        setState(false);
    }, [setState]);

    const toggle = useCallback(() => {
        setState(!state);
    }, [state]);

    return [state, toggleOn, toggleOff, toggle] as const;
};

export default useToggleState;

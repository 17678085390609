import { useMemo } from 'react';
import { getTimezone } from '@lingoda/dates';
import { useConfigurationQuery } from '@lingoda/graphql';
import { getBrowserTimeZone } from '../logic/getBrowserTimeZone';

export const useValidatedTimezone = (
    timezone?: string,
): {
    loading: boolean;
    validatedTimezone: string;
} => {
    const { data, loading } = useConfigurationQuery();

    const browserTimezone = getBrowserTimeZone();
    const defaultTimezone = getTimezone();

    const validatedTimezone = useMemo(() => {
        const timezones = data?.config.timezones || [];

        return getValidTimezone({ timezone, browserTimezone, timezones });
    }, [browserTimezone, timezone, data?.config.timezones]);

    return {
        loading,
        validatedTimezone: validatedTimezone || defaultTimezone,
    };
};

export interface GetValidTimezoneArgs {
    timezone: string | undefined;
    browserTimezone: string;
    timezones: ReadonlyArray<{
        readonly __typename?: 'Timezone';
        readonly label: string;
        readonly tz: string;
    }>;
}
export const getValidTimezone = ({
    timezone,
    browserTimezone,
    timezones,
}: GetValidTimezoneArgs) => {
    let validTimezone = null;

    for (const { tz } of timezones) {
        if (tz === timezone) {
            return timezone;
        }
        if (tz === browserTimezone) {
            validTimezone = browserTimezone;
            if (!timezone) {
                return browserTimezone;
            }
        }
    }

    return validTimezone;
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

const ExpandMore: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.46967 8.96979C6.76256 8.6769 7.23744 8.6769 7.53033 8.96979L12 13.4395L16.4697 8.96979C16.7626 8.6769 17.2374 8.6769 17.5303 8.96979C17.8232 9.26269 17.8232 9.73756 17.5303 10.0305L12 15.5608L6.46967 10.0305C6.17678 9.73756 6.17678 9.26269 6.46967 8.96979Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export default ExpandMore;

import { lazy } from 'react';
import { type RouteConfigExtended, TabValue, studentChallengePath } from '@lingoda/router';

const ActivityChallenge = lazy(() => import('./pages/ActivityChallenge'));

export const challengesRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: ActivityChallenge,
        path: studentChallengePath.raw,
        label: 'ActivityChallenge',
        config: {
            tab: TabValue.Home,
        },
    },
];

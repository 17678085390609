import { Box, Typography, colorsPalette } from '@lingoda/ui';

interface Props {
    label: string;
    name: string;
}

export const ContentClaimed = ({ label, name }: Props) => {
    return (
        <Box>
            <Typography
                variant="subtitle1"
                sx={{ wordBreak: 'break-word' }}
                htmlColor={colorsPalette.blackSolid[100]}
            >
                {name}
            </Typography>
            <Typography variant="body2" sx={{ wordBreak: 'break-word', marginTop: 0.5 }}>
                {label}
            </Typography>
        </Box>
    );
};

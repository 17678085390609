import type { WeeklyProgressQuery } from '@lingoda/graphql';
import type { WeekStart } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { shouldRemindToAdjustGoal } from './shouldRemindToAdjustGoal';
import { shouldRemindToAttendClasses } from './shouldRemindToAttendClasses';
import { shouldRemindToSetAGoal } from './shouldRemindToSetAGoal';
import { goalWasReached } from './utils';

export const getWeeklyProgressReminderText = (
    activeGoalData: WeeklyProgressQuery['activeGoal'],
    studentGoalWidgetData: WeeklyProgressQuery['studentGoalWidgetData'],
    userRegisterDate: string,
    weekStartsOn: WeekStart,
): string | undefined => {
    const currentGoal = activeGoalData?.value ?? null;
    const lastGoalSetDate = activeGoalData?.createdAt ?? null;
    const firstClassAttendanceDate = studentGoalWidgetData.firstAppointmentDate;
    const anyClassAttendedInLast4Weeks = studentGoalWidgetData.weeklyProgress.some(
        ({ attendance }) => attendance > 0,
    );
    const numberOfGoals = studentGoalWidgetData.weeklyProgress.filter(({ goal }) => !!goal).length;
    const numberOfAchievedGoals = studentGoalWidgetData.weeklyProgress.filter(
        ({ goal, attendance }) => goalWasReached(goal, attendance),
    ).length;

    if (shouldRemindToSetAGoal(currentGoal, firstClassAttendanceDate)) {
        return trans('weekly-progress-goal-reminder-set', {}, 'student-common');
    }

    if (shouldRemindToAttendClasses(userRegisterDate, anyClassAttendedInLast4Weeks, weekStartsOn)) {
        return trans('weekly-progress-goal-reminder-start-taking-classes', {}, 'student-common');
    }

    if (
        lastGoalSetDate &&
        shouldRemindToAdjustGoal(
            lastGoalSetDate,
            numberOfGoals,
            numberOfAchievedGoals,
            weekStartsOn,
        )
    ) {
        return trans('weekly-progress-goal-reminder-adjust', {}, 'student-common');
    }

    return undefined;
};

import { createAction, makeActionTrackable } from '@lingoda/core';
import type { StudentPreferences } from '../models';

export default makeActionTrackable(
    createAction<
        Partial<
            Pick<
                StudentPreferences,
                | 'nextClass'
                | 'oneClickBooking'
                | 'npsFeedbackRequired'
                | 'notifications'
                | 'onboardingBooked'
                | 'pushNotificationsStatus'
                | 'pushNotificationsTriggerBeforeClassStartMinutes'
                | 'flashCardPushNotificationsStatus'
                | 'flashCardPushNotificationSchedule'
                | 'showOrientationClassBanner'
                | 'studentFeedbackGroup'
                | 'dismissedWidgets'
                | 'classWeeklyAttendanceGoal'
                | 'classWeeklyWordsGoal'
                | 'flashcardsSwapped'
                | 'quizIntroSeen'
                | 'practiceSequence'
            >
        >
    >('student/UPDATE_PREFERENCES'),
);

import { Container, styled } from '@lingoda/ui';
import { unit } from '@lingoda/ui/theme/common';

interface Props {
    pt?: number;
    pb?: number;
}

export const BookingSearchFrameContainer = styled(Container, {
    shouldForwardProp: (prop) => prop !== 'pt' && prop !== 'pb',
})<Props>(({ theme, pt, pb }) => ({
    ...(pt && { paddingTop: theme.spacing(pt) }),
    ...(pb && { paddingBottom: theme.spacing(pb) }),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 1032 + unit * 3 * 2,
    },
}));

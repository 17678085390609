import { addMinutes, isAfter, isBetween } from '@lingoda/dates';
import { AppointmentStatus } from '@lingoda/graphql';

export const getClassHasStarted = (startDate?: Date, currentTime?: Date) => {
    return !!startDate && !!currentTime && isAfter(currentTime, startDate);
};

export const getClassHasEnded = (endDate?: Date, currentTime?: Date) => {
    return !!endDate && !!currentTime && isAfter(currentTime, endDate);
};

export const getClassIsLive = (startDate?: Date, endDate?: Date, currentTime?: Date) => {
    return (
        !!startDate &&
        !!endDate &&
        !!currentTime &&
        isBetween(addMinutes(-5)(startDate), endDate, currentTime)
    );
};

export const getClassIsMissed = (appointmentStatus: AppointmentStatus | undefined) => {
    return appointmentStatus === AppointmentStatus.Missed;
};

import { useCallback, useState } from 'react';
import { actionFilter } from '@lingoda/analytics';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { useUiEvent } from '@lingoda/ui';
import { objectDiff } from '@lingoda/utils';
import { bookingFilterSelector } from '../selectors';
import { getValidBookingFilter } from '../utils';
import { resetBaseBookingFilter, setFilter } from '../actions';
import type { BookingFilter } from '../models';

export const useBookingFilter = () => {
    const addEvent = useUiEvent();
    const dispatch = useDispatch();
    const bookingFilter = useSelector(bookingFilterSelector);
    const [isMobileFilterOpen, setMobileFilterOpen] = useState(false);

    const handleFilterChange = useCallback(
        (nextFilter: Partial<BookingFilter>) => {
            const newFilter = getValidBookingFilter({ ...bookingFilter, ...nextFilter });
            const filterDiff = objectDiff(bookingFilter, newFilter);
            if (filterDiff) {
                dispatch(setFilter(filterDiff));
            }
        },
        [dispatch, bookingFilter],
    );

    const handleFilterReset = useCallback(() => {
        void addEvent(actionFilter('Reset'));
        dispatch(resetBaseBookingFilter());
    }, [addEvent, dispatch]);

    const toggleMobileFilter = useCallback(() => {
        setMobileFilterOpen(!isMobileFilterOpen);
    }, [isMobileFilterOpen, setMobileFilterOpen]);

    return {
        filter: bookingFilter,
        handleFilterChange,
        handleFilterReset,
        isMobileFilterOpen,
        toggleMobileFilter,
    };
};

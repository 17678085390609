import { trackUrlGtm } from './gtm';
import { addEvent } from './kinesis';
export { default as initVitals } from './initVitals';

export * from './cj';
export * from './ga';
export * from './gtm';
export * from './kinesis';
export * from './common';

export const trackUrl = (pathName: string, pageName?: string) => {
    trackUrlGtm(pathName);
    void addEvent({
        action: 'pageview',
        category: undefined,
        label: undefined,
        metadata: {
            pageName,
        },
    });
};

import { useState } from 'react';
import { useDispatch } from '@lingoda/hooks';
import { accountPath, goTo } from '@lingoda/router';
import { showToast } from '@lingoda/toasts';
import { Box, Card, colorsPalette } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { trans } from '@lingoda/i18n';
import { useRequestTracker } from '@lingoda/core';
import {
    REQUEST_TOKEN_EXPIRED,
    type RequestTokenExpiredError,
    type TokenDecoded,
    getIsResetPasswordTokenExpired,
    login,
    updateForgottenPassword,
} from '@lingoda/auth';
import { type NewPasswordFormValues } from '../../forms';
import { SuccessStep } from './SuccessStep';
import { TokenExpiredStep } from './TokenExpiredStep';
import { InitialStep } from './InitialStep';

type ResetStep = 'INITIAL' | 'SUCCESS' | 'TOKEN_EXPIRED';

interface Props {
    title: string;
    decodedTokenJSON: TokenDecoded;
}

export const Layout = ({ decodedTokenJSON, title }: Props) => {
    const { email, passwordRequestedAt, token, userResetTokenTtl } = decodedTokenJSON;
    const isTokenExpired = getIsResetPasswordTokenExpired(passwordRequestedAt, userResetTokenTtl);
    const [resetStep, setResetStep] = useState<ResetStep>(
        isTokenExpired ? 'TOKEN_EXPIRED' : 'INITIAL',
    );
    const [password, setPassword] = useState<string | null>(null);
    const trackRequest = useRequestTracker();
    const dispatch = useDispatch();
    const handleFormSubmit = async ({ plainPassword }: NewPasswordFormValues) => {
        try {
            await trackRequest(dispatch(updateForgottenPassword(token, { plainPassword })));
            setPassword(plainPassword);
            setResetStep('SUCCESS');
        } catch (e: unknown) {
            if ((e as RequestTokenExpiredError)?.global === REQUEST_TOKEN_EXPIRED) {
                setResetStep('TOKEN_EXPIRED');
            } else {
                showToast('error', trans('error.500.headline', {}, 'messages'));
                throw e;
            }
        }
    };

    const handleClickContinue = async () => {
        if (!password) {
            return;
        }

        await trackRequest(
            dispatch(
                login({
                    username: email,
                    password,
                    rememberMe: false,
                }),
            ),
        );
        goTo(accountPath());
    };

    return (
        <ContainerGrayBg>
            <FormCard>
                {resetStep === 'INITIAL' ? (
                    <InitialStep title={title} email={email} onFormSubmit={handleFormSubmit} />
                ) : resetStep === 'TOKEN_EXPIRED' ? (
                    <TokenExpiredStep />
                ) : (
                    <SuccessStep onClickContinue={handleClickContinue} />
                )}
            </FormCard>
        </ContainerGrayBg>
    );
};

const ContainerGrayBg = styled(Box)`
    background-color: ${colorsPalette.blackSolid[3]};
    display: flex;
    flex: 1;
    justify-content: center;
    padding-top: ${(props) => props.theme.spacing(3)};
`;

const FormCard = styled(Card)`
    max-width: 429px;
    height: fit-content;
    padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};
`;

import { memo, useEffect, useState } from 'react';
import { Box, BoxPerf } from '@lingoda/ui';
import { type BookableClass, type BookingFilter } from '@lingoda/booking';
import { BookClassVariant, getBookingClassPosition } from '@lingoda/booking-ui';
import { BookableClassElement, type BookableClassElementProps } from './BookableClassElement';

interface Props {
    slots: BookableClass[];
    isAbleToBook: boolean;
    isEmbedded?: boolean;
    onBooked?: BookableClassElementProps['onBooked'];
    bookingFilter: BookingFilter;
}

const ClassesListComponent = ({
    slots,
    isAbleToBook,
    isEmbedded,
    onBooked,
    bookingFilter,
}: Props) => {
    const [activeIndex, setActiveIndex] = useState(2);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (activeIndex <= slots.length) {
            timeout = setTimeout(() => setActiveIndex(activeIndex + 2), 0);
        }

        return () => clearTimeout(timeout);
    }, [activeIndex, slots.length]);

    const length = slots.length;

    return (
        <Box display="flex" flexDirection="column">
            {slots.map((klass, i) => (
                <BoxPerf className="display-block" key={klass.uid}>
                    <BookableClassElement
                        isEmbedded={isEmbedded}
                        isAbleToBook={isAbleToBook}
                        variant={BookClassVariant.List}
                        klass={klass}
                        active={i <= activeIndex}
                        position={getBookingClassPosition(i, length)}
                        onBooked={onBooked}
                        bookingFilter={bookingFilter}
                    />
                </BoxPerf>
            ))}
        </Box>
    );
};

export const ClassesList = memo(ClassesListComponent);

import { type SvgIconProps } from '@lingoda/ui';
import type { FC } from 'react';

export const CalendarIcon: FC<SvgIconProps> = (props) => (
    <svg
        width="80"
        height="81"
        viewBox="0 0 80 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M78.687 49.7742C76.167 69.9355 42.3839 82.2767 29.7028 79.9372C-4.65689 73.598 16.0838 31.5946 41.8063 18.8732C57.491 11.1161 82.7257 17.4628 78.687 49.7742Z"
            fill="#153BFF"
            fillOpacity="0.08"
        />
        <g clipPath="url(#clip0)">
            <path
                d="M54.6667 62.668C54.7985 62.668 54.9274 62.7071 55.037 62.7803C55.1467 62.8536 55.2321 62.9577 55.2826 63.0795C55.333 63.2013 55.3462 63.3354 55.3205 63.4647C55.2948 63.594 55.2313 63.7128 55.1381 63.806C55.0448 63.8993 54.926 63.9628 54.7967 63.9885C54.6674 64.0142 54.5334 64.001 54.4115 63.9506C54.2897 63.9001 54.1856 63.8146 54.1124 63.705C54.0391 63.5954 54 63.4665 54 63.3346C54 63.1578 54.0702 62.9883 54.1953 62.8632C54.3203 62.7382 54.4899 62.668 54.6667 62.668Z"
                fill="#051F4E"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M54.668 57.3359V49.3359"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M56.9031 41.3322C56.6869 40.9274 56.3648 40.5889 55.9712 40.3529C55.5777 40.1169 55.1274 39.9922 54.6684 39.9922C54.2095 39.9922 53.7592 40.1169 53.3656 40.3529C52.972 40.5889 52.6499 40.9274 52.4338 41.3322L38.9511 67.0229C38.752 67.4018 38.6549 67.826 38.6694 68.2538C38.6839 68.6816 38.8095 69.0983 39.0338 69.4629C39.261 69.8288 39.5779 70.1307 39.9545 70.3398C40.3312 70.5489 40.755 70.6582 41.1858 70.6575H68.1511C68.5819 70.6582 69.0057 70.5489 69.3823 70.3398C69.7589 70.1307 70.0759 69.8288 70.3031 69.4629C70.5274 69.0983 70.653 68.6816 70.6675 68.2538C70.682 67.826 70.5849 67.4018 70.3858 67.0229L56.9031 41.3322Z"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.334 57.3346H12.0007C11.2934 57.3346 10.6151 57.0537 10.115 56.5536C9.61494 56.0535 9.33398 55.3752 9.33398 54.668V17.3346C9.33398 16.6274 9.61494 15.9491 10.115 15.449C10.6151 14.9489 11.2934 14.668 12.0007 14.668H60.0006C60.7079 14.668 61.3862 14.9489 61.8863 15.449C62.3864 15.9491 62.6673 16.6274 62.6673 17.3346V34.668"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.668 9.33594V22.6693"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M49.334 9.33594V22.6693"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.33398 28.0039H62.6673"
                stroke="#051F4E"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="64" height="64" fill="white" transform="translate(8 8)" />
            </clipPath>
        </defs>
    </svg>
);

import { Box, styled } from '@lingoda/ui';

export const FlashCardContainer = styled(Box)(({ theme }) => {
    return {
        display: 'flex',
        position: 'relative',
        height: '100%',
        width: '100%',
        marginBottom: theme.spacing(2), // account next card preview position absolute shift
        maxHeight: '416px',
        maxWidth: '320px',

        [theme.breakpoints.up('sm')]: {
            maxHeight: '510px',
            maxWidth: '408px',
        },
    };
});

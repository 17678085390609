import { addCallback } from '@lingoda/core';
import { createDate } from '@lingoda/dates';
import { setInterval } from '@lingoda/utils';
import { asyncSetTime, setTime } from './actions';

export default () => {
    let interval: number;

    addCallback(asyncSetTime, (_action, store) => {
        if (interval) {
            clearInterval(interval);
        }

        const updateTime = () => {
            store.dispatch(setTime(createDate()));
        };

        interval = setInterval(updateTime, 1000);
    });
};

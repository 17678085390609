import { lazy } from 'react';
import { type RouteConfigExtended, studentChangedCoursePath } from '@lingoda/router';

const SubscriptionChanged = lazy(() => import('./pages/SubscriptionChanged'));

const subscriptionRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: SubscriptionChanged,
        path: studentChangedCoursePath.raw,
        label: 'ChangedCourse',
        config: undefined,
    },
];

export default subscriptionRoutes;

import { createSelector } from 'reselect';
import { isSameOrAfter } from '@lingoda/dates';
import { localRoundHourSelector } from '@lingoda/time';
import studentAppointmentsSelector from './studentAppointments';

const filterPastAppointments = <T extends { endDate: Date }>(
    appointments: Readonly<T[]>,
    time: Date,
) => appointments.filter((item) => isSameOrAfter(time, item.endDate));

export const studentPastAppointmentsSelector = createSelector(
    studentAppointmentsSelector,
    localRoundHourSelector,
    (appointments, time) => filterPastAppointments(appointments, time),
);

import { createSelector } from 'reselect';
import { studentModuleSelector } from '@lingoda/modules';
import { STORE_KEY } from './constants';
import type { State } from './reducers';

export const learningUnitsStateSelector = (state: GlobalState) => state[STORE_KEY] as State;

export const learningUnitByIdSelector = (selector: (state: unknown, props: unknown) => number) =>
    createSelector(learningUnitsStateSelector, selector, (learningUnits, id) => learningUnits[id]);

export const learningUnitsSelector = createSelector(learningUnitsStateSelector, (learningUnits) =>
    Object.values(learningUnits).sort((a, b) => a.position - b.position),
);

export const studentLearningUnitsSelector = createSelector(
    learningUnitsSelector,
    studentModuleSelector,
    (learningUnits, studentModule) =>
        learningUnits.filter((item) => item.moduleId === studentModule?.id),
);

export const learningUnitsByModuleIdSelector = (selector: () => number | undefined) =>
    createSelector(learningUnitsSelector, selector, (learningUnits, moduleId) =>
        learningUnits.filter((learningUnit) => learningUnit.moduleId === moduleId),
    );

export const lessonsByModulesIdSelector = (selector: (_state: unknown) => number[] | undefined) =>
    createSelector(learningUnitsSelector, selector, (learningUnits, moduleIds) =>
        moduleIds
            ? learningUnits
                  .filter((learningUnit) => moduleIds.includes(learningUnit.moduleId))
                  .reduce((a: number[], b) => [...a, ...b.lessonIds], [])
            : [],
    );

export const unfetchedModuleIdsSelector = (selector: (_state: unknown) => number[] | undefined) =>
    createSelector(
        learningUnitsSelector,
        selector,
        (learningUnits, moduleIds) =>
            moduleIds?.filter(
                (moduleId, index) =>
                    // Remove duplicates
                    moduleIds?.indexOf(moduleId) === index &&
                    // Remove fetched moduleIds
                    !learningUnits.find((classItem) => classItem.moduleId === moduleId),
            ) || [],
    );

export const lessonIdsByModuleIdSelector = (moduleId: number) =>
    createSelector(learningUnitsSelector, (learningUnits) => {
        const learningUnit = learningUnits.find((learningUnit) => learningUnit.id === moduleId);
        if (!learningUnit) {
            return [];
        }

        return learningUnit.lessonIds;
    });

import { useMemo, useState } from 'react';
import { addDays, calcDatesWithinRange, createDate, startOfDay } from '@lingoda/dates';
import InformationStep from './steps/InformationStep';
import CalendarStep from './steps/CalendarStep';
import { useUpcomingAppointmentDates } from './hooks';
import ConfirmationStep from './steps/ConfirmationStep';
import type { RangeState } from './types';

interface Props {
    closeDialog: () => void;
    onSuccess?: () => void;
}

export const PauseSubscriptionDialog = ({ closeDialog, onSuccess }: Props) => {
    const [dialogStep, setDialogStep] = useState(0);
    const step = (diff: number) => () => setDialogStep((prevStep) => prevStep + diff);

    const { data: scheduledClassesDates } = useUpcomingAppointmentDates();

    const today = startOfDay(createDate());
    const tomorrow = addDays(today, 1);
    const in3days = addDays(today, 3);

    const [range, setRange] = useState<RangeState>({ startDate: tomorrow, endDate: in3days });

    const numberOfClassesInPausePeriod = useMemo(() => {
        if (!range || !scheduledClassesDates) {
            return 0;
        }

        return calcDatesWithinRange(scheduledClassesDates, [
            range.startDate,
            addDays(range.endDate, 1), // calculate with end of day / start of next day
        ]);
    }, [range, scheduledClassesDates]);

    const Steps = [
        <InformationStep
            key="informationStep"
            closeDialog={closeDialog}
            isOpen
            onConfirm={step(1)}
        />,
        <CalendarStep
            key="calendarStep"
            isOpen
            closeDialog={closeDialog}
            range={range}
            numberOfClassesInPausePeriod={numberOfClassesInPausePeriod}
            scheduledClassesDates={scheduledClassesDates}
            onRangeChange={setRange}
            onContinue={step(1)}
            onDismiss={step(-1)}
            onPauseSuccess={onSuccess}
        />,
        <ConfirmationStep
            key="confirmationStep"
            range={range}
            isOpen
            closeDialog={closeDialog}
            onDismiss={step(-1)}
            onPauseSuccess={onSuccess}
            numberOfClassesInPausePeriod={numberOfClassesInPausePeriod}
        />,
    ];

    return Steps[dialogStep];
};

import { produce } from 'immer';
import { createSelector } from 'reselect';
import { AppointmentWithClassExpandedFactory } from '../models';
import { expandedClassesSelector } from './expandedClass';
import { appointmentsSelector } from './state';
import type { AppointmentExpanded, AppointmentWithClassExpanded, ClassExpanded } from '../models';

export const expandAppointmentWithClass = (
    appointment: AppointmentExpanded,
    classes: ClassExpanded[],
): AppointmentWithClassExpanded => {
    const appointmentExpanded = AppointmentWithClassExpandedFactory(appointment);

    return produce(appointmentExpanded, (draft) => {
        draft.class = classes?.find((klass) => klass.id === appointment.classId);
    });
};

export const expandedAppointmentsWithClassSelector = createSelector(
    appointmentsSelector,
    expandedClassesSelector,
    (appointments, classes) =>
        Object.values(appointments).map((appointment) =>
            expandAppointmentWithClass(appointment, classes),
        ),
);

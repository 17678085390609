import { getType } from '../models';
import usePaymentMethod from './usePaymentMethod';

const usePaymentMethodType = (value: string | undefined) => {
    const paymentMethod = usePaymentMethod(value);

    return getType(
        value,
        paymentMethod && paymentMethod.type,
        paymentMethod && paymentMethod.gateway,
    );
};

export default usePaymentMethodType;

import { type PropsWithChildren, forwardRef } from 'react';
import { styled } from '../theme';

interface StyledBlockProps {
    gutterBottom?: boolean;
    contentGutterTop?: boolean;
    background?: string;
}

export interface BlockProps extends React.DOMAttributes<HTMLDivElement>, StyledBlockProps {
    className?: string;
}

const StyledBlock = styled('div', {
    shouldForwardProp: (prop) =>
        prop !== 'gutterBottom' && prop !== 'contentGutterTop' && prop !== 'background',
})<StyledBlockProps>(({ theme, gutterBottom, contentGutterTop, background }) => ({
    ...(gutterBottom && { marginBottom: theme.spacing(5) }),
    ...(contentGutterTop && { marginTop: theme.spacing(3) }),
    ...(background && { background }),
}));

export const Block = forwardRef<HTMLDivElement, PropsWithChildren<BlockProps>>(
    ({ gutterBottom = true, contentGutterTop, children, ...props }, ref) => (
        <StyledBlock
            {...props}
            ref={ref}
            gutterBottom={gutterBottom}
            contentGutterTop={contentGutterTop}
        >
            {children}
        </StyledBlock>
    ),
);

Block.displayName = 'Block';

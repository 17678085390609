import { trans } from '@lingoda/i18n';
import { Box, Typography, colorsPalette, styled } from '@lingoda/ui';
import { type Variant } from '../../types';

interface Props {
    variant: Variant;
}

const FlashCardBanner = styled(Box)<Props>(({ theme, variant }) => ({
    backgroundColor:
        variant === 'checked' ? colorsPalette.positiveSolid[12] : colorsPalette.infoSolid[12],
    color: colorsPalette.blackAlpha[70],
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const Top = ({ variant }: Props) => {
    return (
        <Box position="relative" width="100%" minHeight={(theme) => theme.spacing(6)}>
            {
                {
                    checked: (
                        <FlashCardBanner variant="checked">
                            <Typography variant="subtitle2">
                                {trans('got-it', {}, 'student-common')} 👍
                            </Typography>
                        </FlashCardBanner>
                    ),
                    missed: (
                        <FlashCardBanner variant="missed">
                            <Typography variant="subtitle2">
                                👈 {trans('dont-know', {}, 'student-common')}
                            </Typography>
                        </FlashCardBanner>
                    ),
                    none: null,
                }[variant]
            }
        </Box>
    );
};

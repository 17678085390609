import { type PropsWithChildren } from 'react';
import { Stack } from '@lingoda/ui';
import { type Variant } from '../../types';
import { Top } from './Top';

interface Props {
    dataCy?: string;
    variant: Variant;
}

export const CardFaceBase = ({ dataCy, variant, children }: PropsWithChildren<Props>) => {
    return (
        <Stack alignItems="center" position="relative" flex={1} data-cy={dataCy}>
            <Top variant={variant} />
            {children}
        </Stack>
    );
};

import {
    add,
    createDate,
    format,
    formatInTimeZone,
    isBefore,
    isSameDay,
    startOfDay,
    toUtc,
    utcMinutesOffset,
} from '@lingoda/dates';

export const formatTimeSlot = (timeFormat: string, hour: number) =>
    format(timeFormat, add({ hours: hour }, getBaseDate()));

export const formatTimeSlotUtc = (timeFormat: string, hour: number) => {
    return formatInTimeZone(toUtc(add({ hours: hour }, getBaseDate())), 'UTC', timeFormat);
};

const getBaseDate = () => add({ minutes: utcMinutesOffset() }, startOfDay(createDate()));

export const getDisabledTimeSlots = (selectedDate: Date, minDate?: Date, maxDate?: Date) => {
    if (minDate && maxDate && isBefore(maxDate, minDate)) {
        return new Array(24).fill(0).map((_, index) => index);
    }

    let disabledStart: number[] = [];
    if (minDate && isSameDay(selectedDate, minDate)) {
        const startFromNextHour = minDate.getMinutes() === 0 ? 0 : 1;
        disabledStart = new Array(minDate.getHours() + startFromNextHour)
            .fill(0)
            .map((_, index) => index);
    }

    let disabledEnd: number[] = [];
    if (maxDate && isSameDay(selectedDate, maxDate)) {
        const baseIndex = maxDate.getHours() + 1;
        disabledEnd = new Array(23 - maxDate.getHours())
            .fill(0)
            .map((_, index) => baseIndex + index);
    }

    const disabledSlots = [...new Set([...disabledStart, ...disabledEnd])];

    return disabledSlots;
};

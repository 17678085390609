import { memo } from 'react';
import FormControl from '@lingoda/ui/fields/FormControl';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import { ErrorMessage, Field } from '../../utils';
import { MultiToggleButton, type MultiToggleButtonProps } from '../../formFields';
import type { FieldProps } from '../../utils';

interface MultiToggleFieldProps extends Omit<MultiToggleButtonProps, 'value' | 'onChange'> {
    name: string;
}

const MultiToggleFieldComponent = ({ name, ...props }: MultiToggleFieldProps) => (
    <Field name={name}>
        {({ field, form: { errors, touched, setFieldValue } }: FieldProps) => (
            <FormControl error={!!touched[field.name] && !!errors[field.name]}>
                <MultiToggleButton
                    {...props}
                    onChange={(_: React.MouseEvent<HTMLElement>, value: string[]) =>
                        setFieldValue(field.name, value)
                    }
                    value={field.value}
                />
                <ErrorMessage name={field.name}>
                    {(message) => (
                        <FormHelperText>
                            {typeof message === 'object'
                                ? Object.values(message).map((msg, index) => (
                                      <p key={`${index}`}>{msg as string}</p>
                                  ))
                                : message}
                        </FormHelperText>
                    )}
                </ErrorMessage>
            </FormControl>
        )}
    </Field>
);

export const MultiToggleField = memo(MultiToggleFieldComponent);

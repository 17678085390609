import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    useAnswerBookingExperienceSurveyDetailsMutation,
    useAnswerBookingExperienceSurveyMutation,
    useDismissBookingExperienceSurveyMutation,
    useStudentGetBookingFeedbackFormQuery,
} from '@lingoda/graphql';
import { Loader } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { fetchMe } from '@lingoda/auth';
import { useDispatch } from '@lingoda/hooks';
import { CompleteFormState } from './CompleteFormState';
import { type FormState } from './types';
import { BookingFeedbackFormFields } from './BookingFeedbackFormFields';
import { formatQuestionsFormStateOnSubmit, formatScoreFormStateOnSubmit } from './utils';

interface BookingFeedbackFormProps {
    onClose: () => void;
}

export const SCREENS_AMOUNT = 3;

export const BookingFeedbackForm = ({ onClose }: BookingFeedbackFormProps) => {
    const [screenNumber, setScreenNumber] = useState<number>(1);

    const dispatch = useDispatch();

    const mutationsOptions = useMemo(
        () => ({
            update: () => dispatch(fetchMe()),
        }),
        [dispatch],
    );

    const { data } = useStudentGetBookingFeedbackFormQuery();

    const [answerSurveyDetails] = useAnswerBookingExperienceSurveyDetailsMutation(mutationsOptions);
    const [answerSurveyScore] = useAnswerBookingExperienceSurveyMutation(mutationsOptions);
    const [dismissSurvey] = useDismissBookingExperienceSurveyMutation(mutationsOptions);

    const onNext = useCallback(
        (form: FormState) => {
            const answer = formatScoreFormStateOnSubmit(form);
            void answerSurveyScore({ variables: answer });
            setScreenNumber((value) => value + 1);
        },
        [answerSurveyScore],
    );

    const onSubmit = useCallback(
        (form: FormState) => {
            const answer = formatQuestionsFormStateOnSubmit(form);
            void answerSurveyDetails({ variables: answer });
            setScreenNumber(SCREENS_AMOUNT);
        },
        [answerSurveyDetails],
    );

    const onDismiss = useCallback(() => {
        void dismissSurvey();
        onClose();
    }, [dismissSurvey, onClose]);

    const currentQuestions = useMemo(
        () =>
            (data?.bookingExperienceQuestions ?? []).filter(
                (question) => question.screenPosition === screenNumber,
            ),
        [data?.bookingExperienceQuestions, screenNumber],
    );

    useEffect(() => {
        let closeTimeoutId = 0;
        if (screenNumber === SCREENS_AMOUNT) {
            closeTimeoutId = window.setTimeout(onClose, 3000);
        }

        return () => clearTimeout(closeTimeoutId);
    }, [screenNumber, onClose]);

    if (!data?.bookingExperienceQuestions.length) {
        return <Loader minHeight={92} />;
    }

    if (screenNumber === SCREENS_AMOUNT) {
        return <CompleteFormState />;
    }

    const confirmAction = screenNumber === 1 ? onNext : onSubmit;
    const confirmLabel =
        screenNumber < SCREENS_AMOUNT - 1
            ? trans('next', {}, 'student-common')
            : trans('submit-feedback', {}, 'student-common');

    return (
        <BookingFeedbackFormFields
            questions={currentQuestions}
            onClose={onDismiss}
            onConfirm={confirmAction}
            confirmLabel={confirmLabel}
            screenNumber={screenNumber}
            screensAmount={SCREENS_AMOUNT}
        />
    );
};

import { useMount } from '@lingoda/hooks';
import goTo from '../goTo';
import type { Path } from '../types';

interface RedirectProps {
    to: Path;
    replace?: boolean;
}

export const Redirect = ({ to, replace }: RedirectProps) => {
    useMount(() => {
        goTo(to, { replace });
    });

    return null;
};

import { buildClassSubtitle, isClassBooked } from '@lingoda/classes';
import { isLessonLocked, isLessonStatusAttended } from '@lingoda/lessons';
import { getChapterName } from '@lingoda/learning-units';
import type { BookableClass } from '@lingoda/booking';
import { getStudentAvatarsFromClass } from '../../helpers/bookingClassElement';

export const getBookClassElementProps = (klass: BookableClass) => {
    const { lesson } = klass;
    const subtitle = klass && buildClassSubtitle(klass.type, klass.teacher?.firstName);
    const lessonAttended = isLessonStatusAttended(lesson);
    const lessonLocked = isLessonLocked(lesson);
    const isBooked = isClassBooked(klass);
    const studentAvatars = getStudentAvatarsFromClass(klass);
    const chapterName = klass.learningUnit ? getChapterName(klass.learningUnit) : '';

    return {
        subtitle,
        lessonAttended,
        lessonLocked,
        isBooked,
        studentAvatars,
        chapterName,
    };
};

import { useCallback } from 'react';
import { goTo, onboardingBookingPath, onboardingCongratulationsPath } from '@lingoda/router';
import useCanBookOnboardingClasses from './useCanBookOnboardingClasses';

const useOnboardingBookingHandler = () => {
    const canBookClasses = useCanBookOnboardingClasses();

    const handleOnboardingBooking = useCallback(() => {
        if (canBookClasses) {
            goTo(onboardingBookingPath());
        } else {
            goTo(onboardingCongratulationsPath());
        }
    }, [canBookClasses]);

    return handleOnboardingBooking;
};

export default useOnboardingBookingHandler;

import type { Action } from '@lingoda/core';
import type { Middleware } from 'redux';

const handle = import('./serverToState/handle');

const middleware: Middleware =
    (store) =>
    (next) =>
    <T extends Action<unknown>>(action: T) => {
        void (async () => {
            (await handle).default(store, action);
        })();

        return next(action);
    };

export default middleware;

import { PersonGroupIcon16, PersonGroupIcon24 } from '../../icons/PersonGroup';
import { PersonIcon16, PersonIcon24 } from '../../icons/Person';
import type { SvgIconProps } from '../../layout/SvgIcon';

type LingodaClassType = 'individual' | 'group' | 'oc_individual' | 'oc_group' | 'trial';

interface Props extends Omit<SvgIconProps, 'color'> {
    size?: number;
    type: LingodaClassType;
    color?: string;
}

export const LingodaClassIcon = ({ type, color, size = 24, ...props }: Props) => {
    const ClassIcon = getLingodaClassIcon(type, size);

    return (
        <ClassIcon
            {...props}
            style={{
                width: size,
                height: size,
                color: color,
            }}
        />
    );
};

export const getLingodaClassIcon = (type: LingodaClassType, size: number) => {
    switch (type) {
        case 'individual':
        case 'oc_individual':
        case 'trial':
            return size < 24 ? PersonIcon16 : PersonIcon24;
        case 'group':
        case 'oc_group':
            return size < 24 ? PersonGroupIcon16 : PersonGroupIcon24;
    }
};

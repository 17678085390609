import merge from 'lodash/merge';
import { type ThemeOptions, createTheme } from '@mui/material/styles';
import { baseFontSize, typography } from './typography';
import { linkPrimaryStyles, linkStyles, translatedLinkStyles } from './links';
import { colorsPalette } from './colorsPalette';
import { getAlertVariants } from './alert';
import { type ChipColor, getChipVariants } from './chip';
import { gradients } from './gradients';
import { breakpoints } from './breakpoints';
import { getButtonVariants } from './button';
import { dvh } from './utils';

export type { ChipColor, ThemeOptions };
export const unit = 8;
export const MIN_HEIGHT_HEADER = 64;
export const MODAL_Z_INDEX = 1300;
export const SNACKBAR_Z_INDEX = 1400; // notistack forces 1400 so let's keep it consistent

export const primary = {
    light: colorsPalette.primarySolid[12],
    main: colorsPalette.primarySolid[100],
    dark: colorsPalette.primaryCustom.dark,
};

export const secondary = {
    light: colorsPalette.primarySolid[12],
    main: colorsPalette.primarySolid[100],
    dark: colorsPalette.primaryCustom.dark,
};

const success = {
    light: colorsPalette.positiveSolid[12],
    main: colorsPalette.positiveSolid[100],
    dark: colorsPalette.positiveCustom.dark,
};

const warning = {
    light: colorsPalette.infoSolid[12],
    main: colorsPalette.infoSolid[100],
    dark: colorsPalette.infoCustom.dark,
};

const error = {
    light: colorsPalette.negativeSolid[12],
    main: colorsPalette.negativeSolid[100],
    dark: colorsPalette.negativeCustom.dark,
};

const promotion = {
    light: colorsPalette.purpleSolid[12],
    main: colorsPalette.purpleSolid[100],
    dark: colorsPalette.purpleCustom.dark,
    contrastText: colorsPalette.purpleSolid[100],
};

const pink = {
    main: colorsPalette.pinkSolid[100],
    contrastText: colorsPalette.pinkCustom.dark,
};

const grayscale = {
    light: colorsPalette.blackAlpha[5],
    main: colorsPalette.blackAlpha[20],
    contrastText: colorsPalette.blackSolid[100],
};

const borderWidthDefault = 1;

const buttonBorderWidth = borderWidthDefault;
const buttonPadding = {
    small: { y: 1, x: 1.5 },
    medium: { y: 1.75, x: 2.5 },
    large: { y: 1.75, x: 2.5 },
};
const buttonMargin = {
    small: { y: 1, x: 1 },
    medium: { y: 1.5, x: 1.5 },
    large: { y: 1.75, x: 1.75 },
};

const muiDefaultFadeTransition = '225ms cubic-bezier(0.4, 0, 0.2, 1)';

export const shadowElevationsFactory = {
    level1(colorA = colorsPalette.blackAlpha[16]) {
        return `0px 1px 4px ${colorA}`;
    },
    level2(colorA = colorsPalette.blackAlpha[8], colorB = colorsPalette.blackAlpha[12]) {
        return `0px 2px 10px -2px ${colorA}, 0px 4px 16px -4px ${colorB}`;
    },
    level3(colorA = colorsPalette.blackAlpha[1], colorB = colorsPalette.blackAlpha[16]) {
        return `0px 4px 16px -4px ${colorA}, 0px 10px 32px -8px ${colorB}`;
    },
    level4(colorA = colorsPalette.blackAlpha[16], colorB = colorsPalette.blackAlpha[8]) {
        return `0px 10px 48px -8px ${colorA}, 0px 24px 72px -8px ${colorB}, 0px 6px 16px -8px ${colorB}`;
    },
};

const shadows = (() => {
    const muiShadows = createTheme().shadows;

    muiShadows[1] = shadowElevationsFactory.level1();
    muiShadows[2] = shadowElevationsFactory.level2();
    muiShadows[3] = shadowElevationsFactory.level3();
    muiShadows[4] = shadowElevationsFactory.level4();

    return muiShadows;
})();

const common: ThemeOptions = {
    breakpoints,
    zIndex: {
        modal: MODAL_Z_INDEX,
        snackbar: SNACKBAR_Z_INDEX,
    },
    palette: {
        mode: 'light',
        primary,
        secondary,
        common: {
            white: colorsPalette.whiteSolid[100],
        },
        text: {
            primary: colorsPalette.blackSolid[100],
        },
        success,
        warning,
        error,
        promotion,
        pink,
        action: {
            disabledBackground: colorsPalette.blackAlpha[20],
            disabled: colorsPalette.blackAlpha[20],
        },
        background: {
            default: colorsPalette.blackSolid[3],
        },
        gradients,
        grayscale,
    },
    shadows,
    spacing: unit,
    borderWidth: borderWidthDefault,
    typography,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                // hide percy elements
                '@media only percy': {
                    '.hide-in-percy': {
                        visibility: 'hidden',
                    },
                },
                html: {
                    height: '100%',
                    fontSize: baseFontSize,
                },
                body: {
                    backgroundColor: colorsPalette.blackSolid[3],
                    color: colorsPalette.blackSolid[100],
                    display: 'flex',
                    flexDirection: 'column',
                    fontFamily: `'Open Sans', sans-serif`,
                    WebkitFontSmoothing: 'antialiased',
                    fontSize: baseFontSize,
                    fontWeight: 400,
                    minHeight: dvh('100dvh'),
                    margin: 0,
                    overflowX: 'hidden',
                    '& > div:first-of-type': {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    },
                },
                svg: {
                    flexShrink: 0,
                    flexGrow: 0,
                },
                '*, *::before, *::after': {
                    boxSizing: 'border-box',
                },
                '#root': {
                    flexGrow: 1,
                },
                '.list-ol, .list-ul': {
                    listStylePosition: 'inside',
                    paddingInlineStart: 0,
                    '& li': {
                        marginBottom: unit,
                    },
                },
                '.translated-item a': translatedLinkStyles,
            },
        },
        MuiButton: {
            variants: getButtonVariants(),
            styleOverrides: {
                root: {
                    borderRadius: unit * 0.5,
                },
                contained: {
                    '&:disabled': {
                        color: colorsPalette.whiteSolid[100],
                    },
                },
                text: {
                    minWidth: 'auto',
                },
                textPrimary: {
                    '&:hover': {
                        backgroundColor: colorsPalette.primaryAlpha[8],
                        color: primary.dark,
                    },
                    '&:active': {
                        color: primary.dark,
                    },
                },
                sizeSmall: ({ ownerState, theme }) => ({
                    ...typography.body3,

                    ...((ownerState.variant === 'contained' || ownerState.variant === 'text') && {
                        padding: theme.spacing(buttonPadding.small.y, buttonPadding.small.x),
                    }),

                    ...(ownerState.variant === 'outlined' && {
                        padding: theme.spacing(
                            buttonPadding.small.y - buttonBorderWidth / unit,
                            buttonPadding.small.x - buttonBorderWidth / unit,
                        ),
                    }),
                }),
                sizeMedium: ({ ownerState, theme }) => ({
                    ...((ownerState.variant === 'contained' || ownerState.variant === 'text') && {
                        padding: theme.spacing(buttonPadding.medium.y, buttonPadding.medium.x),
                    }),

                    ...(ownerState.variant === 'outlined' && {
                        padding: theme.spacing(
                            buttonPadding.medium.y - buttonBorderWidth / unit,
                            buttonPadding.medium.x - buttonBorderWidth / unit,
                        ),
                    }),
                }),
                sizeLarge: ({ ownerState, theme }) => ({
                    ...typography.subtitle1,

                    ...((ownerState.variant === 'contained' || ownerState.variant === 'text') && {
                        padding: theme.spacing(buttonPadding.large.y, buttonPadding.large.x),
                    }),

                    ...(ownerState.variant === 'outlined' && {
                        padding: theme.spacing(
                            buttonPadding.large.y - buttonBorderWidth / unit,
                            buttonPadding.large.x - buttonBorderWidth / unit,
                        ),
                    }),
                }),
                startIcon: {
                    transition: `opacity ${muiDefaultFadeTransition}`,
                },
                endIcon: {
                    transition: `opacity ${muiDefaultFadeTransition}`,
                },
                iconSizeSmall: {
                    '& > *:nth-of-type(1)': {
                        fontSize: 14,
                    },
                    '&.MuiButton-startIcon': {
                        marginRight: 4,
                    },
                    '&.MuiButton-endIcon': {
                        marginLeft: 4,
                    },
                },
                iconSizeMedium: {
                    '& > *:nth-of-type(1)': {
                        fontSize: 16,
                    },
                },
                iconSizeLarge: {
                    '& > *:nth-of-type(1)': {
                        fontSize: 24,
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...typography.body2,
                    color: colorsPalette.blackAlpha[70],
                    borderColor: colorsPalette.blackAlpha[20],
                    borderRadius: unit * 0.5,
                    textTransform: 'none',
                    padding: unit * 1.5,
                    '@media (hover: hover) and (pointer: fine)': {
                        '&:hover': {
                            borderColor: colorsPalette.blackSolid[100],
                            backgroundColor: 'transparent',
                        },
                    },
                    '&.Mui-selected': {
                        color: primary.main,
                        borderColor: `${primary.main}`,
                        backgroundColor: colorsPalette.primaryAlpha[8],
                        '&:focus, &:active': {
                            backgroundColor: colorsPalette.primaryAlpha[8],
                        },
                        '&:hover': {
                            backgroundColor: colorsPalette.primaryAlpha[20],
                        },
                    },
                    '&.Mui-disabled': {
                        borderColor: colorsPalette.blackAlpha[20],
                        color: colorsPalette.blackAlpha[20],
                        backgroundColor: colorsPalette.blackAlpha[4],
                        '&.Mui-selected': {
                            borderColor: colorsPalette.blackSolid[100],
                        },
                    },
                    '&:focus, &:active': {
                        boxShadow: shadows[1],
                        outline: '1px solid rgba(21, 59, 255, 0.4)',
                        outlineOffset: 1,
                    },
                    '&.MuiToggleButtonGroup-grouped': {
                        '&:hover': {
                            borderColor: colorsPalette.blackSolid[100],
                        },
                        '&.Mui-selected:not(:first-of-type)': {
                            borderColor: `${primary.main}`,
                        },
                    },
                    '&.MuiToggleButton-sizeSmallSeparated': {
                        paddingTop: theme.spacing(buttonPadding.small.y - buttonBorderWidth / unit),
                        paddingBottom: theme.spacing(
                            buttonPadding.small.y - buttonBorderWidth / unit,
                        ),
                    },
                    '&.MuiToggleButton-sizeMediumSeparated': {
                        paddingTop: theme.spacing(
                            buttonPadding.medium.y - buttonBorderWidth / unit,
                        ),
                        paddingBottom: theme.spacing(
                            buttonPadding.medium.y - buttonBorderWidth / unit,
                        ),
                    },
                }),
                sizeSmall: ({ theme }) => ({
                    paddingTop: theme.spacing(buttonPadding.small.y - buttonBorderWidth / unit),
                    paddingBottom: theme.spacing(buttonPadding.small.y - buttonBorderWidth / unit),
                }),
                sizeMedium: ({ theme }) => ({
                    paddingTop: theme.spacing(buttonPadding.medium.y - buttonBorderWidth / unit),
                    paddingBottom: theme.spacing(buttonPadding.medium.y - buttonBorderWidth / unit),
                }),
                sizeLarge: ({ theme }) => ({
                    ...typography.subtitle1,
                    fontWeight: 400,
                    paddingTop: theme.spacing(buttonPadding.large.y - buttonBorderWidth / unit),
                    paddingBottom: theme.spacing(buttonPadding.large.y - buttonBorderWidth / unit),
                }),
            },
        },
        MuiToggleButtonGroup: {
            variants: [
                {
                    props: { size: 'smallSeparated' },
                    style: ({ theme }) => ({
                        marginLeft: theme.spacing(-buttonMargin.small.x),
                        marginBottom: theme.spacing(-buttonMargin.small.y),

                        '&.MuiToggleButtonGroup-fullWidth': {
                            width: `calc(100% + ${unit * buttonMargin.small.x}px)`,
                        },
                        '&:not(.MuiToggleButtonGroup-fullWidth)': {
                            flexWrap: 'wrap',
                        },
                        [`& .MuiToggleButtonGroup-grouped,
                          & .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-middleButton,
                          & .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-lastButton`]: {
                            marginLeft: `${theme.spacing(buttonMargin.small.x)}`,
                            marginBottom: `${theme.spacing(buttonMargin.small.y)}`,
                            borderRadius: unit / 2,
                            borderLeft: `1px solid ${colorsPalette.blackAlpha[20]}`,
                            borderTop: `1px solid ${colorsPalette.blackAlpha[20]}`,
                            '&:hover': {
                                borderColor: colorsPalette.primarySolid[100],
                            },
                        },
                        '& .MuiToggleButtonGroup-grouped.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected':
                            {
                                borderLeft: `1px solid ${colorsPalette.primarySolid[100]}`,
                                borderTop: `1px solid ${colorsPalette.primarySolid[100]}`,
                                marginLeft: theme.spacing(buttonMargin.small.x),
                            },
                    }),
                },
                {
                    props: { size: 'mediumSeparated' },
                    style: ({ theme }) => ({
                        marginLeft: theme.spacing(-buttonMargin.medium.x),
                        marginBottom: theme.spacing(-buttonMargin.medium.y),

                        '&.MuiToggleButtonGroup-fullWidth': {
                            width: `calc(100% + ${unit * buttonMargin.medium.x}px)`,
                        },
                        '&:not(.MuiToggleButtonGroup-fullWidth)': {
                            flexWrap: 'wrap',
                        },
                        [`& .MuiToggleButtonGroup-grouped,
                          & .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-middleButton,
                          & .MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-lastButton`]: {
                            marginLeft: `${theme.spacing(buttonMargin.medium.x)}`,
                            marginBottom: `${theme.spacing(buttonMargin.medium.y)}`,
                            borderRadius: unit / 2,
                            borderLeft: `1px solid ${colorsPalette.blackAlpha[20]}`,
                            borderTop: `1px solid ${colorsPalette.blackAlpha[20]}`,
                            '&:hover': {
                                borderColor: colorsPalette.primarySolid[100],
                            },
                        },
                        '& .MuiToggleButtonGroup-grouped.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected':
                            {
                                borderLeft: `1px solid ${colorsPalette.primarySolid[100]}`,
                                borderTop: `1px solid ${colorsPalette.primarySolid[100]}`,
                                marginLeft: theme.spacing(buttonMargin.medium.x),
                            },
                    }),
                },
            ],
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: unit * 1.5,
                },
            },
            variants: [
                {
                    props: { color: 'default' },
                    style: {
                        color: colorsPalette.blackSolid[100],
                    },
                },
            ],
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    ...linkStyles.root,
                    textDecorationColor: 'currentcolor',
                },
            },
            variants: [
                {
                    props: { color: 'primary' },
                    style: {
                        ...linkPrimaryStyles.root,
                        ':hover': {
                            ...linkPrimaryStyles.hover,
                        },
                    },
                },
            ],
        },
        MuiAlert: {
            variants: getAlertVariants(),
            styleOverrides: {
                root: {
                    ...typography.body2,
                    padding: unit * 3,
                    color: colorsPalette.blackAlpha[70],
                    borderRadius: unit,
                },
                message: {
                    padding: '2px 0 0',
                },
                icon: {
                    opacity: 1,
                    fontSize: '1.5rem',
                    padding: 0,
                    marginRight: unit * 2,
                },
                action: {
                    padding: `${unit * 0.5}px 0 0 ${unit * 2}px`,
                    margin: `0 0 0 auto`,
                    '& .MuiIconButton-root': {
                        color: colorsPalette.blackAlpha[70],
                        padding: unit,
                        margin: -unit,
                        ':hover': {
                            backgroundColor: colorsPalette.blackAlpha[4],
                        },
                        ':focus': {
                            backgroundColor: colorsPalette.blackAlpha[8],
                        },
                    },
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    ...typography.subtitle1,
                    marginTop: '-1px',
                },
            },
        },
        MuiChip: {
            variants: getChipVariants(),
            styleOverrides: {
                root: {
                    ...typography.body3,
                    borderRadius: unit / 2,
                },
                sizeSmall: {
                    paddingTop: unit / 2,
                    paddingBottom: unit / 2,
                },
                icon: {
                    marginLeft: unit,
                    color: 'inherit',
                    fontSize: 16,
                    width: '1em',
                    height: '1em',
                },
                avatar: {
                    color: colorsPalette.whiteSolid[100],
                },
                label: {
                    color: 'inherit',
                },
                deleteIcon: {
                    marginRight: unit,
                    color: 'inherit',
                    '&:hover': {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                popupIndicatorOpen: {
                    transform: 'none',
                },
                endAdornment: {
                    top: 'unset',
                    lineHeight: 1,
                },
                paper: {
                    boxShadow: shadows[2],
                    border: 0,
                    borderRadius: unit * 0.5,
                },
                listbox: {
                    paddingTop: unit * 2,
                    paddingBottom: unit * 2,
                },
                option: {
                    '&.MuiAutocomplete-option[role="option"]': {
                        minHeight: unit * 6,
                    },
                    '&.MuiAutocomplete-option[aria-selected="true"], &.MuiAutocomplete-option[aria-selected="true"].Mui-focused':
                        {
                            background: colorsPalette.blackAlpha[8],
                        },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: unit,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: unit * 2,
                    minHeight: unit * 2.5,
                    height: unit * 2.5,
                    lineHeight: `${unit * 2.5}px`,
                },
                iconOutlined: {
                    fontSize: unit * 2,
                    right: unit * 1.25,
                    top: `calc(50% - ${unit}px)`,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&[role="option"]': {
                        minHeight: unit * 6,
                    },
                    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible': {
                        background: colorsPalette.blackAlpha[8],
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: MIN_HEIGHT_HEADER,
                    textTransform: 'inherit',
                    padding: unit,
                    ...typography.subtitle1,
                    [breakpoints.up('sm')]: {
                        minWidth: 'initial',
                    },
                },
                textColorInherit: {
                    opacity: 1,
                    '&:not(.Mui-selected)': {
                        color: colorsPalette.blackAlpha[70],
                    },
                    '&.Mui-selected': {
                        color: colorsPalette.blackSolid[100],
                    },
                    '&:not(.Mui-selected):hover, &:not(.Mui-selected):active, &:not(.Mui-selected):focus':
                        {
                            color: colorsPalette.blackCustom.dark,
                        },
                },
            },
        },
        MuiTableCell: {
            defaultProps: {
                align: 'left',
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    borderColor: colorsPalette.blackAlpha[20],
                    ...theme.typography.body2,
                    fontVariantNumeric: 'tabular-nums',
                }),
                head: ({ theme }) => ({
                    ...theme.typography.subtitle2,
                    color: colorsPalette.blackAlpha[70],
                }),
                sizeMedium: ({ theme, ownerState }) => ({
                    ...(ownerState.padding === 'normal' && {
                        padding: theme.spacing(2, 1),

                        ...(ownerState.variant === 'head' && {
                            paddingBottom: theme.spacing(1),
                        }),
                    }),
                }),
                sizeSmall: ({ theme, ownerState }) => ({
                    ...(ownerState.padding === 'normal' && {
                        padding: theme.spacing(1, 0.5),

                        ...(ownerState.variant === 'head' && {
                            paddingBottom: theme.spacing(1),
                        }),
                    }),
                }),
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    input: {
                        height: 19,
                    },
                    '.MuiInputBase-root.Mui-disabled': {
                        background: colorsPalette.blackSolid[8],
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: unit,
                },
                outlined: {
                    border: `2px solid ${colorsPalette.blackSolid[8]}`,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                rounded: {
                    borderRadius: unit,
                    '&:first-of-type': {
                        borderTopLeftRadius: unit,
                        borderTopRightRadius: unit,
                    },
                    '&:last-of-type': {
                        borderBottomLeftRadius: unit,
                        borderBottomRightRadius: unit,
                    },
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: {
                    color: colorsPalette.purpleSolid[100],
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: colorsPalette.primaryAlpha[20],
                },
                barColorPrimary: {
                    backgroundImage: `linear-gradient(90deg, ${primary.main} 0%, ${colorsPalette.purpleSolid[100]} 100%)`,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    zIndex: MODAL_Z_INDEX,
                },
                paper: {
                    minWidth: 280,
                    borderRadius: 0,
                    justifyContent: 'space-between',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: colorsPalette.blackSolid[100],
                    padding: unit * 1.5,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    height: 'auto',
                    color: colorsPalette.blackSolid[100],
                    padding: unit * 1.3125,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    marginRight: 2,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '1rem',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                gutters: {
                    [breakpoints.only('sm')]: {
                        padding: `0 ${2 * unit}px`,
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: 4,
                    minWidth: 140,
                },
                valueLabel: {
                    top: -22,
                    fontWeight: 600,
                    '& *': {
                        background: 'transparent',
                        color: 'currentColor',
                    },
                },
                rail: {
                    height: 2,
                    backgroundColor: colorsPalette.blackAlpha[20],
                },
                track: {
                    height: 4,
                },
                thumb: {
                    width: 20,
                    height: 20,
                    marginTop: 2,
                    ':before, :after': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: colorsPalette.blackAlpha[8],
                },
                light: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: shadows[2],
                    borderRadius: unit * 0.5,
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected:hover, &.Mui-selected': {
                        color: colorsPalette.primarySolid[100],
                        backgroundColor: colorsPalette.blackAlpha[8],
                    },
                },
                button: {
                    '&:hover': {
                        color: colorsPalette.primarySolid[100],
                        backgroundColor: colorsPalette.blackAlpha[4],
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 600,
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: '1rem', // 16px
                },
                fontSizeMedium: {
                    fontSize: '1.5rem', // 24px
                },
                fontSizeLarge: {
                    fontSize: '2rem', // 32px
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: colorsPalette.primarySolid[100],
                    borderRadius: unit * 0.5,
                },
            },
        },
        MuiSkeleton: {
            variants: [
                {
                    props: { variant: 'rounded' },
                    style: { borderRadius: unit * 0.5 },
                },
            ],
            styleOverrides: {
                root: {
                    backgroundColor: '#0000000a',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    position: 'relative',
                    ...typography.overline,
                    textTransform: 'none',
                    padding: '10px 15px',
                    background: colorsPalette.blackSolid[100],
                },
                arrow: {
                    color: colorsPalette.blackSolid[100],
                },
                tooltipPlacementLeft: {
                    margin: '0 8px',
                },
                tooltipPlacementRight: {
                    margin: '0 8px',
                },
                tooltipPlacementTop: {
                    margin: '8px 0',
                },
                tooltipPlacementBottom: {
                    margin: '8px 0',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: colorsPalette.blackAlpha[70],
                },
                invisible: {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    transitions: {
        duration: {
            enteringScreen: 300,
        },
    },
};

const commonInverted = merge({}, common, {
    palette: {
        mode: 'dark',
        primary: {
            main: colorsPalette.whiteSolid[100],
            dark: colorsPalette.primarySolid[4],
            contrastText: colorsPalette.primarySolid[100],
        },
        action: {
            disabled: colorsPalette.whiteAlpha[60],
            disabledBackground: colorsPalette.whiteAlpha[20],
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    '&:disabled': {
                        color: colorsPalette.whiteAlpha[60],
                    },
                },
                textPrimary: {
                    '&:hover': {
                        backgroundColor: colorsPalette.whiteAlpha[20],
                        color: colorsPalette.whiteSolid[100],
                    },
                    '&:active': {
                        color: colorsPalette.whiteSolid[100],
                    },
                },
            },
        },
    },
});

export const theme = createTheme(common);
export const themeInverted = createTheme(commonInverted);

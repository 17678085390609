import { Translate, trans } from '@lingoda/i18n';
import { SettingsPages, resolvePath, studentSettingsPath, useRouteMatcher } from '@lingoda/router';
import { DialogConfirm, type DialogConfirmProps, Grid, Typography } from '@lingoda/ui';

export type Props = Pick<
    DialogConfirmProps,
    'isOpen' | 'confirmStatus' | 'onConfirm' | 'onDismiss'
>;

const ConfirmRolloverTrialDialog = (props: Props) => {
    const isMembershipPath = useRouteMatcher(
        studentSettingsPath({ page: SettingsPages.Membership }),
    );

    return (
        <DialogConfirm
            {...props}
            title={trans('skip-trial-confirm-header', {}, 'student-common')}
            confirmLabel={trans('btn-skip-trial-confirm', {}, 'student-common')}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Translate
                        component={Typography}
                        params={{
                            manageSubscriptionsPath: resolvePath(
                                studentSettingsPath({
                                    page: SettingsPages.Membership,
                                }),
                            ),
                        }}
                        id="skip-trial-confirm-content"
                        domain="student-common"
                    />
                </Grid>

                {!isMembershipPath && (
                    <Grid item xs={12}>
                        <Translate
                            component={Typography}
                            params={{
                                manageSubscriptionsPath: resolvePath(
                                    studentSettingsPath({
                                        page: SettingsPages.Membership,
                                    }),
                                ),
                            }}
                            id="skip-trial-confirm-content-navigation"
                            domain="student-common"
                        />
                    </Grid>
                )}
            </Grid>
        </DialogConfirm>
    );
};

export default ConfirmRolloverTrialDialog;

import { trans } from '@lingoda/i18n';
import { DangerousHtml, DialogConfirm, type DialogConfirmProps, Typography } from '@lingoda/ui';

export interface ConfirmRolloverDialog extends ConfirmDialogProps {
    price: string;
    isRecurringSubscription: boolean;
}

type ConfirmDialogProps = Pick<
    DialogConfirmProps,
    'isOpen' | 'confirmStatus' | 'onConfirm' | 'onDismiss'
>;

export const ConfirmRolloverDialog = ({
    price,
    isRecurringSubscription,
    ...dialogProps
}: ConfirmRolloverDialog) => (
    <DialogConfirm
        {...dialogProps}
        title={trans('subscription-rollover-title', {}, 'student-common')}
        confirmLabel={trans('btn-confirm', {}, 'public-common')}
    >
        <DangerousHtml as={Typography} variant="body1">
            {isRecurringSubscription
                ? trans('recurring-subscription-rollover-text', { price: price }, 'student-common')
                : trans('subscription-rollover-text', { price: price }, 'student-common')}
        </DangerousHtml>
    </DialogConfirm>
);

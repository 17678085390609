import { getDefaultTimeFormat, useRegisterQueryParams } from '@lingoda/register';
import { type CurriculumId } from '@lingoda/sections';
import { useRegisterForm } from './useRegisterForm';
import { useSocialOptions } from './useSocialOptions';

interface Params {
    curriculumId?: CurriculumId;
    section?: SectionName;
}

export const useRegister = ({ curriculumId, section }: Params) => {
    const queryParams = useRegisterQueryParams({
        curriculumId,
        timeFormat: getDefaultTimeFormat(),
    });
    const registerParams = { ...queryParams, section };

    const { handleSubmit } = useRegisterForm({ registerParams });

    const { disabled, handleClick, resourceOwners } = useSocialOptions({ registerParams });

    return {
        disabled,
        handleClick,
        handleSubmit,
        resourceOwners,
    };
};

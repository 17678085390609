import { type PropsWithChildren } from 'react';
import { Box, keyframes } from '@lingoda/ui';

const ROTATE_DEGREES = '30deg';
const SLIDE_DISTANCE = '30%';

const rotateLeftAnimation = keyframes`
    40% {
        opacity: 1;
        transform: rotate(0deg) translateX(0);
    }
    100% {
        opacity: 0;
        transform: rotate(-${ROTATE_DEGREES}) translateX(-${SLIDE_DISTANCE});
    }
`;

const rotateRightAnimation = keyframes`
    40% {
        opacity: 1;
        transform: rotate(0deg) translateX(0);
    }
    100% {
        opacity: 0;
        transform: rotate(${ROTATE_DEGREES}) translateX(${SLIDE_DISTANCE});
    }
`;

interface Props {
    direction: 'left' | 'right';
    duration: number;
    isHidden: boolean;
}

export const AnimationNextCard = ({
    children,
    direction,
    duration,
    isHidden,
}: PropsWithChildren<Props>) => {
    return (
        <Box
            width="100%"
            display="flex"
            sx={{
                transformOrigin: direction === 'left' ? 'bottom left' : 'bottom right',
                animation: isHidden
                    ? direction === 'left'
                        ? `${rotateLeftAnimation}`
                        : `${rotateRightAnimation}`
                    : `none`,
                animationDuration: `${duration}ms`,
                animationFillMode: 'forwards',
            }}
        >
            {children}
        </Box>
    );
};

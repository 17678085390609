import { StudentStatus } from '@lingoda/graphql';
import { StudentStatus as StudentStatusLegacy } from '@lingoda/api';

enum StudentStatusComputed {
    Locked = 'locked',
    CompletedImproved = 'completed-improved',
}

export interface ComputeLessonStatusArgs {
    completedCurrentVersion: boolean;
    locked: boolean;
    scheduled: boolean;
    studentStatus: StudentStatus;
}

export type LessonStatus = StudentStatus | StudentStatusComputed;
export const LessonStatus = { ...StudentStatus, ...StudentStatusComputed };

export const computeLessonStatus = ({
    studentStatus,
    scheduled,
    completedCurrentVersion,
    locked,
}: ComputeLessonStatusArgs): LessonStatus => {
    if (locked) {
        return LessonStatus.Locked;
    }

    if (studentStatus === StudentStatus.Retake && scheduled) {
        return LessonStatus.Booked;
    }

    if (studentStatus === StudentStatus.Completed || studentStatus === StudentStatus.Retake) {
        return completedCurrentVersion ? LessonStatus.Completed : LessonStatus.CompletedImproved;
    }

    return studentStatus;
};

const legacyStudentStatusToStudentStatusMap = {
    [StudentStatusLegacy.Booked]: StudentStatus.Booked,
    [StudentStatusLegacy.Completed]: StudentStatus.Completed,
    [StudentStatusLegacy.NotTaken]: StudentStatus.NotTaken,
    [StudentStatusLegacy.Pending]: StudentStatus.Pending,
    [StudentStatusLegacy.Retake]: StudentStatus.Retake,
    [StudentStatusLegacy.Skipped]: StudentStatus.Skip,
};

export const mapLegacyStudentStatus = (studentStatus: StudentStatusLegacy) => {
    return legacyStudentStatusToStudentStatusMap[studentStatus];
};

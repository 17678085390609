import { useState } from 'react';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { CardElement, type StripeCardElement, type StripeCardElementChangeEvent } from '../models';

interface Props {
    onChange?: (event: StripeCardElementChangeEvent) => void;
    onReady?: (el: StripeCardElement) => void;
}

export const CreditCard = ({ onChange, onReady }: Props) => {
    const [error, setError] = useState<string>();

    const onCardElementChange = (event: StripeCardElementChangeEvent) => {
        if (event && event.error) {
            setError(event.error.message);
        } else {
            setError(undefined);
        }
        if (onChange) {
            onChange(event);
        }
    };

    const onCardElementReady = (el: StripeCardElement) => {
        if (onReady && el) {
            onReady(el);
        }
    };

    return (
        <>
            <CardElementStyled
                id="lingoda-card"
                options={{
                    style: {
                        base: {
                            // this has to be hardcoded since there is a flickering if you use rems
                            fontSize: '16px',
                            fontFamily: 'Open Sans',
                            color: colorsPalette.blackSolid[100],
                            '::placeholder': {
                                color: colorsPalette.blackAlpha[70],
                            },
                        },
                    },
                }}
                onChange={onCardElementChange}
                onReady={onCardElementReady}
            />
            <FormHelperText error>{error}</FormHelperText>
        </>
    );
};

const CardElementStyled = styled(CardElement)(({ theme }) => ({
    width: '100%',
    cursor: 'text',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2, 1.75),
    background: theme.palette.common.white,
    border: `1px solid ${colorsPalette.blackAlpha[20]}`,
    borderWidth: 2,
    '&:hover': {
        borderColor: colorsPalette.blackAlpha[70],
    },
    '&.StripeElement--focus': {
        borderColor: theme.palette.secondary.main,
    },
}));

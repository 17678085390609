import { trans } from '@lingoda/i18n';
import { theme } from '../../theme';
import { Button } from '../../buttons';
import { GuidanceIllustration } from '../../illustrations';
import { Box } from '../Box';
import { Alert } from '../Alert';

export interface Props {
    title: string;
    body: string;
    onDismiss: () => void;
    icon?: React.ReactNode | false;
}

export const GuidanceBanner = ({ title, body, onDismiss, icon }: Props) => (
    <Alert
        severity="promotion"
        variant="outlined"
        title={title}
        icon={icon === false ? false : icon ?? <GuidanceIllustration />}
        aria-label={title}
    >
        <Box mb={2}>{body}</Box>
        <Button
            color="primary"
            variant="contained"
            onClick={onDismiss}
            sx={{ background: theme.palette.gradients.darkPurpleToPrimaryDark }}
        >
            {trans('got-it', {}, 'student-common')}
        </Button>
    </Alert>
);

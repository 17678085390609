import { Box, Typography, colorsPalette, styled } from '@lingoda/ui';
import BubbleArrowImage from './bubble-arrow.svg?component';
import { WomanIcon } from './WomanIcon';

type Size = 'small' | 'medium';

interface BubbleSpeechProps {
    text: string;
    size?: Size;
}

export const BubbleSpeechProgress = ({ text, size }: BubbleSpeechProps) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <WomanImageStyled size={size} />
            <BubbleArrowImage />
            <Bubble>
                <Typography variant="body2">{text}</Typography>
            </Bubble>
        </Box>
    );
};

const Bubble = styled(Box)(({ theme }) => ({
    background: colorsPalette.purpleSolid[12],
    color: colorsPalette.blackAlpha[70],
    padding: theme.spacing(3),
    borderRadius: theme.spacing(4),
    maxWidth: 200,
}));

const WomanImageStyled = styled(WomanIcon, { shouldForwardProp: (prop) => prop !== 'size' })<
    Pick<BubbleSpeechProps, 'size'>
>(({ theme, size }) => ({
    width: size ? womanImageSize[size] : womanImageSize.medium,
    height: size ? womanImageSize[size] : womanImageSize.medium,
    marginRight: theme.spacing(2),

    ...(!size && {
        [theme.breakpoints.down('sm')]: {
            width: womanImageSize.small,
            height: womanImageSize.small,
        },
    }),
}));

const womanImageSize: Record<Size, number> = {
    small: 90,
    medium: 122,
};

import { v4 as uuidV4 } from 'uuid';
import type { ClassesClassSchema } from '@lingoda/api';
import { createDate } from '@lingoda/dates';
import type { AppointmentStatus } from '../models';

type ClassesClassWithAppointmentSchema = ClassesClassSchema & {
    currentStudentAppointment: NonNullable<ClassesClassSchema>;
};

function filterByCurrentStudentAppointment(
    classItem: ClassesClassSchema,
): classItem is ClassesClassWithAppointmentSchema {
    return !!classItem.currentStudentAppointment;
}

export const extractAppointments = (classes: ClassesClassSchema[]) => {
    return classes
        .filter(filterByCurrentStudentAppointment)
        .map(({ currentStudentAppointment, id: classId }) => ({
            ...currentStudentAppointment,
            id: currentStudentAppointment.id || uuidV4(),
            status: currentStudentAppointment.status as AppointmentStatus,
            classId,
            startDate: createDate(currentStudentAppointment.startDate),
            createdAt: createDate(currentStudentAppointment.createdAt),
            endDate: createDate(currentStudentAppointment.endDate),
        }));
};

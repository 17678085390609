import { type BookingFilter } from '@lingoda/booking';
import {
    ClassTypeFilter,
    type FilterProps,
    MultipleDatesFilter,
    TimeSlotFilterMenuMobile,
} from '@lingoda/booking-filter';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { Box, Button, Drawer, Hairline, ScrollBox, Stack } from '@lingoda/ui';

export interface BookingFilterModalProps extends FilterProps<BookingFilter> {
    open: boolean;
    onClose: () => void;
    onReset: () => void;
    timeFormat: TimeFormat;
}

export const BookingFilterModal = ({ onClose, open, ...props }: BookingFilterModalProps) => {
    return (
        <Drawer
            variant="temporary"
            open={open}
            anchor="bottom"
            onClose={onClose}
            ModalProps={{ keepMounted: true }}
            PaperProps={{
                sx: {
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                    overflow: 'hidden',
                },
            }}
        >
            <DrawerContent {...props} onClose={onClose} />
        </Drawer>
    );
};

const DrawerContent = ({
    value,
    onChange,
    onClose,
    onReset,
    timeFormat,
}: Omit<BookingFilterModalProps, 'open'>) => {
    return (
        <Stack overflow="hidden" data-cy="Booking filter modal">
            <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
                <Button variant="text" size="small" onClick={onReset}>
                    {trans('btn-clear-all', {}, 'student-common')}
                </Button>
                <Button variant="text" size="small" color="primary" onClick={onClose}>
                    {trans('done', {}, 'student-common')}
                </Button>
            </Box>
            <Hairline />
            <ScrollBox>
                <Stack mt={3} mb={3} spacing={3} divider={<Hairline />}>
                    <Section>
                        <ClassTypeFilter value={value.classType} onChange={onChange} />
                    </Section>
                    <Section>
                        <MultipleDatesFilter
                            value={value.dates}
                            onChange={onChange}
                            timeSlots={value.timeSlots}
                        />
                    </Section>
                    <Section>
                        <TimeSlotFilterMenuMobile
                            value={value.timeSlots}
                            onChange={onChange}
                            timeFormat={timeFormat}
                        />
                    </Section>
                </Stack>
            </ScrollBox>
        </Stack>
    );
};

const Section = ({ children }: React.PropsWithChildren<unknown>) => <Box px={2}>{children}</Box>;

import { Typography } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import ToggleButton from '@lingoda/ui/buttons/ToggleButton';

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
    padding: 0,
    borderRadius: 0,
    margin: theme.spacing(1),
    color: colorsPalette.blackSolid[100],
    borderColor: colorsPalette.blackAlpha[8],
    width: `calc(25% - ${theme.spacing(2)}) `,
    '&.MuiToggleButtonGroup-grouped.MuiToggleButton-root:hover': {
        borderColor: colorsPalette.blackSolid[100],
    },
}));

export interface CalendarHourProps {
    label: string;
    value: string;
}

export const CalendarHour = ({ label, ...props }: CalendarHourProps) => (
    <ToggleButtonStyled {...props}>
        <Typography>{label}</Typography>
    </ToggleButtonStyled>
);

import merge from 'lodash/merge';
import { clearCacheAction, handleActionsImmutably } from '@lingoda/core';
import { AppointmentFactory } from '../models';
import {
    addAppointments,
    fetchClassesDone,
    removeAppointment,
    updateAppointment,
} from '../actions';
import { extractAppointments } from '../transformers';
import type { AppointmentId } from '../constants';
import type { Appointment } from '../models';

export type State = GenericObject<Appointment>;

const handleAddAppointment = (state: State, appointments: Appointment[]) => {
    appointments.forEach((appointmentItem) => {
        state[appointmentItem.id] = AppointmentFactory(appointmentItem);
    });

    return state;
};

const deleteAppointment = (state: State, appointmentId: AppointmentId) => {
    delete state[appointmentId];

    return state;
};

type RemoveAppointmentAction = ReturnType<typeof removeAppointment>;
type AppointmentsAction = ReturnType<typeof addAppointments>;
type ClassesResponseAction = ReturnType<typeof fetchClassesDone>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default handleActionsImmutably<State, any>(
    {
        [`${fetchClassesDone}`]: (state, { payload }: ClassesResponseAction) =>
            handleAddAppointment(state, extractAppointments(payload)),
        [`${addAppointments}`]: (state, { payload }: AppointmentsAction) =>
            handleAddAppointment(state, payload),
        [`${removeAppointment}`]: (state, { payload }: RemoveAppointmentAction) =>
            deleteAppointment(state, payload),
        [`${updateAppointment}`]: (
            state,
            { payload: { appointmentId, changes } }: ReturnType<typeof updateAppointment>,
        ) => {
            state[appointmentId] = state[appointmentId]
                ? merge(state[appointmentId], changes)
                : AppointmentFactory(changes);

            return state;
        },
        [`${clearCacheAction}`]: () => ({}),
    },
    {},
);

type COMPLETED = 'completed';
type NOT_ATTENDED = 'not_attended';
type NO_TEACHER = 'no_teacher';
type CANCELLED = 'cancelled';

export type StudentStatus = COMPLETED | NOT_ATTENDED | NO_TEACHER | CANCELLED;

const studentStatus = {
    COMPLETED: 'completed' as COMPLETED,
    NOT_ATTENDED: 'not_attended' as NOT_ATTENDED,
    NO_TEACHER: 'no_teacher' as NO_TEACHER,
    CANCELLED: 'cancelled' as CANCELLED,
};

export default studentStatus;

import { useEffect } from 'react';
import { useToggleState } from '@lingoda/hooks';
import { type ChecklistItemSlug, useCompleteChecklistItemsMutation } from '@lingoda/graphql';

export interface CompleteChecklistItemsOptions {
    slugs: ChecklistItemSlug[];
    skip?: boolean;
}

export const useCompleteChecklistItems = ({ slugs, skip }: CompleteChecklistItemsOptions) => {
    const [isCompleted, complete] = useToggleState();

    const [completeChecklistItems] = useCompleteChecklistItemsMutation({
        onCompleted: complete,
        variables: { slugs },
    });

    useEffect(() => {
        if (!skip && !isCompleted) {
            void completeChecklistItems();
        }
    }, [skip, isCompleted, completeChecklistItems]);
};

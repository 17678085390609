import { createSelector } from 'reselect';
import { studentModuleIdSelector } from '@lingoda/students';
import { DEFAULT_BOOKING_FILTER } from '../constants';
import { type BookingFilter } from '../models';
import { defaultClassTypeSelector } from './defaultClassType';

export const defaultBookingFilterSelector = createSelector(
    defaultClassTypeSelector,
    studentModuleIdSelector,
    (classType, moduleId): BookingFilter => ({
        ...DEFAULT_BOOKING_FILTER,
        classType,
        modules: moduleId ? [moduleId] : [],
    }),
);

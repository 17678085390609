import { memo } from 'react';
import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@lingoda/ui';

interface Props {
    width: number;
}

const LessonSkeleton = memo(({ width }: Props) => (
    <Grid container justify="space-between" alignItems="center">
        <Grid item>
            <Box padding={2} pt={1} pb={1}>
                <Skeleton variant="circular" width={10} height={10} />
            </Box>
        </Grid>
        <Grid item flex="1 0 auto">
            <Typography variant="body1">
                <Skeleton variant="text" width={width} />
            </Typography>
        </Grid>
        <Grid item>
            <Box padding={2} pt={1} pb={1}>
                <Typography variant="body1">
                    <Skeleton variant="text" width={72} />
                </Typography>
            </Box>
        </Grid>
    </Grid>
));
LessonSkeleton.displayName = 'LessonSkeleton';

const lessons = [290, 70, 200, 270, 50, 100];

const LearningUnitSkeleton = memo(() => (
    <Paper variant="outlined">
        <Stack divider={<Skeleton variant="rectangular" width="100%" height={1} />}>
            <Box p={2}>
                <Stack direction="row" spacing={2}>
                    <Box>
                        <Skeleton variant="circular" width={44} height={44} />
                    </Box>

                    <Box>
                        <Typography variant="body2">
                            <Skeleton variant="text" width={58} />
                        </Typography>
                        <Typography variant="body1">
                            <Skeleton variant="text" width={174} />
                        </Typography>
                    </Box>
                </Stack>
            </Box>
            <Box py={1}>
                <Box p={2} mb={0.5} display="flex" alignItems="center">
                    <Box minWidth={56}>
                        <Skeleton variant="circular" width={24} height={24} />
                    </Box>

                    <Stack
                        direction="row"
                        spacing={1}
                        flexGrow={1}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Skeleton variant="text" width={60} />
                        <Skeleton variant="circular" width={40} height={40} />
                    </Stack>
                </Box>

                {lessons.map((lesson) => (
                    <LessonSkeleton width={lesson} key={lesson} />
                ))}
            </Box>
            <Box p={2} display="flex" justifyContent="center">
                <Skeleton variant="circular" width={24} height={24} />
            </Box>
        </Stack>
    </Paper>
));
LearningUnitSkeleton.displayName = 'LearningUnitSkeleton';

export default LearningUnitSkeleton;

import { useEffect, useState } from 'react';

export const useAnimatedProgress = (currentProgress: number) => {
    const [progress, setProgress] = useState(Math.max(currentProgress - 1, 0));

    useEffect(() => {
        const timeoutId = window.setTimeout(() => {
            setProgress(currentProgress);
        }, 500);

        return () => {
            window.clearTimeout(timeoutId);
        };
    }, [currentProgress]);

    return progress;
};

import { useEffect, useRef, useState } from 'react';

interface Options {
    defaultValue?: number;
}

export const useElementSize = (
    ref: HTMLElement | HTMLDivElement | null | undefined,
    type:
        | 'scrollHeight'
        | 'clientHeight'
        | 'offsetHeight'
        | 'scrollWidth'
        | 'clientWidth'
        | 'offsetWidth',
    options?: Options,
) => {
    const defaultValue = options?.defaultValue || 0;
    const [size, setSize] = useState(defaultValue);
    const requestRef = useRef<number>();

    useEffect(() => {
        if (!ref) {
            setSize(defaultValue);

            return;
        }

        const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            requestRef.current = window.requestAnimationFrame(() => {
                const newSize = (entries[0].target as HTMLElement)[type];
                setSize(newSize);

                return newSize;
            });
        });

        resizeObserver.observe(ref);

        return () => {
            resizeObserver.disconnect();
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    }, [ref, type, defaultValue]);

    return size;
};

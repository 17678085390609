import { useState } from 'react';
import { useToggleState } from '@lingoda/hooks';
import { config } from '@lingoda/config';
import { getStripe } from '../utils';
import type { Stripe } from '../models';

const usePaymentRequest = () => {
    const [loading, startLoading, stopLoading] = useToggleState(false);
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const hasPaymentRequest = window.sessionStorage.getItem('HAS_PAYMENT_REQUEST');
    const stripeKey = config.stripePublishable;

    if (loading || !stripe || !stripeKey) {
        return false;
    }

    if (!stripe) {
        startLoading();
        // TODO: Refactor to useStripe/useElements
        void getStripe(stripeKey)?.then((result) => {
            stopLoading();
            setStripe(result);
        });

        return false;
    }

    if (hasPaymentRequest) {
        return hasPaymentRequest === 'true';
    }

    // making a mock test payment to see if it has apple/google pay
    const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
            label: '',
            amount: 0,
        },
    });

    startLoading();
    void paymentRequest.canMakePayment().then((result) => {
        stopLoading();
        window.sessionStorage.setItem('HAS_PAYMENT_REQUEST', `${!!result}`);
    });

    return false;
};

export default usePaymentRequest;

import type { ErrorContexts } from '@lingoda/monitor';
import { captureError } from '@lingoda/monitor';
import { getMercureMessageData } from '../../lib/mercure-event-source/getMercureMessageData';
import { useMercureListener } from './useMercureListener';

export const useEventSourceListener = <D>(
    topic: string,
    types: string[],
    callback: (data: D) => void,
) => {
    useMercureListener(topic, types, (msg) => {
        try {
            const data: D = getMercureMessageData(msg);
            callback(data);
        } catch (error) {
            captureError(error, { msg } as unknown as ErrorContexts);
        }
    });
};

import { colorsPalette, styled } from '@lingoda/ui/theme';
import {
    ToggleButtonGroup,
    type ToggleButtonGroupProps,
} from '@lingoda/ui/buttons/ToggleButtonGroup';
import { isSameDay } from '@lingoda/dates';
import { CalendarDay } from '../CalendarDay';

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
    margin: theme.spacing(-1),
    [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
    },
    '& .MuiToggleButtonGroup-groupedHorizontal': {
        '&:not(:first-of-type)': {
            margin: theme.spacing(1),
            borderColor: colorsPalette.blackAlpha[8],
        },
    },
}));

export interface CalendarWeekProps
    extends Omit<ToggleButtonGroupProps, 'classes' | 'onChange' | 'value'> {
    dates: Date[];
    disabledDates: Date[];
    disabled: boolean;
    onDateChange: (date: Date) => void;
    value: Date;
}

export const CalendarWeek = ({
    dates,
    disabledDates,
    disabled,
    onDateChange,
    value,
    ...props
}: CalendarWeekProps) => {
    const handleOnChange = (_: unknown, value: Date) => onDateChange(value);

    return (
        <ToggleButtonGroupStyled exclusive onChange={handleOnChange} {...props}>
            {dates.map((date, index) => (
                <CalendarDay
                    data-cy="CalendarDay"
                    date={date}
                    key={index}
                    value={date}
                    selected={isSameDay(date, value)}
                    disabled={disabled || disabledDates.includes(date)}
                />
            ))}
        </ToggleButtonGroupStyled>
    );
};

import { useToggleState } from '@lingoda/hooks';
import { Translate, trans } from '@lingoda/i18n';
import { RevocationDialog, TermsDialog } from '@lingoda/legal';
import { Link, Typography } from '@lingoda/ui';

export const TermsLink = () => {
    const [isTermsOpen, handleOpenTerms, handleCloseTerms] = useToggleState(false);
    const [isRevocationOpen, handleOpenRevocation, handleCloseRevocation] = useToggleState(false);

    return (
        <>
            <Typography variant="body2">
                <Translate
                    id="form-label-terms-and-conditions"
                    domain="public-common"
                    params={{
                        terms: (
                            <Link onClick={handleOpenTerms}>
                                {trans(
                                    'form-label-terms-and-conditions.terms',
                                    {},
                                    'public-common',
                                )}
                            </Link>
                        ),
                        revocation: (
                            <Link onClick={handleOpenRevocation}>
                                {trans(
                                    'form-label-terms-and-conditions.revocation',
                                    {},
                                    'public-common',
                                )}
                            </Link>
                        ),
                    }}
                />
            </Typography>
            <TermsDialog isOpen={isTermsOpen} onClose={handleCloseTerms} />
            <RevocationDialog isOpen={isRevocationOpen} onClose={handleCloseRevocation} />
        </>
    );
};

import { useUserSubscriptionsQuery } from '@lingoda/graphql';
import { useOrientationClassAvailable } from './useOrientationClassAvailable';

export const useOrientationClassEnabled = () => {
    const { isOrientationClassAvailable, loading: availableLoading } =
        useOrientationClassAvailable();

    const { data } = useUserSubscriptionsQuery({
        fetchPolicy: 'cache-and-network',
    });
    const userSubscriptionLoading = !data;

    const subscriptionInfo = data?.subscriptions;

    const loading = availableLoading || userSubscriptionLoading;
    const isInTrial = !!subscriptionInfo?.currentSubscription?.isInTrial;

    const isOrientationClassEnabled = !loading
        ? isInTrial && !!isOrientationClassAvailable
        : undefined;

    return { loading, isOrientationClassEnabled };
};

import { type Maybe, StudentStatus } from '@lingoda/graphql';

export const isLessonStatusAttended = (lesson: Maybe<{ studentStatus: StudentStatus }>) =>
    lesson
        ? [
              StudentStatus.Completed,
              StudentStatus.Pending,
              StudentStatus.Booked,
              StudentStatus.Retake,
          ].includes(lesson.studentStatus)
        : false;

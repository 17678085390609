import { type PropsWithChildren, useState } from 'react';
import styleFunctionSx from '@mui/system/styleFunctionSx';
import { type Theme, colorsPalette, dvw, styled } from '../../theme';
import { Popper, type PopperProps } from '../../utils/Popper';

export interface PopperContainerProps {
    anchorEl: PopperProps['anchorEl'];
    zIndex: number;
    popperProps?: Omit<PopperProps, 'open' | 'anchorEl'>;
}

export const PopperContainer = ({
    anchorEl,
    zIndex,
    children,
    popperProps,
}: PropsWithChildren<PopperContainerProps>) => {
    const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

    const isInPortal = !popperProps?.disablePortal;
    const portalPadding = 16;
    const popperModifiers: PopperProps['modifiers'] = [
        {
            name: 'offset',
            options: {
                offset: [0, 16],
            },
        },
        {
            name: 'arrow',
            enabled: true,
            options: {
                padding: 8,
                element: arrowRef,
            },
        },
    ];
    if (isInPortal) {
        popperModifiers.push({
            name: 'preventOverflow',
            options: {
                padding: portalPadding,
            },
        });
    }

    return (
        <StyledPopper
            anchorEl={anchorEl}
            {...popperProps}
            modifiers={[...popperModifiers, ...(popperProps?.modifiers ?? [])]}
            open
            sx={(theme: Theme) => ({
                zIndex,
                ...(isInPortal ? { maxWidth: [dvw(`calc(100dvw - ${portalPadding * 2}px)`)] } : {}),
                ...(popperProps?.sx ? styleFunctionSx({ sx: popperProps.sx, theme }) : {}),
            })}
        >
            {children}
            <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
        </StyledPopper>
    );
};

const bottomArrowStyles = {
    top: 0,
    left: 0,
    marginTop: '-1.9em',
    width: '3em',
    height: '2em',
    '&::before': {
        borderWidth: '0 1em 2em 1em',
        borderColor: `transparent transparent ${colorsPalette.purpleSolid[4]} transparent`,
    },
};

const topArrowStyles = {
    bottom: 0,
    left: 0,
    marginBottom: '-1.9em',
    width: '3em',
    height: '2em',
    '&::before': {
        borderWidth: '2em 1em 0 1em',
        borderColor: `${colorsPalette.purpleSolid[4]} transparent transparent transparent`,
    },
};

const rightArrowStyles = {
    left: 0,
    marginLeft: '-1.9em',
    height: '3em',
    width: '2em',
    '&::before': {
        borderWidth: '1em 2em 1em 0',
        borderColor: `transparent ${colorsPalette.purpleSolid[4]} transparent transparent`,
    },
};

const leftArrowStyles = {
    right: 0,
    marginRight: '-1.9em',
    height: '3em',
    width: '2em',
    '&::before': {
        borderWidth: '1em 0 1em 2em',
        borderColor: `transparent transparent transparent ${colorsPalette.purpleSolid[4]}`,
    },
};

const StyledPopper = styled(Popper)({
    '&[data-popper-placement*="bottom"] .MuiPopper-arrow': bottomArrowStyles,
    '&[data-popper-placement*="top"] .MuiPopper-arrow': topArrowStyles,
    '&[data-popper-placement*="right"] .MuiPopper-arrow': rightArrowStyles,
    '&[data-popper-placement*="left"] .MuiPopper-arrow': leftArrowStyles,
});

const Arrow = styled('div')({
    position: 'absolute',
    fontSize: 8,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
    },
});

import { useCallback, useEffect, useState } from 'react';
import { type ResourceOwnersSchema, getResourceOwners } from '@lingoda/api';
import { type RegisterQueryParams } from '../types';
import { getNonEmptyRegisterState } from '../utils';

export const useSocialOptions = ({ registerParams }: { registerParams: RegisterQueryParams }) => {
    const [resourceOwners, setResourceOwners] = useState<ResourceOwnersSchema[]>();
    const [disabled, setDisabled] = useState(false);
    const { section, curriculumId, module, timezone, timeFormat, weekStart } = registerParams;

    const handleClick = useCallback(() => {
        setDisabled(true);
    }, [setDisabled]);

    const handleReset = useCallback(() => {
        setDisabled(false);
    }, []);

    useEffect(() => {
        const registerState = getNonEmptyRegisterState({
            section,
            curriculumId,
            module,
            timezone,
            timeFormat,
            weekStart,
        });
        void getResourceOwners(registerState).then(({ data }) => setResourceOwners(data));
    }, [section, curriculumId, module, timezone, timeFormat, weekStart]);

    useEffect(() => {
        return () => handleReset();
    }, [handleReset]);

    return {
        disabled,
        handleClick,
        resourceOwners,
    };
};

import { type ComponentType, useEffect } from 'react';
import { useDispatch } from '@lingoda/hooks';
import { type SectionNameTranslation } from '@lingoda/config';
import { transformSectionName } from '@lingoda/sections';
import { useRequiredParams } from '../hooks';
import { setCurrentRoute } from '../route';
import { wrapDisplayName } from '../utils';

export const addRouterListener =
    (route: string) =>
    <T extends Record<string, unknown>>(Component: ComponentType<T>) => {
        const RouterListener = (props: T) => {
            const dispatch = useDispatch();
            const params = useRequiredParams<{ [key: string]: string }>();

            useEffect(() => {
                dispatch(
                    setCurrentRoute({
                        route,
                        params: transformSectionNameTranslation(params || {}),
                    }),
                );
            }, [dispatch, params]);

            return <Component {...props} />;
        };

        RouterListener.displayName = wrapDisplayName(Component, 'RouterListener');

        return RouterListener;
    };

const transformSectionNameTranslation = <T extends { section?: SectionNameTranslation }>(
    params: T,
): T => {
    if (params.section) {
        return { ...params, section: transformSectionName(params.section) };
    }

    return params;
};

import { useCallback, useEffect, useRef } from 'react';
import { scheduleClockCallback } from '@lingoda/clock';

type Callback = () => void;

export const useScheduleCallback = () => {
    const clearFnRef = useRef<Callback>();

    useEffect(() => {
        return () => clearFnRef.current?.();
    }, []);

    const scheduleCallback = useCallback((callback: Callback, date: Date) => {
        clearFnRef.current?.();

        const clearScheduledCallback = scheduleClockCallback(callback, date);
        clearFnRef.current = clearScheduledCallback;

        return clearScheduledCallback;
    }, []);

    return scheduleCallback;
};

import { type Date } from '@lingoda/dates';
import { buildValidator } from '@lingoda/forms';

export interface FormValues {
    topic: string;
    startDate: Date;
}

export const validate = buildValidator({
    topic: {
        required: true,
    },
    startDate: {
        required: true,
    },
});

import { trans } from '@lingoda/i18n';
import { Box, Divider, Grid, Loader, Typography } from '@lingoda/ui';
import { CalendarTimes } from './CalendarTimes';
import { CalendarMonths } from './CalendarMonths';
import { CalendarIcon } from './CalendarIcon';
import type { CalendarTimesProps } from './CalendarTimes';
import type { CalendarMonthsProps } from './CalendarMonths';

export interface CalendarProps
    extends Pick<CalendarMonthsProps, 'disabledDates'>,
        Pick<CalendarTimesProps, 'times'> {
    selectedDate: Date;
    selectedTime?: string;
    isLoading: boolean;
    onTimeChange: CalendarTimesProps['onChange'];
    onDateChange: CalendarMonthsProps['onDateChange'];
}

export const Calendar = ({
    selectedDate,
    selectedTime,
    times,
    onDateChange,
    onTimeChange,
    disabledDates,
    isLoading,
}: CalendarProps) => (
    <Grid container direction="column" spacing={2}>
        <Grid item>
            <CalendarMonths
                value={selectedDate}
                onDateChange={onDateChange}
                disabledDates={disabledDates}
                disabled={isLoading}
            />
        </Grid>
        <Grid item>
            <Divider />
        </Grid>
        {!!selectedDate && (
            <>
                <Grid item>
                    <Typography>
                        {trans('available-time-slots', {}, 'student-onboarding')}
                    </Typography>
                </Grid>
                <Grid item>
                    {isLoading ? (
                        <Loader />
                    ) : times.length ? (
                        <CalendarTimes value={selectedTime} onChange={onTimeChange} times={times} />
                    ) : (
                        <Box py={2} textAlign="center">
                            <Box pb={2}>
                                <CalendarIcon />
                            </Box>
                            <Typography variant="subtitle2">
                                {trans('fully-booked', {}, 'student-onboarding')}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </>
        )}
    </Grid>
);

import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { learningUnitsSelector } from '@lingoda/learning-units';
import { isLessonNotTaken, lessonsByIdsSelector } from '@lingoda/lessons';
import { useSelector, useStore } from '@lingoda/hooks';
import { lessonsBookingFilterSelector } from '../selectors';
import type { Store } from 'redux';

const getNextLessonsFilterOnBooking = (store: Store, currentlyBookedLessonId: number): number[] => {
    const tickedLessons = lessonsBookingFilterSelector(store.getState());

    if (tickedLessons.length > 1) {
        return tickedLessons.filter((id) => id !== currentlyBookedLessonId);
    } else if (tickedLessons.length === 1) {
        const learningUnits = learningUnitsSelector(store.getState());
        const orderedLessonsIds = learningUnits.flatMap((unit) => unit.lessonIds);
        const lessons = lessonsByIdsSelector(orderedLessonsIds)(store.getState());

        const unbookedLessons = lessons.reduce<number[]>((list, lesson) => {
            // we need to leave currently booked lesson in the list for selecting the next lesson
            if (isLessonNotTaken(lesson) || lesson.id === currentlyBookedLessonId) {
                list.push(lesson.id);
            }

            return list;
        }, []);

        const bookedLessonIndex = unbookedLessons.indexOf(currentlyBookedLessonId);
        const nextLessonId = unbookedLessons[bookedLessonIndex + 1];

        return nextLessonId ? [nextLessonId] : [];
    } else {
        return [];
    }
};

export const useGetNextLessons = () => {
    const store = useStore();
    const tickedLessons = useSelector(lessonsBookingFilterSelector);

    const selectNextLessons = useCallback(
        (lessonId: number) => {
            const nextLessonsList = getNextLessonsFilterOnBooking(store, lessonId);

            if (!isEqual(nextLessonsList, tickedLessons)) {
                return nextLessonsList;
            } else {
                return null;
            }
        },
        [store, tickedLessons],
    );

    return selectNextLessons;
};

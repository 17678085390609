import { useCallback } from 'react';
import type { TimeFormat } from '@lingoda/dates';
import { Grid } from '../../layout/GridNext';
import { ToggleButton } from '../../buttons';
import { Box } from '../../layout';
import { colorsPalette } from '../../theme';
import { formatTimeSlot } from './utils';
import { TIME_SLOT_OPTION_FORMAT } from './formats';
import type { ToggleButtonGroupProps } from '../../buttons';

export type TimeSlotGroupProps = {
    slots: number[];
    timeFormat: TimeFormat;
    onChange: (timeSlot: number) => void;
    isSelected: (timeSlot: number) => boolean;
    isDisabled: (timeSlot: number) => boolean;
    isScheduled: (timeSlot: number) => boolean;
    slotsInRow?: 3 | 6;
};

export const TimeSlotGroup = ({
    slots,
    timeFormat,
    isSelected,
    isDisabled,
    isScheduled,
    onChange,
    slotsInRow = 3,
}: TimeSlotGroupProps) => {
    const handleTimeSlotChange: ToggleButtonGroupProps['onChange'] = useCallback(
        (_, hour: number) => onChange(hour),
        [onChange],
    );

    return (
        <Grid container spacing={1}>
            {slots.map((slot) => (
                <Grid key={slot} xs={slotsInRow === 6 ? 2 : 4}>
                    <ToggleButton
                        fullWidth
                        size="small"
                        value={slot}
                        selected={isSelected(slot)}
                        disabled={isDisabled(slot)}
                        onChange={handleTimeSlotChange}
                        data-cy="Time slot option"
                    >
                        {formatTimeSlot(TIME_SLOT_OPTION_FORMAT[timeFormat], slot)}
                        {isScheduled(slot) && (
                            <Box
                                sx={{
                                    marginLeft: 1,
                                    width: 4,
                                    height: 4,
                                    borderRadius: 2,
                                    background: colorsPalette.primarySolid[100],
                                }}
                            />
                        )}
                    </ToggleButton>
                </Grid>
            ))}
        </Grid>
    );
};

import { SlidePopup, withSnackbarAdapter } from '@lingoda/ui';
import { BookingFeedbackForm } from './BookingFeedbackForm';

declare module 'notistack' {
    interface VariantOverrides {
        bookingFeedback: true;
    }
}

export const BookingFeedbackPopup = withSnackbarAdapter(({ close }) => {
    return (
        <SlidePopup minHeight={92} ariaLabel="Booking Survey popup">
            <BookingFeedbackForm onClose={close} />
        </SlidePopup>
    );
});

import { SvgIcon, type SvgIconProps } from '@lingoda/ui';

const Icon = (props: SvgIconProps) => (
    <SvgIcon {...props} className="trial-package-icon">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.499 1.508a6.403 6.403 0 0 1 3.63.01 6.422 6.422 0 0 1 2.573 10.855 6.425 6.425 0 0 1-7.249 1.059L9.543 14.5a.75.75 0 0 1-.87-1.055l1.68-3.41a6.403 6.403 0 0 1 4.146-8.527ZM17.7 2.955a4.903 4.903 0 0 0-5.842 6.781.75.75 0 0 1-.006.65l-1.097 2.228 2.589-.707a.75.75 0 0 1 .564.07 4.923 4.923 0 0 0 7.33-4.408 4.925 4.925 0 0 0-3.534-4.613H17.7ZM13.73 4.77a1.083 1.083 0 1 1 1.204 1.801A1.083 1.083 0 0 1 13.73 4.77Zm3.998 0a1.083 1.083 0 1 1 1.204 1.802 1.083 1.083 0 0 1-1.204-1.802ZM4.67 8.085a.583.583 0 0 0-.582.583v8.579h5.616a.75.75 0 0 1 .651.378 1.894 1.894 0 0 0 3.29 0 .75.75 0 0 1 .65-.378h5.617V15.33a.75.75 0 0 1 1.5 0v1.916h.583a.75.75 0 0 1 .75.75v1.332a3.415 3.415 0 0 1-3.415 3.416H4.67a3.415 3.415 0 0 1-3.415-3.416v-1.332a.75.75 0 0 1 .75-.75h.583V8.668A2.083 2.083 0 0 1 4.67 6.585h3.332a.75.75 0 1 1 0 1.5H4.67ZM2.755 18.747v.582a1.915 1.915 0 0 0 1.915 1.916h14.66a1.915 1.915 0 0 0 1.915-1.916v-.582h-6.549a3.395 3.395 0 0 1-5.392 0H2.755ZM13.937 7.92a.75.75 0 0 1 .81.685 1.434 1.434 0 0 0 1.531 1.313.75.75 0 0 1 .106 0 1.435 1.435 0 0 0 1.532-1.313.75.75 0 1 1 1.495.124 2.935 2.935 0 0 1-3.08 2.688 2.934 2.934 0 0 1-3.08-2.688.75.75 0 0 1 .686-.81Z"
        />
    </SvgIcon>
);
export default Icon;

import { createSelector } from 'reselect';
import { getUnixTime, isBefore } from '@lingoda/dates';
import { localRoundHourSelector } from '@lingoda/time';
import { AppointmentStatus } from '../models';
import studentAppointmentsSelector from './studentAppointments';

export default createSelector(
    studentAppointmentsSelector,
    localRoundHourSelector,
    (appointments, time) => {
        return appointments
            .filter(
                (item) => isBefore(time, item.endDate) && item.status === AppointmentStatus.ACTIVE,
            )
            .sort((a, b) => getUnixTime(a.endDate) - getUnixTime(b.endDate));
    },
);

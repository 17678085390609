import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { type Achievement } from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { goTo, studentAchievementsPath } from '@lingoda/router';
import { useClaimReward } from '../../hooks/useClaimReward';
import { getIsClaimable } from '../../utils/getIsClaimable';
import { useTrackClaimRewardOnboarding } from '../../hooks/useTrackClaimRewardOnboarding';
import { AchievementSlug } from '../../models';
import { Layout } from './Layout';

interface Props extends InheritedProps {
    achievement: Achievement;
}

type InheritedProps = Pick<React.ComponentProps<typeof Layout>, 'onClose' | 'variant' | 'title'>;

export const AchievementAlert = ({ achievement, ...props }: Props) => {
    const dispatch = useDispatch();
    const [claimReward, isClaiming] = useClaimReward({
        onCompleted: ({ claimAttendanceAchievement }) => {
            props.onClose?.();

            dispatch(
                showDialog(DialogComponent.Achievement, {
                    achievement: claimAttendanceAchievement,
                    animateClaiming: true,
                    onSeeAllClick: () => goTo(studentAchievementsPath()),
                }),
            );
        },
    });
    const { trackClaimRewardOnboarding } = useTrackClaimRewardOnboarding();

    const { claimId, slug } = achievement;

    const isClaimable = getIsClaimable(achievement);

    const handleDialogOpen = async () => {
        if (isClaimable && claimId) {
            if (slug === AchievementSlug.ONBOARDING_CHECKLIST) {
                trackClaimRewardOnboarding();
            }
            await claimReward(claimId);
        }
    };

    return (
        <Layout
            {...props}
            achievement={achievement}
            isClaiming={isClaiming}
            onClaiming={handleDialogOpen}
        />
    );
};

import { createDate, isBefore, isSameOrAfter, subtract, toDate } from '@lingoda/dates';

export const isInSessionClass = (
    start: string | Date,
    end: string | Date,
    now: Date = createDate(),
) => {
    const startDate = toDate(start);
    const endDate = toDate(end);

    return isSameOrAfter(now, getStartSessionDate(startDate)) && isBefore(now, endDate);
};

export const getStartSessionDate = (start: string | Date) => {
    const startDate = toDate(start);

    return subtract({ minutes: 5 }, startDate);
};

import { createAction } from '@lingoda/core';
export { default as showNotification } from './showNotification';
export * from './showNotifications';
export { default as showNotifications } from './showNotifications';
export { default as removeNotification } from './removeNotification';
export * from './markSeenNotifications';

export const toggleAllNotificationsContainer = createAction(
    'notifications/TOGGLE_ALL_NOTIFICATIONS_CONTAINER',
);

export const showAllNotificationsContainer = createAction(
    'notifications/SHOW_ALL_NOTIFICATIONS_CONTAINER',
);

export const showUnSeenNotificationsContainer = createAction(
    'notifications/SHOW_UNSEEN_NOTIFICATIONS_CONTAINER',
);

export const hideNotificationsContainer = createAction(
    'notifications/HIDE_NOTIFICATIONS_CONTAINER',
);

import type {
    BookingExperienceAnswerType,
    BookingExperienceExtraFeedback,
    MutationAnswerBookingExperienceSurveyArgs,
    MutationAnswerBookingExperienceSurveyDetailsArgs,
} from '@lingoda/graphql';
import { AnswerFieldType, QuestionKeys } from './types';
import type { BookingExperienceQuestion, FormState } from './types';

export const formatQuestionsFormStateOnSubmit = (
    values: FormState,
): MutationAnswerBookingExperienceSurveyDetailsArgs => {
    const comment = values.comment as string | undefined;
    const answer = values[QuestionKeys.DidYouFind] as BookingExperienceAnswerType | undefined;
    const answerValues: MutationAnswerBookingExperienceSurveyDetailsArgs = {};
    const extraFeedback = values[
        QuestionKeys.WhatYouWereLookingFor
    ] as BookingExperienceExtraFeedback[];

    if (extraFeedback.length) {
        answerValues.extraFeedback = extraFeedback;
    }

    if (comment) {
        answerValues.comment = comment;
    }

    if (answer) {
        answerValues.answer = answer;
    }

    return answerValues;
};

export const formatScoreFormStateOnSubmit = (
    values: FormState,
): MutationAnswerBookingExperienceSurveyArgs => {
    const scoreString = values[QuestionKeys.HowSatisfied] as string;

    return { score: Number(scoreString) };
};

export const buildFormInitState = (
    questions: BookingExperienceQuestion[],
    screenPosition: number,
): FormState => {
    const initState: FormState = {};

    if (screenPosition === 2) {
        initState.comment = '';
    }

    for (const question of questions) {
        initState[question.key] = getFieldInitState(question);
    }

    return initState;
};

const getFieldInitState = (question: BookingExperienceQuestion) => {
    switch (question.fieldType) {
        case AnswerFieldType.multipleChoiceSelect:
            return [];
        default:
            return null;
    }
};

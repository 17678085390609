import { useCallback, useEffect } from 'react';
import { actionSelection, useUiEvent } from '@lingoda/analytics';
import {
    type TimeFormat,
    formatDate,
    formatTime,
    isAfter,
    isBefore,
    set,
    utcMinutesOffset,
} from '@lingoda/dates';
import { TimePicker } from '../TimePicker';
import { Grid } from '../../layout/Grid';
import { DateInput } from '../DateInput';

interface Props {
    label?: string;
    maxDate?: Date;
    minDate: Date;
    name: string;
    onChange: (value: Date) => void;
    value: Date;
    disabled?: boolean;
    timeFormat: TimeFormat;
}

export const DateTimePicker = ({
    label,
    maxDate,
    minDate,
    name,
    onChange,
    value,
    timeFormat,
    disabled,
}: Props) => {
    const addEvent = useUiEvent();

    const handleTimeChange = useCallback(
        (date: Date) => {
            void addEvent(actionSelection('Time'), { value: formatTime(date) });
        },
        [addEvent],
    );

    const handleDateChange = useCallback(
        (date: Date) => {
            void addEvent(actionSelection('Date'), { value: formatDate(date) });
        },
        [addEvent],
    );

    const handleChangeDateInput = (date: Date) => {
        let newDate = date;
        if (isBefore(newDate, minDate)) {
            newDate = minDate;
        }
        if (maxDate && isAfter(newDate, maxDate)) {
            newDate = maxDate;
        }

        const nextDate = set(
            { hours: value.getHours(), minutes: value.getMinutes(), seconds: 0 },
            newDate,
        );
        handleDateChange(nextDate);
        onChange(nextDate);
    };

    const handleChangeTimePicker = (hour: number) => {
        const newDate = set({ hours: hour, minutes: utcMinutesOffset(), seconds: 0 }, value);
        onChange(newDate);
        handleTimeChange(newDate);
    };

    useEffect(() => {
        if (minDate && (!value || isAfter(minDate, value))) {
            onChange(minDate);
        }
    }, [onChange, minDate, value]);

    useEffect(() => {
        if (maxDate && (!value || isBefore(maxDate, value)) && !isBefore(maxDate, minDate)) {
            onChange(maxDate);
        }
    }, [onChange, maxDate, minDate, value]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <DateInput
                    name={name}
                    label={label}
                    format="PP"
                    selectedDate={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleChangeDateInput}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={6}>
                <TimePicker
                    format={timeFormat}
                    fullWidth
                    maxHour={23}
                    minHour={0}
                    onChange={handleChangeTimePicker}
                    value={value.getHours()}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

import { createSelector } from 'reselect';
import { expandClassesWithAppointmentSelector } from './expandClassesWithAppointment';
import type { ClassId } from '../constants';

export const expandClassWithAppointmentSelector = (
    selector: (state: unknown, id: unknown) => ClassId,
) =>
    createSelector(
        selector,
        expandClassesWithAppointmentSelector,
        (classId, classes) => classes?.find((classItem) => classItem.id === classId),
    );

const expandedClassItemSelector = expandClassWithAppointmentSelector((_state, id) => id as ClassId);

export const getExpandedClassItemSelector = (classId: ClassId) => (state: unknown) =>
    expandedClassItemSelector(state, classId);

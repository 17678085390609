import { createSelector } from 'reselect';
import { ClassType } from '@lingoda/graphql';
import studentSelector from './student';

type ClassTypeCredits = {
    [key in ClassType.Group | ClassType.Individual]: number;
};

export default createSelector(
    studentSelector,
    (student): ClassTypeCredits => ({
        [ClassType.Group]: student?.groupCredits || 0,
        [ClassType.Individual]: student?.individualCredits || 0,
    }),
);

import { styled } from '@lingoda/ui/theme';
import { Drawer as MuiDrawer } from '@lingoda/ui';

const drawerWidth = 360;

export const CurriculumDrawer = styled(MuiDrawer)(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
        },
    },
}));

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

const ArrowLeft: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.75 12C19.75 12.4142 19.4142 12.75 19 12.75L6.81066 12.75L12.5303 18.4697C12.8232 18.7626 12.8232 19.2374 12.5303 19.5303C12.2374 19.8232 11.7626 19.8232 11.4697 19.5303L3.93934 12L11.4697 4.46967C11.7626 4.17678 12.2374 4.17678 12.5303 4.46967C12.8232 4.76256 12.8232 5.23744 12.5303 5.53033L6.81066 11.25L19 11.25C19.4142 11.25 19.75 11.5858 19.75 12Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export default ArrowLeft;

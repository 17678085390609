import { useDispatch } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { updatePreferences } from '@lingoda/students';
import { type SetGoalDialogProps } from '../types';
import { Layout } from './Layout';

const SetGoalDialogWeeklyWords = ({
    hideDialog,
    closable = false,
    currentGoal,
}: SetGoalDialogProps) => {
    const dispatch = useDispatch();

    const handleConfirm = (value: number) => {
        dispatch(updatePreferences({ classWeeklyWordsGoal: value }));
        hideDialog();
    };

    const handleSaveGoal = (selectedValue: number) => handleConfirm(selectedValue);
    const handleDeleteGoal = () => handleConfirm(0);

    const title = currentGoal
        ? trans('weekly-progress-adjust-my-goal', {}, 'student-common')
        : trans('weekly-words-title', {}, 'student-set-goal-dialog');

    return (
        <Layout
            closable={closable}
            currentGoal={currentGoal}
            hideDialog={hideDialog}
            loading={false}
            onDeleteGoal={handleDeleteGoal}
            onSaveGoal={handleSaveGoal}
            options={getOptions()}
            subtitle={trans('weekly-words-subtitle', {}, 'student-set-goal-dialog')}
            title={title}
        />
    );
};

const getOptions = () => [
    {
        value: 1,
        left: trans('words-per-week', { amount: 25 }, 'student-set-goal-dialog'),
        right: trans('set-goal-maintain', {}, 'student-common'),
    },
    {
        value: 2,
        left: trans('words-per-week', { amount: 50 }, 'student-set-goal-dialog'),
        right: trans('set-goal-steady', {}, 'student-common'),
    },
    {
        value: 3,
        left: trans('words-per-week', { amount: 100 }, 'student-set-goal-dialog'),
        right: trans('set-goal-serious', {}, 'student-common'),
    },
    {
        value: 4,
        left: trans('words-per-week', { amount: 150 }, 'student-set-goal-dialog'),
        right: trans('set-goal-unstoppable', {}, 'student-common'),
    },
];

export default SetGoalDialogWeeklyWords;

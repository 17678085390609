import { Paper } from '../../../layout/Paper';
import DialogActions from './DialogActions';
import type { Props as DialogActionProps } from './DialogActions';

export interface Props extends DialogActionProps {
    scrollable?: boolean;
}

const ElevatedDialogActions = ({ scrollable, ...dialogActionsProps }: Props) =>
    scrollable ? (
        <Paper elevation={3} style={{ overflow: 'visible' }}>
            <DialogActions {...dialogActionsProps} />
        </Paper>
    ) : (
        <DialogActions {...dialogActionsProps} />
    );

export default ElevatedDialogActions;

import { getStartedFlowPath, loginPath } from '@lingoda/router';
import { Button, Stack, TextField, Typography, colorsPalette, styled } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import IdeaSvg from './assets/idea.svg?component';

interface ConfirmEmailProps {
    email: string;
}

export const ConfirmSocialEmail = ({ email }: ConfirmEmailProps) => {
    return (
        <BackgroundStyled>
            <MainStackStyled spacing={5}>
                <IdeaSvg />
                <Typography variant="h1" textAlign="center">
                    {trans('no-account-for-email', {}, 'social-auth')}
                </Typography>
                <Stack spacing={2} width={400} maxWidth="100%">
                    <TextField
                        label="Email"
                        value={email}
                        disabled
                        InputLabelProps={{ shrink: true }}
                    />
                    <Button variant="contained" href={getStartedFlowPath()}>
                        {trans('get-started', {}, 'social-auth')}
                    </Button>
                    <Button variant="text" href={loginPath()}>
                        {trans('try-another-method', {}, 'social-auth')}
                    </Button>
                </Stack>
            </MainStackStyled>
        </BackgroundStyled>
    );
};

const BackgroundStyled = styled(Stack)({
    backgroundColor: colorsPalette.primarySolid[4],
    flexGrow: 1,
});

const MainStackStyled = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    width: 600,
    margin: '0 auto',
    paddingTop: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(6, 2),
    },
}));

import { useMemo } from 'react';
import { useRequestTracker } from '@lingoda/core';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { getAgreementsAgreed } from '@lingoda/purchases';
import type { ChangeSubscriptionPurchase } from '@lingoda/subscriptions';
import { changeSubscription } from '@lingoda/subscriptions';
import { fetchMe } from '@lingoda/auth';
import {
    changeLearningLanguageSuccessPath,
    goTo,
    resolvePath,
    studentChangedCoursePath,
} from '@lingoda/router';
import {
    UserSubscriptionsDocument,
    useUpdateUserLanguageAndSubscriptionMutation,
} from '@lingoda/graphql';
import { paymentMethodsSelector } from '../selectors';
import { PaymentGateway } from '../models';
import { addPaymentMethod } from '../actions';
import type { PaymentCallbackReturn } from '../hoc/withPayment';
import type { PaymentFormData } from '../hoc';
import type { PaymentOptions } from '../models';

const usePaymentCallbacksForSubscriptionChange = (
    purchase: ChangeSubscriptionPurchase,
    newSectionName?: SectionName,
    newCurriculumId?: number,
) => {
    const dispatch = useDispatch();
    const trackRequest = useRequestTracker();
    const paymentMethods = useSelector(paymentMethodsSelector);
    const [updateUserLanguageAndSubscription] = useUpdateUserLanguageAndSubscriptionMutation({
        refetchQueries: [UserSubscriptionsDocument],
    });

    return useMemo(() => {
        const submitPayment = async (formData: PaymentFormData, options: PaymentOptions) => {
            const { paymentMethod } = formData;
            const agreementsPayload = getAgreementsAgreed(purchase.agreements, formData.agreements);

            if (newSectionName && newCurriculumId) {
                await updateUserLanguageAndSubscription({
                    variables: {
                        purchaseId: purchase.id,
                        agreements: agreementsPayload,
                        newSectionName: newSectionName,
                        newCurriculumId: newCurriculumId,
                        couponCode: purchase.coupons[0]?.code,
                    },
                });
            } else {
                await trackRequest(
                    dispatch(
                        changeSubscription({
                            purchase,
                            agreements: agreementsPayload,
                        }),
                    ),
                );
            }
            const isExistingPaymentMethod = paymentMethods.some(
                (method) => method.id === paymentMethod && method.stored,
            );

            const redirectUrl = newSectionName
                ? changeLearningLanguageSuccessPath()
                : studentChangedCoursePath();

            if (isExistingPaymentMethod) {
                dispatch(fetchMe());
                goTo(redirectUrl);

                return;
            }

            return trackRequest(
                dispatch(
                    addPaymentMethod({
                        ...options,
                        redirectUrl: resolvePath(redirectUrl),
                        currency: purchase.chargeTotal.currency,
                        paymentMethod,
                        nameOnCard: formData.name,
                    }),
                ),
            ) as Promise<PaymentCallbackReturn>;
        };

        const onSubmitCallback = (formData: PaymentFormData) =>
            submitPayment(formData, { gateway: PaymentGateway.ADYEN });

        const fetchIntentSecret = (formData: PaymentFormData) =>
            submitPayment(formData, { gateway: PaymentGateway.STRIPE });

        return [onSubmitCallback, fetchIntentSecret] as const;
    }, [
        dispatch,
        paymentMethods,
        purchase,
        trackRequest,
        updateUserLanguageAndSubscription,
        newSectionName,
        newCurriculumId,
    ]);
};

export default usePaymentCallbacksForSubscriptionChange;

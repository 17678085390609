import { useIntervalDate } from '@lingoda/hooks';
import { intlFormatDistance } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { ClassStatusHeader } from './ClassStatusHeader';

interface ClassStatusPendingStartProps {
    startDate: Date;
}

export const ClassStatusPendingStart = ({ startDate }: ClassStatusPendingStartProps) => {
    const currentDate = useIntervalDate('second');
    const duration = intlFormatDistance(startDate, currentDate);

    return (
        <ClassStatusHeader color="purple">
            {trans('class-starts-in', { duration }, 'student-classes')}
        </ClassStatusHeader>
    );
};

import { useContext } from 'react';
import { assert } from '@lingoda/utils';
import { LeadConversionFlowContext } from '../context';

const useLeadConversionFlowContext = () => {
    const context = useContext(LeadConversionFlowContext);

    assert(context, 'LeadConversionFlowContext.Provider is not set');

    return context;
};

export default useLeadConversionFlowContext;

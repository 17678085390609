import { handleActionsImmutably } from '@lingoda/core';
import { setCurriculumModules } from '../actions';

export type State = Readonly<GenericKeyObject<number, number[]>>;

type Action = ReturnType<typeof setCurriculumModules>;

export default handleActionsImmutably<State, Action['payload']>(
    {
        [`${setCurriculumModules}`]: (state, { payload }) => {
            state[payload.curriculumId] = payload.moduleIds;

            return state;
        },
    },
    {},
);

import { AppointmentStatus } from '@lingoda/graphql';
import type { BookableClass } from '@lingoda/booking';
import { trans } from '@lingoda/i18n';
import { colorsPalette } from '@lingoda/ui/theme';
import { Close as CloseIcon } from '@lingoda/ui/icons';

export const getAppointmentStatusIcon = (status: AppointmentStatus) =>
    [AppointmentStatus.Active, AppointmentStatus.Attended].includes(status) ? null : (
        <CloseIcon fontSize="inherit" />
    );

export const getAppointmentStatusText = (
    status: AppointmentStatus,
    isPast: boolean,
    isUpcoming: boolean,
): string | undefined => {
    switch (status) {
        case AppointmentStatus.Deleted:
            return trans('cancelled', {}, 'student-common').toUpperCase();
        case AppointmentStatus.Cancelled:
            return trans('cancelled', {}, 'student-common').toUpperCase();
        case AppointmentStatus.Active:
            if (isPast) {
                return undefined;
            }

            return isUpcoming
                ? trans('upcoming', {}, 'student-common').toUpperCase()
                : `${trans('you-are-joining-this-class', {}, 'student-common')} 🎉`;
        case AppointmentStatus.Attended:
            return undefined;
        case AppointmentStatus.Missed:
            return trans('missed', {}, 'student-common').toUpperCase();
        default:
            return undefined;
    }
};

type StatusColor = {
    [x in AppointmentStatus]: string | undefined;
};

const AppointmentStatusColor: StatusColor = {
    [AppointmentStatus.Deleted]: colorsPalette.negativeSolid[100],
    [AppointmentStatus.Cancelled]: colorsPalette.blackAlpha[70],
    [AppointmentStatus.Active]: colorsPalette.positiveCustom.dark,
    [AppointmentStatus.Attended]: colorsPalette.purpleSolid[100],
    [AppointmentStatus.Missed]: colorsPalette.negativeSolid[100],
};

export const getAppointmentStatusColor = (status: AppointmentStatus, isFutureUpcoming: boolean) => {
    if (isFutureUpcoming) {
        return colorsPalette.purpleSolid[100];
    }

    return AppointmentStatusColor[status];
};

export const getStudentAvatarsFromClass = (klass: BookableClass) =>
    klass.students.map((student) => ({
        firstName: student.shortName,
        src: student.photo?.urls.medium,
        id: student.id,
    }));

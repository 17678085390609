import { Box, colorsPalette, styled } from '@lingoda/ui';
import type React from 'react';

type Props = React.PropsWithChildren<unknown>;

export const Wrapper = ({ children }: Props) => {
    return (
        <WrapperStyled>
            <ContainerStyled>{children}</ContainerStyled>
        </WrapperStyled>
    );
};

const WrapperStyled = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'grid',
    flexGrow: '1',
    overflow: 'hidden',
    paddingBlock: '60px',
    width: '100%',
    background: colorsPalette.primarySolid[12],
    [theme.breakpoints.down(991)]: {
        alignItems: 'flex-start',
        paddingBlock: '40px',
    },
    [theme.breakpoints.down(588)]: {
        paddingBottom: '0',
    },
}));

const ContainerStyled = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'grid',
    gridGap: '100px',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    margin: '0 auto',
    maxWidth: 'calc(1300px + 64px)',
    padding: '0 32px',
    width: '100%',
    zIndex: '1',
    [theme.breakpoints.down(1439)]: {
        maxWidth: 'calc(1100px + 64px)',
    },
    [theme.breakpoints.down(1199)]: {
        gridGap: '60px',
        maxWidth: 'calc(930px + 64px)',
    },
    [theme.breakpoints.down(1023)]: {
        maxWidth: 'calc(930px + 48px)',
        padding: '0 24px',
    },
    [theme.breakpoints.down(991)]: {
        alignItems: 'flex-start',
        gridGap: '32px',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
        justifyItems: 'center',
        maxWidth: 'calc(700px + 48px)',
    },
    [theme.breakpoints.down(767)]: {
        maxWidth: 'calc(540px + 48px)',
    },
}));

import { Children, type ReactNode } from 'react';
import { Box, Skeleton } from '@lingoda/ui';
import { Checkbox, CustomErrorMessage, useField } from '@lingoda/forms';
import { Grid } from '@lingoda/ui/layout/GridNext';

const OptionsLayout = ({ children, error }: { children: ReactNode; error?: ReactNode }) => {
    return (
        <Box mb={3}>
            <Grid container>
                {Children.map(children, (child, index) => (
                    <Grid key={index} xs={12} smw={6}>
                        {child}
                    </Grid>
                ))}
            </Grid>
            {error}
        </Box>
    );
};

interface OptionsFieldProps {
    options: OptionType[];
}

export interface OptionType {
    id: string;
    answer: string;
    slug: string;
}

export const OptionsField = ({ options }: OptionsFieldProps) => {
    const fieldName = 'options';

    return (
        <OptionsLayout error={<OptionsError name={fieldName} />}>
            {options.map((option) => {
                return (
                    <Checkbox
                        key={option.id}
                        name={fieldName}
                        value={option}
                        label={option.answer}
                        showError={false}
                    />
                );
            })}
        </OptionsLayout>
    );
};

const OptionsError = ({ name }: { name: string }) => {
    const [, meta] = useField(name);

    return <CustomErrorMessage meta={meta} />;
};

export const OptionsSkeleton = ({ length }: { length: number }) => {
    return (
        <OptionsLayout>
            {Array.from({ length }).map((_, index) => {
                return (
                    <Checkbox
                        key={index}
                        name="options"
                        label={<Skeleton key={index} variant="text" width={200} />}
                        disabled
                    />
                );
            })}
        </OptionsLayout>
    );
};

import { type ReactNode } from 'react';
import { isLeadConversionFlowEnabled } from '@lingoda/feature-flags';
import { useSelector } from '@lingoda/hooks';
import {
    Redirect,
    bookingPreviewPath,
    freeTrialPurchasePath,
    leadFlowPath,
    matchPathMemoized,
    packagePurchasePath,
    productOrderPath,
    productPurchasePath,
    sprintPackagePurchasePath,
    studentCreditsCheckoutPath,
    studentM2MCheckoutPath,
    studentPaymentFailedPath,
    studentPaymentPendingPath,
    studentSprintCheckoutPath,
    studentTeamsCheckoutPath,
    studentTeamsPackagePurchasePath,
    useLocation,
} from '@lingoda/router';
import { areSubscriptionsLoadedSelector } from '@lingoda/subscriptions';
import { LinearProgress } from '@lingoda/ui';
import { useLeadConversionFlowContext } from '../hooks';
import { isLeadUserSelector } from '../selectors';
import LeadConversionContextProvider from './LeadConversionContextProvider';

const LeadConversionFlowFrame = ({ children }: { children: ReactNode }) => {
    if (!isLeadConversionFlowEnabled()) {
        return <>{children}</>;
    }

    return (
        <LeadConversionContextProvider>
            <RestrictedAccess>{children}</RestrictedAccess>
        </LeadConversionContextProvider>
    );
};

const RestrictedAccess = ({ children }: { children: ReactNode }) => {
    const { isComplete } = useLeadConversionFlowContext();

    const areSubscriptionsLoaded = useSelector(areSubscriptionsLoadedSelector);
    const isLeadUser = useSelector(isLeadUserSelector);
    const { pathname } = useLocation();

    if (isComplete) {
        return <>{children}</>;
    }

    if (!areSubscriptionsLoaded) {
        return <LinearProgress />;
    }

    const hasAccess = !isLeadUser || matchPathMemoized(allowedRoutes, pathname);

    return hasAccess ? <>{children}</> : <Redirect to={leadFlowPath()} />;
};

const allowedRoutes = [
    leadFlowPath(),
    { path: packagePurchasePath(undefined as never), matchPrefix: true },
    { path: sprintPackagePurchasePath(undefined as never), matchPrefix: true },
    { path: studentTeamsPackagePurchasePath(undefined as never), matchPrefix: true },
    { path: productOrderPath(undefined as never), matchPrefix: true },
    { path: productPurchasePath(undefined as never), matchPrefix: true },
    { path: freeTrialPurchasePath(undefined as never), matchPrefix: true },
    { path: studentM2MCheckoutPath(undefined as never), matchPrefix: true },
    { path: studentCreditsCheckoutPath(undefined as never), matchPrefix: true },
    { path: studentSprintCheckoutPath(undefined as never), matchPrefix: true },
    { path: studentTeamsCheckoutPath(undefined as never), matchPrefix: true },
    { path: bookingPreviewPath(undefined as never), matchPrefix: true },
    { path: studentPaymentPendingPath(undefined as never), matchPrefix: true },
    { path: studentPaymentFailedPath(undefined as never), matchPrefix: true },
];

export default LeadConversionFlowFrame;

import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { getCurriculumFilter } from '../utils';
import { defaultBookingFilterSelector } from './defaultBookingFilter';
import { bookingFilterSelector } from './bookingFilter';

export const isCurriculumFilterDefaultSelector = createSelector(
    defaultBookingFilterSelector,
    bookingFilterSelector,
    (defaultFilters, filters) =>
        isEqual(getCurriculumFilter(defaultFilters), getCurriculumFilter(filters)),
);

import { type ReactNode } from 'react';
import { Box, type BoxProps, styled } from '@lingoda/ui';

interface ContentBlockProps {
    sx?: BoxProps['sx'];
    innerProps?: BoxProps;
    children?: ReactNode;
}

export const ContentBlock = ({ children, innerProps, ...passProps }: ContentBlockProps) => {
    return (
        <Box {...passProps}>
            <ContentMaxWidth {...innerProps}>{children}</ContentMaxWidth>
        </Box>
    );
};

const ContentMaxWidth = styled(Box)(({ theme }) => {
    const paddingY = { xs: 2, sm: 3 };

    return {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        padding: theme.spacing(0, paddingY.xs),
        maxWidth: theme.spacing(101 + paddingY.xs * 2),

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, paddingY.sm),
            maxWidth: theme.spacing(101 + paddingY.sm * 2),
        },
    };
});

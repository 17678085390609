import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '../TextField';
import ChevronIcon from '../../icons/ChevronIcon';
import { Box } from '../../layout/Box';
import { Checkmark } from '../../icons/lingodaIcons';
import type { TextFieldProps } from '../TextField';
import type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';
import type { ReactNode } from 'react';

export { autocompleteClasses } from '@mui/material/Autocomplete';
export type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

export interface AutocompleteProps<T>
    extends MuiAutocompleteProps<T, boolean, boolean, boolean>,
        Pick<TextFieldProps, 'label'> {
    getOptionIcon?: (value: T) => ReactNode;
    hideCheckMark?: boolean;
}

export const Autocomplete = <T,>({
    label,
    getOptionIcon,
    getOptionLabel,
    hideCheckMark,
    ...rest
}: Omit<AutocompleteProps<T>, 'renderInput' | 'renderOption'>) => {
    const renderInput: AutocompleteProps<T>['renderInput'] = (params) => (
        <TextField label={label} {...params} />
    );

    const renderOption: AutocompleteProps<T>['renderOption'] = (props, value: T, { selected }) => (
        <li {...props}>
            <Box flex={1}>
                {getOptionIcon?.(value)} {getOptionLabel?.(value) || value}
            </Box>
            {selected && !hideCheckMark && <Checkmark color="primary" />}
        </li>
    );

    return (
        <MuiAutocomplete
            popupIcon={<ChevronIcon fontSize="small" />}
            renderInput={renderInput}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            disableClearable
            {...rest}
        />
    );
};

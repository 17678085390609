import { createSelector } from 'reselect';
import type { Section } from '@lingoda/sections';
import { sectionsSelector } from '@lingoda/sections';
import { createRecordFactory } from '@lingoda/utils';
import { productVariantFactory } from '../models';
import productsByIdSelector from './productsById';
import productVariantsByIdSelector from './productVariantsById';
import type { Product, ProductVariant, ProductVariantId } from '../models';
import type { ParametricSelector } from 'reselect';

export interface ExpandedProductVariant extends ProductVariant {
    product: Product;
    section?: Section;
}

const expandedProductVariantFactory = createRecordFactory<ExpandedProductVariant>({
    ...productVariantFactory({}),
    product: undefined,
    section: undefined,
});

type VariantIds = ProductVariantId[];
type VariantId = ProductVariantId;

type ExpandedProductVariantsSelectorResult = Readonly<GenericObject<ExpandedProductVariant>>;

type SectionsResult = ReturnType<typeof sectionsSelector>;
type ProductsResult = ReturnType<typeof productsByIdSelector>;
type ProductVariantsResult = ReturnType<typeof productVariantsByIdSelector>;

const expandProduct = (
    variantId: number,
    productVariants: ProductVariantsResult,
    products: ProductsResult,
    sections: SectionsResult,
) => {
    const variant = productVariants[variantId];

    return expandedProductVariantFactory({
        ...variant,
        product: variant && products[variant.productId],
        section: variant && sections.find((section) => section.id === variant.sectionId),
    });
};

type ResultList<P> = ParametricSelector<unknown, P, ExpandedProductVariantsSelectorResult>;
export const createExpandedProductVariantsSelector = <P>(
    baseSelector: ParametricSelector<unknown, P, VariantIds>,
): ResultList<P> =>
    createSelector(
        baseSelector,
        productVariantsByIdSelector,
        productsByIdSelector,
        sectionsSelector,
        (variantIds, productVariants, products, sections) =>
            Object.fromEntries(
                variantIds.map((variant) => [
                    variant,
                    expandProduct(variant, productVariants, products, sections),
                ]),
            ),
    );

type ResultItem<P> = ParametricSelector<unknown, P, ExpandedProductVariant | undefined>;
export const createExpandedProductVariantSelector = <P>(
    baseSelector: ParametricSelector<unknown, P, VariantId | undefined>,
): ResultItem<P> =>
    createSelector(
        baseSelector,
        productVariantsByIdSelector,
        productsByIdSelector,
        sectionsSelector,
        (variantId, productVariants, products, sections) =>
            (variantId && expandProduct(variantId, productVariants, products, sections)) ||
            undefined,
    );

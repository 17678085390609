import { captureError } from '@lingoda/monitor';
import attend1Active from '../static/badges/attend-1-active.svg?url';
import attend3Active from '../static/badges/attend-3-active.svg?url';
import attend5Active from '../static/badges/attend-5-active.svg?url';
import attend10Active from '../static/badges/attend-10-active.svg?url';
import attend20Active from '../static/badges/attend-20-active.svg?url';
import attend24Active from '../static/badges/attend-24-active.svg?url';
import attend30Active from '../static/badges/attend-30-active.svg?url';
import attend40Active from '../static/badges/attend-40-active.svg?url';
import attend50Active from '../static/badges/attend-50-active.svg?url';
import attend66Active from '../static/badges/attend-66-active.svg?url';
import attend80Active from '../static/badges/attend-80-active.svg?url';
import attend100Active from '../static/badges/attend-100-active.svg?url';
import attend123Active from '../static/badges/attend-123-active.svg?url';
import attend150Active from '../static/badges/attend-150-active.svg?url';
import attend200Active from '../static/badges/attend-200-active.svg?url';
import attend250Active from '../static/badges/attend-250-active.svg?url';
import attend300Active from '../static/badges/attend-300-active.svg?url';
import attend350Active from '../static/badges/attend-350-active.svg?url';
import attend400Active from '../static/badges/attend-400-active.svg?url';
import attend450Active from '../static/badges/attend-450-active.svg?url';
import attend500Active from '../static/badges/attend-500-active.svg?url';
import attend600Active from '../static/badges/attend-600-active.svg?url';
import attend700Active from '../static/badges/attend-700-active.svg?url';
import attend800Active from '../static/badges/attend-800-active.svg?url';
import attend900Active from '../static/badges/attend-900-active.svg?url';
import attend1000Active from '../static/badges/attend-1000-active.svg?url';
import attend1Inactive from '../static/badges/attend-1-inactive.svg?url';
import attend3Inactive from '../static/badges/attend-3-inactive.svg?url';
import attend5Inactive from '../static/badges/attend-5-inactive.svg?url';
import attend10Inactive from '../static/badges/attend-10-inactive.svg?url';
import attend20Inactive from '../static/badges/attend-20-inactive.svg?url';
import attend24Inactive from '../static/badges/attend-24-inactive.svg?url';
import attend30Inactive from '../static/badges/attend-30-inactive.svg?url';
import attend40Inactive from '../static/badges/attend-40-inactive.svg?url';
import attend50Inactive from '../static/badges/attend-50-inactive.svg?url';
import attend66Inactive from '../static/badges/attend-66-inactive.svg?url';
import attend80Inactive from '../static/badges/attend-80-inactive.svg?url';
import attend100Inactive from '../static/badges/attend-100-inactive.svg?url';
import attend123Inactive from '../static/badges/attend-123-inactive.svg?url';
import attend150Inactive from '../static/badges/attend-150-inactive.svg?url';
import attend200Inactive from '../static/badges/attend-200-inactive.svg?url';
import attend250Inactive from '../static/badges/attend-250-inactive.svg?url';
import attend300Inactive from '../static/badges/attend-300-inactive.svg?url';
import attend350Inactive from '../static/badges/attend-350-inactive.svg?url';
import attend400Inactive from '../static/badges/attend-400-inactive.svg?url';
import attend450Inactive from '../static/badges/attend-450-inactive.svg?url';
import attend500Inactive from '../static/badges/attend-500-inactive.svg?url';
import attend600Inactive from '../static/badges/attend-600-inactive.svg?url';
import attend700Inactive from '../static/badges/attend-700-inactive.svg?url';
import attend800Inactive from '../static/badges/attend-800-inactive.svg?url';
import attend900Inactive from '../static/badges/attend-900-inactive.svg?url';
import attend1000Inactive from '../static/badges/attend-1000-inactive.svg?url';
import readySteadyGoActive from '../static/badges/ready-steady-go-active.svg?url';
import readySteadyGoInactive from '../static/badges/ready-steady-go-inactive.svg?url';
import { AchievementSlug } from '../models';

const map: Record<AchievementSlug, [string, string] | undefined> = {
    [AchievementSlug.ONBOARDING_CHECKLIST]: [readySteadyGoActive, readySteadyGoInactive],
    [AchievementSlug.LT_ONBOARDING_CHECKLIST]: [readySteadyGoActive, readySteadyGoInactive],
    [AchievementSlug.CLASS_ATTENDED_1]: [attend1Active, attend1Inactive],
    [AchievementSlug.CLASS_ATTENDED_3]: [attend3Active, attend3Inactive],
    [AchievementSlug.CLASS_ATTENDED_5]: [attend5Active, attend5Inactive],
    [AchievementSlug.CLASS_ATTENDED_10]: [attend10Active, attend10Inactive],
    [AchievementSlug.CLASS_ATTENDED_20]: [attend20Active, attend20Inactive],
    [AchievementSlug.CLASS_ATTENDED_24]: [attend24Active, attend24Inactive],
    [AchievementSlug.CLASS_ATTENDED_30]: [attend30Active, attend30Inactive],
    [AchievementSlug.CLASS_ATTENDED_40]: [attend40Active, attend40Inactive],
    [AchievementSlug.CLASS_ATTENDED_50]: [attend50Active, attend50Inactive],
    [AchievementSlug.CLASS_ATTENDED_66]: [attend66Active, attend66Inactive],
    [AchievementSlug.CLASS_ATTENDED_80]: [attend80Active, attend80Inactive],
    [AchievementSlug.CLASS_ATTENDED_100]: [attend100Active, attend100Inactive],
    [AchievementSlug.CLASS_ATTENDED_123]: [attend123Active, attend123Inactive],
    [AchievementSlug.CLASS_ATTENDED_150]: [attend150Active, attend150Inactive],
    [AchievementSlug.CLASS_ATTENDED_200]: [attend200Active, attend200Inactive],
    [AchievementSlug.CLASS_ATTENDED_250]: [attend250Active, attend250Inactive],
    [AchievementSlug.CLASS_ATTENDED_300]: [attend300Active, attend300Inactive],
    [AchievementSlug.CLASS_ATTENDED_350]: [attend350Active, attend350Inactive],
    [AchievementSlug.CLASS_ATTENDED_400]: [attend400Active, attend400Inactive],
    [AchievementSlug.CLASS_ATTENDED_450]: [attend450Active, attend450Inactive],
    [AchievementSlug.CLASS_ATTENDED_500]: [attend500Active, attend500Inactive],
    [AchievementSlug.CLASS_ATTENDED_600]: [attend600Active, attend600Inactive],
    [AchievementSlug.CLASS_ATTENDED_700]: [attend700Active, attend700Inactive],
    [AchievementSlug.CLASS_ATTENDED_800]: [attend800Active, attend800Inactive],
    [AchievementSlug.CLASS_ATTENDED_900]: [attend900Active, attend900Inactive],
    [AchievementSlug.CLASS_ATTENDED_1000]: [attend1000Active, attend1000Inactive],
};

export const getBadgeUrls = (achievementSlug: string) => {
    const urls = map[achievementSlug as AchievementSlug];

    if (!urls) {
        captureError(new Error(`Unknown achievementSlug: ${achievementSlug}`));
    }

    return { activeBadgeUrl: urls?.[0] || '', inactiveBadgeUrl: urls?.[1] || '' };
};

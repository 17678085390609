import { Box, styled } from '@lingoda/ui';

export const DayBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
    },
}));

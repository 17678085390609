import { FlashcardStatus } from '@lingoda/graphql';
import type { TypePolicy } from '@apollo/client';

const VocabularyItem: TypePolicy = {
    fields: {
        status: (currentStatus, { readField }) => {
            const isCompleted = readField('isCompleted');

            if (isCompleted === true) {
                return FlashcardStatus.GotIt;
            }

            return currentStatus || FlashcardStatus.Default;
        },
    },
};

export default VocabularyItem;

import { default as MuiAlert, type AlertProps as MuiAlertProps } from '@mui/material/Alert';
import { styled } from '../../theme';
import { AlertTitle } from './AlertTitle';

export type AlertColor = 'success' | 'info' | 'warning' | 'error' | 'promotion';

export interface AlertProps
    extends Omit<MuiAlertProps, 'variant' | 'severity' | 'square' | 'title'> {
    severity: AlertColor;
    variant?: 'standard' | 'outlined';
    title?: string;
    size?: 'standard' | 'compact';
}

export const Alert = ({
    severity: rawSeverity,
    variant = 'standard',
    title,
    children,
    ...props
}: AlertProps) => {
    const color = rawSeverity;
    const severity = rawSeverity === 'promotion' ? 'info' : rawSeverity;

    return (
        <MuiAlertStyled {...props} variant={variant} color={color} severity={severity}>
            {title && <AlertTitle sx={{ marginBottom: children ? 1 : 0 }}>{title}</AlertTitle>}
            {children}
        </MuiAlertStyled>
    );
};

const MuiAlertStyled = styled(MuiAlert)<AlertProps>(({ theme, size }) => ({
    ...(size === 'compact' && {
        padding: theme.spacing(2),
        '& .MuiAlert-icon': {
            marginRight: theme.spacing(1.5),
        },
        '& .MuiAlert-action': {
            paddingLeft: theme.spacing(1.5),
        },
    }),
}));

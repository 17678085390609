import { trans } from '@lingoda/i18n';
import { Button, type ConfirmButtonProps, Stack } from '@lingoda/ui';

interface Props extends ConfirmButtonProps {
    currentGoal: number | null | undefined;
    disabled: boolean;
    onDeleteGoal: () => void;
}

export const Buttons = ({ dataCy, onConfirm, currentGoal, disabled, onDeleteGoal }: Props) => {
    return (
        <Stack spacing={3} direction="column" alignItems="center" minWidth={200}>
            {currentGoal ? (
                <>
                    <Button
                        color="primary"
                        data-cy={'dialog-save-goal-btn'}
                        disabled={disabled}
                        fullWidth
                        onClick={onConfirm}
                        variant="contained"
                    >
                        {trans('weekly-progress-save-goal', {}, 'student-common')}
                    </Button>
                    <Button
                        data-cy="dialog-delete-goal-btn"
                        disabled={disabled}
                        fullWidth
                        onClick={onDeleteGoal}
                        variant="text"
                    >
                        {trans('weekly-progress-delete-goal', {}, 'student-common')}
                    </Button>
                </>
            ) : (
                <Button
                    color="primary"
                    data-cy={dataCy}
                    disabled={disabled}
                    onClick={onConfirm}
                    variant="contained"
                >
                    {trans('set-goal-btn-commit', {}, 'student-common')}
                </Button>
            )}
        </Stack>
    );
};

import { Typography } from '@lingoda/ui';

interface Props {
    children: React.ReactNode;
}

const PromoBannerHeader = ({ children }: Props) => (
    <Typography typography="h2" mb={1}>
        {children}
    </Typography>
);

export default PromoBannerHeader;

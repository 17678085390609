import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import { freeTrialPurchasePath } from '@lingoda/router';

const FreeTrialPurchasePage = lazy(() => import('./pages/FreeTrialPurchasePage'));

export const freeTrialPurchaseRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: FreeTrialPurchasePage,
        path: freeTrialPurchasePath.raw,
        label: 'FreeTrialPurchase',
        config: {
            hideNavigation: true,
            tab: undefined,
        },
    },
];

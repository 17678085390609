import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { getEmptyMediaQuery, useMediaQuery } from '../../hooks/useMediaQuery';
import type { Breakpoint, Theme } from '@mui/material/styles';
import type { PropsWithChildren } from 'react';

type Direction = 'Up' | 'Down';
type DirectedBreakpoint = `${Breakpoint}${Direction}`;

export type DirectedBreakpointsProps = Partial<Record<DirectedBreakpoint, boolean>>;

const directedBreakpointToMediaQuery = (theme: Theme): Record<DirectedBreakpoint, string> => ({
    xlDown: theme.breakpoints.down('xl'),
    xlUp: theme.breakpoints.up('xl'),
    lgDown: theme.breakpoints.down('lg'),
    lgUp: theme.breakpoints.up('lg'),
    mdDown: theme.breakpoints.down('md'),
    mdUp: theme.breakpoints.up('md'),
    smwDown: theme.breakpoints.down('smw'),
    smwUp: theme.breakpoints.up('smw'),
    smDown: theme.breakpoints.down('sm'),
    smUp: theme.breakpoints.up('sm'),
    smnDown: theme.breakpoints.down('smn'),
    smnUp: theme.breakpoints.up('smn'),
    xsDown: theme.breakpoints.down('xs'),
    xsUp: theme.breakpoints.up('xs'),
});

const directedBreakpointsList: DirectedBreakpoint[] = [
    'xlUp',
    'xlDown',
    'lgUp',
    'lgDown',
    'mdUp',
    'mdDown',
    'smwUp',
    'smwDown',
    'smUp',
    'smDown',
    'xsUp',
    'xsDown',
];

const useHiddenMediaQuery = (props: DirectedBreakpointsProps): string => {
    const theme = useTheme();
    const foundBreakpointValue = directedBreakpointsList.find((breakpoint) => props[breakpoint]);
    const directedBreakpoint = useMemo(() => directedBreakpointToMediaQuery(theme), [theme]);

    return foundBreakpointValue
        ? directedBreakpoint[foundBreakpointValue]
        : getEmptyMediaQuery(theme);
};

const getHiddenMediaQueryForOnly =
    (onlyValue: Breakpoint | undefined) =>
    (theme: Theme): string =>
        onlyValue ? theme.breakpoints.only(onlyValue) : getEmptyMediaQuery(theme);

export interface HiddenProps extends DirectedBreakpointsProps {
    only?: Breakpoint;
}

const Hidden = (props: PropsWithChildren<HiddenProps>) => {
    const { only, children } = props;
    const hiddenMediaQuery = useHiddenMediaQuery(props);
    const hidden = useMediaQuery(hiddenMediaQuery);
    const onlyHidden = useMediaQuery(getHiddenMediaQueryForOnly(only));

    return <>{hidden || onlyHidden ? null : children}</>;
};

Hidden.displayName = 'Hidden';

export default Hidden;

import { getApolloErrorUiMessage, getValidationErrorMessages } from '@lingoda/graphql';
import type { Agreement, ApolloError, Purchase } from '@lingoda/graphql';
import { trans, transChoice } from '@lingoda/i18n';
import { gqlErrorsToToasts } from '@lingoda/toasts';

export const getAgreementsAgreed = (
    purchaseAgreements: ReadonlyArray<Pick<Agreement, 'id'>>,
    userAgreements: Record<string, boolean | undefined>,
) =>
    purchaseAgreements
        .map(({ id }) => (userAgreements[id] ? id : undefined))
        .filter((item): item is string => !!item);

export const getPackagePurchaseErrorMessageOrToasts = (error: ApolloError) => {
    const toasts = gqlErrorsToToasts(getValidationErrorMessages(error));
    if (toasts.length) {
        const transformedToasts = toasts.map((toast) => {
            if (toast.message === `You can't buy past sprint`) {
                return {
                    ...toast,
                    message: trans('sprint-started-in-the-past', {}, 'public-payment'),
                    dismissable: false,
                };
            }

            return toast;
        });

        return transformedToasts;
    }

    return getApolloErrorUiMessage(error) || error.message;
};

export const getPurchaseSummary = (purchase: Pick<Purchase, 'groupCredits' | 'privateCredits'>) =>
    `${
        purchase.groupCredits
            ? transChoice(
                  'number-group-classes',
                  purchase.groupCredits,
                  { classes: purchase.groupCredits },
                  'public-courses',
              )
            : ''
    } ${
        purchase.privateCredits
            ? transChoice(
                  'number-private-classes',
                  purchase.privateCredits,
                  { classes: purchase.privateCredits },
                  'public-courses',
              )
            : ''
    }`;

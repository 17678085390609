import { useMemo } from 'react';
import { getChallengeLockedPrizeIcons } from '../assets/icons';
import type { Prize } from '../types';

export const usePrizeIcons = (prizes: readonly Prize[]) =>
    useMemo(() => {
        const lockedIcons = getChallengeLockedPrizeIcons(prizes.length);
        const unlockedIcons = prizes.map((prize) => prize.prizeIcon?.urls.original);

        return {
            lockedIcons,
            unlockedIcons,
        };
    }, [prizes]);

import {
    Redirect,
    studentTeamsPackagePurchasePath,
    teamsRegisterSectionPath,
    useLocation,
    useParams,
} from '@lingoda/router';
import type { SectionCurriculumSlug } from '@lingoda/sections';
import { getQueryParams } from '@lingoda/urls';
import type { AcceptedLang } from '@lingoda/env';
import type { Currency } from '@lingoda/graphql';
import { assert } from '@lingoda/utils';
import { transformSectionName } from '@lingoda/sections';

const TeamsSignup = () => {
    const { search } = useLocation();
    const { section, curriculum, locale } = useParams<{
        section: SectionName;
        locale: AcceptedLang;
        curriculum: SectionCurriculumSlug;
    }>();
    const {
        currency,
        packageId,
        courseId,
        certs = '',
    } = getQueryParams<{
        currency: Currency;
        packageId: string;
        courseId: string;
        certs?: string;
    }>(search);

    assert(
        currency && packageId && courseId,
        'Missing packageId and/or currency and/or courseId in URL from Wordpress',
    );

    const redirect = studentTeamsPackagePurchasePath({
        packageId: packageId.toString(),
        currency: currency.toString(),
        courseId: courseId.toString(),
        section: section.toString(),
        certs: certs?.toString(),
    });

    const params = { locale, section: transformSectionName(section), curriculum, redirect };

    return <Redirect to={teamsRegisterSectionPath(params)} />;
};

export default TeamsSignup;

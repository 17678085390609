import { useState } from 'react';
import { getLang } from '@lingoda/env';
import { isVocabAudioEnabled } from '@lingoda/feature-flags';
import { VocabularyStatus, useSetFlashcardStatusMutation } from '@lingoda/graphql';
import { useSelector, useToggleState } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import {
    ANIMATION_DURATION,
    ChangeAudioEffect,
    CheckButton,
    FlashCardContainer,
    FlashCardControl,
    FlipShortcutTip,
    MissedButton,
    useFlashCardAnimation,
} from '@lingoda/quiz-flashcards';
import {
    Checkmark as CheckmarkIcon,
    CloseIcon16,
    NavigateAfter,
    NavigateBefore,
} from '@lingoda/ui/icons/lingodaIcons';
import {
    ButtonResizeTheme,
    ContentBody,
    ContentFooter,
    ReportContentButton,
    VocabItemSameLanguageAlert,
    buttonIconResizeSx,
    soundEffects,
    useKeyshortcutsAvailability,
} from '@lingoda/quiz-ui';
import { isLocaleMatchSectionSelector } from '@lingoda/auth';
import { Box, Stack } from '@lingoda/ui';
import { useAudioContext } from '@lingoda/audio';
import { type VocabularyItemType } from '../types';
import { Finished } from './Finished';
import { ReportItemDialog } from './ReportItemDialog';

interface Props {
    learningUnitId: number;
    onNextCard?: (currentCardIndex: number) => void;
    onReset?: () => Promise<void>;
    onContinue?: () => Promise<void>;
    onFinish: () => void;
    onAudioClick?: () => void;
    totalWords: number;
    totalUncompletedWords: number;
    vocabularyItems: ReadonlyArray<VocabularyItemType>;
    isSwapped: boolean;
}

export const FlashCardPlayer = ({
    learningUnitId,
    onNextCard,
    totalWords,
    totalUncompletedWords,
    vocabularyItems,
    onReset,
    onContinue,
    onFinish,
    onAudioClick,
    isSwapped,
}: Props) => {
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [correctWordsInSession, setCorrectWordsInSession] = useState(0);
    const [isFinished, setToFinished, setToUnfinished] = useToggleState(
        vocabularyItems.length === 0,
    );

    const { play } = useAudioContext();

    const isLocaleMatch = useSelector(isLocaleMatchSectionSelector);

    const [saveFlashcardProgress] = useSetFlashcardStatusMutation();

    const {
        check,
        flashCardVariant,
        isNextCardLoading,
        miss,
        proceedToNextCardEnd,
        proceedToNextCardStart,
    } = useFlashCardAnimation();

    const currentItem = vocabularyItems[currentCardIndex];
    const nextItem = vocabularyItems[currentCardIndex + 1];

    const proceedToNextCard = () => {
        proceedToNextCardStart();

        setTimeout(() => {
            const nextCardIndex = currentCardIndex + 1;
            const shouldIncrementCardIndex = nextCardIndex < vocabularyItems.length;
            if (shouldIncrementCardIndex) {
                setCurrentCardIndex(nextCardIndex);
            } else {
                setToFinished();
                onFinish();
            }

            proceedToNextCardEnd();

            if (shouldIncrementCardIndex) {
                onNextCard?.(nextCardIndex);
            }
        }, ANIMATION_DURATION);
    };

    const handleMissedButtonClick = () => {
        miss();

        void play(soundEffects.incorrect).catch(console.error);

        proceedToNextCard();
    };

    const handleCheckedButtonClick = () => {
        check();

        void play(soundEffects.correct).catch(console.error);

        void saveFlashcardProgress({
            variables: {
                itemId: currentItem.id,
                status: VocabularyStatus.Completed,
            },
        });

        setCorrectWordsInSession(correctWordsInSession + 1);
        proceedToNextCard();
    };

    const handleContinue = async () => {
        await onContinue?.();
        resetPlayer();
    };

    const handleReset = async () => {
        await onReset?.();
        resetPlayer();
    };

    const resetPlayer = () => {
        setCorrectWordsInSession(0);
        setCurrentCardIndex(0);
        setToUnfinished();
    };

    const correctWordsInTotal = totalWords - totalUncompletedWords + correctWordsInSession;
    const isSessionOngoing = !isFinished && !!totalUncompletedWords;

    const [isReportOpen, openReport, closeReport] = useToggleState(false);

    const keyshortcutsAvailable = useKeyshortcutsAvailability();
    const shortcutControls = keyshortcutsAvailable && !isReportOpen ? 'enabled' : 'disabled';

    return (
        <>
            {isSessionOngoing ? (
                <>
                    <ContentBody alignItems="center">
                        {isLocaleMatch && (
                            <Box marginBottom={{ xs: 3, sm: 6 }} width="100%">
                                <VocabItemSameLanguageAlert />
                            </Box>
                        )}

                        <FlashCardContainer>
                            <FlashCardControl
                                flashcard={{
                                    ...currentItem,
                                    sectionName: currentItem.section.name,
                                }}
                                flashcardPreview={
                                    nextItem && {
                                        ...nextItem,
                                        sectionName: nextItem.section.name,
                                    }
                                }
                                isAudioEnabled={isVocabAudioEnabled()}
                                isNextCardLoading={isNextCardLoading}
                                isSwapped={isSwapped}
                                onAudioClick={onAudioClick}
                                targetLang={getLang()}
                                variant={flashCardVariant}
                                shortcutControls={shortcutControls}
                            />
                        </FlashCardContainer>
                        {keyshortcutsAvailable && <FlipShortcutTip />}
                        {/* spacer to pin report button to bottom */}
                        <Box sx={{ flex: '1 1' }} flexBasis={{ xs: 8, smw: 32 }} />
                        <ReportContentButton onClick={openReport} tabIndex={-1} />
                        {isReportOpen && (
                            <ReportItemDialog close={closeReport} item={currentItem} />
                        )}
                        {isVocabAudioEnabled() && currentItem.audioUrls && (
                            <ChangeAudioEffect
                                currentItemAudio={currentItem.audioUrls}
                                defaultFace={isSwapped ? 'translation' : 'target'}
                                isNextCardLoading={isNextCardLoading}
                            />
                        )}
                    </ContentBody>
                    <ContentFooter>
                        <ButtonResizeTheme>
                            <Stack width="100%" spacing={{ xs: 2, sm: 3 }} direction="row">
                                <MissedButton
                                    variant="contained"
                                    disabled={isNextCardLoading || isFinished}
                                    loading={isNextCardLoading && flashCardVariant === 'missed'}
                                    onClick={handleMissedButtonClick}
                                    aria-label={trans('dont-know', {}, 'student-common')}
                                    fullWidth
                                    shortcutKey="ArrowLeft"
                                    shortcutControls={shortcutControls}
                                    onShortcutKeyUp={handleMissedButtonClick}
                                    badge={<NavigateBefore />}
                                    badgeSx={{ p: 0 }}
                                >
                                    <CloseIcon16 sx={buttonIconResizeSx} />
                                </MissedButton>
                                <CheckButton
                                    variant="contained"
                                    disabled={isNextCardLoading || isFinished}
                                    loading={isNextCardLoading && flashCardVariant === 'checked'}
                                    onClick={handleCheckedButtonClick}
                                    aria-label={trans('got-it', {}, 'student-common')}
                                    fullWidth
                                    shortcutKey="ArrowRight"
                                    shortcutControls={shortcutControls}
                                    onShortcutKeyUp={handleCheckedButtonClick}
                                    badge={<NavigateAfter />}
                                    badgePos="end"
                                    badgeSx={{ p: 0 }}
                                >
                                    <CheckmarkIcon sx={buttonIconResizeSx} />
                                </CheckButton>
                            </Stack>
                        </ButtonResizeTheme>
                    </ContentFooter>
                </>
            ) : (
                <Finished
                    correctWordsInSession={correctWordsInSession}
                    correctWordsInTotal={correctWordsInTotal}
                    learningUnitId={learningUnitId}
                    totalWords={totalWords}
                    onContinue={handleContinue}
                    onReset={handleReset}
                />
            )}
        </>
    );
};

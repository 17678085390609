import { useCallback, useEffect } from 'react';
import useHistory from './useHistory';
import useLocation from './useLocation';

const useGoBack = () => {
    const history = useHistory();
    const location = useLocation<{ isFirstPage: boolean }>();

    useEffect(() => {
        if (!location.state?.isFirstPage && history.length === 1) {
            history.replace({ ...location, state: { ...location.state, isFirstPage: true } });
        }
    }, [history, location]);

    const canGoBack = !location.state?.isFirstPage;
    const goBack = useCallback(() => history.goBack(), [history]);

    return { canGoBack, goBack };
};

export default useGoBack;

import { type MouseEvent } from 'react';
import { type DialogBaseProps, DialogComponent, showDialog } from '@lingoda/dialogs';
import { type Achievement, useOnboardingChecklistQuery } from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { goTo, studentAchievementsPath } from '@lingoda/router';
import { Box, Dialog, DialogHeader, Typography } from '@lingoda/ui';
import { useClaimReward, useTrackClaimRewardOnboarding } from '../../hooks';
import { getIsClaimable } from '../../utils';
import { AchievementSlug } from '../../models';
import { AchievementProgress } from './AchievementProgress';

interface Props extends DialogBaseProps {
    achievement: Achievement;
}

const OnboardingChecklistDialog = ({ hideDialog, achievement }: Props) => {
    const { claimId, slug } = achievement;

    const { data, loading: isChecklistLoading } = useOnboardingChecklistQuery({
        variables: { slug },
    });
    const { trackClaimRewardOnboarding } = useTrackClaimRewardOnboarding();

    const isClaimable = getIsClaimable(achievement);

    const handleOnClaim = async (event: MouseEvent) => {
        event.stopPropagation();

        if (isClaimable && claimId) {
            if (slug === AchievementSlug.ONBOARDING_CHECKLIST) {
                trackClaimRewardOnboarding();
            }
            await claimReward(claimId);
        }
    };

    const dispatch = useDispatch();
    const [claimReward, isClaiming] = useClaimReward({
        onCompleted: ({ claimAttendanceAchievement }) => {
            dispatch(
                showDialog(DialogComponent.Achievement, {
                    achievement: claimAttendanceAchievement,
                    animateClaiming: true,
                    onSeeAllClick: () => goTo(studentAchievementsPath()),
                }),
            );

            hideDialog();
        },
    });

    const handleClose = () => {
        hideDialog();
    };

    return (
        <Dialog isOpen onClose={handleClose} blurredBackground>
            <Box padding={{ xs: 3, sm: 4 }}>
                <DialogHeader onClose={handleClose}>
                    <Typography variant="h2">{achievement.name}</Typography>
                </DialogHeader>
                <Box marginTop={3}>
                    <AchievementProgress
                        achievement={achievement}
                        checklist={data?.checklist ?? []}
                        isChecklistLoading={isChecklistLoading}
                        isClaiming={isClaiming}
                        onClaim={handleOnClaim}
                    />
                </Box>
            </Box>
        </Dialog>
    );
};

export default OnboardingChecklistDialog;

import { Currency } from './models';

const CurrencySymbols: Record<Currency, string> = {
    [Currency.Eur]: '€',
    [Currency.Usd]: '$',
    [Currency.Gbp]: '£',
    [Currency.Chf]: 'CHF',
    [Currency.Pln]: 'zł',
    [Currency.Brl]: 'R$',
    [Currency.Try]: '₺',
};

export const getCurrencySymbol = (currency: Currency) => CurrencySymbols[currency];

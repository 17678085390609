import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { studentPreferencesSelector, updatePreferences } from '@lingoda/students';
import { DialogComponent, dialogSelectorFactory, useDialog } from '@lingoda/dialogs';

export const useSubscribeUnlockDialogListener = () => {
    const dispatch = useDispatch();
    const [wasDismissed, setWasDismissed] = useState(false);
    const preferences = useSelector(studentPreferencesSelector);
    const showUnlockDialog = useDialog(DialogComponent.SubscribeUnlockDialog);
    const dialog = useSelector(dialogSelectorFactory(DialogComponent.SubscribeUnlockDialog));

    const isActive = dialog?.active || false;
    const isPopupDismissed = preferences?.dismissedWidgets?.subscribeLingodaPopup;

    const onHideDialog = useCallback(() => {
        dispatch(updatePreferences({ dismissedWidgets: { subscribeLingodaPopup: true } }));
        setWasDismissed(true);
    }, [dispatch, setWasDismissed]);

    useEffect(() => {
        if (isPopupDismissed === false && !isActive && !wasDismissed) {
            showUnlockDialog({
                onConfirm: onHideDialog,
                onDismiss: onHideDialog,
            });
        }
    }, [onHideDialog, showUnlockDialog, isPopupDismissed, isActive, wasDismissed]);

    return null;
};

import { Box, Paper, type PaperProps } from '@lingoda/ui';
import type { AchievementFragment } from '@lingoda/graphql';
import { AchievementItem } from './AchievementItem';
import { RecapAchievementItem } from './RecapAchievementItem';

export interface TopAchievementsLayoutProps {
    achievements: ReadonlyArray<AchievementFragment>;
    loading: boolean;
    isYearlyRecapVisible: boolean;
    paperProps?: Partial<PaperProps>;
}

export const TopAchievementsLayout = ({
    achievements,
    loading,
    isYearlyRecapVisible,
    paperProps,
}: TopAchievementsLayoutProps) => {
    return (
        <Paper sx={{ py: 1.5 }} {...paperProps}>
            <Box component="ul" sx={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {isYearlyRecapVisible && <RecapAchievementItem component="li" />}
                {achievements.map((achievement) => (
                    <AchievementItem
                        key={achievement.id}
                        achievement={achievement}
                        loading={loading}
                        component="li"
                    />
                ))}
            </Box>
        </Paper>
    );
};

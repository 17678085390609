import { useEffect, useRef } from 'react';
import { type ButtonBaseActions, type TouchRippleActions } from '@lingoda/ui';

export const useShortcutListener = ({
    shortcutKey,
    onKeyUp,
    disabled,
}: {
    shortcutKey: string;
    onKeyUp?: () => void;
    disabled?: boolean;
}) => {
    const actionRef = useRef<ButtonBaseActions | null>(null);
    const rippleRef = useRef<TouchRippleActions | null>(null);

    // Prevent repeated calls when key pressed for a period of time
    const keydownRef = useRef<boolean>();

    const onKeyUpRef = useRef(onKeyUp);
    onKeyUpRef.current = onKeyUp;

    useEffect(() => {
        if (disabled) {
            return;
        }

        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                !keydownRef.current &&
                actionRef.current &&
                rippleRef.current &&
                event.key === shortcutKey
            ) {
                keydownRef.current = true;
                rippleRef.current.start(undefined);
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === shortcutKey && rippleRef.current && actionRef.current) {
                keydownRef.current = false;

                const action = actionRef.current;
                rippleRef.current.stop(undefined, () => {
                    action.focusVisible();
                });

                onKeyUpRef.current?.();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [disabled, shortcutKey]);

    return { actionRef, rippleRef };
};

import { memo } from 'react';
import { Stack } from '@lingoda/ui';
import FormControl from '@lingoda/ui/fields/FormControl';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import {
    DateTimeSlotPicker,
    type DateTimeSlotPickerProps,
} from '@lingoda/ui/fields/DateTimeSlotPicker';
import { Field, type FieldProps } from '../utils';

interface DateTimeSlotFieldProps
    extends Omit<DateTimeSlotPickerProps, 'name' | 'value' | 'onChange' | 'children'> {
    name: string;
}

const DateTimeSlotFieldComponent = ({ name, ...props }: DateTimeSlotFieldProps) => {
    return (
        <Field name={name}>
            {({
                field,
                form: { errors, touched, setFieldValue },
                meta: { error: errorMessage },
            }: FieldProps) => {
                const handleChange = (value: Date) => setFieldValue(field.name, value);

                return (
                    <FormControl error={!!touched[field.name] && !!errors[field.name]}>
                        <Stack spacing={2}>
                            <DateTimeSlotPicker
                                name={field.name}
                                value={field.value}
                                onChange={handleChange}
                                {...props}
                            >
                                {({ dateField, timeField }) => (
                                    <>
                                        {dateField}
                                        {timeField}
                                    </>
                                )}
                            </DateTimeSlotPicker>
                            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
                        </Stack>
                    </FormControl>
                );
            }}
        </Field>
    );
};

export const DateTimeSlotField = memo(DateTimeSlotFieldComponent);

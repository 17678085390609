import { type ComponentProps, useEffect, useRef, useState } from 'react';
import { useDispatch } from '@lingoda/hooks';
import type { SnackbarKey } from '@lingoda/ui';
import { Grid, Stack } from '@lingoda/ui';
import { hideToast, showToast } from '@lingoda/toasts';
import { trans } from '@lingoda/i18n';
import { TextField } from '@lingoda/forms';
import PaymentMethodField from '../PaymentMethodField';
import {
    PAYMENT_CREDIT_CARDS,
    PAYMENT_SEPA,
    PAYMENT_SOFORT,
    PaymentMethodType,
    type StripeCardElementChangeEvent,
} from '../../models';
import { CreditCard } from '../CreditCard';
import { SofortFields } from './SofortFields';

interface Props extends Pick<ComponentProps<typeof PaymentMethodField>, 'variant'> {
    type?: PaymentMethodType;
    errorMessage: string;
    isValidCallback: (value: boolean) => void;
    setError: (value?: string) => void;
    currency: string;
    paymentMethod?: string;
    country?: string;
    name?: string;
}

export const PaymentMethodForm = ({
    type,
    setError,
    paymentMethod,
    errorMessage,
    isValidCallback,
    ...props
}: Props) => {
    const [validCardNumber, setValidCardNumber] = useState(false);
    const [validName, setValidName] = useState(false);
    const [validCountry, setValidCountry] = useState(false);
    const errorSnackbarKeyRef = useRef<SnackbarKey | null>(null);
    const dispatch = useDispatch();

    const handleCreditCardChange = (event: StripeCardElementChangeEvent) => {
        setValidCardNumber(event.complete);

        // close error snackbar if user starts typing again
        if (errorSnackbarKeyRef.current) {
            hideToast(errorSnackbarKeyRef.current);
            errorSnackbarKeyRef.current = null;
        }
    };

    useEffect(() => {
        setValidName(!!props.name);
        setError(undefined);
    }, [setError, props.name]);

    useEffect(() => {
        setValidCountry(!!props.country);
        setError(undefined);
    }, [props.country, setError]);

    useEffect(() => {
        setValidCardNumber(PAYMENT_CREDIT_CARDS !== paymentMethod);
        setValidCountry(PAYMENT_SOFORT !== paymentMethod || !!props.country);
    }, [paymentMethod, props.country]);

    // Changing name should be in a separate effect for not calling `setValidCardNumber`
    useEffect(() => {
        const paymentMethodsWithName = [PAYMENT_SEPA, PAYMENT_SOFORT, PAYMENT_CREDIT_CARDS];
        setValidName(
            !paymentMethod || !paymentMethodsWithName.includes(paymentMethod) || !!props.name,
        );
    }, [paymentMethod, props.name]);

    useEffect(() => {
        if (!errorMessage) {
            return;
        }
        const notificationMessage =
            errorMessage === `You can't buy past sprint`
                ? trans('sprint-started-in-the-past', {}, 'public-payment')
                : errorMessage;

        errorSnackbarKeyRef.current = showToast('error', notificationMessage);
    }, [dispatch, errorMessage]);

    useEffect(() => {
        isValidCallback(!type || (type && validCardNumber && validName && validCountry));
    }, [type, validName, validCountry, validCardNumber, isValidCallback]);

    return (
        <Grid item container spacing={2}>
            <Grid item xs={12}>
                <PaymentMethodField
                    variant={props.variant}
                    value={paymentMethod}
                    currency={props.currency}
                    name="paymentMethod"
                />
            </Grid>
            {type === PaymentMethodType.Card && (
                <Stack spacing={2} width="100%">
                    <TextField
                        placeholder={trans('cardholder-placeholder', {}, 'public-payment')}
                        name="name"
                        label={trans('cardholder-label', {}, 'public-payment')}
                        fullWidth
                    />
                    <CreditCard onChange={handleCreditCardChange} />
                </Stack>
            )}
            {type === PaymentMethodType.Sofort && (
                <Grid item xs={12}>
                    <SofortFields />
                </Grid>
            )}
        </Grid>
    );
};

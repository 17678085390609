import union from 'lodash/union';
import type { AppointmentsResponse } from '@lingoda/api';
import type { Draft } from '@lingoda/core';
import { clearCacheAction, handleActionsImmutably } from '@lingoda/core';
import { addStudentAppointmentIds, fetchAppointments } from '../actions';
import type { StudentAppointments } from '../models';

type AddStudentAppointmentIdsAction = ReturnType<typeof addStudentAppointmentIds>;
type FetchAppointmentsAction = ReturnType<typeof fetchAppointments.success>;

const defaultState: Draft<StudentAppointments> = {
    appointmentIds: [],
    status: 'loading',
};

type Payload = AddStudentAppointmentIdsAction['payload'] & FetchAppointmentsAction['payload'];

export default handleActionsImmutably<StudentAppointments, Payload>(
    {
        [`${clearCacheAction}`]: () => defaultState,
        [`${addStudentAppointmentIds}`]: (state, { payload }: AddStudentAppointmentIdsAction) => {
            state.appointmentIds = union(state.appointmentIds, payload);
            state.status = 'loaded';

            return state;
        },
        [`${fetchAppointments.success}`]: (state, { payload }: FetchAppointmentsAction) => {
            const appointmentIds = (payload.result as AppointmentsResponse).data.map(
                (appointment) => appointment.id,
            );
            state.appointmentIds = payload.payload?.clearCache
                ? appointmentIds
                : union(state.appointmentIds, appointmentIds);
            state.status = 'loaded';

            return state;
        },
    },
    defaultState,
);

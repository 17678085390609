import { type ReactNode } from 'react';
import { Box } from '../../../layout/Box';
import DialogContent from '../../../layout/DialogContent';
import { Typography, type TypographyProps } from '../../../layout/Typography';
import { colorsPalette } from '../../../theme';
import { type Props as BaseDialogProps, Dialog } from '../Dialog';
import { DialogConfirmTitle } from './DialogConfirmTitle';
import ElevatedDialogActions from './ElevatedDialogActions';
import { type Props as DialogActionsProps } from './DialogActions';

export interface DialogConfirmProps extends InheritedProps {
    footer?: string | ReactNode;
    scrollable?: boolean;
    scrollableContent?: boolean;
    title?: ReactNode;
    titleProps?: TypographyProps;
    actionsProps?: DialogActionsProps['layoutProps'];
    noHeader?: boolean;
}

type InheritedProps = Omit<DialogActionsProps, 'layout'> & Omit<BaseDialogProps, 'title'>;

export const DialogConfirm = ({
    children,
    confirmLabel,
    confirmStatus,
    confirmVariant,
    renderConfirmButton,
    dismissLabel,
    dismissVariant,
    footer,
    isOpen = false,
    onClose,
    onConfirm,
    onDismiss,
    scrollable,
    scrollableContent = true,
    title,
    maxWidth,
    titleProps,
    actionsProps,
    blurredBackground,
    components,
    noHeader,
    ...props
}: DialogConfirmProps) => {
    return (
        <Dialog
            {...props}
            onClose={onClose || onDismiss}
            isOpen={isOpen}
            maxWidth={maxWidth}
            scrollable={scrollable}
            blurredBackground={blurredBackground}
            components={components}
        >
            {!noHeader && (
                <DialogConfirmTitle titleProps={titleProps} onClose={onClose || onDismiss}>
                    {title}
                </DialogConfirmTitle>
            )}
            <DialogContent
                sx={{
                    px: { xs: 3, sm: 4 },
                    pt: 0,
                    pb: { xs: 0, sm: 2 },
                    overflowY: scrollableContent ? 'auto' : 'initial',
                }}
            >
                {children}
            </DialogContent>
            <ElevatedDialogActions
                confirmLabel={confirmLabel}
                confirmStatus={confirmStatus}
                confirmVariant={confirmVariant}
                renderConfirmButton={renderConfirmButton}
                dismissLabel={dismissLabel}
                dismissVariant={dismissVariant}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
                scrollable={scrollable}
                layoutProps={actionsProps}
            />
            {footer && (
                <Box
                    display="flex"
                    alignItems="center"
                    marginTop={1}
                    paddingY={3}
                    paddingX={{ xs: 3, sm: 4 }}
                    bgcolor={colorsPalette.blackAlpha[4]}
                >
                    <Typography variant="body2">{footer}</Typography>
                </Box>
            )}
        </Dialog>
    );
};

import { useMemo } from 'react';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import type { FieldMetaProps } from './_common';

export interface CustomErrorMessageProps {
    meta: FieldMetaProps<unknown>;
}

export const CustomErrorMessage = ({ meta }: CustomErrorMessageProps) => {
    const errorMessage = meta.touched && meta.error;

    return useMemo(
        () => (
            <>
                {errorMessage && (
                    <FormHelperText error>
                        {typeof errorMessage === 'object'
                            ? Object.values(errorMessage).map((msg, index) => (
                                  <p key={`${index}`}>{msg as string}</p>
                              ))
                            : errorMessage}
                    </FormHelperText>
                )}
            </>
        ),
        [errorMessage],
    );
};

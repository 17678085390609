import {
    type Action,
    type Reducer,
    type Dispatch as ReduxDispatch,
    type Store,
    applyMiddleware,
    combineReducers,
    compose,
    createStore as createReduxStore,
} from 'redux';
import { type Reducers, actionCallback, addListener, asyncReducers } from '@lingoda/core';
import { type Messages, messagesEvent } from '@lingoda/http';
import { asyncSetTime } from '@lingoda/time';
import { logout } from '@lingoda/auth';
import {
    setCurrencyListener,
    setDefaultFormatListener,
    setDefaultTimezoneListener,
} from './listener';
import addCurrentUserInfo from './middleware/addCurrentUserInfo';
import displayNotification from './middleware/displayNotification';
import ensureMinDate from './middleware/ensureMinDate';
import serverToState from './middleware/serverToState';
import updateGtmUserData from './middleware/updateGtmUserData';
import reducers, { type GlobalState } from './reducer';
import { handleHttpMessages } from './messages';

// Lazy load notifications
import('./middleware/notifications');

const createReducers = <T extends Reducers>(
    asyncReducers: T,
): Reducer<GlobalState, Action<unknown>> => {
    const rootReducer = combineReducers({ ...reducers, ...asyncReducers });

    return (state, action) => {
        if (action.type === logout().type) {
            const initialState = rootReducer(undefined, { type: undefined });

            return rootReducer(initialState, action);
        }

        return rootReducer(state, action);
    };
};

interface DevToolsWindow extends Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (params: any) => typeof compose;
}

declare const window: DevToolsWindow;

const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              actionsBlacklist: ['time/SET_TIME'],
              // Specify here name, actionsBlacklist, actionsCreators and other options
          })
        : compose;

export type Dispatch = ReduxDispatch<Action<unknown>>;

export const createStore = (): Store<GlobalState> => {
    const finalCreateStore = composeEnhancers(
        applyMiddleware(
            addCurrentUserInfo,
            ensureMinDate,
            serverToState,
            actionCallback,
            displayNotification,
            updateGtmUserData,
        ),
    );

    const store = createReduxStore<GlobalState, Action<unknown>, unknown, unknown>(
        createReducers(asyncReducers()),
        finalCreateStore,
    );

    store.subscribe(() => {
        setCurrencyListener(() => store.getState());
        setDefaultTimezoneListener(() => store.getState(), store.dispatch);
        setDefaultFormatListener(store);
    });

    messagesEvent.addListener('added', (messages: Messages) => {
        handleHttpMessages(store, messages);
    });

    return store;
};

const store = createStore();

store.dispatch(asyncSetTime());

addListener((reducers) => {
    store.replaceReducer(createReducers(reducers));
});

export default store;

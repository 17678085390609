import { createSelector } from 'reselect';
import { useSelector } from '@lingoda/hooks';
import { usePaymentRequest } from '../../hooks';
import { paymentMethodsNewSelector, paymentMethodsStoredSelector } from '../../selectors';
import { PAYMENT_REQUEST } from '../../models';
import PaymentMethodsList from './List';
import type { ComponentProps } from 'react';

interface OwnProps {
    showMethods?: 'stored' | 'new' | 'both';
    currency?: string;
}

type Props = Omit<ComponentProps<typeof PaymentMethodsList>, 'paymentMethods'>;

const methodsSelector = (showMethod?: string) =>
    createSelector(paymentMethodsNewSelector, paymentMethodsStoredSelector, (methods, stored) => {
        switch (showMethod) {
            case 'stored':
                return stored;
            case 'new':
                return methods;
            default:
                return methods.concat(stored);
        }
    });

const paymentMethodsSelector = (
    currency?: string,
    showMethod?: string,
    filterPaymentMethodsSelector?: () => string[],
) =>
    createSelector(methodsSelector(showMethod), (methods) =>
        methods.filter(
            (method) =>
                currency &&
                method.currencies.includes(currency) &&
                filterPaymentMethodsSelector &&
                !filterPaymentMethodsSelector().includes(method.type),
        ),
    );

const PaymentMethodsContainer: React.FC<Props & OwnProps> = ({ showMethods, ...props }) => {
    const showPaymentRequest = usePaymentRequest();
    const filterPaymentMethods = () => (showPaymentRequest ? [] : [PAYMENT_REQUEST]);

    const paymentMethods = useSelector(
        paymentMethodsSelector(props.currency, showMethods, filterPaymentMethods),
    );

    return <PaymentMethodsList {...props} paymentMethods={paymentMethods} />;
};

export default PaymentMethodsContainer;

import { useDispatch, useToggleState } from '@lingoda/hooks';
import { useRequestTracker } from '@lingoda/core';
import { showToast, showToasts } from '@lingoda/toasts';
import { getMessages, hasApiErrorMessages } from '@lingoda/http';
import { rolloverSubscriptionNow } from '../actions';

interface Options {
    onCompleted?: () => void;
}

export const useRolloverSubscriptionNowRequest = ({ onCompleted }: Options) => {
    const dispatch = useDispatch();
    const trackRequest = useRequestTracker();

    const [isSubmitting, submitting, submitted] = useToggleState(false);

    const request = async () => {
        submitting();

        try {
            await trackRequest(dispatch(rolloverSubscriptionNow()));
            onCompleted && onCompleted();
        } catch (error) {
            if (typeof error === 'object' && error !== null) {
                if (hasApiErrorMessages(error)) {
                    showToasts(getMessages(error.messages));
                } else if (error instanceof Error) {
                    showToast({ variant: 'error', message: error.message });
                }
            }
        }

        submitted();
    };

    return [request, { loading: isSubmitting }] as const;
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

const Icon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.46967 4.96967C2.76256 4.67678 3.23744 4.67678 3.53033 4.96967L8 9.43934L12.4697 4.96967C12.7626 4.67678 13.2374 4.67678 13.5303 4.96967C13.8232 5.26256 13.8232 5.73744 13.5303 6.03033L8 11.5607L2.46967 6.03033C2.17678 5.73744 2.17678 5.26256 2.46967 4.96967Z"
            fill="currentColor"
            fillOpacity="0.6"
        />
    </SvgIcon>
);

export const ChevronIcon24 = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path d="M6.47 8.97a.75.75 0 0 1 1.06 0L12 13.44l4.47-4.47a.75.75 0 1 1 1.06 1.06L12 15.56l-5.53-5.53a.75.75 0 0 1 0-1.06Z" />
    </SvgIcon>
);

export default Icon;

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const PersonIcon16 = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7C6.75736 7 5.75 5.99264 5.75 4.75C5.75 3.50736 6.75736 2.5 8 2.5C9.24264 2.5 10.25 3.50736 10.25 4.75C10.25 5.99264 9.24264 7 8 7ZM8 8.5C5.92893 8.5 4.25 6.82107 4.25 4.75C4.25 2.67893 5.92893 1 8 1C10.0711 1 11.75 2.67893 11.75 4.75C11.75 6.82107 10.0711 8.5 8 8.5Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.7811 11.0495C3.70909 9.84619 5.33354 9 8 9C10.6665 9 12.2909 9.84619 13.2189 11.0495C14.1185 12.216 14.25 13.5764 14.25 14.39C14.25 14.8042 13.9142 15.14 13.5 15.14H2.5C2.08579 15.14 1.75 14.8042 1.75 14.39C1.75 13.5764 1.88149 12.216 2.7811 11.0495ZM3.30142 13.64H12.6986C12.6199 13.0918 12.434 12.4879 12.0311 11.9655C11.4591 11.2238 10.3335 10.5 8 10.5C5.66646 10.5 4.54091 11.2238 3.9689 11.9655C3.56604 12.4879 3.38014 13.0918 3.30142 13.64Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export const PersonIcon24 = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.34327 4.55756C10.1805 3.72034 11.316 3.25 12.5 3.25C13.684 3.25 14.8195 3.72034 15.6567 4.55756C16.4939 5.39477 16.9643 6.53028 16.9643 7.71429C16.9643 8.89829 16.4939 10.0338 15.6567 10.871C14.8195 11.7082 13.684 12.1786 12.5 12.1786C11.316 12.1786 10.1805 11.7082 9.34327 10.871C8.50606 10.0338 8.03571 8.89829 8.03571 7.71429C8.03571 6.53028 8.50606 5.39477 9.34327 4.55756ZM12.5 4.75C11.7138 4.75 10.9598 5.06231 10.4039 5.61822C9.84802 6.17413 9.53571 6.92811 9.53571 7.71429C9.53571 8.50046 9.84802 9.25444 10.4039 9.81035C10.9598 10.3663 11.7138 10.6786 12.5 10.6786C13.2862 10.6786 14.0402 10.3663 14.5961 9.81035C15.152 9.25444 15.4643 8.50046 15.4643 7.71429C15.4643 6.92811 15.152 6.17413 14.5961 5.61822C14.0402 5.06231 13.2862 4.75 12.5 4.75ZM6.38822 16.4985C7.42301 14.7455 9.30784 13.4643 12.5 13.4643C15.6922 13.4643 17.577 14.7455 18.6118 16.4985C19.605 18.1811 19.75 20.1917 19.75 21.5714C19.75 21.9856 19.4142 22.3214 19 22.3214H6C5.58579 22.3214 5.25 21.9856 5.25 21.5714C5.25 20.1917 5.39498 18.1811 6.38822 16.4985ZM6.76774 20.8214H18.2323C18.1746 19.6553 17.958 18.3418 17.32 17.261C16.5821 16.0109 15.2169 14.9643 12.5 14.9643C9.78307 14.9643 8.4179 16.0109 7.67996 17.261C7.04198 18.3418 6.82536 19.6553 6.76774 20.8214Z"
            fill="currentColor"
        />
    </SvgIcon>
);

import { useEffect, useMemo } from 'react';
import {
    getValidationErrorMessages,
    usePersonalizedFeedbackQuestionsQuery,
    usePersonalizedFeedbackSubmitMutation,
} from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { gqlErrorsToToasts, showToast, showToasts } from '@lingoda/toasts';
import { SlidePopupHeader } from '@lingoda/ui';
import {
    PersonalFeedbackSatisfactionForm,
    type PersonalFeedbackSatisfactionFormProps,
} from '../PersonalFeedbackSatisfactionForm';
import { getFormTranslations, submitDismissState, transformFormValuesToPayload } from './utils';

interface ContentProps {
    onCompleted: () => void;
    close: () => void;
}

export const PersonalFeedbackSatisfactionContent = ({ close, onCompleted }: ContentProps) => {
    const dispatch = useDispatch();

    const { data } = usePersonalizedFeedbackQuestionsQuery();
    const { personalizedFeedbackQuestions: questions } = data || {};

    const translations = useMemo(
        () => (questions?.length ? getFormTranslations(questions) : null),
        [questions],
    );

    const [submitFeedback] = usePersonalizedFeedbackSubmitMutation({
        onCompleted,
        onError: (error) => {
            const toasts = gqlErrorsToToasts(getValidationErrorMessages(error));
            if (toasts.length) {
                showToasts(toasts);
            } else {
                showToast('error', error.message);
            }
        },
    });

    useEffect(() => {
        if (!translations) {
            close();
        }
    }, [translations, close]);

    if (!translations) {
        return null;
    }

    const handleDismiss = () => {
        close();
        dispatch(submitDismissState());
    };

    const handleSubmit: PersonalFeedbackSatisfactionFormProps['onSubmit'] = (values) => {
        return submitFeedback({ variables: transformFormValuesToPayload(values) });
    };

    return (
        <>
            <SlidePopupHeader
                title={translations.isHelpful.label}
                onClose={handleDismiss}
                sx={{ mb: 3 }}
            />
            <PersonalFeedbackSatisfactionForm translations={translations} onSubmit={handleSubmit} />
        </>
    );
};

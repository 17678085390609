import { createSelector } from 'reselect';
import pendingNotificationByType from './pendingNotificationByType';
import type { Notification, PayloadRateClass } from '../models';

export default (selector: (state: unknown, props: unknown) => string) =>
    createSelector(pendingNotificationByType('rateClass'), selector, (notifications, classId) =>
        notifications.find(
            (notification) =>
                (notification as Notification<PayloadRateClass>)?.payload?.classId === classId,
        ),
    );

import { useEffect } from 'react';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { setModule, studentSelector } from '@lingoda/students';
import { useRequestTracker } from '@lingoda/core';
import { useLearningTeamsModule } from './useLearningTeamsModule';

/*
 * This hook is used to set the module for the learning teams
 */
export const useLearningTeamsModuleSetter = () => {
    const { loading, modules } = useLearningTeamsModule();
    const dispatch = useDispatch();
    const trackRequest = useRequestTracker();
    const student = useSelector(studentSelector);

    const studentModuleId = student?.moduleId || 0;

    useEffect(() => {
        if (modules.length > 0 && !modules.some(({ id }) => studentModuleId === id)) {
            void trackRequest(dispatch(setModule({ moduleId: modules[0].id })));
        }
    }, [modules, studentModuleId, dispatch, trackRequest]);

    return loading;
};

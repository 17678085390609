import type { BookableClassFragment, ClassType } from '@lingoda/graphql';

export * from './classesVars';
export * from './leadTime';
export * from './sectionLeadTime';
export * from './bookClassEvent';

export enum BookingView {
    Grid = 'grid',
    List = 'list',
}

export interface BookingFilter {
    readonly timeSlots: {
        readonly slots: number[];
        readonly extendHours: number;
    };
    readonly classType: ClassType;
    readonly modules: number[];
    readonly lessons: number[];
    readonly dates: Date[];
}

export interface ChapterSelection {
    readonly color?: string;
    readonly icon?: string;
    readonly id?: number;
    readonly lessonIds?: number[];
    readonly moduleId?: number;
    readonly name?: string;
    readonly position?: number;
    readonly sectionId?: number;
    readonly chapter?: number;
}

export interface BookableClass extends Omit<BookableClassFragment, 'startDate'> {
    startDate: Date;
}

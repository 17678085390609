import store from 'store';
import { render } from 'react-dom';
import { createElement } from 'react';
import configure from 'configure';
import { initGtm, initMarketingData, initVitals, trackUrl } from '@lingoda/analytics';
import { init as authInit, fetchMe } from '@lingoda/auth';
import { initRequestWebProvider } from '@lingoda/http';
import { startClock } from '@lingoda/clock';
import { initErrorTracker } from '@lingoda/monitor';
import { currentRouteLabel, goTo, history, logoutPath } from '@lingoda/router';
import { initBraze } from '@lingoda/braze-sdk';
import Root from './root';
import routes from './routes';

initErrorTracker(history);

authInit();
startClock();
initVitals();

initRequestWebProvider({
    onUnauthorized: () => goTo(logoutPath()),
});

window.addEventListener('load', () => {
    initGtm();
    initMarketingData(history);
    initBraze();

    trackUrl(history.location.pathname, currentRouteLabel(routes));

    history.listen(({ pathname }) => {
        trackUrl(pathname, currentRouteLabel(routes));
    });
});

void configure();

store.dispatch(fetchMe());

render(createElement(Root, { history }), document.getElementById('root'));

import { createSelector } from 'reselect';
import startingModulesSelector from './startingModules';
import type { StartingModule } from '../models';
import type { State as StartingModulesState } from '../reducers/startingModule';

export default createSelector(
    startingModulesSelector,
    (
        startingModules: StartingModule[],
    ): ReadonlyArray<StartingModulesState['modules']['StartingModule']> =>
        Object.values<StartingModule>(startingModules).sort(
            (moduleA, moduleB) => moduleA.position - moduleB.position,
        ),
);

import { useCallback } from 'react';
import type { TimeFormat } from '@lingoda/dates';
import { Box, Divider, Stack, TimeSlotPickerMenu } from '@lingoda/ui';
import { ExtendButtons } from './ExtendButtons';
import type { TimeSlotsFilterValue } from './model';
import type { FilterProps } from '../model';

export type TimeSlotFilterMenuProps = FilterProps<TimeSlotsFilterValue> & {
    timeFormat: TimeFormat;
    disabled?: boolean;
    slotsInRow?: 3 | 6;
};

export const TimeSlotFilterMenu = ({
    value,
    onChange,
    disabled,
    ...menuProps
}: TimeSlotFilterMenuProps) => {
    const handleSlotChange = useCallback(
        (slots: number[]) => {
            onChange({ timeSlots: { ...value, slots } });
        },
        [onChange, value],
    );

    return (
        <Stack spacing={3}>
            <TimeSlotPickerMenu
                multiple
                value={value.slots}
                onChange={handleSlotChange}
                disabled={disabled}
                {...menuProps}
            />
            <Divider />
            <Box>
                <ExtendButtons value={value} onChange={onChange} disabled={disabled} />
            </Box>
        </Stack>
    );
};

import { useCallback } from 'react';
import { useLearningTeamsCourseDateQuery } from '@lingoda/graphql';
import { createDate, isBetween } from '@lingoda/dates';

export const useIsLearningTeamsClassChecker = (skip: boolean) => {
    const { data } = useLearningTeamsCourseDateQuery({ skip: skip });

    return useCallback(
        (klassStartDate: string) => {
            if (
                skip ||
                !data?.studentLearningTeamInfo?.courseStartDate ||
                !data?.studentLearningTeamInfo?.courseEndDate
            ) {
                return false;
            }

            const classStartDate = createDate(klassStartDate);
            const courseStartDate = createDate(data.studentLearningTeamInfo.courseStartDate);
            const courseEndDate = createDate(data.studentLearningTeamInfo.courseEndDate);

            return isBetween(courseStartDate, courseEndDate, classStartDate);
        },
        [skip, data],
    );
};

import { useCallback } from 'react';
import { useDispatch } from '@lingoda/hooks';
import { showDialog } from '../actions';
import type { DialogProps } from '../actions';

const useDialog = <T extends keyof DialogProps>(type: T) => {
    const dispatch = useDispatch();

    return useCallback(
        (params: DialogProps[T] = {}) => {
            dispatch(showDialog(type, params));
        },
        [type, dispatch],
    );
};

export default useDialog;

import { useCallback, useMemo } from 'react';
import type { ParsedQsWithKnownKeys } from '@lingoda/urls';
import { buildQueryString, getQueryParams } from '@lingoda/urls';
import useHistory from './useHistory';
import useLocation from './useLocation';

const useQueryStringState = <T extends object = Record<string, unknown>>() => {
    const history = useHistory();
    const location = useLocation();

    const state = useMemo(() => getQueryParams<T>(location.search), [location.search]);

    const setState = useCallback(
        (
            callback: (prev: ParsedQsWithKnownKeys<T>) => Record<keyof T, unknown>,
            method: 'push' | 'replace' = 'replace',
        ) => {
            const { location } = history;
            const state = getQueryParams<T>(location.search);

            const nextState = callback(state);
            history[method]({ ...location, search: buildQueryString(nextState) });
        },
        [history],
    );

    return [state, setState] as const;
};

export default useQueryStringState;

import { useCallback } from 'react';
import { useTrackingContext } from './useTrackingContext';

export const useTrackingActionEnhancer = () => {
    const trackingContext = useTrackingContext();

    return useCallback(
        <A extends { type: string; meta?: Record<string, unknown> }>(action: A) => {
            action.meta = {
                ...action.meta,
                uiContext: trackingContext,
            };

            return action;
        },
        [trackingContext],
    );
};

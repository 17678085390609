import { type Variant } from '../../types';
import { LocaleFlag } from '../FlashCardContent';
import { CardFaceBase } from './CardFaceBase';
import type { PropsWithChildren } from 'react';

interface Props {
    id: string;
    targetLang: string;
    variant: Variant;
}

export const CardFaceTranslation = ({
    children,
    id,
    targetLang,
    variant,
}: PropsWithChildren<Props>) => {
    return (
        <CardFaceBase dataCy="flashcard-translation" variant={variant}>
            {children}
            {/* key fixes svg mask disappearance in Safari browser on cards change */}
            <LocaleFlag key={id} locale={targetLang} size="small" />
        </CardFaceBase>
    );
};

import { getStorageValue, removeStorageValue, setStorageValue } from '@lingoda/utils';
import type { UserSocialInfoSchema } from '../types';

export const SOCIAL_REGISTER_SESSION_INFO_KEY = 'socialRegisterSessionInfo';

export const setSocialRegisterSessionInfo = (data: UserSocialInfoSchema) =>
    setStorageValue(SOCIAL_REGISTER_SESSION_INFO_KEY, data);

export const getSocialRegisterSessionInfo = (): UserSocialInfoSchema | null =>
    getStorageValue(SOCIAL_REGISTER_SESSION_INFO_KEY) as UserSocialInfoSchema | null;

export const removeSocialRegisterSessionInfo = () =>
    removeStorageValue(SOCIAL_REGISTER_SESSION_INFO_KEY);

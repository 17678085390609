import { handleActionsImmutably } from '@lingoda/core';
import { hideDialog, removeDialog, showDialog } from '../actions';
import type { Dialog } from '../models';

export type State = Record<string, Dialog>;

type ShowDialogAction = ReturnType<typeof showDialog>;
type HideDialogAction = ReturnType<typeof hideDialog>;
type RemoveDialogAction = ReturnType<typeof removeDialog>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default handleActionsImmutably<State, any>(
    {
        [`${showDialog}`]: (state, { payload }: ShowDialogAction) => {
            state[payload.id] = payload;

            return state;
        },
        [`${removeDialog}`]: (state, { payload }: RemoveDialogAction) => {
            if (state[payload]) {
                delete state[payload];
            }

            return state;
        },
        [`${hideDialog}`]: (state, { payload }: HideDialogAction) => {
            if (state[payload]) {
                state[payload].active = false;
            }

            return state;
        },
    },
    {},
);

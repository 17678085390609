import { type Popup, type PopupVariant } from '../types';

export const getHighestPriority = (popups: ReadonlyArray<Popup>) => {
    if (popups.length < 1) {
        return null;
    }

    return popups.reduce(getHigherPriority, null);
};

const priority: PopupVariant[] = [
    'bookingFeedback',
    'personalFeedbackSatisfaction',
    'practiceSatisfaction',
];

const getHigherPriority = (previous: Popup | null, current: Popup) => {
    return previous && priority.indexOf(previous.variant) > priority.indexOf(current.variant)
        ? previous
        : current;
};

import { type Dispatch } from 'store';
import { currentVisitorTimezoneSelector, userSelector } from '@lingoda/auth';
import { setDefaultTimezone } from '@lingoda/dates';
import { revalidateBookingFilter } from '@lingoda/booking';
import { type GlobalState } from '../reducer';

let cachedTimezone: ReturnType<typeof currentVisitorTimezoneSelector>;

export default (getState: () => GlobalState, dispatch: Dispatch) => {
    const state = getState();
    const timezone = currentVisitorTimezoneSelector(state);
    const hasUser = userSelector(state);

    if (cachedTimezone !== timezone) {
        setDefaultTimezone(timezone);
        cachedTimezone = timezone;

        if (hasUser) {
            dispatch(revalidateBookingFilter());
        }
    }
};

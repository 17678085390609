import { Grid, Skeleton, Stack, Typography } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';

const Wrapper = styled(Grid)(({ theme }) => ({
    minHeight: 295,
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3),
}));

export const ClassTileSkeleton = () => (
    <Wrapper container justify="space-between" direction="column">
        <Stack spacing={1}>
            <Typography variant="subtitle2">
                <Skeleton variant="text" width={50} />
            </Typography>

            <Typography variant="h3">
                <Skeleton variant="text" />
                <Skeleton variant="text" width="50%" />
            </Typography>

            <Stack direction="row" spacing={1} pb={1}>
                <Typography variant="overline">
                    <Skeleton variant="text" width={30} />
                </Typography>
                <Typography variant="overline">
                    <Skeleton variant="text" width={60} />
                </Typography>
                <Typography variant="overline">
                    <Skeleton variant="text" width={70} />
                </Typography>
            </Stack>

            <Typography variant="overline">
                <Skeleton variant="text" width="80%" />
            </Typography>

            <Stack direction="row" alignItems="center" spacing={1} mb={3}>
                <Skeleton variant="circular" width={40} height={40} />

                <Stack direction="row">
                    <Skeleton variant="circular" width={28} height={28} />
                    <Skeleton variant="circular" width={28} height={28} />
                </Stack>
            </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2} mt={3}>
            <Skeleton variant="rounded" width={60} height={30} />
            <Skeleton variant="rounded" width={40} height={24} />
        </Stack>
    </Wrapper>
);

export const getBookingClassPosition = (index: number, length: number) => {
    if (length === 1) {
        return undefined;
    }
    if (index === 0) {
        return 'top';
    }

    if (index < length - 1) {
        return 'middle';
    }

    return 'bottom';
};

import { lazy } from 'react';
import { type RouteConfigExtended, studentEmailVerificationPath } from '@lingoda/router';

const EmailVerificationPage = lazy(() => import('./pages/EmailVerificationPage'));

export const emailVerificationRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: EmailVerificationPage,
        path: studentEmailVerificationPath.raw,
        label: 'EmailVerificationPage',
        config: {
            hideNavigation: true,
            logoIsIcon: true,
            hideFooter: true,
            tab: undefined,
        },
    },
];

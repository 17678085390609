import { clearCacheAction, handleActionsImmutably } from '@lingoda/core';
import {
    markSeenNotifications,
    removeNotification,
    showNotification,
    showNotifications,
} from '../actions';
import { notificationFactory } from '../models';
import type { Notification } from '../models';

export type State = Readonly<GenericObject<Notification<unknown>>>;

type AddNotificationAction = ReturnType<typeof showNotification>;
type ShowNotificationsAction = ReturnType<typeof showNotifications>;
type RemoveNotificationAction = ReturnType<typeof removeNotification>;

type Payload = AddNotificationAction['payload'] &
    ShowNotificationsAction['payload'] &
    RemoveNotificationAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${showNotification}`]: (state, { payload }: AddNotificationAction) => {
            state[payload.id] = notificationFactory(payload);

            return state;
        },
        [`${showNotifications}`]: (state, { payload }: ShowNotificationsAction) =>
            payload.reduce((s, notification) => {
                s[notification.id] = notificationFactory(notification);

                return s;
            }, state),
        [`${removeNotification}`]: (state, { payload }: RemoveNotificationAction) => {
            delete state[payload];

            return state;
        },
        [`${markSeenNotifications}`]: (state) => {
            return Object.keys(state).reduce((acc, id) => {
                acc[id] = { ...acc[id], seen: true };

                return acc;
            }, state);
        },
        [`${clearCacheAction}`]: () => ({}),
    },
    {},
);

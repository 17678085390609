import { memo } from 'react';
import { Grid, Skeleton, Typography } from '@lingoda/ui';
import { ClassGrid } from './ClassGrid';
import { ClassTileSkeleton } from './ClassTileItem/ClassTileSkeleton';

interface Props {
    count: number;
}

const ClassGridSkeletonComponent = ({ count }: Props) => (
    <Grid container spacing={2} data-cy="Class placeholder">
        <Grid item xs={12}>
            <Typography variant="body1">
                <Skeleton variant="text" width={230} />
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <ClassGrid>
                {[...Array(count).keys()].map((key) => (
                    <ClassTileSkeleton key={key} />
                ))}
            </ClassGrid>
        </Grid>
    </Grid>
);

export const ClassGridSkeleton = memo(ClassGridSkeletonComponent);

import { Typography } from '@lingoda/ui';
import type { PropsWithChildren } from 'react';

interface FilterLabelProps {
    id?: string;
}

export const FilterLabel = ({ children, id }: PropsWithChildren<FilterLabelProps>) => (
    <Typography variant="body3" component="label" noWrap id={id}>
        {children}
    </Typography>
);

import groupBy from 'lodash/groupBy';
import { Grid, Typography } from '@lingoda/ui';
import { addDays, createDate, format } from '@lingoda/dates';
import { CalendarWeek } from '../CalendarWeek';
import type { CalendarWeekProps } from '../CalendarWeek';
import type { FC } from 'react';

export type CalendarMonthsProps = Pick<
    CalendarWeekProps,
    'disabledDates' | 'disabled' | 'onDateChange' | 'value'
>;

const formatMonthYear = format('LLLL yyyy');

const months = Object.entries(
    groupBy(
        [...Array(7)].map((_, index) => addDays(createDate(), index)),
        (date) => formatMonthYear(date),
    ),
);

export const CalendarMonths: FC<CalendarMonthsProps> = (props) => (
    <Grid container spacing={2} alignItems="flex-end">
        {months.map(([month, dates], index) => (
            <Grid item key={index}>
                <Grid item container spacing={2} key={index} direction="column">
                    {dates.length > 1 && (
                        <Grid item>
                            <Typography variant="subtitle1" textTransform="capitalize">
                                {month}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <CalendarWeek dates={dates} {...props} />
                    </Grid>
                </Grid>
            </Grid>
        ))}
    </Grid>
);

import { Box } from '@lingoda/ui';
import { CardFace } from '../FlashCardFaces';

export interface FlashCardPreviewProps {
    backFace: React.ReactNode;
    frontFace: React.ReactNode;
    isSwapped?: boolean;
}

export const FlashCardPreview = ({
    backFace,
    frontFace,
    isSwapped = false,
}: FlashCardPreviewProps) => {
    return (
        <Box width="100%">
            {isSwapped ? (
                <CardFace key="backFace" isBackFace={false}>
                    {backFace}
                </CardFace>
            ) : (
                <CardFace key="frontFace" isBackFace={false}>
                    {frontFace}
                </CardFace>
            )}
        </Box>
    );
};

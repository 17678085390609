import { useCallback, useMemo } from 'react';
import {
    addDays,
    addMonths,
    createDate,
    isSameDayOrAfter,
    isSameDayOrBefore,
    startOfDay,
} from '@lingoda/dates';
import { type BookingFilter, getValidRangeDate } from '@lingoda/booking';

export const useBookingFilterDateSelector = (
    shownDate: Date | null,
    timeSlots: {
        readonly slots: number[];
        readonly extendHours: number;
    },
    handleFilterChange: (newFilter: Partial<BookingFilter>) => void,
) => {
    const selectPrevDate = useCallback(() => {
        if (!shownDate) {
            return;
        }

        const prevDate = addDays(shownDate, -1);
        handleFilterChange({ dates: [prevDate] });
    }, [shownDate, handleFilterChange]);

    const selectNextDate = useCallback(() => {
        if (!shownDate) {
            return;
        }

        const nextDate = addDays(shownDate, 1);
        handleFilterChange({ dates: [nextDate] });
    }, [shownDate, handleFilterChange]);

    const today = useMemo(() => startOfDay(createDate()), []);
    const minDate = useMemo(() => getValidRangeDate(createDate(), timeSlots), [timeSlots]);
    const maxDate = useMemo(() => addMonths(today, 3), [today]);

    const isPrevDateDisabled = !shownDate || isSameDayOrBefore(shownDate, minDate);
    const isNextDateDisabled = !shownDate || isSameDayOrAfter(shownDate, maxDate);

    return {
        selectPrevDate,
        selectNextDate,
        isPrevDateDisabled,
        isNextDateDisabled,
        minDate,
        maxDate,
    };
};

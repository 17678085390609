import { v4 as uuidV4 } from 'uuid';
import { createDate } from '@lingoda/dates';
import type { ClassesClassSchema } from '@lingoda/api';
import type { ClassType } from '@lingoda/graphql';
import { ClassFactory } from '../models';
import type { ClassCancelledByGroup, ClassProviders, ClassStatus } from '../models';

export const serverResponseToModel = ({
    currentStudentAppointment,
    type,
    provider,
    status,
    cancelledByGroup,
    startDate,
    endDate,
    ...klass
}: ClassesClassSchema) =>
    ClassFactory({
        ...klass,
        temporary: !klass.id && true,
        id: klass.id || klass.tempId || uuidV4(),
        type: type as ClassType,
        provider: provider as ClassProviders,
        status: status as ClassStatus,
        cancelledByGroup: cancelledByGroup as ClassCancelledByGroup,
        startDate: createDate(startDate),
        endDate: createDate(endDate),
    });

import type { FormBag, FormValues } from './_common';

export const addFormErrors = <T extends FormValues>(
    errors: { [key: string]: string },
    formikBag: FormBag<unknown, T>,
) =>
    Object.keys(errors).forEach((key) => {
        formikBag.setFieldError(key, errors[key]);
    });

export const countryToFlag = (isoCode: string) =>
    typeof String.fromCodePoint !== 'undefined'
        ? isoCode
              .toUpperCase()
              .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;

// temporary until all migration to formik useField is done
export * from './CustomErrorMessage';
export * from './_common';
export { default as buildValidator } from './buildValidator';
export { handleFormSubmit } from './handleFormSubmit';

import { Box, Stack, Typography, colorsPalette, styled } from '@lingoda/ui';
import { TypographyAudio } from '@lingoda/audio';

interface ChallengePrizeItemProps {
    noun: string;
    explanation: string;
    nounTranslation: string;
    unlocked: boolean;
    lockedIconUrl: string | undefined;
    unlockedIconUrl: string | undefined;
    audioUrl: string | null;
}

export const ChallengePrizeItem = ({
    noun,
    explanation,
    nounTranslation,
    unlocked,
    unlockedIconUrl,
    lockedIconUrl,
    audioUrl,
}: ChallengePrizeItemProps) => {
    return (
        <StyledBox>
            {unlocked ? (
                <Stack spacing={1} width="100%" alignItems="center" sx={{ textAlign: 'center' }}>
                    <img alt={nounTranslation} width={90} height={90} src={unlockedIconUrl} />
                    {audioUrl ? (
                        <TypographyAudio variant="subtitle1" title={noun} audioUrl={audioUrl} />
                    ) : (
                        <Typography variant="subtitle1">{noun}</Typography>
                    )}
                    <Typography
                        variant="body2"
                        htmlColor={colorsPalette.blackAlpha[60]}
                        fontStyle="italic"
                    >
                        {explanation}
                    </Typography>
                    <Typography variant="body2">{nounTranslation}</Typography>
                </Stack>
            ) : (
                <img alt="Prize" width={56} height={56} src={lockedIconUrl} />
            )}
        </StyledBox>
    );
};

const StyledBox = styled(Box)(({ theme }) => ({
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colorsPalette.blackAlpha[16]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    minHeight: 112,
    [theme.breakpoints.up('smw')]: {
        minHeight: 208,
    },
}));

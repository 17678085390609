import { getSessionValue, removeSessionValue, setSessionValue } from '@lingoda/utils';
import type { Path } from './types';

const SESSION_KEY = 'redirectPath';

export const setRedirectPathStorage = (path: Path) => setSessionValue(SESSION_KEY, path);

export const removeRedirectPathStorage = () => removeSessionValue(SESSION_KEY);

export const getRedirectPathStorage = (): Path => getSessionValue(SESSION_KEY) as Path;

import { useMemo } from 'react';
import { Box, Button, Stack, colorsPalette, useUiEvent } from '@lingoda/ui';
import { useToggleState } from '@lingoda/hooks';
import { useBookingWidgetQuery } from '@lingoda/graphql';
import { sortByStartDate } from '@lingoda/classes';
import { actionClick } from '@lingoda/analytics';
import { trans } from '@lingoda/i18n';
import { BookingOverlay } from '../../BookingOverlay';
import { getWidgetClassSlotsInput } from '../utils';
import { GroupClassItem } from './GroupClassItem';
import { GroupClassesSkeleton } from './GroupClassesSkeleton';

interface Props {
    moduleId: number;
    lessonId: number;
}

export const GroupClassBookingDesktop = ({ moduleId, lessonId }: Props) => {
    const addEvent = useUiEvent();
    const [isBookingOverlayOpen, openBookingOverlay, closeBookingOverlay] = useToggleState();
    const { data, loading } = useBookingWidgetQuery({
        variables: {
            bookingFilter: getWidgetClassSlotsInput({ moduleId, lessonId }),
        },
    });

    const classes = useMemo(() => {
        return Array.from(data?.classes || [])
            .sort(sortByStartDate()) // TODO: figure out why BE returns unsorted classes list
            .slice(0, 3);
    }, [data?.classes]);

    const handleSeeMoreClick = () => {
        void addEvent(actionClick('SeeMoreClasses'));
        openBookingOverlay();
    };

    return (
        <Box>
            <Stack
                sx={{
                    mt: 2,
                    mb: 3,
                    ml: -3,
                    mr: -3,
                    listStyle: 'none',
                    padding: 0,
                    borderTop: `1px solid ${colorsPalette.blackAlpha[16]}`,
                }}
                role="list"
            >
                {classes.map((classItem) => (
                    <GroupClassItem
                        key={classItem.uid}
                        classItem={classItem}
                        lessonId={lessonId}
                        moduleId={moduleId}
                        role="listitem"
                    />
                ))}
                {loading && classes.length === 0 && <GroupClassesSkeleton />}
            </Stack>
            <Button color="primary" variant="outlined" onClick={handleSeeMoreClick} fullWidth>
                {trans('btn-see-more-classes', {}, 'student-common')}
            </Button>
            <BookingOverlay
                moduleId={moduleId}
                lessonId={lessonId}
                open={isBookingOverlayOpen}
                onClose={closeBookingOverlay}
            />
        </Box>
    );
};

import { isClassUnbooked, useClassStateStudent } from '@lingoda/classes';
import { PostBookingClassDetails, PostClassDetails, PreBookingClassDetails } from './components';
import type { ExistingClassData, GeneratedClassData } from '../../types';

interface LayoutProps {
    onBookClick?: () => void;
    onCancelClick?: () => void;
    isClassBookable?: boolean;
    portalContainers?: { status?: Element | null; cta?: Element | null };
    classItem: ExistingClassData | GeneratedClassData;
}

export const Layout = ({
    onBookClick,
    onCancelClick,
    isClassBookable,
    portalContainers,
    classItem,
}: LayoutProps) => {
    const classId = classItem?.isExisting ? classItem.uid : undefined;
    const { hasEnded } = useClassStateStudent(classId);
    const isBooked = classItem.isExisting && !isClassUnbooked(classItem);

    if (classItem.isExisting && hasEnded) {
        return <PostClassDetails classItem={classItem} portalContainers={portalContainers} />;
    }

    if (isBooked) {
        return (
            <PostBookingClassDetails
                classItem={classItem}
                onCancelClick={onCancelClick}
                portalContainers={portalContainers}
            />
        );
    }

    return (
        <PreBookingClassDetails
            classItem={classItem}
            onBookClick={onBookClick}
            isClassBookable={isClassBookable}
            portalContainers={portalContainers}
        />
    );
};

import { trans } from '@lingoda/i18n';
import { FormWrapper, TextField } from '@lingoda/forms';
import { Box, Button, Stack, Typography } from '@lingoda/ui';
import { QuestionKeys } from './types';
import { buildFormInitState } from './utils';
import { BookingFeedbackQuestion } from './BookingFeedbackQuestion';
import type { BookingExperienceQuestion, FormState } from './types';

interface BookingFeedbackFormFieldsProps {
    onClose: () => void;
    onConfirm: (form: FormState) => void;
    confirmLabel: string;
    questions: BookingExperienceQuestion[];
    screenNumber: number;
    screensAmount: number;
}

export const BookingFeedbackFormFields = ({
    onClose,
    confirmLabel,
    onConfirm,
    questions,
    screenNumber,
    screensAmount,
}: BookingFeedbackFormFieldsProps) => {
    const maxStepsToShow = screensAmount - 1;

    return (
        <FormWrapper
            initialValues={buildFormInitState(questions, screenNumber)}
            onSubmit={onConfirm}
            enableReinitialize
        >
            {({ submitForm, values }) => (
                <Stack spacing={4}>
                    <Stack spacing={3}>
                        {questions.map((question, index) => (
                            <BookingFeedbackQuestion
                                key={question.key}
                                question={question}
                                index={index}
                                onClose={onClose}
                            />
                        ))}
                        {screenNumber === 2 && (
                            <TextField
                                name="comment"
                                label={trans('other-comments', {}, 'student-common')}
                            />
                        )}
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography>
                            {trans(
                                'step-of-max-steps',
                                { step: screenNumber, maxSteps: maxStepsToShow },
                                'student-common',
                            )}
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={screenNumber === 1 && !values[QuestionKeys.HowSatisfied]}
                            onClick={submitForm}
                        >
                            {confirmLabel}
                        </Button>
                    </Box>
                </Stack>
            )}
        </FormWrapper>
    );
};

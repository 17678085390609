import { type PropsWithChildren } from 'react';
import Checkbox, { type CheckboxProps } from '@lingoda/ui/fields/Checkbox';
import FormControl from '@lingoda/ui/fields/FormControl';
import FormControlLabel, { type FormControlLabelProps } from '@lingoda/ui/fields/FormControlLabel';
import { type FormErrors } from '../../utils';

type Props = Omit<FormControlLabelProps, 'control' | 'name' | 'onChange'> & {
    checkboxProps?: CheckboxProps;
    errors: FormErrors<Record<string, never>>;
    name: string;
    onChange?: CheckboxProps['onChange'];
};

export const Layout = ({
    checkboxProps,
    checked,
    errors,
    name,
    onChange,
    children,
    ...rest
}: PropsWithChildren<Props>) => {
    // fixing for array values
    const normalizedName = name.split('.')[0];

    return (
        <FormControl
            error={!!errors[normalizedName]}
            required={checkboxProps && checkboxProps.required}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        name={name}
                        checked={checked}
                        {...checkboxProps}
                        onChange={onChange}
                    />
                }
                sx={{ alignItems: 'flex-start', '& .MuiFormControlLabel-label': { paddingTop: 1 } }}
                {...rest}
            />
            {children}
        </FormControl>
    );
};

import { useEffect, useState } from 'react';
import { type ClockInterval } from '@lingoda/clock';
import { useIntervalState } from '../useIntervalState';

export interface UseIntervalStateAutoDisabledOptions<T> {
    getDisabled?: (state?: T) => boolean;
}

// Calculates new state with interval manner
// Typically used when getState function is time dependent to trigger rerender
// Returns new state only when it is different from previous one
// getDisabled can stop interval without rerender the hook as it is calculated in interval manner as well
export const useIntervalStateAutoDisabled = <T>(
    getState: (previousState?: T) => T,
    clockInterval: ClockInterval,
    { getDisabled = defaultGetDisable }: UseIntervalStateAutoDisabledOptions<T> = {},
) => {
    const [disabled, setDisabled] = useState(getDisabled);

    const [state] = useIntervalState<T>(getState, clockInterval, { disabled });

    useEffect(() => {
        setDisabled(getDisabled(state));
    }, [getDisabled, state]);

    return [state, disabled] as const;
};

const defaultGetDisable = () => false;

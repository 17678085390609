import { useMemo } from 'react';
import { getCookieValue } from '@lingoda/cookie';
import { useLocation } from '@lingoda/router';
import { getQueryParam } from '@lingoda/urls';
import type { GetAmbassadorReferral } from '../models';

export const useGetAmbassadorReferral = (): GetAmbassadorReferral | null => {
    const location = useLocation();
    const queryCampaignId = getQueryParam<string>('campaignid', location);
    const queryShortCode = getQueryParam<string>('mbsy', location);
    const cookieCampaignId = getCookieValue('mbsy_campaign_uid');
    const cookieShortCode = getCookieValue('mbsy_short_code');

    return useMemo(() => {
        if (cookieShortCode && cookieCampaignId) {
            return {
                shortCode: cookieShortCode,
                campaignId: parseInt(cookieCampaignId, 10),
            } as GetAmbassadorReferral;
        }

        if (queryCampaignId && queryShortCode) {
            return {
                shortCode: queryShortCode,
                campaignId: parseInt(queryCampaignId, 10),
            } as GetAmbassadorReferral;
        }

        return null;
    }, [queryCampaignId, queryShortCode, cookieCampaignId, cookieShortCode]);
};

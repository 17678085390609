import { SvgIcon } from '../layout/SvgIcon';
import { colorsPaletteThirdParty } from '../theme';
import type { SvgIconProps } from '../layout/SvgIcon';
import type * as React from 'react';

const LinkedInIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        viewBox="0 0 16 16"
        htmlColorDefault={colorsPaletteThirdParty.linkedInSolid[100]}
        {...props}
    >
        <path d="M13.633 13.633h-2.37V9.92c0-.885-.017-2.025-1.234-2.025-1.235 0-1.424.965-1.424 1.96v3.778h-2.37V5.998H8.51V7.04h.031a2.494 2.494 0 0 1 2.246-1.233c2.403 0 2.846 1.58 2.846 3.637l-.001 4.188ZM3.56 4.954a1.376 1.376 0 1 1 0-2.751 1.376 1.376 0 0 1 0 2.751Zm1.185 8.68H2.372V5.997h2.373v7.635ZM14.815 0H1.18A1.168 1.168 0 0 0 0 1.154v13.691A1.168 1.168 0 0 0 1.18 16h13.635A1.17 1.17 0 0 0 16 14.845V1.153A1.17 1.17 0 0 0 14.815 0" />
    </SvgIcon>
);

export default LinkedInIcon;

import { injectAsyncReducer } from '@lingoda/core';
import { STORE_KEY } from './constants';
import reducers from './reducers';
import initSideEffects from './side-effects';

export * from './actions';
export * from './selectors';

injectAsyncReducer(STORE_KEY, reducers);
initSideEffects();

import { useMemo } from 'react';
import { useSelector } from '@lingoda/hooks';
import { productVariantByIdSelector } from '../selectors';

export const useProductVariantSelector = (productVariantId?: number) => {
    const productVariantById = useMemo(
        () => productVariantByIdSelector(() => productVariantId),
        [productVariantId],
    );

    return useSelector(productVariantById);
};

import { Translate, trans } from '@lingoda/i18n';
import { DialogConfirm, Typography, useUiEvent } from '@lingoda/ui';
import { actionResetQuiz } from '@lingoda/quiz-analytics';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm?: () => Promise<boolean>;
    isLoading?: boolean;
}

export const ResetDialog = ({ isOpen, isLoading, onClose, onConfirm }: Props) => {
    const addEvent = useUiEvent();

    const handleConfirm = async () => {
        void addEvent(actionResetQuiz());

        const success = await onConfirm?.();

        if (!success) {
            return;
        }

        onClose();
    };

    return (
        <DialogConfirm
            isOpen={isOpen}
            title={trans('reset-vocab-title', {}, 'quiz-page')}
            confirmLabel={trans('reset', {}, 'quiz-page')}
            confirmStatus={isLoading ? 'loading' : 'enabled'}
            dismissLabel={trans('btn-cancel', {}, 'public-common')}
            onConfirm={handleConfirm}
            onDismiss={onClose}
            onClose={onClose}
        >
            <Typography variant="body1">
                <Translate id="are-you-sure-reset" domain="quiz-page" />
            </Typography>
        </DialogConfirm>
    );
};

import { injectAsyncReducer } from '@lingoda/core';
import { STORE_KEY } from './constants';
import reducers from './reducers';
import initSideEffects from './side-effects';

export * from './actions';
export * from './components';
export * from './models';
export * from './selectors';
export * from './expanded';
export * from './hooks';
export * from './utils';
export * from './NextSubscriptionCancellationSurvey';

injectAsyncReducer(STORE_KEY, reducers);
initSideEffects();

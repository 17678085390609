import { createSelector } from 'reselect';
import { userSelector } from '@lingoda/auth';
import { STUDENT_INFO_STORE_KEY } from '../constants';
import type { State } from '../studentInfoReducers';

export const studentInfosSelector = (state: GlobalState) =>
    state[STUDENT_INFO_STORE_KEY] as State | undefined;

export const studentInfoSelector = createSelector(
    userSelector,
    studentInfosSelector,
    (user, infos) => (user?.studentInfoId && infos ? infos[user.studentInfoId] : undefined),
);

import { add, createDate, isBetween } from '@lingoda/dates';
import { useClassStateStudentQuery, useConfigurationQuery } from '@lingoda/graphql';

export const useCanImmediateCancel = (classId: string) => {
    const { data } = useClassStateStudentQuery({
        variables: { classId: classId },
        skip: !classId,
    });
    const { data: configData } = useConfigurationQuery();

    const createdAt = data?.class.appointment?.createdAt
        ? createDate(data.class.appointment.createdAt)
        : undefined;

    const refundableAfterBookingForMinutes =
        configData?.config.classConfig.refundableAfterBookingForMinutes;

    return (
        !!createdAt &&
        refundableAfterBookingForMinutes !== undefined &&
        isBetween(
            createdAt,
            add({ minutes: refundableAfterBookingForMinutes }, createdAt),
            createDate(),
        )
    );
};

import { injectAsyncReducer } from '@lingoda/core';
import { STORE_KEY } from './constants';
import reducers from './reducers';

export * from './actions';
export * from './models';
export * from './selectors';
export * from './hooks';
export * from './components';

injectAsyncReducer(STORE_KEY, reducers);

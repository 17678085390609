import { createSelector } from 'reselect';
import { userSelector } from '@lingoda/auth';
import { hasSubscriptionSelector } from '@lingoda/subscriptions';
import { studentCreditsSelector } from '@lingoda/students';
import { ClassType } from '@lingoda/graphql';

export const isLeadUserSelector = createSelector(
    userSelector,
    hasSubscriptionSelector,
    studentCreditsSelector,
    (user, hasSubscription, credits) =>
        !user?.isCorporateClient &&
        !hasSubscription &&
        credits[ClassType.Individual] + credits[ClassType.Group] < 1,
);

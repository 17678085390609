import { getSchemaHost, isAbsolute } from '@lingoda/urls';

export default (url?: string): string | null => {
    if (!url) {
        return null;
    }

    if (isAbsolute(url)) {
        return url;
    }

    if (!url.startsWith('/')) {
        url = `/${url}`;
    }

    return `${getSchemaHost()}${url}`;
};

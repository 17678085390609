import {
    Alert,
    type AlertColor,
    type AlertProps,
    colorsPalette,
    shadowElevationsFactory,
    styled,
} from '@lingoda/ui';
import { LottieIcon } from './LottieIcon';

export type ToastAlertProps = Omit<AlertProps, 'elevation' | 'variant'> & { iconKey?: string };

export const ToastAlert = (props: ToastAlertProps) => {
    const { iconKey, icon, ...rest } = props;

    return (
        <AlertStyled
            {...rest}
            variant="outlined"
            icon={iconKey ? <LottieIcon iconKey={iconKey} /> : icon}
        />
    );
};

const AlertStyled = styled(Alert)(({ severity = 'success' }) => ({
    width: 392,
    boxShadow: shadowElevationsFactory.level2(...shadowColors[severity]),
    margin: '0 auto',
}));

const shadowColors: Record<AlertColor, [string, string]> = {
    success: [colorsPalette.positiveAlpha[7], colorsPalette.positiveAlpha[12]],
    info: [colorsPalette.blackAlpha[8], colorsPalette.blackAlpha[12]],
    warning: [colorsPalette.infoAlpha[7], colorsPalette.infoAlpha[12]],
    error: [colorsPalette.negativeAlpha[8], colorsPalette.negativeAlpha[12]],
    promotion: [colorsPalette.purpleAlpha[8], colorsPalette.purpleAlpha[12]],
};

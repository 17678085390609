import { userSelector } from '@lingoda/auth';
import { isActionWithCurrentUserInfo } from '@lingoda/core';
import { studentIdSelector } from '@lingoda/students';
import type { Action, Middleware } from 'redux';

// This auguments action with current user info
const middleware: Middleware =
    (store) =>
    (next) =>
    <A extends Action>(action: A) => {
        if (isActionWithCurrentUserInfo(action)) {
            const state = store.getState();
            const user = userSelector(state);
            const studentId = studentIdSelector(state);

            if (studentId && user) {
                action.meta.currentUser = {
                    userId: user.id,
                    studentId,
                    studentInfoId: user.studentInfoId,
                };
            }
        }

        return next(action);
    };

export default middleware;

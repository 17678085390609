import MuiAvatar from '@mui/material/Avatar';
import { type Theme, colorsPalette, styled } from '../../theme';
import { type BreakPointsAdjustedProp, breakPointsAdjustedProp } from '../../utils';
import { Box } from '../Box';
import type { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import type { FC } from 'react';

export type AvatarSize = 18 | 22 | 24 | 28 | 32 | 40 | 48 | 56 | 64 | 80 | 88 | 156;

export interface AvatarProps extends Omit<MuiAvatarProps, 'variant' | 'imgProps'> {
    hoverEffect?: boolean;
    size?: BreakPointsAdjustedProp<AvatarSize>;
    borderVariant?: 'outlined' | 'stacked';
    color?: string;
    crossOrigin?: 'anonymous';
}

export const Avatar: FC<AvatarProps> = ({ children, src, color, crossOrigin, ...props }) => {
    const initial = typeof children === 'string' ? children.charAt(0).toUpperCase() : undefined;

    const bgColor = color || getAvatarBgColorByInitial(initial);

    const imgProps = {
        loading: 'lazy',
        crossOrigin,
    } as const;

    return (
        <StyledAvatar
            {...props}
            // Setting crossOrigin="Anonymous" leads to CORS error in Chromium browsers (Chrome, Edge)
            // See https://www.hacksoft.io/blog/handle-images-cors-error-in-chrome
            src={src && crossOrigin ? `${src}?not-from-cache-please` : src}
            color={bgColor}
            slotProps={{
                img: imgProps,
            }}
            // Avatar component does not pass slotProps.img to useLoaded() yet. So have to use imgProps still
            // See https://github.com/mui/material-ui/blob/next/packages/mui-material/src/Avatar/Avatar.js#L165
            imgProps={imgProps}
            variant="circular"
        >
            <Box display="flex">{initial || children}</Box>
        </StyledAvatar>
    );
};

const StyledAvatar = styled(MuiAvatar, {
    shouldForwardProp: (prop) =>
        !['size', 'hoverEffect', 'color', 'borderVariant'].includes(String(prop)),
})<AvatarProps>(({ size: sizeProp = 32, hoverEffect, color, borderVariant, theme, src }) => {
    const showBorder = borderVariant === 'stacked' || borderVariant === 'outlined';
    const borderColor = showBorder ? colorsPalette.whiteSolid[100] : colorsPalette.blackAlpha[8];
    const borderWidth = borderVariant === 'stacked' ? '2px' : '1px';
    const border = src || showBorder ? `${borderWidth} solid ${borderColor}` : undefined;
    const borderHoverColor = colorsPalette.blackAlpha[20];

    return {
        ...breakPointsAdjustedProp(theme, sizeProp, (size) => ({
            width: size,
            height: size,
            ...getTypographyByFontSize(theme, size),
        })),
        color: colorsPalette.whiteSolid[100],
        ...(hoverEffect
            ? {
                  '&:hover': {
                      cursor: 'pointer',
                      borderColor: borderHoverColor,
                  },
                  '&:hover::after': {
                      borderColor: borderHoverColor,
                  },
              }
            : {}),
        ...(showBorder
            ? { border }
            : {
                  '&::after': {
                      content: '""',
                      border,
                      borderRadius: '50%',
                      position: 'absolute',
                      top: 0,
                      width: '100%',
                      height: '100%',
                  },
              }),
        lineHeight: 1,
        contentVisibility: 'auto',

        '&.MuiAvatar-colorDefault': {
            backgroundColor: color,
        },

        '@media only percy': {
            backgroundColor: `${colorsPalette.darkblueSolid[100]} !important`,
        },
    };
});

// when there's no image colors need to be random, in order to make them consistent
// between renders the initial letter will be dictating which color should be applied
const getAvatarBgColorByInitial = (letter: string | undefined) => {
    if (!letter) {
        return 'none';
    }

    const lowerCaseLetter = letter.toLowerCase();

    if (lowerCaseLetter < 'h') {
        return colorsPalette.purpleSolid[100];
    }

    if (lowerCaseLetter < 'o') {
        return colorsPalette.pinkCustom.dark;
    }

    return colorsPalette.darkblueSolid[100];
};

const getTypographyByFontSize = ({ typography }: Theme, size: AvatarSize) =>
    ({
        18: typography.overline,
        22: typography.overline,
        24: typography.subtitle2,
        28: typography.subtitle2,
        32: typography.subtitle1,
        40: typography.h3,
        48: typography.h2,
        56: typography.h2,
        64: typography.h2,
        80: typography.h1,
        88: typography.h1,
        156: typography.h1,
    })[size];

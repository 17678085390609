import { getAllAppointmentsByStudent } from '@lingoda/api';
import { fetchMe, updateSettings } from '@lingoda/auth';
import { addCallback, addTrackerCallback } from '@lingoda/core';
import { assert } from '@lingoda/utils';
import { createDate } from '@lingoda/dates';
import { addLearningUnits, fetchLearningUnits } from '@lingoda/learning-units';
import { addLessons, updateLesson } from '@lingoda/lessons';
import { addModules } from '@lingoda/modules';
import { addStudents, studentIdSelector, studentSelector } from '@lingoda/students';
import { addTeachers } from '@lingoda/teachers';
import { isNoCreditCardTrialEnabled } from '@lingoda/feature-flags';
import { ClassType } from '@lingoda/graphql';
import { rateClassNotificationSelector, removeNotification } from '@lingoda/notifications';
import {
    addAppointments,
    addClasses,
    cleanStudentClassAppointment,
    closeRateClassNotification,
    fetchAppointments,
    fetchClassesDone,
    handleClassesResponse,
    removeAppointment,
    removeClass,
} from '../actions';
import { wrapClasses } from '../helpers';
import { classByIdSelector } from '../selectors';
import { composeAppointmentId } from '../utils/appointmentId';
import type { AppointmentStatus } from '../models';

export default () => {
    addCallback(cleanStudentClassAppointment, (action, store) => {
        const classId = action.payload;
        const studentId = studentIdSelector(store.getState());
        assert(studentId, 'Invalid argument');

        const appointmentId = composeAppointmentId(classId, studentId);
        store.dispatch(removeAppointment(appointmentId));

        const klass = classByIdSelector(() => classId)(store.getState(), undefined);

        if (klass?.lessonId) {
            store.dispatch(updateLesson(klass.lessonId));
        }

        if (klass?.type === ClassType.Individual) {
            store.dispatch(removeClass(classId));
        }

        if (isNoCreditCardTrialEnabled() && klass?.moduleId) {
            store.dispatch(fetchLearningUnits([klass.moduleId]));
        }

        store.dispatch(fetchMe());
    });

    addTrackerCallback(fetchAppointments, (action, store) => {
        const state = store.getState();
        let { studentId } = action.payload || {};

        if (!studentId) {
            const student = studentSelector(state);
            studentId = student && student.id;
        }

        if (!studentId) {
            return Promise.reject();
        }

        const promise = getAllAppointmentsByStudent({ ...action.payload, studentId });

        void promise.then(({ data, included }) => {
            if (included) {
                const { learningUnits, lessons, students, modules, teachers } = included;
                store.dispatch(addLearningUnits(learningUnits || []));
                store.dispatch(addLessons(lessons || []));
                store.dispatch(addModules(modules || []));
                store.dispatch(addStudents(students || []));
                if (teachers) {
                    store.dispatch(
                        addTeachers(
                            teachers.map((teacher) => ({
                                ...teacher,
                                createdAt: createDate(teacher.createdAt),
                            })),
                        ),
                    );
                }
            }

            const appointmentClasses = data.map((appointment) => appointment.class);
            const classes = wrapClasses(appointmentClasses);
            store.dispatch(addClasses(classes));

            const appointments = data.map(({ class: { id }, ...appointment }) => ({
                ...appointment,
                classId: id,
                status: appointment.status as AppointmentStatus,
                startDate: createDate(appointment.startDate),
                createdAt: createDate(appointment.createdAt),
                endDate: createDate(appointment.endDate),
            }));
            store.dispatch(addAppointments(appointments));
        });

        return promise;
    });

    addTrackerCallback(handleClassesResponse, (action, store) => {
        const dispatch = store.dispatch;
        const { classItems, wrappedClassItems, included } = action.payload;

        if (included) {
            const { learningUnits, lessons, modules, teachers, students } = included;
            dispatch(addLearningUnits(learningUnits || []));
            dispatch(addLessons(lessons || []));
            dispatch(addModules(modules || []));
            dispatch(addStudents(students || []));
            if (teachers) {
                dispatch(
                    addTeachers(
                        teachers.map((teacher) => ({
                            ...teacher,
                            createdAt: createDate(teacher.createdAt),
                        })),
                    ),
                );
            }
        }

        dispatch(addClasses(wrappedClassItems));

        dispatch(fetchClassesDone(classItems));

        return Promise.resolve(wrappedClassItems);
    });

    addCallback(updateSettings.success, (_, store) => store.dispatch(fetchAppointments()));

    addCallback(closeRateClassNotification, (action, store) => {
        const classId = action.payload;

        const notification = rateClassNotificationSelector(() => classId)(store.getState(), {});

        notification && store.dispatch(removeNotification(notification.id));
    });
};

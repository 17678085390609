import { useContext } from 'react';
import { MercureContext } from '../context';

export const useMercureEventSource = () => {
    const context = useContext(MercureContext);

    if (!context) {
        throw new Error('`useMercureEventSource` have to be used inside `MercureContext`');
    }

    return context;
};

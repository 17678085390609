import { Box, colorsPalette, styled } from '@lingoda/ui';
import { RouterLink, marketingWebPath } from '@lingoda/router';
import LingodaTextLogoV2 from '@lingoda/ui/illustrations/lingodaTextLogoV2.svg?component';

export const AuthHeader = () => {
    return (
        <HeaderStyled as="header">
            <ContainerStyled>
                <RouterLinkStyled onMouseDown={(e) => e.preventDefault()} to={marketingWebPath()}>
                    <LogoStyled />
                </RouterLinkStyled>
            </ContainerStyled>
        </HeaderStyled>
    );
};

const HeaderStyled = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    background: colorsPalette.whiteSolid[100],
    display: 'grid',
    height: '64px',
    [theme.breakpoints.down(991)]: {
        justifyContent: 'center',
    },
}));

const ContainerStyled = styled(Box)(({ theme }) => ({
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: 'calc(1300px + 64px)',
    padding: '0 32px',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down(1439)]: {
        maxWidth: 'calc(1100px + 64px)',
    },
    [theme.breakpoints.down(1199)]: {
        maxWidth: 'calc(930px + 64px)',
    },
    [theme.breakpoints.down(1023)]: {
        maxWidth: 'calc(930px + 48px)',
        padding: '0 24px',
    },
    [theme.breakpoints.down(991)]: {
        maxWidth: 'calc(700px + 48px)',
    },
    [theme.breakpoints.down(767)]: {
        maxWidth: 'calc(540px + 48px)',
    },
}));

const RouterLinkStyled = styled(RouterLink)(() => ({
    display: 'inline-flex',
    marginTop: '5px',
    [`&:focus`]: {
        outline: '2px solid ' + colorsPalette.primarySolid['100'],
        outlineOffset: '4px',
    },
}));

const LogoStyled = styled(LingodaTextLogoV2)(() => ({
    height: '34px',
    width: '150px',
}));

import { Stack, Typography, colorsPalette } from '@lingoda/ui';
import { useParams } from '@lingoda/router';
import { trans, transChoice } from '@lingoda/i18n';
import { useBasicActivityChallengeDataQuery } from '@lingoda/graphql';
import { getMaxPrizeValue } from '../utils';
import { ChallengeProgressBar } from './ChallengeProgressBar';
import { ChallengesPaper } from './ChallengesPaper';

export const ChallengeProgress = () => {
    const { challengeName } = useParams<{ challengeName: string }>();
    const { data } = useBasicActivityChallengeDataQuery({
        variables: { challengeName },
    });
    if (!data) {
        return null;
    }

    const { activityChallenge: challenge } = data;
    const { prizes, currentParticipant } = challenge;
    const completedActivities = currentParticipant?.completedActivities ?? 0;
    const maxPrizeValue = getMaxPrizeValue(prizes);

    return (
        <ChallengesPaper>
            <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center">
                <Typography variant="h3">
                    {transChoice(
                        'complete-learning-activities',
                        maxPrizeValue,
                        { activities: maxPrizeValue },
                        'student-challenges',
                    )}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h3" color="primary">
                        {completedActivities}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="primary"
                        fontWeight={400}
                        htmlColor={colorsPalette.blackAlpha[60]}
                        whiteSpace="nowrap"
                    >
                        / {maxPrizeValue}{' '}
                        {trans('learning-activities-abbreviation', {}, 'student-challenges')}
                    </Typography>
                </Stack>
            </Stack>
            <ChallengeProgressBar
                size={'extraLarge'}
                challengeName={challengeName}
                prizes={prizes}
                completedActivities={completedActivities}
            />
        </ChallengesPaper>
    );
};

import { memo } from 'react';
import FormControl from '@lingoda/ui/fields/FormControl';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import type { TextFieldProps } from '@lingoda/ui/fields/TextField';
import type { AutocompleteProps } from '@lingoda/ui';
import { Autocomplete } from '@lingoda/ui';
import { ErrorMessage, Field } from '../../utils';
import type { FieldProps } from '../../utils';

type FormikAutocomplete<T> = AutocompleteProps<T>;

export interface AutocompleteFieldProps<T>
    extends Omit<FormikAutocomplete<T>, 'renderInput' | 'defaultValue'>,
        Pick<TextFieldProps, 'name' | 'label'> {
    fullWidth?: boolean;
    required?: boolean;
}

const FormikAutocomplete = <T,>({ fullWidth, required, ...props }: AutocompleteFieldProps<T>) => (
    <Field {...props}>
        {({ field, form: { errors, touched, ...form } }: FieldProps) => {
            const handleChange: FormikAutocomplete<T>['onChange'] = (_, value) =>
                form.setFieldValue(field.name, value);

            return (
                <FormControl
                    error={!!touched[field.name] && !!errors[field.name]}
                    fullWidth={fullWidth}
                    required={required}
                >
                    <Autocomplete {...props} {...field} onChange={handleChange} />
                    <ErrorMessage name={field.name}>
                        {(message) => (
                            <FormHelperText>
                                {typeof message === 'object'
                                    ? Object.values<string>(message).map((msg, index) => (
                                          <p key={`${index}`}>{msg}</p>
                                      ))
                                    : message}
                            </FormHelperText>
                        )}
                    </ErrorMessage>
                </FormControl>
            );
        }}
    </Field>
);

export const AutocompleteField = memo(FormikAutocomplete) as typeof FormikAutocomplete;

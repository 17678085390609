import { createDate, isAfter, isBefore } from '@lingoda/dates';

export type ChallengeStatus = 'upcoming' | 'active' | 'ended';

export const getChallengeStatus = (startDate: string, endDate: string): ChallengeStatus => {
    const now = createDate();
    const start = createDate(startDate);
    const end = createDate(endDate);

    if (isBefore(now, start)) {
        return 'upcoming';
    }

    if (isAfter(now, end)) {
        return 'ended';
    }

    return 'active';
};

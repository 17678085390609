import { type ChecklistItem as ChecklistItemType } from '@lingoda/graphql';
import { Box, Typography, colorsPalette, gradients, styled } from '@lingoda/ui';
import { Checkmark } from '@lingoda/ui/icons/lingodaIcons';

interface Props extends ChecklistItemType {
    isActive: boolean;
    value: number;
}

export const ChecklistItem = ({ title, description, isActive, isCompleted, value }: Props) => {
    return (
        <Box display="flex">
            <StatusContainer isActive={isActive} isCompleted={isCompleted}>
                {isCompleted ? (
                    <Checkmark htmlColor={colorsPalette.whiteSolid[100]} fontSize="small" />
                ) : (
                    <Typography
                        variant="body3"
                        htmlColor={
                            isActive ? colorsPalette.blackAlpha[70] : colorsPalette.blackAlpha[20]
                        }
                    >
                        {value}
                    </Typography>
                )}
            </StatusContainer>
            <Box sx={{ flex: '1 1 auto', marginLeft: 1 }}>
                <Typography
                    variant={isActive ? 'subtitle2' : 'body2'}
                    htmlColor={
                        isActive || isCompleted
                            ? colorsPalette.blackSolid[100]
                            : colorsPalette.blackAlpha[20]
                    }
                >
                    {title}
                </Typography>
                {isActive && (
                    <Box sx={{ marginTop: 0.5, color: colorsPalette.blackAlpha[70] }}>
                        <Typography fontStyle="italic">{description}</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const StatusContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'isCompleted',
})<{
    isActive: boolean;
    isCompleted: boolean;
}>(({ isActive, isCompleted }) => ({
    alignItems: 'center',
    background: isCompleted
        ? gradients.darkPurpleToPrimaryDark
        : isActive
          ? colorsPalette.blackSolid[8]
          : colorsPalette.blackAlpha[5],
    borderRadius: 10,
    display: 'flex',
    flexShrink: 0,
    height: 20,
    justifyContent: 'center',
    width: 20,
}));

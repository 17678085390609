import {
    AppleIcon,
    FacebookIcon,
    GoogleIcon,
    LinkedInIcon,
    TwitterIcon,
    WhatsAppIconOutlined,
} from './lingodaIcons';
import type { SocialVendors } from '../theme/models';
import type { SvgIconProps } from '../layout/SvgIcon';
import type { ComponentType, FC } from 'react';

interface Props extends SvgIconProps {
    vendor: SocialVendors;
}

const iconMapping: Record<SocialVendors, ComponentType<SvgIconProps>> = {
    google: GoogleIcon,
    apple: AppleIcon,
    facebook: FacebookIcon,
    twitter: TwitterIcon,
    linkedin: LinkedInIcon,
    whatsapp: WhatsAppIconOutlined,
};

const SocialIcon: FC<Props> = ({ vendor, ...props }) => {
    const Icon = iconMapping[vendor];

    return <Icon {...props} />;
};

export default SocialIcon;

import { Alert, Link, Typography, colorsPalette, useResolution } from '@lingoda/ui';
import { CalendarTodayIcon24 } from '@lingoda/ui/icons/CalendarTodayIcon';
import { Translate, trans } from '@lingoda/i18n';
import { cancellationPolicyPath } from '@lingoda/router';

export const ClassesInPauseAlert = () => {
    const lessThanSmall = useResolution('phone');

    return (
        <Alert
            variant="outlined"
            severity="info"
            icon={
                !lessThanSmall && <CalendarTodayIcon24 htmlColor={colorsPalette.blackAlpha[70]} />
            }
            title={trans('rollover-pause-you-have-bookings', {}, 'student-common')}
            sx={{ p: 2, mt: 3 }}
        >
            <Typography variant="body2" htmlColor={colorsPalette.blackAlpha[70]}>
                <Translate
                    id="rollover-pause-we-detected"
                    params={{
                        policyLink: (
                            <Link
                                key="policy-link"
                                color="inherit"
                                to={cancellationPolicyPath()}
                                target="_blank"
                            >
                                {trans(
                                    'rollover-pause-according-to-cancellation-policy',
                                    {},
                                    'student-common',
                                )}
                            </Link>
                        ),
                    }}
                    domain="student-common"
                />
            </Typography>
        </Alert>
    );
};

import { styled } from '../../theme';

export interface ImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    circle?: boolean;
    fullWidth?: boolean;
    className?: string;
    maxWidth?: string;
}

export const Img = styled('img', {
    shouldForwardProp: (name: string) => !['circle', 'fullWidth', 'maxWidth'].includes(name),
})<ImgProps>(({ maxWidth, fullWidth, circle }) => ({
    maxWidth: maxWidth || '100%',
    height: 'auto',
    position: 'relative',
    verticalAlign: 'middle',
    ...(fullWidth && { minWidth: '100%' }),
    ...(circle && { borderRadius: '50%' }),
}));

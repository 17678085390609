import { trans } from '@lingoda/i18n';
import { useParams } from '@lingoda/router';
import { Stack, Typography } from '@lingoda/ui';
import { useBasicActivityChallengeDataSuspenseQuery } from '@lingoda/graphql';

export const ChallengeDescription = () => {
    const { challengeName } = useParams<{ challengeName: string }>();
    const {
        data: { activityChallenge: challenge },
    } = useBasicActivityChallengeDataSuspenseQuery({
        variables: { challengeName },
    });

    const overview = [challenge.overviewFirstParagraph, challenge.overviewSecondParagraph];

    return (
        <Stack spacing={2}>
            <Typography variant="h2">{trans('overview', {}, 'student-challenges')}</Typography>
            {overview.map((text) => (
                <Typography variant="body1" key={text}>
                    {text}
                </Typography>
            ))}
        </Stack>
    );
};

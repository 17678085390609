import cx from 'clsx';
import camelCase from 'lodash/camelCase';
// Lodash capitalize changes everything else to lowercase
import { capitalize } from '@lingoda/utils';
import css from './index.css';

interface Props {
    icon: string;
    color?: string;
}

const LingodaIcon = ({ icon, color }: Props) => (
    <i
        className={cx(
            css.iconLingoda,
            css[`iconLingoda${capitalize(camelCase(icon))}` as keyof typeof css],
        )}
        style={color ? { color } : {}}
    />
);

export default LingodaIcon;

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const CloseIcon8: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 8 8" fontSize="inherit" sx={{ fontSize: 8 }} {...props}>
        <path d="M.47.47a.75.75 0 0 1 1.06 0L4 2.94 6.47.47a.75.75 0 0 1 1.06 1.06L5.06 4l2.47 2.47a.75.75 0 0 1-1.06 1.06L4 5.06 1.53 7.53A.75.75 0 0 1 .47 6.47L2.94 4 .47 1.53a.75.75 0 0 1 0-1.06Z" />
    </SvgIcon>
);

export const CloseIcon12: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 13 12" fill="none" {...props}>
        <path
            d="M3.10225 2.54225C3.32192 2.32258 3.67808 2.32258 3.89775 2.54225L6.5 5.14451L9.10225 2.54225C9.32192 2.32258 9.67808 2.32258 9.89775 2.54225C10.1174 2.76192 10.1174 3.11808 9.89775 3.33775L7.2955 5.94L9.89775 8.54225C10.1174 8.76192 10.1174 9.11808 9.89775 9.33775C9.67808 9.55742 9.32192 9.55742 9.10225 9.33775L6.5 6.7355L3.89775 9.33775C3.67808 9.55742 3.32192 9.55742 3.10225 9.33775C2.88258 9.11808 2.88258 8.76192 3.10225 8.54225L5.7045 5.94L3.10225 3.33775C2.88258 3.11808 2.88258 2.76192 3.10225 2.54225Z"
            fill="#031638"
        />
    </SvgIcon>
);

export const CloseIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
        <path d="M3.46967 3.38965C3.76256 3.09676 4.23744 3.09676 4.53033 3.38965L8 6.85932L11.4697 3.38965C11.7626 3.09676 12.2374 3.09676 12.5303 3.38965C12.8232 3.68255 12.8232 4.15742 12.5303 4.45031L9.06066 7.91998L12.5303 11.3897C12.8232 11.6825 12.8232 12.1574 12.5303 12.4503C12.2374 12.7432 11.7626 12.7432 11.4697 12.4503L8 8.98064L4.53033 12.4503C4.23744 12.7432 3.76256 12.7432 3.46967 12.4503C3.17678 12.1574 3.17678 11.6825 3.46967 11.3897L6.93934 7.91998L3.46967 4.45031C3.17678 4.15742 3.17678 3.68255 3.46967 3.38965Z" />
    </SvgIcon>
);

export const CloseIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" />
    </SvgIcon>
);

import { useCallback } from 'react';
import { UserDisplayedWidgetClassEnum, useMarkWidgetAsDisplayedMutation } from '@lingoda/graphql';
import { updatePreferences } from '@lingoda/students';
import { useRequestTracker } from '@lingoda/core';
import { useDispatch } from '@lingoda/hooks';
import { showApolloErrorToast } from '@lingoda/toasts';

export const useMarkConnectCalendarDialogAsDismissed = () => {
    const dispatch = useDispatch();
    const trackRequest = useRequestTracker();

    const [markAsDisplayedMutation] = useMarkWidgetAsDisplayedMutation({
        onError: showApolloErrorToast,
    });

    const onMarkConnectCalendarDialogAsDismissed = useCallback(async () => {
        // we want to dismiss the widget for the user, so we call
        // 1. updatePreferences
        await trackRequest(
            dispatch(updatePreferences({ dismissedWidgets: { calendarReminderWidget: true } })),
        );
        // however, we want to show it again after given period of time, so we have to call
        // 2. markAsDisplayedMutation
        await markAsDisplayedMutation({
            variables: { widgetClass: UserDisplayedWidgetClassEnum.CalendarReminderWidget },
        });
        // that will trigger BE mechanisms to show the widget again by removing it from dismissedWidgets after given period of time
    }, [dispatch, markAsDisplayedMutation, trackRequest]);

    return {
        onMarkConnectCalendarDialogAsDismissed,
    };
};

import { trans } from '@lingoda/i18n';
import { Typography, type TypographyProps } from '@lingoda/ui';

interface WordClassProps extends TypographyProps {
    value: string;
}

export const WordClass = ({ value, ...props }: WordClassProps) => {
    return (
        <Typography
            component="span"
            variant="subtitle2"
            fontStyle="italic"
            display="inline"
            {...props}
        >
            {trans(/* @ignore */ `word-class-type-${value}`, {}, 'student-common')}
        </Typography>
    );
};

import { memo } from 'react';
import { BoxPerf, Paper, type PaperProps } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import type { ClassId } from '@lingoda/classes';
import { RouterLink } from '@lingoda/router';
import type { CommonProps } from '../../types/props';

interface Props extends Pick<CommonProps, 'linkTo' | 'openInNewTab'> {
    outlined?: boolean;
    classes?: { root: string };
    children?: React.ReactNode;
    classId?: ClassId;
    ribbon?: string;
    onMouseEnter?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
}

const PaperStyled = styled(Paper)<PaperProps & { component?: React.ElementType }>(({ theme }) => ({
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    minHeight: 255,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    textDecoration: 'inherit',
    '&.MuiPaper-outlined': {
        border: `1px solid ${colorsPalette.blackAlpha[20]}`,

        '&:hover': {
            backgroundColor: colorsPalette.primarySolid[12],
            borderColor: colorsPalette.primaryAlpha[20],

            '& .MuiAvatar-circular': {
                borderColor: colorsPalette.primarySolid[12],
            },
        },
    },
    '&.MuiPaper-elevation1': {
        '&:hover': {
            boxShadow: theme.shadows[3],
        },
    },
}));

const ClassTileComponent = ({
    onMouseEnter,
    onMouseLeave,
    children,
    outlined = true,
    linkTo,
    openInNewTab,
}: Props) => {
    return (
        <>
            <PaperStyled
                tabIndex={0}
                {...(outlined ? { variant: 'outlined' } : { elevation: 1 })}
                component="section"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...(linkTo && {
                    component: RouterLink,
                    to: linkTo,
                    target: openInNewTab ? '_blank' : '_self',
                })}
                data-cy="class-item class-tile"
            >
                <BoxPerf className="flex-direction-column flex-1">{children}</BoxPerf>
            </PaperStyled>
        </>
    );
};

export const ClassTile = memo(ClassTileComponent);

import type { Reducer } from '@lingoda/core';
import { handleActions } from '@lingoda/core';
import type { Date } from '@lingoda/dates';
import { createDate } from '@lingoda/dates';
import { setTime } from '../actions';

export type State = Date;

type SetTimeAction = ReturnType<typeof setTime>;

export default handleActions(
    {
        [`${setTime}`]: (_state: State, { payload }: SetTimeAction) => payload,
    },
    createDate(),
) as Reducer<State>;

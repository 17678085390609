import { type AlertProps, AlertTitle, Box, IconButton, colorsPalette } from '@lingoda/ui';
import { CloseIcon16 } from '@lingoda/ui/icons/Close';
import { ConfettiIcon24 } from '@lingoda/ui/icons/ConfettiIcon';

interface Props {
    onClose?: AlertProps['onClose'];
    title: string;
}

export const Header = ({ onClose, title }: Props) => {
    return (
        <AlertTitle>
            <Box display="flex" alignItems="center">
                <Box sx={{ marginRight: 1.5, display: 'inline-flex' }}>
                    <ConfettiIcon24 />
                </Box>
                <Box flex="1 1 auto">{title}</Box>
                {onClose && (
                    <IconButton
                        sx={{
                            color: colorsPalette.blackAlpha[70],
                            padding: 0.5,
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon16 fontSize="small" />
                    </IconButton>
                )}
            </Box>
        </AlertTitle>
    );
};

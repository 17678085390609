import { styled } from '../theme';
import { Divider } from './Divider';

interface HairlineProps {
    margin?: 'sm' | 'md' | 'lg';
}

export const Hairline = styled(Divider, {
    shouldForwardProp: (prop) => prop !== 'margin',
})<HairlineProps>(({ theme, margin }) => ({
    ...(margin === 'sm' && {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }),
    ...(margin === 'md' && {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    }),
    ...(margin === 'lg' && {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    }),
}));

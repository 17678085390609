import { useCallback, useEffect } from 'react';
import { enqueueSnackbar, useResolution } from '@lingoda/ui';
import { getHighestPriority } from '../utils';
import { hidePopup, markPopupAsDisplayed, usePopups } from '../popups';
import { type Popup } from '../types';

export const PopupSystem = () => {
    const { popups } = usePopups();
    const isMobile = useResolution('phone');
    const animationDurationMs = 500;

    const displaySnackbarPopup = useCallback(
        (popupToDisplay: Popup) => {
            markPopupAsDisplayed(popupToDisplay);

            // NOTE: call enqueueSnackbar() with 2 args (message, options)
            // to enable proper type mapping of custom props based on 'variant' value
            enqueueSnackbar('randomMessage', {
                variant: popupToDisplay.variant,
                // Remove the popup from state after animation is finished, to postpone displaying of next notification
                onExited: () => hidePopup(popupToDisplay),
                persist: true,
                transitionDuration: { enter: animationDurationMs, exit: animationDurationMs },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: isMobile ? 'center' : 'left',
                },
            });
        },
        [isMobile],
    );

    useEffect(() => {
        const displayedPopupsAmount = popups.filter((item) => item.status === 'displayed').length;
        if (displayedPopupsAmount >= 1) {
            return;
        }

        const popupToDisplay = getHighestPriority(popups);
        if (!popupToDisplay) {
            return;
        }

        displaySnackbarPopup(popupToDisplay);

        // NOTE: don't react to changes inside popup objects, only on their amount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displaySnackbarPopup, popups.length]);

    return null;
};

import { type MouseEvent } from 'react';
import { trans } from '@lingoda/i18n';
import { Box, GradientButton, ProgressBar, Typography, keyframes } from '@lingoda/ui';
import { useAnimatedProgress } from './useAnimatedProgress';

interface Props {
    currentProgress: number;
    isClaimable: boolean;
    isClaiming: boolean;
    label: string;
    onClaiming: (event: MouseEvent) => void;
    value: number;
}

export const ContentProgress = ({
    currentProgress,
    isClaimable,
    isClaiming,
    label,
    onClaiming,
    value,
}: Props) => {
    const progress = useAnimatedProgress(currentProgress);

    return (
        <Box width="100%" display="flex" flexDirection="column">
            <Box display="flex">
                <Box flex="auto">
                    <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {label}
                    </Typography>
                </Box>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {currentProgress}/{value}
                </Typography>
            </Box>
            <ProgressBar value={(progress / value) * 100} size="medium" sx={{ marginTop: 1 }} />
            {isClaimable && (
                <Box sx={{ animation: animationLinearGradientFadeIn }}>
                    <GradientButton
                        size="small"
                        fullWidth
                        onClick={onClaiming}
                        disabled={isClaiming}
                        loading={isClaiming}
                        sx={{
                            maxWidth: 240,
                            marginTop: 2,
                        }}
                    >
                        {trans('btn-claim-reward', {}, 'student-achievements')}
                    </GradientButton>
                </Box>
            )}
        </Box>
    );
};

const fadeIn = keyframes({ from: { opacity: 0 }, to: { opacity: 1 } });
const animationLinearGradientFadeIn = `${fadeIn} 1.5s linear`;

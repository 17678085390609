import { memo } from 'react';
import { trans } from '@lingoda/i18n';
import { useTimezone } from '@lingoda/timezone';
import { AutocompleteField } from '../../formik/AutocompleteField/AutocompleteField';
import type { AutocompleteFieldProps } from '../../formik/AutocompleteField/AutocompleteField';

type TimezoneProps = Pick<AutocompleteFieldProps<string>, 'name' | 'label' | 'fullWidth'>;

const Timezone = (props: TimezoneProps) => {
    const { loading, timezones, timezoneLabels } = useTimezone();

    return (
        <AutocompleteField
            label={trans('form-label-timezone', {}, 'public-common')}
            name="timezone"
            fullWidth
            loading={loading}
            options={timezones?.map(({ tz }) => tz) || []}
            getOptionLabel={(e) => timezoneLabels?.[e] || ''}
            {...props}
        />
    );
};

export default memo(Timezone);

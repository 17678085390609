import { StudentStatus } from '@lingoda/graphql';

export const isLessonSkipped = ({ studentStatus }: { studentStatus: StudentStatus }) =>
    studentStatus === StudentStatus.Skip;

export const isLessonNotTaken = ({ studentStatus }: { studentStatus: StudentStatus }) =>
    studentStatus === StudentStatus.NotTaken;

// @TODO merge with `isLessonProgressed` from @lingoda/lessons when we move all lessons to graphql
export const isLessonCompleted = ({ studentStatus }: { studentStatus: StudentStatus }) =>
    [
        StudentStatus.Completed,
        StudentStatus.Retake,
        StudentStatus.Booked,
        StudentStatus.Skip,
        StudentStatus.Pending,
    ].includes(studentStatus);

import { forwardRef } from 'react';
import { createSelector } from 'reselect';
import { userIsSprintRunnerSelector } from '@lingoda/auth';
import { useSelector } from '@lingoda/hooks';
import { isNoCreditCardTrialEnabled } from '@lingoda/feature-flags';
import { Box } from '@lingoda/ui';
import {
    currentSubscriptionSelector,
    isSubscriptionWillExpireSelector,
    useShowRolloverTrialBanner,
} from '@lingoda/subscriptions';
import { RolloverTrialNotificationBar } from './RolloverTrialNotificationBar';
import { SprintNotificationBar } from './SprintNotificationBar';
import { AccountCloseNotificationBar } from './AccountCloseNotificationBar';
import { TrialDaysLeftNotificationBar } from './TrialDaysLeftNotificationBar';

const subscriptionExpireSelector = createSelector(
    currentSubscriptionSelector,
    (subscription) => subscription && subscription.expiresAt,
);

export const NotificationBars = forwardRef<HTMLDivElement>((_, ref) => {
    const expiresAt = useSelector(subscriptionExpireSelector);

    const isSubscriptionWillExpire = useSelector(isSubscriptionWillExpireSelector);
    const isSprintRunner = useSelector(userIsSprintRunnerSelector);
    const [showRollover, handleRollover] = useShowRolloverTrialBanner();
    const noCreditCardTrialEnabled = isNoCreditCardTrialEnabled();

    return (
        <Box ref={ref} data-cy="Notification bars">
            {isSubscriptionWillExpire && expiresAt && (
                <AccountCloseNotificationBar expiresAt={expiresAt} />
            )}
            {isSprintRunner && <SprintNotificationBar />}
            {showRollover && <RolloverTrialNotificationBar onClose={handleRollover} />}
            {noCreditCardTrialEnabled && <TrialDaysLeftNotificationBar />}
        </Box>
    );
});

NotificationBars.displayName = 'NotificationBars';

import type { ComponentType } from 'react';

const getDisplayName = <T extends Record<string, unknown>>(Component: ComponentType<T>) => {
    if (typeof Component === 'string') {
        return Component;
    }

    if (!Component) {
        return undefined;
    }

    return Component.displayName || Component.name || 'Component';
};

export const wrapDisplayName = <T extends Record<string, unknown>>(
    BaseComponent: ComponentType<T>,
    hocName: string,
) => `${hocName}(${getDisplayName(BaseComponent)})`;

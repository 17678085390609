import { colorsPalette, muiFocusVisibleOutlineMixin, styled } from '../theme';
import { Button, type ButtonProps } from './Button';
import type { PropsWithChildren } from 'react';

interface OptionButtonProps extends InheritedButtonProps {
    selected?: boolean;
}

type InheritedButtonProps = Pick<ButtonProps, 'onClick' | 'labelFullWidth' | 'sx' | 'disabled'>;

export const OptionButton = (props: PropsWithChildren<OptionButtonProps>) => (
    <ButtonStyled disableRipple {...props} />
);

const ButtonStyled = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'selected',
})<OptionButtonProps>(({ theme, selected }) => ({
    border: `${theme.borderWidth}px solid ${colorsPalette.blackAlpha[16]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    color: 'inherit',
    ...muiFocusVisibleOutlineMixin(theme),
    ':hover': {
        color: 'inherit',
        borderColor: colorsPalette.primaryAlpha[20],
        backgroundColor: colorsPalette.primaryAlpha[8],
        [theme.breakpoints.down('sm')]: {
            borderColor: colorsPalette.blackAlpha[16],
            backgroundColor: 'inherit',
        },
    },
    ...(selected && {
        borderColor: colorsPalette.primarySolid[100],
        backgroundColor: colorsPalette.primaryAlpha[16],

        ':hover': {
            borderColor: colorsPalette.primarySolid[100],
            backgroundColor: colorsPalette.primaryAlpha[8],
            [theme.breakpoints.down('sm')]: {
                backgroundColor: colorsPalette.primaryAlpha[16],
            },
        },
    }),
}));

import { Children, type PropsWithChildren, isValidElement } from 'react';

interface ViewSwitchProps {
    value?: string | number;
}

export const ViewSwitch = ({ children, value }: PropsWithChildren<ViewSwitchProps>) => {
    if (!children) {
        return null;
    }

    const childrenArray = Children.toArray(children);

    let node = childrenArray.find((item) => isValidElement(item) && item.props.value === value);

    if (!node) {
        node = childrenArray.find((item) => isValidElement(item) && item.type === ViewDefault.name);
    }

    return <>{node}</>;
};

interface ViewCaseProps {
    value: string | number;
}

export const ViewCase = (props: PropsWithChildren<ViewCaseProps>) => {
    return <>{props.children}</>;
};

export const ViewDefault = (props: PropsWithChildren<unknown>) => {
    return <>{props.children}</>;
};

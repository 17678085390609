import { injectAsyncReducer } from '@lingoda/core';
import { STUDENT_INFO_STORE_KEY, STUDENT_STORE_KEY } from './constants';
import reducers from './reducers';
import initSideEffects from './side-effects';
import studentInfoReducers from './studentInfoReducers';

export * from './actions';
export * from './constants';
export * from './hooks';
export * from './models';
export { default as reducers } from './reducers';
export type { State as StudentsState } from './reducers';
export * from './selectors';
export * from './utils';

injectAsyncReducer(STUDENT_STORE_KEY, reducers);
injectAsyncReducer(STUDENT_INFO_STORE_KEY, studentInfoReducers);

initSideEffects();

type AuthHeaders = () => Promise<GenericObject<string> | void>;

let authCheck: AuthHeaders;

export const setAuthHeaders = (func: AuthHeaders) => (authCheck = func);

export const getAuthHeaders = () => {
    if (authCheck) {
        return authCheck();
    }

    return Promise.resolve({});
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const FlagItalianIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_2043_1235"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
        >
            <circle cx="8" cy="8" r="8" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_2043_1235)">
            <rect x="16" width="16" height="5.33333" transform="rotate(90 16 0)" fill="#FF3333" />
            <rect
                x="10.6667"
                width="16"
                height="5.33333"
                transform="rotate(90 10.6667 0)"
                fill="#F0F0F4"
            />
            <rect
                x="5.33334"
                width="16"
                height="5.33333"
                transform="rotate(90 5.33334 0)"
                fill="#019364"
            />
        </g>
    </SvgIcon>
);

export const FlagItalianIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_2043_979"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                fill="#C4C4C4"
            />
        </mask>
        <g mask="url(#mask0_2043_979)">
            <path d="M24 0L24 24L16 24L16 -3.49691e-07L24 0Z" fill="#FF3333" />
            <path d="M16 0L16 24L8 24L8 -3.49691e-07L16 0Z" fill="#F3F4F5" />
            <path d="M8 0L8 24L-1.04907e-06 24L0 -3.49691e-07L8 0Z" fill="#019364" />
        </g>
    </SvgIcon>
);

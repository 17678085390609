import { type FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useToggleState } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { Button, Divider, Grid, Typography } from '@lingoda/ui';
import { useRequestTracker } from '@lingoda/core';
import {
    paymentMethodDefaultSelector,
    paymentMethodsNewSelector,
    paymentMethodsStoredSelector,
} from '../../selectors';
import PaymentMethods from '../PaymentMethods';
import { fetchPaymentMethods } from '../../actions';

export interface Props {
    onChange?: (value: string) => void;
    value?: string;
    currency: string;
    variant?: 'all' | 'new' | 'stored';
}

const PaymentMethodsRelational = ({ onChange, value, currency, variant }: Props) => {
    const { isSuccess: isPaymentMethodsLoaded } = usePaymentMethodsRequestTrack(currency);
    const paymentMethodDefault = useSelector(paymentMethodDefaultSelector);
    const paymentMethodsNew = useSelector(paymentMethodsNewSelector);
    const paymentMethodsStored = useSelector(paymentMethodsStoredSelector);
    const [addPaymentMethod, setAddPaymentMethod, unsetAddPaymentMethod] = useToggleState(false);
    const paymentMethodsStoredWithVariant = useMemo(
        () => (variant === 'new' ? [] : paymentMethodsStored),
        [variant, paymentMethodsStored],
    );
    const paymentMethodDefaultWithVariant = useMemo(
        () => (variant === 'new' ? undefined : paymentMethodDefault?.id),
        [variant, paymentMethodDefault],
    );

    // Setup the default payment method
    const shouldReinitialize = isPaymentMethodsLoaded && !value && paymentMethodDefaultWithVariant;
    useEffect(() => {
        if (shouldReinitialize) {
            onChange?.(paymentMethodDefaultWithVariant);
        }
    }, [onChange, paymentMethodDefaultWithVariant, shouldReinitialize]);

    // Stored payment method was choosen
    useEffect(() => {
        if (paymentMethodsNew.every((paymentMethod) => paymentMethod.id !== value)) {
            unsetAddPaymentMethod();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const addNewPaymentMethod = useCallback(() => {
        const [defaultPayment] = paymentMethodsNew;
        if (onChange) {
            onChange(defaultPayment.id);
        }
        setAddPaymentMethod();
    }, [paymentMethodsNew, onChange, setAddPaymentMethod]);

    const handleChange = useCallback(
        (_e: FormEvent<HTMLElement>, newValue: string): void => {
            if (onChange && newValue !== value) {
                onChange(newValue);
            }
        },
        [onChange, value],
    );

    const paymentMethodsLoaded =
        !!paymentMethodsNew.length || !!paymentMethodsStoredWithVariant.length;
    const showPaymentMethodsStored =
        paymentMethodsLoaded && !!paymentMethodsStoredWithVariant.length;
    const showPaymentMethodsNew =
        paymentMethodsLoaded && (!paymentMethodsStoredWithVariant.length || addPaymentMethod);

    return (
        <Grid container spacing={2}>
            {variant !== 'new' && (
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {trans('store-payment-method-subheader', {}, 'public-common')}
                    </Typography>
                </Grid>
            )}
            {showPaymentMethodsStored && (
                <Grid item xs={12}>
                    <PaymentMethods
                        onChange={handleChange}
                        value={value}
                        showMethods="stored"
                        currency={currency}
                    />
                </Grid>
            )}
            {showPaymentMethodsStored && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
            {showPaymentMethodsNew ? (
                <Grid item xs={12}>
                    <PaymentMethods
                        onChange={handleChange}
                        value={value}
                        showMethods="new"
                        currency={currency}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Button onClick={addNewPaymentMethod} color="primary" variant="outlined">
                        {trans('add-payment-method', {}, 'public-courses-summary')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export const usePaymentMethodsRequestTrack = (currency: string) => {
    const dispatch = useDispatch();
    const trackRequest = useRequestTracker();
    const [isLoaded, setLoaded] = useToggleState(false);

    useEffect(() => {
        void trackRequest(dispatch(fetchPaymentMethods(currency))).then(() => setLoaded());
    }, [currency, dispatch, trackRequest, setLoaded]);

    return { isSuccess: isLoaded };
};

export default PaymentMethodsRelational;

import merge from 'lodash/merge';
import type { cancelSubscription as cancelSubscriptionApi } from '@lingoda/api';
import { combineActions, handleActionsImmutably } from '@lingoda/core';
import { createDate } from '@lingoda/dates';
import {
    addSubscriptions,
    confirmCancelSubscription,
    confirmDeleteNextSubscription,
    setSubscriptions,
    uncancelSubscription,
    unpauseSubscription,
} from './actions';
import { SubscriptionFactory, SubscriptionLoadedState, SubscriptionStatus } from './models';
import type { Subscription } from './models';

export interface State {
    items: GenericObject<Subscription>;
    status: SubscriptionLoadedState;
}

type SetSubscriptionsAction = ReturnType<typeof setSubscriptions>;
type AddSubscriptionsAction = ReturnType<typeof addSubscriptions>;
type SuccessAction = ReturnType<typeof uncancelSubscription.success>;
type Success = Unpacked<ReturnType<typeof cancelSubscriptionApi>>;

type Payload = SuccessAction['payload'] & AddSubscriptionsAction['payload'] & Success;

export default handleActionsImmutably<State, Payload>(
    {
        [`${setSubscriptions}`]: (_state, { payload }: SetSubscriptionsAction) => ({
            status: SubscriptionLoadedState.Loaded,
            items: (payload || []).reduce((newState: GenericObject<Subscription>, subscription) => {
                newState[subscription.id] = SubscriptionFactory(subscription);

                return newState;
            }, {}),
        }),
        [`${addSubscriptions}`]: (state, { payload }: AddSubscriptionsAction) => {
            state.status = SubscriptionLoadedState.Loaded;

            (payload || []).forEach((subscription) => {
                state.items[subscription.id] = SubscriptionFactory(subscription);
            });

            return state;
        },
        [`${combineActions(
            unpauseSubscription.success,
            uncancelSubscription.success,
            confirmCancelSubscription.success,
        )}`]: (state, { payload }: SuccessAction) => {
            state.status = SubscriptionLoadedState.Loaded;
            const data = (payload.result as Success).data;
            state.items[data.id] = merge(
                state.items[data.id],
                SubscriptionFactory({
                    ...data,
                    expiresAt: (data.expiresAt && createDate(data.expiresAt)) || undefined,
                    createdAt: createDate(data.createdAt),
                    lastChargedAt:
                        (data.lastChargedAt && createDate(data.lastChargedAt)) || undefined,
                    nextChargeAt: (data.nextChargeAt && createDate(data.nextChargeAt)) || undefined,
                    pausedAt: (data.pausedAt && createDate(data.pausedAt)) || undefined,
                    resumesAt: (data.resumesAt && createDate(data.resumesAt)) || undefined,
                    status: data.status as SubscriptionStatus,
                }),
            );

            return state;
        },
        [`${combineActions(confirmDeleteNextSubscription.success)}`]: (state) => {
            state.status = SubscriptionLoadedState.Loaded;
            state.items = Object.keys(state.items)
                .filter((id) => state.items[id].status !== SubscriptionStatus.Next)
                .reduce<State['items']>((obj, key) => {
                    obj[key] = state.items[key];

                    return obj;
                }, {});

            return state;
        },
    },
    {
        status: SubscriptionLoadedState.Loading,
        items: {},
    },
);

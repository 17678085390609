import { Link, Typography } from '@lingoda/ui';
import { Translate, trans } from '@lingoda/i18n';
import { useToggleState } from '@lingoda/hooks';
import { RevocationDialog, TermsDialog } from '@lingoda/legal';

interface TermsLinkSprintProps {
    pdfLink: string;
}

export const TermsLinkSprint = ({ pdfLink }: TermsLinkSprintProps) => {
    const [isTermsOpen, handleOpenTerms, handleCloseTerms] = useToggleState(false);
    const [isRevocationOpen, handleOpenRevocation, handleCloseRevocation] = useToggleState(false);

    return (
        <>
            <Typography variant="body2">
                <Translate
                    id="form-label-terms-and-conditions-sprint"
                    domain="public-common"
                    params={{
                        terms: (
                            <Link variant="inherit" onClick={handleOpenTerms}>
                                {trans(
                                    'form-label-terms-and-conditions-sprint.terms',
                                    {},
                                    'public-common',
                                )}
                            </Link>
                        ),
                        revocation: (
                            <Link variant="inherit" onClick={handleOpenRevocation}>
                                {trans(
                                    'form-label-terms-and-conditions-sprint.revocation',
                                    {},
                                    'public-common',
                                )}
                            </Link>
                        ),
                        sprint: (
                            <Link variant="inherit" href={pdfLink} target="_blank">
                                {trans(
                                    'form-label-terms-and-conditions-sprint.sprint',
                                    {},
                                    'public-common',
                                )}
                            </Link>
                        ),
                    }}
                />
            </Typography>
            <TermsDialog isOpen={isTermsOpen} onClose={handleCloseTerms} />
            <RevocationDialog isOpen={isRevocationOpen} onClose={handleCloseRevocation} />
        </>
    );
};

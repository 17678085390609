import type { ContentBlock, Location } from '@lingoda/graphql';

export const findContentBlock = (
    list: Readonly<ContentBlock[]>,
    location: Location,
): ContentBlock | undefined => list.find((item) => item.location === location);

export const findAllContentBlocks = (
    list: Readonly<ContentBlock[]>,
    location: Location | Location[],
): ContentBlock[] | undefined => {
    const result = Array.isArray(location)
        ? location.flatMap((item) => list.filter((contentBlock) => contentBlock.location === item))
        : list.filter((item) => item.location === location);

    return result.length > 0 ? result : undefined;
};

import { memo } from 'react';
import { Avatar, BoxPerf, Grid, Hidden } from '@lingoda/ui';
import { type AvatarProps } from './common';

interface Props {
    teacher: AvatarProps | undefined;
}

const TeacherParticipantComponent = ({ teacher }: Props) => (
    <>
        <Hidden lgDown>
            {teacher ? (
                <Grid item>
                    <BoxPerf className="mr-2">
                        <Avatar size={40} src={teacher.src}>
                            {teacher.firstName}
                        </Avatar>
                    </BoxPerf>
                </Grid>
            ) : (
                <Grid item>
                    <BoxPerf className="mr-2 visibility-hidden">
                        <Avatar size={40} />
                    </BoxPerf>
                </Grid>
            )}
        </Hidden>
        <Hidden lgUp>
            {teacher ? (
                <Grid item>
                    <BoxPerf className="mr-0-5">
                        <Avatar size={40} src={teacher.src}>
                            {teacher.firstName}
                        </Avatar>
                    </BoxPerf>
                </Grid>
            ) : null}
        </Hidden>
    </>
);

export const TeacherParticipant = memo(TeacherParticipantComponent);

import { useCallback } from 'react';
import { Button } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import type { TimeSlotsFilterValue } from './model';
import type { FilterProps } from '../model';

interface ResetTimeButtonProps extends Pick<FilterProps<TimeSlotsFilterValue>, 'onChange'> {
    disabled?: boolean;
}

export const ResetTimeButton = ({ onChange, disabled }: ResetTimeButtonProps) => {
    const handleClick = useCallback(
        () => onChange({ timeSlots: { slots: [], extendHours: 0 } }),
        [onChange],
    );

    return (
        <Button
            size="small"
            color="grayscale"
            variant="outlined"
            onClick={handleClick}
            disabled={disabled}
        >
            {trans('btn-reset-time', {}, 'student-common')}
        </Button>
    );
};

import {
    addDays as addDaysFns,
    addHours as addHoursFns,
    addMilliseconds as addMillisecondsFns,
    addMinutes as addMinutesFns,
    addMonths as addMonthsFns,
    addSeconds as addSecondsFns,
    addWeeks as addWeeksFns,
    addYears as addYearsFns,
    differenceInDays,
    differenceInHours,
    differenceInMilliseconds,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInWeeks,
    endOfDay,
    endOfWeek,
    formatDistance as formatDistanceFns,
    formatDistanceStrict as formatDistanceStrictFns,
    formatDistanceToNow as formatDistanceToNowFns,
    formatDistanceToNowStrict as formatDistanceToNowStrictFns,
    formatDuration as formatDurationFns,
    formatRelative,
    intlFormatDistance as intlFormatDistanceFns,
    isAfter as isAfterFns,
    isBefore as isBeforeFns,
    isEqual as isEqualFns,
    isSameDay,
    isWithinInterval,
    set as setFns,
    setMinutes,
    startOfDay,
    startOfHour,
    startOfWeek,
} from 'date-fns';
import {
    format as formatTz,
    toDate as toDateFns,
    utcToZonedTime,
    zonedTimeToUtc,
} from 'date-fns-tz';
import buildFormatLong from 'date-fns/locale/_lib/buildFormatLongFn';
import curry from 'lodash/curry';
import curryRight from 'lodash/curryRight';
import flow from 'lodash/flow';
import merge from 'lodash/merge';
import { type DateInputObject, TimeFormat, TimeFormatLegacy, type Weekdays } from '../common';
import { type Duration } from './_common';
import type { Config } from 'date-fns/locale/_lib/buildFormatLongFn';
import type { Locale } from 'date-fns';

export type { NativeDate as Date } from './_common';

export const DEFAULT_TZ = 'Europe/Berlin';
let defaultTimezone = DEFAULT_TZ;
let defaultLocale: Locale;

export {
    compareAsc,
    differenceInHours,
    differenceInSeconds,
    differenceInMilliseconds,
    formatISO,
    isSameDay,
    isSameHour,
    isSameMinute,
    isSameWeek,
    isSameMonth,
    getSeconds,
    getHours,
    getDay,
    getTime,
    getYear,
    getMinutes,
    getMilliseconds,
    startOfDay,
    startOfHour,
    startOfWeek,
    startOfMonth,
    endOfDay,
    endOfHour,
    endOfWeek,
    endOfMonth,
    getDate,
    getWeek,
    setDate,
    setDay,
    setMilliseconds,
    setHours,
    setMonth,
    setYear,
    differenceInMinutes as diffInMinutes,
    daysToWeeks,
    isValid as isDateValid,
} from 'date-fns';

export { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

export const getTimezone = () => defaultTimezone;

const setTimezone = curry<string, Date, Date>(
    (timezone, date: Date): Date => utcToZonedTime(date, timezone),
);

export const addMilliseconds = curryRight(addMillisecondsFns);
export const addSeconds = curryRight(addSecondsFns);
export const addHours = curryRight(addHoursFns);
export const addDays = curryRight(addDaysFns);
export const addWeeks = curryRight(addWeeksFns);
export const addMinutes = curryRight(addMinutesFns);
export const addMonths = curryRight(addMonthsFns);
export const addYears = curryRight(addYearsFns);

type SetFnsParams = Parameters<typeof setFns>;
export const set = (values: SetFnsParams[1], date: SetFnsParams[0]) => setFns(date, values);

export const startOf = curry((type: 'hour' | 'day' | 'week', date: Date) => {
    switch (type) {
        case 'hour':
            return setMinutes(startOfHour(date), utcMinutesOffset());
        case 'day':
            return startOfDay(date);
        default:
            return startOfWeek(date);
    }
});

export const endOf = curry((type: 'day' | 'week', date: Date) => {
    switch (type) {
        case 'day':
            return endOfDay(date);
        default:
            return endOfWeek(date);
    }
});

export const add = curry(
    (
        amount: Pick<
            DateInputObject,
            'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years'
        >,
        date: Date,
    ): Date =>
        flow([
            addMilliseconds(amount.milliseconds || 0),
            addSeconds(amount.seconds || 0),
            addMinutes(amount.minutes || 0),
            addHours(amount.hours || 0),
            addDays(amount.days || 0),
            addWeeks(amount.weeks || 0),
            addMonths(amount.months || 0),
            addYears(amount.years || 0),
        ])(date),
);

export const subtract = curry((amount: FirstItem<Parameters<typeof add>>, date: Date): Date => {
    const negateAmount = (Object.keys(amount) as (keyof typeof amount)[]).reduce(
        (item, key) => ({
            ...item,
            [key]: -1 * (amount?.[key] || 0),
        }),
        {},
    );

    return add(negateAmount, date);
});

export const isBetween = (start: Date, end: Date, date: Date) =>
    isWithinInterval(date, {
        start,
        end,
    });

export type TimeUnit =
    | 'months'
    | 'weeks'
    | 'days'
    | 'milliseconds'
    | 'minutes'
    | 'seconds'
    | 'hours';

export const diffInUnits = curry((unit: TimeUnit, dateLeft: Date, dateRight: Date): number => {
    switch (unit) {
        case 'months':
            return differenceInMonths(dateLeft, dateRight);
        case 'weeks':
            return differenceInWeeks(dateLeft, dateRight);
        case 'days':
            return differenceInDays(dateLeft, dateRight);
        case 'minutes':
            return differenceInMinutes(dateLeft, dateRight);
        case 'seconds':
            return differenceInSeconds(dateLeft, dateRight);
        case 'hours':
            return differenceInHours(dateLeft, dateRight);
        default:
            return differenceInMilliseconds(dateLeft, dateRight);
    }
});

export const relativeDiffInUnit = curry(
    (unit: TimeUnit, dateLeft: Date, dateRight: Date): number => {
        switch (unit) {
            case 'minutes':
                return diffInUnits(unit, dateLeft, dateRight) % 60;
            case 'seconds':
                return diffInUnits(unit, dateLeft, dateRight) % 60;
            case 'hours':
                return diffInUnits(unit, dateLeft, dateRight) % 24;
            case 'milliseconds':
                return diffInUnits(unit, dateLeft, dateRight) % 1000;
            default:
                return diffInUnits(unit, dateLeft, dateRight) % 365;
        }
    },
);

export const diff = diffInUnits('milliseconds');

export const getUnixTime = (date: Date) => date?.valueOf();

export const relativeDiffInUnits = (
    units: TimeUnit[],
    dateLeft: Date,
    dateRight: Date,
): Partial<Duration> =>
    units.reduce(
        (duration, base) => ({
            ...duration,
            [base]: relativeDiffInUnit(base, dateLeft, dateRight),
        }),
        {},
    );

export const getLocale = () => defaultLocale;

export const getWeekStartOn = () => getLocale()?.options?.weekStartsOn;

export const setWeekStartOn = (startsOn: Weekdays) => {
    const locale = getLocale();
    if (!locale.options) {
        return;
    }
    locale.options.weekStartsOn = startsOn;
};

export const format = curry((stringFormat: string, date: Date) =>
    formatTz(date, stringFormat, {
        locale: defaultLocale,
        timeZone: defaultTimezone,
    }),
);

export const formatTinyHour = (date: Date, timeFormat: TimeFormat) => {
    return format(timeFormatsMapping[timeFormat].tiny, date);
};

export const formatDateHour = (date: Date, timeFormat: TimeFormat) => {
    return format(timeFormatsMapping[timeFormat].short, date);
};

export const formatHour = (hour: number, timeFormat: TimeFormat) => {
    return formatDateHour(
        set({ hours: hour, minutes: utcMinutesOffset() }, createDate()),
        timeFormat,
    );
};

export const formatGMTtime = (timezone: string, date = createDate(undefined, timezone)) => {
    return formatTz(date, 'O', {
        locale: defaultLocale,
        timeZone: timezone,
    });
};

export const createDate = (date?: string, tz?: string): Date => {
    const dateObject = date ? toDateFns(date) : new Date();

    return setTimezone(tz || defaultTimezone, dateObject);
};

export const createDateFromLocalDateString = (date?: string): Date => {
    return date ? toDateFns(date) : createDate();
};

export const toDate = (date: string | Date, tz?: string): Date => {
    return typeof date === 'string' ? createDate(date, tz) : date;
};

export const toUtc = (date: Date, tz?: string) => {
    return zonedTimeToUtc(date, tz || defaultTimezone);
};

export const toUTCString = (date: Date, tz?: string) => {
    return toUtc(date, tz).toISOString();
};

export const isBefore = (date: Date, dateToCompare: Date): boolean => {
    return isBeforeFns(date, dateToCompare);
};

export const isAfter = curry((date: Date, dateToCompare: Date): boolean => {
    return isAfterFns(date, dateToCompare);
});

export const isSameOrAfter = curry((date: Date, dateToCompare: Date) => {
    return isEqual(date, dateToCompare) || isAfter(date, dateToCompare);
});

export const isSameOrAfterToday = (date: Date) => isSameOrAfter(date, createDate());

export const isSameOrBefore = curry((date: Date, dateToCompare: Date) => {
    return isEqual(date, dateToCompare) || isBefore(date, dateToCompare);
});

export const isSameOrBeforeToday = (date: Date) => isSameOrBefore(date, createDate());

export const isSameDayOrBefore = (date: Date, dateToCompare: Date) =>
    isSameDay(date, dateToCompare) || isBefore(date, dateToCompare);

export const isSameDayOrAfter = (date: Date, dateToCompare: Date) =>
    isSameDay(date, dateToCompare) || isAfter(date, dateToCompare);

export const isPast = (date: Date) => isBefore(date, createDate());

export const isToday = (date: Date) => isSameDay(date, createDate());

export const isTomorrow = (date: Date) => isSameDay(date, addDays(createDate(), 1));

export const isEqual = curry(isEqualFns);

export const setDefaultLocale = (locale: Locale) => (defaultLocale = locale);

export const setDefaultTimezone = (tz: string) => (defaultTimezone = tz);

export const weekdays = (sortLocale?: boolean, options?: unknown) => {
    const startsOn = (sortLocale && getWeekStartOn()) || 0;
    const localize = getLocale().localize;

    if (!localize) {
        return [...Array(7).keys()].map((index) => index.toString());
    }

    return [...Array(7).keys()].map(
        (_item, index) => localize.day((index + startsOn) % 7, options) as string,
    );
};

export const weekDayShort = (n: number) => weekdaysShort()[n];

export const weekdayValue = (day: string) => weekdays().indexOf(day) || 7;

export const weekdaysShort = (sortLocale?: boolean) =>
    weekdays(sortLocale, { width: 'abbreviated' });

export const utcTotalMinuteOffset = () => {
    const now = new Date().toISOString();

    return differenceInMinutes(createDate(now), createDate(now, 'UTC'));
};

export const utcMinutesOffset = () => Math.abs(utcTotalMinuteOffset()) % 60;

export const convertLocalToCurrentTz = (dateInLocalTz: Date) => {
    const localTzOffset = differenceInMinutes(new Date(), createDate());

    return add({ minutes: localTzOffset }, dateInLocalTz);
};

const timeFormatsMapping: Record<TimeFormat, Config['formats']> = {
    [TimeFormat.Iso8601]: {
        full: `HH:mm`,
        long: `HH:mm:ss`,
        medium: `HH:mm`,
        short: `HH:mm`,
        tiny: `HH:mm`,
    },
    [TimeFormat.Ampm]: {
        full: 'h:mm:ss a',
        long: 'h:mm:ss a',
        medium: 'h:mm a',
        short: 'h:mm a',
        tiny: 'h a',
    },
};

export const firstDayOfWeek = () => defaultLocale?.options?.weekStartsOn;

export const localeFromFormats = (timeFormat: TimeFormat, startWeek?: Weekdays) => {
    let customLocale = {};

    if (timeFormat) {
        customLocale = {
            formatLong: {
                time: buildFormatLong({
                    formats: timeFormatsMapping[timeFormat],
                    defaultWidth: 'short',
                }),
            },
        };
    }

    return merge(defaultLocale, {
        ...customLocale,
        options: {
            weekStartsOn: startWeek ?? firstDayOfWeek(),
        },
    });
};

export const getTimeFormatFromLegacy = (timeFormat: TimeFormat | TimeFormatLegacy) => {
    if (timeFormat === TimeFormatLegacy.Ampm) {
        return TimeFormat.Ampm;
    }
    if (timeFormat === TimeFormatLegacy.Iso8601) {
        return TimeFormat.Iso8601;
    }

    return timeFormat;
};

// regex for validating ISO string
const ISO_PERIOD =
    /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;
// date-fns lacks this functionality, will be replaced for it when added
export const addDuration = (date: Date, duration: string): Date => {
    duration = duration.trim();
    const matches = ISO_PERIOD.exec(duration);
    if (!matches || duration.length < 3) {
        throw new TypeError(
            `duration invalid: "${duration}". Must be a ISO 8601 duration. See https://en.wikipedia.org/wiki/ISO_8601#Durations`,
        );
    }

    const posNeg = matches[1] === '-' ? -1 : 1;

    return [addYears, addMonths, addWeeks, addDays, addHours, addMinutes, addSeconds].reduce(
        (result, method, index) => method(result, posNeg * +matches[index + 2] || 0),
        date,
    );
};

export const formatDistance = (
    ...[date, baseDate, options]: Parameters<typeof formatDistanceFns>
) =>
    formatDistanceFns(date, baseDate, {
        locale: defaultLocale,
        ...options,
    });

export const formatDistanceStrict = (
    ...[date, baseDate, options]: Parameters<typeof formatDistanceStrictFns>
) =>
    formatDistanceStrictFns(date, baseDate, {
        locale: defaultLocale,
        ...options,
    });

export const formatDistanceToNow = (
    ...[date, options]: Parameters<typeof formatDistanceToNowFns>
) =>
    formatDistanceToNowFns(date, {
        locale: defaultLocale,
        ...options,
    });

export const formatDistanceToNowStrict = (
    ...[date, options]: Parameters<typeof formatDistanceToNowStrictFns>
) =>
    formatDistanceToNowStrictFns(date, {
        locale: defaultLocale,
        ...options,
    });

export const intlFormatDistance = (
    ...[date, baseDate, options]: Parameters<typeof intlFormatDistanceFns>
) =>
    intlFormatDistanceFns(date, baseDate, {
        locale: defaultLocale.code,
        ...options,
    });

export const durationToUnix = (duration: Partial<Duration>): number =>
    Object.keys(duration).reduce((acc, key) => {
        const value: number = duration[key as keyof Duration] || 0;
        switch (key) {
            case 'milliseconds':
                return acc + value;
            case 'seconds':
                return acc + value * 1000;
            case 'minutes':
                return acc + value * 1000 * 60;
            case 'hours':
                return acc + value * 1000 * 60 * 60;
            case 'days':
                return acc + value * 1000 * 60 * 60 * 24;
            default:
                return acc;
        }
    }, 0);

export const formatDuration = (
    duration: Parameters<typeof formatDurationFns>[0],
    options?: Parameters<typeof formatDurationFns>[1],
) =>
    formatDurationFns(duration, {
        locale: defaultLocale,
        ...(options || {}),
    });

export const formatDurationToHuman = (
    duration: Parameters<typeof formatDurationFns>[0],
    format: Parameters<typeof relativeDiffInUnits>[0],
) => {
    Object.keys(duration).forEach((key) => {
        if (!format.find((item) => key === item)) {
            duration[key as keyof Parameters<typeof formatDurationFns>[0]] = undefined;
        }
    });

    return formatDuration(duration);
};

export const formatDurationToHumanShort = (
    duration: Parameters<typeof formatDurationFns>[0],
    format: Parameters<typeof relativeDiffInUnits>[0],
) =>
    formatDurationToHuman(duration, format)
        .replace(/(\d+)\s(\b[A-Za-z])\w+($|\s)/g, '$1$2')
        .toLowerCase();

export const formatDurationToCounter = (duration: Parameters<typeof formatDurationFns>[0]) =>
    Object.values(duration)
        .reduce((acc: string[], item) => {
            if (item && (acc.length > 0 || item > 0)) {
                const value = item < 10 ? `0${item}` : `${item}`;

                return [...acc, value];
            }

            return acc;
        }, [])
        .join(':');

export const formatRelativeDate =
    (dateFormat: string, appendFormat = false, separator = ' ') =>
    (date: Date) => {
        if (isToday(date) || isTomorrow(date)) {
            const append = appendFormat ? `${separator}${format(dateFormat)(date)}` : '';

            return `${formatRelative(date, createDate()).split(' ')[0]}${append}`;
        }

        return format(dateFormat)(date);
    };

export const isoWeekdays: (keyof typeof Weekdays)[] = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
];

export const getSortedWeekdays = () =>
    weekdays(true).map((day) => isoWeekdays[weekdayValue(day) - 1]);

const filterDatesWithinRange = (dates: Date[], [startDate, endDate]: [Date, Date]) =>
    dates.filter((date) => isBetween(startDate, endDate, date));

export const calcDatesWithinRange = (dates: Date[], range: [Date, Date]) =>
    filterDatesWithinRange(dates, range).length;

const dayStringPattern = 'yyyy-MM-dd';
export const formatTzIndependentDay = (date: Date) => {
    const [yearToken, monthToken, dayToken] = dayStringPattern.split('-');

    return dayStringPattern
        .replace(yearToken, format('yyyy', date))
        .replace(monthToken, format('MM', date))
        .replace(dayToken, format('dd', date));
};

export const parseTzIndependentDay = (dayString: string) => {
    if (dayString.length === dayStringPattern.length) {
        const [year, month, day] = dayString.split('-').map((item) => parseInt(item, 10));
        const date = startOfDay(createDate());
        const sameDayDate = set({ year: year, month: month - 1, date: day }, date);

        return sameDayDate;
    }

    return createDate(dayString);
};

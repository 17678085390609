import { isNoCreditCardTrialEnabled } from '@lingoda/feature-flags';
import { trans } from '@lingoda/i18n';
import { Button, Grid } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { BookButton } from '../BookButton';
import type { CommonProps } from '../../types/props';

interface Props extends Pick<CommonProps, 'onBook' | 'onDetails' | 'isEmbedded'> {
    isLessonAttended: boolean;
    isLessonLocked: boolean;
    disabled?: boolean;
}

const DynamicGrid = styled(Grid, {
    shouldForwardProp: (prop) => prop !== 'isEmbedded',
})<{ isEmbedded?: boolean }>(({ theme, isEmbedded }) => ({
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up(isEmbedded ? 'md' : 'lg')]: {
        flexDirection: 'row',
    },
}));

export const ClassListItemActions = ({
    disabled,
    onBook,
    isLessonAttended,
    isLessonLocked,
    onDetails,
    isEmbedded,
}: Props) => (
    <DynamicGrid container alignItems="center" spacing={1} isEmbedded={isEmbedded}>
        <Grid item>
            <BookButton
                size="small"
                outlined={isLessonAttended && !isLessonLocked}
                locked={isLessonLocked}
                disabled={disabled}
                onClick={onBook}
            >
                {isLessonAttended
                    ? trans('btn-book-again', {}, 'student-common')
                    : trans('btn-book', {}, 'student-common')}
            </BookButton>
        </Grid>
        {!isNoCreditCardTrialEnabled() && (
            <Grid item>
                <Button variant="text" size="small" disabled={disabled} onClick={onDetails}>
                    {trans('btn-details', {}, 'student-common')}
                </Button>
            </Grid>
        )}
    </DynamicGrid>
);

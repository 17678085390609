import { Radio, RadioGroup } from '@lingoda/forms';
import amexSvg from './amex.svg?component';
import mcSvg from './mastercard.svg?component';
import paymentRequestSvg from './paymentRequest.svg?component';
import paypalSvg from './paypal.svg?component';
import sepaSvg from './sepa.svg?component';
import sofortSvg from './sofort.svg?component';
import visaSvg from './visa.svg?component';
import type { PaymentMethod } from '../../models';

const logosConfig = {
    amex: { svg: amexSvg, width: 24, height: 15 },
    mc: { svg: mcSvg, width: 33, height: 20 },
    paypal: { svg: paypalSvg, width: 59, height: 20 },
    sepa: { svg: sepaSvg, width: 55, height: 20 },
    sepadirectdebit: { svg: sepaSvg, width: 55, height: 20 },
    visa: { svg: visaSvg, width: 37, height: 15 },
    sofort: { svg: sofortSvg, width: 69, height: 22 },
    paymentRequest: { svg: paymentRequestSvg, height: 48, width: 90 },
};

type LogoConfigItem = keyof typeof logosConfig;

interface PaymentMethodsProps {
    onChange: (_e: React.FormEvent<HTMLElement>, value: string) => void;
    value?: string;
    paymentMethods: PaymentMethod[];
}

type Props = PaymentMethodsProps;

const PaymentMethods: React.FC<Props> = ({ value, onChange, paymentMethods }) => (
    <RadioGroup name="paymentMethod" row onChange={onChange}>
        {paymentMethods
            .filter(
                ({ logos }) => !!logos.find((logo: string) => logosConfig[logo as LogoConfigItem]),
            )
            .map(({ text, id, logos }) => (
                <Radio
                    key={id}
                    label={
                        <>
                            {logos.map((logo: string) => {
                                const Logo = logosConfig[logo as LogoConfigItem].svg;

                                return (
                                    <Logo
                                        key={logo}
                                        width={logosConfig[logo as LogoConfigItem].width}
                                        height={logosConfig[logo as LogoConfigItem].height}
                                        style={{ marginRight: 5, verticalAlign: 'bottom' }}
                                    />
                                );
                            })}
                            {text && ` ${text}`}
                        </>
                    }
                    checked={id === value}
                    value={id}
                />
            ))}
    </RadioGroup>
);

export default PaymentMethods;

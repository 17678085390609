import { createSelector } from 'reselect';
import { userSectionSelector } from '@lingoda/auth';
import studentModuleSelector from './studentModule';

//the specific section+module are defined in https://lingoda.atlassian.net/browse/LW-21945

const flags = (sectionId: number, moduleId: number) => {
    //French A2+B1
    if (sectionId === 4 && moduleId >= 13 && moduleId <= 18) {
        return 'https://band-fact-745.notion.site/Lingoda-Live-Practice-Beginners-Intermediate-37aa48bb04404f83afa269f949d03ff2';
    }
    //German A2+B1
    if (sectionId === 2 && moduleId >= 13 && moduleId <= 18) {
        return 'https://band-fact-745.notion.site/Lingoda-Live-Practice-Beginners-Intermediate-0cb0c228a4a74fb1870f0117c385573e';
    }
    //German B2+C1
    if (sectionId === 2 && !!moduleId && moduleId >= 19 && moduleId <= 29) {
        return 'https://band-fact-745.notion.site/Lingoda-Live-Practice-Intermediate-Advanced-52941e2d78e14012b79e84c411624767';
    }
};

export default createSelector(
    userSectionSelector,
    studentModuleSelector,
    (section, module) => section && module && flags(section.id, module.id),
);

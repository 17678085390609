import memoize from 'lodash/memoize';
import resolvePath from './resolvePath';
import type { Path } from './types';

interface ObjArg {
    path: Path;
    matchPrefix: boolean;
}

export type Arg = Path | ObjArg;
type CheckFn = (path: Arg, loc: string) => boolean;

const isObjArg = (arg: Arg): arg is ObjArg => typeof arg === 'object' && 'matchPrefix' in arg;

const resolveArg = (arg: Arg) => (isObjArg(arg) ? arg.path : arg);

const check: CheckFn = (path, loc) =>
    isObjArg(path) && path.matchPrefix
        ? loc.startsWith(resolvePath(resolveArg(path)))
        : loc === resolvePath(resolveArg(path));

const matchPath = (paths: Arg | Arg[], location: string, checkFn: CheckFn = check) =>
    Array.isArray(paths) ? paths.some((path) => checkFn(path, location)) : checkFn(paths, location);

const checkMemoized = memoize(check, (...args) => JSON.stringify(args));

export const matchPathMemoized = memoize(
    (paths: Arg | Arg[], location: string) => matchPath(paths, location, checkMemoized),
    (...args) => JSON.stringify(args),
);

export default matchPath;

import { isNoCreditCardTrialEnabled } from '@lingoda/feature-flags';
import { trans } from '@lingoda/i18n';
import { Button, Grid } from '@lingoda/ui';
import { BookButton } from '../BookButton';
import type { CommonProps } from '../../types/props';

interface Props extends Pick<CommonProps, 'onBook' | 'onDetails'> {
    isLessonAttended: boolean;
    isLessonLocked: boolean;
    disabled?: boolean;
}

export const ClassTileActions = ({
    disabled,
    onBook,
    isLessonAttended,
    isLessonLocked,
    onDetails,
}: Props) => (
    <Grid container alignItems="center" spacing={1}>
        <Grid item>
            <BookButton
                size="small"
                outlined={isLessonAttended && !isLessonLocked}
                locked={isLessonLocked}
                disabled={disabled}
                onClick={onBook}
            >
                {isLessonAttended
                    ? trans('btn-book-again', {}, 'student-common')
                    : trans('btn-book', {}, 'student-common')}
            </BookButton>
        </Grid>
        {!isNoCreditCardTrialEnabled() && (
            <Grid item>
                <Button variant="text" size="small" disabled={disabled} onClick={onDetails}>
                    {trans('btn-details', {}, 'student-common')}
                </Button>
            </Grid>
        )}
    </Grid>
);

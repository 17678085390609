import { Box, useUiEvent } from '@lingoda/ui';
import { studentQuizNextPath } from '@lingoda/router';
import { trans } from '@lingoda/i18n';
import { actionGoToQuiz } from '@lingoda/quiz-analytics';
import { ButtonCta, Entry, LastReview, Title } from './Entry';
import prepareImg from './assets/frank-prepares.svg?url';

interface EntryPendingProps {
    lastReview?: string | Date;
}

export const EntryPending = ({ lastReview }: EntryPendingProps) => {
    const addEvent = useUiEvent();

    return (
        <Entry
            contentSx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
            bgImage={prepareImg}
        >
            <Box>
                <Title mb={0.5}>
                    {trans('entry-incomplete-title', {}, 'quiz-practice-dashboard')}
                </Title>

                {lastReview && <LastReview date={lastReview} />}
            </Box>

            <Box mt={2}>
                <ButtonCta
                    href={studentQuizNextPath()}
                    onClick={() => void addEvent(actionGoToQuiz())}
                >
                    {trans('entry-incomplete-cta', {}, 'quiz-practice-dashboard')}
                </ButtonCta>
            </Box>
        </Entry>
    );
};

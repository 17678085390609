import { useLayoutEffect } from 'react';
import useToggleState from './useToggleState';

const useSticky = (offset = 0) => {
    const [isSticky, setSticky, clearSticky] = useToggleState();

    useLayoutEffect(() => {
        const onScroll = () => {
            window.requestAnimationFrame(() => {
                if (window.pageYOffset > offset) {
                    setSticky();
                } else {
                    clearSticky();
                }
            });
        };

        if (window) {
            window.addEventListener('scroll', onScroll);

            return () => {
                window.removeEventListener('scroll', onScroll);
            };
        }
    }, [setSticky, offset, clearSticky]);

    return isSticky;
};

export default useSticky;

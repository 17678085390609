import { AvatarFallback, type AvatarProps } from '@lingoda/ui';
import type { TeacherAvatarFragment } from '@lingoda/graphql';
import type { Teacher } from '../../models';

interface PropsOld extends AvatarProps {
    teacher?: Pick<Teacher, 'firstName' | 'photos'>;
}

// @deprecated use TeacherAvatar instead with GQL data
export const TeacherAvatarOld = ({ teacher, size = 40, ...props }: PropsOld) => (
    <AvatarFallback src={teacher?.photos?.medium} alt={teacher?.firstName} {...props} size={size} />
);

interface Props extends AvatarProps {
    teacher?: TeacherAvatarFragment | null;
}

export const TeacherAvatar = ({ teacher, size = 40, ...props }: Props) => {
    return (
        <AvatarFallback
            src={teacher?.photo?.urls.medium}
            alt={teacher?.firstName}
            size={size}
            {...props}
        />
    );
};

import { createSelector } from 'reselect';
import { type CourseOverviewLearningUnitFragment, LessonType } from '@lingoda/graphql';
import { Box, Divider, ListItem, Stack, useResolution } from '@lingoda/ui';
import { hasOrientationEGSelector } from '@lingoda/modules';
import { isEasyGeneratorEnabled, isFlashcardsPageEnabled } from '@lingoda/feature-flags';
import { useSelector } from '@lingoda/hooks';
import { ExerciseContent } from './ExerciseContent';
import { FlashcardsContent } from './FlashcardsContent';

interface Props {
    learningUnit: CourseOverviewLearningUnitFragment;
    moduleId: number;
}

export const ExercisesListItem = ({ learningUnit, moduleId }: Props) => {
    const hasOrientationEG = useSelector(hasOrientationEGSelector(moduleId));
    const isOrientation = hasOrientationEG ? hasOrientationLesson(learningUnit) : false;

    const vocabularyItemsCount = learningUnit.vocabularyItems.length || 0;

    const isSmallScreen = useResolution('phone');

    const [stackDir, dividerDir] = isSmallScreen
        ? (['column', 'horizontal'] as const)
        : (['row', 'vertical'] as const);

    return (
        <ListItem divider disablePadding>
            <Stack
                flexDirection={stackDir}
                width="100%"
                divider={<Divider flexItem orientation={dividerDir} />}
            >
                {learningUnit.exercise?.link && (isEasyGeneratorEnabled() || hasOrientationEG) && (
                    <Box flex={1}>
                        <ExerciseContent
                            exercise={learningUnit.exercise}
                            isOrientation={isOrientation}
                        />
                    </Box>
                )}
                {learningUnit && isFlashcardsPageEnabled() && vocabularyItemsCount > 0 && (
                    <Box flex={1}>
                        <FlashcardsContent learningUnit={learningUnit} />
                    </Box>
                )}
            </Stack>
        </ListItem>
    );
};

const hasOrientationLesson = createSelector(
    (learningUnit: CourseOverviewLearningUnitFragment) => learningUnit.lessons,
    (lessons: CourseOverviewLearningUnitFragment['lessons']) => {
        return lessons.some((lesson) => {
            return lesson.type === LessonType.Orientation;
        });
    },
);

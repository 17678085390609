import merge from 'lodash/merge';
import pick from 'lodash/pick';
import type { StudentResponse } from '@lingoda/api';
import { updateNotifications, updateSettings } from '@lingoda/auth';
import { handleActionsImmutably } from '@lingoda/core';
import { addStudents, setOnboarded, updatePreferences, updateStudent } from './actions';
import { StudentFactory, StudentPreferencesFactory } from './models';
import type { Student } from './models';

export type State = GenericObject<Student>;

type AddStudentsAction = ReturnType<typeof addStudents>;
type StudentAction = ReturnType<typeof updateStudent>;
type UpdateSettingsAction = ReturnType<typeof updateSettings.success>;
type UpdateNotificationsAction = ReturnType<typeof updateNotifications.success>;
type SetOnboardedAction = ReturnType<typeof setOnboarded.success>;
type UpdatePreferencesAction = ReturnType<typeof updatePreferences.success>;

type Payload = StudentAction['payload'] &
    AddStudentsAction['payload'] &
    UpdateSettingsAction['payload'] &
    UpdateNotificationsAction['payload'] &
    SetOnboardedAction['payload'] &
    UpdatePreferencesAction;

export default handleActionsImmutably<State, Payload>(
    {
        [`${updateStudent}`]: (
            state: State,
            { payload: { preferences, ...payload } }: StudentAction,
        ) => {
            // In case you're wondering why we create record here too, it's because it forces
            // to drop incorrect keys and merge doesn't throw error if unspecified keys are
            // tried to merge
            const updates = pick(StudentFactory(payload), Object.keys(payload));

            if (preferences) {
                updates.preferences = StudentPreferencesFactory({
                    ...state.preferences,
                    ...preferences,
                });
            }

            // Update only keys passed
            state[payload.id] = merge(state[payload.id], updates);

            return state;
        },

        [`${addStudents}`]: (state: State, { payload: students }: AddStudentsAction) => {
            students.forEach((student) => {
                if (state[student.id]) {
                    return;
                }
                state[student.id] = StudentFactory(student);
            });

            return state;
        },
        [`${updateSettings.success}`]: (
            state: State,
            {
                payload: {
                    payload: { studentId, changes },
                },
            }: UpdateSettingsAction,
        ) => {
            state[studentId] = StudentFactory({
                ...state[studentId],
                preferences: StudentPreferencesFactory({
                    ...state[studentId].preferences,
                    timeFormat: changes.timeFormat,
                    weekStart: changes.weekStart,
                }),
            });

            return state;
        },
        [`${updateNotifications.success}`]: (
            state: State,
            {
                payload: {
                    payload: { studentId, changes },
                },
            },
        ) => {
            state[studentId] = StudentFactory({
                ...state[studentId],
                preferences: StudentPreferencesFactory({
                    ...state[studentId].preferences,
                    nextClass: changes.nextClass,
                    oneClickBooking: changes.oneClickBooking,
                    emailCancelledClassConfirmation: changes.emailCancelledClassConfirmation,
                    emailBookedClassConfirmation: changes.emailBookedClassConfirmation,
                    emailClassReminder: changes.emailClassReminder,
                    emailWeeklyReport: changes.emailWeeklyReport,
                }),
            });

            return state;
        },
        [`${setOnboarded.success}`]: (state: State, action: SetOnboardedAction) => {
            const student = (action.payload.result as StudentResponse).data;
            if (state[student.id]) {
                state[student.id] = StudentFactory({
                    ...state[student.id],
                    onboarded: student.onboarded,
                });
            }

            return state;
        },
        [`${updatePreferences.success}`]: (state: State, action: SetOnboardedAction) => {
            const student = (action.payload.result as StudentResponse).data;
            if (state[student.id]) {
                state[student.id] = StudentFactory({
                    ...state[student.id],
                    preferences: student.preferences,
                });
            }

            return state;
        },
    },
    {},
);

import { type ReactNode, type Ref, forwardRef } from 'react';
import {
    Box,
    Img,
    Paper,
    Skeleton,
    Stack,
    Typography,
    type TypographyProps,
    colorsPalette,
    styled,
} from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { ExpandLess, ExpandMore } from '@lingoda/ui/icons';
import knownStatImage from './assets/knownStatBackground.svg?url';
import reviewingStatImage from './assets/reviewingStatBackground.svg?url';

interface StyleProps {
    backgroundColor: string;
    textColor?: string;
    counterColor?: string;
    statImage?: string;
}

interface DataProps {
    title?: string;
    count?: ReactNode;
    difCount?: number;
    indicatorRef?: Ref<HTMLElement>;
    indicatorLabel?: ReactNode;
    indicatorSx?: IndicatorProps['sx'];
    backgroundAnimation?: ReactNode;
}

type Props = StyleProps & DataProps;

export const ReviewingStat = (props: DataProps) => <Stat {...reviewingStatProps} {...props} />;

export const KnownStat = (props: DataProps) => <Stat {...knownStatProps} {...props} />;

export const ReviewingStatSkeleton = (props: DataProps) => (
    <StatSkeleton {...reviewingStatProps} {...props} />
);

export const KnownStatSkeleton = (props: DataProps) => (
    <StatSkeleton {...knownStatProps} {...props} />
);

const knownStatProps = {
    textColor: colorsPalette.positiveCustom.extradark,
    backgroundColor: colorsPalette.positiveSolid[12],
    counterColor: colorsPalette.positiveCustom.dark,
    title: trans('Known', {}, 'quiz-practice-dashboard'),
    statImage: knownStatImage,
};

const reviewingStatProps = {
    textColor: colorsPalette.infoCustom.extradark,
    backgroundColor: colorsPalette.infoSolid[12],
    counterColor: colorsPalette.infoSolid[100],
    title: trans('Reviewing', {}, 'quiz-practice-dashboard'),
    statImage: reviewingStatImage,
};

const Stat = ({
    backgroundAnimation,
    backgroundColor,
    title,
    count,
    difCount,
    textColor,
    counterColor,
    indicatorRef,
    indicatorLabel,
    indicatorSx,
    statImage,
}: Props) => {
    return (
        <StatPaper backgroundColor={backgroundColor}>
            {backgroundAnimation}
            <BackgroundImage src={statImage} aria-hidden="true" />
            <Box position="relative">
                <Typography variant="subtitle2" htmlColor={textColor}>
                    {title}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Box color={counterColor}>{count}</Box>

                    {difCount ? (
                        <Indicator
                            ref={indicatorRef}
                            sx={indicatorSx}
                            htmlColor={textColor}
                            count={difCount}
                            label={indicatorLabel}
                        />
                    ) : null}
                </Stack>
            </Box>
        </StatPaper>
    );
};

const StatSkeleton = ({ backgroundColor, textColor }: Props) => {
    return (
        <StatPaper backgroundColor={backgroundColor}>
            <Box>
                <Typography variant="subtitle2" htmlColor={textColor}>
                    <Skeleton variant="text" width="100px" />
                </Typography>
                <Skeleton variant="text" width="70px" height="56px" />
            </Box>
        </StatPaper>
    );
};

const StatPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor: string }>(({ theme, backgroundColor }) => ({
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2, 2),
    },
    height: '108px',
    backgroundColor,
    boxShadow: 'none',
    border: `1px solid ${colorsPalette.blackAlpha[16]}`,
    borderRadius: '8px',
}));

const BackgroundImage = styled(Img)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: '1rem',
    [theme.breakpoints.down('md')]: {
        right: '0.5rem',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const StatCount = (props: Pick<TypographyProps, 'component' | 'children' | 'htmlColor'>) => {
    return (
        <Typography
            variant="h2"
            fontSize={{ xs: '32px', smw: '40px', md: '48px' }}
            fontWeight={700}
            lineHeight={['56px']}
            {...props}
        />
    );
};

interface IndicatorProps {
    htmlColor?: string;
    count: number;
    label?: ReactNode;
    sx?: TypographyProps['sx'];
}

const Indicator = forwardRef<HTMLElement, IndicatorProps>(
    ({ htmlColor, count, label, sx }, ref) => (
        <Typography
            ref={ref}
            variant="subtitle2"
            htmlColor={htmlColor}
            display="flex"
            alignItems="center"
            sx={sx}
        >
            {count > 0 ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}{' '}
            {Math.abs(count)} {label}
        </Typography>
    ),
);
Indicator.displayName = 'Indicator';

import { createSelector } from 'reselect';
import type { WeekStart } from '@lingoda/dates';
import { isSameWeek } from '@lingoda/dates';
import { studentSelector } from '@lingoda/students';
import { localRoundMinuteSelector } from '@lingoda/time';
import { AppointmentStatus } from '../models/status';
import { studentPastAppointmentsSelector } from './studentPastAppointments';

export const studentPastAppointmentsFromCurrentWeekSelector = createSelector(
    localRoundMinuteSelector,
    studentSelector,
    studentPastAppointmentsSelector,
    (now, student, pastAppointments) =>
        pastAppointments.filter(
            (appointment) =>
                appointment.status === AppointmentStatus.ATTENDED &&
                isSameWeek(appointment.startDate, now, {
                    weekStartsOn: student?.preferences?.weekStart as WeekStart,
                }),
        ),
);

import { Loader } from '@lingoda/ui';
import { showApolloErrorToast } from '@lingoda/toasts';
import { useAllAchievements } from '../../hooks/useAchievements';
import { AchievementsBoardLayout } from './AchievementsBoardLayout';

export const AchievementsBoard = () => {
    const { achievements, loading } = useAllAchievements({
        onError: showApolloErrorToast,
    });

    if (loading) {
        return <Loader />;
    }

    return <AchievementsBoardLayout achievements={achievements} />;
};

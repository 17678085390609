import { type ReactNode } from 'react';
import { type BoxProps, styled } from '@lingoda/ui';
import { ContentBlock } from './ContentBlock';

interface ContentBodyProps {
    alignItems?: BoxProps['alignItems'];
    justifyContent?: BoxProps['justifyContent'];
    children?: ReactNode;
}

export const ContentBody = ({ children, alignItems, justifyContent }: ContentBodyProps) => {
    return (
        <ContentBlockStyled
            innerProps={{
                alignItems,
                justifyContent,
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            {children}
        </ContentBlockStyled>
    );
};

const ContentBlockStyled = styled(ContentBlock)(({ theme }) => {
    return {
        width: '100%',
        flex: '1 1 auto',
        padding: theme.spacing(2, 0),
        backgroundColor: theme.palette.common.white,
    };
});

import { useEffect, useRef, useState } from 'react';
import { type ClockInterval, setClockInterval } from '@lingoda/clock';

export interface UseIntervalStateOptions {
    disabled?: boolean;
}

// Calculates new state with interval manner
// Typically used when getState function is time dependent to trigger rerender
// Returns new state only when it is different from previous one
export const useIntervalState = <T>(
    getState: (previousState?: T) => T,
    clockInterval: ClockInterval,
    { disabled = false }: UseIntervalStateOptions = {},
) => {
    const [state, setState] = useState(getState);
    const getStateRef = useRef(getState);
    getStateRef.current = getState;

    useEffect(() => {
        // When disabled calculate state last time
        // When enabled again set new state immediately
        setState(getStateRef.current);

        if (disabled) {
            return;
        }

        const clearClockInterval = setClockInterval(
            () => setState(getStateRef.current),
            clockInterval,
        );

        return () => clearClockInterval();
    }, [disabled, clockInterval]);

    return [state];
};

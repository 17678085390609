import type { WeekStart } from '@lingoda/dates';
import { createDate, diffInUnits, endOfWeek } from '@lingoda/dates';

export const shouldRemindToAttendClasses = (
    userRegisterDate: string,
    anyClassAttendedInLast4Weeks: boolean,
    weekStartsOn: WeekStart,
) =>
    !anyClassAttendedInLast4Weeks &&
    diffInUnits('weeks', endOfWeek(createDate(), { weekStartsOn }), createDate(userRegisterDate)) >=
        4;

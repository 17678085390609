import { useEffect, useState } from 'react';
import { usePrevious } from '@lingoda/hooks';

const CHECKMARK_TIMEOUT = 400;

/**
 * we have to show checkmark in 400ms after progress bar becomes full
 * 300ms is from the progress-bar self animation and 100ms after it
 */
export const useShowCheckmark = (shouldShowCheckmark: boolean) => {
    const [showCheckmark, setShowCheckmark] = useState(shouldShowCheckmark);
    const [showAnimation, setShowAnimation] = useState(false);
    const checkmarkWasShownBefore = usePrevious(showCheckmark);

    useEffect(() => {
        let timeout: number | undefined;

        if (shouldShowCheckmark && checkmarkWasShownBefore === false) {
            // if checkmark wasn't show before, i.e. completion was not full
            // we should show the checkmark with animation in some time
            timeout = window.setTimeout(() => {
                setShowAnimation(true);
                setShowCheckmark(true);
            }, CHECKMARK_TIMEOUT);
        } else if (!shouldShowCheckmark) {
            setShowAnimation(false);
            setShowCheckmark(false);
        }

        return () => window.clearTimeout(timeout);
    }, [checkmarkWasShownBefore, shouldShowCheckmark]);

    return { showAnimation, showCheckmark };
};

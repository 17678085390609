import cx from 'clsx';
import { BoxPerf } from '@lingoda/ui';
import type { AppointmentStatus } from '@lingoda/graphql';
import { ClassTileActions } from './ClassTileActions';
import { ClassTileStatus } from './ClassTileStatus';
import type { CommonProps } from '../../types/props';

interface Props extends Pick<CommonProps, 'onBook' | 'onDetails' | 'isPreview'> {
    status: AppointmentStatus;
    isUpcoming?: boolean;
    isBooked?: boolean;
    isPast: boolean;
    isLessonAttended: boolean | undefined;
    isLessonLocked: boolean;
    disableActions?: boolean;
    className?: string;
    showStatus?: boolean;
}

export const ClassTileFooter = ({
    isPast,
    isBooked,
    onBook,
    onDetails,
    status,
    isUpcoming = false,
    isLessonAttended = false,
    isLessonLocked,
    disableActions,
    className,
    showStatus = true,
}: Props) => (
    <BoxPerf className={cx('display-block mt-3 min-height-20', className)}>
        {showStatus &&
            (isBooked || isPast ? (
                <ClassTileStatus isPast={isPast} status={status} isUpcoming={isUpcoming} />
            ) : (
                <ClassTileActions
                    isLessonAttended={isLessonAttended}
                    isLessonLocked={isLessonLocked}
                    onBook={onBook}
                    onDetails={onDetails}
                    disabled={disableActions}
                />
            ))}
    </BoxPerf>
);

import type { FormControlLabelProps } from '@lingoda/ui/fields/FormControlLabel';
import FormControlLabel from '@lingoda/ui/fields/FormControlLabel';
import type { RadioProps } from '@lingoda/ui/fields/Radio';
import Radio from '@lingoda/ui/fields/Radio';

type Props = Omit<FormControlLabelProps, 'control'> & { radioProps?: RadioProps };

const FormikRadio: React.FC<Props> = ({ radioProps, ...rest }) => (
    <FormControlLabel control={<Radio {...radioProps} />} {...rest} />
);

export default FormikRadio;

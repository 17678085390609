import merge from 'lodash/merge';
import { handleActionsImmutably } from '@lingoda/core';
import { addLessons } from './actions';
import { LessonFactory } from './models';
import type { Lesson } from './models';

export type State = GenericObject<Lesson>;

type LessonsAction = ReturnType<typeof addLessons>;

type Payload = LessonsAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${addLessons}`]: (state, { payload }: LessonsAction) => {
            (payload || []).forEach((lesson) => {
                state[lesson.id] = merge(state[lesson.id], LessonFactory(lesson));
            });

            return state;
        },
    },
    {},
);

import type { Date, Recurrence } from '@lingoda/dates';
import type { PurchaseType } from '@lingoda/graphql';
import type { Price } from '@lingoda/products';
import type { StudentId } from '@lingoda/students';
import { createRecordFactory } from '@lingoda/utils';

export type SubscriptionId = number;
export interface PausedSubscription extends Omit<Subscription, 'pausedAt' | 'resumesAt'> {
    pausedAt: Date;
    resumesAt: Date;
}

export enum SubscriptionStatus {
    Active = 'active',
    Inactive = 'inactive',
    Cancelled = 'cancelled',
    NoSubscription = 'no-subscription',
    Paused = 'paused',
    Suspended = 'suspended',
    Next = 'next',
}

export enum SubscriptionLoadedState {
    Loading,
    Loaded,
}

export interface Subscription {
    readonly allowPause?: boolean;
    readonly allowCancel?: boolean;
    readonly allowChange?: boolean;
    readonly expiresAt?: Date | undefined;
    readonly id: SubscriptionId;
    readonly createdAt: Date | undefined;
    readonly lastChargedAt?: Date | undefined;
    readonly months?: number;
    readonly nextChargeAt?: Date | undefined;
    readonly price?: Price;
    readonly productId?: number;
    readonly productVariantId?: number;
    readonly pausedAt?: Date | undefined;
    readonly resumesAt?: Date | undefined;
    readonly status?: SubscriptionStatus;
    readonly studentId?: StudentId;
    readonly isExpired?: boolean;
    readonly isPaymentNeeded?: boolean;
    readonly purchaseType?: PurchaseType;
    readonly isInTrial?: boolean;
    readonly title: string;
    readonly individualCredits: number;
    readonly isRecurring: boolean;
    readonly groupCredits: number;
    readonly privateCredits: number;
    readonly productType: string;
    readonly recurrenceInterval?: Recurrence;
    readonly creditsValidityInterval?: Recurrence;
}

export const SubscriptionFactory = createRecordFactory<Subscription>({
    allowPause: undefined,
    allowCancel: undefined,
    allowChange: undefined,
    expiresAt: undefined,
    id: undefined,
    createdAt: undefined,
    lastChargedAt: undefined,
    months: undefined,
    nextChargeAt: undefined,
    price: undefined,
    productId: undefined,
    productVariantId: undefined,
    pausedAt: undefined,
    resumesAt: undefined,
    status: undefined,
    studentId: undefined,
    isExpired: undefined,
    isPaymentNeeded: undefined,
    purchaseType: undefined,
    isInTrial: undefined,
    title: undefined,
    individualCredits: undefined,
    groupCredits: undefined,
    privateCredits: undefined,
    productType: undefined,
    isRecurring: undefined,
    recurrenceInterval: undefined,
    creditsValidityInterval: undefined,
});

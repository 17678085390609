import { createSelector } from 'reselect';
import { learningUnitsSelector } from '@lingoda/learning-units';
import { lessonsSelector } from '@lingoda/lessons';
import { modulesSelector } from '@lingoda/modules';
import type { Student } from '@lingoda/students';
import { studentsSelector } from '@lingoda/students';
import { teachersSelector } from '@lingoda/teachers';
import { ClassRecordExpanded } from '../models';
import { classesSelector } from './classes';
import type { Class, ClassExpanded } from '../models';

export const expandClass = (
    klass: Class,
    learningUnits: ReturnType<typeof learningUnitsSelector>,
    lessons: ReturnType<typeof lessonsSelector>,
    modules: ReturnType<typeof modulesSelector>,
    teachers: ReturnType<typeof teachersSelector>,
    students: readonly Student[],
): ClassExpanded =>
    ClassRecordExpanded({
        ...klass,
        module: modules[klass.moduleId],
        lesson: lessons.find((lesson) => lesson.id === klass.lessonId),
        teacher: teachers.find((teacher) => teacher.id === klass.teacherId),
        students: students.filter((student) => klass.studentIds?.includes(student.id)),
        learningUnit: learningUnits.find((learningUnit) =>
            klass.lessonId
                ? learningUnit.lessonIds?.includes(klass.lessonId) &&
                  learningUnit.moduleId === klass.moduleId
                : false,
        ),
    });

export const expandedClassesSelector = createSelector(
    classesSelector,
    learningUnitsSelector,
    lessonsSelector,
    modulesSelector,
    teachersSelector,
    studentsSelector,
    (classes, learningUnits, lessons, modules, teachers, students) =>
        classes.map((klass) =>
            expandClass(klass, learningUnits, lessons, modules, teachers, students),
        ),
);

import { createSelector } from 'reselect';
import { userSelector } from '@lingoda/auth';
import { addDays, createDate, isBefore } from '@lingoda/dates';

export const studentHasBeenActiveSelector = createSelector(userSelector, (user) => {
    if (!user?.lastActiveAt) {
        return true;
    }

    const lastWeekDate = addDays(createDate(), -7);

    return isBefore(lastWeekDate, createDate(user.lastActiveAt));
});

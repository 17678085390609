import { styled } from '@lingoda/ui';
import CurvedLine from './curved-line.svg?component';

export const CurvedLineStyled = styled(CurvedLine)(({ theme }) => ({
    left: '50%',
    minWidth: '1750px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    zIndex: '-1',
    ['@media (max-height: 950px) and (min-width: 991px)']: {
        top: '91px',
        transform: 'translate(-50%, 0)',
    },
    [theme.breakpoints.down(991)]: {
        height: '650px',
        left: '-424px',
        transform: ' translate(0, -50%)',
        width: '1800px',
    },
    [theme.breakpoints.down(588)]: {
        display: 'none',
    },
}));

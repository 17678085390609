import { lazy } from 'react';
import { hasFeature } from '@lingoda/feature-flags';
import { FeatureFlag } from '@lingoda/graphql';
import { type RouteConfigExtended, TabValue, studentPracticeSequencePath } from '@lingoda/router';
import { NotFoundPage } from '@lingoda/fallbacks';

const SequencePage = lazy(() => import('./page/Page/Page'));

const PracticeSequencePageRoute = () => {
    const Route = hasFeature(FeatureFlag.PracticeSequence) ? SequencePage : NotFoundPage;

    return <Route />;
};

export const practiceSequenceRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: PracticeSequencePageRoute,
        path: studentPracticeSequencePath.raw,
        label: 'Lingobites',
        config: {
            hideHeader: true,
            hideFooter: true,
            hideDixa: true,
            hideMobileBottomNavbar: true,
            tab: TabValue.Practice,
            hideNotifications: true,
        },
    },
];

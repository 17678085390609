import { TimeFormat } from '@lingoda/dates';

export const TIME_SLOT_OPTION_FORMAT = {
    [TimeFormat.Ampm]: 'h:mm',
    [TimeFormat.Iso8601]: 'HH:mm',
};

export const TIME_SLOT_VALUE_FORMAT = {
    [TimeFormat.Ampm]: 'h:mm a',
    [TimeFormat.Iso8601]: 'HH:mm',
};

import { AppointmentStatus } from '@lingoda/graphql';
import { Box, Typography } from '@lingoda/ui';
import {
    getAppointmentStatusColor,
    getAppointmentStatusIcon,
    getAppointmentStatusText,
} from '../../helpers/bookingClassElement';

interface Props {
    status: AppointmentStatus;
    isUpcoming: boolean;
    isPast: boolean;
}

export const ClassTileStatus = ({ isUpcoming, status, isPast }: Props) => {
    const displayContent = getAppointmentStatusText(status, isPast, isUpcoming);
    const displayIcon = !isPast && isUpcoming ? null : getAppointmentStatusIcon(status);
    const displayColor = getAppointmentStatusColor(status, !isPast && isUpcoming);
    const justify = !isUpcoming && AppointmentStatus.Active === status ? 'flex-start' : 'flex-end';

    return (
        <Box display="flex" width="100%" color={displayColor} justifyContent={justify}>
            <Typography variant="subtitle2">{displayContent}</Typography>
            <Typography variant="subtitle1" display="flex" alignItems="center">
                {displayIcon}
            </Typography>
        </Box>
    );
};

import { createAction, makeActionTrackable } from '@lingoda/core';
import type { StudentId } from '@lingoda/students';
import type { Agreement, Purchase } from '@lingoda/graphql';
import type { Subscription, SubscriptionId } from './models';

export const addSubscriptions = createAction<Subscription[]>('subscription/ADD_SUBSCRIPTIONS');

export const setSubscriptions = createAction<Subscription[]>('subscription/SET_SUBSCRIPTIONS');

export type ChangeSubscriptionPurchase = Pick<
    Purchase,
    'id' | 'chargeTotal' | 'type' | 'displayName' | 'discount' | 'coupons' | '__typename'
> & { withTrial?: boolean } & {
    agreements: ReadonlyArray<Pick<Agreement, 'id'>>;
};

interface ChangeSubscriptionPayload {
    purchase: ChangeSubscriptionPurchase;
    agreements?: string[];
}

export const changeSubscription = makeActionTrackable(
    createAction('subscription/CHANGE_SUBSCRIPTION', (payload: ChangeSubscriptionPayload) => ({
        payload,
    })),
);

export const confirmCancelSubscription = makeActionTrackable(
    createAction(
        'subscription/CONFIRM_CANCEL_SUBSCRIPTION',
        (subscriptionId: number, reason: string) => ({
            subscriptionId,
            reason,
        }),
    ),
);

export const confirmDeleteNextSubscription = makeActionTrackable(
    createAction('subscription/CONFIRM_DELETE_NEXT_SUBSCRIPTION'),
);

export const uncancelSubscription = makeActionTrackable(
    createAction<number>('subscription/UNCANCEL_SUBSCRIPTION'),
);

export const unpauseSubscription = makeActionTrackable(
    createAction<number>('subscription/UNPAUSE_SUBSCRIPTION'),
);

const type = 'subscription/FETCH_SUBSCRIPTIONS';
const actionCreator = (payload?: StudentId) => ({
    type,
    payload,
});

/**
 * @deprecated use `rolloverSubscriptionNow` from `@lingoda/subscriptions`
 */
export const rolloverSubscription = makeActionTrackable(
    createAction<SubscriptionId>('subscription/ROLL_OVER_SUBSCRIPTION'),
);

export const rolloverSubscriptionNow = makeActionTrackable(
    createAction('subscription/ROLL_OVER_SUBSCRIPTION_NOW'),
);

actionCreator.toString = () => type;

export const fetchSubscriptions = makeActionTrackable(actionCreator);

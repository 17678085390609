import { useMemo } from 'react';
import { getSessionValue } from '@lingoda/utils';
import { useStudentCalendarsQuery } from '@lingoda/graphql';
import { showApolloErrorToast } from '@lingoda/toasts';
import { MISSED_CLASS_CALENDAR_DIALOG_WAS_SHOWN_SESSION_KEY } from '../const';
import { shouldShowConnectCalendarDialogForMissedClass } from '../logic/shouldShowConnectCalendarDialogForMissedClass';

export const useMissedClassConnectCalendarDialog = () => {
    const { data, loading } = useStudentCalendarsQuery({
        onError: showApolloErrorToast,
    });

    const { shouldShowConnectCalendarDialog, options } = useMemo(() => {
        const calendars = data?.calendarList || [];

        const wasDialogShownInCurrentSession =
            getSessionValue(MISSED_CLASS_CALENDAR_DIALOG_WAS_SHOWN_SESSION_KEY) === true;

        return {
            shouldShowConnectCalendarDialog: shouldShowConnectCalendarDialogForMissedClass(
                calendars.map(({ status }) => status),
                wasDialogShownInCurrentSession,
            ),
            options: calendars.map(({ type }) => ({ type })),
        };
    }, [data?.calendarList]);

    return {
        shouldShowConnectCalendarDialog,
        options,
        loading,
    };
};

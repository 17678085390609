import { useSelector } from '@lingoda/hooks';
import { studentHasCreditsSelector } from '@lingoda/students';
import { isSprintSubscriptionSelector } from '@lingoda/subscriptions';
import { classesSelector } from '@lingoda/classes';

const useCanBookOnboardingClasses = () => {
    const isSprint = useSelector(isSprintSubscriptionSelector);
    const hasClasses = useSelector(classesSelector).length > 0;
    const hasCredits = useSelector(studentHasCreditsSelector);

    return canBookOnboardingClasses(isSprint, hasClasses, hasCredits);
};

export const canBookOnboardingClasses = (
    isSprint: boolean,
    hasClasses: boolean,
    hasCredits: boolean,
) => !isSprint && (hasClasses || hasCredits);

export default useCanBookOnboardingClasses;

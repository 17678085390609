import uniqueId from 'lodash/uniqueId';
import { useMemo } from 'react';
import {
    ChecklistItemSlug,
    type ExercisesLearningUnitFragment,
    useCompleteChecklistItemsMutation,
} from '@lingoda/graphql';
import { Translate, trans } from '@lingoda/i18n';
import { chromeBrowserPath, enableCookiesPath } from '@lingoda/router';
import { Box, ProgressBarWithLabel, Stack, Tooltip, Typography } from '@lingoda/ui';
import ExerciseIcon from '@lingoda/ui/icons/ExerciseIcon/ExerciseIcon';
import { ExerciseListItemLink } from './ExerciseListItemLink';

interface Props {
    exercise: ExercisesLearningUnitFragment['exercise'];
    isOrientation?: boolean;
}

export const ExerciseContent = ({ exercise, isOrientation }: Props) => {
    const a11y = useMemo(
        () => ({
            label: uniqueId('label-'),
        }),
        [],
    );

    const [completeChecklistItems] = useCompleteChecklistItemsMutation({
        variables: { slugs: [ChecklistItemSlug.Practice, ChecklistItemSlug.StartPracticing] },
    });

    const handleClickExercise = () => {
        void completeChecklistItems();
    };

    return (
        <ExerciseListItemLink
            padding={2}
            to={exercise?.link || ''}
            target="_blank"
            rel="noopener noreferrer"
            aria-labelledby={a11y.label}
            onClick={handleClickExercise}
        >
            <Tooltip
                arrow
                disableInteractive={false}
                title={
                    <Translate
                        id="exercises-tooltip"
                        domain="student-common"
                        params={{
                            chromeUrl: chromeBrowserPath(),
                            cookiesUrl: enableCookiesPath(),
                        }}
                    />
                }
            >
                <Stack flexDirection="row" gap={2} alignItems="center">
                    <ExerciseIcon />
                    <Box flex={1}>
                        <Stack direction="row">
                            <Typography id={a11y.label} variant="subtitle1">
                                {isOrientation
                                    ? trans('orientation-course-link', {}, 'student-common')
                                    : trans('chapter-exercises', {}, 'student-common')}
                            </Typography>
                        </Stack>
                        <ProgressBarWithLabel
                            value={(exercise?.score ?? 0) * 100}
                            sxBar={{ height: 8 }}
                        />
                    </Box>
                </Stack>
            </Tooltip>
        </ExerciseListItemLink>
    );
};

import { type Event } from '@lingoda/analytics';

export const TIMES_SELECTED_ON_TIME_FILTER = 'filterTimes';
export const RANGE_SELECTED_ON_TIME_FILTER = 'filterTimeRange';
export const DATE_SELECTED_ON_MULTIPLE_DAY_FILTER = 'filterDates';

export interface BookClassEvent extends Event {
    [TIMES_SELECTED_ON_TIME_FILTER]?: string;
    [RANGE_SELECTED_ON_TIME_FILTER]?: string;
    [DATE_SELECTED_ON_MULTIPLE_DAY_FILTER]?: string;
}

export const getRangeSelectedLabel = (timeRange: number) => rangeLabel[timeRange] || '';

const rangeLabel: Record<number, string> = {
    0: 'exact time',
    1: '+- 1 day',
    2: '+- 2 days',
};

export const getWeekdayLabel = (weekday: number) => weekdayLabel[weekday];

const weekdayLabel: Record<number, string> = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
};

import { formatTimeRemaining } from '@lingoda/dates';
import { type ClockInterval } from '@lingoda/clock';
import { useIntervalStateAutoDisabled } from '../useIntervalStateAutoDisabled';

export interface UseTimeRemainingAutoDisabledArgs {
    formatter?: (date: Date) => string;
    getDisabled?: () => boolean;
    clockInterval?: ClockInterval;
}

export const useTimeRemainingAutoDisabled = (
    toDate: Date,
    {
        clockInterval = 'minute',
        formatter = formatTimeRemaining,
        getDisabled,
    }: UseTimeRemainingAutoDisabledArgs = {},
) => {
    const [timeRemaining, disabled] = useIntervalStateAutoDisabled(
        () => formatter(toDate),
        clockInterval,
        { getDisabled },
    );

    return [timeRemaining, disabled] as const;
};

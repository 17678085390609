import {
    Box,
    ConfettiBackdrop,
    DialogConfirm,
    Divider,
    Stack,
    Typography,
    colorsPalette,
    useResolution,
} from '@lingoda/ui';
import { trans, transChoice } from '@lingoda/i18n';
import { type DialogBaseProps } from '@lingoda/dialogs';
import { useSelector } from '@lingoda/hooks';
import { sectionLocaleSelector } from '@lingoda/auth';
import { RandomCongratsText } from '../RandomCongratsText';
import Stars from './stars.svg?component';
import Flag from './flag.svg?component';
import Video from './video.svg?component';
import type { PropsWithChildren } from 'react';

export interface GoalAchievedCelebrationDialogProps extends DialogBaseProps {
    firstName: string | undefined;
    goal: number | null;
    attendance: number;
    weekDuration: string;
}

export const GoalAchievedCelebrationDialog = ({
    hideDialog,
    firstName,
    goal,
    attendance,
    weekDuration,
}: GoalAchievedCelebrationDialogProps) => {
    const isMobile = useResolution('phone');
    const locale = useSelector(sectionLocaleSelector);

    const handleConfirm = () => {
        hideDialog();
    };

    return (
        <DialogConfirm
            isOpen
            noHeader
            onConfirm={handleConfirm}
            confirmLabel={trans('btn-acknowledge', {}, 'public-common')}
            actionsProps={{ justifyContent: 'center', display: 'flex' }}
            components={{ Backdrop: ConfettiBackdrop }}
        >
            <Stack spacing={3} mt={4} alignItems="center">
                <Stars />
                <RandomCongratsText firstName={firstName} congratsMsgLocale={locale}>
                    <Box>
                        {trans('weekly-progress-dialog-you-achieved-goal', {}, 'student-common')}
                    </Box>
                </RandomCongratsText>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    sx={{
                        borderRadius: '16px',
                        border: `1px solid ${colorsPalette.blackAlpha[20]}`,
                        width: '100%',
                    }}
                >
                    <Tile>
                        <Flag />
                        <TileContent
                            title={transChoice(
                                'weekly-progress-dialog-classes-goal',
                                goal ?? 0,
                                { goal: goal ?? 0 },
                                'student-common',
                            )}
                            subtitle={trans(
                                'weekly-progress-dialog-your-goal',
                                {},
                                'student-common',
                            )}
                        />
                    </Tile>

                    <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
                    <Tile>
                        <Video />
                        <TileContent
                            title={transChoice(
                                'weekly-progress-dialog-classes-attended',
                                attendance,
                                { attendance },
                                'student-common',
                            )}
                            subtitle={weekDuration}
                        />
                    </Tile>
                </Stack>
            </Stack>
        </DialogConfirm>
    );
};

const Tile = ({ children }: PropsWithChildren<unknown>) => {
    const isMobile = useResolution('phone');

    return (
        <Stack
            p={3}
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent={isMobile ? 'center' : undefined}
        >
            {children}
        </Stack>
    );
};

const TileContent = ({ title, subtitle }: { title: string; subtitle: string }) => {
    const isMobile = useResolution('phone');

    return (
        <Stack spacing={0.5} direction="column">
            <Typography
                variant="h4"
                htmlColor={colorsPalette.blackSolid[100]}
                minWidth={isMobile ? '180px' : undefined}
            >
                {title}
            </Typography>
            <Typography variant="body3" htmlColor={colorsPalette.blackAlpha[70]}>
                {subtitle}
            </Typography>
        </Stack>
    );
};

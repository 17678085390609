import { useCanImmediateCancel, useIsRefundable } from '@lingoda/classes';
import { createDate, isSameOrAfterToday } from '@lingoda/dates';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useDispatch } from '@lingoda/hooks';
import { useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';
import { unifyNullable } from '@lingoda/utils';
import { getBookClassEvent, useBooking } from '@lingoda/booking';
import { Layout } from './Layout';
import { type PortalContainers } from './types';
import { ClassDetailsTilePaperStyled } from './components/ClassDetailsTilePaperStyled';
import type { ExistingClassData } from '../../types';

interface ExistingClassTileProps {
    classItem: ExistingClassData;
    onBooked?: (classId: string) => void;
    onCancelConfirm?: () => void;
    portalContainers?: PortalContainers;
}

export const ExistingClassDetailsTile = ({
    classItem,
    onBooked,
    onCancelConfirm,
    portalContainers,
}: ExistingClassTileProps) => {
    const dispatch = useDispatch();
    const addEvent = useUiEvent();
    const [bookDisabled, bookClass] = useBooking();
    const {
        module,
        startDate: startDateRaw,
        teacher,
        type,
        uid: classId,
        availableSeats,
    } = classItem;
    const canImmediateCancel = useCanImmediateCancel(classId);
    const startDate = createDate(startDateRaw);
    const isClassBookable =
        !bookDisabled &&
        (availableSeats === undefined || (isSameOrAfterToday(startDate) && availableSeats > 0));

    const teacherId = unifyNullable(teacher?.id);
    const isRefundable = useIsRefundable({ startDate, teacherId });

    const handleBookClick = () => {
        void addEvent(actionClick('Book class'), getBookClassEvent());

        bookClass({
            id: classId,
            moduleId: module.id,
            startDate,
            type,
        })
            .then(({ classId }) => onBooked?.(classId))
            .catch(() => undefined);
    };

    const handleCancelClick = () => {
        void addEvent(actionClick('Cancel class'));
        dispatch(
            showDialog(DialogComponent.CancelClass, {
                classType: type,
                classId,
                isImmediate: canImmediateCancel,
                isRefundable,
                onConfirm: onCancelConfirm,
            }),
        );
    };

    return (
        <ClassDetailsTilePaperStyled variant="outlined" data-cy="Class details tile">
            <Layout
                classItem={classItem}
                isClassBookable={isClassBookable}
                onBookClick={handleBookClick}
                onCancelClick={handleCancelClick}
                portalContainers={portalContainers}
            />
        </ClassDetailsTilePaperStyled>
    );
};

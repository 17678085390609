import { Typography, colorsPalette, styled } from '@lingoda/ui';
import { createDate, formatDistance, toDate } from '@lingoda/dates';
import { useIntervalDate } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';

interface LastReviewProps {
    date: string | Date;
}

export const LastReview = ({ date }: LastReviewProps) => {
    useIntervalDate('minute');

    return (
        <LastReviewTypography>
            {trans(
                'last-reviewed',
                { when: formatDistance(toDate(date), createDate()) },
                'quiz-practice-dashboard',
            )}
        </LastReviewTypography>
    );
};

const LastReviewTypography = styled(Typography)(({ theme }) => ({
    color: colorsPalette.blackAlpha[70],
    ...theme.typography.body2,
    [theme.breakpoints.up('md')]: {
        ...theme.typography.body1,
    },
}));

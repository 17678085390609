import { trans } from '@lingoda/i18n';
import { SlidePopupSuccessLayout } from '@lingoda/ui';

export const CompleteFormState = () => {
    return (
        <SlidePopupSuccessLayout
            sx={{ minHeight: 92 }}
            message={trans('feedback-submitted', {}, 'student-common')}
        />
    );
};

import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { Box } from '@lingoda/ui';

export const LottieIcon = (props: { iconKey: string }) => {
    const [animation, setAnimation] = useState<unknown>(null);

    useEffect(() => {
        const animationFileName = getAnimationFileName(props.iconKey);

        if (!animationFileName) {
            return;
        }

        void import(`./animations/${animationFileName}.json`).then((newAnimation) => {
            setAnimation(newAnimation.default);
        });
    }, [props.iconKey]);

    if (!animation) {
        return null;
    }

    return (
        <Box sx={{ position: 'relative', width: 80 }}>
            <Box sx={{ position: 'absolute', top: '-10px' }}>
                <Lottie animationData={animation} loop={true} />
            </Box>
        </Box>
    );
};

const getAnimationFileName = (iconKey: string) => {
    switch (iconKey) {
        case 'tadaCelebration':
            return 'tada-celebration';
        case 'binoculars':
            return 'binoculars';
        case 'fire':
            return 'fire';
        case 'okhand':
            return 'okhand';
        default:
            return undefined;
    }
};

import { useMemo } from 'react';
import styled from 'styled-components';
import { breakpoints, compose, style } from '@mui/system';
import { default as MuiGrid } from '@mui/material/Grid';
import type { Property } from 'csstype';
import type { ComponentType } from 'react';
import type { GridProps as MuiGridProps } from '@mui/material/Grid';

export interface GridProps extends MuiGridProps {
    /**
     * @deprecated Use `justifyContent` instead, the prop was renamed
     */
    justify?: Property.JustifyContent;
    textAlign?: Property.TextAlign;
    spacing?: number;
}

const flex = style({
    prop: 'flex',
});

const order = style({
    prop: 'order',
});

const textAlign = style({
    prop: 'textAlign',
});

const GridStyled = styled(MuiGrid)(
    breakpoints(compose(flex, order, textAlign)),
) as ComponentType<GridProps>;

/**
 * The default layout of grids in MuiV5 was changed, so we have to add custom values depending on spacing for make it aligned with v4 Grid
 * https://github.com/mui/material-ui/issues/29266.
 * example of an issue:
 * https://github.com/mui/material-ui/issues/29266#issuecomment-954700658
 */
const getV4DefaultGridSpacing = (spacing: number, isContainer: boolean | undefined) =>
    isContainer && spacing !== 0
        ? {
              m: -0.5 * spacing,
              '> .MuiGrid-item': {
                  p: 0.5 * spacing,
              },
          }
        : {};

/**
 * @deprecated use import { Grid } from '@lingoda/ui/layout/GridNext' instead
 */
export const Grid = ({ justify, children, ...props }: GridProps) => {
    const spacing = props.spacing ?? 0;

    const v4DefaultSpacing = useMemo(
        () => getV4DefaultGridSpacing(spacing, props.container),
        [props.container, spacing],
    );

    const customSx = useMemo(
        () => ({
            ...v4DefaultSpacing,
            ...(props.sx ?? {}),
        }),
        [v4DefaultSpacing, props.sx],
    );

    return (
        <GridStyled justifyContent={justify} {...props} sx={customSx}>
            {children}
        </GridStyled>
    );
};

import { isNewCoursePageEnabled } from '@lingoda/feature-flags';
import { ORIENTATION_CHAPTER_NUMBER } from './const';

export const getChaptersCount = (chapters: { chapter: number }[]) => {
    if (isNewCoursePageEnabled()) {
        return chapters.filter(({ chapter }) => chapter !== ORIENTATION_CHAPTER_NUMBER).length;
    }

    return chapters.length;
};

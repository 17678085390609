import { type KeyboardEventHandler, type MouseEventHandler, useRef } from 'react';
import {
    Box,
    IconButton,
    Typography,
    type TypographyProps,
    colorsPalette,
    styled,
} from '@lingoda/ui';
import CircularProgress from '@lingoda/ui/feedback/CircularProgress';
import { PlayAudioIcon24 } from '@lingoda/ui/icons/PlayAudioIcon';
import { type PlayAudioIconAnimation } from '@lingoda/ui/icons/PlayAudioIcon';
import { useDebouncedState } from '@lingoda/hooks';
import { useAudioTrackContext } from '../context/Audio';

export interface TypographyAudioProps {
    title: string;
    audioUrl: string;
    controlPosition?: 'start' | 'end';
    variant?: TypographyProps['variant'];
    onClick?: () => void;
    sx?: TypographyProps['sx'];
}

export const TypographyAudio = ({
    variant,
    controlPosition = 'end',
    audioUrl,
    title,
    onClick,
    sx,
}: TypographyAudioProps) => {
    const { play, isLoading } = useAudioTrackContext(audioUrl);

    // Improve UX: do not show loading, when it's fast or audio brought from cache
    const isLoadingDebounced = useDebouncedState(isLoading, 800);

    const handleClick: MouseEventHandler = (event) => {
        event.stopPropagation();
        iconAnimation.current?.play();
        play().catch(console.error);
        onClick?.();
    };

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (event.code === 'Space') {
            event.stopPropagation();
        }
    };

    const iconAnimation = useRef<PlayAudioIconAnimation>(null);

    const control = (
        <IconButtonStyled position={controlPosition} onKeyDown={handleKeyDown}>
            {isLoadingDebounced ? <Loading /> : <PlayAudioIcon24 animationRef={iconAnimation} />}
        </IconButtonStyled>
    );

    return (
        <TypographyStyled variant={variant} onClick={isLoading ? undefined : handleClick} sx={sx}>
            {controlPosition === 'start' && control}
            {title}
            {controlPosition === 'end' && control}
        </TypographyStyled>
    );
};

const TypographyStyled = styled(Typography)(() => ({
    cursor: 'pointer',

    ':hover': {
        '.MuiIconButton-root': {
            color: colorsPalette.primarySolid[100],
        },
    },
}));

const IconButtonStyled = styled(IconButton, { shouldForwardProp: (name) => name !== 'position' })<{
    position: 'start' | 'end';
}>(({ theme, position }) => ({
    marginTop: theme.spacing(-0.25),
    padding: theme.spacing(0.5),
    color: colorsPalette.blackAlpha[70],
    ...(position === 'start' && { marginRight: theme.spacing(0.5) }),
    ...(position === 'end' && { marginLeft: theme.spacing(0.5) }),
}));

const Loading = () => {
    return (
        <Box display="inline-flex" p={0.5}>
            <CircularProgress color="inherit" size={16} />
        </Box>
    );
};

import { createRecordFactory } from '@lingoda/utils';

export enum ModuleLevel {
    beginner,
    intermediate,
    advanced,
}

export interface Module {
    readonly id: number;
    readonly name: string;
    readonly longName: string;
    readonly code: string;
    readonly description: string;
    readonly position: number;
    readonly cefrLevelName: string;
    readonly learningUnitIds: number[];
    readonly completedClasses: number;
    readonly totalClasses: number;
}

export const moduleFactory = createRecordFactory<Module>({
    id: undefined,
    name: undefined,
    longName: undefined,
    code: undefined,
    description: undefined,
    position: undefined,
    cefrLevelName: undefined,
    learningUnitIds: undefined,
    completedClasses: undefined,
    totalClasses: undefined,
});

export interface StartingModule {
    readonly id: string;
    readonly code: string;
    readonly title: string;
    readonly description: string;
    readonly position: number;
    readonly moduleId: number;
    readonly modules: Module[];
}

export const startingModuleFactory = createRecordFactory<StartingModule>({
    id: undefined,
    code: undefined,
    title: undefined,
    description: undefined,
    position: undefined,
    moduleId: undefined,
    modules: undefined,
});

export enum LoadedStatus {
    Loading,
    Loaded,
}

export const OPEN_DISCUSSION_MODULE_ID = 49;

import { forwardRef } from 'react';
import { default as MuiSlide } from '@mui/material/Slide';
import type { SlideProps } from '@mui/material/Slide';
export type { SlideProps } from '@mui/material/Slide';

const Slide = forwardRef<unknown, SlideProps>(function SlideWrapper(props, ref) {
    return <MuiSlide direction="up" ref={ref} {...props} />;
});

Slide.displayName = 'Slide';

export default Slide;

import { Add, Remove } from '@lingoda/ui/icons';
import { Box, Button, LingodaClassIcon, Typography } from '@lingoda/ui';
import { styled, theme } from '@lingoda/ui/theme';
import { ClassType } from '@lingoda/graphql';
import { trans, transChoice } from '@lingoda/i18n';
import { capitalize } from '@lingoda/utils';

const CounterButton = styled(Button)(({ theme: { breakpoints } }) => ({
    minWidth: 0,
    padding: 0,
    height: '2.5rem',
    width: '2.5rem',
    [breakpoints.up('sm')]: {
        height: '3rem',
        width: '3rem',
    },
}));

interface Props {
    type: ClassType;
    disabled?: boolean;
    credits: number;
    onCreditsChange: (credits: number) => void;
}

const MIN_CREDITS = 0;
const MAX_CREDITS = 50;
const MINIMUM_GROUP_CREDITS = 5;

const CreditsInput = ({ type, credits, onCreditsChange, disabled }: Props) => {
    const isIncreaseButtonDisabled = disabled || credits >= MAX_CREDITS;
    const isDecreaseButtonDisabled = disabled || credits <= MIN_CREDITS;

    const creditsToAdd = type === ClassType.Group && credits === 0 ? MINIMUM_GROUP_CREDITS : 1;

    const handleIncreaseCredits = () => {
        if (!isIncreaseButtonDisabled) {
            onCreditsChange(credits + creditsToAdd);
        }
    };

    const creditsToRemove =
        type === ClassType.Group && credits == MINIMUM_GROUP_CREDITS ? MINIMUM_GROUP_CREDITS : 1;

    const handleDecreaseCredits = () => {
        if (!isDecreaseButtonDisabled) {
            onCreditsChange(credits - creditsToRemove);
        }
    };

    return (
        <Box marginY={1} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={[0.5, 1]} mr={[0.5, 1]}>
                <LingodaClassIcon type={type} />
                <Typography
                    variant="body1"
                    fontSize={[theme.typography.body2.fontSize, theme.typography.body1.fontSize]}
                >
                    {type === ClassType.Group
                        ? trans('group-class-credits', {}, 'student-store')
                        : trans('private-class-credits', {}, 'student-store')}
                </Typography>
            </Box>
            <Box display="flex" justifyItems="center" alignItems="center">
                <CounterButton
                    disabled={isDecreaseButtonDisabled}
                    onClick={handleDecreaseCredits}
                    color="primary"
                    variant="outlined"
                    aria-label={getCounterButtonLabel(type, 'remove', creditsToRemove)}
                >
                    <Remove />
                </CounterButton>
                <Box width="3.5ch" fontSize={['1.1rem', '1.5rem']} textAlign="center">
                    {credits}
                </Box>
                <CounterButton
                    color="primary"
                    variant="outlined"
                    disabled={isIncreaseButtonDisabled}
                    onClick={handleIncreaseCredits}
                    aria-label={getCounterButtonLabel(type, 'add', creditsToAdd)}
                >
                    <Add />
                </CounterButton>
            </Box>
        </Box>
    );
};

const getCounterButtonLabel = (
    type: ClassType,
    action: 'add' | 'remove',
    credits: number,
): string | undefined => {
    const actionTrans =
        action === 'add'
            ? trans('add', {}, 'student-common')
            : trans('remove', {}, 'student-common');

    // Translate: 'group-class-credits' | trans: {}: 'public-common'
    // Translate: 'private-class-credits' | trans: {}: 'public-common'
    const creditTypeTrans = transChoice(
        /*@ignore*/ `${type}-class-credits`,
        credits,
        {},
        'public-common',
    );

    return `${capitalize(actionTrans)} ${credits} ${creditTypeTrans}`;
};

export default CreditsInput;

import { useState } from 'react';
import { trans } from '@lingoda/i18n';
import { Box, DialogConfirm, Loader, useResolution } from '@lingoda/ui';
import { type FocusedDate } from '@lingoda/ui/fields/DateRangePicker';
import { useSchedulePause } from '../../hooks';
import { type RangeState, type StepCommonProps } from '../../types';
import { CalendarStepContent } from './CalendarStepContent';

interface Props extends StepCommonProps {
    range: RangeState;
    scheduledClassesDates?: Date[];
    numberOfClassesInPausePeriod: number;
    onRangeChange: (range: RangeState) => void;
    onContinue: () => void;
    onDismiss: () => void;
    onPauseSuccess?: () => void;
}

const CalendarStep = ({
    range,
    onRangeChange,
    scheduledClassesDates,
    numberOfClassesInPausePeriod,
    isOpen,
    closeDialog,
    onContinue,
    onDismiss,
    onPauseSuccess,
}: Props) => {
    const isMobile = useResolution('phone');
    const isClassInPausePeriod = !!numberOfClassesInPausePeriod;
    const [schedulePause, { loading }] = useSchedulePause();

    const [focus, setFocus] = useState<FocusedDate>();
    const isEndDateFocused = focus === 'endDate';

    const isAlertVisible = focus === 'startDate' && isClassInPausePeriod;

    const confirmButtonStatus = loading
        ? 'loading'
        : range && scheduledClassesDates
          ? 'enabled'
          : 'disabled';

    const handleConfirm = async () => {
        if (!range) {
            return;
        }

        if (!isClassInPausePeriod) {
            try {
                await schedulePause({ startDate: range.startDate, endDate: range.endDate });
                onPauseSuccess?.();
                closeDialog();
            } catch {}

            return;
        }

        onContinue();
    };

    return (
        <DialogConfirm
            isOpen={isOpen}
            title={trans('subscription-pause-select-period', {}, 'student-common')}
            dismissLabel={trans('btn-cancel', {}, 'public-common')}
            onDismiss={onDismiss}
            confirmLabel={
                isClassInPausePeriod
                    ? trans('continue', {}, 'student-onboarding')
                    : trans('subscription-pause-confirm-pause', {}, 'student-common')
            }
            onConfirm={handleConfirm}
            onClose={closeDialog}
            maxWidth="md"
            confirmStatus={confirmButtonStatus}
            scrollable={isMobile}
        >
            <Box maxWidth={584}>
                {scheduledClassesDates ? (
                    <CalendarStepContent
                        range={range}
                        onRangeChange={onRangeChange}
                        numberOfClassesInPausePeriod={numberOfClassesInPausePeriod}
                        scheduledClassesDates={scheduledClassesDates}
                        isAlertVisible={isAlertVisible}
                        isEndDateFocused={isEndDateFocused}
                        setFocus={setFocus}
                    />
                ) : (
                    <Loader minHeight={0} />
                )}
            </Box>
        </DialogConfirm>
    );
};

export default CalendarStep;

import {
    FlagEnglishIcon24,
    FlagFrenchIcon24,
    FlagGermanIcon24,
    FlagItalianIcon24,
    FlagRussianIcon24,
    FlagSpanishIcon24,
} from '@lingoda/ui/icons/lingodaIcons';
import { FlagWrapper } from './FlagWrapper';

const getIconFromLocale = (locale: string) => {
    switch (locale) {
        case 'de':
            return FlagGermanIcon24;
        case 'en':
            return FlagEnglishIcon24;
        case 'es':
            return FlagSpanishIcon24;
        case 'fr':
            return FlagFrenchIcon24;
        case 'ru':
            return FlagRussianIcon24;
        case 'it':
            return FlagItalianIcon24;
        default:
            return FlagEnglishIcon24;
    }
};

interface Props {
    locale: string;
    size?: 'small' | 'medium';
}

export const LocaleFlag = ({ locale, size }: Props) => {
    const Icon = getIconFromLocale(locale);

    return (
        <FlagWrapper>
            <Icon fontSize={size} />
        </FlagWrapper>
    );
};

import { type ReactNode } from 'react';
import { trans } from '@lingoda/i18n';
import { useResolution } from '../../../hooks';
import { Box } from '../../../layout/Box';
import { Grid } from '../../../layout/GridNext';
import { Button, GradientButton } from '../../../buttons';
import type { GridProps } from '../../../layout/GridNext';

export type ConfirmStatus = 'enabled' | 'disabled' | 'loading';
export type ConfirmVariant = 'primary' | 'gradient' | 'alert';

export type DismissVariant = 'text' | 'outlined';

export interface ConfirmButtonProps {
    dataCy: string;
    onConfirm: () => void;
}

export interface Props {
    confirmLabel?: string;
    dismissLabel?: string;
    confirmStatus?: ConfirmStatus;
    confirmVariant?: ConfirmVariant;
    dismissVariant?: DismissVariant;
    renderConfirmButton?: (props: ConfirmButtonProps) => ReactNode;
    onConfirm: () => void;
    onDismiss?: () => void;
    layoutProps?: GridProps;
}

const DialogActions = ({
    confirmLabel = trans('btn-ok', {}, 'public-common'),
    dismissLabel = trans('btn-close', {}, 'public-common'),
    confirmStatus,
    confirmVariant = 'primary',
    dismissVariant = 'text',
    renderConfirmButton,
    onConfirm,
    onDismiss,
    layoutProps,
}: Props) => {
    const isMobile = useResolution('phone');

    const ConfirmButton = confirmVariant === 'gradient' ? GradientButton : Button;

    const confirmButton = renderConfirmButton?.({ dataCy: 'dialog-confirm-btn', onConfirm });

    return (
        <Box pt={2} pb={{ xs: 3, sm: 4 }} px={{ xs: 3, sm: 4 }}>
            <Grid container justifyContent="flex-end" spacing={isMobile ? 2 : 3} {...layoutProps}>
                <Grid xs={12} sm="auto" order={{ sm: 1 }}>
                    {confirmButton || (
                        <ConfirmButton
                            variant="contained"
                            color={confirmVariant === 'alert' ? 'error' : 'primary'}
                            data-cy="dialog-confirm-btn"
                            disabled={confirmStatus === 'disabled'}
                            fullWidth
                            onClick={onConfirm}
                            loading={confirmStatus === 'loading'}
                        >
                            {confirmLabel}
                        </ConfirmButton>
                    )}
                </Grid>
                {onDismiss && (
                    <Grid xs={12} sm="auto">
                        <Button
                            variant={dismissVariant}
                            data-cy="dialog-dismiss-btn"
                            color="primary"
                            fullWidth
                            onClick={onDismiss}
                            disabled={confirmStatus === 'loading'}
                        >
                            {dismissLabel}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default DialogActions;

import 'regenerator-runtime/runtime';
import { polyfillTimezones, shouldApplyPolyfillTimezone } from '@lingoda/i18n';
import { isRequirePolyfillTimezonesTest } from '@lingoda/feature-flags';
import { getLang, toUrlLang } from '@lingoda/env';

const initApplication = async () => {
    // setting html lang tag
    const lang = getLang();
    const polyfillLang = toUrlLang(lang);
    window.document.documentElement.lang = lang;

    await Promise.all([
        ...(shouldApplyPolyfillTimezone() || isRequirePolyfillTimezonesTest()
            ? [polyfillTimezones(polyfillLang)]
            : []),
        import(`./locale/${lang}`),
    ]);

    // Load main file only after dependencies are loaded and app is ready to start.
    // Eager means it's not creating a separate async chunk but instead including it in current chunk.
    await import(/* webpackMode: "eager" */ './apps/student');
};

(function () {
    void initApplication();
})();

import { type ClassDetailsTileTeacherFragment, type Maybe } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { studentTeacherPath } from '@lingoda/router';
import { AvatarFallback, Box, Grid, Link, Stack, Typography, colorsPalette } from '@lingoda/ui';

const getTitle = (isGroupClass: boolean, teacher?: Maybe<ClassDetailsTileTeacherFragment>) => {
    if (!teacher) {
        return isGroupClass
            ? trans('group-class', {}, 'student-common')
            : trans('private-class', {}, 'student-common');
    }

    return isGroupClass
        ? trans('group-class-by', { name: teacher?.firstName || '' }, 'student-booking')
        : trans('private-class-by', { name: teacher?.firstName || '' }, 'student-booking');
};

interface Props {
    isGroupClass?: boolean;
    teacher?: Maybe<ClassDetailsTileTeacherFragment>;
}

const Title = ({ isGroupClass = true, teacher }: Props) => (
    <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
                <Box flexGrow={1}>
                    <Typography variant="h3">{getTitle(isGroupClass, teacher)}</Typography>
                </Box>
                {!teacher && (
                    <Box flexGrow={1} mt={1}>
                        <Typography variant="body2" htmlColor={colorsPalette.blackAlpha[70]}>
                            {trans('teacher-will-be-assigned', {}, 'student-common')}
                        </Typography>
                    </Box>
                )}
            </Stack>

            <Box flexShrink={0}>
                {teacher ? (
                    <Link to={studentTeacherPath({ uid: teacher.uid })}>
                        <AvatarFallback
                            src={teacher.photo?.urls.medium}
                            alt={teacher.firstName}
                            size={{ xs: 48, sm: 56 }}
                        />
                    </Link>
                ) : (
                    <AvatarFallback size={{ xs: 48, sm: 56 }} />
                )}
            </Box>
        </Stack>
    </Grid>
);

export default Title;

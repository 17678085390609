import '@formatjs/intl-pluralrules/polyfill';
import type { AcceptedLang } from '@lingoda/env';

export const polyfillLocale = (locale: AcceptedLang) => {
    if (!Intl.PluralRules) {
        import('@formatjs/intl-pluralrules/polyfill');
        mapLocalToPluralRulesDataImport(locale).catch(console.error);
    }

    if (!Intl.RelativeTimeFormat) {
        import('@formatjs/intl-relativetimeformat/polyfill');
        mapLocalToRelativeTimeFormatDataImport(locale).catch(console.error);
    }
};

function mapLocalToPluralRulesDataImport(locale: AcceptedLang) {
    switch (locale) {
        case 'en':
            return import('@formatjs/intl-pluralrules/locale-data/en');
        case 'de':
            return import('@formatjs/intl-pluralrules/locale-data/de');
        case 'ru':
            return import('@formatjs/intl-pluralrules/locale-data/ru');
        case 'fr':
            return import('@formatjs/intl-pluralrules/locale-data/fr');
        case 'es':
            return import('@formatjs/intl-pluralrules/locale-data/es');
        case 'it':
            return import('@formatjs/intl-pluralrules/locale-data/it');
        case 'pl':
            return import('@formatjs/intl-pluralrules/locale-data/pl');
        case 'tr':
            return import('@formatjs/intl-pluralrules/locale-data/tr');
        case 'pt_BR':
            return import('@formatjs/intl-pluralrules/locale-data/pt');
        default:
            throw new Error(`Unsupported locale: ${locale}`);
    }
}

function mapLocalToRelativeTimeFormatDataImport(locale: AcceptedLang) {
    switch (locale) {
        case 'en':
            return import('@formatjs/intl-relativetimeformat/locale-data/en');
        case 'de':
            return import('@formatjs/intl-relativetimeformat/locale-data/de');
        case 'ru':
            return import('@formatjs/intl-relativetimeformat/locale-data/ru');
        case 'fr':
            return import('@formatjs/intl-relativetimeformat/locale-data/fr');
        case 'es':
            return import('@formatjs/intl-relativetimeformat/locale-data/es');
        case 'it':
            return import('@formatjs/intl-relativetimeformat/locale-data/it');
        case 'pl':
            return import('@formatjs/intl-relativetimeformat/locale-data/pl');
        case 'tr':
            return import('@formatjs/intl-relativetimeformat/locale-data/tr');
        case 'pt_BR':
            return import('@formatjs/intl-relativetimeformat/locale-data/pt');
        default:
            assertExhaustedSwitch(locale);
            throw new Error(`Unsupported locale: ${locale}`);
    }
}

const assertExhaustedSwitch = (switchCase: never) => {
    console.error(new Error(`Unhandled switch case: ${JSON.stringify(switchCase)}`));
};

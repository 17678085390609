import { Paper, styled } from '@lingoda/ui';
import { useParams } from '@lingoda/router';
import { useActivityChallengeQuery } from '../../mocks';
import { LeaderboardTable } from './LeaderboardTable';
import { LeaderboardTableSkeleton } from './LeaderboardTableSkeleton';

export const ChallengeLeaderboard = () => {
    const { challengeName } = useParams<{ challengeName: string }>();
    const { data } = useActivityChallengeQuery(challengeName);
    const leaderboardAvailable = !!data.activityChallenge.currentParticipant;
    const rows = data.activityChallenge.leaderboard.map((participant) => ({
        rank: participant.rank,
        avatar: participant.student.photo.urls.thumbnail,
        name: participant.student.shortName,
        completedActivities: participant.completedActivities,
        selected: participant.student.id === data.activityChallenge.currentParticipant?.student.id,
    }));

    return (
        <PaperStyled elevation={1}>
            {leaderboardAvailable ? <LeaderboardTable rows={rows} /> : <LeaderboardTableSkeleton />}
        </PaperStyled>
    );
};

const PaperStyled = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
}));

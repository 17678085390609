import { TrackingContext, withSnackbarAdapter } from '@lingoda/ui';
import { type Achievement } from '@lingoda/graphql';
import { AchievementAlert } from './AchievementAlert';

declare module 'notistack' {
    interface VariantOverrides {
        achievement: CustomProps;
    }
}

type CustomProps = {
    achievement: Achievement;
};

export const AchievementSnackbarAlert = withSnackbarAdapter<CustomProps>(
    ({ message, achievement, close }) => {
        return (
            <TrackingContext section="Achievement Alert">
                <AchievementAlert
                    variant="toast"
                    title={message}
                    achievement={achievement}
                    onClose={close}
                />
            </TrackingContext>
        );
    },
);

import { createSelectorCreator, defaultMemoize } from 'reselect';
import type { Date } from '@lingoda/dates';
import { getMinutes, isSameMinute, set } from '@lingoda/dates';
import { timeSelector } from './state';

const createEqualMomentMinuteSelector = createSelectorCreator(
    defaultMemoize,
    (a: unknown, b: unknown) => isSameMinute(b as Date, a as Date),
);

export const selectorFactory = () =>
    createEqualMomentMinuteSelector(timeSelector as () => Date, (now) =>
        set({ minutes: getMinutes(now), seconds: 0, milliseconds: 0 }, now),
    );

export default selectorFactory();

import { ClassType } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';

export const buildClassSubtitle = (type: string | undefined, teacherFirstName?: string) => {
    switch (type) {
        case ClassType.Trial:
            return teacherFirstName
                ? trans(
                      'trial-class-subtitle-teacher',
                      { teacher: teacherFirstName },
                      'student-common',
                  )
                : trans('trial-class-subtitle-no-teacher', {}, 'student-common');
        case ClassType.Individual:
            return teacherFirstName
                ? trans(
                      'private-class-subtitle-teacher',
                      {
                          teacher: teacherFirstName,
                      },
                      'student-common',
                  )
                : trans('private-class-subtitle-no-teacher', {}, 'student-common');
        default:
            return teacherFirstName
                ? trans(
                      'public-class-subtitle-teacher',
                      {
                          teacher: teacherFirstName,
                      },
                      'student-common',
                  )
                : trans('public-class-subtitle-no-teacher', {}, 'student-common');
    }
};

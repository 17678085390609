import { useCallback, useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useAppointmentDatesQuery } from '@lingoda/graphql';
import { createDate, endOfMonth, startOfMonth, toUTCString } from '@lingoda/dates';
import { useDebouncedState } from '@lingoda/hooks';
import { useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';

export const useMonthAppointmentDates = (selectedDate: string | null) => {
    const addEvent = useUiEvent();
    const currentMonth = useMemo(() => startOfMonth(createDate()), []);

    const [activeMonth, setActiveMonth] = useState(currentMonth);
    const activeMonthDebounced = useDebouncedState(activeMonth, 500, debounceOptions);
    const endOfActiveMonthDebounced = endOfMonth(activeMonthDebounced);

    useEffect(() => {
        if (selectedDate) {
            setActiveMonth(startOfMonth(createDate(selectedDate)));
        } else {
            setActiveMonth(currentMonth);
        }
    }, [selectedDate, currentMonth]);

    const { data, networkStatus, refetch } = useAppointmentDatesQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            fromDate: toUTCString(activeMonthDebounced),
            toDate: toUTCString(endOfActiveMonthDebounced),
        },
    });

    const appointments = useMemo(() => {
        return (data?.appointmentDates ?? []).map((date) => createDate(date));
    }, [data]);

    const onMonthChange = useCallback(
        (newMonthStart: Date) => {
            void addEvent(actionClick('MonthScroll'), {
                metadata: { currentUrl: window.location.href },
            });
            setActiveMonth(startOfMonth(newMonthStart));
        },
        [addEvent],
    );

    return {
        scheduledDays: appointments,
        loading: networkStatus === NetworkStatus.loading,
        activeMonth,
        onMonthChange,
        refetchAppointmentDates: refetch,
    };
};

const debounceOptions = { leading: true };

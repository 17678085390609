import { SectionIcons } from '@lingoda/ui/icons/SectionIcons';
import { FlagWrapper } from './FlagWrapper';

interface Props {
    section: string;
    width: number;
    height: number;
}

export const SectionFlag = ({ section, width, height }: Props) => {
    const Icon = SectionIcons[section];

    return (
        <FlagWrapper>
            <Icon width={width} height={height} viewBox="0 0 24 24" />
        </FlagWrapper>
    );
};

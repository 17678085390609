import { forwardRef } from 'react';
import { DialogComponent, useDialog } from '@lingoda/dialogs';
import { useSelector } from '@lingoda/hooks';
import { RouterLink, type RouterLinkProps } from '@lingoda/router';
import { isSelfLearningLimitAccessEnabled } from '@lingoda/feature-flags';
import { selfLearningAccessSelector } from '../utils';

const RestrictedLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
    (props: RouterLinkProps, ref) => {
        const openDialog = useDialog(DialogComponent.SelfLearningLimitAccess);
        const hasAccess = useSelector(selfLearningAccessSelector);

        const preventRedirectHandler: RouterLinkProps['onClick'] = (event) => {
            event.preventDefault();

            openDialog();
        };

        return (
            <RouterLink
                ref={ref}
                {...props}
                onClick={hasAccess ? props.onClick : preventRedirectHandler}
            />
        );
    },
);
RestrictedLink.displayName = 'RestrictedLink';

export const LearningMaterialLink = forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
    const Component = isSelfLearningLimitAccessEnabled() ? RestrictedLink : RouterLink;

    return <Component {...props} ref={ref} />;
});

LearningMaterialLink.displayName = 'LearningMaterialLink';

import { addDays, createDate } from '@lingoda/dates';
import { Box } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { trans } from '@lingoda/i18n';
import {
    DateRangePicker,
    type DateRangePickerProps,
    type FocusedDate,
} from '@lingoda/ui/fields/DateRangePicker';
import ClassInPeriodAlert from './ClassInPeriodAlert';
import type { RangeState } from '../../types';

interface Props {
    range: RangeState;
    onRangeChange: (range: RangeState) => void;
    scheduledClassesDates?: Date[];
    isAlertVisible?: boolean;
    numberOfClassesInPausePeriod: number;
    isEndDateFocused: boolean;
    setFocus: (focusedDate: FocusedDate) => void;
}

export const CalendarStepContent = ({
    scheduledClassesDates,
    range,
    onRangeChange,
    numberOfClassesInPausePeriod,
    isEndDateFocused,
    setFocus,
    isAlertVisible,
}: Props) => {
    const tomorrow = addDays(createDate(), 1);

    const handleDateRangeChange: DateRangePickerProps['onChange'] = ({ range }) => {
        range?.startDate &&
            range?.endDate &&
            onRangeChange({ startDate: range?.startDate, endDate: range?.endDate });
    };

    return (
        <Box display="flex" flexDirection="column">
            {isAlertVisible && (
                <ClassInPeriodAlertContainer>
                    <ClassInPeriodAlert
                        numberOfClassesInPausePeriod={numberOfClassesInPausePeriod}
                    />
                </ClassInPeriodAlertContainer>
            )}
            <Box overflow="auto" sx={{ scrollbarWidth: 'none' }}>
                <DateRangePicker
                    dateFrom={range?.startDate}
                    dateTo={range?.endDate}
                    onChange={handleDateRangeChange}
                    scheduledDays={scheduledClassesDates}
                    scheduledDayTooltip={trans('scheduled-class-this-day', {}, 'student-common')}
                    onFocus={setFocus}
                    minDate={isEndDateFocused ? range?.startDate : tomorrow}
                    maxDate={
                        isEndDateFocused && range?.startDate
                            ? addDays(range.startDate, 27)
                            : undefined
                    }
                />
            </Box>
        </Box>
    );
};

const ClassInPeriodAlertContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
        order: 1,
    },
}));

import { Button, Grid, useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';
import { userSelector } from '@lingoda/auth';
import { trans } from '@lingoda/i18n';
import { UrlProvider } from '@lingoda/router';
import { useSelector } from '@lingoda/hooks';

export const AddCalendar = () => {
    const user = useSelector(userSelector);
    const googleCalendarUrl = `https://calendar.google.com/calendar/render?cid=${user?.calendarUrl}`;
    const addEvent = useUiEvent();

    const handleOutlookClick = () => addEvent(actionClick('outlook/apple'));

    const handleGoogleClick = () => addEvent(actionClick('google'));

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleGoogleClick}
                    href={googleCalendarUrl}
                    target="_blank"
                    fullWidth
                >
                    {trans('google-calendar', {}, 'public-common')}
                </Button>
            </Grid>
            {user?.calendarUrl && (
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOutlookClick}
                        href={{
                            provider: UrlProvider.External,
                            href: user.calendarUrl,
                        }}
                        target="_blank"
                        fullWidth
                    >
                        {trans('outlook-calendar', {}, 'public-common')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default AddCalendar;

import { trans } from '@lingoda/i18n';
import {
    Box,
    Button,
    GradientButton,
    ProgressBar,
    Stack,
    Typography,
    colorsPalette,
} from '@lingoda/ui';
import { ButtonResizeTheme, ContentBody, ContentFooter } from '@lingoda/quiz-ui';
import NiceWorkIllustration from './NiceWorkIllustration.svg?component';

interface Props {
    correctWordsInTotal: number;
    correctWordsInSession: number;
    loading?: boolean;
    totalWords: number;
    onReset: () => void;
    onContinue: () => void;
}

export const NiceWork = ({
    correctWordsInTotal,
    correctWordsInSession,
    loading,
    totalWords,
    onReset,
    onContinue,
}: Props) => {
    const percentage = Math.round((correctWordsInTotal / totalWords) * 100);
    const remainingWords = totalWords - correctWordsInTotal;

    return (
        <>
            <ContentBody alignItems="center" justifyContent="center">
                <Box mb={2}>
                    <NiceWorkIllustration />
                </Box>
                <Typography mb={1} variant="h1">
                    {trans('nice-work', {}, 'student-common')}
                </Typography>
                <Typography
                    htmlColor={colorsPalette.blackAlpha[70]}
                    fontSize={{ xs: '1rem', sm: '1.25rem' }}
                    lineHeight="1.75rem"
                    marginBottom={4}
                    textAlign="center"
                    variant="body2"
                >
                    {trans(
                        'flashcards-you-just-learned',
                        {
                            correctWordsInSession,
                            remainingWords,
                        },
                        'student-common',
                    )}
                </Typography>
                <Typography
                    color={colorsPalette.blackAlpha[70]}
                    fontSize={{ xs: '1rem', sm: '1.25rem' }}
                    lineHeight="1.5rem"
                    marginBottom={1.25}
                    variant="body2"
                >
                    {trans(
                        'flashcards-mastered',
                        {
                            correctWordsInTotal,
                            totalWords,
                        },
                        'student-common',
                    )}
                </Typography>
                <Box width="100%">
                    <ProgressBar
                        variant="determinate"
                        value={percentage}
                        size="medium"
                        sx={{ mx: 'auto' }}
                    />
                </Box>
            </ContentBody>
            <ContentFooter>
                <ButtonResizeTheme>
                    <Stack width="100%" spacing={3} direction={{ xs: 'column-reverse', sm: 'row' }}>
                        <Button
                            color="grayscale"
                            fullWidth
                            loading={loading}
                            onClick={onReset}
                            variant="outlined"
                        >
                            {trans('flashcards-reset', {}, 'student-common')}
                        </Button>
                        <GradientButton fullWidth loading={loading} onClick={onContinue}>
                            {trans('flashcards-continue-practising', {}, 'student-common')}
                        </GradientButton>
                    </Stack>
                </ButtonResizeTheme>
            </ContentFooter>
        </>
    );
};

import { colorsPalette } from '@lingoda/ui';
import type { CSSProperties } from 'react';

interface WidgetStyles {
    colors: {
        subtitle: string;
        title: string;
        status: string;
    };
    background?: string;
    ':hover'?: CSSProperties;
    ':active'?: CSSProperties;
    unjoinedBlock: {
        colors: {
            description: string;
            buttons?: string;
        };
    };
}

const challengeGradients = {
    'february-2024': 'linear-gradient(100deg, #153BFF -150.93%, #90C 103.43%)',
};

export const getWidgetStyles = (challengeName: string, joined: boolean): WidgetStyles => {
    switch (challengeName) {
        case 'february-2024':
            return {
                colors: {
                    subtitle: joined ? colorsPalette.blackAlpha[60] : colorsPalette.whiteAlpha[70],
                    title: joined ? colorsPalette.blackSolid[100] : colorsPalette.whiteSolid[100],
                    status: joined ? colorsPalette.blackAlpha[70] : colorsPalette.whiteAlpha[70],
                },
                background: joined ? undefined : challengeGradients['february-2024'],
                ':hover': joined
                    ? undefined
                    : {
                          background: challengeGradients['february-2024'],
                          filter: 'brightness(0.9)',
                      },
                unjoinedBlock: {
                    colors: {
                        description: colorsPalette.whiteAlpha[60],
                        buttons: colorsPalette.whiteSolid[100],
                    },
                },
            };
        default: {
            return {
                colors: {
                    subtitle: colorsPalette.blackAlpha[60],
                    title: colorsPalette.blackSolid[100],
                    status: colorsPalette.blackAlpha[70],
                },
                unjoinedBlock: {
                    colors: {
                        description: colorsPalette.blackAlpha[70],
                    },
                },
            };
        }
    }
};

import { Box, Stack, Typography, colorsPalette, styled } from '@lingoda/ui';

interface ClassStatusCustomProps {
    title: string;
    subtitle?: string;
}

export const ClassStatusCustom = ({ title, subtitle }: ClassStatusCustomProps) => {
    return (
        <BoxStyled>
            <Stack spacing={0.5}>
                <Typography variant="subtitle2">{title}</Typography>
                {subtitle && <Typography variant="body3">{subtitle}</Typography>}
            </Stack>
        </BoxStyled>
    );
};

const BoxStyled = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1.5),
    textAlign: 'center',
    backgroundColor: colorsPalette.blackSolid[3],
}));

import { type MouseEvent } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { type AchievementFragment } from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { goTo, studentAchievementsPath } from '@lingoda/router';
import {
    Box,
    GradientButton,
    ProgressBar,
    Stack,
    Typography,
    colorsPalette,
    styled,
    withCursorInteractiveStyles,
} from '@lingoda/ui';
import { useClaimReward, useTrackClaimRewardOnboarding } from '../../hooks';
import { AchievementSlug } from '../../models';
import { getIsClaimable } from '../../utils';
import { getAchieved } from '../../utils/getAchieved';
import { AchievementBadge } from '../AchievementBadge';

interface Props {
    achievement: AchievementFragment;
    loading?: boolean;
    component?: React.ElementType;
}

export const AchievementItem = ({ achievement, loading, component }: Props) => {
    const dispatch = useDispatch();
    const { trackClaimRewardOnboarding } = useTrackClaimRewardOnboarding();
    const [claimReward, isClaiming] = useClaimReward({
        onCompleted: ({ claimAttendanceAchievement }) => {
            showAchievementDialog(claimAttendanceAchievement, true);
        },
    });

    const { slug, currentProgress, value, name, claimed, claimId, progressLabel, achievedLabel } =
        achievement;
    // TODO: use achieved from api when those are finished: https://lingoda.atlassian.net/browse/LW-23619, https://lingoda.atlassian.net/browse/LW-23623
    const achieved = getAchieved(achievement);
    const isClaimable = getIsClaimable(achievement);
    const label = achieved ? achievedLabel : progressLabel;
    const isClickable = claimed || isClaimable;

    const showAchievementDialog = (achievement: AchievementFragment, animateClaiming: boolean) =>
        dispatch(
            showDialog(DialogComponent.Achievement, {
                achievement,
                animateClaiming,
                onSeeAllClick: () => goTo(studentAchievementsPath()),
            }),
        );

    const handleDialogOpen = async (event: MouseEvent) => {
        event.stopPropagation();

        if (isClaimable && claimId) {
            if (slug === AchievementSlug.ONBOARDING_CHECKLIST) {
                trackClaimRewardOnboarding();
            }
            await claimReward(claimId);
        } else if (claimed) {
            showAchievementDialog(achievement, false);
        }
    };

    return (
        <AchievementItemContainer
            component={component}
            data-cy="Achievement"
            onClick={isClickable ? handleDialogOpen : undefined}
            isClickable={isClickable}
        >
            <Stack direction="row" spacing={2} width="100%">
                <AchievementBadge
                    badgeSlug={slug}
                    active={claimed}
                    name={name}
                    width={64}
                    height={64}
                    loading={loading}
                />
                {claimed ? (
                    <DetailsStack spacing={1}>
                        <Typography
                            variant="subtitle1"
                            sx={{ wordBreak: 'break-word' }}
                            htmlColor={colorsPalette.blackSolid[100]}
                        >
                            {name}
                        </Typography>
                        <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                            {label}
                        </Typography>
                    </DetailsStack>
                ) : (
                    <DetailsStack spacing={1}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                                {label}
                            </Typography>
                            <Typography flex="none" variant="body2" ml={0.5}>
                                {currentProgress}/{value}
                            </Typography>
                        </Box>
                        {isClaimable ? (
                            <GradientButton
                                size="small"
                                fullWidth
                                onClick={handleDialogOpen}
                                disabled={isClaiming}
                                loading={isClaiming}
                                sx={{ maxWidth: 240 }}
                            >
                                {trans('btn-claim-reward', {}, 'student-achievements')}
                            </GradientButton>
                        ) : (
                            <ProgressBar
                                value={(currentProgress / value) * 100}
                                size="medium"
                                fullWidth
                            />
                        )}
                    </DetailsStack>
                )}
            </Stack>
        </AchievementItemContainer>
    );
};

const AchievementItemContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isClickable',
})<{ isClickable: boolean }>(({ theme, isClickable }) =>
    withCursorInteractiveStyles(isClickable)({
        display: 'flex',
        padding: theme.spacing(1, 3),
        color: colorsPalette.blackAlpha[70],
    }),
);

const DetailsStack = styled(Stack)(({ theme }) => ({
    flex: '1 1 auto',
    padding: theme.spacing(0.5, 0),
}));

import { useMemo } from 'react';
import { AppointmentStatus } from '@lingoda/graphql';
import { format } from '@lingoda/dates';
import { BoxPerf } from '@lingoda/ui';
import { ClassTile } from '../ClassTileItem/ClassTile';
import { ClassTileFooter } from '../ClassTileItem/ClassTileFooter';
import { ClassTileHeader } from '../ClassTileItem/ClassTileHeader';
import { ClassTileInfo } from '../ClassTileItem/ClassTileInfo';
import { ClassTileParticipants } from '../ClassTileItem/ClassTileParticipants';
import { getBookClassElementProps } from './helpers';
import type { CommonProps } from '../../types/props';

export const TileLayout = ({
    klass,
    isUpcoming,
    onBook,
    onDetails,
    outlined,
    disableBookClass,
    showFullDate,
    linkTo,
    isPreview,
    openInNewTab,
}: CommonProps) => {
    const { lesson } = klass;
    const { subtitle, lessonAttended, lessonLocked, isBooked, studentAvatars, chapterName } =
        useMemo(() => getBookClassElementProps(klass), [klass]);

    return (
        <BoxPerf className="height-100">
            <ClassTile outlined={outlined} linkTo={linkTo} openInNewTab={openInNewTab}>
                <ClassTileHeader>
                    {showFullDate && `${format('ccc, PP', klass.startDate)} • `}
                    {format('p', klass.startDate)}
                </ClassTileHeader>
                <BoxPerf className="flex-direction-column flex-1">
                    {lesson && klass.module && (
                        <ClassTileInfo
                            lessonTitle={lesson.title || ''}
                            chapterName={chapterName}
                            moduleName={klass.module.name}
                            lessonLabel={lesson.label}
                            classTypeInfo={subtitle}
                            hasTeacher={!!klass.teacher}
                        />
                    )}
                    <ClassTileParticipants
                        teacher={
                            klass.teacher?.firstName
                                ? {
                                      firstName: klass.teacher.firstName,
                                      src: klass.teacher.photo?.urls.medium,
                                  }
                                : undefined
                        }
                        avatars={studentAvatars}
                    />
                </BoxPerf>
                {!isPreview && (
                    <ClassTileFooter
                        isLessonAttended={lessonAttended}
                        isLessonLocked={lessonLocked}
                        isPast={false}
                        isBooked={isBooked}
                        isUpcoming={isUpcoming}
                        status={AppointmentStatus.Active}
                        onDetails={onDetails}
                        onBook={onBook}
                        disableActions={disableBookClass}
                    />
                )}
            </ClassTile>
        </BoxPerf>
    );
};

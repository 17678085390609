import { useMemo } from 'react';
import { MultiToggleField } from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { Box, Typography, colorsPalette } from '@lingoda/ui';

interface Props {
    answers: Answer[];
    name: string;
}

interface Answer {
    value: string;
    translatedAnswer: string;
}

export const ToggleQuestion = ({ name, answers }: Props) => {
    const mappedAnswers = useMemo(() => {
        return answers.map(({ value, translatedAnswer }) => ({
            value,
            label: translatedAnswer,
        }));
    }, [answers]);

    return (
        <>
            <MultiToggleField name={name} items={mappedAnswers} fullWidth exclusive />
            <Box display="flex" justifyContent="space-between">
                <Typography htmlColor={colorsPalette.negativeCustom.dark}>
                    {trans('not-satisfied', {}, 'student-common')}
                </Typography>
                <Typography htmlColor={colorsPalette.positiveCustom.dark}>
                    {trans('extremely-satisfied', {}, 'student-common')}
                </Typography>
            </Box>
        </>
    );
};

import { Prompt, matchPathMemoized } from '@lingoda/router';
import { DialogComponent, useDialog } from '@lingoda/dialogs';
import { useSelector } from '@lingoda/hooks';
import { selfLearningAccessSelector, selfLearningRoutes } from '../utils';

export const SelfLearningAccess = () => {
    const hasAccess = useSelector(selfLearningAccessSelector);

    if (hasAccess) {
        return null;
    }

    return <BlockSelfLearningAccess />;
};

const BlockSelfLearningAccess = () => {
    const openDialog = useDialog(DialogComponent.SelfLearningLimitAccess);

    return (
        <Prompt
            message={(location) => {
                const hasMatch = matchPathMemoized(selfLearningRoutes, location.pathname);

                if (!hasMatch) {
                    return true;
                }

                openDialog();

                return false;
            }}
        />
    );
};

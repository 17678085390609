import { createSelector } from 'reselect';
import { CHAPTER_SELECTION_STORE_KEY } from '../constants';
import type { ChapterSelection } from '../models';
import type { State } from '../reducers/chapterSelectionReducers';

export const chapterSelectionSelector = (state: GlobalState) =>
    state[CHAPTER_SELECTION_STORE_KEY] as State;

export default createSelector(
    chapterSelectionSelector,
    (chapterSelection): ChapterSelection => chapterSelection,
);

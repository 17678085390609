import Translator from 'bazinga-translator';
import type { AcceptedLang } from '@lingoda/env';
import type { TransParams } from './common';

export const trans = (
    id: string,
    params?: TransParams,
    domain = 'messages',
    locale?: AcceptedLang,
): string => {
    // Next is workaround to enable `icu` processing with `bazinga-translator`:
    // Bazinga enables icu only when translation is found in a domain suffixed with `+intl-icu`,
    // so it won't work for already suffixed domains like `messages+intl-icu`, since it will be doubled.
    // However we can't omit the suffix from domain in our `trans` call,
    // since it required for the JMSTranslationBundle extractor to create corresponding translation files.
    // So to both make the extractor work and avoid double `+intl-icu` suffix, we trim it here.
    //
    // See: https://github.com/willdurand/BazingaJsTranslationBundle/blob/11e717d9baf2ecb34b355986a2225d70c015d7fc/Resources/js/translator.js#L301-L304
    const trimIcuSuffix = (domain: string) => {
        const ICU_SUFFIX = '+intl-icu';

        if (domain.endsWith(ICU_SUFFIX)) {
            return domain.slice(0, -ICU_SUFFIX.length);
        }

        return domain;
    };

    return Translator.trans(id, params, trimIcuSuffix(domain), locale);
};

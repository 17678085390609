import { useEffect, useState } from 'react';
import { config } from '@lingoda/config';
import '../styles/googleRecaptchaV3.css';

interface RecaptchaWindow extends Window {
    grecaptcha: {
        ready: (callback: () => void) => void;
        execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
}

declare const window: RecaptchaWindow;

const SCRIPT_ID = 'googleRecaptchaV3';

export const useGoogleRecaptchaV3 = () => {
    const [loading, setLoading] = useState(true);

    const siteKey = config.googleRecaptchaV3SiteKey;

    useEffect(() => {
        if (!siteKey) {
            return;
        }

        const existingScript = document.getElementById(SCRIPT_ID);

        if (existingScript && window.grecaptcha) {
            setLoading(false);

            return;
        }

        const script = document.createElement('script');
        script.id = SCRIPT_ID;
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;

        const onload = () => {
            window.grecaptcha.ready(() => {
                setLoading(false);
            });
        };

        script.addEventListener('load', onload);

        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', onload);
        };
    }, [setLoading, siteKey]);

    if (!siteKey) {
        return { loading };
    }

    const getToken = () => window.grecaptcha.execute(siteKey, { action: 'submit' });

    return { getToken, loading };
};

import { getQueryParams } from './getQueryParams';
import type { ParsedQs } from 'qs';

export const getQueryParam = <T extends string | ParsedQs | string[] | ParsedQs[]>(
    queryParam: string,
    location: Pick<Location, 'search'>,
): T | undefined => {
    const values = getQueryParams(location.search);
    if (values && values[queryParam]) {
        return values[queryParam] as T;
    }

    return undefined;
};

import MuiTableRow from '@mui/material/TableRow';
import { colorsPalette, styled } from '../../theme';

export const TableRow = styled(MuiTableRow)(() => ({
    '&.MuiTableRow-hover': {
        '&:hover': {
            backgroundColor: `${colorsPalette.primarySolid[4]} !important`,
        },
    },
})) as typeof MuiTableRow; // https://github.com/mui/material-ui/issues/15759#issuecomment-493994852

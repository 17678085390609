import { useState } from 'react';
import { Button, Typography } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { trans } from '@lingoda/i18n';
import CheckmarkIcon from './checkmark_filled.svg?component';

interface SuccessStepProps {
    onClickContinue: () => Promise<void>;
}

export const SuccessStep = ({ onClickContinue }: SuccessStepProps) => {
    const [disableButton, setDisableButton] = useState(false);

    const handleClickContinue = async () => {
        try {
            setDisableButton(true);
            await onClickContinue();
        } catch (e) {
            setDisableButton(false);
            throw e;
        }
    };

    return (
        <Grid container justifyContent={'center'} textAlign={'center'} rowSpacing={2}>
            <Grid>
                <CheckmarkIcon />
            </Grid>
            <Grid xs={12}>
                <Typography variant="h2" marginBottom={2}>
                    {trans('your-password-is-saved', {}, 'public-common')}
                </Typography>
            </Grid>

            <Grid marginTop={2} width={'100%'}>
                <Button
                    variant={'outlined'}
                    color={'grayscale'}
                    fullWidth
                    onClick={handleClickContinue}
                    disabled={disableButton}
                >
                    {trans('form-continue-button', {}, 'public-common')}
                </Button>
            </Grid>
        </Grid>
    );
};

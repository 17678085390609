import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const CalendarIcon = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 14 14" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.2 0.25C4.61421 0.25 4.95 0.585786 4.95 1V1.85H9.05V1C9.05 0.585786 9.38579 0.25 9.8 0.25C10.2142 0.25 10.55 0.585786 10.55 1V1.85H13C13.4142 1.85 13.75 2.18579 13.75 2.6V13C13.75 13.4142 13.4142 13.75 13 13.75H1C0.585786 13.75 0.25 13.4142 0.25 13V2.6C0.25 2.18579 0.585786 1.85 1 1.85H3.45V1C3.45 0.585786 3.78579 0.25 4.2 0.25ZM3.45 3.35H1.75V5.05H12.25V3.35H10.55V3.8C10.55 4.21421 10.2142 4.55 9.8 4.55C9.38579 4.55 9.05 4.21421 9.05 3.8V3.35H4.95V3.8C4.95 4.21421 4.61421 4.55 4.2 4.55C3.78579 4.55 3.45 4.21421 3.45 3.8V3.35ZM12.25 6.55H1.75V12.25H12.25V6.55Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export const CalendarIcon16 = (props: SvgIconProps) => (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fontSize="small" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.2 1.25C5.61421 1.25 5.95 1.58579 5.95 2V2.85H10.05V2C10.05 1.58579 10.3858 1.25 10.8 1.25C11.2142 1.25 11.55 1.58579 11.55 2V2.85H14C14.4142 2.85 14.75 3.18579 14.75 3.6V14C14.75 14.4142 14.4142 14.75 14 14.75H2C1.58579 14.75 1.25 14.4142 1.25 14V3.6C1.25 3.18579 1.58579 2.85 2 2.85H4.45V2C4.45 1.58579 4.78579 1.25 5.2 1.25ZM4.45 4.35H2.75V6.05H13.25V4.35H11.55V4.8C11.55 5.21421 11.2142 5.55 10.8 5.55C10.3858 5.55 10.05 5.21421 10.05 4.8V4.35H5.95V4.8C5.95 5.21421 5.61421 5.55 5.2 5.55C4.78579 5.55 4.45 5.21421 4.45 4.8V4.35ZM13.25 7.55H2.75V13.25H13.25V7.55Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export const CalendarIcon24 = (props: SvgIconProps) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.65C2.58579 3.65 2.25 3.98579 2.25 4.4V20C2.25 20.4142 2.58579 20.75 3 20.75H21C21.4142 20.75 21.75 20.4142 21.75 20V4.4C21.75 3.98579 21.4142 3.65 21 3.65H16.95V2C16.95 1.58579 16.6142 1.25 16.2 1.25C15.7858 1.25 15.45 1.58579 15.45 2V3.65H8.55V2C8.55 1.58579 8.21421 1.25 7.8 1.25C7.38579 1.25 7.05 1.58579 7.05 2V3.65H3ZM3.75 19.25H20.25V9.95H3.75V19.25ZM7.8 6.95C8.21421 6.95 8.55 6.61421 8.55 6.2V5.15H15.45V6.2C15.45 6.61421 15.7858 6.95 16.2 6.95C16.6142 6.95 16.95 6.61421 16.95 6.2V5.15H20.25V8.45H3.75V5.15H7.05V6.2C7.05 6.61421 7.38579 6.95 7.8 6.95Z"
            fill="currentColor"
        />
    </SvgIcon>
);

import { type ResourceOwnersSchema } from '@lingoda/api';
import { trans } from '@lingoda/i18n';
import { Box, SocialButton, Typography, keyframes, styled } from '@lingoda/ui';

interface Props {
    disabled: boolean;
    handleClick: () => void;
    resourceOwners?: ResourceOwnersSchema[];
}

export const SocialOptions = ({ disabled, handleClick, resourceOwners }: Props) => {
    return (
        <BoxStyled>
            <TypographyStyled variant="body1">
                {trans('link-social-to-continue', {}, 'social-auth')}
            </TypographyStyled>
            {resourceOwners ? (
                <ListStyled>
                    {resourceOwners.map(({ type, name, loginUrl }) => (
                        <ListItemStyled key={type}>
                            <SocialButton
                                aria-label={trans('sign-up-with', { name }, 'social-auth')}
                                disabled={disabled}
                                href={loginUrl}
                                iconPosition="content"
                                onClick={handleClick}
                                fullWidth
                                vendor={type}
                            />
                        </ListItemStyled>
                    ))}
                </ListStyled>
            ) : (
                <LoadingBoxStyled>
                    <LoadingSvg aria-hidden="true" viewBox="22 22 44 44">
                        <LoadingSvgCircle cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6" />
                    </LoadingSvg>
                </LoadingBoxStyled>
            )}
        </BoxStyled>
    );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const BoxStyled = styled(Box)(() => ({
    display: 'grid',
    gridGap: '16px',
}));

const TypographyStyled = styled(Typography)(() => ({
    marginBlock: '-5px -6px',
    position: 'relative',
    textAlign: 'center',
}));

const ListStyled = styled('ul')(() => ({
    animation: `${fadeIn} 0.5s ease-in-out forwards`,
    display: 'grid',
    gridGap: '12px',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    listStyle: 'none',
    margin: '0',
    padding: '0',
}));

const ListItemStyled = styled('li')(() => ({
    alignItems: 'center',
    display: 'flex',
}));

const LoadingBoxStyled = styled(Box)(() => ({
    alignItems: 'center',
    display: 'flex',
    height: '56px',
    justifyContent: 'center',
}));

const LoadingSvg = styled('svg')(() => ({
    height: '32px',
    width: '32px',
}));

const spinner = keyframes`
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
    }
`;

const LoadingSvgCircle = styled('circle')(() => ({
    animation: `${spinner} 1.2s ease-in-out infinite`,
    stroke: '#9900cc',
    strokeDasharray: '80px, 200px',
    strokeDashoffset: '0',
}));

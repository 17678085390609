import { Children, Component } from 'react';
import { findDOMNode } from 'react-dom';

interface DomEvent {
    selector: string;
    eventType: string;
    callback: (e: Event) => void;
}

interface Props {
    events?: DomEvent[];
}

export class DOMEvent extends Component<Props> {
    public override componentDidMount(): void {
        // eslint-disable-next-line react/no-find-dom-node
        const element = findDOMNode(this) as Element;
        this.props.events?.forEach(({ selector, eventType, callback }: DomEvent) => {
            const selectedElement = element.querySelector(selector);
            if (selectedElement) {
                selectedElement.addEventListener(eventType, callback);
            }
        });
    }

    public override componentWillUnmount(): void {
        // eslint-disable-next-line react/no-find-dom-node
        const element = findDOMNode(this) as Element;
        this.props.events?.forEach(({ selector, eventType, callback }: DomEvent) => {
            const selectedElement = element.querySelector(selector);
            if (selectedElement) {
                selectedElement.removeEventListener(eventType, callback);
            }
        });
    }

    public override render() {
        return Children.only(this.props.children);
    }
}

import { createSelector } from 'reselect';
import { requestsSelector } from '@lingoda/core';
import { fetchSubscriptions } from './actions';
import { STORE_KEY } from './constants';
import { SubscriptionLoadedState, SubscriptionStatus } from './models';
import type { State } from './reducers';

export const subscriptionsStateSelector = (state: GlobalState) => state[STORE_KEY] as State;

export const subscriptionsSelector = createSelector(subscriptionsStateSelector, (subscriptions) =>
    Object.values(subscriptions.items),
);

export const subscriptionsLoadedStateSelector = createSelector(
    subscriptionsStateSelector,
    (subscriptions) => subscriptions.status,
);

export const areSubscriptionsLoadedSelector = createSelector(
    subscriptionsLoadedStateSelector,
    (status) => status === SubscriptionLoadedState.Loaded,
);

export const hasTrialSubscriptionSelector = createSelector(subscriptionsSelector, (subscriptions) =>
    subscriptions.some((item) => item.isInTrial),
);

export const areSubscriptionsRequestedSelector = createSelector(requestsSelector, (requests) =>
    Object.values(requests).some((req) => req.actionType === `${fetchSubscriptions}`),
);

export const subscriptionsListSelector = createSelector(subscriptionsSelector, (subscriptions) =>
    subscriptions.sort(
        (oneSubscription, anotherSubscription) => oneSubscription?.id - anotherSubscription?.id,
    ),
);

export const hasSubscriptionSelector = createSelector(
    subscriptionsListSelector,
    (subscriptions) => !!subscriptions.length,
);

export const lastSubscriptionSelector = createSelector(
    subscriptionsListSelector,
    (subscriptions) => subscriptions[subscriptions.length - 1],
);

export const activeSubscriptionSelector = createSelector(subscriptionsSelector, (subscriptions) =>
    subscriptions.find((subscription) => subscription.status === SubscriptionStatus.Active),
);

export const isActiveSubscriptionPrivate = createSelector(
    activeSubscriptionSelector,
    (activeSubscription) => activeSubscription && activeSubscription?.privateCredits > 0,
);

export const hasActiveSubscriptionSelector = createSelector(
    activeSubscriptionSelector,
    (activeSubscription) => !!activeSubscription,
);

export const hasExpiredSubscriptionSelector = createSelector(
    subscriptionsSelector,
    (subscriptions) => !!subscriptions.find((subscription) => subscription.isExpired),
);

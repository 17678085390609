import { colorsPalette } from '../theme';
import type { CSSObject } from '@mui/system';

export const withCursorInteractiveStyles =
    (isClickable: boolean) =>
    (styles: CSSObject): CSSObject =>
        isClickable
            ? {
                  ...styles,
                  cursor: 'pointer',
                  userSelect: 'none',
                  ':hover': {
                      background: colorsPalette.blackAlpha[4],
                      ...(styles[':hover'] as CSSObject | undefined),
                  },
                  ':active': {
                      background: colorsPalette.blackAlpha[8],
                      ...(styles[':active'] as CSSObject | undefined),
                  },
              }
            : styles;

import type { Action, ActionMeta } from '@lingoda/core';
import addEvent from './addEvent';
import { sectionsToCategory, sectionsToLabel } from './utils';
import type { UiTrackingContextProps } from '../../context';
import type { Event } from './models';

export default <A extends Omit<Event, 'id' | 'category' | 'action' | 'label'>>(
    action: Action<unknown>,
    eventAction: string,
    section: string | null,
    values?: A,
) => {
    if (containsUiMetadata(action)) {
        let { sections } = action.meta.uiContext;
        if (section) {
            sections = [section, ...sections];
        }

        void addEvent({
            label: sectionsToLabel(sections),
            category: sectionsToCategory(sections),
            action: eventAction,
            ...values,
        });
    }
};

interface MetaUiContext {
    uiContext: UiTrackingContextProps;
}

export const containsUiMetadata = (action: {
    type: string;
    meta?: Partial<MetaUiContext>;
}): action is ActionMeta<unknown, MetaUiContext> => !!action.meta && !!action.meta.uiContext;

import { trans } from '@lingoda/i18n';
import { Box } from '@lingoda/ui';

interface Props {
    sepa: boolean;
}

const PaymentMethodDescription = ({ sepa }: Props) =>
    (sepa && (
        <Box textAlign="center" margin={3}>
            {trans('sepa-payment-description', {}, 'public-payment')}
        </Box>
    )) ||
    null;

export default PaymentMethodDescription;

import { memo } from 'react';
import get from 'lodash/get';
import FormControl from '@lingoda/ui/fields/FormControl';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import type { InputLabelProps } from '@lingoda/ui/fields/InputLabel';
import InputLabel from '@lingoda/ui/fields/InputLabel';
import type { SelectProps } from '@lingoda/ui';
import { Select } from '@lingoda/ui';
import { ErrorMessage, Field, type FieldProps } from '../../utils';

interface OwnProps {
    label?: string;
    labelProps?: InputLabelProps;
}

const FormikSelect = <T,>({ labelProps, onChange, ...props }: SelectProps<T> & OwnProps) => (
    <Field {...props}>
        {({ field, form: { errors, touched } }: FieldProps) => (
            <FormControl
                error={!!get(touched, field.name) && !!get(errors, field.name)}
                fullWidth={props.fullWidth}
                required={props.required}
                variant={props.variant}
            >
                <InputLabel id={`${field.name}_label`} color="secondary" {...labelProps}>
                    {props.label}
                </InputLabel>
                <Select
                    id={`${field.name}_id`}
                    labelId={`${field.name}_label`}
                    color="secondary"
                    error={!!touched[field.name] && !!errors[field.name]}
                    {...props}
                    {...field}
                    onChange={(event, child) => {
                        field.onChange(event);
                        onChange?.(event, child);
                    }}
                />
                <ErrorMessage name={field.name}>
                    {(message) => (
                        <FormHelperText>
                            {typeof message === 'object'
                                ? Object.values(message).map((msg, index) => (
                                      <p key={`${index}`}>{msg as string}</p>
                                  ))
                                : message}
                        </FormHelperText>
                    )}
                </ErrorMessage>
            </FormControl>
        )}
    </Field>
);

export const SelectField = memo(FormikSelect) as typeof FormikSelect;

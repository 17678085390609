import { memo } from 'react';
import { BoxPerf, Hidden, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';

interface Props {
    lessonTitle: string;
    chapterName: string;
    moduleName: string;
    lessonLabel: string;
}

const ClassListItemInfoComponent = ({
    moduleName,
    chapterName,
    lessonTitle,
    lessonLabel,
}: Props) => {
    const subtitle = [moduleName, chapterName, lessonLabel].filter(Boolean).join(' • ');

    return (
        <BoxPerf className="display-block">
            <Hidden lgDown>
                <BoxPerf className="display-block">
                    <Typography
                        variant="overline"
                        data-cy="classListItemInfo_subtitle"
                        htmlColor={colorsPalette.blackAlpha[70]}
                    >
                        {subtitle}
                    </Typography>
                </BoxPerf>
                <Typography
                    variant="h6"
                    data-cy="classListItemInfo_lessonTitle"
                    htmlColor={colorsPalette.blackSolid[100]}
                >
                    {lessonTitle}
                </Typography>
            </Hidden>
            <Hidden lgUp>
                <BoxPerf className="display-block mb-0-5">
                    <Typography
                        variant="h6"
                        data-cy="classListItemInfo_lessonTitle"
                        htmlColor={colorsPalette.blackSolid[100]}
                    >
                        {lessonTitle}
                    </Typography>
                </BoxPerf>
                <Typography
                    variant="overline"
                    data-cy="classListItemInfo_subtitle"
                    htmlColor={colorsPalette.blackAlpha[70]}
                >
                    {subtitle}
                </Typography>
            </Hidden>
        </BoxPerf>
    );
};

export const ClassListItemInfo = memo(ClassListItemInfoComponent);

import { useMemo } from 'react';
import { useSelector } from '@lingoda/hooks';
import { userIdSelector } from '@lingoda/auth';
import { assert } from '@lingoda/utils';
import { LeadConversionFlowContext } from '../context';
import { useLeadConversionFlowState } from '../hooks';
import type { LeadConversionFlowContextValue } from '../context';
import type { ReactNode } from 'react';

const LeadConversionContextProvider = ({ children }: { children: ReactNode }) => {
    const userId = useSelector(userIdSelector);
    assert(userId, 'Invalid argument');

    const [isComplete, complete] = useLeadConversionFlowState(userId);

    const contextValue: LeadConversionFlowContextValue = useMemo(
        () => ({
            isComplete,
            complete,
        }),
        [isComplete, complete],
    );

    return (
        <LeadConversionFlowContext.Provider value={contextValue}>
            {children}
        </LeadConversionFlowContext.Provider>
    );
};

export default LeadConversionContextProvider;

import { addDays, createDate, isAfter } from '@lingoda/dates';

export const shouldRemindToSetAGoal = (
    currentGoal: number | null,
    firstClassAttendanceDate: string | null,
) =>
    !currentGoal &&
    !!firstClassAttendanceDate &&
    numberOfDaysDidNotYetPassedFromFirstClassAttendance(7, firstClassAttendanceDate);

const numberOfDaysDidNotYetPassedFromFirstClassAttendance = (
    days: number,
    firstClassAttendanceDate: string,
) => !isAfter(createDate(), addDays(createDate(firstClassAttendanceDate), days));

import { Alert, DangerousHtml, Stack } from '@lingoda/ui';
import type { ContentBlock } from '@lingoda/graphql';

interface PurchaseWarningsProps {
    warnings: ContentBlock[];
}

export const PurchaseWarnings = ({ warnings }: PurchaseWarningsProps) => {
    return (
        <Stack spacing={1}>
            {warnings.map((warning, index) => (
                <Alert
                    key={index}
                    severity="warning"
                    variant="outlined"
                    title={warning.label || ''}
                >
                    <DangerousHtml>{warning.text}</DangerousHtml>
                </Alert>
            ))}
        </Stack>
    );
};

import { useCallback } from 'react';
import { Grid } from '@lingoda/ui/layout/GridNext';
import type { ToggleButtonGroupProps } from '@lingoda/ui';
import { ToggleButton } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { add, createDate, formatDistanceStrict } from '@lingoda/dates';
import type { TimeSlotsFilterValue } from './model';
import type { FilterProps } from '../model';

type ExtendButtonsProps = FilterProps<TimeSlotsFilterValue> & {
    disabled?: boolean;
};

export const ExtendButtons = ({ value, onChange, disabled }: ExtendButtonsProps) => {
    const handleExtendHoursChange: ToggleButtonGroupProps['onChange'] = useCallback(
        (_, extendHours: number) => {
            onChange({ timeSlots: { ...value, extendHours } });
        },
        [onChange, value],
    );

    return (
        <Grid container spacing={1}>
            {hours.map((hour) => (
                <Grid key={hour} xs={4} display="flex">
                    <ToggleButton
                        fullWidth
                        size="small"
                        value={hour}
                        selected={value?.extendHours === hour}
                        onChange={handleExtendHoursChange}
                        disabled={disabled}
                        aria-label={buttonAriaLabel[hour]}
                    >
                        {translateHour(hour)}
                    </ToggleButton>
                </Grid>
            ))}
        </Grid>
    );
};

const hours = [0, 1, 2];

const translateHour = (hour: number) => buttonTranslate()[hour];

const buttonTranslate = () => [
    trans('btn-exact-time', {}, 'student-common'),
    `± ${translateHourDistance(1)}`,
    `± ${translateHourDistance(2)}`,
];

const buttonAriaLabel = ['Exact time', 'Extend by 1 hour', 'Extend by 2 hours'];

const translateHourDistance = (hours: number) => {
    const from = createDate();
    const to = add({ hours }, from);

    return formatDistanceStrict(from, to, {
        unit: 'hour',
    });
};

import { trans } from '@lingoda/i18n';
import { Button } from '../buttons/Button';
import { ArrowLeft } from '../icons/lingodaIcons';
import { Box } from './Box';
import type { ButtonProps } from '../buttons/Button';

type Props = {
    goBack: () => void;
} & Pick<ButtonProps, 'inverted'>;

export const BackButtonBar = ({ goBack, inverted }: Props) => {
    return (
        <Box mt={2} ml={-2}>
            <Button
                color="primary"
                startIcon={<ArrowLeft />}
                onClick={goBack}
                size="small"
                inverted={inverted}
            >
                {trans('btn-back', {}, 'student-notification')}
            </Button>
        </Box>
    );
};

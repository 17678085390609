import { type BookingFilter } from '@lingoda/booking';
import {
    ClassTypeFilter,
    type FilterProps,
    MultipleDatesFilter,
    TimeSlotFilter,
} from '@lingoda/booking-filter';
import { BookingSearchFrameContainer } from '@lingoda/booking-ui';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { Button } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';

export interface BookingFilterDesktopSlotsProps extends FilterProps<BookingFilter> {
    onReset: () => void;
    timeFormat: TimeFormat;
}

export const BookingFilterDesktopSlots = ({
    value,
    onChange,
    onReset,
    timeFormat,
}: BookingFilterDesktopSlotsProps) => {
    return (
        <BookingSearchFrameContainer>
            <Grid container spacing={2} alignItems="flex-end" justifyContent="flex-start">
                <Grid xs={12} flex={1} minWidth={200}>
                    <MultipleDatesFilter
                        value={value.dates}
                        onChange={onChange}
                        timeSlots={value.timeSlots}
                    />
                </Grid>
                <Grid xs={12} flex="1" minWidth={200}>
                    <TimeSlotFilter
                        value={value.timeSlots}
                        onChange={onChange}
                        timeFormat={timeFormat}
                    />
                </Grid>
                <Grid xs={12} md="auto">
                    <ClassTypeFilter value={value.classType} onChange={onChange} />
                </Grid>
                <Grid xs={12} md="auto">
                    <Button color="primary" variant="outlined" onClick={onReset}>
                        {trans('btn-clear-all', {}, 'student-common')}
                    </Button>
                </Grid>
            </Grid>
        </BookingSearchFrameContainer>
    );
};

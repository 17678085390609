import { useEventSourceListener } from '@lingoda/event-source';
import { useSelector } from '@lingoda/hooks';
import { userIdSelector } from '@lingoda/auth';
import type { NotificationEventUnion, NotificationType } from './types';

export const useEventSourceNotificationListener = <T extends NotificationType>(
    types: T[],
    callback: (data: Notification<T>) => void,
) => {
    const userId = useSelector(userIdSelector);

    useEventSourceListener(`/${userId}/notification`, types, callback);
};

type Notification<T extends NotificationType> = Extract<NotificationEventUnion, { type: T }>;

import { userSelector } from '@lingoda/auth';
import { useSelector } from '@lingoda/hooks';

export const useDownloadCalendar = () => {
    const user = useSelector(userSelector);

    const downloadCalendar = () => {
        if (user?.calendarUrl) {
            const fileUrl = user.calendarUrl.replace('webcal://', 'https://');
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'calendar.ics';
            link.click();
        }
    };

    return {
        downloadCalendar,
        shouldShowDownloadCalendarEvent: !!user?.calendarUrl,
    };
};

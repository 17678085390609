export const getBrowserTimeZone = () => {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return fixMap[browserTimeZone] || browserTimeZone;
};

type Browser = string;
type Api = string | undefined;

// There is a known Chrome / Safari browsers behavior that returns some timezones with obsolete spelling
// exp for Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=580195
const fixMap: Record<Browser, Api> = {
    'America/Coral_Harbour': 'America/Atikokan',
    'America/Indianapolis': 'America/Indiana/Indianapolis',
    'America/Louisville': 'America/Kentucky/Louisville',
    'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
    'America/Catamarca': 'America/Argentina/Catamarca',
    'America/Cordoba': 'America/Argentina/Cordoba',
    'America/Jujuy': 'America/Argentina/Jujuy',
    'America/Mendoza': 'America/Argentina/Mendoza',
    'America/Godthab': 'America/Nuuk',
    'Atlantic/Faeroe': 'Atlantic/Faroe',
    'Africa/Asmera': 'Africa/Asmara',
    'Asia/Calcutta': 'Asia/Kolkata',
    'Asia/Katmandu': 'Asia/Kathmandu',
    'Asia/Rangoon': 'Asia/Yangon',
    'Asia/Saigon': 'Asia/Ho_Chi_Minh',
    'Europe/Kyiv': 'Europe/Kiev',
    'Pacific/Truk': 'Pacific/Chuuk',
    'Pacific/Ponape': 'Pacific/Pohnpei',
    'Pacific/Enderbury': 'Pacific/Kanton',
};

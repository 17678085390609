import LinearProgress, { type LinearProgressProps } from '../../feedback/LinearProgress';
import { colorsPalette, gradients, styled } from '../../theme';

type Size = 'small' | 'medium' | 'large' | 'extraLarge';

export interface ProgressBarProps extends LinearProgressProps {
    trackColor?: string;
    barColor?: string;
    fullWidth?: boolean;
    rounded?: true | 'left' | 'right' | false;
    size?: Size;
}

const StyledLinearProgress = styled(LinearProgress, {
    shouldForwardProp: (prop) =>
        !['trackColor', 'barColor', 'fullWidth', 'rounded', 'size'].includes(prop as string),
})<{
    trackColor?: ProgressBarProps['trackColor'];
    barColor?: ProgressBarProps['barColor'];
    color?: ProgressBarProps['color'];
    fullWidth: boolean;
    rounded: ProgressBarProps['rounded'];
    size: Size;
}>(({ color, trackColor, barColor, rounded, fullWidth, size, theme: { spacing } }) => {
    const { borderRadius, height } = {
        small: {
            borderRadius: spacing(0.25),
            height: spacing(0.5),
        },
        medium: {
            borderRadius: spacing(0.5),
            height: spacing(1),
        },
        large: {
            borderRadius: spacing(0.75),
            height: spacing(1.25),
        },
        extraLarge: {
            borderRadius: spacing(1),
            height: spacing(2),
        },
    }[size];

    const isRoundedLeft = rounded === true || rounded === 'left';
    const isRoundedRight = rounded === true || rounded === 'right';

    return {
        backgroundColor: trackColor || colorsPalette.blackAlpha[8],
        borderTopLeftRadius: isRoundedLeft ? borderRadius : 0,
        borderBottomLeftRadius: isRoundedLeft ? borderRadius : 0,
        borderTopRightRadius: isRoundedRight ? borderRadius : 0,
        borderBottomRightRadius: isRoundedRight ? borderRadius : 0,
        height,
        width: '100%',
        maxWidth: fullWidth ? 'none' : spacing(38),

        '.MuiLinearProgress-bar': {
            background: barColor || (color ? null : gradients.purpleToPrimary),
            borderRadius: 'inherit',
        },
    };
});

export const ProgressBar = ({
    fullWidth = false,
    rounded = true,
    size = 'large',
    ...props
}: ProgressBarProps) => {
    return (
        <StyledLinearProgress
            fullWidth={fullWidth}
            rounded={rounded}
            size={size}
            variant="determinate"
            {...props}
        />
    );
};

import { useEffect } from 'react';
import { useSelector } from '@lingoda/hooks';
import { goTo, onboardingPath, studentHomePath, useRouteMatcher } from '@lingoda/router';
import { studentOnboardedSelector } from '@lingoda/students';
import { currentSubscriptionSelector } from '@lingoda/subscriptions';

/**
 * This hook is used to redirect User to home page if he is already onboarded.
 **/

export const useRedirectToHomeIfShouldNotBeOnboarded = () => {
    const isOnboarded = useSelector(studentOnboardedSelector);
    const isOnboardingPath = useRouteMatcher([{ path: onboardingPath(), matchPrefix: true }]);
    const subscription = useSelector(currentSubscriptionSelector);
    useEffect(() => {
        if (isOnboarded && isOnboardingPath) {
            goTo(studentHomePath());
        }
    }, [isOnboarded, isOnboardingPath, subscription]);
};

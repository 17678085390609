import { forwardRef } from 'react';
import type { TimeFormat } from '@lingoda/dates';
import { isNonNullable } from '@lingoda/utils';
import TextField from '../TextField';
import { colorsPalette, styled } from '../../theme';
import { ClockIcon16 } from '../../icons/lingodaIcons';
import { formatTimeSlot } from './utils';
import { TIME_SLOT_VALUE_FORMAT } from './formats';

const ClockIcon = styled(ClockIcon16)(({ theme }) => ({
    padding: 0,
    color: colorsPalette.primarySolid[100],
    '&.MuiSvgIcon-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            transform: `translateX(6px)`,
        },
    },
}));

interface TimeSlotInputProps {
    name: string;
    label: string;
    disabled?: boolean;
    loading?: boolean;
    onClick: (ev: React.MouseEvent<HTMLDivElement>) => void;
    value?: number;
    timeFormat: TimeFormat;
    opened: boolean;
}

const TimeSlotInputComponent = (
    { name, label, disabled, loading, onClick, value, timeFormat, opened }: TimeSlotInputProps,
    ref: React.Ref<HTMLInputElement>,
) => {
    const renderValue = () => {
        return (value && formatTimeSlot(TIME_SLOT_VALUE_FORMAT[timeFormat], value)) || '';
    };

    return (
        <TextField
            variant="outlined"
            name={name}
            InputLabelProps={{
                shrink: opened || isNonNullable(value),
            }}
            label={label}
            autoComplete="off"
            fullWidth
            onClick={disabled ? undefined : onClick}
            value={renderValue()}
            InputProps={{
                disabled: disabled || loading,
                endAdornment: <ClockIcon />,
            }}
            inputRef={ref}
        />
    );
};

export const TimeSlotInput = forwardRef<HTMLInputElement, TimeSlotInputProps>(
    TimeSlotInputComponent,
);

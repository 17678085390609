import { memo, useCallback } from 'react';
import { actionClick } from '@lingoda/analytics';
import {
    type CourseOverviewLearningUnitFragment,
    StudentLessonStatus,
    StudentStatus,
    useSetLessonsStatusMutation,
} from '@lingoda/graphql';
import { useToggleState } from '@lingoda/hooks';
import { Card, Divider, useUiEvent } from '@lingoda/ui';
import { isLessonCompleted, isLessonNotTaken, isLessonSkipped } from '@lingoda/lesson';
import { LearningUnitActions } from './LearningUnitActions';
import { LearningUnitContent } from './LearningUnitContent';
import { LearningUnitHeader } from './LearningUnitHeader';

const mapToId = (item: { id: number }) => item.id;

interface Props {
    learningUnit: CourseOverviewLearningUnitFragment;
    moduleId: number;
    onUpdate: () => void;
}

const LearningUnitItem = ({ moduleId, learningUnit, onUpdate }: Props) => {
    const addEvent = useUiEvent();
    const [isDisabled, disable, enable] = useToggleState();

    const { lessons } = learningUnit;

    const lessonIds = lessons.map(mapToId);
    const skippedIds = lessons.filter(isLessonSkipped).map(mapToId);
    const notTakenIds = lessons.filter(isLessonNotTaken).map(mapToId);
    const completedIds = lessons.filter(isLessonCompleted).map(mapToId);

    const [isOpen, open, close] = useToggleState(
        ![completedIds.length, skippedIds.length].includes(lessonIds.length),
    );

    const isCompleted = lessonIds.length === completedIds.length;
    const isReadyToRetake = lessons.some(
        (lesson) => ![StudentStatus.Completed, StudentStatus.Retake].includes(lesson.studentStatus),
    );
    const showSkip = !isCompleted && !!notTakenIds.length;
    const showRetake = isReadyToRetake && !notTakenIds.length && !!skippedIds.length;
    const showController = lessonIds.length > 5 || isCompleted;

    const [setLessonsStatus] = useSetLessonsStatusMutation();

    const handleSkip = useCallback(() => {
        disable();
        void setLessonsStatus({
            variables: {
                lessonsStatus: notTakenIds.map((lessonId) => ({
                    lessonId,
                    status: StudentLessonStatus.Skip,
                })),
            },
        }).finally(() => {
            enable();
            onUpdate();
        });
    }, [disable, enable, notTakenIds, onUpdate, setLessonsStatus]);

    const handleRetake = useCallback(() => {
        disable();
        void setLessonsStatus({
            variables: {
                lessonsStatus: skippedIds.map((lessonId) => ({
                    lessonId,
                    status: StudentLessonStatus.Retake,
                })),
            },
        }).finally(() => {
            enable();
            open();
            onUpdate();
        });
    }, [disable, enable, skippedIds, onUpdate, open, setLessonsStatus]);

    const handleExpand = useCallback(() => {
        if (isOpen) {
            close();
            void addEvent(actionClick('Collapse'));
        } else {
            open();
            void addEvent(actionClick('Expand'));
        }
    }, [addEvent, isOpen, open, close]);

    const handleLessonClick = useCallback(() => {
        void addEvent(actionClick('Lesson'));
    }, [addEvent]);

    return (
        <Card variant="outlined" data-cy="Chapter">
            <LearningUnitHeader learningUnit={learningUnit} />
            <Divider />
            <LearningUnitContent
                learningUnit={learningUnit}
                completed={completedIds.length}
                isOpen={isOpen}
                lessons={lessons}
                onClick={handleLessonClick}
                total={lessonIds.length}
                moduleId={moduleId}
            />
            <LearningUnitActions
                isOpen={isOpen}
                showSkip={showSkip}
                onSkip={handleSkip}
                onExpand={handleExpand}
                isDisabled={isDisabled}
                showRetake={showRetake}
                onRetake={handleRetake}
                showController={showController}
            />
        </Card>
    );
};

export default memo(LearningUnitItem);

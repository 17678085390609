import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const InfoFilledIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM7.5 3C7.30222 3 7.10888 3.05865 6.94443 3.16853C6.77998 3.27841 6.65181 3.43459 6.57612 3.61732C6.50043 3.80004 6.48063 4.00111 6.51922 4.19509C6.5578 4.38907 6.65304 4.56725 6.79289 4.70711C6.93274 4.84696 7.11093 4.9422 7.30491 4.98078C7.49889 5.01937 7.69996 4.99957 7.88268 4.92388C8.06541 4.84819 8.22159 4.72002 8.33147 4.55557C8.44135 4.39112 8.5 4.19778 8.5 4C8.5 3.73478 8.39464 3.48043 8.20711 3.29289C8.01957 3.10536 7.76522 3 7.5 3ZM6.75 6C6.33579 6 6 6.33579 6 6.75C6 7.16421 6.33579 7.5 6.75 7.5H7.216V9.91998C7.216 10.4452 7.416 10.9547 7.78112 11.335C8.14723 11.7164 8.65048 11.9366 9.182 11.9366H9.79C10.2042 11.9366 10.54 11.6009 10.54 11.1866C10.54 10.7724 10.2042 10.4366 9.79 10.4366H9.182C9.06852 10.4366 8.95317 10.39 8.8632 10.2963C8.77223 10.2015 8.716 10.0667 8.716 9.91998V7.38333C8.716 7.0261 8.58005 6.67766 8.32896 6.4161C8.07687 6.1535 7.72827 6 7.358 6H6.75Z"
        />
    </SvgIcon>
);

import { type AcceptedLang, fullySupportedLangs, partiallySupportedLangs } from './models';
import { routeLabelsForPartiallySupportedLangs } from './routeLabelsForPartiallySupportedLangs';

export const isFullySupportedLangValid = (lang: AcceptedLang) => {
    return fullySupportedLangs.includes(lang);
};

export const isPartiallySupportedLangValid = (
    lang: AcceptedLang,
    routeLabel: string | undefined,
) => {
    return (
        partiallySupportedLangs.includes(lang) &&
        routeLabel &&
        routeLabelsForPartiallySupportedLangs.includes(routeLabel)
    );
};

import { useState } from 'react';
import { useElementSize } from '@lingoda/hooks';
import { Box, Paper, type PaperProps } from '../../layout';
import { dvh, styled } from '../../theme';

interface Props {
    minHeight?: number;
    ariaLabel?: string;
    sx?: PaperProps['sx'];
}

export type SlidePopupProps = React.PropsWithChildren<Props>;

export const SlidePopup = ({ children, minHeight = 0, ariaLabel, sx }: SlidePopupProps) => {
    const [ref, setRef] = useState<HTMLDivElement | null>();
    const childrenHeight = useElementSize(ref, 'clientHeight');
    const contentHeight = Math.max(childrenHeight, minHeight);

    return (
        <StyledPaper elevation={4} aria-label={ariaLabel} role="dialog" sx={sx}>
            <TransitionHeightBox height={contentHeight}>
                <Box ref={setRef}>{children}</Box>
            </TransitionHeightBox>
        </StyledPaper>
    );
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'relative',
    padding: theme.spacing(3),
    maxHeight: dvh(`calc(100dvh - ${theme.spacing(4)})`),
    overflowY: 'auto',
    width: theme.spacing(60),

    [theme.breakpoints.up('sm')]: {
        right: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const TransitionHeightBox = styled(Box)({
    transition: 'height .2s linear',
});

import { injectAsyncReducer } from '@lingoda/core';
import { BOOKING_FILTER_STORE_KEY, VARS_STORE_KEY } from './constants';
import bookingFilterReducers from './reducers/bookingFilterReducers';
import varsReducers from './reducers/varsReducers';
import initSideEffects from './side-effects';

export * from './actions';
export * from './adapters';
export * from './constants';
export * from './hooks';
export * from './models';
export * from './selectors';
export * from './utils';
export { type BookClassActionResult } from './side-effects';

injectAsyncReducer(BOOKING_FILTER_STORE_KEY, bookingFilterReducers);
injectAsyncReducer(VARS_STORE_KEY, varsReducers);

initSideEffects();

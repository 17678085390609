import { Stack } from '@lingoda/ui';
import { useParams } from '@lingoda/router';
import { trans } from '@lingoda/i18n';
import { AudioProvider } from '@lingoda/audio';
import { useBasicActivityChallengeDataSuspenseQuery } from '@lingoda/graphql';
import { ChallengesPaper } from '../ChallengesPaper';
import { usePrizeIcons } from '../../hooks';
import { ChallengePrizeItem } from './ChallengePrizeItem';

export const ChallengePrizes = () => {
    const { challengeName } = useParams<{ challengeName: string }>();
    const {
        data: { activityChallenge },
    } = useBasicActivityChallengeDataSuspenseQuery({
        variables: { challengeName },
    });
    const { prizes, currentParticipant } = activityChallenge;
    const completedActivities = currentParticipant?.completedActivities ?? 0;

    const { lockedIcons, unlockedIcons } = usePrizeIcons(prizes);

    return (
        <ChallengesPaper title={trans('prizes', {}, 'student-challenges')}>
            <Stack
                direction={{ xs: 'column', smw: prizes.length > 3 ? 'column' : 'row' }}
                spacing={2}
                width="100%"
            >
                <AudioProvider>
                    {prizes.map((prize, index) => (
                        <ChallengePrizeItem
                            key={`${challengeName}-${index}`}
                            noun={prize.noun}
                            explanation={prize.explanation}
                            nounTranslation={prize.nounTranslation}
                            unlocked={completedActivities >= prize.value}
                            lockedIconUrl={lockedIcons[index]}
                            unlockedIconUrl={unlockedIcons[index]}
                            audioUrl={prize.audioUrl}
                        />
                    ))}
                </AudioProvider>
            </Stack>
        </ChallengesPaper>
    );
};

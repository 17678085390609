import { trans } from '@lingoda/i18n';
import { format } from '@lingoda/dates';
import TextField from '../../fields/TextField';
import { CalendarIcon as CalendarIconSvg } from '../../icons/lingodaIcons';
import { colorsPalette, styled } from '../../theme';

const CalendarIcon = styled(CalendarIconSvg)(({ theme }) => ({
    padding: 0,
    color: colorsPalette.primarySolid[100],
    '&.MuiSvgIcon-root': {
        fontSize: '1rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.75rem',
            transform: `translateX(6px)`,
        },
    },
}));

interface Props {
    name: string;
    label: string;
    format?: string;
    disabled?: boolean;
    onClick: (ev: React.MouseEvent<HTMLDivElement>) => void;
    selectedDate?: Date;
}

export const CalendarInput = ({
    name,
    label,
    format: formatStr,
    disabled,
    onClick,
    selectedDate,
}: Props) => {
    const getDisplayDate = () => {
        if (!selectedDate) {
            return '';
        }
        const formatMask = formatStr || 'PPP';

        return format(formatMask, selectedDate);
    };

    return (
        <TextField
            variant="outlined"
            name={name}
            label={label || trans('date-label', {}, 'public-common')}
            placeholder={label || trans('date-label', {}, 'public-common')}
            fullWidth
            onClick={disabled ? undefined : onClick}
            value={getDisplayDate()}
            InputProps={{
                disabled,
                endAdornment: <CalendarIcon />,
            }}
        />
    );
};

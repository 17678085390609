import { createSelector } from 'reselect';
import { TimeFormat, getTimeFormatFromLegacy } from '@lingoda/dates';
import { studentPreferencesSelector } from './studentPreferences';

export const studentTimeFormatPreferenceSelector = createSelector(
    studentPreferencesSelector,
    (preferences) => {
        const timeFormat = preferences?.timeFormat || TimeFormat.Iso8601;

        return getTimeFormatFromLegacy(timeFormat);
    },
);

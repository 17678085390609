import { type PropsWithChildren } from 'react';
import { Box } from '../../../layout/Box';
import { DialogCloseButton } from '../DialogCloseButton';
import type { DialogCloseButtonProps } from '../DialogCloseButton';

interface Props {
    onClose?: () => void;
    closeButtonDisabled?: boolean;
    closeInvertedColor?: boolean;
    noClosePadding?: boolean;
    closeButtonProps?: DialogCloseButtonProps;
}

export const DialogHeader = ({
    onClose,
    children,
    closeInvertedColor,
    noClosePadding = false,
    closeButtonDisabled,
    closeButtonProps,
}: PropsWithChildren<Props>) => {
    return (
        <Box position="relative">
            {children && <Box paddingRight={onClose && !noClosePadding ? 4 : 0}>{children}</Box>}
            {onClose && (
                <Box
                    sx={({ spacing }) => ({
                        position: 'absolute',
                        zIndex: 1,
                        right: spacing(-1.5),
                        top: { xs: spacing(-0.75), sm: spacing(-0.25) },
                    })}
                >
                    <DialogCloseButton
                        disabled={closeButtonDisabled}
                        onClick={onClose}
                        closeInvertedColor={closeInvertedColor}
                        {...closeButtonProps}
                    />
                </Box>
            )}
        </Box>
    );
};

import { memo, useCallback, useEffect } from 'react';
import { addDays, format, isSameDay, isSameDayOrAfter, isSameDayOrBefore } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { useToggleState } from '@lingoda/hooks';
import { Stack, Typography } from '../../layout';
import { colorsPalette, styled } from '../../theme';
import { DatePicker } from '../DatePicker';
import { MuiSelect } from '../MuiSelect';
import FormControl from '../FormControl';
import InputLabel from '../InputLabel';
import ChevronIcon from '../../icons/ChevronIcon';
import { Button, IconButton } from '../../buttons';
import NavigateAfter from '../../icons/NavigateAfter';
import NavigateBefore from '../../icons/NavigateBefore';

const formatDate = format('cccc, d MMM yyyy');
const multipleDatesFormatDate = format('cccc, d MMM');
const getDatesText = (dates: Date[]) => dates.map(multipleDatesFormatDate).join('; ');

export interface DateSelectorProps {
    disabled?: boolean;
    loading?: boolean;
    values: Date[];
    onMonthChange?: (visibleMonth: Date) => void;
    onChange: (newDates: Date[]) => void;
    onModalOpened?: () => void;
    scheduledDays?: Date[];
    id: string;
    minDate: Date;
    maxDate: Date;
    type?: 'single' | 'multiple';
    maxDatesSelected?: number;
    placeholder?: string;
    title?: string;
}

const DateSelectorComponent = ({
    disabled = false,
    loading = false,
    scheduledDays = [],
    onMonthChange,
    onModalOpened,
    id,
    maxDate,
    minDate,
    values,
    onChange,
    type = 'single',
    maxDatesSelected,
    placeholder = trans('select-date', {}, 'student-common'),
    title = trans('select-date', {}, 'student-common'),
}: DateSelectorProps) => {
    const [opened, open, close] = useToggleState(false);
    const isMultiple = type === 'multiple';
    const selectedDate: Date | undefined = values[0];
    const disabledPrev =
        isMultiple || !selectedDate || disabled || isSameDayOrBefore(selectedDate, minDate);
    const disabledNext =
        isMultiple || !selectedDate || disabled || isSameDayOrAfter(selectedDate, maxDate);

    const onPrev = useCallback(() => {
        if (!selectedDate || isMultiple) {
            return;
        }
        const newDate = addDays(selectedDate, -1);
        onChange([newDate]);
    }, [selectedDate, isMultiple, onChange]);

    const onNext = useCallback(() => {
        if (!selectedDate || isMultiple) {
            return;
        }
        const newDate = addDays(selectedDate, +1);
        onChange([newDate]);
    }, [selectedDate, isMultiple, onChange]);

    const renderedValue = useCallback(() => {
        if (values.length > 1) {
            return getDatesText(values);
        } else {
            return selectedDate ? formatDate(selectedDate) : undefined;
        }
    }, [selectedDate, values]);

    const handleDateChange = useCallback(
        (newDates: Date[]) => {
            if (isMultiple) {
                onChange(newDates);
            } else {
                const newDate: Date | undefined = newDates[0];
                const dates =
                    !newDate || (selectedDate && isSameDay(newDate, selectedDate)) ? [] : [newDate];

                onChange(dates);
                close();
            }
        },
        [isMultiple, onChange, selectedDate, close],
    );

    useEffect(() => {
        if (opened) {
            onModalOpened?.();
        }
    }, [opened, onModalOpened]);

    const handleReset = useCallback(() => handleDateChange([]), [handleDateChange]);

    return (
        <FormControl fullWidth>
            {values.length === 0 && (
                <InputLabel
                    disableAnimation
                    shrink={false}
                    focused={false}
                    sx={{
                        lineHeight: '16px',
                        color: colorsPalette.blackAlpha[70],
                        overflow: 'visible',
                    }}
                    data-cy="date-selector-placeholder"
                >
                    {placeholder}
                </InputLabel>
            )}

            <SelectStyled
                onOpen={open}
                open={opened}
                onClose={close}
                twoIcons={!isMultiple}
                MenuProps={{
                    MenuListProps: {
                        variant: 'menu',
                        sx: { p: 3, maxWidth: 440 },
                    },
                }}
                IconComponent={
                    isMultiple
                        ? ChevronIcon
                        : () => (
                              <Stack
                                  direction="row"
                                  sx={{
                                      position: 'absolute',
                                      top: 'calc(50% - 12px)',
                                      right: (theme) => theme.spacing(1),
                                  }}
                              >
                                  <IconButtonStyled
                                      disableFocusRipple
                                      size="small"
                                      disabled={disabledPrev}
                                      onClick={onPrev}
                                      aria-label="Select previous day"
                                  >
                                      <NavigateBefore />
                                  </IconButtonStyled>
                                  <IconButtonStyled
                                      disableFocusRipple
                                      size="small"
                                      disabled={disabledNext}
                                      onClick={onNext}
                                      aria-label="Select next day"
                                  >
                                      <NavigateAfter />
                                  </IconButtonStyled>
                              </Stack>
                          )
                }
                value={selectedDate ?? ''}
                placeholder={placeholder}
                renderValue={renderedValue}
                displayEmpty
                disabled={disabled}
                labelId={id}
                autoWidth
            >
                <Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={3}
                    >
                        <Typography variant="h3">{title}</Typography>
                        <Button
                            size="small"
                            color="grayscale"
                            variant="outlined"
                            onClick={handleReset}
                        >
                            {trans('btn-reset-date', {}, 'student-common')}
                        </Button>
                    </Stack>
                    <DatePicker
                        type={type}
                        dates={values}
                        onChange={handleDateChange}
                        minDate={minDate}
                        maxDate={maxDate}
                        scheduledDays={scheduledDays}
                        onMonthChange={onMonthChange}
                        loading={loading}
                        dotColor="primary"
                        scheduledDayBg="transparent"
                        maxDatesSelected={maxDatesSelected}
                    />
                </Stack>
            </SelectStyled>
        </FormControl>
    );
};

const SelectStyled = styled(MuiSelect, {
    shouldForwardProp: (propName) => propName !== 'twoIcons',
})<{ twoIcons: boolean }>(({ theme, twoIcons }) => ({
    height: theme.spacing(6),
    '& fieldset': {
        borderColor: colorsPalette.blackAlpha[20],
        paddingRight: theme.spacing(7),
    },
    '& .MuiSelect-select': {
        paddingRight: twoIcons ? `${theme.spacing(6)}!important` : theme.spacing(4),
    },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    ':focus': {
        backgroundColor: colorsPalette.blackAlpha[4],
    },
}));

export const DateSelector = memo(DateSelectorComponent);

import { parse } from 'qs';
import { type History } from '@lingoda/router';
import { type DataShape, setCjStorage, tagKeys } from './utils';

export const initCjData = (history: History) => {
    const data = parseData(history.location.search);
    setMarketingData(data);
    stripCjQueryParams(history);
};

export const setMarketingData = (data: Partial<DataShape>) => {
    if (tagKeys.every((key) => !data[key])) {
        return;
    }

    setCjStorage(data);
};

const parseData = (locationSearch: string): Partial<DataShape> => {
    const parsed = parse(locationSearch, { ignoreQueryPrefix: true });

    const validated = Object.fromEntries<string | undefined>(
        tagKeys.map((key) => {
            const value = parsed[key];

            return [key, typeof value === 'string' ? value : undefined];
        }),
    );

    const exp = validated['cj_exp'] && Number.parseInt(validated['cj_exp'], 10);

    return {
        ...validated,
        cj_exp: exp && !isNaN(exp) ? exp : undefined,
    };
};

const stripCjQueryParams = (history: History) => {
    const search = history.location.search;

    if (!search) {
        return;
    }

    const nextSearch = tagKeys.reduce(
        (acc, key) => acc.replace(new RegExp(`&?${key}=[^&]*`), ''),
        search,
    );

    if (nextSearch !== search) {
        history.replace({ ...history.location, search: nextSearch });
    }
};

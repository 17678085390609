import type { ApolloError } from '@lingoda/graphql';
import { getGraphQLErrorsFromApollo } from '@lingoda/graphql';

export const getInactiveLessonIdsFromError = (error: ApolloError): number[] => {
    const apolloError = getGraphQLErrorsFromApollo(error);
    const inactiveLessons: number[] = [];

    apolloError.forEach((error) => {
        const message = error.message ?? '';
        const lessonChar = message.indexOf('Lesson ');
        const inactiveChar = message.indexOf(' is inactive');
        if (lessonChar !== -1 || inactiveChar !== -1) {
            const lessonIdString = message.slice(lessonChar + 7, inactiveChar);
            const lessonId = Number.parseInt(lessonIdString);

            if (Number.isInteger(lessonId)) {
                inactiveLessons.push(lessonId);
            }
        }
    });

    return inactiveLessons;
};

import { TableCell, TableRow, colorsPalette, styled } from '@lingoda/ui';

export const TableRowStyled = styled(TableRow)({
    [`&.Mui-selected`]: {
        backgroundColor: 'transparent',
    },
    [`&.Mui-selected:hover`]: {
        backgroundColor: 'transparent',
    },
    [`&.Mui-selected .MuiTableCell-root`]: {
        backgroundColor: colorsPalette.primaryAlpha[8],
    },
});

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: 'none',
    [`&:first-of-type`]: {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
    },
    [`&:last-of-type`]: {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
    },
}));

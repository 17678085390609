import type { ToggleButtonGroupProps } from '@lingoda/ui/buttons/ToggleButtonGroup';
import { ToggleButtonGroup } from '@lingoda/ui/buttons/ToggleButtonGroup';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { CalendarHour } from '../CalendarHour';
import type { CalendarHourProps } from '../CalendarHour';

const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
    width: '100%',
    flexWrap: 'wrap',
    margin: theme.spacing(-1),
    '& .MuiToggleButtonGroup-groupedHorizontal': {
        '&:not(:first-of-type)': {
            margin: theme.spacing(1),
            borderColor: colorsPalette.blackAlpha[8],
        },
    },
}));

export interface CalendarTimesProps extends Omit<ToggleButtonGroupProps, 'classes'> {
    times: CalendarHourProps[];
}

export const CalendarTimes = ({ times, ...props }: CalendarTimesProps) => (
    <ToggleButtonGroupStyled {...props} exclusive>
        {times.map(({ value, label }) => (
            <CalendarHour data-cy="CalendarHour" value={value} label={label} key={value} />
        ))}
    </ToggleButtonGroupStyled>
);

import { FramePageContainer, Hidden, Stack, TrackingContext } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { useSelector, useToggleState } from '@lingoda/hooks';
import {
    SubscribeUnlockBanner,
    isSprintSubscriptionSelector,
    useSubscribeUnlockBanner,
} from '@lingoda/subscriptions';
import { BannersBlock, ClassCredits } from '../../components';
import { CreditsTypeInfoAlert, CreditsTypeInfoModal } from './CreditsTypeInfo';
import BuyCredits from './BuyCredits';

const ExtraCredits = () => {
    const [isInfoModalOpen, openInfoModal, closeInfoModal] = useToggleState(false);

    const isUnlockBannerVisible = useSubscribeUnlockBanner();
    const isCurrentSubscriptionSprint = useSelector(isSprintSubscriptionSelector);

    return (
        <TrackingContext section="Buy Extra Credits">
            <FramePageContainer
                pt={{ xs: 2, sm: 3, md: 5 }}
                pb={{ xs: 2, sm: 3, md: 5 }}
                data-cy="Buy Extra Credits"
            >
                <Grid
                    container
                    spacing={{ xs: 2, md: 3, lg: 5 }}
                    direction={{ xs: 'column-reverse', md: 'row' }}
                >
                    <Grid md={7}>
                        <Stack spacing={2}>
                            <BuyCredits onCreditsInfoClick={openInfoModal} />
                            {isUnlockBannerVisible ? (
                                <SubscribeUnlockBanner withImage={false} />
                            ) : !isCurrentSubscriptionSprint ? (
                                <BannersBlock />
                            ) : null}
                        </Stack>
                    </Grid>
                    <Grid md={5}>
                        <Stack spacing={2}>
                            <ClassCredits />
                            <Hidden smDown>
                                <CreditsTypeInfoAlert />
                            </Hidden>
                        </Stack>
                    </Grid>
                </Grid>
            </FramePageContainer>
            <CreditsTypeInfoModal isOpen={isInfoModalOpen} onClose={closeInfoModal} />
        </TrackingContext>
    );
};

export default ExtraCredits;

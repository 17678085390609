import { useRef } from 'react';
import { useToggleState } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { styled } from '../theme';
import { Menu } from '../layout/Menu';
import { MenuItem } from '../layout/MenuItem';
import ChevronIcon from '../icons/ChevronIcon';
import { Button } from './Button';
import ButtonGroup from './ButtonGroup';
import type { ButtonGroupProps } from './ButtonGroup';

interface Option {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    action?: () => void;
}

interface Props extends Omit<ButtonGroupProps, 'variant'> {
    options: Option[];
    variant?: Exclude<ButtonGroupProps['variant'], 'text'>;
}

const SplitButton = ({ options: [mainOption, ...options], children, variant, ...other }: Props) => {
    const anchorRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, open, close] = useToggleState();
    const isDisabled = options.every((option) => option.disabled);

    return (
        <>
            <ButtonGroup ref={anchorRef} {...other}>
                <Button
                    onClick={mainOption.action}
                    disabled={mainOption.disabled}
                    loading={mainOption.loading}
                    variant={variant}
                >
                    {children ?? mainOption.label}
                </Button>
                <Button
                    size="small"
                    onClick={open}
                    sx={{ width: 50 }}
                    disabled={isDisabled}
                    variant="outlined"
                    aria-label={trans('show-more', {}, 'student-common')}
                >
                    <ChevronIcon />
                </Button>
            </ButtonGroup>
            <MenuStyled
                open={isOpen}
                anchorEl={anchorRef.current}
                onClose={close}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options.map(({ label, action, disabled }, i) => (
                    <MenuItemStyled key={i} onClick={action} disabled={disabled}>
                        {label}
                    </MenuItemStyled>
                ))}
            </MenuStyled>
        </>
    );
};

const MenuStyled = styled(Menu)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        '& .MuiMenu-list': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
    },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
        textAlign: 'center',
    },
}));

export default SplitButton;

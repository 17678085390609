import { useCallback } from 'react';
import { usePersistentState } from '@lingoda/hooks';

const useLeadConversionFlowState = (userId: number) => {
    const [state, setState] = usePersistentState<string>('LEAD_FLOW_COMPLETE', '');

    const completionList = parseState(state);
    const isComplete = completionList.includes(`${userId}`);

    const complete = useCallback(() => {
        if (isComplete) {
            return;
        }

        setState([...completionList, userId].join(DELIMITER));
    }, [completionList, isComplete, setState, userId]);

    return [isComplete, complete] as const;
};

const DELIMITER = ',';
const emptyList: string[] = [];

const parseState = (state: unknown) =>
    typeof state === 'string' && state !== '' ? state.split(DELIMITER) : emptyList;

export default useLeadConversionFlowState;

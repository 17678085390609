import type { TypographyProps } from '@lingoda/ui';
import { Box, Typography } from '@lingoda/ui';
import type { AcceptedLang } from '@lingoda/env';
import { useGetRandomizedCongrats } from './useGetRandomizedCongrats';
import type { PropsWithChildren } from 'react';

export interface RandomCongratsMessageProps {
    firstName?: string;
    // set it in case the congrats message lang should be different from the current locale
    congratsMsgLocale?: AcceptedLang;
    typographyProps?: TypographyProps;
}

export const RandomCongratsText = ({
    children,
    firstName = '',
    congratsMsgLocale,
    typographyProps,
}: PropsWithChildren<RandomCongratsMessageProps>) => {
    const { variant = 'h2', textAlign = 'center', ...restTypographyProps } = typographyProps || {};
    const congrats = useGetRandomizedCongrats(firstName, congratsMsgLocale);

    return (
        <Typography variant={variant} textAlign={textAlign} {...restTypographyProps}>
            <Box>{congrats}</Box>
            {children}
        </Typography>
    );
};

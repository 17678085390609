import Checkbox from '@mui/material/Checkbox';
import { Icon, IconChecked, IconCheckedDisabled, IconIndeterminate } from './Icons';
import type { FC } from 'react';
import type { CheckboxProps } from '@mui/material/Checkbox';
export type { CheckboxProps };

const CustomCheckbox: FC<CheckboxProps> = (props) => (
    <Checkbox
        icon={<Icon />}
        indeterminateIcon={<IconIndeterminate />}
        checkedIcon={props.disabled ? <IconCheckedDisabled /> : <IconChecked />}
        {...props}
    />
);

export default CustomCheckbox;

import { createAction, makeActionTrackable } from '@lingoda/core';

export interface Changes {
    nextClass: boolean | undefined;
    oneClickBooking: boolean | undefined;
    marketingAgreement: boolean | undefined;
    emailCancelledClassConfirmation: boolean | undefined;
    emailBookedClassConfirmation: boolean | undefined;
    emailClassReminder: boolean | undefined;
    emailWeeklyReport: boolean | undefined;
}

export default makeActionTrackable(
    createAction(
        'student/UPDATE_SETTINGS_NOTIFICATIONS',
        (studentId: number | undefined, changes: Changes) => ({
            changes,
            studentId,
        }),
    ),
);

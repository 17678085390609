import { httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';

type LingodaFeedbackApiAttributes = {
    feedback?: string;
    rating: number;
};

export const submitLingodaFeedback = (payload: LingodaFeedbackApiAttributes) =>
    httpPost(getUrl('api_feedback_create'), payload);

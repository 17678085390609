import type { RouteConfig } from 'react-router-config';

export enum UrlProvider {
    External = 'external',
    Student = 'student',
}

export interface PathAttributes {
    provider: UrlProvider;
    href: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: { [key: string]: any };
}

export type Path = string | InterfaceToType<PathAttributes>;

export type PathFunction = <P>(params?: P, locale?: string) => Path;

export enum TabValue {
    BookClasses = 'bookClasses',
    CourseOverview = 'courseOverview',
    MyClasses = 'myClasses',
    Practice = 'practice',
    Settings = 'settings',
    Store = 'store',
    Home = 'home',
    Writing = 'writing',
    LivePractice = 'livePractice',
}

export type { RouteConfig };

export type DynamicOption<T> = T | (() => T);

type RouteResolutionVariant = 'phablet';
export type ResolutionDependentOption = boolean | RouteResolutionVariant;

export interface RouteConfigOption {
    isPublic?: boolean;
    logoIsIcon?: boolean;
    headerIsPortal?: boolean;
    hideHeader?: ResolutionDependentOption;
    hideFooter?: DynamicOption<boolean>;
    hideNavigation?: boolean;
    hideMobileBottomNavbar?: boolean;
    hideNotifications?: boolean;
    hideDixa?: true;
    disableLogoLink?: boolean;
    theme?: 'dark' | 'light';
    navigationElevation?: number;
    /**
     * @deprecated This option interrupts native scroll restoration behavior.
     */
    pageNotScrollable?: DynamicOption<boolean>;
    contentBg?: DynamicOption<string | undefined>;
    tab: TabValue | undefined;
    backgroundColor?: DynamicOption<string | undefined>;
}

export interface RouteConfigExtended extends RouteConfig {
    label: DynamicOption<string>;
    config: RouteConfigOption | undefined;
}

import { memo } from 'react';
import { trans } from '@lingoda/i18n';
import { Grid, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';

interface Props {
    hasTeacher: boolean;
    classTypeInfo: string;
}

const ClassInfoComponent = ({ classTypeInfo, hasTeacher }: Props) => (
    <>
        <Grid item>
            <Typography
                variant="overline"
                data-cy="classInfo_classTypeInfo"
                htmlColor={colorsPalette.blackAlpha[70]}
            >
                {classTypeInfo}
            </Typography>
        </Grid>
        {!hasTeacher && (
            <Grid item>
                <Typography variant="overline" htmlColor={colorsPalette.blackAlpha[70]}>
                    {trans('teacher-will-be-assigned', {}, 'student-common')}
                </Typography>
            </Grid>
        )}
    </>
);

export const ClassInfo = memo(ClassInfoComponent);

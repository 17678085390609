import { trans } from '@lingoda/i18n';
import { Button, Stack, Typography } from '@lingoda/ui';
import { showApolloErrorToast, showToast } from '@lingoda/toasts';
import type { ActivityChallengeParticipantStatusEnum } from '@lingoda/graphql';
import { useJoinActivityChallenge } from '../../hooks/useJoinActivityChallenge';

interface UnjoinedChallengeBlockProps {
    challengeName: string;
    description: string;
    joiningDisabled?: boolean;
    descriptionColor: string;
    userStatus?: ActivityChallengeParticipantStatusEnum;
    buttonsColor?: string;
}

const onSuccess = () => {
    showToast('success', trans('btn-challenge-joined', {}, 'student-challenges'));
};

export const UnjoinedChallengeBlock = ({
    challengeName,
    description,
    joiningDisabled,
    descriptionColor,
    buttonsColor,
    userStatus,
}: UnjoinedChallengeBlockProps) => {
    const [joinChallenge, { loading }] = useJoinActivityChallenge({
        challengeName,
        userStatus,
        onSuccess,
        onError: showApolloErrorToast,
    });
    const handleJoinChallenge: React.MouseEventHandler = (event) => {
        // NOTE: keep preventDefault() to avoid wrapper Link navigation
        event.preventDefault();

        return joinChallenge();
    };

    return (
        <Stack spacing={2}>
            <Typography variant="body2" htmlColor={descriptionColor}>
                {description}
            </Typography>
            <Stack direction="row" spacing={2} color={buttonsColor}>
                <Button
                    // outlined loader is very thin and with custom buttons color sometimes not visible
                    variant={loading && buttonsColor ? 'contained' : 'outlined'}
                    color={buttonsColor ? 'inherit' : 'primary'}
                    size="small"
                    disabled={joiningDisabled}
                    loading={loading}
                    onClick={handleJoinChallenge}
                >
                    {trans('btn-join-challenge', {}, 'student-challenges')}
                </Button>
                <Button variant="text" color={buttonsColor ? 'inherit' : 'primary'} size="small">
                    {/* no need in onClick because entire widget wrapper is a link */}
                    {trans('btn-see-details', {}, 'student-challenges')}
                </Button>
            </Stack>
        </Stack>
    );
};

import { useEffect, useRef } from 'react';
import { actionScroll, useUiEvent } from '@lingoda/analytics';
import { useToggleState } from '@lingoda/hooks';

interface Options {
    callback: () => Promise<unknown>;
    element: HTMLElement | null;
}

export const useInfiniteScroll = ({ callback, element }: Options) => {
    const addEvent = useUiEvent();
    const observer = useRef<IntersectionObserver>();
    const callbackRef = useRef(callback);
    const [isFetching, fetching, fetched] = useToggleState();

    callbackRef.current = callback;

    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect();
        }

        if (!element) {
            return;
        }

        observer.current = new IntersectionObserver((entries) => {
            if (!isFetching && entries[0].isIntersecting) {
                fetching();
                void addEvent(actionScroll('Infinite scroll'));
                void callbackRef.current().finally(fetched);
            }
        });
        observer.current.observe(element);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element, isFetching]);

    return isFetching;
};

import ContextProviders from 'components/ContextProviders';
import { type PropsWithChildren } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@lingoda/monitor';
import { Suspense } from '@lingoda/ui';
import { InternalErrorPage } from '@lingoda/fallbacks';
import { getDashLang } from '@lingoda/env';

const FallbackError = () => (
    <ContextProviders locale={getDashLang()}>
        <Suspense variant="linear">
            <InternalErrorPage />
        </Suspense>
    </ContextProviders>
);

const ErrorBoundary = ({ children }: PropsWithChildren<unknown>) => (
    <SentryErrorBoundary fallback={FallbackError}>{children}</SentryErrorBoundary>
);

export default ErrorBoundary;

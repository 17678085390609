import { useParams } from '@lingoda/router';
import { showApolloErrorToast, showToast } from '@lingoda/toasts';
import { trans } from '@lingoda/i18n';
import { useBasicActivityChallengeDataSuspenseQuery } from '@lingoda/graphql';
import { getChallengeTexts } from '../../utils';
import { useJoinActivityChallenge } from '../../hooks';
import { TopSectionLayout } from './TopSectionLayout';

const onSuccess = () =>
    showToast('success', trans('btn-challenge-joined', {}, 'student-challenges'));

export const TopSection = () => {
    const { challengeName } = useParams<{ challengeName: string }>();
    const {
        data: { activityChallenge },
    } = useBasicActivityChallengeDataSuspenseQuery({
        variables: { challengeName },
    });
    const { subtitle } = getChallengeTexts(challengeName, activityChallenge.startDate);

    const [joinChallenge, { loading }] = useJoinActivityChallenge({
        challengeName,
        userStatus: activityChallenge.currentParticipant?.status,
        onSuccess,
        onError: showApolloErrorToast,
    });

    return (
        <TopSectionLayout
            title={activityChallenge.publicName}
            subtitle={subtitle}
            startDate={activityChallenge.startDate}
            endDate={activityChallenge.endDate}
            joined={!!activityChallenge.currentParticipant}
            participants={activityChallenge.participants}
            onJoinClick={joinChallenge}
            joiningLoading={loading}
            desktopImageUrl={activityChallenge.desktopBackgroundImage?.urls.original}
            mobileImageUrl={activityChallenge.mobileBackgroundImage?.urls.original}
            desktopBackgroundTheme={activityChallenge.desktopBackgroundTheme}
            mobileBackgroundTheme={activityChallenge.mobileBackgroundTheme}
        />
    );
};

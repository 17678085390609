import { type ReactNode } from 'react';
import {
    Form as FormElement,
    Formik,
    type FormConfig as FormikConfig,
    buildValidator,
} from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { type OptionType } from './OptionsField';

interface FormProps {
    onSubmit: FormConfig['onSubmit'];
    children?: ReactNode;
}

export const Form = (props: FormProps) => {
    return (
        <Formik<FormValues>
            initialValues={{ options: [], feedback: '' }}
            validate={validate}
            {...props}
        >
            <FormElement noValidate>{props.children}</FormElement>
        </Formik>
    );
};

const validate: FormConfig['validate'] = (values) => {
    const isFeedbackRequired = values.options.some((option) => option.slug === 'other');

    const schema = {
        options: {
            notEmptyArray: trans('dialog-input-options-required', {}, 'quiz-report-item'),
        },
        feedback: {
            required: isFeedbackRequired
                ? trans('dialog-input-comments-required', {}, 'quiz-report-item')
                : false,
        },
    };

    return buildValidator(schema)(values);
};

export interface FormValues {
    options: OptionType[];
    feedback: string;
}

export type FormConfig = FormikConfig<FormValues>;

import { Box, Grid, Skeleton, Stack, Typography } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';

const GridContainer = styled(Grid)(({ theme }) => ({
    textAlign: 'center',
    background: theme.palette.common.white,
    marginTop: '-1px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(3),
    ':first-of-type': {
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
    },
    ':last-of-type': {
        borderBottomLeftRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
    },
}));

export const ClassListItemSkeleton = () => (
    <GridContainer container direction="row">
        <Grid item xs="auto">
            <Box width={64} mr={1}>
                <Typography variant="subtitle2">
                    <Skeleton variant="text" width={40} />
                </Typography>
            </Box>
        </Grid>
        <Grid item xs={9}>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                    <Stack spacing={1}>
                        <Typography variant="overline">
                            <Skeleton variant="text" width="80%" />
                        </Typography>
                        <Typography variant="h6">
                            <Skeleton variant="text" width="80%" />
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Grid item>
                            <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="circular" width={28} height={28} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="circular" width={28} height={28} />
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    </GridContainer>
);

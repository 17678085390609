import { v4 as uuidV4 } from 'uuid';
import { type Action } from '@lingoda/core';
import { createDate } from '@lingoda/dates';
import { type Notification, type NotificationPayload } from '../models';
import { checkNotificationSeen } from '../utils';

const type = 'notifications/ADD_NOTIFICATION';

const actionCreator = <T extends keyof NotificationPayload>(
    notificationType: T,
    payload?: NotificationPayload[T],
    iconKey?: string,
): Action<Notification<NotificationPayload[T]>> => {
    const notification: Notification<NotificationPayload[T]> = {
        type: notificationType,
        id: uuidV4(),
        payload,
        timestamp: createDate(),
        seen: checkNotificationSeen({ type: notificationType, payload }),
        iconKey,
    };

    return {
        type,
        payload: notification,
    };
};

actionCreator.toString = () => type;

export default actionCreator;

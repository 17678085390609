import { useEffect, useRef } from 'react';
import { useMercureEventSource } from './useMercureEventSource';
import type { MercureEventSource } from '../../lib/mercure-event-source';

export const useMercureListener = (...args: Parameters<MercureEventSource['addListener']>) => {
    const [topic, types, listener] = args;

    const client = useMercureEventSource();

    const listenerRef = useRef(listener);
    listenerRef.current = listener;

    const typesRef = useRef(types);
    typesRef.current = types;

    useEffect(() => {
        const unlisten = client.addListener(topic, typesRef.current, listenerRef.current);

        return () => {
            unlisten();
        };
    }, [client, topic]);
};

import { Stack } from '@lingoda/ui';
import { ChecklistItemSkeleton } from './ChecklistItemSkeleton';

export const ChecklistSkeleton = () => {
    return (
        <Stack spacing={2}>
            <ChecklistItemSkeleton />
            <ChecklistItemSkeleton />
            <ChecklistItemSkeleton />
            <ChecklistItemSkeleton />
            <ChecklistItemSkeleton />
        </Stack>
    );
};

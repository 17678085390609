import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const ChevronLeft = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 12 18">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9945 17.5279C11.4338 17.0886 11.4338 16.3763 10.9945 15.9369L4.28996 9.23242L10.9945 2.52792C11.4338 2.08858 11.4338 1.37627 10.9945 0.936928C10.5551 0.497587 9.84282 0.497587 9.40348 0.936928L1.10794 9.23242L9.40348 17.5279C9.84282 17.9673 10.5551 17.9673 10.9945 17.5279Z"
            fill="currentColor"
        />
    </SvgIcon>
);

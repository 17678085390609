import { handleActionsImmutably } from '@lingoda/core';
import { setCurrentRoute, setCurrentSection } from './actions';
import { RouteRecordFactory } from './models';
import type { RouteState } from './models';

type SectionAction = ReturnType<typeof setCurrentSection>;
type RouteAction = ReturnType<typeof setCurrentRoute>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default handleActionsImmutably<RouteState, any>(
    {
        [`${setCurrentSection}`]: (state, { payload }: SectionAction) => {
            state.currentSection = payload;

            return state;
        },
        [`${setCurrentRoute}`]: (state, { payload }: RouteAction) => {
            state.currentRoute = payload;

            return state;
        },
    },
    RouteRecordFactory({}),
);

import { useCallback } from 'react';
import { actionClick } from '@lingoda/analytics';
import { styled } from '@lingoda/ui/theme';
import { useUiEvent } from '@lingoda/ui';
import LingodaClassroom from '../lingoda-classroom.svg?component';

interface Props {
    width?: string | number;
    height?: string | number;
}

const ClassroomLogo = styled(LingodaClassroom)({});

export const LingodaClassroomLogo = ({ width, height }: Props) => {
    const addEvent = useUiEvent();
    const iconClick = useCallback(() => addEvent(actionClick('Lingoda icon')), [addEvent]);

    return <ClassroomLogo sx={{ width, height, maxWidth: 'none' }} onClick={iconClick} />;
};

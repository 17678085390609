import { useEffect, useRef } from 'react';
import type { DependencyList, EffectCallback } from 'react';

export const useChange = (callback: EffectCallback, deps: DependencyList) => {
    const init = useRef(false);

    useEffect(() => {
        if (init.current) {
            callback();
        }
        init.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [init, ...deps]);
};

import { buildValidator } from '@lingoda/forms';

export interface FormValues {
    isHelpful?: boolean;
    description?: string;
}

export const initialValues: FormValues = {
    isHelpful: undefined,
    description: '',
};

export const validate = buildValidator({
    isHelpful: {
        required: true,
        boolean: true,
    },
});

export interface Translations {
    isHelpful: { label: string; options: [string, string] };
    description: { label: string };
}

import type { Gateway } from '@lingoda/api';

export interface PaymentMethodAttributes {
    id: string;
    logos: string[];
    stored: boolean;
    text: string;
    type: string;
    default: boolean;
    currencies: string[];
    gateway: Gateway;
}

export enum PaymentGateway {
    STRIPE,
    ADYEN,
}

export interface PaymentOptions {
    gateway: PaymentGateway;
}

export type PaymentMethod = Readonly<PaymentMethodAttributes>;

import type { WeeklyProgressQuery } from '@lingoda/graphql';
import { goalWasReached } from '../WeeklyProgress/utils';

type WeeklyProgress = Unpacked<WeeklyProgressQuery['studentGoalWidgetData']['weeklyProgress']>;

type CelebrationWeeklyProgress = Pick<WeeklyProgress, 'goal' | 'attendance'> & {
    weekId: string;
};

export const getWeeklyProgressToCelebrate = <T extends CelebrationWeeklyProgress>(
    weeklyProgress: T[],
    lastCelebratedWeekId: string | undefined,
) => {
    const weekToCelebrate = weeklyProgress
        .slice(0, 2)
        .find(({ goal, attendance }) => goalWasReached(goal, attendance));

    const shouldCelebrate = weekToCelebrate && weekToCelebrate.weekId !== lastCelebratedWeekId;

    return shouldCelebrate ? weekToCelebrate : undefined;
};

import { useEffect } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useDispatch } from '@lingoda/hooks';
import { useGoalSetting } from '../../../hooks';

export const useSetGoalDialogWeeklyClasses = () => {
    const dispatch = useDispatch();

    const { data, loading } = useGoalSetting();
    const shouldOpen = !loading && !!data && data.isGoalSettingEnabled && !data.goalWasSet;

    useEffect(() => {
        if (shouldOpen) {
            dispatch(showDialog(DialogComponent.SetGoalWeeklyClasses));
        }
    }, [dispatch, shouldOpen]);
};

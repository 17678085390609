import { Paper, Stack, Typography } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import ChangeSubBanner from './ChangeSubBanner';

export const BannersBlock = () => {
    return (
        <Paper elevation={2}>
            <Stack gap={4} p={3}>
                <Typography variant="h2">
                    {trans('credits-store-banners-title', {}, 'student-store')}
                </Typography>
                <ChangeSubBanner />
            </Stack>
        </Paper>
    );
};

import {
    default as MuiAvatarGroup,
    type AvatarGroupProps as MuiAvatarGroupProps,
} from '@mui/material/AvatarGroup';
import React from 'react';
import { type SxProps, type Theme, colorsPalette } from '../../theme';

export interface AvatarGroupProps extends MuiAvatarGroupProps {
    counterSize: AvatarGroupCounterSize;
}

export const AvatarGroup = (props: AvatarGroupProps) => {
    const { counterSize, ...passProps } = props;

    return (
        <MuiAvatarGroup
            {...passProps}
            componentsProps={{
                additionalAvatar: {
                    sx: getAdditionalAvatarSx(props),
                },
            }}
        />
    );
};

type AvatarGroupCounterSize = 24 | 40;

const getAdditionalAvatarSx =
    ({ counterSize, ...props }: AvatarGroupProps): SxProps<Theme> =>
    (theme) => ({
        width: 'auto',
        minWidth: `calc(${counterSize}px + ${theme.spacing(0.5)})`,
        height: `calc(${counterSize}px + ${theme.spacing(0.5)})`,
        color: colorsPalette.blackAlpha[70],
        backgroundColor: colorsPalette.blackSolid[8],
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(1),
        borderRadius: `${counterSize}px`,
        zIndex: React.Children.count(props.children),
        ...getAdditionalAvatarTypographyByFontSize(theme, counterSize),

        // Increase specificity to override mui's default `content-box` style
        '&.MuiAvatarGroup-avatar': {
            boxSizing: 'border-box',
        },

        ...props.componentsProps?.additionalAvatar,
    });

const getAdditionalAvatarTypographyByFontSize = (
    { typography }: Theme,
    size: AvatarGroupCounterSize,
) =>
    ({
        24: typography.body3,
        40: typography.subtitle1,
    })[size];

import { clearCacheAction, handleActionsImmutably } from '@lingoda/core';
import { addClasses, removeClass } from '../actions';
import { ClassFactory } from '../models';
import type { Class } from '../models';

type AddClassesAction = ReturnType<typeof addClasses>;
type RemoveClassAction = ReturnType<typeof removeClass>;

export type State = Record<string, Class>;

type Payload = AddClassesAction['payload'] & RemoveClassAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${addClasses}`]: (state: State, { payload }: AddClassesAction) =>
            payload.reduce((newState, klass) => {
                if (klass.id) {
                    newState[klass.id] = ClassFactory(klass);
                }

                return newState;
            }, state),
        [`${removeClass}`]: (state: State, { payload }: RemoveClassAction) => {
            delete state[payload];

            return state;
        },
        [`${clearCacheAction}`]: () => ({}),
    },
    {},
);

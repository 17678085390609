import { assert } from '@lingoda/utils';
import {
    type PersonalizedFeedbackQuestionsQuery,
    type PersonalizedFeedbackSubmitMutationVariables,
} from '@lingoda/graphql';
import { updatePreferences } from '@lingoda/students';
import { createDate, formatIso } from '@lingoda/dates';
import { type FormValues, type Translations } from '../PersonalFeedbackSatisfactionForm';

export const getFormTranslations = (
    data: PersonalizedFeedbackQuestionsQuery['personalizedFeedbackQuestions'],
): Translations => {
    const [firstQuestion, secondQuestion] = data;

    if (!firstQuestion || !secondQuestion) {
        throw new Error("Can't transform BE data to form translations: data is not complete");
    }

    return {
        isHelpful: {
            label: firstQuestion.question,
            options: [
                firstQuestion.answers?.[0]?.answer || '',
                firstQuestion.answers?.[1]?.answer || '',
            ],
        },
        description: {
            label: secondQuestion.question,
        },
    };
};

export const transformFormValuesToPayload = (
    values: FormValues,
): PersonalizedFeedbackSubmitMutationVariables => {
    const { isHelpful, description } = values;

    assert(typeof isHelpful === 'boolean', 'Invalid argument');

    return { firstAnswer: isHelpful ? 'Yes' : 'No', secondAnswer: description || '' };
};

export const submitDismissState = () => {
    return updatePreferences({
        dismissedWidgets: { personalizedFeedbackDismissedAt: formatIso(createDate()) },
    });
};

import { TermsAndPrivacy } from '@lingoda/legal';
import { trans } from '@lingoda/i18n';
import { Button, Grid } from '@lingoda/ui';

interface Props {
    onSubmit: () => Promise<unknown>;
    isSubmitting: boolean;
}

export const RegisterSocialForm = ({ onSubmit, isSubmitting }: Props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TermsAndPrivacy />
            </Grid>
            <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onSubmit}
                    disabled={isSubmitting}
                    fullWidth
                >
                    {trans('keep-me-posted-btn', {}, 'social-auth')}
                </Button>
            </Grid>
        </Grid>
    );
};

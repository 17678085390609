import { memo } from 'react';
import FormControl from '@lingoda/ui/fields/FormControl';
import FormHelperText from '@lingoda/ui/fields/FormHelperText';
import type { RadioGroupProps } from '@lingoda/ui/fields/RadioGroup';
import RadioGroup from '@lingoda/ui/fields/RadioGroup';
import { ErrorMessage, Field } from '../../utils';
import type { FieldProps } from '../../utils';

type Props = RadioGroupProps & { required?: boolean };

const FormikRadioGroup: React.FC<Props> = (props) => (
    <Field {...props}>
        {({ field, form: { errors, touched } }: FieldProps) => {
            const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                field.onChange(event);
                if (props.onChange) {
                    props.onChange(event, value);
                }
            };

            return (
                <FormControl
                    error={touched && !!touched[field.name] && errors && !!errors[field.name]}
                    required={props.required}
                >
                    <RadioGroup {...props} {...field} onChange={handleOnChange}>
                        {props.children}
                    </RadioGroup>
                    <ErrorMessage name={field.name}>
                        {(message) => (
                            <FormHelperText>
                                {typeof message === 'object'
                                    ? Object.values(message).map((msg, index) => (
                                          <p key={`${index}`}>{msg as string}</p>
                                      ))
                                    : message}
                            </FormHelperText>
                        )}
                    </ErrorMessage>
                </FormControl>
            );
        }}
    </Field>
);

export default memo(FormikRadioGroup);

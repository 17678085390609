import { handleActionsImmutably } from '@lingoda/core';
import { addCredits } from '../actions';
import type { Credit } from '../models/credit';

export interface State {
    [key: number]: Credit;
}

type CreditsAction = ReturnType<typeof addCredits>;

export default handleActionsImmutably<State, Credit[]>(
    {
        [`${addCredits}`]: (state, { payload }: CreditsAction) => {
            (payload || []).forEach((credit) => {
                if (credit.id) {
                    state[credit.id] = credit;
                }
            });

            return state;
        },
    },
    {},
);

import type { AuthTokenStatus } from '@lingoda/graphql';
import { wasAnyCalendarEverConnected } from './wasAnyCalendarEverConnected';

export const shouldShowConnectCalendarDialogForMissedClass = (
    calendarStatuses: (AuthTokenStatus | null)[],
    wasAlreadyShownInSession: boolean,
) => {
    return (
        !!calendarStatuses.length &&
        !wasAnyCalendarEverConnected(calendarStatuses) &&
        !wasAlreadyShownInSession
    );
};

import { useCallback, useState } from 'react';
import { Box, Hidden } from '@lingoda/ui';
import { studentTimeFormatPreferenceSelector } from '@lingoda/students';
import { useSelector } from '@lingoda/hooks';
import { BookingFilterMobile } from './BookingFilterMobile';
import {
    BookingFilterDesktopSlots,
    type BookingFilterDesktopSlotsProps,
} from './BookingFilterDesktopSlots';

export type BookingFilterProps = Pick<
    BookingFilterDesktopSlotsProps,
    'value' | 'onChange' | 'onReset'
>;

export const BookingFilter = ({ value, onChange, onReset }: BookingFilterProps) => {
    const timeFormat = useSelector(studentTimeFormatPreferenceSelector);
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = useCallback(() => {
        setModalOpen(!isModalOpen);
    }, [isModalOpen, setModalOpen]);

    return (
        <>
            <Hidden mdDown>
                <Box my={2}>
                    <BookingFilterDesktopSlots
                        value={value}
                        onChange={onChange}
                        onReset={onReset}
                        timeFormat={timeFormat}
                    />
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Box my={2}>
                    <BookingFilterMobile
                        value={value}
                        onChange={onChange}
                        onReset={onReset}
                        isModalOpen={isModalOpen}
                        toggleModal={toggleModal}
                        timeFormat={timeFormat}
                    />
                </Box>
            </Hidden>
        </>
    );
};

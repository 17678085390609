import { trans } from '@lingoda/i18n';
import { Box, Chip, Stack, colorsPalette, styled } from '@lingoda/ui';
import { ClassStatusHeader } from './ClassStatusHeader';

export const ClassStatusLive = () => {
    const chip = (
        <ChipStyled aria-hidden color="error" label={trans('live-label', {}, 'student-common')} />
    );

    return (
        <ClassStatusHeader color="gradient" sx={{ textAlign: 'initial' }}>
            <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
            >
                <Box flexShrink={0}>{chip}</Box>
                <Box textAlign="center">{trans('class-started', {}, 'student-classes')}</Box>
                <Box flexShrink={0} visibility="hidden">
                    {chip}
                </Box>
            </Stack>
        </ClassStatusHeader>
    );
};

const ChipStyled = styled(Chip)(({ theme }) => ({
    textTransform: 'uppercase',
    backgroundColor: colorsPalette.negativeSolid[100],
    color: colorsPalette.whiteSolid[100],

    '.MuiChip-label::before': {
        display: 'inline-block',
        marginRight: theme.spacing(0.25),
        background: colorsPalette.whiteSolid[100],
        borderRadius: '50%',
        content: '""',
        width: '0.5rem',
        height: '0.5rem',
    },
}));

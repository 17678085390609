import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { fetchSubscriptions } from '../actions';
import { areSubscriptionsRequestedSelector } from '../selectors';
import { expandedSubscriptionByIdSelectorFactory } from '../expanded';

export const useSubscription = (subscriptionId: number) => {
    const dispatch = useDispatch();
    const areSubscriptionsRequested = useSelector(areSubscriptionsRequestedSelector);
    const subscriptionSelector = useMemo(
        () => expandedSubscriptionByIdSelectorFactory(subscriptionId),
        [subscriptionId],
    );

    const subscription = useSelector(subscriptionSelector);

    useEffect(() => {
        if (!subscription && !areSubscriptionsRequested) {
            dispatch(fetchSubscriptions());
        }
    }, [areSubscriptionsRequested, dispatch, subscription, subscriptionId]);

    return subscription;
};

import { useMemo, useState } from 'react';
import { DialogConfirmTitle, DialogContent, Link, Stack, Typography } from '@lingoda/ui';
import DialogActions from '@lingoda/ui/feedback/dialogs/DialogConfirm/DialogActions';
import { Translate, trans, transChoice } from '@lingoda/i18n';
import { addWeeks, formatDateDMY } from '@lingoda/dates';
import { externalRolloverPauseLetUsKnowPath } from '@lingoda/router';
import { SelectionElement } from './SelectionElement';
import { ClassesInPauseAlert } from './ClassesInPauseAlert';

interface SelectionStepProps {
    pauseStartDate: Date;
    onConfirm: (pauseEndDate: Date) => void;
    onDismiss: () => void;
    onClose: () => void;
    checkClassesDuringPause: (endDate: Date) => boolean;
}
export const SelectionStep = ({
    pauseStartDate,
    onDismiss,
    onConfirm,
    onClose,
    checkClassesDuringPause,
}: SelectionStepProps) => {
    const [currentPauseWeeks, setCurrentPauseWeeks] = useState(0);

    const pauseEndDate = addWeeks(pauseStartDate, currentPauseWeeks);

    const isClassesBetweenDates = useMemo(() => {
        return checkClassesDuringPause(pauseEndDate);
    }, [pauseEndDate, checkClassesDuringPause]);

    return (
        <>
            <DialogConfirmTitle onClose={onClose}>
                {trans('rollover-pause-choose-period', {}, 'student-common')}
            </DialogConfirmTitle>
            <DialogContent
                sx={{
                    pt: 0,
                    pb: 3,
                    px: {
                        xs: 2,
                        sm: 4,
                    },
                }}
            >
                <Typography variant="body1">
                    {trans(
                        'rollover-pause-your-pause-starts',
                        { startDate: formatDateDMY(pauseStartDate) },
                        'student-common',
                    )}
                </Typography>
                <Stack spacing={2} my={3}>
                    {PAUSE_WEEKS.map((weeks) => (
                        <SelectionElement
                            key={weeks}
                            isSelected={weeks === currentPauseWeeks}
                            startDate={pauseStartDate}
                            endDate={addWeeks(pauseStartDate, weeks)}
                            onClick={() => setCurrentPauseWeeks(weeks)}
                            label={transChoice(
                                'duration-in-weeks',
                                weeks,
                                { weeks },
                                'public-common',
                            )}
                        />
                    ))}
                </Stack>
                <Typography variant="body1">
                    <Translate
                        id="rollover-pause-cant-find-suitable-option"
                        params={{
                            letUsKnowLink: (
                                <Link
                                    key="let-us-know-link"
                                    color="inherit"
                                    to={externalRolloverPauseLetUsKnowPath()}
                                    target="_blank"
                                >
                                    {trans('rollover-pause-let-us-know', {}, 'student-common')}
                                </Link>
                            ),
                        }}
                        domain="student-common"
                    />
                </Typography>
                {isClassesBetweenDates && <ClassesInPauseAlert />}
            </DialogContent>
            <DialogActions
                onConfirm={() => {
                    onConfirm(pauseEndDate);
                }}
                confirmLabel={trans('btn-confirm-pause', {}, 'student-common')}
                onDismiss={onDismiss}
                confirmStatus={currentPauseWeeks === 0 ? 'disabled' : 'enabled'}
                dismissLabel={trans('btn-back', {}, 'student-notification')}
            ></DialogActions>
        </>
    );
};

const PAUSE_WEEKS = [1, 2, 3, 4];

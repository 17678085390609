import { createSelector } from 'reselect';
import { SortDirection } from '@lingoda/utils';
import { sortByStartDate } from '../utils';
import allStudentAppointmentIdsSelector from './allStudentAppointmentIds';
import { expandedAppointmentsWithClassSelector } from './expandedAppointmentsWithClass';

export default createSelector(
    allStudentAppointmentIdsSelector,
    expandedAppointmentsWithClassSelector,
    (
        appointmentsId,
        appointments,
    ): Readonly<ReturnType<typeof expandedAppointmentsWithClassSelector>> =>
        appointments
            .filter((item) => appointmentsId.includes(item.id))
            .sort(sortByStartDate(SortDirection.DESC)),
);

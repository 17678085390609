import { produce } from 'immer';
import mergeWith from 'lodash/mergeWith';
import { handleActionsImmutably } from '@lingoda/core';
import { addLearningUnitIdsToModule, addModules, clearModules } from '../actions';
import { moduleFactory } from '../models';
import type { Module } from '../models';

export type State = Readonly<GenericObject<Module>>;

type ModulesAction = ReturnType<typeof addModules>;
type LearningUnitIdsAction = ReturnType<typeof addLearningUnitIdsToModule>;

type Payload = LearningUnitIdsAction['payload'] & ModulesAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${addModules}`]: (state, { payload }: ModulesAction) => {
            (payload || []).forEach((module) => {
                const prevModule = state[module.id];
                state[module.id] = mergeWith(prevModule, moduleFactory(module), (oldVal, newVal) =>
                    typeof newVal !== 'undefined' ? newVal : oldVal,
                );
            });

            return state;
        },
        [`${addLearningUnitIdsToModule}`]: (
            state,
            { payload: { moduleId, learningUnitIds } }: LearningUnitIdsAction,
        ) => {
            state[moduleId] = produce(state[moduleId], (draft) => {
                draft.learningUnitIds = learningUnitIds;
            });

            return state;
        },
        [`${clearModules}`]: () => ({}),
    },
    {},
);

import {
    addDays,
    addHours,
    addMinutes,
    createDate,
    isBefore,
    isToday,
    startOfDay,
    startOfHour,
    utcMinutesOffset,
} from '@lingoda/dates';
import type { BookingFilter } from '../models';

export const getValidRangeDate = (date: Date, timeSlots: BookingFilter['timeSlots']): Date => {
    const now = createDate();
    const today = startOfDay(now);

    // we should adjust it for all non-round timezones, i.e. GMT-2:30, GMT+5:45
    const currentHourWithOffset = addMinutes(now, -utcMinutesOffset()).getHours();

    const hasSlotsOnlyInPast =
        !!timeSlots.slots.length && timeSlots.slots.every((slot) => slot <= currentHourWithOffset);

    // if the date from filter is in the past, we use the next hour
    // if the date is in the future, means that it comes from filter, it's already beginning of the hour
    let validDate = isBefore(date, today) ? addHours(startOfHour(now), 1) : date;

    if (isToday(validDate) && hasSlotsOnlyInPast) {
        validDate = addDays(validDate, 1);
    }
    const noTimeDate = startOfDay(validDate);

    return noTimeDate;
};

import { produce } from 'immer';
import merge from 'lodash/merge';
import { handleActionsImmutably } from '@lingoda/core';
import { addLeadTime, updateMinDates } from '../actions';
import { ClassesVarsFactory, MinimalBookingDatesFactory } from '../models';
import type { ClassesVarsAttributes } from '../models';

export type State = ClassesVarsAttributes;

type UpdateMinDateAction = ReturnType<typeof updateMinDates>;
type AddLeadTimeAction = ReturnType<typeof addLeadTime>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default handleActionsImmutably<State, any>(
    {
        [`${updateMinDates}`]: (state, { payload: minDates }: UpdateMinDateAction): State => {
            state.minBookingDates =
                state.minBookingDates &&
                produce(state.minBookingDates, (draft) => {
                    merge(draft, minDates);
                });

            return state;
        },
        [`${addLeadTime}`]: (state, { payload }: AddLeadTimeAction): State => {
            state.leadTimes = produce(state.leadTimes, (draft) => {
                merge(draft, payload);
            });

            return state;
        },
    },
    ClassesVarsFactory({
        minBookingDates: MinimalBookingDatesFactory({}),
    }),
);

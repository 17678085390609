import { type Appointment, AppointmentStatus, type Maybe } from '@lingoda/graphql';

type PartialClassItem = { appointment: Maybe<Pick<Appointment, 'status'>> };

export const isClassBooked = (classItem: PartialClassItem) => {
    return !!classItem.appointment && isAppointmentBooked(classItem.appointment);
};

const isAppointmentBooked = (appointment: Pick<Appointment, 'status'>) => {
    return (
        appointment.status !== AppointmentStatus.Cancelled &&
        appointment.status !== AppointmentStatus.Deleted
    );
};

import { createAction } from '@lingoda/core';
import type { Appointment } from '../models';
import type { AppointmentId } from '../constants';

type Changes = Partial<Appointment>;

export default createAction(
    'appointment/UPDATE_APPOINTMENT',
    (appointmentId: AppointmentId, changes: Changes) => ({
        appointmentId,
        changes,
    }),
);

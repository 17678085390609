import { useCallback } from 'react';
import { actionClick } from '@lingoda/analytics';
import { resolvePath, studentClassDetailsPath, studentLessonDetailsPath } from '@lingoda/router';
import { format, formatDate, formatTime, toUTCString } from '@lingoda/dates';
import { useResolution, useUiEvent } from '@lingoda/ui';
import { assert } from '@lingoda/utils';
import { type BookingFilter, getBookClassEvent, useBooking } from '@lingoda/booking';
import {
    BookClassElement,
    type BookClassElementProps,
    BookClassVariant,
} from '@lingoda/booking-ui';

export type BookableClassElementProps = Pick<
    BookClassElementProps,
    'position' | 'outlined' | 'klass' | 'variant' | 'active' | 'showFullDate'
> & {
    onBooked?: (classId: string, lessonId?: number) => void;
    bookingFilter?: BookingFilter;
    hideBookClass?: boolean;
    isAbleToBook: boolean;
    isEmbedded?: boolean;
};

export const BookableClassElement = ({
    active,
    outlined,
    position,
    klass,
    onBooked,
    isEmbedded,
    showFullDate,
    bookingFilter,
    variant = BookClassVariant.Tile,
}: BookableClassElementProps) => {
    const { uid: classId, isExisting, type, startDate, lesson } = klass;
    const addEvent = useUiEvent();
    const [bookDisabled, bookClass] = useBooking();
    const openInNewTab = !useResolution('phone');

    const linkTo = isExisting
        ? resolvePath(studentClassDetailsPath({ class_id: classId }))
        : lesson
          ? resolvePath(
                studentLessonDetailsPath({
                    lessonId: lesson.id,
                    type: type,
                    date: toUTCString(startDate),
                }),
            )
          : '';

    const handleOpenBookClass = useCallback(
        (event) => {
            event.stopPropagation();
            event.preventDefault();

            const bookClassEvent = getBookClassEvent(bookingFilter);
            void addEvent(actionClick('Book class'), {
                ...bookClassEvent,
                metadata: {
                    variant,
                    category: 'View Type',
                    isEmbedded,
                    ...bookClassEvent.metadata,
                },
            });

            const {
                uid: classId,
                isExisting,
                type,
                startDate,
                lesson,
                module: { id: moduleId },
            } = klass;
            assert(lesson, 'Lesson should be assigned to a bookable class from search');
            const lessonId = lesson.id;
            const existingOrGeneratedClassToBook = isExisting
                ? { id: classId, type, startDate, moduleId }
                : { startDate, type, lessonId, moduleId };
            bookClass(existingOrGeneratedClassToBook)
                .then(({ classId, lessonId }) => onBooked?.(classId, lessonId))
                .catch(() => undefined);
        },
        [bookingFilter, addEvent, variant, isEmbedded, klass, bookClass, onBooked],
    );

    const handleDetails = useCallback(
        (event) => {
            void addEvent(actionClick('ClassDetailsTile'), {
                metadata: {
                    classId,
                    classDate: formatDate(startDate),
                    classWeekDay: format('iii')(startDate),
                    classTime: formatTime(startDate),
                },
            });
            event?.stopPropagation();
        },
        [addEvent, classId, startDate],
    );

    return (
        <BookClassElement
            outlined={outlined}
            onBook={handleOpenBookClass}
            active={active}
            disableBookClass={bookDisabled}
            klass={klass}
            onDetails={handleDetails}
            variant={variant}
            position={position}
            showFullDate={showFullDate}
            isEmbedded={isEmbedded}
            linkTo={linkTo}
            openInNewTab={openInNewTab}
        />
    );
};

export { BookClassVariant as Variant };

import { useEffect, useState } from 'react';
import { isAuthStatusUnauthenticatedSelector, sectionNameSelector } from '@lingoda/auth';
import { trans } from '@lingoda/i18n';
import { currentCurrencySelector } from '@lingoda/currency';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { showToast } from '@lingoda/toasts';
import { fetchProductVariants, useProductVariantSelector } from '@lingoda/products';
import {
    changeSubscriptionFailedPath,
    errorLanguagePath,
    goTo,
    redirect,
    studentStorePath,
} from '@lingoda/router';
import { currentSubscriptionSelector } from '../expanded';
import { areSubscriptionsLoadedSelector, hasSubscriptionSelector } from '../selectors';

export const useCheckProductEligible = (productVariantId: number) => {
    const dispatch = useDispatch();
    const [productId, setProductId] = useState<number>();
    const currency = useSelector(currentCurrencySelector);
    const section = useSelector(sectionNameSelector);
    const subscription = useSelector(currentSubscriptionSelector);
    const hasSubscription = useSelector(hasSubscriptionSelector);
    const subscriptionsLoaded = useSelector(areSubscriptionsLoadedSelector);
    const unauthenticated = useSelector(isAuthStatusUnauthenticatedSelector);
    const productVariant = useProductVariantSelector(productVariantId);
    const rolloverProductId = productVariant?.product.rolloverProductId;

    useEffect(() => {
        if (rolloverProductId && currency && section) {
            dispatch(
                fetchProductVariants({
                    section,
                    currency,
                    productIds: [rolloverProductId],
                }),
            );
        }
    }, [currency, dispatch, rolloverProductId, section]);

    useEffect(() => {
        if (
            unauthenticated ||
            !subscriptionsLoaded ||
            !productVariant ||
            (subscription && !subscription.allowChange) ||
            productId
        ) {
            return;
        }

        if (productVariant?.section && productVariant.section.name !== section) {
            redirect(errorLanguagePath({ section: productVariant.section.name }));
        }

        if (!subscription) {
            setProductId(productVariant.productId);

            return;
        }

        if (!subscription.allowChange) {
            return goTo(changeSubscriptionFailedPath());
        }

        const { product } = productVariant;
        const isTrialEligible = !(hasSubscription && product.isTrial);

        if (!isTrialEligible) {
            showToast('error', trans('ineligible-free-to-rolling', {}, 'public-courses-summary'));

            if (!product.rolloverProductId) {
                goTo(studentStorePath());

                return;
            }
        }

        setProductId(isTrialEligible ? productVariant.productId : product.rolloverProductId);
    }, [
        subscriptionsLoaded,
        hasSubscription,
        productVariant,
        unauthenticated,
        subscription,
        productId,
        section,
        dispatch,
    ]);

    return productId;
};

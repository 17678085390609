import { usePrivacyPolicyQuery } from '@lingoda/graphql';
import { Box, Loader } from '@lingoda/ui';
import { typography } from '@lingoda/ui/theme/typography';

export const PrivacyPolicy = () => {
    const { data, loading } = usePrivacyPolicyQuery();

    const content = data?.privacyPolicy.body || '';

    return (
        <Box sx={styles}>
            {loading ? (
                <Box mt={7} textAlign="center">
                    <Loader />
                </Box>
            ) : (
                <div dangerouslySetInnerHTML={{ __html: content }} />
            )}
        </Box>
    );
};

const styles = {
    ...typography,
    h1: {
        ...typography.h1,
        marginTop: 0,
    },
} as const;

import { type PropsWithChildren, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import { useIntl } from '@lingoda/i18n';
import { type AcceptedLang } from '@lingoda/env';
import { type ThemeOptions, theme } from './common';

interface Props {
    locales: Record<(typeof mapLocaleToMuiLang)[AcceptedLang], object>;
    themeOptions?: ThemeOptions;
}

export const ThemeProviderLocalized = ({
    children,
    locales,
    themeOptions,
}: PropsWithChildren<Props>) => {
    const { locale } = useIntl();

    const themeResult = useMemo(() => {
        const customTheme = themeOptions ? createTheme(theme, themeOptions) : theme;

        const muiLocale = mapLocaleToMuiLang[locale as AcceptedLang];
        const localization = locales[muiLocale];
        const localizedTheme = localization ? createTheme(customTheme, localization) : customTheme;

        return localizedTheme;
    }, [locales, locale, themeOptions]);

    return <ThemeProvider theme={themeResult}>{children}</ThemeProvider>;
};

const mapLocaleToMuiLang = {
    de: 'deDE',
    en: 'enUS',
    es: 'esES',
    fr: 'frFR',
    it: 'itIT',
    pl: 'plPL',
    pt_BR: 'ptBR',
    ru: 'ruRU',
    tr: 'trTR',
} as const;

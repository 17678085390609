import { Avatar, AvatarGroup, Box, Stack, Typography, colorsPalette } from '@lingoda/ui';
import {
    type ClassCardStudentFragment,
    type ClassCardTeacherFragment,
    type Maybe,
} from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';

interface Props {
    students: ReadonlyArray<ClassCardStudentFragment>;
    teacher: Maybe<ClassCardTeacherFragment>;
}

export const ClassAvatarsGroup = ({ students, teacher }: Props) => {
    return (
        <Stack>
            {!teacher && (
                <Typography
                    variant="body3"
                    htmlColor={colorsPalette.blackAlpha[70]}
                    textTransform="uppercase"
                >
                    {trans('teacher-will-be-assigned', {}, 'student-common')}
                </Typography>
            )}
            <Box display="flex" alignItems="flex-end">
                {teacher && (
                    <Avatar size={24} src={teacher.photo?.urls.medium} sx={{ marginRight: -0.25 }}>
                        {teacher.firstName}
                    </Avatar>
                )}
                {students.length > 0 && (
                    <AvatarGroup max={6} counterSize={24} sx={{ marginBottom: -0.25 }}>
                        {students?.map(({ id, firstLetterOfFirstName, photo }, index) => (
                            <Avatar
                                key={id}
                                size={18}
                                src={photo?.urls.medium}
                                borderVariant="stacked"
                                sx={{ zIndex: index }}
                            >
                                {firstLetterOfFirstName}
                            </Avatar>
                        ))}
                    </AvatarGroup>
                )}
            </Box>
        </Stack>
    );
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const CalendarTodayIcon24 = (props: SvgIconProps) => (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 4.20859C2.58579 4.20859 2.25 4.54438 2.25 4.95859V20.5586C2.25 20.9728 2.58579 21.3086 3 21.3086H21C21.4142 21.3086 21.75 20.9728 21.75 20.5586V4.95859C21.75 4.54438 21.4142 4.20859 21 4.20859H16.95V2.55859C16.95 2.14438 16.6142 1.80859 16.2 1.80859C15.7858 1.80859 15.45 2.14438 15.45 2.55859V4.20859H8.55V2.55859C8.55 2.14438 8.21421 1.80859 7.8 1.80859C7.38579 1.80859 7.05 2.14438 7.05 2.55859V4.20859H3ZM3.75 19.8086H20.25V10.5086H3.75V19.8086ZM7.8 7.50859C8.21421 7.50859 8.55 7.17281 8.55 6.75859V5.70859H15.45V6.75859C15.45 7.17281 15.7858 7.50859 16.2 7.50859C16.6142 7.50859 16.95 7.17281 16.95 6.75859V5.70859H20.25V9.00859H3.75V5.70859H7.05V6.75859C7.05 7.17281 7.38579 7.50859 7.8 7.50859Z"
        />
        <path d="M5.5 13.0586C5.5 12.5063 5.94772 12.0586 6.5 12.0586H8.5C9.05228 12.0586 9.5 12.5063 9.5 13.0586V15.0586C9.5 15.6109 9.05228 16.0586 8.5 16.0586H6.5C5.94772 16.0586 5.5 15.6109 5.5 15.0586V13.0586Z" />
    </SvgIcon>
);

import { useCallback } from 'react';
import { actionFilter } from '@lingoda/analytics';
import { type BookingFilter, mapTimeSlotsToEvent } from '@lingoda/booking';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { Box, Stack, TimeSlotPickerMenu, useResolution, useUiEvent } from '@lingoda/ui';
import { FilterLabel } from '../FilterLabel';
import { ExtendButtons } from './ExtendButtons';
import { ResetTimeButton } from './ResetTimeButton';
import type { TimeSlotsFilterValue } from './model';
import type { FilterProps } from '../model';

export type TimeSlotFilterMenuMobileProps = FilterProps<TimeSlotsFilterValue> & {
    timeFormat: TimeFormat;
    disabled?: boolean;
};

export const TimeSlotFilterMenuMobile = ({
    value,
    onChange,
    disabled,
    timeFormat,
}: TimeSlotFilterMenuMobileProps) => {
    const isMobile = useResolution('phone');
    const addEvent = useUiEvent();

    const handleChange = useCallback(
        (newValue: Partial<BookingFilter>) => {
            const slots = newValue.timeSlots?.slots || [];
            const extendHours = newValue.timeSlots?.extendHours || 0;
            void addEvent(actionFilter('Time'), {
                metadata: mapTimeSlotsToEvent(slots, extendHours),
            });
            onChange(newValue);
        },
        [addEvent, onChange],
    );

    const handleSlotChange = useCallback(
        (slots: number[]) => {
            handleChange({ timeSlots: { ...value, slots } });
        },
        [handleChange, value],
    );

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                <FilterLabel>{trans('time-filter', {}, 'student-common')}</FilterLabel>
                <ResetTimeButton onChange={handleChange} disabled={disabled} />
            </Stack>
            <ExtendButtons value={value} onChange={handleChange} disabled={disabled} />
            <Box mt={4}>
                <TimeSlotPickerMenu
                    multiple
                    value={value?.slots || []}
                    onChange={handleSlotChange}
                    disabled={disabled}
                    slotsInRow={isMobile ? 3 : 6}
                    timeFormat={timeFormat}
                />
            </Box>
        </Stack>
    );
};

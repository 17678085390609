import { createSelector } from 'reselect';
import { studentSelector } from '@lingoda/students';
import expandModule from './expandModule';
import modulesSelector from './modules';

export default createSelector(
    modulesSelector,
    studentSelector,
    (modules, student) =>
        (student?.moduleId && expandModule(modules[student.moduleId])) || undefined,
);

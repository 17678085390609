import { Gateway } from '@lingoda/api';

export * from '@stripe/react-stripe-js';

export const PAYMENT_CREDIT_CARDS = 'creditCards';
export const PAYMENT_SEPA = 'sepadirectdebit';
export const PAYMENT_SOFORT = 'sofort';
export const PAYMENT_REQUEST = 'paymentRequest';

export enum PaymentMethodType {
    Card = 'Card',
    Sepa = 'Sepa',
    Sofort = 'Sofort',
    PaymentRequest = 'PaymentRequest',
    StoredCard = 'StoredCard',
    StoredSepa = 'StoredSepa',
}

const creditsCardTypes = [
    'amex',
    'mc',
    'visa',
    'Diners Club',
    'Discover',
    'JCB',
    'UnionPay',
    'Unknown',
];

export const getType = (
    paymentMethod?: string,
    paymentMethodType?: string,
    paymentMethodGateway?: string,
) => {
    switch (paymentMethod) {
        case PAYMENT_REQUEST:
            return PaymentMethodType.PaymentRequest;
        case PAYMENT_CREDIT_CARDS:
            return PaymentMethodType.Card;
        case PAYMENT_SEPA:
            return PaymentMethodType.Sepa;
        case PAYMENT_SOFORT:
            return PaymentMethodType.Sofort;
        default:
            if (
                paymentMethodType &&
                creditsCardTypes.includes(paymentMethodType) &&
                paymentMethodGateway === Gateway.STRIPE
            ) {
                return PaymentMethodType.StoredCard;
            }

            if (PAYMENT_SEPA === paymentMethodType && paymentMethodGateway === Gateway.STRIPE) {
                return PaymentMethodType.StoredSepa;
            }

            return undefined;
    }
};

export const isStripePaymentMethod = (paymentMethod: string) => !!getType(paymentMethod);

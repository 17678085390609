import { useMemo } from 'react';
import { Box, Stack } from '@lingoda/ui';
import { useSelector } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { type BookableClassFragment } from '@lingoda/graphql';
import {
    type BookingFilter,
    BookingView,
    isAbleToBookSelector,
    processSearchClasses,
} from '@lingoda/booking';
import {
    BookingSearchFrameContainer,
    ClassesListHeader,
    DayLabel,
    SectionTitle,
} from '@lingoda/booking-ui';
import { ClassesList } from '../ClassesList';
import { ClassesGrid } from '../ClassesGrid';

interface Props {
    classes: ReadonlyArray<BookableClassFragment>;
    bookingView: BookingView;
    bookingFilter: BookingFilter;
    isEmbedded?: boolean;
    onBooked: (classId: string) => void;
    stickyFilterHeight?: number;
    bgColor?: string;
}

export const SuggestedClasses = ({
    classes,
    bookingView,
    bookingFilter,
    isEmbedded,
    onBooked,
    stickyFilterHeight,
    bgColor,
}: Props) => {
    const isAbleToBook = useSelector(isAbleToBookSelector);
    const ClassesLayoutComponent = bookingView === BookingView.List ? ClassesList : ClassesGrid;

    const classesList = useMemo(() => processSearchClasses(classes), [classes]);

    return (
        <Box mt={6} data-cy="Suggested classes">
            <BookingSearchFrameContainer>
                <SectionTitle title={trans('you-might-also-like', {}, 'student-common')} />
            </BookingSearchFrameContainer>
            {Object.entries(classesList).map(([day, slots]) => (
                <Stack key={day} data-cy="Classes list day">
                    <ClassesListHeader stickyFilterHeight={stickyFilterHeight} bgColor={bgColor}>
                        <BookingSearchFrameContainer>
                            <DayLabel label={day} />
                        </BookingSearchFrameContainer>
                    </ClassesListHeader>
                    <BookingSearchFrameContainer>
                        <ClassesLayoutComponent
                            isAbleToBook={isAbleToBook}
                            slots={slots.filter(Boolean)}
                            isEmbedded={isEmbedded}
                            bookingFilter={bookingFilter}
                            onBooked={onBooked}
                        />
                    </BookingSearchFrameContainer>
                </Stack>
            ))}
        </Box>
    );
};

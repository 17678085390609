import { buildValidator } from '@lingoda/forms';

export interface FormValues {
    startDate: Date;
}

export const validate = buildValidator({
    startDate: {
        required: true,
    },
});

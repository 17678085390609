import { createSelector } from 'reselect';
import { userSelector } from '@lingoda/auth';
import { STORE_KEY } from './constants';
import { Currency } from './models';
import type { State } from './reducers';

const stateSelector = (state: GlobalState) => state[STORE_KEY] as State;

export const currentCurrencySelector = createSelector(
    stateSelector,
    (state): Currency => state?.currentCurrency || Currency.Eur,
);

export const userCurrencySelector = createSelector(
    userSelector,
    (user) => (user?.currency as Currency) || Currency.Eur,
);

export interface ErrorMessage {
    message: string;
    type: 'error' | 'warning' | 'info' | 'success';
}

export interface ErrorMessages {
    [key: string]: ErrorMessage[];
}

export interface ErrorResponse {
    messages: ErrorMessages;
}

export interface FormErrorResponse {
    [key: string]: ErrorMessages;
}

export const getMessages = (messages: ErrorMessages | undefined) =>
    messages
        ? Object.keys(messages)
              .filter((id) => id !== '')
              .map((id) => ({
                  variant: messages[id][0].type,
                  message: messages[id][0].message,
              }))
        : [];

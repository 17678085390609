import {
    createDate,
    format,
    formatTimeRemaining,
    isAfter,
    isBefore,
    subtract,
} from '@lingoda/dates';
import { useTimeRemainingAutoDisabled } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { capitalize } from '@lingoda/utils';
import { ClockIcon16, StudentIcon, VideoIcon16 } from '../../icons/lingodaIcons';
import { colorsPalette } from '../../theme';
import { Avatar } from '../Avatar';
import { AvatarGroup } from '../AvatarGroup';
import { Grid } from '../GridNext';
import { Typography } from '../Typography';

interface Participant {
    id: number;
    name: string;
    photo?: string;
}

export interface MeetingCardDetailsProps {
    endDate?: Date;
    hasHost?: boolean;
    isActive?: boolean;
    participants?: Participant[];
    showTime?: boolean;
    startDate: Date;
    subtitle?: string;
}

export const MeetingCardDetails = ({
    endDate,
    hasHost,
    isActive,
    participants,
    showTime,
    startDate,
    subtitle,
}: MeetingCardDetailsProps) => {
    const subtitleColor = colorsPalette.blackAlpha[70];
    const [timeRemaining, timeRemainingDisabled] = useTimeRemainingAutoDisabled(startDate, {
        formatter: timeFormatter,
        getDisabled: () =>
            !isActive ||
            isBefore(createDate(), subtract({ hours: 24 }, startDate)) ||
            isAfter(createDate(), startDate),
    });

    return (
        <>
            {subtitle && (
                <Grid display="flex" color={subtitleColor}>
                    <VideoIcon16 sx={{ fontSize: '1rem' }} />
                    <Typography variant="body3" ml={0.5} sx={{ overflowWrap: 'anywhere' }}>
                        {subtitle}
                    </Typography>
                </Grid>
            )}
            {showTime && (
                <Grid display="flex" color={subtitleColor}>
                    <ClockIcon16 sx={{ fontSize: '1rem' }} />
                    <Typography variant="body3" ml={0.5} component="span" display="inline">
                        <Typography
                            variant="body3"
                            component="span"
                            display="inline"
                            sx={{ overflowWrap: 'anywhere' }}
                        >
                            {format('p', startDate)}
                            {' - '}
                            {endDate && format('p OOO', endDate)}
                        </Typography>
                        {!timeRemainingDisabled && (
                            <>
                                <Typography variant="body3" component="span" display="inline">
                                    {' • '}
                                </Typography>
                                <Typography
                                    variant="body3"
                                    htmlColor={colorsPalette.negativeCustom.dark}
                                    component="span"
                                    display="inline"
                                >
                                    {capitalize(timeRemaining)}
                                </Typography>
                            </>
                        )}
                    </Typography>
                </Grid>
            )}
            {!hasHost && (
                <Grid display="flex" color={subtitleColor}>
                    <StudentIcon />
                    <Typography variant="body3" ml={0.5} sx={{ overflowWrap: 'anywhere' }}>
                        {trans('teacher-will-be-assigned', {}, 'student-common')}
                    </Typography>
                </Grid>
            )}
            <Grid display="flex" alignItems="center">
                <AvatarGroup max={6} counterSize={24}>
                    {participants?.map(({ id, name, photo }, index) => (
                        <Avatar
                            key={id}
                            size={24}
                            src={photo}
                            borderVariant="stacked"
                            sx={{ zIndex: index }}
                        >
                            {name}
                        </Avatar>
                    ))}
                </AvatarGroup>
            </Grid>
        </>
    );
};

const timeFormatter = (toDate: Date) => formatTimeRemaining(toDate, { addSuffix: true });

import type { ClassType } from '@lingoda/graphql';
import type { SectionName } from '@lingoda/sections';
import { createRecordFactory } from '@lingoda/utils';

type ClassTypeSchema = GenericKeyObject<SectionName, string>;

export type ClassLeadTimeSchema = GenericKeyObject<ClassType, ClassTypeSchema>;

export type StartDateLeadTimes = GenericKeyObject<ClassType, ClassTypeSchema>;

export const ClassLeadTimeSchemaFactory = createRecordFactory<ClassLeadTimeSchema>({
    trial: undefined,
    group: undefined,
    individual: undefined,
});

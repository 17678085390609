import { config } from '@lingoda/config';
import { loadAnalytics } from './common';

export default async () => {
    // lazy loading libs as they are huge and sync is happening async
    const [Analytics, { default: Amplify }, { AWSKinesisProvider }, { default: Auth }] =
        await Promise.all([
            loadAnalytics(),
            import('@aws-amplify/core'),
            import('@aws-amplify/analytics'),
            import('@aws-amplify/auth'),
        ]);

    Analytics?.addPluggable(
        new AWSKinesisProvider({ endpoint: config.kinesisEndpoint, region: config.kinesisRegion }),
    );

    Amplify.configure({
        Analytics: {
            disabled: false,
        },
        Auth: {
            identityPoolId: config.kinesisCognitoIdentityId,
            region: config.kinesisRegion,
        },
        AWSKinesis: {
            flushInterval: 5000,
            resendLimit: 5,
            region: config.kinesisRegion,
        },
    });

    Auth.configure({});
};

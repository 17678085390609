import { type Variant } from '../../types';
import { CardFace } from '../FlashCardFaces';
import { AnimationFlip, type AnimationFlipProps, AnimationNextCard } from './Animations';

export interface FlashCardProps {
    animationDuration: number;
    backFace: React.ReactNode;
    frontFace: React.ReactNode;
    isHidden?: boolean;
    isSwapped?: boolean;
    onFlipEnd?: AnimationFlipProps['onFlipEnd'];
    onFlipStart?: AnimationFlipProps['onFlipStart'];
    variant?: Variant;
    shortcutControls?: AnimationFlipProps['shortcutControls'];
}

export const FlashCard = ({
    animationDuration,
    backFace,
    frontFace,
    isHidden = false,
    isSwapped = false,
    onFlipEnd,
    onFlipStart,
    variant = 'none',
    shortcutControls,
}: FlashCardProps) => {
    return (
        <AnimationNextCard
            isHidden={isHidden}
            duration={animationDuration}
            direction={variant === 'missed' ? 'left' : 'right'}
        >
            <AnimationFlip
                duration={animationDuration}
                resetAnimation={isHidden}
                onFlipStart={onFlipStart}
                onFlipEnd={onFlipEnd}
                shortcutControls={shortcutControls}
            >
                {orderCardFaces(isSwapped, [
                    <CardFace key="frontFace" isBackFace={isSwapped}>
                        {frontFace}
                    </CardFace>,
                    <CardFace key="backFace" isBackFace={!isSwapped}>
                        {backFace}
                    </CardFace>,
                ])}
            </AnimationFlip>
        </AnimationNextCard>
    );
};

const orderCardFaces = (isOrderSwapped: boolean, faces: [React.ReactNode, React.ReactNode]) =>
    isOrderSwapped ? faces.reverse() : faces;

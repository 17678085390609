import { trans } from '@lingoda/i18n';
import { Button, type ButtonProps, colorsPalette } from '@lingoda/ui';
import { FlagIcon16 } from '@lingoda/ui/icons/FlagIcon';

type ReportContentButtonProps = Omit<ButtonProps, 'variant'>;

export const ReportContentButton = (props: ReportContentButtonProps) => {
    return (
        <Button
            variant="text"
            {...props}
            color="inherit"
            sx={{ color: colorsPalette.blackAlpha[70] }}
            startIcon={<FlagIcon16 />}
        >
            {trans('control-label', {}, 'quiz-report-item')}
        </Button>
    );
};

import {
    ACTION_FINISHED_USER_REGISTRATION,
    CATEGORY_LEAD,
    EVENT_REGISTRATION_FINISHED,
    HIT_TYPE_EVENT,
    LABEL_PLUTO,
    gaSet,
    gtmAddEvent,
} from '@lingoda/analytics';
import { getTopLevelDomain, getUrl } from '@lingoda/urls';
import { getStorageValue, removeStorageValue, setStorageValue } from '@lingoda/utils';
import { getCookieValue, removeCookieValue } from '@lingoda/cookie';
import {
    BEARER_TOKEN,
    COOKIE_BEARER_EXP_KEY,
    COOKIE_BEARER_KEY,
    COOKIE_MERCURE_KEY,
    REFRESH_TOKEN,
} from './constants';
import getAuthTokenExpiry from './getAuthTokenExpiry';
import { refreshToken, shouldRefreshToken } from './token';

const noAuthCheck = () => [getUrl('user_security_check'), getUrl('gesdinet_jwt_refresh_token')];

export const getAuthCheck = async (url: string): Promise<unknown> => {
    const expiry = getAuthTokenExpiry();

    if (noAuthCheck().includes(url) || !expiry || !shouldRefreshToken(expiry)) {
        return;
    }

    const result = await refreshToken();

    // support for header approach
    result?.refresh_token && setStorageValue(REFRESH_TOKEN, result.refresh_token);
    result?.token && setStorageValue(BEARER_TOKEN, result.token);
};

export const sendFinishedUserRegistrationEvent = (userId: number, userEmail: string) =>
    new Promise((resolve) => {
        gaSet({ userId });
        gtmAddEvent(EVENT_REGISTRATION_FINISHED, {
            hitType: HIT_TYPE_EVENT,
            eventCategory: CATEGORY_LEAD,
            eventAction: ACTION_FINISHED_USER_REGISTRATION,
            eventLabel: LABEL_PLUTO,
            userId: userId,
            userEmail: userEmail,
        });

        resolve(true);
    });

export const clearAuthStorageTokens = () => {
    removeStorageValue(REFRESH_TOKEN);
    removeStorageValue(BEARER_TOKEN);
};

export const setAuthStorageToken = (token: string) => {
    setStorageValue(BEARER_TOKEN, token);
};

const USER_ID_STORAGE_KEY = 'user_id';

export const setUserIdToStorage = (userId: number) => {
    setStorageValue(USER_ID_STORAGE_KEY, userId);
};

export const getUserIdFromStorage = () => getStorageValue<number>(USER_ID_STORAGE_KEY);

export const hasAppAuthExpCookie = (): boolean => {
    return !!getCookieValue(COOKIE_BEARER_EXP_KEY);
};

export const hasMercureAuthCookie = (): boolean => {
    return !!getCookieValue(COOKIE_MERCURE_KEY);
};

export const clearAuthCookies = (): void => {
    const domain = getTopLevelDomain();
    removeCookieValue(COOKIE_BEARER_KEY, { domain });
    removeCookieValue(COOKIE_BEARER_EXP_KEY, { domain });
    removeCookieValue(COOKIE_MERCURE_KEY, { domain });
};

export const getIsResetPasswordTokenExpired = (
    passwordRequestedAt: number,
    userResetTokenTtlMins: number,
) => Date.now() > passwordRequestedAt * 1000 + userResetTokenTtlMins * 60 * 1000;

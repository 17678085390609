import { subscriptionsStorePath } from '@lingoda/router';
import { Button, useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';
import { trans } from '@lingoda/i18n';
import StoreBanner from '../StoreBanner';
import ChangeSub from './changeSub.svg?component';

const ChangeSubBanner = () => {
    const addEvent = useUiEvent();

    return (
        <StoreBanner
            title={trans('banner-change-subscription-title', {}, 'student-store')}
            subtitle={trans('banner-change-subscription-subtitle', {}, 'student-store')}
            button={
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => addEvent(actionClick('ChangeYourSubscriptionClick'))}
                    href={subscriptionsStorePath()}
                >
                    {trans('banner-change-subscription-cta', {}, 'student-store')}
                </Button>
            }
            illustration={<ChangeSub />}
        />
    );
};

export default ChangeSubBanner;

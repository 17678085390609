import { useCallback } from 'react';
import { openWindow } from '@lingoda/router';
import { getApolloErrorUiMessage, useClassroomUrlLazyQuery } from '@lingoda/graphql';
import type { ApolloError, ApolloErrorHandler } from '@lingoda/graphql';
import {
    TRYING_TO_GENERATE_ZOOM_MEETING_TOO_EARLY_ERROR_MESSAGE,
    TryingToGenerateZoomMeetingTooEarly,
    getBrowserTimeOutOfSyncErrorData,
} from '@lingoda/timezone';
import { captureError } from '@lingoda/monitor';
import { getMe } from '@lingoda/api';

interface Options {
    onError: ApolloErrorHandler;
    join?: (classroomUrl: string) => void;
}

export const useJoinClass = ({ onError, join = openWindow }: Options) => {
    // This is temporary error handler to collect more information about "Trying to generate Zoom meeting too early" error
    // Revert when this ticket is completed https://lingoda.atlassian.net/browse/LW-25759
    const handleError = async (apolloError: ApolloError) => {
        const message = getApolloErrorUiMessage(apolloError);

        if (message === TRYING_TO_GENERATE_ZOOM_MEETING_TOO_EARLY_ERROR_MESSAGE) {
            // We need to take current api time since it could change from last time getMe was called
            const me = await getMe().catch(captureError);

            if (!me?.data.now) {
                return;
            }

            const data = getBrowserTimeOutOfSyncErrorData(new Date(me.data.now));

            captureError(
                new TryingToGenerateZoomMeetingTooEarly(
                    TRYING_TO_GENERATE_ZOOM_MEETING_TOO_EARLY_ERROR_MESSAGE,
                ),
                {
                    data,
                },
            );
        }

        onError(apolloError);
    };

    const [fetchClassroomUrl, { loading }] = useClassroomUrlLazyQuery({
        onCompleted: ({ classroomUrl }) => join(classroomUrl),
        onError: handleError,
    });

    const joinClass = useCallback(
        async (classId: string) => {
            const classroomUrl = await fetchClassroomUrl({ variables: { classId } });

            return { success: !!classroomUrl } as const;
        },
        [fetchClassroomUrl],
    );

    return [joinClass, { loading }] as const;
};

import { lazy } from 'react';
import addSectionListener from 'hoc/addSectionListener';
import { type RouteConfigExtended, addRouterListener, errorLanguagePath } from '@lingoda/router';
import { NotFoundPage } from '@lingoda/fallbacks';

const ErrorLanguage = lazy(() => import('../components/pages/errors/Language'));

const RouteErrorLanguage = addSectionListener(
    addRouterListener('errorLanguagePath')(ErrorLanguage),
);

const commonRoutes: RouteConfigExtended[] = [
    {
        exact: true,
        component: RouteErrorLanguage,
        path: errorLanguagePath.raw,
        label: 'ErrorLanguage',
        config: {
            isPublic: true,
            tab: undefined,
        },
    },
    {
        component: NotFoundPage,
        label: 'PageNotFound',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNotifications: true,
            tab: undefined,
        },
    },
];

export default commonRoutes;

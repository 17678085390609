import { getModules, getStartingModules, getStudentModules } from '@lingoda/api';
import { addTrackerCallback, memoiseAction } from '@lingoda/core';
import { studentIdSelector } from '@lingoda/students';
import {
    addModules,
    addStartingModules,
    fetchModules,
    fetchStartingModules,
    fetchStudentModules,
    setCurriculumModules,
} from './actions';

export default () => {
    addTrackerCallback(fetchModules, (action, store) => {
        const { query } = action.payload;
        const promise = getModules(query);

        void promise.then(({ data }) => {
            store.dispatch(addModules(data));

            if (query && query.curriculumId) {
                store.dispatch(
                    setCurriculumModules({
                        curriculumId: query.curriculumId,
                        moduleIds: data.map((module) => module.id),
                    }),
                );
            }
        });

        return promise;
    });

    addTrackerCallback(fetchStudentModules, async (_, store) => {
        const studentId = studentIdSelector(store.getState());

        if (!studentId) {
            return Promise.reject();
        }

        const response = await getStudentModules(studentId);

        store.dispatch(addModules(response.data));

        return response;
    });

    addTrackerCallback(fetchStartingModules, (action, store) =>
        memoiseAction(action, () => {
            const studentId = studentIdSelector(store.getState());

            if (!studentId) {
                return Promise.reject();
            }

            const promise = getStartingModules(studentId);

            void promise.then(({ data }) => {
                const startingModules = data.map(({ module, ...startingModule }) => {
                    store.dispatch(addModules([module]));

                    return {
                        ...startingModule,
                        moduleId: module.id,
                    };
                });
                store.dispatch(addStartingModules(startingModules));
            });

            return promise;
        }),
    );
};

import { useState } from 'react';
import { useToggleState } from '@lingoda/hooks';
import { type Variant } from '../types';

export const useFlashCardAnimation = () => {
    const [flashCardVariant, setFlashCardVariant] = useState<Variant>('none');
    const [isNextCardLoading, showNextCardLoader, hideNextCardLoader] = useToggleState(false);

    const proceedToNextCardStart = () => {
        showNextCardLoader();
    };

    const proceedToNextCardEnd = () => {
        setFlashCardVariant('none');
        hideNextCardLoader();
    };

    const miss = () => {
        if (isNextCardLoading) {
            return;
        }

        setFlashCardVariant('missed');
    };

    const check = () => {
        if (isNextCardLoading) {
            return;
        }

        setFlashCardVariant('checked');
    };

    const reset = () => {
        setFlashCardVariant('none');
        hideNextCardLoader();
    };

    return {
        check,
        flashCardVariant,
        isNextCardLoading,
        miss,
        reset,
        proceedToNextCardEnd,
        proceedToNextCardStart,
    };
};

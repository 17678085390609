import { useMemo } from 'react';
import type { PaymentResponse } from '@lingoda/api';
import { useRequestTracker } from '@lingoda/core';
import { useDispatch } from '@lingoda/hooks';
import { getAgreementsAgreed } from '@lingoda/purchases';
import { getUrl } from '@lingoda/urls';
import type { Agreement, Purchase } from '@lingoda/graphql';
import { PaymentGateway } from '../models';
import { submitPurchasePayment } from '../actions';
import type { PaymentFormData } from '../hoc';
import type { PaymentOptions } from '../models';

type ProductPurchase = Pick<Purchase, 'id'> & { agreements: ReadonlyArray<Pick<Agreement, 'id'>> };

export type CreatePackagePurchase = () => Promise<ProductPurchase>;

const usePaymentCallbacksForProduct = (
    initialPurchase: ProductPurchase,
    createPackagePurchase?: CreatePackagePurchase,
) => {
    const dispatch = useDispatch();
    const trackRequest = useRequestTracker();

    return useMemo(() => {
        const submitPayment = async (formData: PaymentFormData, options: PaymentOptions) => {
            const purchase = (await createPackagePurchase?.()) || initialPurchase;
            const agreementsPayload = getAgreementsAgreed(purchase.agreements, formData.agreements);
            const redirectUrl = getUrl('student_store_paymentPending', {
                purchaseId: purchase.id,
            });

            return trackRequest(
                dispatch(
                    submitPurchasePayment({
                        agreementsId: agreementsPayload || [],
                        gateway: options.gateway,
                        paymentMethod: formData.paymentMethod,
                        purchaseId: purchase.id,
                        redirectUrl,
                        nameOnCard: formData.name,
                    }),
                ),
            ) as Promise<PaymentResponse>;
        };

        const onSubmitCallback = (formData: PaymentFormData) =>
            submitPayment(formData, { gateway: PaymentGateway.ADYEN });

        const fetchIntentSecret = (formData: PaymentFormData) =>
            submitPayment(formData, { gateway: PaymentGateway.STRIPE });

        return [onSubmitCallback, fetchIntentSecret] as const;
    }, [createPackagePurchase, dispatch, initialPurchase, trackRequest]);
};

export default usePaymentCallbacksForProduct;

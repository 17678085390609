import { createSelector } from 'reselect';
import { hasStudentSelector } from '@lingoda/students';
import { BOOKING_FILTER_STORE_KEY } from '../constants';
import { getStoredBookingFilter } from '../utils';
import { type BookingFilter } from '../models';
import { defaultBookingFilterSelector } from './defaultBookingFilter';
import type { State } from '../reducers/bookingFilterReducers';

export const bookingFilterSelector = createSelector(
    defaultBookingFilterSelector,
    hasStudentSelector,
    (state) => state[BOOKING_FILTER_STORE_KEY] as State,
    (defaultBookingFilter, hasStudent, bookingFilter): BookingFilter => ({
        ...defaultBookingFilter,
        ...(hasStudent ? getStoredBookingFilter() : undefined),
        ...bookingFilter,
    }),
);

import {
    Redirect,
    registerSectionSprintPath,
    sprintPackagePurchasePath,
    useLocation,
    useParams,
} from '@lingoda/router';
import type { SectionCurriculumSlug } from '@lingoda/sections';
import { getQueryParams } from '@lingoda/urls';
import type { AcceptedLang } from '@lingoda/env';
import { isSprintBookingPreviewEnabled } from '@lingoda/feature-flags';
import type { Currency } from '@lingoda/graphql';
import { assert } from '@lingoda/utils';
import { transformSectionName } from '@lingoda/sections';
import { isFullySupportedLangValid } from '@lingoda/env';

const SprintSignup = () => {
    const location = useLocation();
    const { section, curriculum, locale } = useParams<{
        section: SectionName;
        locale: AcceptedLang;
        curriculum: SectionCurriculumSlug;
    }>();

    const { currency, packageId, ...restParams } = getQueryParams<{
        currency: Currency;
        packageId: string;
    }>(location.search);

    assert(
        typeof packageId === 'string' && typeof currency === 'string',
        '`packageId` or `currency` url param is missing',
    );

    const pathParams = {
        section: transformSectionName(section),
        curriculum,
        ...restParams,
        redirect: sprintPackagePurchasePath({
            packageId,
            currency,
            trial: false,
        }),
        previewBooking: isFullySupportedLangValid(locale) && isSprintBookingPreviewEnabled(),
    };

    return <Redirect to={registerSectionSprintPath(pathParams)} />;
};

export default SprintSignup;

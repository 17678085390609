import { type MouseEvent } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { type AchievementFragment } from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import {
    Box,
    GradientButton,
    Paper,
    ProgressBar,
    Stack,
    Typography,
    colorsPalette,
    styled,
    withCursorInteractiveStyles,
} from '@lingoda/ui';
import { useClaimReward, useTrackClaimRewardOnboarding } from '../../hooks';
import { AchievementSlug } from '../../models';
import { getIsClaimable, isAchievementActive } from '../../utils';
import { AchievementBadge } from '../AchievementBadge';

interface Props {
    achievement: AchievementFragment;
    showProgress: boolean;
}

export const AchievementsBoardItem = ({ achievement, showProgress }: Props) => {
    const dispatch = useDispatch();
    const { trackClaimRewardOnboarding } = useTrackClaimRewardOnboarding();
    const [claimReward, isClaiming] = useClaimReward({
        onCompleted: ({ claimAttendanceAchievement }) => {
            showAchievementDialog(claimAttendanceAchievement, true);
        },
    });

    const {
        slug,
        value,
        claimed,
        claimId,
        name,
        achievedLabel,
        progressLabel,
        currentProgress,
        achieved,
    } = achievement;
    const isClaimable = getIsClaimable(achievement);
    const label = achieved ? achievedLabel : progressLabel;
    const isTileActive = isAchievementActive(achievement) || claimed;

    const showAchievementDialog = (achievement: AchievementFragment, animateClaiming: boolean) =>
        dispatch(showDialog(DialogComponent.Achievement, { achievement, animateClaiming }));

    const showOnboardingChecklistDialog = (achievement: AchievementFragment) =>
        dispatch(showDialog(DialogComponent.OnboardingChecklist, { achievement }));

    const handleDialogOpen = async (event: MouseEvent) => {
        event.stopPropagation();

        if (isClaimable && claimId) {
            if (slug === AchievementSlug.ONBOARDING_CHECKLIST) {
                trackClaimRewardOnboarding();
            }
            await claimReward(claimId);

            return;
        }
        if (claimed) {
            showAchievementDialog(achievement, false);
        } else if (slug === AchievementSlug.ONBOARDING_CHECKLIST) {
            showOnboardingChecklistDialog(achievement);
        }
    };

    return (
        <BoardItemTile
            variant={isTileActive ? 'elevation' : 'outlined'}
            elevation={isTileActive ? 1 : undefined}
            active={isTileActive}
            data-cy="Achievement"
            onClick={isTileActive ? handleDialogOpen : undefined}
        >
            <Stack flex="1 1 100%" justifyContent="center" spacing={2}>
                <Box display="flex" justifyContent="center">
                    <AchievementBadge
                        badgeSlug={slug}
                        active={claimed}
                        name={name}
                        width={88}
                        height={88}
                    />
                </Box>
                {claimed ? (
                    <Stack spacing={1}>
                        <Typography variant="subtitle1">{name}</Typography>
                        <Typography
                            variant="body2"
                            sx={{ wordBreak: 'break-word' }}
                            htmlColor={colorsPalette.blackAlpha[70]}
                        >
                            {label}
                        </Typography>
                    </Stack>
                ) : (
                    <Stack spacing={1}>
                        <Typography
                            variant="body2"
                            sx={{ wordBreak: 'break-word' }}
                            htmlColor={colorsPalette.blackAlpha[70]}
                        >
                            {label}
                        </Typography>
                        {isClaimable ? (
                            <GradientButton
                                size="small"
                                fullWidth
                                onClick={handleDialogOpen}
                                disabled={isClaiming}
                                loading={isClaiming}
                            >
                                {trans('btn-claim-reward', {}, 'student-achievements')}
                            </GradientButton>
                        ) : showProgress ? (
                            <Stack direction="row" alignItems="center" spacing={1} minHeight={32}>
                                <Box flex="1 1 auto">
                                    <ProgressBar
                                        value={(currentProgress / value) * 100}
                                        size="medium"
                                    />
                                </Box>
                                <Typography
                                    variant="body2"
                                    htmlColor={colorsPalette.blackAlpha[70]}
                                >
                                    {currentProgress}/{value}
                                </Typography>
                            </Stack>
                        ) : null}
                    </Stack>
                )}
            </Stack>
        </BoardItemTile>
    );
};

const BoardItemTile = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'active',
})<{
    active: boolean;
}>(({ theme, active }) =>
    withCursorInteractiveStyles(active)({
        padding: theme.spacing(3),
        textAlign: 'center',
        height: '100%',
        minHeight: 216,
        display: 'flex',
        flexDirection: 'column',
        border: active ? 'none' : `1px solid ${colorsPalette.blackAlpha[16]}`,
        background: active ? theme.palette.common.white : 'none',
        ':hover': active
            ? {
                  background: theme.palette.common.white,
                  boxShadow: theme.shadows[4],
              }
            : {},
    }),
);

import {
    Form,
    Formik,
    TextField,
    ToggleButtonGroupField,
    useField,
    useFormContext,
} from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { Box, Button, Stack, ToggleButton } from '@lingoda/ui';
import { type FormValues, type Translations, initialValues, validate } from './form';

export interface PersonalFeedbackSatisfactionFormProps {
    translations: Translations;
    onSubmit: (values: FormValues) => void | Promise<unknown>;
}

export const PersonalFeedbackSatisfactionForm = ({
    translations,
    onSubmit,
}: PersonalFeedbackSatisfactionFormProps) => {
    const { isHelpful, description } = translations;

    return (
        <Formik<FormValues>
            initialValues={initialValues}
            validate={validate}
            validateOnMount
            onSubmit={onSubmit}
        >
            <Form noValidate>
                <Stack spacing={2}>
                    <ToggleButtonGroupField
                        name="isHelpful"
                        size="mediumSeparated"
                        required
                        exclusive
                        fullWidth
                        aria-label={isHelpful.label}
                    >
                        <ToggleButton value={true}>{isHelpful.options[0]}</ToggleButton>
                        <ToggleButton value={false}>{isHelpful.options[1]}</ToggleButton>
                    </ToggleButtonGroupField>

                    <DescriptionField label={description.label} />

                    <Box textAlign="end">
                        <SubmitButton />
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
};

interface DescriptionFieldProps {
    label: string;
}

const DescriptionField = ({ label }: DescriptionFieldProps) => {
    const [, { value: isHelpfulValue }] = useField<FormValues['isHelpful']>('isHelpful');

    if (typeof isHelpfulValue !== 'boolean') {
        return null;
    }

    return <TextField name="description" label={label} multiline maxRows={4} />;
};

const SubmitButton = () => {
    const { isValid, isSubmitting } = useFormContext();

    return (
        <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!isValid}
            loading={isSubmitting}
        >
            {trans('submit-feedback', {}, 'student-common')}
        </Button>
    );
};

import { useCallback } from 'react';
import { useDispatch } from '@lingoda/hooks';
import { logout as logoutAction, removeRefreshToken } from '@lingoda/auth';
import { useGQLClient } from '@lingoda/graphql';

export const useLogout = () => {
    const dispatch = useDispatch();
    const gqlClient = useGQLClient();

    return useCallback(() => {
        dispatch(logoutAction());
        void gqlClient.clearStore();
        void removeRefreshToken();
    }, [dispatch, gqlClient]);
};

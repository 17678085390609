import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const FlagRussianIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_4718_32617"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
        >
            <circle cx="8" cy="8" r="8" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_4718_32617)">
            <rect
                x="16"
                y="16"
                width="16"
                height="5.33333"
                transform="rotate(-180 16 16)"
                fill="#F44653"
            />
            <rect
                x="16"
                y="5.33337"
                width="16"
                height="5.33333"
                transform="rotate(-180 16 5.33337)"
                fill="#F0F0F4"
            />
            <rect
                x="16"
                y="10.6667"
                width="16"
                height="5.33333"
                transform="rotate(-180 16 10.6667)"
                fill="#1035BB"
            />
        </g>
    </SvgIcon>
);

export const FlagRussianIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_11195_98652"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <circle cx="12" cy="12" r="12" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_11195_98652)">
            <rect
                x="24"
                y="24"
                width="24"
                height="8"
                transform="rotate(-180 24 24)"
                fill="#F44653"
            />
            <rect
                x="24"
                y="8.00002"
                width="24"
                height="8"
                transform="rotate(-180 24 8.00002)"
                fill="#F0F0F4"
            />
            <rect
                x="24"
                y="16"
                width="24"
                height="8"
                transform="rotate(-180 24 16)"
                fill="#1035BB"
            />
        </g>
    </SvgIcon>
);

import { createSelector } from 'reselect';
import { createExpandedProductVariantSelector } from './expanded';
import productVariantsSelector from './productVariants';
import type { ExpandedProductVariant } from './expanded';

type productVariantIdSelector = () => number | undefined;

export default (selector: productVariantIdSelector) =>
    createExpandedProductVariantSelector(
        createSelector(
            selector,
            productVariantsSelector,
            (productVariantId, productVariants) =>
                productVariants?.find((productVariant) => productVariant.id === productVariantId)
                    ?.id as number,
        ),
    ) as (state: unknown) => ExpandedProductVariant;

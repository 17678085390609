import { createSelector } from 'reselect';
import {
    expandedAppointmentsWithClassSelector,
    studentAppointmentsSelector,
} from '@lingoda/classes';

const mappedById = createSelector(expandedAppointmentsWithClassSelector, (appointments) =>
    Object.fromEntries(appointments.map((val) => [val.id, val])),
);

export default createSelector(
    studentAppointmentsSelector,
    mappedById,
    (studentAppointments, appointments) =>
        studentAppointments.map((item) => appointments[item.id]).filter((item) => !!item),
);

import { httpGet, httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';

export const getAnswersOnboardingSurvey = (): Promise<SurveyResponse> =>
    httpGet(getUrl('api_class_questionnaire_onboarding_survey'));

export interface QuestionAnswerSchema {
    answer_text: string;
    id: string;
    slug: string;
}

export interface MissedClassSurveySchema {
    description: string;
    fragment: string;
    id: string;
    question: string;
    questionAnswer: QuestionAnswerSchema[];
}

export type SurveyResponse = HttpResponse<string[], never>;

export type MissedClassSurveyResponse = HttpResponse<MissedClassSurveySchema, never>;

export type MissedClassApiAttributes = {
    classFeedback: string;
    class_id: string;
    student_id: number;
};

export const submitMissedClassFeedback = (data: MissedClassApiAttributes) =>
    httpPost(getUrl('api_missed_class_feedback_create'), data);

const getClassQuestionnaireByName = <T>(name: string): Promise<T> =>
    httpGet(getUrl('api_class_questionnaire_info', { name }));

export const getMissedClassQuestionnaire = () =>
    getClassQuestionnaireByName<MissedClassSurveyResponse>('missed-class');

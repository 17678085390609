import { type Appointment, AppointmentStatus, type Maybe } from '@lingoda/graphql';

interface ClassWithAppointment {
    appointment: Maybe<Pick<Appointment, 'status'>>;
}

export const isClassUnbooked = (classItem?: ClassWithAppointment) => {
    if (!classItem || !classItem.appointment) {
        return true;
    }

    return isAppointmentUnbooked(classItem.appointment);
};

export const isAppointmentUnbooked = (appointment: Pick<Appointment, 'status'>) => {
    return (
        appointment.status === AppointmentStatus.Missed ||
        appointment.status === AppointmentStatus.Deleted ||
        appointment.status === AppointmentStatus.Cancelled
    );
};

import { lazy } from 'react';
import { useOrientationClassEnabled } from '@lingoda/orientation-class';
import { Loader } from '@lingoda/ui';

const Booking = lazy(() => import('./BookClass'));
const BookOrientationClass = lazy(() => import('./BookOrientationClass'));

const BookingRoute = () => {
    const { loading, isOrientationClassEnabled } = useOrientationClassEnabled();

    if (loading) {
        return <Loader />;
    }

    return isOrientationClassEnabled ? <BookOrientationClass /> : <Booking />;
};

export default BookingRoute;

import { colorsPalette, colorsPaletteThirdParty } from '../../theme';
import type { SocialVendors } from '../../theme/models';
import type { ButtonProps } from '../../buttons';

export interface VendorConfig {
    colors: {
        main: string;
        hover: string;
    };
    variant?: Extract<ButtonProps['variant'], 'contained' | 'outlined'>;
}

export const vendorConfigMap: Record<SocialVendors, VendorConfig> = {
    apple: {
        colors: {
            main: colorsPaletteThirdParty.appleSolid[100],
            hover: colorsPaletteThirdParty.appleAlpha[80],
        },
        variant: 'contained',
    },
    facebook: {
        colors: {
            main: colorsPaletteThirdParty.facebookSolid[100],
            hover: colorsPaletteThirdParty.facebookAlpha[80],
        },
        variant: 'contained',
    },
    google: {
        colors: {
            main: colorsPalette.blackAlpha[70],
            hover: colorsPalette.blackAlpha[4],
        },
        variant: 'outlined',
    },
    linkedin: {
        colors: {
            main: colorsPaletteThirdParty.linkedInSolid[100],
            hover: colorsPaletteThirdParty.linkedInAlpha[80],
        },
        variant: 'contained',
    },
    twitter: {
        colors: {
            main: colorsPaletteThirdParty.twitterSolid[100],
            hover: colorsPaletteThirdParty.twitterAlpha[80],
        },
        variant: 'contained',
    },
    whatsapp: {
        colors: {
            main: colorsPaletteThirdParty.whatsappSolid[100],
            hover: colorsPaletteThirdParty.whatsappAlpha[80],
        },
        variant: 'contained',
    },
};

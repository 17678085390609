import { useCallback } from 'react';
import { useToggleState } from '@lingoda/hooks';

export const useLegal = () => {
    const [isPrivacyOpen, handleOpenPrivacy, handleClosePrivacy] = useToggleState(false);
    const [isPrivacyTerms, handleOpenTerms, handleCloseTerms] = useToggleState(false);

    const termsLinkCallback = useCallback(
        (e: Event) => {
            e.preventDefault();
            handleOpenTerms();
        },
        [handleOpenTerms],
    );

    const privacyLinkCallback = useCallback(
        (e: Event) => {
            e.preventDefault();
            handleOpenPrivacy();
        },
        [handleOpenPrivacy],
    );

    return {
        handleClosePrivacy,
        handleCloseTerms,
        isPrivacyOpen,
        isPrivacyTerms,
        termsLinkCallback,
        privacyLinkCallback,
    };
};

import { type TimeFormat, format, formatDateHour } from '@lingoda/dates';
import { useSelector } from '@lingoda/hooks';
import { studentTimeFormatPreferenceSelector } from '@lingoda/students';
import { Box, Grid, Typography } from '@lingoda/ui';
import { ClockIcon24 } from '@lingoda/ui/icons/lingodaIcons';
import { capitalize } from '@lingoda/utils';

const ClassTime = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
    const timeFormat = useSelector(studentTimeFormatPreferenceSelector);

    return (
        <Grid container item xs={12} marginTop={3}>
            <Box width={({ spacing }) => spacing(6)}>
                <ClockIcon24 />
            </Box>
            {startDate && (
                <Box width={({ spacing }) => `calc(100% - ${spacing(6)})`}>
                    <Typography variant="body1">
                        {capitalize(format('cccc, d MMMM yyyy', startDate))}
                    </Typography>
                    {endDate && (
                        <Typography variant="body1">
                            {getClassDuration(startDate, endDate, timeFormat)}
                        </Typography>
                    )}
                </Box>
            )}
        </Grid>
    );
};

const getClassDuration = (startDate: Date, endDate: Date, timeFormat: TimeFormat) => {
    const startHour = formatDateHour(startDate, timeFormat);
    const endHour = formatDateHour(endDate, timeFormat);
    const timeZoneGtmLabel = format('O', startDate);

    return `${startHour} - ${endHour} (${timeZoneGtmLabel})`;
};

export default ClassTime;

import {
    createPaymentMethod,
    deletePaymentContract as deletePaymentContractApi,
    getPaymentMethods,
    setDefaultPaymentMethod as setDefaultPaymentMethodApi,
    submitPaymentSubscription,
    submitPurchasePayment as submitPurchasePaymentApi,
} from '@lingoda/api';
import { fetchMe } from '@lingoda/auth';
import { addCallback, addTrackerCallback } from '@lingoda/core';
import { checkoutPath, goTo } from '@lingoda/router';
import { userCurrencySelector } from '@lingoda/currency';
import {
    addPaymentMethod,
    deletePaymentContract,
    fetchPaymentMethods,
    retryPayment,
    setDefaultPaymentMethod,
    submitPurchasePayment,
} from '../actions';
import { PaymentGateway } from '../models';

interface AdyenOptions {
    url: string;
}

const performAdyenFlow = ({ url }: AdyenOptions) => {
    goTo(checkoutPath(url));
};

export default () => {
    addTrackerCallback(addPaymentMethod, async ({ payload }, store) => {
        const response = await createPaymentMethod({
            paymentMethod: payload.paymentMethod,
            currency: payload.currency,
            redirectUrl: undefined,
            nameOnCard: payload.nameOnCard,
        });

        if (payload.gateway === PaymentGateway.STRIPE) {
            return { data: response.data, redirectUrl: payload.redirectUrl };
        }

        if (!response.data.url) {
            if (payload.redirectUrl) {
                goTo(payload.redirectUrl);
            }

            return response;
        }

        performAdyenFlow({ url: response.data.url });

        // Reload default contract but should find less broad way
        store.dispatch(fetchMe());

        return response;
    });

    addCallback([setDefaultPaymentMethod.success, addPaymentMethod.success], (action, store) => {
        store.dispatch(fetchPaymentMethods(action.payload.payload.currency));
    });

    addTrackerCallback(setDefaultPaymentMethod, (action) =>
        setDefaultPaymentMethodApi({ paymentMethod: action.payload.paymentMethod }),
    );

    addTrackerCallback(deletePaymentContract, ({ payload }) => deletePaymentContractApi(payload));

    addCallback([deletePaymentContract.success], (_, store) => {
        const currency = userCurrencySelector(store.getState());
        store.dispatch(fetchPaymentMethods(currency));
    });

    addTrackerCallback(fetchPaymentMethods, ({ payload }) => getPaymentMethods(payload));

    addTrackerCallback(retryPayment, async ({ payload }) => {
        const response = await submitPaymentSubscription(payload);

        if (payload.gateway === PaymentGateway.STRIPE) {
            return response;
        }

        if (!response.data.url) {
            if (payload.redirectUrl) {
                goTo(payload.redirectUrl);
            }

            return response;
        }

        performAdyenFlow({ url: response.data.url });

        return response;
    });

    addTrackerCallback(submitPurchasePayment, async ({ payload }) => {
        const response = await submitPurchasePaymentApi(payload);

        if (payload.gateway === PaymentGateway.STRIPE) {
            return response;
        }

        if (!response.data.url) {
            goTo(payload.redirectUrl);

            return response;
        }

        performAdyenFlow({ url: response.data.url });

        return response;
    });
};

import { getApp } from '@lingoda/env';
import { isAbsolute } from '@lingoda/urls';
import history from './history';
import resolvePath from './resolvePath';
import { UrlProvider } from './types';
import type { Path } from './types';

function redirectTo(url: string): void {
    window.location.replace(url);
}

function navigateTo(url: string): void {
    window.location.href = url;
}

function replaceState(url: string) {
    window.history.replaceState(undefined, '', url);
    navigateTo(url);
}

const redirectInternalPath = (path: Path) => {
    history.replace(resolvePath(path));
};

export default (path: Path) => {
    if (typeof path === 'string') {
        if (isAbsolute(path)) {
            redirectTo(path);
        } else {
            redirectInternalPath(path);
        }

        return;
    }

    switch (path.provider) {
        case UrlProvider.External:
            replaceState(path.href);
            break;
        case UrlProvider.Student:
            if (getApp() === 'student') {
                redirectInternalPath(path);
            } else {
                navigateTo(resolvePath(path));
            }
            break;
    }
};

import { createContext, useContext } from 'react';
import { filtersOptionsDefault } from './filter';
import type { StoreContextValue } from './types';

const StoreContext = createContext<StoreContextValue>({
    loading: false,
    filteredPackages: [],
    filtersOptions: filtersOptionsDefault,
    filtersState: null,
    updateFilter: () => undefined,
});

export const useStoreContext = () => useContext(StoreContext);

export default StoreContext;

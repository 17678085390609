import { trans } from '@lingoda/i18n';
import { goTo, subscriptionsStorePath } from '@lingoda/router';
import { DialogConfirm, type DialogConfirmProps, Typography } from '@lingoda/ui';

interface ConfirmBookInTrialModalProps extends Pick<DialogConfirmProps, 'confirmStatus'> {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const ConfirmBookInTrialModal = ({
    isOpen,
    onClose,
    onConfirm,
    confirmStatus,
}: ConfirmBookInTrialModalProps) => {
    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    const handleDismiss = () => {
        onClose();
        goTo(subscriptionsStorePath());
    };

    return (
        <DialogConfirm
            isOpen={isOpen}
            title={trans('sure-want-to-buy-extra-credits', {}, 'student-store')}
            dismissLabel={trans('subscribe-unlock-subscribe-button', {}, 'student-store')}
            confirmLabel={trans('buy-extra-credits-button', {}, 'student-store')}
            onConfirm={handleConfirm}
            onDismiss={handleDismiss}
            onClose={onClose}
            confirmStatus={confirmStatus}
        >
            <Typography variant="body1">
                {trans('buy-extra-in-trial-message', {}, 'student-store')}
            </Typography>
        </DialogConfirm>
    );
};

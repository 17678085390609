import { trans } from '@lingoda/i18n';
import { ProgressCircle } from '@lingoda/ui';

interface Props {
    total: number;
    completed: number;
}

const Progress = ({ total, completed }: Props) => (
    <ProgressCircle
        labelType="ratio"
        completed={completed}
        total={total}
        tooltipTitle={trans('you-have-taken', { completed, total }, 'student-common')}
        variant="modern"
    />
);

export default Progress;

import { produce } from 'immer';
import { createSelector } from 'reselect';
import { studentIdSelector } from '@lingoda/students';
import { createRecordFactory } from '@lingoda/utils';
import type { StudentId } from '@lingoda/students';
import { AppointmentStudentStatus } from '../models';
import { isStudentAppointmentActive } from '../utils';
import { expandedClassesSelector } from './expandedClass';
import expandedAppointmentsSelector from './expandedAppointment';
import type { AppointmentExpanded, ClassExpanded } from '../models';

export interface ClassWithAppointmentExpanded extends ClassExpanded {
    booked: boolean;
    rated?: boolean;
    studentAppointment?: AppointmentExpanded;
}

export const ClassAppointmentExpandedFactory = createRecordFactory<ClassWithAppointmentExpanded>({
    booked: false,
    endDate: undefined,
    id: undefined,
    learningUnit: undefined,
    lesson: undefined,
    lessonId: undefined,
    module: undefined,
    moduleId: undefined,
    provider: undefined,
    rated: undefined,
    startDate: undefined,
    studentAppointment: undefined,
    teacher: undefined,
    teacherId: undefined,
    temporary: undefined,
    type: undefined,
    availableSeats: undefined,
    maxSeats: undefined,
    status: undefined,
    cancelledByGroup: undefined,
    annotatedPresentation: undefined,
});

const isClassBooked = (appointments: AppointmentExpanded[], studentId: StudentId) =>
    appointments.some((appointment) => isStudentAppointmentActive(appointment, studentId));

const expandClass = (
    classItem: ClassExpanded,
    appointments: AppointmentExpanded[],
    studentId: StudentId,
): ClassWithAppointmentExpanded => {
    const expandedClassItem = ClassAppointmentExpandedFactory(classItem);
    const studentAppointment = appointments.find(
        (appointment) => appointment.studentId === studentId,
    );

    return produce(expandedClassItem, (draft) => {
        draft.booked = isClassBooked(appointments, studentId);
        draft.studentAppointment = studentAppointment;
        draft.rated =
            !!studentAppointment &&
            (!!studentAppointment.feedbackId ||
                studentAppointment.statusByStudent === AppointmentStudentStatus.NOT_ATTENDED);
    });
};

export const expandClassesWithAppointmentSelector = createSelector(
    expandedAppointmentsSelector,
    expandedClassesSelector,
    studentIdSelector,
    (allAppointments, expandedClasses, studentId) =>
        expandedClasses &&
        expandedClasses.map((classItem) => {
            const appointments = allAppointments.filter((item) => item.classId === classItem.id);

            return expandClass(classItem, appointments, studentId as number);
        }),
);

import { useEffect, useState } from 'react';
import { type ClassicCard, requestContentCardsRefresh, showContentCards } from '@braze/web-sdk';
import { getLang } from '@lingoda/env';
import { useBrazeSdk } from './useBrazeSdk';

export type ContentCardPlace = 'today_above' | 'today_below';

export const useBrazeContentCards = (
    ref: React.RefObject<HTMLDivElement>,
    placeholder: ContentCardPlace,
) => {
    const lang = getLang();
    // We need to return isVisible as true initially to show card container which braze use to initialize
    const [cardCount, setCardCount] = useState(-1);

    const { isSdkInitialized, userUid } = useBrazeSdk();

    useEffect(() => {
        let mounted = true;

        if (!isSdkInitialized || !userUid) {
            return;
        }

        requestContentCardsRefresh(() => {
            // Avoid showing cards when braze container was already removed (otherwise Braze will show a drawer)
            if (!ref.current) {
                return;
            }

            showContentCards(ref.current, (cards) => {
                const filterCards = cards.filter(
                    (card) =>
                        card.extras?.sbe_placement === placeholder &&
                        card.extras?.sbe_language === lang,
                );

                // Avoid update state caused by Braze when component was already unmounted
                if (mounted) {
                    setCardCount(filterCards.length);
                }

                filterCards.forEach((card) => {
                    card.pinned = false;
                    const subscriptionId = card.subscribeToDismissedEvent(() => {
                        setCardCount((count) => count - 1);
                        card.removeSubscription(subscriptionId);
                    });
                    (card as ClassicCard).dismissible = true;
                });

                return filterCards;
            });
        });

        return () => {
            mounted = false;
        };
    }, [ref, placeholder, lang, isSdkInitialized, userUid]);

    return { isVisible: cardCount !== 0 } as const;
};

import { useMemo, useState } from 'react';
import { Box, ProgressBar, Stack, colorsPalette, getLinearScale, styled } from '@lingoda/ui';
import type { ProgressBarProps } from '@lingoda/ui';
import { useElementSize } from '@lingoda/hooks';
import { getMaxPrizeValue } from '../utils';
import { usePrizeIcons } from '../hooks';
import type { Prize } from '../types';

interface ChallengeProgressBarProps {
    size: ProgressBarProps['size'];
    challengeName: string;
    prizes: ReadonlyArray<Prize>;
    completedActivities: number;
}

export const ChallengeProgressBar = ({
    size,
    challengeName,
    prizes,
    completedActivities,
}: ChallengeProgressBarProps) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
    const width = useElementSize(containerRef, 'clientWidth');

    const progressBars = useMemo(
        () =>
            getProgressBars({
                prizes,
                completedActivities,
                maxPrizeValue: getMaxPrizeValue(prizes),
                totalWidth: Math.max(
                    width - prizes.length * ICON_CIRCLE_SIZE,
                    prizes.length * MIN_BAR_WIDTH,
                ),
            }),
        [completedActivities, prizes, width],
    );

    const { lockedIcons, unlockedIcons } = usePrizeIcons(prizes);

    return (
        <Box>
            <Stack direction="row" width="100%" ref={setContainerRef}>
                {prizes.map((prize, index) => (
                    <Stack direction="row" key={`${challengeName}-${index}`} alignItems="center">
                        <ProgressBar
                            variant="determinate"
                            size={size}
                            value={progressBars[index].progressValue}
                            barColor={progressBars[index].barColor}
                            sx={{
                                width: progressBars[index].width + ICON_CIRCLE_OVERLAP_WIDTH * 2,
                                maxWidth: 'none',
                            }}
                            rounded={index === 0 ? 'left' : false}
                        />
                        <CircleBox
                            unlocked={progressBars[index].unlocked}
                            circleColor={progressBars[index].circleColor}
                        >
                            <PrizeIconBox>
                                <img
                                    src={
                                        progressBars[index].unlocked
                                            ? unlockedIcons[index]
                                            : lockedIcons[index]
                                    }
                                    alt={prize.nounTranslation}
                                    width={progressBars[index].unlocked ? 28 : 24}
                                    height={progressBars[index].unlocked ? 28 : 24}
                                />
                            </PrizeIconBox>
                        </CircleBox>
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
};

const ICON_CIRCLE_SIZE = 40;
const ICON_CIRCLE_BORDER_WIDTH = 2;
const ICON_CIRCLE_OVERLAP_WIDTH = 2;
const MIN_BAR_WIDTH = 4;

export const CircleBox = styled(Box, {
    shouldForwardProp: (name) => !['unlocked', 'circleColor'].includes(name as string),
})<{ unlocked: boolean; circleColor: string }>(({ unlocked, circleColor }) => ({
    border: `${ICON_CIRCLE_BORDER_WIDTH}px solid`,
    marginLeft: -ICON_CIRCLE_OVERLAP_WIDTH,
    marginRight: -ICON_CIRCLE_OVERLAP_WIDTH,
    width: ICON_CIRCLE_SIZE,
    height: ICON_CIRCLE_SIZE,
    minWidth: ICON_CIRCLE_SIZE,
    borderRadius: '50%',
    color: colorsPalette.blackSolid[8],
    zIndex: 2,

    ...(unlocked && {
        position: 'relative',
        color: 'transparent',

        '&:before': {
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: '50%',
            margin: -ICON_CIRCLE_BORDER_WIDTH,
            padding: ICON_CIRCLE_BORDER_WIDTH,
            background: circleColor,
            WebkitMask: `linear-gradient(${colorsPalette.whiteSolid[100]} 0 0) content-box, linear-gradient(${colorsPalette.whiteSolid[100]} 0 0)`,
            WebkitMaskComposite: 'xor',
            MaskComposite: 'exclude',
        },
    }),
}));

const PrizeIconBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    padding: 2,
    background: colorsPalette.whiteSolid[100],
});

interface GetProgressBarsArgs {
    prizes: ReadonlyArray<{ value: number }>;
    completedActivities: number;
    maxPrizeValue: number;
    totalWidth: number;
}

interface GetProgressBarOutput {
    left: number;
    width: number;
    progressValue: number;
    barColor: string;
    circleColor: string;
    unlocked: boolean;
}

const getProgressBars = ({
    prizes,
    completedActivities,
    maxPrizeValue,
    totalWidth,
}: GetProgressBarsArgs) =>
    prizes
        .map(({ value }) => value)
        .map((value, index, array) =>
            index === 0 ? { min: 0, max: value } : { min: array[index - 1], max: value },
        )
        .map(({ min, max }) => {
            const scaleWidth = getLinearScale(0, maxPrizeValue, 0, totalWidth);
            const width = scaleWidth(max - min);

            const scaleProgressValue = getLinearScale(min, max, 0, 100);
            const progressValueScaled = scaleProgressValue(completedActivities);
            const progressValue = Math.min(Math.max(progressValueScaled, 0), 100);

            const scaleProgressFillWidth = getLinearScale(0, 100, 0, width);
            const progressValueFillScaled = scaleProgressFillWidth(progressValue);
            const progressFillWidth = Math.min(Math.max(progressValueFillScaled, 0), width);

            return {
                progressValue,
                progressFillWidth,
                width,
                unlocked: completedActivities >= max,
            };
        })
        .reduce((acc, { width, progressValue, progressFillWidth, unlocked }, index) => {
            const last = index > 0 ? acc[acc.length - 1] : { left: 0, width: 0 };
            const left = last.left + last.width;

            return [
                ...acc,
                {
                    left,
                    width,
                    progressValue,
                    barColor: getPurpleToPrimary(left - (width - progressFillWidth), totalWidth),
                    circleColor: getPurpleToPrimary(left + width, totalWidth),
                    unlocked,
                },
            ];
        }, [] as GetProgressBarOutput[]);

const getPurpleToPrimary = (offset: number, totalWidth: number) =>
    `linear-gradient(90deg, ${colorsPalette.purpleSolid[100]} ${-offset}px, ${
        colorsPalette.primarySolid[100]
    } ${totalWidth * 1.8 - offset}px);`;

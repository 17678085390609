import { Box, Skeleton, Typography } from '@lingoda/ui';

export const ChecklistItemSkeleton = () => {
    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body3">
                <Skeleton variant="circular" width={20} height={20} />
            </Typography>
            <Box sx={{ flex: '1 1 auto', marginLeft: 1 }}>
                <Typography variant="subtitle2">
                    <Skeleton variant="text" />
                </Typography>
            </Box>
        </Box>
    );
};

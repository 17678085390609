import type { Date } from '@lingoda/dates';
import type { SectionName } from '@lingoda/sections';
import { createRecordFactory } from '@lingoda/utils';

export type TeacherId = number;

export interface Photo {
    medium?: string;
    medium2x?: string;
    thumbnail_large?: string;
    thumbnail_large2x?: string;
    thumbnail?: string;
    thumbnail2x?: string;
}

export interface Teacher {
    id: TeacherId;
    firstName?: string;
    canTeachGroupClass?: boolean;
    canTeachPrivateClass?: boolean;
    createdAt?: Date;
    photo?: string;
    photos?: Photo;
    teacherInfo: {
        aboutMe?: string;
        classRating?: number;
        country?: string;
        firstName?: string;
        largeThumbnail?: string;
        spokenLanguages?: {
            fluency: string;
            language: string;
        }[];
        teachLanguage?: {
            id: number;
            name: SectionName;
        };
        thumbnail?: string;
    };
    uid: string;
}

export const TeacherFactory = createRecordFactory<Teacher>({
    id: undefined,
    firstName: undefined,
    photo: undefined,
    photos: {},
    teacherInfo: undefined,
    uid: undefined,
    canTeachGroupClass: undefined,
    canTeachPrivateClass: undefined,
    createdAt: undefined,
});

export default TeacherFactory;

import { useState } from 'react';
import { type DialogBaseProps } from '@lingoda/dialogs';
import { trans } from '@lingoda/i18n';
import { Box, DialogConfirm, OptionButton, Stack, Typography, colorsPalette } from '@lingoda/ui';
import { Buttons } from '../Buttons';
import { BubbleSpeechProgress } from './BubbleSpeechProgress';

interface Option {
    value: number;
    left: string;
    right: string;
}

interface Props extends Pick<DialogBaseProps, 'hideDialog'> {
    closable?: boolean;
    currentGoal?: number | null;
    loading: boolean;
    onDeleteGoal: () => void;
    onSaveGoal: (selectedValue: number) => void;
    options: Option[];
    subtitle?: string;
    title: string;
}

export const Layout = ({
    closable = false,
    currentGoal,
    hideDialog,
    loading,
    onDeleteGoal,
    onSaveGoal,
    options,
    subtitle,
    title,
}: Props) => {
    const [selectedValue, setSelectedValue] = useState(currentGoal || UNSELECTED_VALUE);

    const handleSaveGoal = () => {
        onSaveGoal(selectedValue);
    };

    const confirmStatus = loading
        ? 'loading'
        : selectedValue === UNSELECTED_VALUE
          ? 'disabled'
          : 'enabled';
    const disabled = confirmStatus === 'disabled';

    return (
        <DialogConfirm
            isOpen
            onConfirm={handleSaveGoal}
            renderConfirmButton={({ dataCy, onConfirm }) => (
                <Buttons
                    dataCy={dataCy}
                    onConfirm={onConfirm}
                    currentGoal={currentGoal}
                    disabled={disabled}
                    onDeleteGoal={onDeleteGoal}
                />
            )}
            onClose={closable ? hideDialog : undefined}
            confirmStatus={confirmStatus}
            actionsProps={{ justifyContent: 'center', display: 'flex' }}
            noHeader
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box marginTop={3}>
                    <Typography variant="h2">{title}</Typography>
                </Box>
                <Box marginTop={2}>
                    <Typography variant="body1" htmlColor={colorsPalette.blackAlpha[70]}>
                        {subtitle}
                    </Typography>
                </Box>
            </Box>
            <Stack spacing={3} mt={2}>
                <Stack spacing={2}>
                    {options.map(({ value, left, right }) => (
                        <OptionButton
                            key={value}
                            selected={value === selectedValue}
                            sx={{
                                px: 2,
                                py: 2,
                                minHeight: 48,
                                color: colorsPalette.blackSolid[100],
                            }}
                            onClick={() => setSelectedValue(value)}
                            labelFullWidth
                            disabled={loading}
                        >
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                justifyContent="space-between"
                                alignItems="center"
                                flex={1}
                            >
                                <Typography variant="body1" fontWeight={600}>
                                    {left}
                                </Typography>
                                <Typography variant="body1">{right}</Typography>
                            </Stack>
                        </OptionButton>
                    ))}
                </Stack>
                <BubbleSpeechProgress text={trans('set-goal-hint', {}, 'student-common')} />
            </Stack>
        </DialogConfirm>
    );
};

const UNSELECTED_VALUE = -1;

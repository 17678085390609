import { type PropsWithChildren, useCallback, useEffect } from 'react';
import { actionClick } from '@lingoda/analytics';
import { cancelledExpiresAtSelector } from '@lingoda/auth';
import { getLeadTime, minIndividualBookingDateSelector } from '@lingoda/booking';
import { type Date, addMonths, createDate, endOfDay, isBefore } from '@lingoda/dates';
import { TextField, useFormContext } from '@lingoda/forms';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { studentTimeFormatPreferenceSelector } from '@lingoda/students';
import { Box, Typography, useUiEvent } from '@lingoda/ui';
import { DateTimePicker } from '@lingoda/ui/fields/DateTimePicker';
import { type FormValues } from './schema';

interface PrivateClassFormProps {
    disabled?: boolean;
}

export const PrivateClassForm = ({
    children,
    disabled,
}: PropsWithChildren<PrivateClassFormProps>) => {
    const addEvent = useUiEvent();
    const dispatch = useDispatch();

    const { setFieldValue, values } = useFormContext<FormValues>();
    const onTopicClick = useCallback(() => {
        void addEvent(actionClick('Topic'));
    }, [addEvent]);

    const timeFormat = useSelector(studentTimeFormatPreferenceSelector);
    const minDate = useSelector(minIndividualBookingDateSelector);
    const cancelledExpiresAt = useSelector(cancelledExpiresAtSelector);
    const in3MonthDate = addMonths(endOfDay(createDate()), 3);
    const maxDate =
        cancelledExpiresAt && isBefore(cancelledExpiresAt, in3MonthDate)
            ? cancelledExpiresAt
            : in3MonthDate;

    const handleDateChange = useCallback(
        (value: Date) => {
            setFieldValue('startDate', value);
        },
        [setFieldValue],
    );

    useEffect(() => {
        dispatch(getLeadTime());
    }, [dispatch]);

    return (
        <>
            <Box mt={1} mb={3}>
                <TextField
                    name="topic"
                    onClick={onTopicClick}
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    label={trans('tell-us-what-to-learn-label', {}, 'student-common')}
                    placeholder={trans('custom-private-class-placeholder', {}, 'student-common')}
                    variant="outlined"
                    disabled={disabled}
                />
            </Box>
            <Box mb={3}>
                <Typography>
                    {trans('specific-teacher-not-possible', {}, 'student-common')}
                </Typography>
            </Box>
            <Box>
                <DateTimePicker
                    name="date"
                    onChange={handleDateChange}
                    minDate={minDate || createDate()}
                    maxDate={maxDate}
                    value={values.startDate || minDate}
                    timeFormat={timeFormat}
                    disabled={disabled}
                />
            </Box>
            {children}
        </>
    );
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const FlagGermanIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_2043_1236"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
        >
            <circle cx="8" cy="8" r="8" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_2043_1236)">
            <rect width="16" height="5.33333" fill="#151515" />
            <rect y="5.33331" width="16" height="5.33333" fill="#DB2727" />
            <rect y="10.6667" width="16" height="5.33333" fill="#F8D12E" />
        </g>
    </SvgIcon>
);

export const FlagGermanIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_2043_980"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                fill="#C4C4C4"
            />
        </mask>
        <g mask="url(#mask0_2043_980)">
            <path d="M0 0H24V8H0V0Z" fill="#151515" />
            <path d="M0 8H24V16H0V8Z" fill="#DB2727" />
            <path d="M0 16H24V24H0V16Z" fill="#F8D12E" />
        </g>
    </SvgIcon>
);

import { createDate, diffInMinutes, toUTCString } from '@lingoda/dates';
import { getStorageValue, setStorageValue } from '@lingoda/utils';

const LOCAL_STORAGE_KEY = 'lastBookingDate';

interface LastBooking {
    date: Date;
    count: number;
}

const BOOKING_COMBO_TIMEOUT_MIN = 15;

export const setLastBookingDate = (date: Date): LastBooking => {
    const oldValue = getLastBookingCount();

    const newCount =
        oldValue && diffInMinutes(date, oldValue.date) < BOOKING_COMBO_TIMEOUT_MIN
            ? oldValue.count + 1
            : 1;

    const newValue = {
        date: toUTCString(date),
        count: newCount,
    };

    setStorageValue(LOCAL_STORAGE_KEY, newValue);

    return {
        date,
        count: newCount,
    };
};

export const getLastBookingCount = (): LastBooking | null => {
    const value = getStorageValue(LOCAL_STORAGE_KEY) as { date: string; count: number } | null;
    const date = value?.date ? createDate(value.date) : null;

    return date ? { date, count: value?.count ?? 1 } : null;
};

export const clearLastBookingDate = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
};

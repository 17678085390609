import isEmail from 'validator/lib/isEmail';
import { trans as translate } from '@lingoda/i18n';
import { getTimezones } from '@lingoda/dates';

interface Field {
    required?: boolean | string;
    email?: boolean;
    min?: number;
    max?: number;
    password?: boolean;
    phone?: {
        // redux
        prefix?: string;
        // formik
        codeName?: string;
        phoneName?: string;
    };
    number?: boolean;
    match?: { with: string; message: string };
    timezone?: boolean;
    notEmptyArray?: boolean | string;
    boolean?: true;
}

interface Fields {
    [field: string]: Field;
}

interface Options {
    checkNull?: boolean;
}

const validateField = (
    field: Field,
    fieldValue: unknown,
    getRelatedFieldValue: (fieldName: string) => unknown,
    options?: Options,
) => {
    for (const constraint in field) {
        const fieldConstraint: Field[keyof Field] = field[constraint as keyof Field];
        if (!field.hasOwnProperty(constraint) || !fieldConstraint) {
            continue;
        }
        let pattern;
        switch (constraint) {
            case 'required':
                const normalizedField =
                    typeof fieldValue === 'string'
                        ? fieldValue && fieldValue?.replace(/\s/g, '')
                        : fieldValue;
                if (
                    normalizedField === undefined ||
                    normalizedField === '' ||
                    (!field.boolean && normalizedField === false) ||
                    (options?.checkNull && normalizedField === null)
                ) {
                    return getValidationMessage(
                        fieldConstraint,
                        translate('form-validation-required', {}, 'validators'),
                    );
                }
                break;
            case 'email':
                if (!isEmail(fieldValue as string)) {
                    return translate('form-validation-email', {}, 'public-common');
                }
                break;
            case 'min':
                if (
                    !fieldValue ||
                    (fieldValue as string).length < (fieldConstraint as NonNullable<Field['min']>)
                ) {
                    return translate('form-validation-minlength', {}, 'public-common');
                }
                break;
            case 'max':
                if (
                    !fieldValue ||
                    (fieldValue as string).length > (fieldConstraint as NonNullable<Field['max']>)
                ) {
                    return translate('form-validation-maxlength', {}, 'public-common');
                }
                break;
            case 'password':
                pattern = /^.{6,}$/;
                if (fieldValue && !pattern.test(fieldValue as string)) {
                    return translate('form-validation-password', {}, 'validators');
                }
                break;
            case 'number':
                pattern = /^\d+$/;
                if (!pattern.test(fieldValue as string)) {
                    return translate('form-validation-number', {}, 'public-common');
                }
                break;
            case 'match':
                if (
                    fieldValue &&
                    getRelatedFieldValue((fieldConstraint as NonNullable<Field['match']>).with) !==
                        fieldValue
                ) {
                    return field[constraint]?.message;
                }
                break;
            case 'timezone':
                if (!getTimezones().includes(fieldValue as string)) {
                    return translate('form-validation-required', {}, 'validators');
                }
                break;

            case 'notEmptyArray':
                if (Array.isArray(fieldValue) && fieldValue.length === 0) {
                    return getValidationMessage(
                        fieldConstraint,
                        translate('form-validation-empty-array', {}, 'validators'),
                    );
                }
                break;
        }
    }
};

const getValidationMessage = (fieldConstraint: Field[keyof Field], fallback: string) => {
    return typeof fieldConstraint === 'string' ? fieldConstraint : fallback;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (fields: Fields, options?: Options) => (values: { [key: string]: any }) => {
    const errors: GenericObject<string | true> = {};

    const getRelatedFieldValue = (fieldName: string) => values[fieldName];

    for (const fieldName in values) {
        if (!values.hasOwnProperty(fieldName)) {
            continue;
        }
        const field = fields[fieldName];
        const fieldValue = values[fieldName];
        const error = validateField(field, fieldValue, getRelatedFieldValue, options);
        if (error) {
            errors[fieldName] = error;
        }
    }

    return errors;
};

import { colorsPalette, styled } from '@lingoda/ui/theme';
import BaseBanner from '../BaseBanner';
import bgImage from './background-image.png';
import bgImage2x from './background-image@2x.png';

const gradientCss = `linear-gradient(260.96deg, ${colorsPalette.primarySolid[100]} -80.13%, ${colorsPalette.purpleSolid[100]} 100%)`;

const PromoBanner = styled(BaseBanner)(({ theme }) => ({
    color: colorsPalette.whiteSolid[100],
    backgroundPosition: 'right center',
    backgroundImage: `url(${bgImage}), ${gradientCss}`,

    '@media(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {
        backgroundImage: `url(${bgImage2x}), ${gradientCss}`,
    },

    [theme.breakpoints.only('xs')]: {
        padding: theme.spacing(2),
    },
}));

export default PromoBanner;

import { userCurrencySelector } from '@lingoda/currency';
import { type Currency, useConfigurationQuery } from '@lingoda/graphql';
import { CurrencyField as CurrencyFieldForms } from '@lingoda/forms';
import { useSelector } from '@lingoda/hooks';

export interface CurrencyFieldProps {
    value?: Currency;
    onChange: (currency: Currency) => void;
    disabled?: boolean;
    fullWidth?: boolean;
}

export const CurrencyField = ({ value, onChange, disabled, fullWidth }: CurrencyFieldProps) => {
    const defaultValue = useSelector(userCurrencySelector);

    const { data } = useConfigurationQuery();

    const currencies = data?.config.currencies;

    if (!currencies) {
        return null;
    }

    return (
        <CurrencyFieldForms
            currency={value || defaultValue}
            currencies={currencies}
            onChange={onChange}
            disabled={disabled}
            fullWidth={fullWidth}
        />
    );
};

import { isFunction } from '@lingoda/utils';
import { type AcceptedLang } from '@lingoda/env';
import type { DynamicOption, Path } from '../types';

export interface LocaleType {
    locale?: string;
}

export interface RouterPathFunction<P> {
    (params: P, locale?: AcceptedLang): Path;
    raw: string;
}

export const resolveDynamicOption = <T>(param?: DynamicOption<T>) => {
    return isFunction(param) ? param() : param;
};

import type { ClassType } from '@lingoda/graphql';
import type { Date } from '@lingoda/dates';
import type { StudentId } from '@lingoda/students';
import type { Lesson } from '@lingoda/lessons';
import { createRecordFactory } from '@lingoda/utils';
import type { ClassId } from '../constants';
import type { ClassCancelledByGroup } from './cancelledByGroup';
import type { ClassProviders } from './providers';
import type { ClassStatus } from './status';

export interface Class {
    readonly availableSeats: number;
    readonly maxSeats?: number;
    readonly endDate?: Date;
    readonly id: ClassId;
    readonly tempId?: ClassId;
    readonly lessonId?: number;
    readonly lesson?: Lesson;
    readonly moduleId: number;
    readonly provider?: ClassProviders;
    readonly startDate: Date;
    readonly teacherId?: number;
    readonly temporary?: boolean;
    readonly type: ClassType;
    readonly status?: ClassStatus;
    readonly studentIds?: StudentId[];
    readonly topic?: string;
    readonly isOrientation?: boolean;
    readonly cancelledByGroup?: ClassCancelledByGroup;
    readonly annotatedPresentation?: string;
    readonly stats?: string;
}

export const ClassFactory = createRecordFactory<Class>({
    availableSeats: 0,
    maxSeats: undefined,
    endDate: undefined,
    id: '',
    lessonId: undefined,
    moduleId: undefined,
    provider: undefined,
    startDate: undefined,
    teacherId: undefined,
    temporary: undefined,
    type: undefined,
    status: undefined,
    topic: undefined,
    studentIds: [],
    cancelledByGroup: undefined,
    annotatedPresentation: undefined,
});

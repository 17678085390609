import { injectAsyncReducer } from '@lingoda/core';
import { PRODUCTS_STORE_KEY, PRODUCT_VARIANTS_STORE_KEY } from './constants';
import product from './reducers/product';
import productVariant from './reducers/productVariant';
import initSideEffects from './side-effects';

export * from './actions';
export * from './hooks';
export * from './models';
export * from './selectors';
export * from './utils';

injectAsyncReducer(PRODUCT_VARIANTS_STORE_KEY, productVariant);
injectAsyncReducer(PRODUCTS_STORE_KEY, product);
initSideEffects();

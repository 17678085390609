import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const FlagSpanishIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_2043_1234"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
        >
            <circle cx="8" cy="8" r="8" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_2043_1234)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-1.33334 4.26667H21.0667V0H-1.33334V4.26667Z"
                fill="#DD172C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-1.33334 16H21.0667V11.7333H-1.33334V16Z"
                fill="#DD172C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-1.33334 11.7333H21.0667V4.26666H-1.33334V11.7333Z"
                fill="#FFD133"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.27222 7.79979C3.23335 7.33337 3.60142 6.93335 4.06945 6.93335H4.99724C5.46527 6.93335 5.83334 7.33337 5.79448 7.79979L5.68859 9.07037C5.63853 9.6712 5.13626 10.1334 4.53335 10.1334C3.93043 10.1334 3.42817 9.6712 3.3781 9.07037L3.27222 7.79979Z"
                fill="#A41517"
            />
            <rect
                x="1.86667"
                y="6.40002"
                width="1.06667"
                height="3.73333"
                rx="0.533333"
                fill="#A41517"
            />
            <rect
                x="6.13333"
                y="6.40002"
                width="1.06667"
                height="3.73333"
                rx="0.533333"
                fill="#A41517"
            />
            <path
                d="M3.46667 6.18671C3.46667 5.71542 3.84872 5.33337 4.32001 5.33337H4.74667C5.21796 5.33337 5.60001 5.71542 5.60001 6.18671C5.60001 6.30453 5.5045 6.40004 5.38667 6.40004H3.68001C3.56219 6.40004 3.46667 6.30453 3.46667 6.18671Z"
                fill="#A41517"
            />
        </g>
    </SvgIcon>
);

export const FlagSpanishIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_2043_977"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                fill="#C4C4C4"
            />
        </mask>
        <g mask="url(#mask0_2043_977)">
            <path fillRule="evenodd" clipRule="evenodd" d="M-2 6.4H31.6V0H-2V6.4Z" fill="#DD172C" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-2 24H31.6V17.6H-2V24Z"
                fill="#DD172C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M-2 17.6H31.6V6.39998H-2V17.6Z"
                fill="#FFD133"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.79999 11.2H8.39999V12H6.79999V11.2Z"
                fill="#FFEDB1"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.93059 11.2L5.66947 11.2L5.87501 13.6665C5.90956 14.0811 6.25617 14.4 6.67224 14.4H6.92782C7.34389 14.4 7.6905 14.0811 7.72505 13.6665L7.93059 11.2ZM5.66947 10.4C5.20144 10.4 4.83337 10.8 4.87223 11.2665L5.07777 13.7329C5.14688 14.5622 5.8401 15.2 6.67224 15.2H6.92782C7.75996 15.2 8.45319 14.5622 8.52229 13.7329L8.72783 11.2665C8.7667 10.8 8.39862 10.4 7.93059 10.4H5.66947Z"
                fill="#A41517"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.20001 12H8.40001V12.8H7.60001L6.80001 14.4L6.00001 12.8H5.20001V12Z"
                fill="#A41517"
            />
            <path
                d="M2.79999 10.4C2.79999 9.95818 3.15816 9.60001 3.59999 9.60001C4.04182 9.60001 4.39999 9.95818 4.39999 10.4V14.4C4.39999 14.8418 4.04182 15.2 3.59999 15.2C3.15816 15.2 2.79999 14.8418 2.79999 14.4V10.4Z"
                fill="#A41517"
            />
            <path
                d="M9.19998 10.4C9.19998 9.95818 9.55815 9.60001 9.99998 9.60001C10.4418 9.60001 10.8 9.95818 10.8 10.4V14.4C10.8 14.8418 10.4418 15.2 9.99998 15.2C9.55815 15.2 9.19998 14.8418 9.19998 14.4V10.4Z"
                fill="#A41517"
            />
            <path
                d="M5.20001 9.28003C5.20001 8.57311 5.77309 8.00003 6.48001 8.00003H7.12001C7.82694 8.00003 8.40001 8.57311 8.40001 9.28003C8.40001 9.45676 8.25674 9.60003 8.08001 9.60003H5.52001C5.34328 9.60003 5.20001 9.45676 5.20001 9.28003Z"
                fill="#A41517"
            />
        </g>
    </SvgIcon>
);

import { Chip } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { AppointmentLabel } from '../../models';

interface ClassTagProps {
    label: string;
}

export const ClassTag = ({ label }: ClassTagProps) => {
    if (label === AppointmentLabel.ClassCancelled) {
        return <Chip color="info" label={trans('class-was-cancelled', {}, 'student-common')} />;
    }
    if (label === AppointmentLabel.ClassMissed) {
        return <Chip color="info" label={trans('you-missed-this-class', {}, 'student-common')} />;
    }
    if (label === AppointmentLabel.InSession) {
        return <Chip color="promotion" label={trans('class-in-session', {}, 'student-common')} />;
    }
    if (label === AppointmentLabel.TeacherFeedbackReady) {
        return (
            <Chip color="success" label={trans('teacher-feedback-ready', {}, 'student-common')} />
        );
    }
    if (label === AppointmentLabel.TeacherFeedbackNotReady) {
        return (
            <Chip
                color="warning"
                label={trans('teacher-feedback-not-ready', {}, 'student-common')}
            />
        );
    }

    return null;
};

import { v4 as uuidV4 } from 'uuid';
import { createAction } from '@lingoda/core';
import type { DialogComponent } from '../constants';
import type { Dialog } from '../models';

/*
TODO: Apply types when components are moved
import type { NoCreditsDialogProps } from 'components/dialogs/NoCreditsDialog';
import type { RolloverSuccessDialogProps } from 'components/dialogs/RolloverSuccessDialog';
import type { StartNowDialogProps } from 'components/dialogs/StartNowDialog';
import type { CancelClassDialogProps } from 'containers/dialogs/CancelClassDialog';
import type { ConfirmBookingDialogProps } from 'containers/dialogs/ConfirmBookingDialog';
import type { DeletePaymentMethodDialogProps } from 'containers/dialogs/DeletePaymentMethodDialog';
import type { ResumeCourseDialogProps } from 'containers/dialogs/ResumeCourseDialog';
import type { UpdatePaymentMethodDialogProps } from 'containers/dialogs/UpdatePaymentMethodDialog';
*/

export interface TempComponentProps {
    onConfirm?: () => void;
    onDismiss?: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface AppLanguageDialogProps extends Omit<TempComponentProps, 'onConfirm'> {
    onConfirm?: (language: string) => void;
}

export interface DialogProps {
    [DialogComponent.GenericInfoMessageDialog]: TempComponentProps;
    [DialogComponent.BookingBlockedByPause]: TempComponentProps;
    [DialogComponent.BookingBlockedBySubsExpiry]: TempComponentProps;
    [DialogComponent.CancelClass]: TempComponentProps;
    [DialogComponent.ClassReview]: TempComponentProps;
    [DialogComponent.ConfirmBooking]: TempComponentProps;
    [DialogComponent.DeletePaymentMethod]: TempComponentProps;
    [DialogComponent.FlashCardTipsDialog]: TempComponentProps;
    [DialogComponent.MobileBookingDialog]: TempComponentProps;
    [DialogComponent.MobileClassFilterDialog]: TempComponentProps;
    [DialogComponent.MobileCreditsPurchaseDialog]: TempComponentProps;
    [DialogComponent.MobileCurriculumFilterDialog]: TempComponentProps;
    [DialogComponent.MobileFlashCardDialog]: TempComponentProps;
    [DialogComponent.MobileModuleSelectDialog]: TempComponentProps;
    [DialogComponent.MobilePreClassChecklistDialog]: TempComponentProps;
    [DialogComponent.MobilePushNotificationReminderPicker]: TempComponentProps;
    [DialogComponent.MobileVocabularyDialog]: TempComponentProps;
    [DialogComponent.NoCredits]: TempComponentProps;
    [DialogComponent.PrivateClass]: TempComponentProps;
    [DialogComponent.PurchaseOnWebsiteDialog]: TempComponentProps;
    [DialogComponent.ResumeCourse]: TempComponentProps;
    [DialogComponent.RolloverSuccessDialog]: TempComponentProps;
    [DialogComponent.SubscribeUnlockDialog]: TempComponentProps;
    [DialogComponent.UpdatePaymentMethod]: TempComponentProps;
    [DialogComponent.SelfLearningLimitAccess]: TempComponentProps;
    [DialogComponent.SetGoalWeeklyClasses]: TempComponentProps;
    [DialogComponent.SetGoalWeeklyWords]: TempComponentProps;
    [DialogComponent.GoalAchievedCelebration]: TempComponentProps;
    [DialogComponent.Achievement]: TempComponentProps;
    [DialogComponent.AppLanguageDialog]: AppLanguageDialogProps;
    [DialogComponent.OnboardingChecklist]: TempComponentProps;
    [DialogComponent.VocabAudio]: TempComponentProps;
    [DialogComponent.YearlyRecapDialog]: TempComponentProps;
    [DialogComponent.ConfirmCalendarDisconnectDialog]: TempComponentProps;
    [DialogComponent.ConnectCalendarDialog]: TempComponentProps;
}

export type ComponentType = keyof DialogProps;

const actionCreator = createAction<Dialog>('dialog/SHOW_DIALOG');

const wrapper = <C extends ComponentType>(component: C, params?: DialogProps[C]) =>
    actionCreator({
        active: true,
        id: uuidV4(),
        component,
        params,
    });

wrapper.toString = () => actionCreator.toString();

export default wrapper;

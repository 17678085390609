import { default as MuiSvgIcon } from '@mui/material/SvgIcon';
import { type ElementType, forwardRef } from 'react';
import type { SvgIconProps as MuiSvgIconProps } from '@mui/material/SvgIcon';

export interface SvgIconProps extends MuiSvgIconProps {
    component?: ElementType;
    htmlColorDefault?: MuiSvgIconProps['htmlColor'];
}

export const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>(
    ({ htmlColorDefault, ...props }, ref) => (
        <MuiSvgIcon
            {...props}
            ref={ref}
            htmlColor={props.htmlColor || (props.color ? undefined : htmlColorDefault)}
        />
    ),
);
SvgIcon.displayName = 'SvgIcon';

import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import { getStartedPath, getStartedStepNamePath } from '@lingoda/router';

const GetStartedDefault = lazy(() => import('../pages/GetStartedDefault'));
const GetStarted = lazy(() => import('../pages/GetStarted'));

export const getStartedRoutes: RouteConfigExtended[] = [
    {
        component: GetStartedDefault,
        path: getStartedPath.raw,
        label: 'GetStarted',
        exact: true,
        config: {
            isPublic: true,
            hideFooter: true,
            logoIsIcon: true,
            disableLogoLink: true,
            hideNavigation: true,
            tab: undefined,
            navigationElevation: 1,
        },
    },
    {
        component: GetStarted,
        path: getStartedStepNamePath.raw,
        label: 'GetStartedStep',
        config: {
            isPublic: true,
            hideFooter: true,
            logoIsIcon: true,
            disableLogoLink: true,
            hideNavigation: true,
            tab: undefined,
            navigationElevation: 1,
        },
    },
];

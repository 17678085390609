import {
    studentCreditsSelector,
    // TODO: Probably needs also to remove from backend
    // studentNotificationsSelector,
    studentPreferencesSelector,
} from '@lingoda/students';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { showToast } from '@lingoda/toasts';
import type { PausedSubscription } from '@lingoda/subscriptions';
import { createDate } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { isNudgeForPlanningEnabled } from '@lingoda/feature-flags';
import { getBookingComboParams, setLastBookingDate } from '../utils';
import type { Store } from 'redux';

export const oneClickBookingEnabled = (store: Store) => {
    const preferences = studentPreferencesSelector(store.getState());

    return preferences?.oneClickBooking;
};

export const checkCredits = (store: Store, classType: string) => {
    const credits = studentCreditsSelector(store.getState());

    if (credits[classType as keyof typeof credits] < 1) {
        store.dispatch(showDialog(DialogComponent.NoCredits, { classType }));
        throw new Error('Not enough credits');
    }
};

export const showBookingBlockedByPauseDialog = (store: Store, subscription: PausedSubscription) => {
    store.dispatch(showDialog(DialogComponent.BookingBlockedByPause, { subscription }));
    throw new Error('Booking during pause');
};

export const showBookClassSuccessNotification = () => {
    const nudgeForPlanning = isNudgeForPlanningEnabled();

    if (!nudgeForPlanning) {
        showToast('success', trans('way-to-go-you-booked-a-class', {}, 'student-notification'));
    } else {
        const bookingCountData = setLastBookingDate(createDate());
        const bookingCount = bookingCountData?.count ?? 1;
        const bookingComboParams = getBookingComboParams(bookingCount);

        showToast({
            variant: 'success',
            title: bookingComboParams.content.title,
            message: bookingComboParams.content.message,
            iconKey: bookingComboParams.icon,
            'data-cy': 'nudge-for-planning-toast',
        });
    }
};

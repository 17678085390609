import { useCallback } from 'react';
import { useRequestTracker } from '@lingoda/core';
import { useDispatch, useToggleState } from '@lingoda/hooks';
import type { Path } from '@lingoda/router';
import { goTo, studentHomePath } from '@lingoda/router';
import { fetchMe } from '@lingoda/auth';
import { setOnboarded } from '../actions';

interface FinishOnboardingOptions {
    redirectPath?: Path;
}

const defaultRedirectPath = studentHomePath();

const useFinishOnboarding = () => {
    const dispatch = useDispatch();
    const [loading, startLoading, finishLoading] = useToggleState();
    const trackRequest = useRequestTracker();

    const finishOnboarding = useCallback(
        (options?: FinishOnboardingOptions) => {
            startLoading();
            void trackRequest(dispatch(setOnboarded(true)))
                .then(() => {
                    dispatch(fetchMe());
                    const redirectPath = options?.redirectPath ?? defaultRedirectPath;
                    goTo(redirectPath);
                })
                .finally(finishLoading);
        },
        [startLoading, trackRequest, dispatch, finishLoading],
    );

    return [loading, finishOnboarding] as const;
};

export default useFinishOnboarding;

import { memo } from 'react';
import { Box, Typography } from '@lingoda/ui';
import type { AppointmentStatus } from '@lingoda/graphql';
import {
    getAppointmentStatusColor,
    getAppointmentStatusIcon,
    getAppointmentStatusText,
} from '../../helpers/bookingClassElement';

interface Props {
    status: AppointmentStatus;
    isUpcoming?: boolean;
    isPast: boolean;
}

const ClassListItemStatusComponent = ({ isUpcoming = false, status, isPast }: Props) => {
    const displayContent = getAppointmentStatusText(status, isPast, isUpcoming);
    const displayIcon = !isPast && isUpcoming ? null : getAppointmentStatusIcon(status);
    const displayColor = getAppointmentStatusColor(status, !isPast && isUpcoming);

    return (
        <Box display="flex" width="100%" color={displayColor} alignItems="center">
            <Typography variant="subtitle2">{displayContent}</Typography>
            <Typography variant="subtitle2" display="flex" alignItems="center">
                {displayIcon}
            </Typography>
        </Box>
    );
};

export const ClassListItemStatus = memo(ClassListItemStatusComponent);

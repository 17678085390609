import { useMemo } from 'react';
import type { AuthTokenStatus } from '@lingoda/graphql';
import { useStudentCalendarsQuery } from '@lingoda/graphql';
import { isAnyCalendarConnected } from '@lingoda/calendar-connection/logic/wasAnyCalendarEverConnected';
import { showApolloErrorToast } from '@lingoda/toasts';

export const useShouldShowConnectYourCalendar = () => {
    const { data } = useStudentCalendarsQuery({
        onError: showApolloErrorToast,
    });

    const { shouldShowConnectYourCalendar } = useMemo(() => {
        const calendars = data?.calendarList || [];

        return {
            shouldShowConnectYourCalendar: shouldShowConnectCalendar(
                calendars.map(({ status }) => status),
            ),
        };
    }, [data?.calendarList]);

    return {
        shouldShowConnectYourCalendar,
    };
};

const shouldShowConnectCalendar = (calendarStatuses: (AuthTokenStatus | null)[]) => {
    return !!calendarStatuses.length && !isAnyCalendarConnected(calendarStatuses);
};

import SocialIcon from '../../icons/SocialIcon';
import { styled } from '../../theme';
import { Button, type ButtonProps } from '../Button';
import { vendorConfigMap } from './config';
import type { SocialVendors } from '../../theme/models';
import type { PropsWithChildren } from 'react';
import type { VendorConfig } from './config';

export interface SocialButtonProps extends ExposedButtonProps {
    vendor: SocialVendors;
    iconPosition?: IconPosition;
}

type IconPosition = 'start' | 'content';
type ExposedButtonProps = Pick<
    ButtonProps,
    'onClick' | 'href' | 'target' | 'size' | 'disabled' | 'fullWidth'
>;

const SocialButton = ({
    vendor,
    iconPosition = 'start',
    ...props
}: PropsWithChildren<SocialButtonProps>) => {
    const vendorConfig = vendorConfigMap[vendor];

    const isIconButton = iconPosition === 'content';
    const iconProps = {
        isIconButton,
        [isIconButton ? 'children' : 'startIcon']: <SocialIcon vendor={vendor} color="inherit" />,
    };

    return (
        <StyledButton
            {...props}
            config={vendorConfig}
            variant={vendorConfig.variant}
            {...iconProps}
        />
    );
};

interface StyledButtonProps {
    config: VendorConfig;
    isIconButton?: boolean;
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'config' && prop !== 'isIconButton',
})<StyledButtonProps>(({ config, isIconButton, size, theme }) => ({
    ...(config.variant === 'contained' && {
        borderColor: 'transparent',
        backgroundColor: config.colors.main,
        '&:hover': {
            backgroundColor: config.colors.hover,
        },
    }),
    ...(config.variant === 'outlined' && {
        borderColor: config.colors.main,
        color: theme.palette.text.primary,
        '&:hover, &:focus, &:active': {
            borderColor: config.colors.main,
            backgroundColor: config.colors.hover,
        },
    }),
    ...(isIconButton && {
        minWidth: 'auto',
        // Substract border width to stay consistent with ButtonsBase padding value
        padding:
            size === 'small'
                ? `calc(${theme.spacing(1.25)} - 1px)`
                : `calc(${theme.spacing(2)} - 1px)`,
    }),
}));

export default SocialButton;

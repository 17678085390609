import { useCallback, useState } from 'react';

export interface StepperHookOptions {
    steps: number;
    initialStep?: number;
}

export const useStepper = ({ steps, initialStep = 0 }: StepperHookOptions) => {
    const [currentIndex, setCurrentIndex] = useState<number>(initialStep);

    const next = useCallback(() => {
        setCurrentIndex((currentIndex) => Math.min(currentIndex + 1, steps));
    }, [steps]);

    const previous = useCallback(() => {
        setCurrentIndex((currentIndex) => Math.max(currentIndex - 1, 0));
    }, []);

    const goTo = useCallback(
        (index: number) => {
            const indexNormalized = Math.min(Math.max(0, index), steps);
            setCurrentIndex(indexNormalized);
        },
        [steps],
    );

    return {
        currentStep: currentIndex,
        isFirstStep: currentIndex === 0,
        isLastStep: currentIndex === steps - 1,
        isFinished: currentIndex >= steps,
        next,
        previous,
        goTo,
    };
};

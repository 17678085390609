import { getLang } from '@lingoda/env';

export * from './getBaseUri';
export * from './getRawPath';
export * from './getUrl';
export * from './getPath';
export * from './getNewLangLocation';
export * from './getQueryParam';
export * from './getQueryParams';
export * from './getCurrentPath';
export * from './buildUrl';
export * from './buildQueryString';
export * from './isAbsolute';
export * from './getSchema';
export * from './getSchemaHost';
export * from './getHost';
export * from './resolveProtocol';
export * from './extractRelativePath';
export * from './getTopLevelDomain';
export * from './isInTeacherDomain';
export * from './getAppByDomain';
export { toMarketingUrl } from './replaceDomain';
export * from './queryParamsDecoder';
export * from './isLocalHost';

export enum SupportUrlType {
    Home = 'home',
    Apple = 'apple',
    Facebook = 'facebook',
    Class = 'class',
    CancelClass = 'cancelClass',
    Recording = 'recording',
    TeacherLogClasses = 'teacherLogClasses',
    TeacherCancelClass = 'teacherCancelClass',
    TechnicalRequirements = 'technicalRequirements',
}

const elevioBase = 'https://lingoda-students.elevio.help';
const elevioTeacherBase = 'https://lingodateachers.elevio.help';

export const getSupportUrl = (type: SupportUrlType) =>
    ({
        home: elevioBase,
        apple: `${elevioBase}/${getLang()}/articles/256`,
        facebook: `${elevioBase}/${getLang()}/articles/247`,
        class: `${elevioBase}/${getLang()}/articles/157`,
        cancelClass: `${elevioBase}/${getLang()}/articles/230`,
        recording: `${elevioBase}/${getLang()}/articles/153-are-the-chat-notes-from-my-class-saved`,
        technicalRequirements: `${elevioBase}/${getLang()}/articles/175`,
        teacherLogClasses: `${elevioTeacherBase}/${getLang()}/articles/70-how-to-log-your-classes`,
        teacherCancelClass: `${elevioTeacherBase}/${getLang()}/articles/60-cancelling-classes`,
    })[type];

import { type ChecklistItem as ChecklistItemType } from '@lingoda/graphql';
import { Stack } from '@lingoda/ui';
import { ChecklistItem } from './ChecklistItem';

interface Props {
    checklist: ReadonlyArray<ChecklistItemType>;
}

export const Checklist = ({ checklist }: Props) => {
    const currentProgress = checklist.findIndex((item) => !item.isCompleted);

    return (
        <Stack spacing={2}>
            {checklist.map((item, index) => {
                return (
                    <ChecklistItem
                        key={index}
                        {...item}
                        isActive={currentProgress === index}
                        value={index + 1}
                    />
                );
            })}
        </Stack>
    );
};

import { LearningTeamStatus, useStudentLearningTeamStatusQuery } from '@lingoda/graphql';
import { useIsLearningTeams } from './useIsLearningTeams';

export const useIsLearningTeamStatusReady = () => {
    const isLearningTeams = useIsLearningTeams();

    const { data } = useStudentLearningTeamStatusQuery({
        fetchPolicy: 'cache-and-network',
        skip: !isLearningTeams,
    });

    return data?.studentLearningTeamInfo?.status === LearningTeamStatus.Ready;
};

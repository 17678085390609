import { useEffect } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { studentPreferencesSelector } from '@lingoda/students';

export const SetGoalWeeklyWordsDialogTrigger = () => {
    const dispatch = useDispatch();

    const studentPreferences = useSelector(studentPreferencesSelector);
    const isGoalSet = !!studentPreferences?.classWeeklyWordsGoal;
    const isIntroCompleted = !!studentPreferences?.quizIntroSeen;

    useEffect(() => {
        if (isIntroCompleted && !isGoalSet) {
            dispatch(showDialog(DialogComponent.SetGoalWeeklyWords));
        }
    }, [dispatch, isIntroCompleted, isGoalSet]);

    return null;
};

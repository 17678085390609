import { DateTimeSlotField, useFormContext } from '@lingoda/forms';
import { type TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { Button, Stack } from '@lingoda/ui';
import { type FormValues } from './formSchema';

interface IndividualClassBookingFormLayoutProps {
    minDate: Date;
    maxDate?: Date;
    timeFormat: TimeFormat;
    disabled?: boolean;
    submitDisabled?: boolean;
    loading?: boolean;
}

export const IndividualClassBookingFormLayout = ({
    minDate,
    maxDate,
    timeFormat,
    loading,
    disabled,
    submitDisabled,
}: IndividualClassBookingFormLayoutProps) => {
    const { isValid } = useFormContext<FormValues>();

    return (
        <Stack spacing={2}>
            <DateTimeSlotField
                name="startDate"
                minDate={minDate}
                maxDate={maxDate}
                timeFormat={timeFormat}
                loading={loading}
                disabled={disabled}
                dateLabel={trans('select-date', {}, 'student-common')}
                dateMobileConfirmLabel={trans('btn-continue', {}, 'student-common')}
                timeLabel={trans('select-time', {}, 'student-common')}
                timeMobileConfirmLabel={trans('btn-continue', {}, 'student-common')}
            />
            <Button
                color="primary"
                variant="contained"
                type="submit"
                fullWidth
                disabled={!isValid || disabled || submitDisabled}
                loading={loading}
            >
                {trans('btn-book-class', {}, 'student-common')}
            </Button>
        </Stack>
    );
};

import { useEffect, useMemo, useRef } from 'react';
import { actionSelection, useUiEvent } from '@lingoda/analytics';
import {
    type TimeFormat,
    formatDate,
    formatTime,
    isAfter,
    isBefore,
    set,
    utcMinutesOffset,
} from '@lingoda/dates';
import { useResolution } from '../../hooks';
import { DateInput } from '../DateInput';
import { TimeSlotPicker, getDisabledTimeSlots } from '../TimeSlotPicker';

export interface DateTimeSlotPickerProps {
    dateLabel?: string;
    dateMobileConfirmLabel?: string;
    timeLabel?: string;
    timeMobileConfirmLabel?: string;
    minDate: Date;
    maxDate?: Date;
    name: string;
    onChange: (value: Date) => void;
    value: Date;
    disabled?: boolean;
    loading?: boolean;
    dateFormat?: string;
    timeFormat: TimeFormat;
    children: (renderProps: RenderProps) => React.ReactElement;
    popperMaxWidth?: number;
}

interface RenderProps {
    dateField: React.ReactElement;
    timeField: React.ReactElement;
}

export const DateTimeSlotPicker = ({
    dateLabel,
    dateMobileConfirmLabel,
    timeLabel,
    timeMobileConfirmLabel,
    maxDate,
    minDate,
    name,
    onChange,
    value,
    dateFormat = 'PP',
    timeFormat,
    disabled,
    loading,
    children,
    popperMaxWidth,
}: DateTimeSlotPickerProps) => {
    const addEvent = useUiEvent();
    const timeInputRef = useRef<HTMLInputElement>(null);
    const isMobile = useResolution('phone');

    const disabledTimeSlots = useMemo(() => {
        return getDisabledTimeSlots(value, minDate, maxDate);
    }, [value, minDate, maxDate]);

    const handleChangeDateInput = (newDate: Date) => {
        const nextDate = set(
            { hours: value.getHours(), minutes: value.getMinutes(), seconds: 0, milliseconds: 0 },
            newDate,
        );
        void addEvent(actionSelection('Date'), { value: formatDate(nextDate) });
        onChange(nextDate);

        if (isMobile) {
            timeInputRef.current?.click();
        }
    };

    const handleChangeTimePicker = (hour: number) => {
        const newDate = set(
            { hours: hour, minutes: utcMinutesOffset(), seconds: 0, milliseconds: 0 },
            value,
        );
        void addEvent(actionSelection('Time'), { value: formatTime(newDate) });
        onChange(newDate);
    };

    useEffect(() => {
        if (minDate && (!value || isAfter(minDate, value))) {
            onChange(minDate);
        }

        if (maxDate && (!value || isBefore(maxDate, value)) && !isBefore(maxDate, minDate)) {
            onChange(maxDate);
        }
    }, [onChange, maxDate, minDate, value]);

    const dateField = (
        <DateInput
            name={name}
            label={dateLabel}
            format={dateFormat}
            selectedDate={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChangeDateInput}
            disabled={disabled}
            popperMaxWidth={popperMaxWidth}
            loading={loading}
            mobileTitle={dateLabel}
            mobileConfirmLabel={dateMobileConfirmLabel}
        />
    );

    const timeField = (
        <TimeSlotPicker
            name={`${name}-time`}
            label={timeLabel}
            value={value.getHours()}
            onChange={handleChangeTimePicker}
            disabled={disabled}
            disabledSlots={disabledTimeSlots}
            timeFormat={timeFormat}
            popperMaxWidth={popperMaxWidth}
            loading={loading}
            mobileTitle={timeLabel}
            mobileConfirmLabel={timeMobileConfirmLabel}
            ref={timeInputRef}
        />
    );

    return children({ dateField, timeField });
};

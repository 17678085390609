import jwtDecode from 'jwt-decode';
import memoize from 'lodash/memoize';
import type { FetchTokenResponse } from '@lingoda/api';
import { refreshToken as refreshTokenApi } from '@lingoda/api';
import { addMinutes, isBefore } from '@lingoda/dates';
import { httpGet } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import getRefreshToken from './getRefreshToken';

interface Token {
    exp: number;
    iat: number;
    sub: number;
    username: string;
}

let response: Promise<FetchTokenResponse> | undefined;
let fetching = false;

export const refreshToken = () => {
    if (!fetching || !response) {
        fetching = true;
        response = new Promise((resolve, reject) => {
            void (async () => {
                try {
                    const token = await getRefreshToken();
                    const result = await refreshTokenApi(token);

                    resolve(result);
                } catch (error) {
                    reject(error);
                } finally {
                    fetching = false;
                }
            })();
        });
    }

    return response;
};

export const resetRefreshTokenMechanism = () => {
    response = undefined;
    fetching = false;
};

export const decodeToken = memoize((token: string) => jwtDecode<Token>(token));

export const isTokenExpired = (token: string) => {
    const { exp } = decodeToken(token);

    return isBefore(new Date(exp * 1000), new Date());
};

export const getTokenExpiry = (token: string) => {
    const { exp } = decodeToken(token);

    return new Date(exp * 1000);
};

export const shouldRefreshToken = (expiry: Date) => {
    return !isBefore(new Date(), addMinutes(expiry, -1));
};

export const removeRefreshToken = async () => httpGet(getUrl('user_security_jwt_logout'));

import { memo } from 'react';
import { StudentStatus } from '@lingoda/graphql';
import type {
    CourseOverviewLearningUnitFragment,
    CourseOverviewLessonFragment,
} from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { RouterLink, studentLessonDetailsPath } from '@lingoda/router';
import {
    Chip,
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import { LessonStatusTooltip } from './LessonStatusTooltip';
import Progress from './Progress';
import VideoCall from './VideoCall.svg?component';
import { ExercisesListItem } from './ExercisesListItem';

interface Props {
    learningUnit: CourseOverviewLearningUnitFragment;
    completed: number;
    isOpen: boolean;
    lessons: ReadonlyArray<CourseOverviewLessonFragment>;
    onClick: () => void;
    total: number;
    moduleId: number;
}

const LearningUnitContentComponent = ({
    learningUnit,
    completed,
    isOpen,
    lessons,
    onClick,
    total,
    moduleId,
}: Props) => (
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
            <ExercisesListItem learningUnit={learningUnit} moduleId={moduleId} />
            <ListItem>
                <ListItemIcon>
                    <VideoCall color={colorsPalette.blackSolid[100]} />
                </ListItemIcon>
                <ListItemText disableTypography>
                    <Typography variant="body1">
                        {trans('live-lessons', {}, 'student-common')}
                    </Typography>
                </ListItemText>
                <Progress completed={completed} total={total} />
            </ListItem>
            {lessons.map(
                (lesson) =>
                    lesson && (
                        <ListItemButton
                            key={lesson.id}
                            component={RouterLink}
                            onClick={onClick}
                            color="inherit"
                            to={studentLessonDetailsPath({ lessonId: lesson.id })}
                            sx={{
                                backgroundColor:
                                    lesson.studentStatus === StudentStatus.Skip
                                        ? colorsPalette.blackAlpha[4]
                                        : '',
                            }}
                        >
                            <ListItemIcon>
                                <LessonStatusTooltip lesson={lesson} />
                            </ListItemIcon>
                            <ListItemText primary={lesson.title} />
                            <Chip color="primary" label={lesson.label} />
                        </ListItemButton>
                    ),
            )}
        </List>
        <Divider />
    </Collapse>
);

export const LearningUnitContent = memo(LearningUnitContentComponent);

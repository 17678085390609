import { handleActionsImmutably } from '@lingoda/core';
import { addProducts } from '../actions';
import { productFactory } from '../models';
import type { Product } from '../models';

export type State = Readonly<GenericObject<Product>>;

type ProductsAction = ReturnType<typeof addProducts>;

export default handleActionsImmutably<State, ProductsAction['payload']>(
    {
        [`${addProducts}`]: (state, { payload }: ProductsAction) =>
            payload.reduce((result, product) => {
                result[product.id] = productFactory(product);

                return result;
            }, state),
    },
    {},
);

import { memo } from 'react';
import { trans } from '@lingoda/i18n';
import { Box, Button, CardActions, Grid, IconButton } from '@lingoda/ui';
import { ExpandLess, ExpandMore } from '@lingoda/ui/icons/lingodaIcons';

interface Props {
    isOpen: boolean;
    showSkip: boolean;
    onSkip: () => void;
    isDisabled: boolean;
    showRetake: boolean;
    onRetake: () => void;
    onExpand: () => void;
    showController: boolean;
}

const LearningUnitActionsComponent = ({
    showController,
    showRetake,
    showSkip,
    isOpen,
    isDisabled,
    onExpand,
    onRetake,
    onSkip,
}: Props) => (
    <CardActions disableSpacing>
        <Grid container alignItems="center" justify="space-between" alignContent="center">
            <Grid item xs={5} />

            {showController && (
                <Grid item xs={2}>
                    <Box textAlign="center">
                        <IconButton
                            onClick={onExpand}
                            aria-label={isOpen ? 'Expand less' : 'Expand more'}
                        >
                            {isOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                </Grid>
            )}

            <Grid item xs={5}>
                <Box textAlign="right">
                    {showSkip && (
                        <Button color="primary" disabled={isDisabled} onClick={onSkip}>
                            {trans('skip-chapter', {}, 'student-common')}
                        </Button>
                    )}

                    {showRetake && (
                        <Button color="primary" disabled={isDisabled} onClick={onRetake}>
                            {trans('retake-chapter', {}, 'student-common')}
                        </Button>
                    )}
                </Box>
            </Grid>
        </Grid>
    </CardActions>
);

export const LearningUnitActions = memo(LearningUnitActionsComponent);

import { isNoCreditCardTrialEnabled } from '@lingoda/feature-flags';
import { useUserSubscriptionsQuery } from '@lingoda/graphql';

// TODO: Ones experiment success and removed, rename it to `useIsInTrial`
export const useBookWithoutPaymentAvailable = () => {
    const { data, loading } = useUserSubscriptionsQuery();

    const isInTrial = !!data?.subscriptions?.currentSubscription?.isInTrial;

    return {
        loading,
        bookWithoutPaymentAvailable: isInTrial && isNoCreditCardTrialEnabled(),
    };
};

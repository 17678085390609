import { type ReactNode } from 'react';
import { Box, type BoxProps } from '../../layout/Box';
import { Typography } from '../../layout/Typography';
import { CheckCircle } from '../../icons/lingodaIcons';
import { colorsPalette } from '../../theme';

export interface SlidePopupSuccessLayoutProps {
    sx?: BoxProps['sx'];
    message: ReactNode;
}

export const SlidePopupSuccessLayout = ({ sx, message }: SlidePopupSuccessLayoutProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ...sx,
            }}
        >
            <CheckCircle htmlColor={colorsPalette.positiveCustom.extradark} />
            <Typography variant="subtitle1" mt={1}>
                {message}
            </Typography>
        </Box>
    );
};

import { trans } from '@lingoda/i18n';
import { Box, Button, Img, Paper, Typography } from '@lingoda/ui';
import { ArrowRight } from '@lingoda/ui/icons/lingodaIcons';
import { colorsPalette } from '@lingoda/ui/theme';
import type { ReactNode } from 'react';

export interface ConversionCardProps {
    label?: string;
    header: string;
    body: ReactNode;
    image: string;
    buttonLabel?: string;
    onCtaClick: () => void;
    stepper?: ReactNode;
}

const ConversionCard = ({
    label,
    header,
    body,
    buttonLabel,
    image,
    onCtaClick: onNextClick,
    stepper,
}: ConversionCardProps) => (
    <Paper elevation={1}>
        <Box p={[2, 4]} display="flex" alignItems="center" flexDirection="column" gap={2}>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                maxWidth="calc(65% + 32px)"
            >
                {label && (
                    <Typography
                        variant="subtitle2"
                        htmlColor={colorsPalette.primarySolid[100]}
                        gutterBottom
                    >
                        {label}
                    </Typography>
                )}

                <Typography variant="h2" textAlign="center" gutterBottom>
                    {header}
                </Typography>

                <Typography
                    component={typeof body === 'string' ? 'p' : 'div'}
                    variant="body2"
                    textAlign="center"
                    htmlColor={colorsPalette.blackAlpha[70]}
                >
                    {body}
                </Typography>
            </Box>

            {stepper && <Box>{stepper}</Box>}

            <Box my={1}>
                <Paper elevation={2}>
                    <Img src={image} />
                </Paper>
            </Box>

            <Box mb={1}>
                <Button color="primary" variant="contained" onClick={onNextClick}>
                    {buttonLabel || (
                        <Box display="inline-flex" alignItems="center" gap={0.5}>
                            {trans('next', {}, 'student-common')}
                            <ArrowRight />
                        </Box>
                    )}
                </Button>
            </Box>
        </Box>
    </Paper>
);

export default ConversionCard;

import { type FormMessages } from '@lingoda/utils';
import { type FormValues } from '@lingoda/forms';
import { actionClick, getMarketingData } from '@lingoda/analytics';
import { useUiEvent } from '@lingoda/ui';
import { createStudent } from '@lingoda/auth';
import { goTo, redirect, studentHomePath, useRedirectPath } from '@lingoda/router';
import { showToast } from '@lingoda/toasts';
import { useDispatch } from '@lingoda/hooks';
import { getCaptchaErrorMessage, useGoogleRecaptchaV3 } from '@lingoda/recaptcha';
import { useRequestTracker } from '@lingoda/core';
import { type RegisterQueryParams } from '../types';

export const useRegisterForm = ({ registerParams }: { registerParams: RegisterQueryParams }) => {
    const dispatch = useDispatch();
    const cjData = getMarketingData();
    const trackRequest = useRequestTracker();
    const addEvent = useUiEvent();

    const [redirectPath] = useRedirectPath();

    const { getToken, loading } = useGoogleRecaptchaV3();

    const handleSubmit = async ({
        email,
        fullName,
        privacy,
        marketing,
        section,
        curriculumId,
        password,
    }: FormValues) => {
        if (!loading) {
            let token;
            try {
                if (getToken) {
                    token = await getToken();
                } else {
                    const message = getCaptchaErrorMessage();
                    showToast('error', message);
                }
            } catch (error: unknown) {
                const message = getCaptchaErrorMessage();
                showToast('error', message);

                return Promise.reject({ token: message });
            }

            const consent = {
                marketing_communication: marketing,
                privacy_policy: privacy,
            };
            const {
                module,
                timezone,
                timeFormat,
                weekStart,
                getAmbassadorShortCode,
                getAmbassadorCampaignId,
            } = registerParams;
            const data = {
                consent,
                email,
                fullName,
                password,
                section,
                curriculum: curriculumId,
                module,
                timezone,
                timeFormat,
                weekStart,
                cj: cjData,
                getAmbassadorShortCode,
                getAmbassadorCampaignId,
                token,
            };

            void addEvent(actionClick('SignUpButton'));

            return trackRequest(dispatch(createStudent(data)))
                .then(() => {
                    redirectPath ? redirect(redirectPath) : goTo(studentHomePath());
                })
                .catch((errors: FormMessages) => {
                    if (errors.token) {
                        showToast('error', errors.token);
                    }
                    throw errors;
                });
        }
    };

    return {
        getToken,
        loading,
        handleSubmit,
    };
};

import { createSelector } from 'reselect';
import { isAuthStatusUnauthenticatedSelector } from '@lingoda/auth';
import { currentRoutePathSelector } from '@lingoda/router';
import { isNotLegacyModule } from '../utils';
import expandedModulesSelector from './expandedModules';
import studentModuleSelector from './studentModule';

const defaultModuleNameSelector = (_state: unknown, props: string | undefined) => props;

type ModuleNameSelector = typeof defaultModuleNameSelector;

// this is to ensure that we will only be displaying the current curricula modules in the course
// overview page
export const currentModulesSelector = createSelector(expandedModulesSelector, (modules) => {
    const currentModules = modules.filter(
        (item) => item.totalClasses > 0 && isNotLegacyModule(item),
    );

    // Learning materials page doesnt have totalClasses
    return currentModules.length ? currentModules : modules;
});

export const createModuleSelector = (moduleNameSelector: ModuleNameSelector) =>
    createSelector(
        currentModulesSelector,
        currentRoutePathSelector,
        moduleNameSelector,
        studentModuleSelector,
        isAuthStatusUnauthenticatedSelector,
        (modules, currentPath, moduleName, studentModule, isUnauthenticated) => {
            if (!studentModule && !moduleName && !currentPath) {
                return modules[0];
            }

            if (!moduleName && (!currentPath || !currentPath.params.module) && studentModule) {
                return studentModule;
            }

            if (!moduleName && (!currentPath || !currentPath.params.module) && isUnauthenticated) {
                return modules[0];
            }

            // Old modules are stored with different key. Try to choose the one with more information
            return modules
                .sort((a, b) => Object.keys(b).length - Object.keys(a).length)
                .find((module) =>
                    moduleName
                        ? module.name === moduleName
                        : module.name === currentPath.params.module,
                );
        },
    );

export const moduleSelector = createModuleSelector(defaultModuleNameSelector);

const createModuleIndexSelector = (moduleNameSelector: ModuleNameSelector) =>
    createSelector(
        currentModulesSelector,
        createModuleSelector(moduleNameSelector),
        (modules, module) => modules.findIndex((mod) => mod.id === (module && module.id)),
    );

export const createPrevModuleSelector = (moduleNameSelector: ModuleNameSelector) =>
    createSelector(
        currentModulesSelector,
        createModuleIndexSelector(moduleNameSelector),
        (modules, moduleIndex) => (moduleIndex > 0 ? modules[moduleIndex - 1] : undefined),
    );

export const prevModuleSelector = createPrevModuleSelector(defaultModuleNameSelector);

export const createNextModuleSelector = (moduleNameSelector: ModuleNameSelector) =>
    createSelector(
        currentModulesSelector,
        createModuleIndexSelector(moduleNameSelector),
        (modules, index) => (index >= modules.length ? undefined : modules[index + 1]),
    );

export const nextModuleSelector = createNextModuleSelector(defaultModuleNameSelector);

/* eslint-disable react-hooks/rules-of-hooks */
import { matchPathMemoized } from '../matchPath';
import useLocation from './useLocation';
import type { Arg } from '../matchPath';

const useRouteMatcher = (paths: Arg | Arg[]) => {
    try {
        const { pathname } = useLocation();

        return matchPathMemoized(paths, pathname);
    } catch {
        return false;
    }
};

export default useRouteMatcher;

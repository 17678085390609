import { useEffect } from 'react';
import { NetworkStatus, usePersonalizedFeedbackQuestionsQuery } from '@lingoda/graphql';
import { showPopup } from '@lingoda/popups';

export const PersonalFeedbackSatisfactionPopupTrigger = () => {
    const { data, networkStatus } = usePersonalizedFeedbackQuestionsQuery();

    const isLoaded = networkStatus === NetworkStatus.ready;

    const { personalizedFeedbackQuestions: config } = data || {};
    const isEligible = config && config.length > 0;

    useEffect(() => {
        if (isLoaded && isEligible) {
            showPopup('personalFeedbackSatisfaction');
        }
    }, [isLoaded, isEligible]);

    return null;
};

import { useCallback, useEffect, useState } from 'react';
import { useUiEvent } from '@lingoda/ui';
import { actionFilter } from '@lingoda/analytics';
import { useSelector } from '@lingoda/hooks';
import type { ClassType } from '@lingoda/graphql';
import {
    type BookingFilter,
    bookingFilterSelector,
    defaultBookingFilterSelector,
    getBaseFilter,
    getValidBookingFilter,
} from '@lingoda/booking';

export const useBookingOverlayFilter = (
    moduleId?: number,
    lessonId?: number,
    classType?: ClassType,
) => {
    const addEvent = useUiEvent();
    const bookingFilter = useSelector(bookingFilterSelector);

    // Here is a copy of filters to avoid tight coupling with booking filters.
    // All changes in this will change only this instance as well, so not affect booking filters and local storage.
    const [filter, setFilter] = useState<BookingFilter>(() => ({
        ...getBaseFilter(bookingFilter),
        modules: moduleId ? [moduleId] : [],
        lessons: lessonId ? [lessonId] : [],
        ...(classType ? { classType } : {}),
    }));

    useEffect(() => {
        setFilter((previousFilter) => ({
            ...previousFilter,
            modules: moduleId ? [moduleId] : [],
            lessons: lessonId ? [lessonId] : [],
            ...(classType ? { classType } : {}),
        }));
    }, [classType, lessonId, moduleId]);

    const defaultBookingFilter = useSelector(defaultBookingFilterSelector);

    const handleChange = useCallback((newFilter: Partial<BookingFilter>) => {
        setFilter((previousFilter) => {
            const nextFilter = getValidBookingFilter({ ...previousFilter, ...newFilter });

            return nextFilter;
        });
    }, []);

    const handleReset = useCallback(() => {
        void addEvent(actionFilter('Reset'));
        setFilter({
            ...filter,
            ...getBaseFilter(defaultBookingFilter),
            ...(classType ? { classType } : {}),
        });
    }, [addEvent, filter, defaultBookingFilter, classType]);

    return { filter, handleChange, handleReset };
};

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const ClockIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 16 16" fontSize="small" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 3.75a.75.75 0 0 1 .75.75v2.75h1.75a.75.75 0 0 1 0 1.5H7.25V4.5A.75.75 0 0 1 8 3.75Zm0 9.75a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11ZM8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z"
        />
    </SvgIcon>
);

export const ClockIcon24 = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 24 24" width={24} height={24} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4.25a.75.75 0 0 1 .75.75v6.25H17a.75.75 0 0 1 0 1.5h-5.75V5a.75.75 0 0 1 .75-.75Zm0 17.25a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19Zm0 1.5c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11Z"
        />
    </SvgIcon>
);

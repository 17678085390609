import { createSelector } from 'reselect';
import { getSectionSelectorByName } from '@lingoda/sections';
import { getCurrentPath } from '@lingoda/urls';
import { studentHomePath } from '../paths';
import resolvePath from '../resolvePath';
import { type RouteState } from './models';

export const routeSelector = (state: GlobalState): RouteState => state.route;

export const currentRoutePathSelector = createSelector(
    routeSelector,
    (route) => route.currentRoute,
);

export const currentRouteSectionSelector = createSelector(
    routeSelector,
    (route) => route.currentSection,
);

export const currentRouteSectionCurriculumSlugSelector = createSelector(
    currentRoutePathSelector,
    currentRouteSectionSelector,
    (currentRoute, currentSection) => ({
        sectionName: currentSection,
        curriculumSlug: currentRoute.params?.curriculum,
    }),
);

export const currentRouteSectionIdSelector = createSelector(
    getSectionSelectorByName(currentRouteSectionSelector),
    (section) => (section ? section.id : undefined),
);

const routes = [resolvePath(studentHomePath())];

// this might seem like over-engineering, but it's prepwork and allows me to have proper
// selector signature
export const isHomePageSelector = createSelector(
    () => getCurrentPath(),
    (path) => routes.includes(path),
);

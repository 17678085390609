import { useEffect } from 'react';
import { useAudioContext } from '@lingoda/audio';
import { type Audio } from '../types';

interface ChangeAudioEffectProps {
    currentItemAudio: Audio;
    isNextCardLoading: boolean;
    defaultFace: 'translation' | 'target';
}

export const ChangeAudioEffect = ({
    currentItemAudio,
    defaultFace,
    isNextCardLoading,
}: ChangeAudioEffectProps) => {
    const { play, stop } = useAudioContext();

    useEffect(() => {
        if (isNextCardLoading) {
            stop();
        }
    }, [isNextCardLoading, stop]);

    useEffect(() => {
        if (!isNextCardLoading) {
            const audioUrl = currentItemAudio?.[defaultFace];

            if (audioUrl) {
                play(audioUrl).catch(console.error);
            }
        }
    }, [currentItemAudio, isNextCardLoading, defaultFace, play]);

    return null;
};

import { Box, type BoxProps } from '@lingoda/ui';
import { LearningMaterialLink } from '../../access';

type ExerciseListItemLinkProps = BoxProps<typeof LearningMaterialLink>;

export const ExerciseListItemLink = ({ sx, ...props }: ExerciseListItemLinkProps) => {
    return (
        <Box
            sx={{ display: 'block', color: 'inherit', textDecoration: 'none', ...sx }}
            component={LearningMaterialLink}
            {...props}
        />
    );
};

import { createSelector } from 'reselect';
import { STORE_KEY } from './constants';
import type { Teacher, TeacherId } from './models';

export type TeachersSelectorResult = ReadonlyArray<Teacher>;

export const teacherStateSelector = (state: GlobalState) => state[STORE_KEY];

export const teachersSelector = createSelector(
    teacherStateSelector,
    (teachers: Teacher[]): TeachersSelectorResult => Object.values(teachers),
);

export const teacherByIdSelector = (
    selector: (state: unknown, props: unknown) => TeacherId | undefined,
) =>
    createSelector(teachersSelector, selector, (teachers, id) =>
        id ? teachers.find((teacher) => teacher.id === id) : undefined,
    );

import { getCLS, getFID, getLCP } from 'web-vitals';
import { gtmAddEvent } from './gtm';
import type { Metric } from 'web-vitals';

function sendToTagManager({ name, delta, value, id }: Metric) {
    gtmAddEvent('coreWebVitals', {
        webVitalsMeasurements: {
            name,
            id,
            value,
            delta,
            valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
            deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
        },
    });
}

export default function () {
    getCLS(sendToTagManager);
    getFID(sendToTagManager);
    getLCP(sendToTagManager);
}

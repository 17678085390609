import { ShortcutButton } from '@lingoda/quiz-ui';
import { shortcutControlClasses } from '@lingoda/quiz-ui/ShortcutControl';
import { colorsPalette, styled } from '@lingoda/ui';

export const CheckButton = styled(ShortcutButton)({
    backgroundColor: colorsPalette.positiveAlpha[70],
    color: 'white',

    [`.${shortcutControlClasses.badge}`]: {
        color: colorsPalette.positiveCustom.dark,
    },

    '&:hover': {
        backgroundColor: colorsPalette.positiveSolid[100],
    },
});

import { Typography } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import type { ToggleButtonProps } from '@lingoda/ui/buttons/ToggleButton';
import ToggleButton from '@lingoda/ui/buttons/ToggleButton';
import { format } from '@lingoda/dates';

const ToggleButtonStyled = styled(ToggleButton)(({ theme }) => ({
    width: 64,
    height: 64,
    borderRadius: 0,
    margin: theme.spacing(1),
    color: colorsPalette.blackSolid[100],
    borderColor: colorsPalette.blackAlpha[8],
    '&:hover:not(.Mui-selected)': {
        color: colorsPalette.blackSolid[100],
        background: `${colorsPalette.blackAlpha[8]} !important`,
        borderColor: `rgba(5, 31, 78, 0.08) !important`,
    },
    flexDirection: 'column',
    '&.Mui-selected': {
        '&:disabled': {
            color: colorsPalette.blackAlpha[20],
            background: colorsPalette.blackAlpha[4],
            borderColor: `${colorsPalette.blackSolid[100]} !important`,
        },
    },
}));

interface Props extends ToggleButtonProps {
    date: Date;
}

export const CalendarDay = ({ date, ...props }: Props) => (
    <ToggleButtonStyled {...props}>
        <Typography variant="h6" component="span">
            {format('d', date)}
        </Typography>
        <Typography variant="subtitle2" textTransform="uppercase" component="span">
            {format('E', date)}
        </Typography>
    </ToggleButtonStyled>
);

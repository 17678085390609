import { memo, useEffect } from 'react';
import { Box, TrackingContext } from '@lingoda/ui';
import { useDispatch } from '@lingoda/hooks';
import { type BookingFilter, type BookingView, getLeadTime } from '@lingoda/booking';
import { SuggestedClasses } from '../SuggestedClasses';
import { AvailableClasses } from '../AvailableClasses';
import { useBookingSearchResults } from './useBookingSearchResults';

interface Props {
    isEmbedded?: boolean;
    bookingFilter: BookingFilter;
    bookingView: BookingView;
    stickyFilterHeight?: number;
    bgColor?: string;
    onBookingFilterChange: (newFilter: Partial<BookingFilter>) => void;
}

const BookingSearchResultsComponent = ({
    isEmbedded,
    bookingFilter,
    bookingView,
    stickyFilterHeight,
    bgColor,
    onBookingFilterChange,
}: Props) => {
    const dispatch = useDispatch();

    const {
        fetchMoreClasses,
        clearClassesCache,
        availableClasses,
        loading,
        suggestedClasses,
        nextAvailableDate,
        debouncedFilter,
        handleOnAvailableClassBooked,
        handleSuggestedClassBooked,
    } = useBookingSearchResults({
        isEmbedded,
        bookingFilter,
        onBookingFilterChange,
    });

    useEffect(() => {
        dispatch(getLeadTime());

        return () => clearClassesCache();
    }, [dispatch, clearClassesCache]);

    return (
        <Box flex="1 1 auto">
            <TrackingContext section="Tile: Available class">
                <AvailableClasses
                    isEmbedded={isEmbedded}
                    bookingFilter={debouncedFilter}
                    bookingView={bookingView}
                    stickyFilterHeight={stickyFilterHeight}
                    bgColor={bgColor}
                    classes={availableClasses}
                    fetchMore={fetchMoreClasses}
                    onBooked={handleOnAvailableClassBooked}
                    onFilterChange={onBookingFilterChange}
                    loading={loading}
                    nextAvailableDate={nextAvailableDate}
                />
            </TrackingContext>
            {!loading && suggestedClasses.length > 0 && (
                <TrackingContext section="Tile: Suggested classes">
                    <SuggestedClasses
                        classes={suggestedClasses}
                        bookingView={bookingView}
                        bookingFilter={bookingFilter}
                        isEmbedded={isEmbedded}
                        onBooked={handleSuggestedClassBooked}
                        stickyFilterHeight={stickyFilterHeight}
                        bgColor={bgColor}
                    />
                </TrackingContext>
            )}
        </Box>
    );
};

export const BookingSearchResults = memo(BookingSearchResultsComponent);

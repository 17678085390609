import { isNewCoursePageEnabled } from '@lingoda/feature-flags';
import { trans } from '@lingoda/i18n';
import { ORIENTATION_CHAPTER_NUMBER } from './const';

export const getChapterName = ({ chapter }: { chapter: number }) => {
    if (isNewCoursePageEnabled() && chapter === ORIENTATION_CHAPTER_NUMBER) {
        return trans('chapter-get-started', {}, 'student-common');
    }

    return trans('chapter', { chapter }, 'student-common');
};

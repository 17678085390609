import { type ReactNode, forwardRef } from 'react';
import { colorsPalette, gradients, styled } from '../theme';
import { Button, type ButtonProps } from './Button';
import type { Theme } from '../theme';

export interface GradientButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
    variant?: 'contained' | 'outlined';
}

export const GradientButton = forwardRef<HTMLButtonElement, GradientButtonProps>(
    ({ variant = 'contained', ...restProps }, ref) => {
        return <GradientButtonStyled {...restProps} variant={variant} color="primary" ref={ref} />;
    },
);
GradientButton.displayName = 'GradientButton';

const GradientButtonStyled = styled(Button)(({
    theme,
    variant,
    inverted = false,
    children,
    disabled,
    loading,
    startIcon,
    endIcon,
    fullWidth,
}) => {
    if (variant === 'contained') {
        return inverted ? invertedContainedStyles : containedStyled(theme);
    } else if (!disabled && !loading) {
        return outlineStyles(theme, children, !!startIcon, !!endIcon, inverted, !!fullWidth);
    }
});

const invertedContainedStyles = {
    '& .MuiBox-root': {
        background: gradients.purpleToPrimary,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    },
};

const containedStyled = (theme: Theme) => ({
    background: theme.palette.gradients.purpleToPrimary,
    backgroundOrigin: 'border-box',

    '&:hover': {
        background: theme.palette.gradients.darkPurpleToPrimaryDark,
        backgroundOrigin: 'border-box',
    },
    '&:focus-visible': {
        outline: `${theme.borderWidth}px solid ${theme.palette.common.white}`,
        '&::before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            width: `calc(100% + ${theme.borderWidth * 4}px)`,
            height: `calc(100% + ${theme.borderWidth * 4}px)`,

            background: 'inherit',
            opacity: 0.4,
            borderRadius: theme.spacing(0.5),
        },
    },
    '&:disabled': {
        background: colorsPalette.blackSolid[20],
        backgroundOrigin: 'border-box',
    },
});

const outlineStyles = (
    theme: Theme,
    children: ReactNode,
    hasStartIcon: boolean,
    hasEndIcon: boolean,
    inverted: boolean,
    fullWidth: boolean,
) => {
    if (inverted) {
        return {
            ...containedStyled(theme),
        };
    }

    return {
        borderColor: 'transparent',
        outline: 'none',
        position: 'relative',
        zIndex: 1,
        borderRadius: theme.spacing(0.5),
        background: gradients.purpleToPrimary,

        '&::before': {
            content: '""',
            position: 'absolute',
            border: `1px solid transparent`,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            borderRadius: theme.spacing(0.5 - 1 / 8),
            background: colorsPalette.whiteSolid[100],
            transition: 'background 250ms',
            zIndex: -1,
        },

        '& .MuiBox-root': {
            color: 'transparent',
        },

        '&::after': {
            content: `'${children}'`,
            position: 'absolute',
            left: 0,
            width: (!hasEndIcon || fullWidth) && '100%',
            padding: 'inherit',
            display: 'inline-block',
            background: gradients.purpleToPrimary,
            color: 'transparent',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            marginLeft: hasStartIcon ? theme.spacing(1) : fullWidth ? theme.spacing(-1) : 0,
        },

        '&:hover': {
            borderColor: 'transparent',
            background: gradients.darkPurpleToPrimaryDark,
            '&::before': {
                background: colorsPalette.primarySolid[4],
            },
        },
    } as const;
};

import { Box, styled } from '@lingoda/ui';
import { getBadgeUrls } from '../utils';

interface AchievementBadgeProps {
    badgeSlug: string;
    active?: boolean;
    name: string;
    loading?: boolean;
    width?: number;
    height?: number;
    zIndex?: number;
}

export const AchievementBadge = ({
    badgeSlug,
    active,
    name,
    loading,
    width = 200,
    height = 200,
    zIndex,
}: AchievementBadgeProps) => {
    const { activeBadgeUrl, inactiveBadgeUrl } = getBadgeUrls(badgeSlug);

    return (
        <Container width={width} height={height} zIndex={zIndex} loading={loading}>
            <img src={active ? activeBadgeUrl : inactiveBadgeUrl} alt={name}></img>
        </Container>
    );
};

const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'loading',
})<{ loading?: boolean }>(({ loading }) => ({
    flex: '0 0 auto',
    visibility: loading ? 'hidden' : 'visible',
    '& > img': {
        width: '100%',
        height: '100%',
    },
}));

import { useMemo } from 'react';
import { createDate, diffInUnits } from '@lingoda/dates';
import { localRoundMinuteSelector } from '@lingoda/time';
import { useSelector } from '@lingoda/hooks';
import type { UserSubscriptionsQuery } from '@lingoda/graphql';
import { useUserSubscriptionsQuery } from '@lingoda/graphql';

const diffInDays = diffInUnits('days');
const diffInMinutes = diffInUnits('minutes');

type TrialDaysLeftState =
    | { state: 'loading' }
    | { state: 'finished' }
    | { state: 'not-trial' }
    | { state: 'trial'; days: number };

type TrialStateData = [TrialDaysLeftState, () => void];

const getTrialState = (
    data: UserSubscriptionsQuery | undefined,
    loading: boolean,
    refetch: () => Promise<unknown>,
    now: Date,
): TrialStateData => {
    if (loading || !data) {
        return [{ state: 'loading' }, refetch];
    }

    const { currentSubscription, latestSubscription } = data.subscriptions;

    if (!currentSubscription && latestSubscription?.isInTrial) {
        return [{ state: 'finished' }, refetch];
    }

    if (!currentSubscription || !currentSubscription.expiresAt || !currentSubscription.isInTrial) {
        return [{ state: 'not-trial' }, refetch];
    }

    const expirationDate = createDate(currentSubscription.expiresAt);

    // we need the next days value. i.e. when  6 < days < 7, we need 7
    // might not be updated when day is changed, so just taking the current date on the moment of checking;
    const exactMoment = createDate();
    const days = diffInDays(expirationDate, exactMoment) + 1;

    // we need minutes value so it would disappear as soon as becomes expired
    const minutes = diffInMinutes(expirationDate, now);

    if (minutes < 0) {
        return [{ state: 'finished' }, refetch];
    }

    return [{ state: 'trial', days }, refetch];
};

export const useTrialState = (): TrialStateData => {
    const now = useSelector(localRoundMinuteSelector);
    const { data, loading, refetch } = useUserSubscriptionsQuery();

    const state = useMemo(
        () => getTrialState(data, loading, refetch, now),
        [data, loading, now, refetch],
    );

    return state;
};

import { createSelector } from 'reselect';
import { ClassType } from '@lingoda/graphql';
import { studentSelector } from '@lingoda/students';

export const defaultClassTypeSelector = createSelector(studentSelector, (student) => {
    return !student
        ? ClassType.Group
        : student.groupCredits
          ? ClassType.Group
          : student.individualCredits
            ? ClassType.Individual
            : ClassType.Group;
});

import { colorsPalette, styled } from '../theme';
import { Button } from './Button';

const FilterButton = styled(Button)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    borderRadius: 22,
    '&.MuiButton-outlined': {
        padding: theme.spacing(0.5, 1),
    },
    '&.MuiButton-outlinedPrimary': {
        color: colorsPalette.primarySolid[100],
        background: colorsPalette.primaryAlpha[8],
        borderColor: colorsPalette.primarySolid[100],
        '&:focus, &:hover, &[disabled]': {
            color: colorsPalette.primarySolid[100],
            background: colorsPalette.primaryAlpha[20],
            borderColor: colorsPalette.primarySolid[100],
        },
    },
    '&.MuiButton-outlinedSecondary': {
        color: colorsPalette.blackSolid[100],
        background: theme.palette.common.white,
        borderColor: colorsPalette.blackAlpha[20],
        '&:focus, &:hover, &[disabled]': {
            color: colorsPalette.blackSolid[100],
            background: theme.palette.common.white,
            borderColor: colorsPalette.blackSolid[100],
        },
    },
    '& .MuiButton-startIcon': {
        paddingLeft: theme.spacing(1),
    },
}));

export default FilterButton;

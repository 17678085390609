import { useCallback, useEffect, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client/errors';
import {
    useBasicActivityChallengeDataLazyQuery,
    useJoinActivityChallengeMutation,
} from '@lingoda/graphql';
import type { ActivityChallengeParticipantStatusEnum, ApolloErrorHandler } from '@lingoda/graphql';

interface Params {
    challengeName: string;
    onSuccess: () => void;
    onError: ApolloErrorHandler;
    fetchChallengePollingInterval?: number;
    userStatus: ActivityChallengeParticipantStatusEnum | undefined;
    maxNumOfPollingAttempts?: number;
}

/**
 * @description
 * Hook to join an activity challenge.
 * 1. After triggering joinTrigger, it starts polling the data until user is joined or an error occurs.
 * 2. If user is eligible for autojoin, it calls joinTrigger automatically.
 */
export const useJoinActivityChallenge = ({
    challengeName,
    onSuccess,
    onError,
    fetchChallengePollingInterval = 5000,
    userStatus,
    maxNumOfPollingAttempts = 10,
}: Params) => {
    const shouldAutoJoinRef = useRef(userStatus === 'created');
    const [loading, setLoadingState] = useState(false);
    const [isError, setIsError] = useState(false);
    const [autoJoinPollingStarted, setAutoJoinPollingStarted] = useState(false);
    const [pollingInProgress, setPollingInProgress] = useState(false);
    const [pollsCounter, setPollsCount] = useState(0);
    const handleError = useCallback(
        (error: ApolloError) => {
            setLoadingState(false);
            setPollingInProgress(false);
            setIsError(true);

            if (!shouldAutoJoinRef.current) {
                onError(error);
            }
        },
        [onError],
    );
    const [, { startPolling, stopPolling, data: lazyQueryData, error: challengeError }] =
        useBasicActivityChallengeDataLazyQuery({
            notifyOnNetworkStatusChange: true, // to trigger onComplete each polled time
            variables: { challengeName },
            onCompleted: () => {
                setPollsCount((prev) => prev + 1);
            },
            onError: (error) => {
                handleError(error);
                stopPolling();
            },
        });

    const initPolling = useCallback(() => {
        setPollingInProgress(true);
        setPollsCount(0);
        setLoadingState(true);
        setIsError(false);
        startPolling(fetchChallengePollingInterval);
    }, [fetchChallengePollingInterval, startPolling]);

    const handleSuccess = useCallback(() => {
        stopPolling();
        setLoadingState(false);
        setPollingInProgress(false);

        if (!shouldAutoJoinRef.current) {
            onSuccess();
        }
    }, [onSuccess, stopPolling]);

    const [joinChallenge, { data: triggerData }] = useJoinActivityChallengeMutation({
        variables: { challengeName },
        onError: handleError,
    });

    const joinChallengeTrigger = () => {
        setLoadingState(true);
        setIsError(false);

        return joinChallenge();
    };
    const userIsJoining =
        lazyQueryData?.activityChallenge.currentParticipant?.status === 'created' ||
        triggerData?.joinChallenge.status === 'created';
    const userJoined =
        lazyQueryData?.activityChallenge.currentParticipant?.status === 'joined' ||
        triggerData?.joinChallenge.status === 'joined';

    useEffect(() => {
        const didUserJustJoin = userJoined && pollingInProgress;

        if (didUserJustJoin) {
            handleSuccess();
        }
    }, [handleSuccess, pollingInProgress, userJoined]);

    useEffect(() => {
        const shouldInitPolling =
            !userJoined &&
            userIsJoining &&
            !pollingInProgress &&
            !autoJoinPollingStarted &&
            !isError;

        if (shouldInitPolling) {
            initPolling();
        }
    }, [
        autoJoinPollingStarted,
        challengeError,
        initPolling,
        isError,
        pollingInProgress,
        userIsJoining,
        userJoined,
    ]);

    useEffect(() => {
        // when passed down userStatus is 'created' it means that user is in process of joining the challenge
        // BE is collecting data and will return 'joined' status when it's ready
        const shouldInitAutoJoinPolling =
            shouldAutoJoinRef.current && !autoJoinPollingStarted && !pollingInProgress;
        if (shouldInitAutoJoinPolling) {
            setAutoJoinPollingStarted(true);
            initPolling();
        }
    }, [autoJoinPollingStarted, initPolling, pollingInProgress]);

    useEffect(() => {
        if (pollsCounter >= maxNumOfPollingAttempts) {
            stopPolling();
            handleError(new ApolloError({}));
        }
    }, [handleError, maxNumOfPollingAttempts, pollsCounter, stopPolling]);

    useEffect(
        () => () => {
            stopPolling();
        },
        [stopPolling],
    );

    return [joinChallengeTrigger, { loading }] as const;
};

import { type MouseEvent } from 'react';
import { type Achievement, type ChecklistItem } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { Box, GradientButton, ProgressBar, Typography, colorsPalette } from '@lingoda/ui';
import { AchievementSlug } from '../../models';
import { getIsClaimable } from '../../utils';
import { AchievementBadge } from '../AchievementBadge';
import { Checklist } from './Checklist';
import { ChecklistSkeleton } from './ChecklistSkeleton';

interface Props {
    achievement: Achievement;
    checklist: ReadonlyArray<ChecklistItem>;
    isClaiming: boolean;
    isChecklistLoading?: boolean;
    onClaim: (event: MouseEvent) => Promise<void>;
}

export const AchievementProgress = ({
    achievement,
    achievement: { achieved, achievedLabel, claimed, currentProgress, name, progressLabel, value },
    checklist,
    isClaiming,
    isChecklistLoading,
    onClaim,
}: Props) => {
    const isClaimable = getIsClaimable(achievement);
    const label = !achieved || isChecklistLoading ? progressLabel : achievedLabel;

    return (
        <Box display="flex">
            <AchievementBadge
                badgeSlug={AchievementSlug.ONBOARDING_CHECKLIST}
                active={claimed}
                name={name}
                width={64}
                height={64}
            />
            <Box sx={{ flex: 'auto', marginLeft: { xs: 2, sm: 3 }, maxWidth: 304 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="body2"
                        sx={{ wordBreak: 'break-word' }}
                        htmlColor={colorsPalette.blackAlpha[70]}
                    >
                        {label}
                    </Typography>
                    <Typography
                        variant="body2"
                        marginLeft={0.5}
                        htmlColor={colorsPalette.blackAlpha[70]}
                    >
                        {currentProgress}/{value}
                    </Typography>
                </Box>
                <ProgressBar
                    value={(currentProgress / value) * 100}
                    size="medium"
                    sx={{ marginTop: 1 }}
                />
                <Box sx={{ marginTop: 3 }}>
                    {isClaimable ? (
                        <GradientButton
                            size="small"
                            fullWidth
                            onClick={onClaim}
                            disabled={isClaiming}
                            loading={isClaiming}
                            sx={{ maxWidth: 240 }}
                        >
                            {trans('btn-claim-reward', {}, 'student-achievements')}
                        </GradientButton>
                    ) : isChecklistLoading ? (
                        <ChecklistSkeleton />
                    ) : (
                        <Checklist checklist={checklist} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

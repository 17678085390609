/**
 * TODO: Add AppointmentLabel as enum to schema
 * https://lingoda.atlassian.net/browse/LW-22430
 */
export enum AppointmentLabel {
    InSession = 'in-session',
    TeacherFeedbackNotReady = 'teacher-feedback-not-ready',
    TeacherFeedbackReady = 'teacher-feedback-ready',
    ClassCancelled = 'class-cancelled',
    ClassMissed = 'class-missed',
}

/**
 * @deprecated TODO: Use AppointmentStatus from @lingoda/graphql instead
 * https://lingoda.atlassian.net/browse/LW-22429
 */
export enum AppointmentStatus {
    ACTIVE = 'active',
    ATTENDED = 'attended',
    CANCELLED = 'cancelled',
    MISSED = 'missed',
    DELETED = 'deleted',
}

/**
 * @deprecated TODO: Use ClassStatus from @lingoda/graphql instead
 * https://lingoda.atlassian.net/browse/LW-22429
 */
export enum ClassStatus {
    Requested = 'requested',
    Scheduled = 'scheduled',
    Cancelled = 'cancelled',
    Completed = 'completed',
    Unsuccessful = 'unsuccessful',
}

export const getBarBackgroundColor = (
    value: number,
    goal: number | null,
    page: number,
    itemIndex: number,
    totalItems: number,
) => getColor(value, goalWasReached(goal, value), isLastWeek(page, itemIndex, totalItems));

const getColor = (lessonTaken: number, goalAchieved: boolean | undefined, isLastWeek: boolean) => {
    if (lessonTaken === 0) {
        return '#b6bbc6';
    }

    if (goalAchieved) {
        return isLastWeek
            ? 'linear-gradient(90deg, #f38400 0%, #f3db03 100%)'
            : 'linear-gradient(90deg, #f4be8f 0%, #f3e892 100%)';
    }

    return isLastWeek
        ? 'linear-gradient(90deg, #7200cc 0%, #173bff 100%)'
        : 'linear-gradient(90deg, #b68fe1 0%, #859ffa 100%)';
};

export const goalWasReached = (goal: number | null, value: number) => !!goal && value >= goal;

const isLastWeek = (page: number, itemIndex: number, itemsCount: number) =>
    page === 0 && itemIndex === itemsCount - 1;

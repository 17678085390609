import { loadStripe } from '@stripe/stripe-js';
import { getDashLang } from '@lingoda/env';
import { config } from '@lingoda/config';
import { Elements } from '../models';
import type { StripeElementLocale } from '@stripe/stripe-js';

const fonts = [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:400' }];

const stripePromise = loadStripe(config.stripePublishable);

const withStripe = <T extends Record<string, unknown>>(
    Component: React.ComponentType<T>,
): React.ComponentType<T> =>
    function withStripe(props: T) {
        const options = { fonts, locale: getDashLang() as StripeElementLocale };

        return (
            <Elements stripe={stripePromise} options={options}>
                <Component {...props} />
            </Elements>
        );
    };

export default withStripe;

import { httpGet } from '@lingoda/http';
import { buildUrl, getUrl } from '@lingoda/urls';
import type { StudentInvoiceSchema } from './common';

export interface StudentInvoicesResponse {
    data: StudentInvoiceSchema[];
    meta: {
        total: number;
    };
}

interface Query {
    page?: string | number;
    size?: string | number;
}

export const getStudentInvoices = (
    userId: number,
    query?: Query,
): Promise<StudentInvoicesResponse> =>
    httpGet(
        buildUrl(
            getUrl('api_users_invoices_list', { user_id: userId }),
            query ? { query } : undefined,
        ),
    );

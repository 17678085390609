import { useCallback } from 'react';
import { actionClick } from '@lingoda/analytics';
import { styled } from '@lingoda/ui/theme';
import { useUiEvent } from '@lingoda/ui';
import LingodaO from '../lingoda-o.svg?component';
import LingodaWhite from '../lingoda-white.svg?component';
import Lingoda from '../lingoda.svg?component';

interface Props {
    white?: boolean;
    isIcon?: boolean;
    width?: string | number;
    height?: string | number;
}

const LingodaOIcon = styled(LingodaO)({});

const LingodaWhiteIcon = styled(LingodaWhite)({});

const LingodaIcon = styled(Lingoda)({});

export const LingodaLogo = ({ white, width, isIcon, height }: Props) => {
    const addEvent = useUiEvent();
    const iconClick = useCallback(() => addEvent(actionClick('Lingoda icon')), [addEvent]);

    const Logo = isIcon ? LingodaOIcon : white ? LingodaWhiteIcon : LingodaIcon;

    return <Logo sx={{ width, height, maxWidth: 'none' }} onClick={iconClick} />;
};

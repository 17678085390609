// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../linguando/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../linguando/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../linguando/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Lingoda-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Lingoda-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Lingoda-Semibold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'Lingoda Regular';\n    font-weight: 300;\n    src:\n        local('Lingoda Regular'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Lingoda Bold';\n    font-weight: 600;\n    src:\n        local('Lingoda Bold'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n}\n\n@font-face {\n    font-family: 'Lingoda Semibold';\n    font-weight: 400;\n    src:\n        local('Lingoda Semibold'),\n        url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n", "",{"version":3,"sources":["webpack://./../workspaces/packages/ui/src/fonts/lingoda/index.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,gBAAgB;IAChB;;kEAEmD;AACvD;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB;;kEAEgD;AACpD;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB;;kEAEoD;AACxD","sourcesContent":["@font-face {\n    font-family: 'Lingoda Regular';\n    font-weight: 300;\n    src:\n        local('Lingoda Regular'),\n        url('./Lingoda-Regular.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Lingoda Bold';\n    font-weight: 600;\n    src:\n        local('Lingoda Bold'),\n        url('./Lingoda-Bold.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'Lingoda Semibold';\n    font-weight: 400;\n    src:\n        local('Lingoda Semibold'),\n        url('./Lingoda-Semibold.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

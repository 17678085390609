import { ApolloLink, fromPromise } from '@apollo/client';
import { getAuthCheck } from '@lingoda/auth';
import { getAuthHeaders } from '@lingoda/http';
import { getLang } from '@lingoda/env';
import { config } from '@lingoda/config';

export default () =>
    new ApolloLink((operation, forward) =>
        fromPromise(getAuthCheck('').then(getAuthHeaders)).flatMap((headers) => {
            operation.setContext({
                headers: {
                    ...headers,
                    Accept: 'application/json',
                    'X-Locale': getLang(),
                    'X-Release-Id': config.releaseId,
                },
            });

            return forward(operation);
        }),
    );

import { v4 as uuidV4 } from 'uuid';
import { createAction } from '@lingoda/core';
import { createDate } from '@lingoda/dates';
import { type Notification } from '../models';
import { checkNotificationSeen } from '../utils';

export type Notifications = Pick<Notification<unknown>, 'type' | 'payload'>[];

export default createAction('notifications/ADD_NOTIFICATIONS', (notifications: Notifications) =>
    notifications.map(
        (notification): Notification<unknown> => ({
            ...notification,
            id: uuidV4(),
            timestamp: createDate(),
            seen: checkNotificationSeen(notification),
        }),
    ),
);

import type { Date } from '@lingoda/dates';
import type { StudentId } from '@lingoda/students';
import { createRecordFactory } from '@lingoda/utils';
import type { AppointmentId, ClassId } from '../constants';
import type { AppointmentStatus } from './status';
import type { StudentStatus } from './studentStatus';

export interface Appointment {
    classId: ClassId;
    endDate: Date;
    feedbackId?: number;
    needsMissedClassFeedback?: boolean;
    id: AppointmentId;
    moduleId?: number;
    startDate: Date;
    createdAt: Date;
    status: AppointmentStatus;
    statusByStudent?: StudentStatus;
    studentId: StudentId;
}

export const AppointmentFactory = createRecordFactory<Appointment>({
    classId: undefined,
    endDate: undefined,
    feedbackId: undefined,
    needsMissedClassFeedback: undefined,
    id: undefined,
    moduleId: undefined,
    startDate: undefined,
    createdAt: undefined,
    status: undefined,
    statusByStudent: undefined,
    studentId: undefined,
});

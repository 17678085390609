import { memo, useEffect, useState } from 'react';
import { type BookableClass, type BookingFilter } from '@lingoda/booking';
import { ClassGrid } from '@lingoda/booking-ui';
import { BookableClassElement, type BookableClassElementProps } from './BookableClassElement';

interface Props {
    slots: BookableClass[];
    isAbleToBook: boolean;
    isEmbedded?: boolean;
    onBooked?: BookableClassElementProps['onBooked'];
    bookingFilter: BookingFilter;
}

const ClassesGridComponent = ({
    slots,
    isAbleToBook,
    isEmbedded,
    onBooked,
    bookingFilter,
}: Props) => {
    const [activeIndex, setActiveIndex] = useState(2);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (activeIndex <= slots.length) {
            timeout = setTimeout(() => setActiveIndex(activeIndex + 2), 0);
        }

        return () => clearTimeout(timeout);
    }, [activeIndex, slots.length]);

    return (
        <ClassGrid>
            {slots.map((klass, i) => (
                <BookableClassElement
                    key={i}
                    isEmbedded={isEmbedded}
                    isAbleToBook={isAbleToBook}
                    klass={klass}
                    active={i <= activeIndex}
                    outlined={false}
                    onBooked={onBooked}
                    bookingFilter={bookingFilter}
                />
            ))}
        </ClassGrid>
    );
};

export const ClassesGrid = memo(ClassesGridComponent);

import { createSelector } from 'reselect';
import { ClassStatus } from '@lingoda/classes';
import type { LessonId } from '@lingoda/lessons';
import studentAppointmentSelector from './studentAppointments';

export default createSelector(
    studentAppointmentSelector,
    (_: unknown, lessonId: LessonId) => lessonId,
    (appointments, lessonId) =>
        appointments.some(
            (appointment) =>
                appointment.class &&
                appointment.class.lessonId === lessonId &&
                appointment.class.status === ClassStatus.Scheduled,
        ),
);

import type { Date } from '@lingoda/dates';
import { createRecordFactory } from '@lingoda/utils';

export type UserId = number;

interface Photos {
    thumbnail?: string;
    thumbnail_large?: string;
}

export interface User {
    id: UserId;
    uid?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    photos?: Photos;
    sectionId?: number;
    studentId?: number;
    studentInfoId?: number;
    teacher?: boolean;
    timezone?: string;
    calendarUrl?: string;
    verified?: boolean;
    currency?: unknown;
    isSprintRunner?: boolean;
    marketingAgreement?: {
        id: number;
        acceptanceText: string;
    };
    isCorporateClient?: boolean;
    photo?: {
        thumbnail: string;
        thumbnail2x: string;
    };
    defaultContract?: {
        id: number;
        type: string;
        reference: string;
        displayNumber: string;
    };
    referralCode?: string;
    now?: Date;
    localNow?: Date;
    cancelledExpiresAt?: Date;
    sociallyRegistered?: boolean;
    lastActiveAt?: string;
    createdAt?: string;
    isEmailVerified?: boolean;
    showClassBookingExperienceSurvey?: boolean;
    showPracticeSatisfactionSurvey?: boolean;
    isGuest?: boolean;
    hasSubscription?: boolean;
}

export type Users = ReadonlyArray<User>;

export const UserFactory = createRecordFactory<User>({
    id: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    fullName: undefined,
    photos: undefined,
    sectionId: undefined,
    studentId: undefined,
    studentInfoId: undefined,
    teacher: undefined,
    timezone: undefined,
    calendarUrl: undefined,
    verified: undefined,
    isSprintRunner: undefined,
    marketingAgreement: undefined,
    isCorporateClient: undefined,
    defaultContract: undefined,
    photo: undefined,
    referralCode: undefined,
    now: undefined,
    localNow: undefined,
    cancelledExpiresAt: undefined,
    createdAt: undefined,
    hasSubscription: undefined,
});

import { useCallback, useMemo } from 'react';
import type { Country } from '@lingoda/graphql';
import { useCountriesQuery } from '@lingoda/graphql';
import { countryToFlag } from '../../utils';
import { AutocompleteField } from '../AutocompleteField/AutocompleteField';
import type { AutocompleteFieldProps } from '../AutocompleteField/AutocompleteField';

interface CountryFieldProps
    extends Pick<AutocompleteFieldProps<string>, 'name' | 'label' | 'fullWidth'> {
    countryCodesIncluded?: string[];
}

export const CountryField = ({ countryCodesIncluded, ...props }: CountryFieldProps) => {
    const { data, loading } = useCountriesQuery();

    const { countryCodes, toCountryLabelMapper } = useMemo(() => {
        const countries = data?.countries || [];
        const countriesFiltered = countryCodesIncluded
            ? (countries || []).filter((country) => countryCodesIncluded.includes(country.code))
            : countries;

        const countryCodes = countriesFiltered?.map((country) => country.code);
        const toCountryLabelMapper: Record<string, Country | undefined> = countriesFiltered.reduce(
            (sum, value) => ({
                ...sum,
                [value.code]: value,
            }),
            {},
        );

        return { countryCodes, toCountryLabelMapper };
    }, [countryCodesIncluded, data?.countries]);

    const getOptionLabel = useCallback(
        (code: string) => toCountryLabelMapper[code]?.commonName || '',
        [toCountryLabelMapper],
    );

    return (
        <AutocompleteField
            options={countryCodes}
            getOptionLabel={getOptionLabel}
            getOptionIcon={(code) => countryToFlag(code)}
            loading={loading}
            {...props}
        />
    );
};

import { createSelector } from 'reselect';
import studentModuleSelector from './studentModule';
import { studentNextModuleSelector, studentPrevModuleSelector } from './studentPrevNextModule';

// this selector is used to encode the different modules scenarios
export default createSelector(
    studentNextModuleSelector,
    studentPrevModuleSelector,
    studentModuleSelector,
    (nextModule, prevModule, currentModule) =>
        `${currentModule?.name}-${currentModule?.completed}-${!!currentModule?.cefrLevelName}-${!!nextModule}-${!!prevModule}`
            .split('.')
            .join('-'),
);

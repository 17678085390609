import { Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import type { PropsWithChildren } from 'react';

export const ClassListItemHeader = ({ children }: PropsWithChildren<unknown>) => (
    <Typography
        variant="subtitle2"
        htmlColor={colorsPalette.primarySolid[100]}
        sx={{ width: 64, mr: 1 }}
    >
        {children}
    </Typography>
);

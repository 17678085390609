import { studentChallengePath } from '@lingoda/router';
import {
    Link,
    Paper,
    Stack,
    Typography,
    colorsPalette,
    styled,
    withCursorInteractiveStyles,
} from '@lingoda/ui';
import { getChallengeTexts } from '../../utils';
import { type Prize } from '../../types';
import { ChallengeBoardPrizes } from './ChallengeBoardPrizes';

interface Props {
    challengeName: string;
    prizes: ReadonlyArray<Prize>;
    completedActivities: number;
    startDate: string;
    challengeIconUrl: string | undefined;
    title: string;
}

export const ChallengeBoardItem = ({
    challengeName,
    startDate,
    prizes,
    completedActivities,
    challengeIconUrl,
    title,
}: Props) => {
    const { subtitleLong } = getChallengeTexts(challengeName, startDate);

    return (
        <PaperStyled component={Link} to={studentChallengePath({ challengeName })} elevation={1}>
            <ChallengeBoardPrizes
                prizes={prizes}
                completedActivities={completedActivities}
                label={title}
                challengeIconUrl={challengeIconUrl}
            />
            <Stack spacing={1} mt={2}>
                <Typography variant="subtitle1">{title}</Typography>
                <Typography
                    variant="body2"
                    htmlColor={colorsPalette.blackAlpha[70]}
                    sx={{ textTransform: 'capitalize' }}
                >
                    {subtitleLong}
                </Typography>
            </Stack>
        </PaperStyled>
    );
};

const PaperStyled = styled(Paper)(({ theme }) =>
    withCursorInteractiveStyles(true)({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3),
        textDecoration: 'none',
        textAlign: 'center',
        color: 'inherit',
        ':hover': {
            color: 'inherit',
            background: theme.palette.common.white,
            boxShadow: theme.shadows[4],
        },
    }),
) as typeof Paper; // `as` to retain `component` property

import { type PropsWithChildren, useState } from 'react';
import { useChange, useTimeout } from '@lingoda/hooks';
import { ErrorBoundary } from '@lingoda/monitor';
import { useLocation } from '@lingoda/router';
import {
    SlidePopup,
    SlidePopupSuccessLayout,
    colorsPalette,
    withSnackbarAdapter,
} from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { PersonalFeedbackSatisfactionContent } from './PersonalFeedbackSatisfactionContent';

declare module 'notistack' {
    interface VariantOverrides {
        personalFeedbackSatisfaction: true;
    }
}

export const PersonalFeedbackSatisfactionPopup = withSnackbarAdapter(({ close }) => {
    const [isCompleted, setCompleted] = useState<boolean>(false);

    useTimeout(() => close(), isCompleted ? 2000 : null);

    const location = useLocation();
    useChange(() => {
        close();
    }, [location.pathname]);

    return (
        <StyledPopup isCompleted={isCompleted}>
            {isCompleted ? (
                <SlidePopupSuccessLayout
                    message={trans('feedback-submitted', {}, 'student-common')}
                />
            ) : (
                <ErrorBoundary onError={close}>
                    <PersonalFeedbackSatisfactionContent
                        close={close}
                        onCompleted={() => setCompleted(true)}
                    />
                </ErrorBoundary>
            )}
        </StyledPopup>
    );
});

interface StyledPopup {
    isCompleted?: boolean;
}

const StyledPopup = ({ isCompleted, children }: PropsWithChildren<StyledPopup>) => {
    return (
        <SlidePopup
            sx={{
                width: (theme) => theme.spacing(65),
                backgroundColor: isCompleted ? colorsPalette.positiveSolid[12] : undefined,
            }}
        >
            {children}
        </SlidePopup>
    );
};

import { TimeFormatLegacy } from '@lingoda/dates';
import { getLang } from '@lingoda/env';

// the registration endpoint is only accepting legacy time format (should be changed here when BE is updated)
export const getDefaultTimeFormat = () => {
    if (getLang() !== 'en') {
        return TimeFormatLegacy.Iso8601;
    }

    const userLanguage = window.navigator.language;
    if (!userLanguage) {
        return TimeFormatLegacy.Iso8601;
    }

    const amPmLanguages = ['en-US']; // See options in https://www.techonthenet.com/js/language_tags.php
    if (amPmLanguages.includes(userLanguage)) {
        return TimeFormatLegacy.Ampm;
    }

    return TimeFormatLegacy.Iso8601;
};

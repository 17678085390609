import { type ReactNode } from 'react';
import { Box } from '../../../layout/Box';
import { DialogHeader } from '../DialogHeader';
import { Typography, type TypographyProps } from '../../../layout/Typography';

interface DialogTitleProps {
    children?: ReactNode;
    onClose?: () => void;
    titleProps?: TypographyProps;
}

export const DialogConfirmTitle = ({ children, titleProps, onClose }: DialogTitleProps) => {
    return (
        <Box
            marginBottom={{ xs: 2, sm: 4 }}
            marginTop={{ xs: 3, sm: 4 }}
            paddingX={{ xs: 3, sm: 4 }}
            minHeight="1.6rem" // preserves space when there's no title
            flexShrink={0} // fixes issue with long titles on small screens
        >
            <DialogHeader onClose={onClose}>
                {children && (
                    <Typography variant="h2" {...titleProps}>
                        {children}
                    </Typography>
                )}
            </DialogHeader>
        </Box>
    );
};

import { type ComponentProps } from 'react';
import { DialogLayout } from './DialogLayout';
import { Form } from './Form';

type ReportContentDialogProps = ComponentProps<typeof Form> & ComponentProps<typeof DialogLayout>;

export const ReportContentDialog = ({
    onSubmit,
    onDismiss,
    isFormLoading,
    subject,
    options,
    container,
}: ReportContentDialogProps) => {
    return (
        <Form onSubmit={onSubmit}>
            <DialogLayout
                onDismiss={onDismiss}
                isFormLoading={isFormLoading}
                options={options}
                subject={subject}
                container={container}
            />
        </Form>
    );
};

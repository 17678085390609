import { useMemo } from 'react';
import { createDate, isAfter } from '@lingoda/dates';
import { type DatePickerDotColor } from '../DatePicker';
import { CalendarContent } from '../DatePicker/Calendar';
import { CalendarContainer } from '../DatePicker/CalendarContainer';
import { useResolution } from '../../hooks';
import { styled } from '../../theme';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import type { DateRangeProps, Range, RangeKeyDict } from 'react-date-range';

export { DatePickerDotColor, type Range };

export interface DateRangePickerProps {
    dateFrom?: Date;
    dateTo?: Date;
    onChange: ({ range }: { range: Range }) => void;
    onFocus?: (focus: FocusedDate) => void;
    format?: string;
    disabled?: boolean;
    minDate?: Date;
    maxDate?: Date;
    scheduledDays?: Date[];
    scheduledDayTooltip?: string;
    loading?: boolean;
    dotColor?: DatePickerDotColor;
}

export const DateRangePicker = ({
    dateFrom,
    dateTo,
    minDate,
    maxDate,
    onChange,
    onFocus,
    scheduledDays,
    scheduledDayTooltip,
    loading,
    dotColor = 'info',
}: DateRangePickerProps) => {
    const isMobile = useResolution('phone');

    const onRangeChange = (ranges: RangeKeyDict) => {
        const range = ranges[DATE_RANGE_PICKER_ID];
        onChange({ range });
    };

    const minDateOrNow = minDate && isAfter(createDate(), minDate) ? createDate() : minDate;
    const startDate = dateFrom || minDateOrNow;
    const endDate = dateTo || minDateOrNow;

    const customProps = useMemo(() => {
        const selectionRange = [
            {
                key: DATE_RANGE_PICKER_ID,
                startDate,
                endDate,
            },
        ];

        const onRangeFocusChange: DateRangeProps['onRangeFocusChange'] | undefined = onFocus
            ? ([, index]) => onFocus(index ? 'endDate' : 'startDate')
            : undefined;

        return {
            ranges: selectionRange,
            showMonthAndYearPickers: false,
            editableDateInputs: false,
            showDateDisplay: false,
            shownDate: startDate,
            dragSelectionEnabled: false,
            minDate,
            maxDate,
            onRangeFocusChange,
            ...(isMobile ? { months: 2 } : {}),
        };
    }, [endDate, isMobile, maxDate, minDate, onFocus, startDate]);

    return (
        <CalendarRangeContainer loading={loading} dotColor={dotColor} isMobile={isMobile}>
            <CalendarContent
                type="range"
                withScroll={isMobile}
                customProps={customProps}
                onSelection={onRangeChange}
                scheduledDays={scheduledDays}
                scheduledDayTooltip={scheduledDayTooltip}
            />
        </CalendarRangeContainer>
    );
};

export type FocusedDate = 'startDate' | 'endDate';

const DATE_RANGE_PICKER_ID = 'rangePicker';

const CalendarRangeContainer = styled(CalendarContainer)(({ isMobile, theme }) => ({
    ...(isMobile
        ? {
              '& .rdrCalendarWrapper.rdrDateRangeWrapper': {
                  width: '100%',
              },
          }
        : {
              '& .rdrMonths': {
                  gap: theme.spacing(1),
              },
              '& .rdrMonth': {
                  width: 'auto',
                  flexBasis: `calc(50% - ${theme.spacing(0.5)})`,
                  maxWidth: 'none',
              },
          }),
}));

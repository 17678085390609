import { useMemo } from 'react';
import { useSelector } from '@lingoda/hooks';
import { storedPaymentMethodByIdSelector } from '../selectors';
import type { PaymentMethod } from '../models';

const usePaymentMethod = (paymentMethodId?: string): PaymentMethod | undefined => {
    // Make sure each usage gets own memoised selector instead of sharing global selector
    const selector = useMemo(
        () =>
            storedPaymentMethodByIdSelector(
                (_state: unknown, id: unknown) => id as string | undefined,
            ),
        [],
    );

    return useSelector((state) => selector(state, paymentMethodId));
};

export default usePaymentMethod;

import {
    type Appointment,
    AppointmentStatus,
    ClassType,
    type Klass,
    type Maybe,
} from '@lingoda/graphql';
import { useToggleState } from '@lingoda/hooks';
import { useClassStateStudent } from '@lingoda/classes';
import { Button, Portal, TrackingContext, useResolution, useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';
import { trans } from '@lingoda/i18n';
import { BookingWidget, type BookingWidgetProps } from './BookingWidget';

type PartialClass = Pick<Klass, 'type' | 'uid'>;
type PartialAppointment = Pick<Appointment, 'status'> & { class: PartialClass };

interface BookingWidgetCollapseProps extends InheritedProps {
    lastLessonAppointment?: Maybe<PartialAppointment>;
    portalContainers?: { widget?: Element | null; toggle?: Element | null };
    classId?: string;
    availableSeats?: number;
}

type InheritedProps = Omit<BookingWidgetProps, 'mobilePortal'>;

export const BookingWidgetCollapse = ({
    lastLessonAppointment,
    portalContainers,
    classId,
    availableSeats,
    ...props
}: React.PropsWithChildren<BookingWidgetCollapseProps>) => {
    const addEvent = useUiEvent();
    const [isWidgetVisible, showWidget] = useToggleState(false);
    const isInPortal = useResolution('phone-large');
    const isPrivateClassBooked = lastLessonAppointment?.class.type === ClassType.Individual;
    const status = lastLessonAppointment?.status;
    const isOnClassDetailsPage = !!classId;
    const isOnLessonDetailsPage = !classId;
    const { hasEnded: currentClassHasEnded } = useClassStateStudent(classId);
    const { hasEnded: lastClassHasEnded } = useClassStateStudent(lastLessonAppointment?.class.uid);
    const isClassBookable = isOnClassDetailsPage && currentClassHasEnded;
    const isClassFullyBooked = availableSeats === 0;
    const canBookAgain =
        status === AppointmentStatus.Attended ||
        status === AppointmentStatus.Cancelled ||
        status === AppointmentStatus.Missed ||
        (status === AppointmentStatus.Active && lastClassHasEnded) ||
        isClassBookable ||
        isClassFullyBooked;
    const handleBookAgainClick = () => {
        void addEvent(actionClick('Book again'));
        showWidget();
    };

    if ((isOnLessonDetailsPage && !canBookAgain) || isWidgetVisible) {
        return (
            <TrackingContext section="Booking widget">
                <BookingWidget
                    initialClassType={isPrivateClassBooked ? ClassType.Individual : undefined}
                    mobilePortal={portalContainers?.widget}
                    {...props}
                />
            </TrackingContext>
        );
    }

    if (canBookAgain) {
        return (
            <Portal container={portalContainers?.toggle} disablePortal={!isInPortal}>
                <Button color="primary" variant="outlined" onClick={handleBookAgainClick} fullWidth>
                    {lastLessonAppointment
                        ? trans('btn-book-again', {}, 'student-common')
                        : trans('btn-find-classes', {}, 'student-common')}
                </Button>
            </Portal>
        );
    }

    return null;
};

import { createDate, formatDistanceStrict, isSameOrBefore } from '../date-fns';
import { getRemainingTimeUnits } from './getRemainingTimeUnits';

interface Options {
    addSuffix?: boolean;
    now?: Date;
}

export const formatTimeRemaining = (toDate: Date, options?: Options) => {
    const { now = createDate(), ...formatDistanceOptions } = options || {};

    if (isSameOrBefore(toDate, now)) {
        return '';
    }

    return formatDistanceStrict(toDate, now, {
        roundingMethod: 'round',
        unit: getRemainingTimeUnits(toDate, now),
        ...formatDistanceOptions,
    });
};

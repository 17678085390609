import { TimeFormat, TimeFormatLegacy } from '@lingoda/dates';
import type { Student } from './models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidAttributes = (input: any): input is Student => {
    if (typeof input !== 'object') {
        return false;
    }

    return true;
};

export const sortClassStudents =
    (studentId: number) =>
    <T extends { readonly id: number }>(studentA: T) => {
        return studentA.id === studentId ? -1 : 0;
    };

export const getTimeFormat = (preferenceTimeFormat: string | undefined) => {
    return preferenceTimeFormat === TimeFormat.Iso8601 ||
        preferenceTimeFormat === TimeFormatLegacy.Iso8601
        ? 'HH:mm'
        : 'h:mm a';
};

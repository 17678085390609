import {
    Box,
    DialogCloseButton,
    DialogContent,
    Paper,
    Stack,
    Typography,
    colorsPalette,
} from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import DialogActions from '@lingoda/ui/feedback/dialogs/DialogConfirm/DialogActions';
import { trans } from '@lingoda/i18n';
import juiceGlassImg from '../../../../images/juice-glass.png';
import { default as FreeAccessIllustration } from '../../images/freeAccessIllustration.svg?component';
import { default as ProgressIllustration } from '../../images/progressIllustration.svg?component';
import { default as StartIllustration } from '../../images/startIllustration.svg?component';
import { ListElement } from './ListElement';

interface BasicInfoStepProps {
    onConfirm: () => void;
    onDismiss: () => void;
    onClose: () => void;
}
export const BasicInfoStep = ({ onConfirm, onDismiss, onClose }: BasicInfoStepProps) => {
    return (
        <>
            <Paper
                sx={{
                    backgroundImage: {
                        xs: 'linear-gradient(274.88deg, #D6DDFF 4.21%, rgba(203, 210, 244, 0) 116.47%)',
                        md: `url(${juiceGlassImg}), linear-gradient(274.88deg, #D6DDFF 4.21%, rgba(203, 210, 244, 0) 116.47%)`,
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right',
                }}
                elevation={0}
            >
                <Grid
                    container
                    px={{ xs: 3, sm: 4 }}
                    py={{
                        xs: 5,
                        sm: 7.5,
                    }}
                >
                    <Grid sm={12} md={10}>
                        <Typography mb={1} variant="h1">
                            {trans('rollover-pause-next-subscription', {}, 'student-common')}
                        </Typography>
                        <Typography variant="subtitle1" htmlColor={colorsPalette.blackAlpha[60]}>
                            {trans('rollover-pause-cool-down-from-sprint', {}, 'student-common')}
                        </Typography>
                    </Grid>
                </Grid>
                {onClose && (
                    <Box
                        sx={({ spacing }) => ({
                            marginBottom: { xs: 2, sm: 4 },
                            marginTop: { xs: 3, sm: 4 },
                            paddingX: { xs: 1.5, sm: 2.5 },
                            minHeight: '1.6rem',
                            position: 'absolute',
                            right: 0,
                            top: { xs: spacing(-0.75), sm: spacing(-0.25) },
                        })}
                    >
                        <DialogCloseButton onClick={onClose} />
                    </Box>
                )}
            </Paper>
            <DialogContent
                sx={{
                    pt: 4,
                    pb: 3,
                    px: {
                        xs: 3,
                        sm: 4,
                    },
                }}
            >
                <Typography variant="overline" htmlColor={colorsPalette.blackAlpha[60]}>
                    {trans('rollover-pause-in-a-nutshell', {}, 'student-common')}
                </Typography>
                <Stack spacing={3} mt={3} mb={5}>
                    <ListElement
                        icon={<StartIllustration />}
                        title={trans('rollover-pause-you-decide', {}, 'student-common')}
                        content={trans('rollover-pause-take-a-break', {}, 'student-common')}
                    />
                    <ListElement
                        icon={<FreeAccessIllustration />}
                        title={trans('rollover-pause-free-access', {}, 'student-common')}
                        content={trans('rollover-pause-catch-up', {}, 'student-common')}
                    />
                    <ListElement
                        icon={<ProgressIllustration />}
                        title={trans('rollover-pause-retain-you-progress', {}, 'student-common')}
                        content={trans(
                            'rollover-pause-progress-will-still-be-here',
                            {},
                            'student-common',
                        )}
                    />
                </Stack>
                <Typography variant="overline" htmlColor={colorsPalette.blackAlpha[60]}>
                    {trans('rollover-pause-during-the-break', {}, 'student-common')}
                </Typography>
                <Stack spacing={3} mt={3}>
                    <ListElement
                        title={trans('rollover-pause-you-dont-lose-credits', {}, 'student-common')}
                        content={trans(
                            'rollover-pause-they-will-be-inactive',
                            {},
                            'student-common',
                        )}
                    />
                    <ListElement
                        title={trans('rollover-pause-payment-later', {}, 'student-common')}
                        content={trans(
                            'rollover-pause-you-will-only-be-charged',
                            {},
                            'student-common',
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions
                onConfirm={onConfirm}
                confirmLabel={trans('btn-continue', {}, 'student-common')}
                onDismiss={onDismiss}
                dismissLabel={trans('btn-cancel', {}, 'public-common')}
            />
        </>
    );
};

import { type CourseOverviewLearningUnitFragment } from '@lingoda/graphql';
import { CardHeader, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import { getChapterName } from '@lingoda/learning-units';
import LingodaIcon from './LingodaIcon';

interface Props {
    learningUnit: CourseOverviewLearningUnitFragment;
}

export const LearningUnitHeader = ({ learningUnit }: Props) => {
    const chapterName = getChapterName(learningUnit);

    return (
        <CardHeader
            avatar={<LingodaIcon icon={learningUnit.icon || ''} color={learningUnit.color} />}
            subheader={
                <Typography htmlColor={colorsPalette.blackAlpha[70]}>{chapterName}</Typography>
            }
            sx={{ '.MuiCardHeader-content': { display: 'flex', flexDirection: 'column-reverse' } }}
            title={<Typography variant="body1">{learningUnit.name}</Typography>}
        />
    );
};

import { useCallback, useEffect, useState } from 'react';
import { getQueryParam } from '@lingoda/urls';
import {
    getRedirectPathStorage,
    removeRedirectPathStorage,
    setRedirectPathStorage,
} from '../redirectPath';
import resolveUrl from '../resolveUrl';
import { UrlProvider } from '../types';
import useLocation from './useLocation';
import type { Path } from '../types';

const useRedirectPath = (redirectParamName = 'redirect') => {
    const [path, setPath] = useState<Path>();
    const location = useLocation();
    const queryParam = getQueryParam<string>(redirectParamName, location);

    const setRedirectPath = useCallback(
        (path?: Path) => {
            setPath(path);
            if (path) {
                setRedirectPathStorage(path);
            } else {
                removeRedirectPathStorage();
            }
        },
        [setPath],
    );

    useEffect(() => {
        if (queryParam) {
            setRedirectPath({
                provider: UrlProvider.External,
                href: resolveUrl(queryParam) as string,
            });
        }
    }, [setRedirectPath, queryParam]);

    useEffect(() => {
        const key = getRedirectPathStorage();
        setPath(key);
    }, []);

    return [path, setRedirectPath] as const;
};

export default useRedirectPath;

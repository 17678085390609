import { Skeleton } from '@lingoda/ui';
import { GroupClassItemLayout, type GroupClassItemLayoutProps } from './GroupClassItemLayout';

interface GroupClassesSkeletonProps {
    count?: number;
}

export const GroupClassesSkeleton = ({ count = 3 }: GroupClassesSkeletonProps) => {
    return (
        <>
            {new Array(count).fill(null).map((_, index) => (
                <GroupClassItemSkeleton key={index} role="listitem" />
            ))}
        </>
    );
};

type GroupClassItemSkeletonProps = Omit<
    GroupClassItemLayoutProps,
    'title' | 'bodyText' | 'bookLoading'
>;

const GroupClassItemSkeleton = (props: GroupClassItemSkeletonProps) => {
    return (
        <GroupClassItemLayout
            title={<Skeleton variant="text" sx={{ maxWidth: 100 }} />}
            bodyText={<Skeleton variant="text" sx={{ maxWidth: 140 }} />}
            bookLoading
            {...props}
        />
    );
};

import { Typography, colorsPalette } from '@lingoda/ui';

interface DayLabelProps {
    label: string;
}

export const DayLabel = ({ label }: DayLabelProps) => {
    return (
        <Typography
            variant="subtitle1"
            textTransform="capitalize"
            htmlColor={colorsPalette.blackAlpha[70]}
            data-cy="booking-list-day-title"
        >
            {label}
        </Typography>
    );
};

import { memo } from 'react';
import { type TimeFormat, formatHour } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { createArrayOfSize } from '@lingoda/utils';
import { ClockIcon16 } from '../../icons/ClockIcon';
import { colorsPalette, styled } from '../../theme';
import { Popper } from '../../utils/Popper';
import { Autocomplete, autocompleteClasses } from '../Autocomplete/Autocomplete';
import type { ChangeEvent } from 'react';

const StyledPopper = styled(Popper)(({ theme: { spacing } }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
        maxHeight: spacing(30),

        [`& .${autocompleteClasses.option}`]: {
            justifyContent: 'center',
            padding: spacing(1, 2),
            marginBottom: spacing(1),
            '&[aria-selected="true"]': {
                backgroundColor: colorsPalette.blackAlpha[8],
                '&.Mui-focused': {
                    backgroundColor: colorsPalette.blackAlpha[4],
                },
            },
            '&.Mui-focused': {
                backgroundColor: colorsPalette.blackAlpha[4],
            },
        },
    },
}));

export interface TimePickerProps {
    format: TimeFormat;
    fullWidth?: boolean;
    maxHour: number;
    minHour: number;
    onChange: (value: number) => void;
    value: number;
    disabled?: boolean;
}

const TimePickerComponent = ({
    disabled,
    format,
    fullWidth = false,
    maxHour,
    minHour,
    onChange,
    value,
}: TimePickerProps) => {
    const handHourChange = (_: ChangeEvent<unknown>, hour: unknown) => {
        onChange(hour as number);
    };

    return (
        <Autocomplete<number>
            sx={{ ...(!fullWidth && { maxWidth: 160 }) }}
            disableClearable
            fullWidth={fullWidth}
            PopperComponent={StyledPopper}
            getOptionDisabled={(hour) => hour < minHour || hour > maxHour}
            getOptionLabel={(hour) => formatHour(Number(hour), format)}
            options={createArrayOfSize(24)}
            popupIcon={<ClockIcon16 color="primary" sx={{ fontSize: '1.143rem' }} />}
            onChange={handHourChange}
            value={value}
            label={trans('time-label', {}, 'public-common')}
            disabled={disabled}
            hideCheckMark
        />
    );
};

export const TimePicker = memo(TimePickerComponent);

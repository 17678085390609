import { useMemo } from 'react';
import type { AchievementFragment, ApolloErrorHandler } from '@lingoda/graphql';
import { useStudentAchievementsQuery } from '@lingoda/graphql';
import { getTopAchievements } from '../utils/getTopAchievements';
import { useAchievementsBadgeLoading } from './useAchievementsBadgeLoading';

interface UseAchievementsProps {
    onError?: ApolloErrorHandler;
}

interface UseAchievements {
    achievements: ReadonlyArray<AchievementFragment>;
    loading: boolean;
}

const useAchievements = ({ onError }: UseAchievementsProps): UseAchievements => {
    const { data, loading } = useStudentAchievementsQuery({
        onError,
    });
    const achievements = useMemo(
        () => data?.studentAchievements ?? [],
        [data?.studentAchievements],
    );

    return {
        achievements,
        loading,
    };
};

type UseAllAchievementsProps = UseAchievementsProps;

export const useAllAchievements = ({ onError }: UseAllAchievementsProps): UseAchievements => {
    const { achievements, loading: loadingData } = useAchievements({ onError });

    const loadingImages = useAchievementsBadgeLoading(achievements);

    return {
        achievements,
        loading: loadingData || loadingImages,
    };
};

interface UseTopAchievementsProps extends UseAchievementsProps {
    isYearlyRecapVisible: boolean;
}

export const useTopAchievements = ({
    onError,
    isYearlyRecapVisible,
}: UseTopAchievementsProps): UseAchievements => {
    const { achievements, loading: loadingData } = useAchievements({ onError });

    const topLimit = isYearlyRecapVisible ? 2 : 3;
    const topAchievements = useMemo(
        () => getTopAchievements(achievements, topLimit),
        [achievements, topLimit],
    );
    const loadingImages = useAchievementsBadgeLoading(topAchievements);

    return {
        achievements: topAchievements,
        loading: loadingData || loadingImages,
    };
};

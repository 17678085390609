type AuthCheck = (url: string) => Promise<unknown>;

let authCheck: AuthCheck;

export const setAuthCheck = (func: AuthCheck) => (authCheck = func);

export const getAuthCheck = async (url: string): Promise<unknown> => {
    if (authCheck) {
        return authCheck(url);
    }
};

export const achievementSupportValues = [
    0, 1, 3, 5, 10, 20, 24, 30, 40, 50, 66, 80, 100, 123, 150, 200, 250, 300, 350, 400, 450, 500,
    600, 700, 800, 900, 1000,
] as const;
export type AchievementSupportValue = Unpacked<typeof achievementSupportValues>;

export enum AchievementSlug {
    ONBOARDING_CHECKLIST = 'onboarding_checklist',
    LT_ONBOARDING_CHECKLIST = 'lt_onboarding_checklist',
    CLASS_ATTENDED_1 = '1_class_attended',
    CLASS_ATTENDED_3 = '3_classes_attended',
    CLASS_ATTENDED_5 = '5_classes_attended',
    CLASS_ATTENDED_10 = '10_classes_attended',
    CLASS_ATTENDED_20 = '20_classes_attended',
    CLASS_ATTENDED_24 = '24_classes_attended',
    CLASS_ATTENDED_30 = '30_classes_attended',
    CLASS_ATTENDED_40 = '40_classes_attended',
    CLASS_ATTENDED_50 = '50_classes_attended',
    CLASS_ATTENDED_66 = '66_classes_attended',
    CLASS_ATTENDED_80 = '80_classes_attended',
    CLASS_ATTENDED_100 = '100_classes_attended',
    CLASS_ATTENDED_123 = '123_classes_attended',
    CLASS_ATTENDED_150 = '150_classes_attended',
    CLASS_ATTENDED_200 = '200_classes_attended',
    CLASS_ATTENDED_250 = '250_classes_attended',
    CLASS_ATTENDED_300 = '300_classes_attended',
    CLASS_ATTENDED_350 = '350_classes_attended',
    CLASS_ATTENDED_400 = '400_classes_attended',
    CLASS_ATTENDED_450 = '450_classes_attended',
    CLASS_ATTENDED_500 = '500_classes_attended',
    CLASS_ATTENDED_600 = '600_classes_attended',
    CLASS_ATTENDED_700 = '700_classes_attended',
    CLASS_ATTENDED_800 = '800_classes_attended',
    CLASS_ATTENDED_900 = '900_classes_attended',
    CLASS_ATTENDED_1000 = '1000_classes_attended',
}

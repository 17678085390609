import { trans } from '@lingoda/i18n';
import { DialogInfo, type DialogInfoProps } from '@lingoda/ui';
import { PrivacyPolicy } from './PrivacyPolicy';

interface Props extends Omit<DialogInfoProps, 'children'> {
    onClose: () => void;
}

export const PrivacyDialog = ({ isOpen, onClose }: Props) => (
    <DialogInfo
        isOpen={isOpen}
        buttonLabel={trans('form-button-close', {}, 'public-common')}
        title={trans('header', {}, 'public-privacy')}
        onClose={onClose}
    >
        <PrivacyPolicy />
    </DialogInfo>
);

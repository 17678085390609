import { clearCacheAction } from '@lingoda/core';
import { type TimeFormat, localeFromFormats, setDefaultLocale } from '@lingoda/dates';
import { studentPreferencesSelector } from '@lingoda/students';
import type { Store } from 'redux';
import type { GlobalState } from '../reducer';

let cachedPreferences: ReturnType<typeof studentPreferencesSelector>;

export default (store: Store<GlobalState>) => {
    const preferences = studentPreferencesSelector(store.getState());

    if (
        (!cachedPreferences && preferences) ||
        (preferences &&
            (cachedPreferences?.weekStart !== preferences.weekStart ||
                cachedPreferences?.timeFormat !== preferences.timeFormat))
    ) {
        const init = !cachedPreferences;
        cachedPreferences = preferences;
        setDefaultLocale(
            localeFromFormats(preferences?.timeFormat as TimeFormat, preferences.weekStart),
        );

        if (!init) {
            store.dispatch(clearCacheAction());
        }
    }
};

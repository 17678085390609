import { forwardRef } from 'react';
import { Button } from '@lingoda/ui';
import { ShortcutControl, type ShortcutControlProps } from '../ShortcutControl';

export type ShortcutButtonProps = Omit<ShortcutControlProps<typeof Button>, 'component'>;

export const ShortcutButton = forwardRef<HTMLButtonElement, ShortcutButtonProps>((props, ref) => {
    return <ShortcutControl component={Button} innerRef={ref} labelFullWidth {...props} />;
});
ShortcutButton.displayName = 'ShortcutButton';

import type { LearningUnit } from '@lingoda/learning-units';
import type { Lesson } from '@lingoda/lessons';
import type { Module } from '@lingoda/modules';
import type { Student } from '@lingoda/students';
import type { Teacher } from '@lingoda/teachers';
import { createRecordFactory } from '@lingoda/utils';
import type { Class } from './class';

export interface ClassExpanded extends Class {
    readonly learningUnit?: LearningUnit;
    readonly lesson?: Lesson;
    readonly module?: Module;
    readonly teacher?: Teacher;
    readonly students?: Student[];
}

export const ClassRecordExpanded = createRecordFactory<ClassExpanded>({
    availableSeats: undefined,
    maxSeats: undefined,
    endDate: undefined,
    id: '',
    learningUnit: undefined,
    lesson: undefined,
    lessonId: undefined,
    module: undefined,
    moduleId: undefined,
    provider: undefined,
    startDate: undefined,
    teacher: undefined,
    teacherId: undefined,
    temporary: undefined,
    type: undefined,
    status: undefined,
    cancelledByGroup: undefined,
    annotatedPresentation: undefined,
});

import { Link, Paper, Stack, Typography, styled, withCursorInteractiveStyles } from '@lingoda/ui';
import { ClockIcon16 } from '@lingoda/ui/icons/ClockIcon';
import { studentChallengePath } from '@lingoda/router';
import { ActivityChallengeParticipantStatusEnum } from '@lingoda/graphql';
import { getChallengeStatus, getChallengeStatusText, getChallengeTexts } from '../../utils';
import { type Prize } from '../../types';
import { getWidgetStyles } from '../../utils/getChallengeStyles';
import { JoinedChallengeBlock } from './JoinedChallengeBlock';
import { UnjoinedChallengeBlock } from './UnjoinedChallengeBlock';

interface Props {
    challengeName: string;
    startDate: string;
    endDate: string;
    userStatus: ActivityChallengeParticipantStatusEnum | undefined;
    prizes: ReadonlyArray<Prize>;
    completedActivities: number;
    challengeIconUrl: string | undefined;
    title: string;
    description: string;
}

export const ActiveChallengeWidget = ({
    challengeName,
    startDate,
    endDate,
    prizes,
    completedActivities,
    userStatus,
    challengeIconUrl,
    title,
    description,
}: Props) => {
    const alreadyJoined = userStatus === ActivityChallengeParticipantStatusEnum.Joined;
    const status = getChallengeStatus(startDate, endDate);
    const statusText = getChallengeStatusText(status, startDate, endDate);
    const { subtitle } = getChallengeTexts(challengeName, startDate);
    const challengeStyles = getWidgetStyles(challengeName, alreadyJoined);

    return (
        <PaperLink
            sx={challengeStyles}
            component={Link}
            to={studentChallengePath({ challengeName })}
        >
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
                    <Stack spacing={1}>
                        <Typography variant="overline" htmlColor={challengeStyles.colors.subtitle}>
                            {subtitle}
                        </Typography>
                        <Typography htmlColor={challengeStyles.colors.title} variant="h3">
                            {title}
                        </Typography>
                        <Stack spacing={0.5} direction="row" color={challengeStyles.colors.status}>
                            <ClockIcon16 />
                            <Typography variant="body3">{statusText}</Typography>
                        </Stack>
                    </Stack>
                    <img src={challengeIconUrl} alt={title} width={64} height={64} />
                </Stack>
                {!alreadyJoined && (
                    <UnjoinedChallengeBlock
                        userStatus={userStatus}
                        challengeName={challengeName}
                        description={description}
                        joiningDisabled={status === 'ended'}
                        descriptionColor={challengeStyles.unjoinedBlock.colors.description}
                        buttonsColor={challengeStyles.unjoinedBlock.colors.buttons}
                    />
                )}
                {alreadyJoined && (
                    <JoinedChallengeBlock
                        challengeName={challengeName}
                        prizes={prizes}
                        completedActivities={completedActivities}
                    />
                )}
            </Stack>
        </PaperLink>
    );
};

const PaperLink = styled(Paper)(({ theme }) =>
    withCursorInteractiveStyles(true)({
        padding: theme.spacing(3),
        textDecoration: 'none',
        color: 'inherit',
        ':hover': {
            color: 'inherit',
        },
    }),
) as typeof Paper; // `as` to retain `component` property

import { type ReactNode } from 'react';
import { colorsPalette, styled } from '@lingoda/ui';
import { ContentBlock } from './ContentBlock';

interface ContentFooterProps {
    children?: ReactNode;
    hidden?: boolean;
}

export const ContentFooter = ({ children, hidden }: ContentFooterProps) => {
    return (
        <ContentBlockStyled sx={{ visibility: hidden ? 'hidden' : undefined }}>
            {children}
        </ContentBlockStyled>
    );
};

const ContentBlockStyled = styled(ContentBlock)(({ theme }) => {
    return {
        width: '100%',
        borderTop: '1px solid',
        borderColor: colorsPalette.blackAlpha[8],
        padding: theme.spacing(3, 0),
        position: 'sticky',
        zIndex: 1,
        bottom: 0,
        backgroundColor: theme.palette.common.white,

        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(4, 0),
        },
    };
});

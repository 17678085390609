import { trans } from '@lingoda/i18n';
import { ToggleButton, ToggleButtonGroup, type ToggleButtonGroupProps } from '@lingoda/ui';
import { VALUE_OTHER } from './utils';
import type { MouseEvent, ReactNode } from 'react';

interface MultiToggleItemType {
    label: ReactNode;
    value: string;
}

export interface MultiToggleButtonProps extends ToggleButtonGroupProps {
    items: ReadonlyArray<MultiToggleItemType>;
    onChange: (_: MouseEvent<HTMLElement>, value: string[]) => void;
    value: string[];
    withOtherItem?: boolean;
}

export const MultiToggleButton = ({ items, withOtherItem, ...props }: MultiToggleButtonProps) => (
    <ToggleButtonGroup {...props}>
        {items.map(({ value, label }, index) => (
            <ToggleButton key={index} value={value}>
                {label}
            </ToggleButton>
        ))}
        {withOtherItem && (
            <ToggleButton value={VALUE_OTHER}>
                {trans('other-comments', {}, 'class-review')}
            </ToggleButton>
        )}
    </ToggleButtonGroup>
);

import { styled } from '../../theme';
import { type BreakPointsAdjustedProp, getBreakPointStyleProp } from '../../utils';
import { Container } from '../Container';

interface FramePageContainerProps {
    pt?: BreakPointsAdjustedProp<number>;
    pb?: BreakPointsAdjustedProp<number>;
    px?: BreakPointsAdjustedProp<number>;
    height?: string;
}

export const FramePageContainer = styled(Container, {
    shouldForwardProp: (prop) => !['pt', 'pb', 'px', 'height'].includes(prop as string),
})<FramePageContainerProps>(({ theme, pt, pb, px = { xs: 2, sm: 3, md: 8 }, height }) => ({
    paddingLeft: getBreakPointStyleProp(px, 'xs', theme.spacing),
    paddingRight: getBreakPointStyleProp(px, 'xs', theme.spacing),
    paddingTop: getBreakPointStyleProp(pt, 'xs', theme.spacing),
    paddingBottom: getBreakPointStyleProp(pb, 'xs', theme.spacing),
    height,
    [theme.breakpoints.up('smn')]: {
        paddingLeft: getBreakPointStyleProp(px, 'smn', theme.spacing),
        paddingRight: getBreakPointStyleProp(px, 'smn', theme.spacing),
        paddingTop: getBreakPointStyleProp(pt, 'smn', theme.spacing),
        paddingBottom: getBreakPointStyleProp(pb, 'smn', theme.spacing),
    },
    [theme.breakpoints.up('sm')]: {
        paddingLeft: getBreakPointStyleProp(px, 'sm', theme.spacing),
        paddingRight: getBreakPointStyleProp(px, 'sm', theme.spacing),
        paddingTop: getBreakPointStyleProp(pt, 'sm', theme.spacing),
        paddingBottom: getBreakPointStyleProp(pb, 'sm', theme.spacing),
    },
    [theme.breakpoints.up('smw')]: {
        paddingLeft: getBreakPointStyleProp(px, 'smw', theme.spacing),
        paddingRight: getBreakPointStyleProp(px, 'smw', theme.spacing),
        paddingTop: getBreakPointStyleProp(pt, 'smw', theme.spacing),
        paddingBottom: getBreakPointStyleProp(pb, 'smw', theme.spacing),
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: getBreakPointStyleProp(px, 'md', theme.spacing),
        paddingRight: getBreakPointStyleProp(px, 'md', theme.spacing),
        paddingTop: getBreakPointStyleProp(pt, 'md', theme.spacing),
        paddingBottom: getBreakPointStyleProp(pb, 'md', theme.spacing),
    },
    [theme.breakpoints.up('lg')]: {
        paddingTop: getBreakPointStyleProp(pt, 'lg', theme.spacing),
        paddingBottom: getBreakPointStyleProp(pb, 'lg', theme.spacing),
    },
    [theme.breakpoints.up('xl')]: {
        paddingTop: getBreakPointStyleProp(pt, 'xl', theme.spacing),
        paddingBottom: getBreakPointStyleProp(pb, 'xl', theme.spacing),
    },
}));

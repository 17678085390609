import { addCallback } from '@lingoda/core';
import { logout } from '@lingoda/auth';
import { isFlashNotificationsEnabled } from '@lingoda/feature-flags';
import {
    hideNotificationsContainer,
    markSeenNotifications,
    showNotification,
    showNotifications,
    showUnSeenNotificationsContainer,
    toggleAllNotificationsContainer,
} from './actions';
import {
    filterVisibleNotifications,
    notificationsShowTypeSelector,
    pendingNotificationsSelector,
} from './selectors';
import { clearSeenNotifications, saveSeenNotifications } from './utils';

let timeoutID: ReturnType<typeof setTimeout>;

export default () => {
    if (isFlashNotificationsEnabled()) {
        addCallback([showNotification, showNotifications], (_action, store) => {
            const allNotifications = pendingNotificationsSelector(store.getState());
            const hasUnseenNotifications =
                filterVisibleNotifications(allNotifications, 'UnSeen').length > 0;

            if (!hasUnseenNotifications) {
                return;
            }

            store.dispatch(showUnSeenNotificationsContainer());
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => {
                store.dispatch(hideNotificationsContainer());
            }, 5000);
        });
    }
    addCallback(toggleAllNotificationsContainer, (_action, store) => {
        const showType = notificationsShowTypeSelector(store.getState());

        if (showType === 'None') {
            store.dispatch(markSeenNotifications());
        }
    });
    addCallback(hideNotificationsContainer, (_action, store) => {
        store.dispatch(markSeenNotifications());
    });
    addCallback(markSeenNotifications, (_action, store) => {
        const allNotifications = pendingNotificationsSelector(store.getState());
        saveSeenNotifications(allNotifications);
    });
    addCallback(logout, () => {
        clearSeenNotifications();
    });
};

import { Box, colorsPalette, styled } from '@lingoda/ui';

export const RegisterFormWrapperStyled = styled(Box)(({ theme }) => ({
    alignContent: 'flex-start',
    background: colorsPalette.whiteSolid[100],
    borderRadius: '30px',
    display: 'grid',
    gridGap: '16px',
    maxWidth: '428px',
    padding: '30px',
    width: '100%',
    [theme.breakpoints.down(991)]: {
        height: '511px',
        maxWidth: '586px',
        position: 'relative',
    },
    [theme.breakpoints.down(588)]: {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        height: '100%',
        marginInline: '-24px',
        padding: '32px 24px 24px',
        width: 'calc(100% + 48px)',
    },
}));

import { type Store } from 'redux';
import { showToast } from '@lingoda/toasts';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { type Messages } from '@lingoda/http';

export const handleHttpMessages = (store: Store, messages: Messages) => {
    getNotificationMessages(messages).forEach((message) => {
        showToast(message.type, message.message);
    });

    getDialogMessages(messages).forEach((dialogMessage) => {
        store.dispatch(showDialog(DialogComponent.GenericInfoMessageDialog, dialogMessage));
    });
};

const messageTitleSeparator = '|';

const getNotificationMessages = (messages: Messages) => {
    if (!messages['']) {
        return [];
    }

    return messages[''].filter((item) => !item.message.includes(messageTitleSeparator));
};

const getDialogMessages = (messages: Messages) => {
    if (!messages['']) {
        return [];
    }

    return messages['']
        .filter((item) => item.message.includes(messageTitleSeparator))
        .map((item) => {
            const [title, message] = item.message.split(messageTitleSeparator);

            return {
                ...item,
                title: title.trim(),
                message: message.trim(),
            };
        });
};

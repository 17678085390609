import { trans } from '@lingoda/i18n';
import { buildValidator } from '@lingoda/forms';

export const validate = buildValidator({
    plainPassword: {
        required: true,
        password: true,
    },
    plainPasswordRe: {
        required: true,
        password: true,
        match: {
            with: 'plainPassword',
            message: trans('form-validation-match-password', {}, 'validators'),
        },
    },
});

import { memo } from 'react';
import type { FieldProps } from '@lingoda/forms';
import { Field } from '@lingoda/forms';
import PaymentMethod from './PaymentMethod';
import type { FC } from 'react';
import type { Props } from './PaymentMethod';

const FormikPaymentMethodProps: FC<{ name: string } & Props> = (props) => (
    <Field {...props}>
        {({ field, form: { setFieldValue } }: FieldProps) => {
            const handleChange = (value?: string) => {
                setFieldValue(field.name, value);
            };

            return <PaymentMethod {...props} onChange={handleChange} />;
        }}
    </Field>
);

export default memo(FormikPaymentMethodProps);

import { handleActionsImmutably } from '@lingoda/core';
import {
    hideNotificationsContainer,
    showAllNotificationsContainer,
    showUnSeenNotificationsContainer,
    toggleAllNotificationsContainer,
} from '../actions';
import type { NotificationsShowType } from '../types';

export type State = NotificationsShowType;

type HideNotificationsContainerAction = ReturnType<typeof hideNotificationsContainer>;

type Payload = HideNotificationsContainerAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${toggleAllNotificationsContainer}`]: (state: State): State =>
            state === 'None' ? 'All' : 'None',
        [`${showAllNotificationsContainer}`]: (): State => 'All',
        [`${showUnSeenNotificationsContainer}`]: (state: State): State =>
            state === 'None' ? 'UnSeen' : state,
        [`${hideNotificationsContainer}`]: (): State => 'None',
    },
    'None',
);

export { default as memoize } from 'lodash/memoize';
export * from './html';
export { default as preventDefault } from './event/preventDefault';
export * from './forms/buildFormErrors';
export * from './objectDiff';
export { default as memoizePromise } from './memoizePromise';
export { default as createRecordFactory } from './record/createRecordFactory';
export * from './storage';
export * from './timers';
export * from './platform';
export * from './validators';
export * from './sort';

export function capitalize(value: string, prefix?: string): string;
export function capitalize(value: boolean, prefix?: string): string;
export function capitalize(value: number, prefix?: string): string;
export function capitalize(
    value: string | boolean | number | undefined,
    prefix?: string,
): string | false {
    switch (typeof value) {
        case 'boolean':
            return prefix || '';
        case 'number':
            return `${prefix}${value}`;
        case 'string':
            const capitalizedText: string = value.charAt(0).toUpperCase() + value.slice(1);
            if (prefix) {
                return `${prefix}${capitalizedText}`;
            } else {
                return capitalizedText;
            }
        default:
            return false;
    }
}

export const createArrayOfSize = <T>(
    size: number,
    callback = (idx: number): T => idx as unknown as T,
): T[] =>
    Array(size)
        .fill(null)
        .map((_, idx) => callback(idx));

export const isFunction = (value: any): value is Function => typeof value === 'function'; // eslint-disable-line

export const isNonNullable = <T>(value: T | null | undefined): value is NonNullable<T> => {
    return value !== null && value !== undefined;
};

export const unifyNullable = <T>(value: T | null | undefined): T | undefined => {
    return value ?? undefined;
};

export function assert(condition: unknown, message: string): asserts condition {
    if (condition) {
        return;
    }

    throw new Error(`Assert failed: ${message}`);
}

export * from './lock';

import { useMemo } from 'react';
import type { SubscriptionCancellationSurveyQuery } from '@lingoda/graphql';
import {
    useSubmitSubscriptionCancellationSurveyMutation,
    useSubscriptionCancellationSurveyQuery,
} from '@lingoda/graphql';
import { cancelCourseChangePath, confirmCancelPath, goTo } from '@lingoda/router';

export const useNextSubscriptionCancellationInTrialAccountSurvey = () => {
    const { data, loading } = useSubscriptionCancellationSurveyQuery();
    const question = data?.subscriptionCancellationSurvey.question;
    const answers = data?.subscriptionCancellationSurvey.answers;
    const reasons = useMemo(() => getCancelSubscriptionReasons(answers || []), [answers]);

    const [submitSurvey] = useSubmitSubscriptionCancellationSurveyMutation();

    const submit = async (
        subscriptionId: number,
        slug: string,
        reason: string,
        canChangeSubscription: boolean,
    ) => {
        await submitSurvey({
            variables: {
                subscriptionId,
                feedbacks: [
                    {
                        slug,
                        reason,
                    },
                ],
            },
        });

        const redirectPath = getCancelSubscriptionRedirect(canChangeSubscription, slug);

        const path = redirectPath({
            subscription: subscriptionId,
            cancelReason: slug,
        });

        goTo(path);
    };

    return { question, reasons, loading, submit } as const;
};

export const getCancelSubscriptionRedirect = (allowChange: boolean, cancelReasonSlug: string) => {
    if (!allowChange) {
        return confirmCancelPath;
    }

    switch (cancelReasonSlug) {
        case CancelNextSubscriptionInTrialAccountReasonSlug.TooExpensive:
        case CancelNextSubscriptionInTrialAccountReasonSlug.SubscribeDifferentProduct:
        case CancelNextSubscriptionInTrialAccountReasonSlug.Other:
            return cancelCourseChangePath;
        default:
            return confirmCancelPath;
    }
};

export enum CancelNextSubscriptionInTrialAccountReasonSlug {
    TooExpensive = 'too-expensive',
    DifficultToBook = 'difficult-to-book',
    NotFlexible = 'not-flexible',
    AccountWrongLanguage = 'account-wrong-language',
    SubscribeDifferentProduct = 'subscribe-different-product',
    Other = 'other',
}

export interface CancelReasons {
    readonly [key: string]: string;
}

export const getCancelSubscriptionReasons = (
    answers: SubscriptionCancellationSurveyQuery['subscriptionCancellationSurvey']['answers'],
) =>
    (answers || []).reduce(
        (sum, current) => ({ ...sum, [current?.slug || '']: current?.answer || '' }),
        {} as CancelReasons,
    );

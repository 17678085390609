import { trans } from '@lingoda/i18n';

interface BookingComboParams {
    content: { title: string; message: string };
    icon: string;
}

export const getBookingComboParams = (bookingCount: number): BookingComboParams => {
    if (bookingCount === 1 || bookingCount > 4) {
        return {
            content: {
                title: trans('way-to-go', {}, 'student-notification'),
                message: trans('class-booked-successfully', {}, 'student-notification'),
            },
            icon: 'tadaCelebration',
        };
    }

    if (bookingCount === 2) {
        return {
            content: {
                title: trans('keep-going', {}, 'student-notification'),
                message: trans('class-booked-successfully', {}, 'student-notification'),
            },
            icon: 'okhand',
        };
    }

    if (bookingCount === 3) {
        return {
            content: {
                title: trans('you-are-on-fire-today', {}, 'student-notification'),
                message: trans('class-booked-successfully', {}, 'student-notification'),
            },
            icon: 'fire',
        };
    }

    return {
        content: {
            title: trans('really-planning-ahead', {}, 'student-notification'),
            message: trans('class-booked-successfully', {}, 'student-notification'),
        },
        icon: 'binoculars',
    };
};

import { type Section, getTranslatedSection } from '@lingoda/sections';
import { capitalize } from '@lingoda/utils';
import type { Module } from './models';

export const getModuleHeading = (module: Module | undefined, section: Section | undefined) => {
    if (!module || !section) {
        return '';
    }

    if (module.cefrLevelName) {
        const language = capitalize(getTranslatedSection(section.name));

        return `${language} ${module.longName}`;
    }

    return module.longName;
};

export const isNotLegacyModule = (expandedModule: Module) => !/old/i.exec(expandedModule.code);

export const moduleIsAvailableForSelection = (module: { code: string }) => {
    return ['Open discussion'].every(
        (ignoredCode) => module.code.toLowerCase() !== ignoredCode.toLowerCase(),
    );
};

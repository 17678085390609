import { useAudioContext } from '@lingoda/audio';
import { type FlashCardProps } from '../components/FlashCard';
import { type Audio } from '../types';

export const useFlipAudioHandler = (
    currentItemAudio: Audio | null | undefined,
    { isDefaultTranslationFace }: { isDefaultTranslationFace: boolean },
) => {
    const { play, stop } = useAudioContext();

    const handleFlipStart: FlashCardProps['onFlipStart'] = () => {
        stop();
    };

    const handleFlipEnd: FlashCardProps['onFlipEnd'] = (isFlipped: boolean) => {
        const isTranslationFace = isDefaultTranslationFace !== isFlipped; // XNOR operation
        const audioField = isTranslationFace ? 'translation' : 'target';
        const audioUrl = currentItemAudio?.[audioField];

        if (audioUrl) {
            play(audioUrl).catch(console.error);
        }
    };

    return { handleFlipStart, handleFlipEnd };
};

import { useCallback } from 'react';
import {
    type UserDisplayedWidgetClassEnum,
    useMarkWidgetAsDisplayedMutation,
} from '@lingoda/graphql';
import { getStorageValue, setStorageValue } from '@lingoda/utils';
import { userIdSelector } from '@lingoda/auth';
import { useSelector } from '@lingoda/hooks';

export const useMarkWidgetAsDisplayed = () => {
    const userId = useSelector(userIdSelector);
    const [markAsDisplayedMutation] = useMarkWidgetAsDisplayedMutation();

    const markAsDisplayed = useCallback(
        (widgetClass: UserDisplayedWidgetClassEnum): void => {
            if (!userId || wasMarked(widgetClass, userId)) {
                return;
            }
            markAsDisplayedMutation({
                variables: {
                    widgetClass,
                },
            })
                .then(() => {
                    saveAsMarked(widgetClass, userId);
                })
                .catch(() => {
                    // do nothing, not critical, we can try once again later
                });
        },
        [markAsDisplayedMutation, userId],
    );

    return {
        markAsDisplayed,
    };
};

const getStorageData = (userId: number): Record<UserDisplayedWidgetClassEnum, boolean> => {
    return (getStorageValue(getKey(userId)) ?? {}) as Record<UserDisplayedWidgetClassEnum, boolean>;
};

const getKey = (userId: number) => {
    return `displayed_widgets_${userId}`;
};

const wasMarked = (widgetClass: UserDisplayedWidgetClassEnum, userId: number): boolean => {
    const data = getStorageData(userId);

    return data.hasOwnProperty(widgetClass);
};

const saveAsMarked = (widgetClass: UserDisplayedWidgetClassEnum, userId: number): void => {
    const key = getKey(userId);
    const data = getStorageData(userId);
    data[widgetClass] = true;
    setStorageValue(key, data);
};

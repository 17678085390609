import merge from 'lodash/merge';
import { handleActionsImmutably } from '@lingoda/core';
import { addLearningUnits } from './actions';
import { LearningUnitFactory } from './models';
import type { LearningUnit } from './models';

export type State = GenericObject<LearningUnit>;

type Action = ReturnType<typeof addLearningUnits>;

export default handleActionsImmutably<State, Action['payload']>(
    {
        [`${addLearningUnits}`]: (state, { payload }) => {
            (payload || []).forEach((learningUnit) => {
                state[learningUnit.id] = merge(
                    state[learningUnit.id],
                    LearningUnitFactory(learningUnit),
                );
            });

            return state;
        },
    },
    {},
);

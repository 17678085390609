import { TypographyAudio } from '@lingoda/audio';
import { WordClass, WordGender } from '@lingoda/quiz-ui/dictionary';
import { Box, Stack, Typography, colorsPalette } from '@lingoda/ui';
import { type GenderType, type VocabularyItemWordClass } from './types';

interface Props {
    audioUrl?: string | null;
    gender?: GenderType | null;
    isAudioEnabled?: boolean;
    onAudioClick?: () => void;
    sampleSentence?: string;
    text: string;
    wordClass?: VocabularyItemWordClass;
}

export const Content = ({
    audioUrl,
    gender,
    isAudioEnabled = false,
    onAudioClick,
    sampleSentence,
    text,
    wordClass,
}: Props) => {
    const hasClassification = wordClass || gender || sampleSentence;

    return (
        <Stack direction="column" alignItems="center" justifyContent="center" flex={1} p={2}>
            {isAudioEnabled && audioUrl ? (
                <TypographyAudio
                    variant="h2"
                    title={text}
                    audioUrl={audioUrl}
                    onClick={onAudioClick}
                    sx={{ textAlign: 'center' }}
                />
            ) : (
                <Typography variant="h2" textAlign="center">
                    {text}
                </Typography>
            )}
            {hasClassification && (
                <Box mt={1} textAlign="center">
                    <Stack
                        component="span"
                        display="inline"
                        divider={<Box component="span">, </Box>}
                    >
                        {wordClass && <WordClass value={wordClass} />}
                        {gender && (
                            <WordGender
                                htmlColor={colorsPalette['blackAlpha'][70]}
                                gender={gender}
                            />
                        )}
                    </Stack>
                    {sampleSentence && (
                        <Typography
                            variant="subtitle1"
                            fontStyle="italic"
                            htmlColor={colorsPalette['blackAlpha'][70]}
                            mt={3}
                            fontWeight={400}
                        >
                            &bdquo;{sampleSentence}&quot;
                        </Typography>
                    )}
                </Box>
            )}
        </Stack>
    );
};

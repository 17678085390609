import { PrivacyDialog, TermsDialog } from '@lingoda/legal';
import { DOMEvent, Typography, colorsPalette, styled } from '@lingoda/ui';
import { Translate } from '@lingoda/i18n';
import { getUrlLang } from '@lingoda/env';
import { useLegal } from '../../../hooks';

export const Legal = () => {
    const {
        handleClosePrivacy,
        handleCloseTerms,
        isPrivacyOpen,
        isPrivacyTerms,
        privacyLinkCallback,
        termsLinkCallback,
    } = useLegal();

    return (
        <TypographyStyled variant="body1">
            <DOMEvent
                events={[
                    {
                        selector: '[rel="termsLink"]',
                        eventType: 'click',
                        callback: termsLinkCallback,
                    },
                    {
                        selector: '[rel="privacyLink"]',
                        eventType: 'click',
                        callback: privacyLinkCallback,
                    },
                ]}
            >
                <Translate
                    id="form-label-signup-terms-and-conditions"
                    params={{
                        imprintHref: `https://www.lingoda.com/${getUrlLang(
                            window.location.href,
                        )}/imprint/`,
                    }}
                    domain="public-common"
                />
            </DOMEvent>
            <TermsDialog isOpen={isPrivacyTerms} onClose={handleCloseTerms} />
            <PrivacyDialog isOpen={isPrivacyOpen} onClose={handleClosePrivacy} />
        </TypographyStyled>
    );
};

const TypographyStyled = styled(Typography)(() => ({
    color: colorsPalette.blackAlpha[70],
    fontSize: '10px',
    lineHeight: '1.4',
    marginBlock: '-1px -4px',
    textAlign: 'left',

    '& a': {
        cursor: 'pointer',
    },
}));

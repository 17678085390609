import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const ConfettiIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon viewBox="0 0 24 24" width={24} height={24} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.702.942a.75.75 0 0 1 .829.662c.205 1.835.03 3.693-.516 5.457a.75.75 0 0 1-1.433-.443c.484-1.567.64-3.217.458-4.847a.75.75 0 0 1 .662-.829ZM16.335 2.215a.75.75 0 0 1 .589.883l-.486 2.43a.75.75 0 1 1-1.471-.294l.486-2.43a.75.75 0 0 1 .882-.589ZM16.94 10.985a13.434 13.434 0 0 1 5.456-.516.75.75 0 0 1-.167 1.49 11.933 11.933 0 0 0-4.847.459.75.75 0 1 1-.443-1.433ZM21.785 7.665a.75.75 0 0 1-.589.882l-2.43.486a.75.75 0 0 1-.294-1.47l2.43-.487a.75.75 0 0 1 .883.589ZM10.604 8.11c-1.056-.353-2.214-.367-2.968.387a2.1 2.1 0 0 0-.58 1.157L1.162 19.989a2.125 2.125 0 0 0 2.848 2.848l10.348-5.895a2.098 2.098 0 0 0 1.145-.579c.754-.753.74-1.911.387-2.967-.364-1.093-1.125-2.234-2.089-3.198-.963-.963-2.104-1.724-3.197-2.089ZM8.697 9.557c-.149.148-.311.573-.025 1.432.274.821.885 1.77 1.726 2.611.842.842 1.79 1.453 2.612 1.727.859.286 1.284.123 1.432-.025.149-.149.312-.574.025-1.433-.273-.821-.885-1.77-1.726-2.611-.842-.841-1.79-1.453-2.612-1.727-.858-.286-1.283-.123-1.432.026ZM2.484 20.699a.625.625 0 0 0 .817.816l8.715-4.965c-.929-.408-1.866-1.076-2.678-1.888-.81-.81-1.478-1.747-1.886-2.674l-4.968 8.711Z"
        />
        <path d="M10 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM21 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM17 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM20 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    </SvgIcon>
);

import { useMutation as useGQLMutation } from '@apollo/client';
import type {
    DocumentNode,
    MutationHookOptions,
    MutationTuple,
    OperationVariables,
    TypedDocumentNode,
} from '@apollo/client';

export const useMutation = <TData = unknown, TVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
    const [mutate, meta] = useGQLMutation<TData, TVariables>(query, options);

    return [mutate, meta];
};

import { type MouseEvent } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import {
    type Achievement,
    useOnboardingAchievementQuery,
    useOnboardingChecklistQuery,
} from '@lingoda/graphql';
import { useDispatch } from '@lingoda/hooks';
import { useIsLearningTeams } from '@lingoda/learning-teams';
import { goTo, studentAchievementsPath } from '@lingoda/router';
import { Loader } from '@lingoda/ui';
import { useClaimReward, useTrackClaimRewardOnboarding } from '../../hooks';
import { getIsClaimable } from '../../utils';
import { AchievementSlug } from '../../models';
import { Layout } from './Layout';

export const OnboardingChecklist = () => {
    const slug = useIsLearningTeams()
        ? AchievementSlug.LT_ONBOARDING_CHECKLIST
        : AchievementSlug.ONBOARDING_CHECKLIST;

    const { data, loading } = useOnboardingAchievementQuery({
        variables: { slug },
    });

    const achievement = data?.studentAchievement;

    if (!achievement || achievement.claimed) {
        return null;
    }

    return loading ? <Loader /> : <OnboardingChecklistInternal achievement={achievement} />;
};

interface Props {
    achievement: Achievement;
}

const OnboardingChecklistInternal = ({ achievement }: Props) => {
    const { claimId, slug } = achievement;

    const dispatch = useDispatch();
    const { trackClaimRewardOnboarding } = useTrackClaimRewardOnboarding();
    const [claimReward, isClaiming] = useClaimReward({
        onCompleted: ({ claimAttendanceAchievement }) => {
            dispatch(
                showDialog(DialogComponent.Achievement, {
                    achievement: claimAttendanceAchievement,
                    checklist,
                    animateClaiming: true,
                    onSeeAllClick: () => goTo(studentAchievementsPath()),
                }),
            );
        },
    });

    const { data, loading: isChecklistLoading } = useOnboardingChecklistQuery({
        variables: { slug },
    });

    const isClaimable = getIsClaimable(achievement);
    const checklist = data?.checklist || [];

    const handleOnClaim = async (event: MouseEvent) => {
        event.stopPropagation();

        if (isClaimable && claimId) {
            if (
                [
                    AchievementSlug.ONBOARDING_CHECKLIST,
                    AchievementSlug.LT_ONBOARDING_CHECKLIST,
                ].includes(slug as AchievementSlug)
            ) {
                trackClaimRewardOnboarding();
            }
            await claimReward(claimId);
        }
    };

    return (
        <Layout
            achievement={achievement}
            checklist={checklist}
            isChecklistLoading={isChecklistLoading}
            isClaiming={isClaiming}
            onClaim={handleOnClaim}
        />
    );
};

import { type StudentGetBookingFeedbackFormQuery } from '@lingoda/graphql';

export type FormState = Record<string, unknown>;

export type BookingExperienceQuestion =
    StudentGetBookingFeedbackFormQuery['bookingExperienceQuestions'][number];

export enum AnswerFieldType {
    multipleChoiceSelect = 'multiple_choice_select',
    toggle = 'toggle',
}

export enum QuestionKeys {
    DidYouFind = 'did_you_find_what_you_were_looking_for',
    WhatYouWereLookingFor = 'what_were_you_looking_for',
    HowSatisfied = 'how_satisfied_are_you_with_the_class_booking_experience',
}

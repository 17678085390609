import { Typography } from '../Typography';
import { styled } from '../../theme';
import type { TypographyProps as TypographyProps } from '../Typography';

const StyledSpan = styled(Typography)({
    display: 'inline-block',
    verticalAlign: 'middle',
    fontWeight: 400,
});

export const Span = ({ variant = 'body3', ...props }: TypographyProps) => (
    <StyledSpan component="span" variant={variant} {...props} />
);

import type { ClassId } from '@lingoda/classes';
import type { ClassType } from '@lingoda/graphql';
import { createAction, makeActionTrackable } from '@lingoda/core';
import type { Date } from '@lingoda/dates';
import type { LessonId } from '@lingoda/lessons';

export type ExistingClassBookParams = {
    id: ClassId;
    startDate: Date;
    type: ClassType;
    stats?: string;
};

export type GeneratedClassBookParams = {
    lessonId: LessonId;
    startDate: Date;
    type: ClassType;
};

export type RequestedClassBookParams = {
    topic?: string;
    lessonId?: LessonId;
    startDate: Date;
    type: ClassType;
    isRequestedByUser: true;
};

export type BookClassActionParams = (
    | ExistingClassBookParams
    | GeneratedClassBookParams
    | RequestedClassBookParams
) & {
    overrideOneClickBooking?: boolean;
    availableSeats?: number;
};

export const bookClass = makeActionTrackable(
    createAction<BookClassActionParams>('booking/BOOK_CLASS'),
);

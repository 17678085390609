import { getStorageValue, setStorageValue } from '@lingoda/utils';

export interface DataShape {
    cj_aid: string;
    cj_pid: string;
    cj_sid?: string;
    cj_exp?: number;
}

const storageKey = 'marketing_data';
export const getCjStorage = () => getStorageValue(storageKey);
export const setCjStorage = (data: Partial<DataShape>) => setStorageValue(storageKey, data);

export const tagKeys: Array<keyof DataShape> = ['cj_aid', 'cj_pid', 'cj_sid', 'cj_exp'];

import { Translate, trans } from '@lingoda/i18n';
import { StepComponent } from './StepComponent';

export const MobileStep1 = () => (
    <StepComponent title={trans('mobile-product-tour-step1-title', {}, 'student-common')}>
        <Translate
            id="mobile-product-tour-step1-description"
            domain="student-common"
            component="div"
        />
    </StepComponent>
);

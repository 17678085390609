// @TODO: split this into small files by feature
import addSectionListener from 'hoc/addSectionListener';
import { lazy } from 'react';
import type { RouteConfigExtended } from '@lingoda/router';
import {
    addRouterListener,
    defaultAppPath,
    finalizeDisconnectPath,
    forgotPasswordPath,
    forgotPasswordSuccessfulPath,
    loginPath,
    referralPath,
    registerPath,
    socialConfirmPath,
    socialLoginConfirmMobilePath,
    socialRegisterConfirmMobilePath,
    socialRegisterPath,
} from '@lingoda/router';
import { studentAuthRoutes } from '@lingoda/student-auth';

const FinalizeDisconnect = lazy(() => import('../components/pages/FinalizeDisconnect'));

const ForgottenPassword = lazy(() => import('../components/pages/ForgottenPassword'));
const ForgottenPasswordSuccessful = lazy(
    () => import('../components/pages/ForgottenPasswordSuccessful'),
);
const Login = lazy(() => import('../components/pages/Login'));
const SocialRegister = lazy(() => import('../components/pages/SocialRegister'));
const SocialConfirm = lazy(() => import('../components/pages/SocialConfirm'));
const SocialMobileConfirm = lazy(() => import('../components/pages/SocialMobileConfirm'));

const ChooseSectionCurriculum = lazy(() => import('../components/pages/ChooseSectionCurriculum'));
const Referral = lazy(() => import('../components/pages/Referral'));

// Make notification system async
// student BE React pure pages

const RouteSocialConfirm = addRouterListener('confirmSocialPath')(SocialConfirm);
const RouteLoginSocialMobileConfirm = addRouterListener('socialLoginConfirmMobilePath')(
    SocialMobileConfirm,
);
const RouteRegisterSocialMobileConfirm = addRouterListener('socialRegisterConfirmMobilePath')(
    SocialMobileConfirm,
);
const RouteSocialRegister = addRouterListener('registerLoginPath')(SocialRegister);
const RouteLogin = addSectionListener(addRouterListener('loginPath')(Login));
const RouteReferral = addSectionListener(addRouterListener('referralPath')(Referral));

const RouteForgottenPassword = addSectionListener(
    addRouterListener('forgotPasswordPath')(ForgottenPassword),
);
const RouteForgottenPasswordSuccessful = addSectionListener(
    addRouterListener('forgotPasswordSuccessfulPath')(ForgottenPasswordSuccessful),
);

const RouteFinalizeDisconnect = addSectionListener(
    addRouterListener('finalizeDisconnectPath')(FinalizeDisconnect),
);

const routes: RouteConfigExtended[] = [
    {
        exact: true,
        label: 'Login',
        path: defaultAppPath.raw,
        component: RouteLogin,
        config: {
            isPublic: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: ChooseSectionCurriculum,
        path: registerPath.raw,
        label: 'ChooseSectionCurriculum',
        config: {
            isPublic: true,
            hideFooter: true,
            theme: 'dark',
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteLogin,
        path: loginPath.raw,
        label: 'Login',
        config: {
            isPublic: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteReferral,
        path: referralPath.raw,
        label: 'Referral',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteSocialRegister,
        path: socialRegisterPath.raw,
        label: 'SocialRegister',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
            navigationElevation: 1,
        },
    },
    {
        exact: true,
        component: RouteSocialConfirm,
        path: socialConfirmPath.raw,
        label: 'ConfirmSocialLogin',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            theme: 'light',
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteLoginSocialMobileConfirm,
        path: socialLoginConfirmMobilePath.raw,
        label: 'ConfirmLoginMobileSocialLogin',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
            disableLogoLink: true,
        },
    },
    {
        exact: true,
        component: RouteRegisterSocialMobileConfirm,
        path: socialRegisterConfirmMobilePath.raw,
        label: 'ConfirmRegisterMobileSocialLogin',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
            disableLogoLink: true,
        },
    },
    {
        exact: true,
        component: RouteForgottenPassword,
        path: forgotPasswordPath.raw,
        label: 'ForgottenPassword',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteForgottenPasswordSuccessful,
        path: forgotPasswordSuccessfulPath.raw,
        label: 'ForgottenPasswordSuccess',
        config: {
            isPublic: true,
            hideFooter: true,
            tab: undefined,
            hideNotifications: true,
        },
    },
    {
        exact: true,
        component: RouteFinalizeDisconnect,
        path: finalizeDisconnectPath.raw,
        label: 'FinalizeDisconnect',
        config: {
            isPublic: true,
            hideFooter: true,
            hideNavigation: true,
            tab: undefined,
            hideNotifications: true,
        },
    },

    ...studentAuthRoutes,
];

export default routes;

import { Box, Stack, Typography } from '@lingoda/ui';
import { usePracticeQuizDashboardQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { showApolloErrorToast } from '@lingoda/toasts';
import { trans } from '@lingoda/i18n';
import { formatIso } from '@lingoda/dates';
import { studentWeekStartDate } from '@lingoda/students';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { EntryComplete, EntryEmpty, EntryPending, EntrySkeleton } from './Entry';
import { Stats } from './Stats';

export const PracticePageQuizDashboard = () => {
    const weekStartDate = useSelector(studentWeekStartDate);

    const { data, loading } = usePracticeQuizDashboardQuery({
        variables: { to: formatIso(weekStartDate) },
        fetchPolicy: 'cache-and-network',
        onError: showApolloErrorToast,
    });

    const progress = data?.quizEngineProgress;
    const lastReviewed = progress?.lastReviewed || undefined;

    const knownDifInPeriod = (progress?.known ?? 0) - (progress?.periodProgress?.known ?? 0);

    return (
        <Box>
            <Typography variant="h3" mb={3}>
                {trans('feature-title', {}, 'quiz-page')}
            </Typography>

            <Grid container spacing={{ xs: 2, md: 3, lg: 5 }}>
                <Grid xs={12} md={7}>
                    {!progress || loading ? (
                        <EntrySkeleton />
                    ) : progress.total < 1 ? (
                        <EntryEmpty />
                    ) : progress.uncompleted < 1 ? (
                        <EntryComplete lastReview={lastReviewed} />
                    ) : (
                        <EntryPending lastReview={lastReviewed} />
                    )}
                </Grid>
                <Grid xs={12} md={5}>
                    <Stack
                        height="100%"
                        spacing={{ xs: 2, sm: 2.5 }}
                        direction={{ xs: 'row', md: 'column' }}
                    >
                        <Stats
                            reviewing={progress?.reviewing}
                            known={progress?.known}
                            knownDifInPeriod={knownDifInPeriod}
                            loading={loading}
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

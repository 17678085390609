import { type ComponentProps, useEffect } from 'react';
import { DialogConfirm, type DialogConfirmProps, GlobalStyles, Paper } from '@lingoda/ui';
import { ContentBlock } from '../../ContentLayout';

export interface DialogStickyProps extends DialogConfirmProps {}

export const DialogSticky = (props: DialogStickyProps) => {
    // Apply custom backdrop that allows to select text but blocks pointer events
    useCustomBackdrop({ container: props.container, open: props.isOpen });

    return (
        <>
            <DialogConfirm
                fullScreen
                componentsProps={dialogUiComponentsProps}
                PaperComponent={DialogPaper}
                disableScrollLock
                hideBackdrop
                disableEnforceFocus
                {...props}
            />

            <GlobalStyles
                styles={{
                    [`.${CONTAINER_CLASS}`]: {
                        pointerEvents: 'none',

                        '.MuiButtonBase-root': {
                            userSelect: 'text',
                        },
                    },

                    [`.${CONTAINER_PARENT_CLASS}`]: {
                        cursor: 'text',
                    },
                }}
            />
        </>
    );
};

const dialogUiComponentsProps = {
    root: {
        sx: {
            top: 'initial',
            position: 'sticky',
            bottom: 0,
            pointerEvents: 'auto',
            cursor: 'auto',
        },
    },
};

const DialogPaper = (props: ComponentProps<typeof Paper>) => {
    return <Paper component={ContentBlock} {...props} />;
};

interface CustomBackdropOptions {
    container: DialogConfirmProps['container'];
    open?: boolean;
}

const useCustomBackdrop = ({ container, open = false }: CustomBackdropOptions) => {
    const portalContainer =
        (typeof container === 'function' ? container() : container) || document.body;

    useEffect(() => {
        if (open) {
            portalContainer.classList.add(CONTAINER_CLASS);

            const parent = portalContainer.parentElement;
            parent?.classList.add(CONTAINER_PARENT_CLASS);

            return () => {
                portalContainer.classList.remove(CONTAINER_CLASS);
                parent?.classList.remove(CONTAINER_PARENT_CLASS);
            };
        }
    }, [portalContainer, open]);
};

const CONTAINER_CLASS = 'dialog-sticky-root';
const CONTAINER_PARENT_CLASS = 'dialog-sticky-parent';

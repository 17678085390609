import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

const ExpandLess: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.5303 15.0305C17.2374 15.3233 16.7626 15.3233 16.4697 15.0305L12 10.5608L7.53033 15.0305C7.23744 15.3233 6.76256 15.3233 6.46967 15.0305C6.17678 14.7376 6.17678 14.2627 6.46967 13.9698L12 8.43946L17.5303 13.9698C17.8232 14.2627 17.8232 14.7376 17.5303 15.0305Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export default ExpandLess;

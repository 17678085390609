import { Box, Button, Img, Paper, Stack, Typography } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { CheckCircle } from '@lingoda/ui/icons/lingodaIcons';
import { subscriptionsStorePath } from '@lingoda/router';
import { useSelector } from '@lingoda/hooks';
import { sectionNameSelector } from '@lingoda/auth';
import { trans } from '@lingoda/i18n';
import zoomScreensImage from './zoomScreens.png';

interface SubscribeUnlockBannerProps {
    withImage?: boolean;
}

export const SubscribeUnlockBanner = ({ withImage = true }: SubscribeUnlockBannerProps) => {
    const sectionName = useSelector(sectionNameSelector);
    const color = colorsPalette.whiteSolid[100];

    const textItems = [
        trans(
            'subscribe-unlock-lessons',
            { language: trans(/* @ignore */ `section-name-${sectionName}`, {}, 'student-store') },
            'student-store',
        ),
        trans('subscribe-unlock-materials', {}, 'student-store'),
        trans('subscribe-unlock-feedback', {}, 'student-store'),
    ];

    return (
        <Paper elevation={1}>
            <BannerGradient>
                {withImage && (
                    <ImageBox>
                        <Img src={zoomScreensImage} width="100%" alt="zoom" />
                    </ImageBox>
                )}
                <Stack flexBasis={withImage ? '65%' : '100%'} spacing={3}>
                    <Typography
                        variant="h2"
                        htmlColor={color}
                        textAlign={{ xs: 'center', md: 'left' }}
                    >
                        {trans(
                            'subscribe-unlock-title',
                            {
                                language: trans(
                                    /* @ignore */ `section-name-${sectionName}`,
                                    {},
                                    'student-store',
                                ),
                            },
                            'student-store',
                        )}
                    </Typography>
                    <Stack spacing={1.5}>
                        {textItems.map((message) => (
                            <Box key={message} display="flex" alignItems="center">
                                <Box
                                    component="span"
                                    alignSelf="flex-start"
                                    display="inline-flex"
                                    flexShrink={0}
                                    mr={2}
                                    color={color}
                                >
                                    <CheckCircle />
                                </Box>
                                <Typography variant="body1" htmlColor={color} display="inline-flex">
                                    {message}
                                </Typography>
                            </Box>
                        ))}
                    </Stack>
                    <Typography
                        variant="h3"
                        htmlColor={color}
                        textAlign={{ xs: 'center', md: 'left' }}
                    >
                        {trans(
                            'subscribe-unlock-promotion',
                            {
                                language: trans(
                                    /* @ignore */ `section-name-${sectionName}`,
                                    {},
                                    'student-store',
                                ),
                            },
                            'student-store',
                        )}
                    </Typography>
                    <Box alignSelf={{ xs: 'stretch', md: 'flex-start' }}>
                        <Button
                            color="primary"
                            variant="contained"
                            inverted
                            href={subscriptionsStorePath()}
                            fullWidth
                        >
                            {trans('subscribe-unlock-subscribe-button', {}, 'student-store')}
                        </Button>
                    </Box>
                </Stack>
            </BannerGradient>
        </Paper>
    );
};

const BannerGradient = styled(Box)(({ theme }) => ({
    position: 'relative',
    background: `linear-gradient(260.96deg, ${colorsPalette.primarySolid[100]} -80.13%, ${colorsPalette.purpleSolid[100]} 100%)`,
    borderRadius: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
        padding: theme.spacing(6, 5, 6, 6),
    },
}));

const ImageBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        flexBasis: '45%',
        marginBottom: 0,
        marginLeft: theme.spacing(1),
    },
}));

import { Button, Paper, Typography, colorsPalette } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { trans } from '@lingoda/i18n';
import juiceGlassImg from '../images/juice-glass.png';
interface PauseRolloverSubscriptionBannerProps {
    onClick: () => void;
}
export const PauseRolloverSubscriptionBanner = ({
    onClick,
}: PauseRolloverSubscriptionBannerProps) => {
    return (
        <Paper
            variant="outlined"
            sx={{
                backgroundImage: {
                    xs: 'linear-gradient(274.88deg, #D6DDFF 4.21%, rgba(203, 210, 244, 0) 116.47%)',
                    md: `url(${juiceGlassImg}), linear-gradient(274.88deg, #D6DDFF 4.21%, rgba(203, 210, 244, 0) 116.47%)`,
                },
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom right',
                border: `1px solid ${colorsPalette.blackAlpha[8]}`,
            }}
        >
            <Grid container p={3}>
                <Grid sm={12} md={10}>
                    <Typography variant="h3" mb={1}>
                        {trans('rollover-pause-take-a-break-banner-title', {}, 'student-common')}
                    </Typography>
                    <Typography variant="body2" mb={3} htmlColor={colorsPalette.blackAlpha[70]}>
                        {trans('rollover-pause-take-a-break-banner-content', {}, 'student-common')}
                    </Typography>
                    <Button color="primary" variant="contained" onClick={onClick}>
                        {trans('rollover-pause-next-subscription-btn', {}, 'student-common')}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

import {
    Button,
    DialogInfo,
    FramePageContainer,
    Portal,
    Select,
    type SelectProps,
    Stack,
    getLingodaClassIcon,
} from '@lingoda/ui';
import { ClassType } from '@lingoda/graphql';
import { useToggleState } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { BookingOverlay } from '../../BookingOverlay';
import { IndividualClassBookingForm } from '../individual/IndividualClassBookingForm';
import { type WidgetClassType } from '../types';

interface Props {
    classType: WidgetClassType;
    onClassTypeChange: (value: WidgetClassType) => void;
    moduleId: number;
    lessonId: number;
    portalContainer?: Element | null;
}

export const BookingWidgetMobile = ({
    classType,
    onClassTypeChange,
    moduleId,
    lessonId,
    portalContainer,
}: Props) => {
    const [isBookingModalOpen, openBookingModal, closeBookingModal] = useToggleState();

    const handleTypeChange: SelectProps<ClassTypeOption>['onChange'] = (event) => {
        onClassTypeChange(event.target.value as WidgetClassType);
    };

    return (
        <Portal container={portalContainer}>
            <FramePageContainer pt={2} pb={2} data-cy="Booking widget">
                <Stack spacing={2}>
                    <Select
                        value={classType}
                        onChange={handleTypeChange}
                        options={typeOptions}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.label}
                        getOptionIcon={(option) => getLingodaClassIcon(option.id, 16)}
                        MenuProps={SelectMenuProps}
                    />
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={openBookingModal}
                        fullWidth
                    >
                        {trans('btn-book-class', {}, 'student-common')}
                    </Button>
                </Stack>
                <BookingOverlay
                    moduleId={moduleId}
                    lessonId={lessonId}
                    open={classType === ClassType.Group && isBookingModalOpen}
                    onClose={closeBookingModal}
                />
                <DialogInfo
                    title={trans('book-individual-class', {}, 'student-common')}
                    isOpen={classType === ClassType.Individual && isBookingModalOpen}
                    onClose={closeBookingModal}
                    isButtonVisible={false}
                >
                    <IndividualClassBookingForm moduleId={moduleId} lessonId={lessonId} />
                </DialogInfo>
            </FramePageContainer>
        </Portal>
    );
};

type ClassTypeOption = {
    id: WidgetClassType;
    label: string;
};

const typeOptions: ClassTypeOption[] = [
    {
        id: ClassType.Group,
        label: trans('group-class', {}, 'student-common'),
    },
    {
        id: ClassType.Individual,
        label: trans('individual-class', {}, 'student-common'),
    },
];

const SelectMenuProps = {
    anchorOrigin: { vertical: 'top', horizontal: 'left' },
    transformOrigin: { vertical: 'bottom', horizontal: 'left' },
} as const;

import { isAfter, isBetween } from '@lingoda/dates';
import { SubscriptionStatus } from './models';
import type { PausedSubscription, Subscription } from './models';

const currentSubscriptionStatuses = [
    SubscriptionStatus.Active,
    SubscriptionStatus.Cancelled,
    SubscriptionStatus.Paused,
];

export function hasCurrentSubscriptionStatus(subscription: Subscription | null) {
    return subscription?.status && currentSubscriptionStatuses.includes(subscription.status);
}

export const isPausedSubscription = (
    subscription?: Subscription,
): subscription is PausedSubscription =>
    Boolean(subscription && subscription.pausedAt && subscription.resumesAt);

export const isDateInsidePausePeriod = (date: Date, subscription: PausedSubscription): boolean =>
    isBetween(subscription.pausedAt, subscription.resumesAt, date);

export const isDateAfterExpiry = (date: Date, subscription: Subscription) =>
    !!subscription?.expiresAt && isAfter(date, subscription.expiresAt);

import { useCallback, useEffect } from 'react';
import { LoadState, PlayState } from './context';
import { useAudioContext } from './useAudioContext';

export const useAudioTrackContext = (audioUrl: string, options: { preload?: boolean } = {}) => {
    const audio = useAudioContext();

    const isTrackActive = audio.currentSrc?.includes(audioUrl);

    const playTrack = useCallback(() => {
        return audio.play.call(null, audioUrl);
    }, [audio.play, audioUrl]);

    const pauseTrack = useCallback(() => {
        if (isTrackActive) {
            audio.pause.call(null);
        }
    }, [audio.pause, isTrackActive]);

    const stopTrack = useCallback(() => {
        if (isTrackActive) {
            audio.stop.call(null);
        }
    }, [audio.stop, isTrackActive]);

    const trackState: TrackState = isTrackActive
        ? {
              loadState: audio.loadState,
              playState: audio.playState,
              isLoading: audio.loadState === LoadState.Loading,
              isPlaying: audio.playState === PlayState.Playing,
          }
        : {
              isLoading: false,
              isPlaying: false,
          };

    useEffect(() => {
        if (options.preload) {
            audio.preload.call(null, audioUrl);
        }
    }, [audioUrl, options.preload, audio.preload]);

    return {
        play: playTrack,
        stop: stopTrack,
        pause: pauseTrack,
        ...trackState,
    };
};

interface TrackState {
    loadState?: LoadState;
    playState?: PlayState;
    isLoading: boolean;
    isPlaying: boolean;
}

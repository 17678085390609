import { type Achievement } from '@lingoda/graphql';
import { AchievementSlug } from '../models';

const SPECIAL_ACHIEVEMENT_SLUGS = [AchievementSlug.ONBOARDING_CHECKLIST];

export const isAchievementSpecial = (achievement: Achievement) => {
    return (
        isAchievementSlug(achievement.slug) && SPECIAL_ACHIEVEMENT_SLUGS.includes(achievement.slug)
    );
};

const isAchievementSlug = (value: string): value is AchievementSlug => {
    return Object.values<string>(AchievementSlug).includes(value);
};

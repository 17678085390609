import type { PaymentMethodsResponse } from '@lingoda/api';
import { handleActionsImmutably } from '@lingoda/core';
import { deletePaymentContract, fetchPaymentMethods } from '../actions';
import type { PaymentMethod } from '../models';

export type State = Readonly<GenericObject<PaymentMethod>>;

type FetchPaymentMethodsAction = ReturnType<typeof fetchPaymentMethods.success>;

export default handleActionsImmutably<State, FetchPaymentMethodsAction['payload']>(
    {
        [`${fetchPaymentMethods.success}`]: (_state, { payload }: FetchPaymentMethodsAction) =>
            (payload.result as PaymentMethodsResponse).data.reduce(
                (newState: GenericObject<PaymentMethod>, method) => {
                    newState[method.id] = method;

                    return newState;
                },
                {},
            ),
        [`${deletePaymentContract.success}`]: (
            state,
            { payload }: ReturnType<typeof deletePaymentContract.success>,
        ) => {
            delete state[payload.payload];

            return state;
        },
    },
    {},
);

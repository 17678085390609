import { memo } from 'react';
import { BoxPerf } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { ClassListItemActions } from './ClassListItemActions';
import type { CommonProps } from '../../types/props';

interface Props extends Pick<CommonProps, 'onBook' | 'onDetails' | 'isEmbedded'> {
    isLessonAttended: boolean | undefined;
    isLessonLocked: boolean;
    disableActions?: boolean;
    showActions: boolean;
    className?: string;
}

const StyledBoxPerf = styled(BoxPerf, {
    shouldForwardProp: (prop) => prop !== 'isEmbedded',
})<{ isEmbedded?: boolean }>(({ theme, isEmbedded }) => ({
    justifyContent: 'flex-end',
    [theme.breakpoints.up(isEmbedded ? 'md' : 'lg')]: {
        width: 180,
    },
}));

const ClassListItemFooterComponent = ({
    onBook,
    onDetails,
    isLessonAttended = false,
    isLessonLocked,
    disableActions,
    showActions,
    className,
    isEmbedded,
}: Props) => (
    <StyledBoxPerf className={className} isEmbedded={isEmbedded}>
        {showActions && (
            <BoxPerf>
                <ClassListItemActions
                    isLessonAttended={isLessonAttended}
                    isLessonLocked={isLessonLocked}
                    onBook={onBook}
                    onDetails={onDetails}
                    disabled={disableActions}
                    isEmbedded={isEmbedded}
                />
            </BoxPerf>
        )}
    </StyledBoxPerf>
);

export const ClassListItemFooter = memo(ClassListItemFooterComponent);

import { useEffect } from 'react';
import { isAuthStatusUnauthenticatedSelector, isAuthStatusUnknownSelector } from '@lingoda/auth';
import { currentCurrencySelector, setCurrency } from '@lingoda/currency';
import { useCreateLegacyPurchaseMutation } from '@lingoda/graphql';
import { useDispatch, useSelector } from '@lingoda/hooks';
import {
    UrlProvider,
    directRegisterSectionPath,
    isPathAttributes,
    redirect,
    useLocation,
    useRedirectPath,
} from '@lingoda/router';
import { useCheckProductEligible } from '@lingoda/subscriptions';
import { useProductVariantSelector } from '@lingoda/products';

const useCreateOrphanPurchase = (productVariantId: number) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const [redirectPath, setRedirectPath] = useRedirectPath();
    const isUnauthenticatedUser = useSelector(isAuthStatusUnauthenticatedSelector);
    const isUnknown = useSelector(isAuthStatusUnknownSelector);
    const currency = useSelector(currentCurrencySelector);
    const productId = useCheckProductEligible(productVariantId);
    const productVariant = useProductVariantSelector(productVariantId);
    const [purchaseLegacy, { data, loading }] = useCreateLegacyPurchaseMutation();
    const purchaseId = data?.purchase.id;

    useEffect(() => {
        if (isUnauthenticatedUser && productVariant?.section?.name) {
            if (
                !isPathAttributes(redirectPath) ||
                (isPathAttributes(redirectPath) && redirectPath.href !== pathname)
            ) {
                setRedirectPath({
                    provider: UrlProvider.External,
                    href: pathname,
                });
            }

            return redirect(directRegisterSectionPath({ section: productVariant.section.name }));
        }

        if (isUnknown || isUnauthenticatedUser || purchaseId || loading || !productId) {
            return;
        }

        if (redirectPath) {
            setRedirectPath(undefined);
        }

        void purchaseLegacy({ variables: { currency, productId } });
    }, [
        currency,
        isUnauthenticatedUser,
        isUnknown,
        loading,
        redirectPath,
        pathname,
        productId,
        productVariant,
        purchaseId,
        purchaseLegacy,
        setRedirectPath,
    ]);

    useEffect(() => {
        if (productVariant) {
            dispatch(setCurrency(productVariant.price.currency));
        }
    }, [dispatch, productVariant]);

    return purchaseId;
};

export default useCreateOrphanPurchase;

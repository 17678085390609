import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWeeklyProgressQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { userCreatedAtSelector } from '@lingoda/auth';
import { studentWeekStartPreference } from '@lingoda/students';
import type { WeekStart } from '@lingoda/dates';
import { WeeklyProgressLayout, type WeeklyProgressLayoutProps } from './WeeklyProgressLayout';
import { getWeeklyProgressReminderText } from './getWeeklyProgressReminderText';

interface WeeklyProgressProps extends LayoutProps {}

type LayoutProps = Pick<WeeklyProgressLayoutProps, 'paperProps'>;

export const WeeklyProgress = (props: WeeklyProgressProps) => {
    const [page, setPage] = useState(0);
    const [currentGoal, setCurrentGoal] = useState<number | null>(null);
    const [reminderText, setReminderText] = useState<string | undefined>(undefined);
    const { data, loading } = useWeeklyProgressQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-and-network',
        variables: {
            page,
        },
    });
    const userRegisterDate = useSelector(userCreatedAtSelector);
    const weekStartsOn = useSelector(studentWeekStartPreference);

    const hasPrevious = data?.studentGoalWidgetData.hasPrevious;
    const hasNext = data?.studentGoalWidgetData.hasNext;

    const handleNavigationLeft = useCallback(() => setPage((page) => page + 1), []);
    const handleNavigationRight = useCallback(() => setPage((page) => page - 1), []);

    useEffect(() => {
        if (page === 0 && !!data && !!userRegisterDate && weekStartsOn !== undefined) {
            setReminderText(
                getWeeklyProgressReminderText(
                    data.activeGoal,
                    data.studentGoalWidgetData,
                    userRegisterDate,
                    weekStartsOn as WeekStart,
                ),
            );
        }
        if (page === 0 && !!data) {
            setCurrentGoal(data?.activeGoal?.value ?? null);
        }
    }, [page, userRegisterDate, weekStartsOn, data]);

    const chartItems = useMemo(
        () =>
            (data?.studentGoalWidgetData.weeklyProgress ?? [])
                .map(({ attendance, goal, shortLabel, longLabel }, index) => ({
                    id: `${page}-${index}`,
                    attendance,
                    goal,
                    shortLabel,
                    longLabel,
                }))
                .reverse(),
        [data?.studentGoalWidgetData.weeklyProgress, page],
    );

    return (
        <WeeklyProgressLayout
            loading={loading && !data}
            currentGoal={currentGoal}
            monthRange={data?.studentGoalWidgetData.range ?? ''}
            page={page}
            chartItems={chartItems}
            onNavigationLeft={hasPrevious ? handleNavigationLeft : undefined}
            onNavigationRight={hasNext ? handleNavigationRight : undefined}
            bubbleSpeechText={reminderText}
            paperProps={props.paperProps}
        />
    );
};

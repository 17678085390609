import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const VideoIcon16 = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 16 16" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 4.5C2 4.08579 2.33579 3.75 2.75 3.75H8.75C10.2688 3.75 11.5 4.98122 11.5 6.5V11.5C11.5 11.9142 11.1642 12.25 10.75 12.25H4.75C3.23122 12.25 2 11.0188 2 9.5V4.5ZM3.5 5.25V9.5C3.5 10.1904 4.05964 10.75 4.75 10.75H10V6.5C10 5.80964 9.44036 5.25 8.75 5.25H3.5Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.8874 4.89517C14.0598 4.79365 14.2613 4.74056 14.4705 4.75138C14.6797 4.7622 14.8731 4.83565 15.0325 4.95135C15.1907 5.06616 15.3085 5.21695 15.3856 5.37945C15.4625 5.5417 15.5 5.71756 15.5 5.89153V10.1083C15.5 10.2822 15.4624 10.4581 15.3855 10.6204C15.3085 10.7827 15.1909 10.9333 15.0329 11.0481C14.8738 11.1638 14.6807 11.2374 14.4718 11.2485C14.2626 11.2597 14.0609 11.2069 13.8882 11.1056L12.3701 10.2138C12.013 10.004 11.8935 9.5444 12.1033 9.18725C12.3131 8.83011 12.7727 8.71066 13.1299 8.92046L14 9.43161V6.56871L13.1299 7.07986C12.7727 7.28966 12.3131 7.17022 12.1033 6.81307C11.8935 6.45592 12.013 5.99631 12.3701 5.78651L13.8874 4.89517Z"
            />
        </SvgIcon>
    );
};

export const VideoIcon24 = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 6.75C1.25 6.33579 1.58579 6 2 6H13C14.5188 6 15.75 7.23122 15.75 8.75V17.75C15.75 18.1642 15.4142 18.5 15 18.5H4C2.48122 18.5 1.25 17.2688 1.25 15.75V6.75ZM2.75 7.5V15.75C2.75 16.4404 3.30964 17 4 17H14.25V8.75C14.25 8.05964 13.6904 7.5 13 7.5H2.75Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7498 8.11478C20.9538 8.03061 21.1738 7.99317 21.3907 8.00102C21.6077 8.00888 21.8254 8.06226 22.0237 8.16303C22.2221 8.2638 22.4033 8.41344 22.5367 8.61047C22.6713 8.80906 22.7501 9.04625 22.75 9.29815V15.2016C22.75 15.4535 22.6711 15.6907 22.5366 15.8892C22.4032 16.0861 22.2222 16.2356 22.0241 16.3365C21.8259 16.4373 21.6085 16.4908 21.3916 16.4989C21.1747 16.507 20.9548 16.4699 20.7506 16.386L17.7148 15.1376C17.3317 14.9801 17.1488 14.5418 17.3064 14.1588C17.4639 13.7757 17.9021 13.5928 18.2852 13.7504L21.25 14.9695V9.53096L18.2852 10.7501C17.9021 10.9076 17.4639 10.7248 17.3064 10.3417C17.1488 9.9586 17.3317 9.52034 17.7148 9.36281L20.7498 8.11478Z"
            />
        </SvgIcon>
    );
};

export const VideoIconGradient24 = (props: SvgIconProps) => {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.25 7.41669C1.25 7.00247 1.58579 6.66669 2 6.66669H13C14.5188 6.66669 15.75 7.8979 15.75 9.41669V18.4167C15.75 18.8309 15.4142 19.1667 15 19.1667H4C2.48122 19.1667 1.25 17.9355 1.25 16.4167V7.41669ZM2.75 8.16669V16.4167C2.75 17.107 3.30964 17.6667 4 17.6667H14.25V9.41669C14.25 8.72633 13.6904 8.16669 13 8.16669H2.75Z"
                fill="url(#paint0_linear_1831_16942)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7498 8.78146C20.9538 8.6973 21.1738 8.65985 21.3907 8.66771C21.6077 8.67557 21.8254 8.72894 22.0237 8.82971C22.2221 8.93049 22.4033 9.08013 22.5367 9.27716C22.6713 9.47575 22.7501 9.71294 22.75 9.96484V15.8683C22.75 16.1202 22.6711 16.3574 22.5366 16.5559C22.4032 16.7528 22.2222 16.9023 22.0241 17.0032C21.8259 17.104 21.6085 17.1575 21.3916 17.1656C21.1747 17.1737 20.9548 17.1365 20.7506 17.0527L17.7148 15.8043C17.3317 15.6468 17.1488 15.2085 17.3064 14.8254C17.4639 14.4424 17.9021 14.2595 18.2852 14.417L21.25 15.6362V10.1976L18.2852 11.4168C17.9021 11.5743 17.4639 11.3915 17.3064 11.0084C17.1488 10.6253 17.3317 10.187 17.7148 10.0295L20.7498 8.78146Z"
                fill="url(#paint1_linear_1831_16942)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1831_16942"
                    x1="1.25"
                    y1="19.1667"
                    x2="34.6635"
                    y2="12.908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9900CC" />
                    <stop offset="1" stopColor="#153BFF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1831_16942"
                    x1="1.25"
                    y1="19.1667"
                    x2="34.6635"
                    y2="12.908"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9900CC" />
                    <stop offset="1" stopColor="#153BFF" />
                </linearGradient>
            </defs>
        </SvgIcon>
    );
};

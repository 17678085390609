import { createContext, useContext, useMemo, useState } from 'react';
import { MIN_HEIGHT_HEADER } from '@lingoda/ui/theme/common';

export interface HeaderContextProps {
    linkColor: string;
    visibleLinks: string;
    backgroundColor: string;
    height: number;
    sticky: boolean;
    rawHeight: number;
    setHeight?: (height: number) => void;
    setHeaderContext: (headerContext: Omit<HeaderContextProps, 'setHeaderContext'>) => void;
}

export const initialHeaderContext = {
    linkColor: '',
    visibleLinks: '',
    backgroundColor: '',
    height: MIN_HEIGHT_HEADER,
    rawHeight: MIN_HEIGHT_HEADER,
    sticky: false,
    setHeight: undefined,
};

export const useHeaderContextValue = () => {
    const [headerContext, setHeaderContext] =
        useState<Omit<HeaderContextProps, 'setHeaderContext'>>(initialHeaderContext);

    const contextValue = useMemo(() => {
        return { ...headerContext, setHeaderContext };
    }, [headerContext, setHeaderContext]);

    return contextValue;
};

export const HeaderContext = createContext<HeaderContextProps>({
    ...initialHeaderContext,
    setHeaderContext: () => undefined,
});

export const useHeaderContext = () => useContext(HeaderContext);

import { trans } from '@lingoda/i18n';
import {
    Box,
    Button,
    GradientButton,
    ProgressBar,
    Stack,
    Typography,
    colorsPalette,
} from '@lingoda/ui';
import { ButtonResizeTheme, ContentBody, ContentFooter } from '@lingoda/quiz-ui';
import CongratulationsIllustration from './CongratulationsIllustration.svg?component';

interface Props {
    loading?: boolean;
    onDone: () => void;
    onReset: () => void;
    totalWords: number;
}

export const Congratulations = ({ loading, onDone, onReset, totalWords }: Props) => {
    return (
        <>
            <ContentBody alignItems="center" justifyContent="center">
                <Box mb={3}>
                    <CongratulationsIllustration />
                </Box>
                <Typography mb={1} variant="h1">
                    {trans('flashcards-congratulations', {}, 'student-common')}
                </Typography>
                <Typography
                    htmlColor={colorsPalette.blackAlpha[70]}
                    fontSize={{ xs: '1rem', sm: '1.25rem' }}
                    lineHeight="1.75rem"
                    marginBottom={4}
                    textAlign="center"
                    variant="body2"
                >
                    {trans('you-have-learnt-everything', {}, 'student-common')}
                </Typography>
                <Typography
                    color={colorsPalette.blackAlpha[70]}
                    fontSize={{ xs: '1rem', sm: '1.25rem' }}
                    lineHeight="1.5rem"
                    marginBottom={1.25}
                    variant="body2"
                >
                    {trans(
                        'flashcards-mastered',
                        {
                            correctWordsInTotal: totalWords,
                            totalWords,
                        },
                        'student-common',
                    )}
                </Typography>
                <Box width="100%">
                    <ProgressBar
                        variant="determinate"
                        value={100}
                        size="medium"
                        sx={{ mx: 'auto' }}
                    />
                </Box>
            </ContentBody>

            <ContentFooter>
                <ButtonResizeTheme>
                    <Stack width="100%" spacing={3} direction={{ xs: 'column-reverse', sm: 'row' }}>
                        <Button
                            color="grayscale"
                            fullWidth
                            loading={loading}
                            onClick={onReset}
                            variant="outlined"
                        >
                            {trans('flashcards-reset', {}, 'student-common')}
                        </Button>
                        <GradientButton fullWidth loading={loading} onClick={onDone}>
                            {trans('flashcards-done', {}, 'student-common')}
                        </GradientButton>
                    </Stack>
                </ButtonResizeTheme>
            </ContentFooter>
        </>
    );
};

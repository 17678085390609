import { Stack, Typography, colorsPalette, styled } from '@lingoda/ui';
import { format } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
interface SelectionElementProps {
    startDate: Date;
    endDate: Date;
    label: string;
    isSelected?: boolean;
    onClick: () => void;
}
export const SelectionElement = ({
    startDate,
    endDate,
    label,
    isSelected,
    onClick,
}: SelectionElementProps) => {
    return (
        <Wrapper onClick={onClick} isSelected={isSelected}>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" whiteSpace="nowrap">
                    {label}
                </Typography>
                <Typography
                    variant="body1"
                    htmlColor={
                        isSelected ? colorsPalette.primarySolid[100] : colorsPalette.blackAlpha[70]
                    }
                >
                    {trans(
                        'from-until',
                        { startDate: formatDate(startDate), endDate: formatDate(endDate) },
                        'public-common',
                    )}
                </Typography>
            </Stack>
        </Wrapper>
    );
};

const Wrapper = styled('button', { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
    isSelected?: boolean;
}>(({ isSelected, theme }) => ({
    backgroundColor: isSelected ? colorsPalette.primaryAlpha[5] : 'initial',
    borderColor: isSelected ? colorsPalette.primarySolid[100] : 'transparent',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: theme.spacing(1),
    color: isSelected ? colorsPalette.primarySolid[100] : 'inherit',
    boxShadow: isSelected ? `none` : `inset 0px 0px 0px 1px ${colorsPalette.blackAlpha[20]}`,
    transition: 'none',
    cursor: 'pointer',
    padding: `${theme.spacing(2.5)} ${theme.spacing(2)}`,
}));

const formatDate = format('ccc, dd MMM');

import { useMemo } from 'react';
import { AppointmentStatus } from '@lingoda/graphql';
import { format } from '@lingoda/dates';
import { BoxPerf, Grid, Hidden } from '@lingoda/ui';
import { styled } from '@lingoda/ui/theme';
import { ClassListItem } from '../ClassListItem/ClassListItem';
import { ClassListItemFooter } from '../ClassListItem/ClassListItemFooter';
import { ClassListItemHeader } from '../ClassListItem/ClassListItemHeader';
import { ClassListItemInfo } from '../ClassListItem/ClassListItemInfo';
import { ClassListItemParticipants } from '../ClassListItem/ClassListItemParticipants';
import { ClassListItemStatus } from '../ClassListItem/ClassListItemStatus';
import { getBookClassElementProps } from './helpers';
import type { CommonProps } from '../../types/props';

export const ListLayout = ({
    position,
    onDetails,
    klass,
    isUpcoming,
    onBook,
    isEmbedded,
    disableBookClass,
    linkTo,
    openInNewTab,
    isPreview,
}: CommonProps) => {
    const { lesson } = klass;
    const { subtitle, lessonAttended, lessonLocked, isBooked, studentAvatars, chapterName } =
        useMemo(() => getBookClassElementProps(klass), [klass]);

    return (
        <BoxPerf>
            <ClassListItem
                position={position}
                linkTo={linkTo}
                isPreview={isPreview}
                openInNewTab={openInNewTab}
            >
                <ClassListItemHeader>{format('p', klass.startDate)}</ClassListItemHeader>
                <BoxPerf className="display-flex flex-1 justify-content-space-between">
                    <ContentWrapper isEmbedded={isEmbedded}>
                        {lesson && klass.module && (
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <ClassListItemInfo
                                        lessonTitle={lesson.title || ''}
                                        chapterName={chapterName}
                                        moduleName={klass.module.name}
                                        lessonLabel={lesson.label}
                                    />
                                </Grid>
                                {isBooked && (
                                    <Hidden lgDown>
                                        <Grid item>
                                            <ClassListItemStatus
                                                isPast={false}
                                                status={AppointmentStatus.Active}
                                                isUpcoming={isUpcoming}
                                            />
                                        </Grid>
                                    </Hidden>
                                )}
                            </Grid>
                        )}
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <ClassListItemParticipants
                                    classTypeInfo={subtitle}
                                    teacher={
                                        klass.teacher?.firstName
                                            ? {
                                                  firstName: klass.teacher.firstName,
                                                  src: klass.teacher.photo?.urls.medium,
                                              }
                                            : undefined
                                    }
                                    avatars={studentAvatars}
                                />
                            </Grid>
                            {isBooked && (
                                <Hidden lgUp>
                                    <Grid item>
                                        <ClassListItemStatus
                                            isPast={false}
                                            status={AppointmentStatus.Active}
                                            isUpcoming={isUpcoming}
                                        />
                                    </Grid>
                                </Hidden>
                            )}
                        </Grid>
                    </ContentWrapper>
                    {!isPreview && (
                        <ClassListItemFooter
                            isLessonAttended={lessonAttended}
                            isLessonLocked={lessonLocked}
                            onDetails={onDetails}
                            onBook={onBook}
                            disableActions={disableBookClass}
                            showActions={!isBooked}
                            isEmbedded={isEmbedded}
                        />
                    )}
                </BoxPerf>
            </ClassListItem>
        </BoxPerf>
    );
};

const ContentWrapper = styled(BoxPerf, { shouldForwardProp: (prop) => prop !== 'isEmbedded' })<{
    isEmbedded?: boolean;
}>(({ theme, isEmbedded }) => ({
    paddingLeft: theme.spacing(1),
    display: 'flex',
    rowGap: theme.spacing(1),
    columnGap: theme.spacing(2),
    flexDirection: 'column',
    [theme.breakpoints.up(isEmbedded ? 'md' : 'lg')]: {
        flexDirection: 'row',
        flex: 1,
    },
    '& > *': {
        [theme.breakpoints.up(isEmbedded ? 'md' : 'lg')]: {
            flex: 1,
        },
    },
}));

import type QueryString from 'qs';

export const queryParamsDecoder: NonNullable<QueryString.IParseOptions['decoder']> = (str) => {
    if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
    }

    const keywords = {
        true: true,
        false: false,
        null: null,
        undefined: undefined,
    };

    if (str in keywords) {
        return keywords[str as keyof typeof keywords];
    }

    try {
        return decodeURIComponent(str);
    } catch (e) {
        return str;
    }
};

import { Grid, Portal, useResolution } from '@lingoda/ui';
import { AppointmentStatus, ClassType } from '@lingoda/graphql';
import { createDate } from '@lingoda/dates';
import { unifyNullable } from '@lingoda/utils';
import { type ExistingClassData } from '../../../types';
import ClassTime from './ClassTime';
import { Participants } from './Participants';
import Title from './Title';
import { ClassStatusFinished, getVariant } from './ClassStatusHeader/ClassStatusFinished';
import { TileBody } from './TileBody';
import type { AppointmentData } from '../types';

export interface PostClassDetailsProps {
    portalContainers?: { status?: Element | null; cta?: Element | null };
    classItem: ExistingClassData;
}

export const PostClassDetails = ({ portalContainers, classItem }: PostClassDetailsProps) => {
    const { appointment } = classItem;
    const isInPortal = useResolution('phone-large');
    const { startDate: startDateRaw, endDate: endDateRaw } = classItem;
    const startDate = createDate(startDateRaw);
    const endDate = createDate(endDateRaw);
    const isGroupClass = classItem.type === ClassType.Group;

    const { showParticipants, status } = getAppointmentUi(unifyNullable(appointment));

    return (
        <>
            <Portal container={portalContainers?.status} disablePortal={!isInPortal}>
                {status}
            </Portal>

            <TileBody>
                <Grid container>
                    <Title teacher={classItem.teacher} isGroupClass={isGroupClass} />
                    <ClassTime startDate={startDate} endDate={endDate} />
                    {showParticipants && (
                        <Participants participants={classItem.students} isBeforeClass={false} />
                    )}
                </Grid>
            </TileBody>
        </>
    );
};

const getAppointmentUi = (appointment?: AppointmentData) => {
    const showParticipants =
        appointment &&
        ![AppointmentStatus.Cancelled, AppointmentStatus.Missed].includes(appointment.status);

    const statusVariant = getVariant(appointment);
    const status = <ClassStatusFinished variant={statusVariant} />;

    return {
        showParticipants: !!showParticipants,
        status,
    };
};

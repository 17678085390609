import { createSelectorCreator, defaultMemoize } from 'reselect';
import type { Date } from '@lingoda/dates';
import { getDate, isSameDay, set } from '@lingoda/dates';
import { timeSelector } from './state';

const createEqualMomentDaySelector = createSelectorCreator(
    defaultMemoize,
    (a: unknown, b: unknown) => isSameDay(b as Date, a as Date),
);

export const selectorFactory = () =>
    createEqualMomentDaySelector(timeSelector as () => Date, (now) =>
        set({ date: getDate(now), hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }, now),
    );

export default selectorFactory();

import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Box, type BoxProps } from '@lingoda/ui';
import { useHeaderContext } from '@lingoda/contexts';

interface Props {
    stickySx?: BoxProps['sx'];
    children?: ReactNode;
    onStickyHeightChange?: (stickyHeight: number) => void;
}

export const Sticky = ({ children, stickySx, onStickyHeightChange }: Props) => {
    const [stickyHeight, setStickyHeight] = useState(0);
    const [sticky, setSticky] = useState(false);
    // used to track height of sticky content
    const stickyEl = useRef<HTMLDivElement>(null);
    const headerTheme = useHeaderContext();

    const observer = useRef<IntersectionObserver>();
    const [ref, setRef] = useState<HTMLDivElement | null>();

    const onObserverChanged = useCallback((changedSticky: boolean) => {
        setSticky(changedSticky);

        const newHeight = stickyEl?.current?.offsetHeight;
        if (newHeight) {
            setStickyHeight(newHeight);
        }
    }, []);

    useEffect(() => {
        onStickyHeightChange?.(stickyHeight);
    }, [stickyHeight, onStickyHeightChange]);

    useEffect(() => {
        if (!ref) {
            return;
        }

        observer.current = new IntersectionObserver(
            (entries) => {
                if (!entries[0].isIntersecting) {
                    onObserverChanged(true);
                } else {
                    onObserverChanged(false);
                }
            },
            { rootMargin: `-${headerTheme.height}px` },
        );

        observer.current.observe(ref);

        return () => observer.current?.disconnect();
    }, [ref, headerTheme, onObserverChanged]);

    return (
        <>
            <div ref={setRef} />
            <Box style={{ minHeight: sticky ? stickyHeight : 0 }} />
            <Box
                ref={stickyEl}
                sx={{
                    top: headerTheme.height,
                    ...(sticky && {
                        zIndex: 10,
                        position: 'fixed',
                        right: 0,
                        left: 0,
                        ...stickySx,
                    }),
                }}
            >
                {children}
            </Box>
        </>
    );
};

import { SvgIcon } from '../layout/SvgIcon';
import { colorsPaletteThirdParty } from '../theme';
import type { SvgIconProps } from '../layout/SvgIcon';
import type * as React from 'react';

const AppleIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        viewBox="0 0 16 16"
        htmlColorDefault={colorsPaletteThirdParty.appleSolid[100]}
        {...props}
    >
        <path d="M14.528 12.469a8.704 8.704 0 0 1-.86 1.546c-.453.645-.823 1.092-1.108 1.34-.443.407-.917.615-1.425.627-.364 0-.803-.104-1.315-.314-.513-.21-.985-.313-1.416-.313-.453 0-.938.103-1.457.313-.52.21-.938.32-1.258.33-.487.021-.972-.193-1.456-.643-.31-.27-.696-.732-1.16-1.387a9.591 9.591 0 0 1-1.225-2.434c-.343-.999-.515-1.966-.515-2.902 0-1.072.232-1.997.696-2.772a4.082 4.082 0 0 1 1.457-1.474 3.92 3.92 0 0 1 1.97-.556c.387 0 .894.12 1.524.355.629.236 1.032.355 1.209.355.132 0 .58-.14 1.34-.418.719-.259 1.325-.366 1.822-.324 1.346.109 2.357.64 3.03 1.595-1.204.73-1.8 1.752-1.788 3.062.011 1.021.382 1.87 1.11 2.545.33.313.698.555 1.107.727-.088.258-.182.505-.282.742ZM11.441.32c0 .8-.293 1.547-.875 2.239-.704.822-1.554 1.297-2.476 1.222a2.491 2.491 0 0 1-.019-.303c0-.768.334-1.59.928-2.263.297-.34.674-.623 1.131-.849.456-.222.888-.345 1.294-.366.012.107.017.214.017.32Z" />
    </SvgIcon>
);

export default AppleIcon;

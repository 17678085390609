import { createAction, makeActionTrackable } from '@lingoda/core';
import type { SectionName } from '@lingoda/sections';
import type { Module, StartingModule } from './models';

interface ModulesQueryParams {
    curriculumId: number;
    section: SectionName;
}

const FETCH_MODULES = 'module/FETCH_MODULES';

const actionCreator = (query?: ModulesQueryParams) => ({
    type: FETCH_MODULES,
    payload: { query },
});
actionCreator.toString = () => FETCH_MODULES;

export const addLearningUnitIdsToModule = createAction(
    'module/ADD_LEARNING_UNIT_IDS',
    (moduleId: number, learningUnitIds: number[]) => ({
        moduleId,
        learningUnitIds,
    }),
);
export const addModules = createAction<Module[]>('store/ADD_MODULES');
export const fetchModules = makeActionTrackable(actionCreator);
export const fetchStudentModules = makeActionTrackable(
    createAction('module/FETCH_STUDENT_MODULES'),
);
export const clearModules = createAction('module/CLEAR_STUDENT_MODULES');
export const fetchStartingModules = makeActionTrackable(
    createAction('module/FETCH_STARTING_MODULES'),
);
export const addStartingModules = createAction<StartingModule[]>('store/ADD_STARTING_MODULES');

export const setCurriculumModules = createAction<{ curriculumId: number; moduleIds: number[] }>(
    'curriculumModules/SET_CURRICULUM_MODULES',
);

import { type MouseEvent } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useDispatch } from '@lingoda/hooks';
import {
    Box,
    Img,
    Stack,
    Typography,
    colorsPalette,
    styled,
    withCursorInteractiveStyles,
} from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import year2023Svg from './yearly-recap-2023.svg';

interface Props {
    component?: React.ElementType;
}

export const RecapAchievementItem = ({ component }: Props) => {
    const dispatch = useDispatch();

    const handleDialogOpen = (event: MouseEvent) => {
        event.stopPropagation();

        dispatch(showDialog(DialogComponent.YearlyRecapDialog));
    };

    return (
        <AchievementItemContainer
            component={component}
            data-cy="Recap achievement"
            onClick={handleDialogOpen}
            isClickable
        >
            <Stack direction="row" spacing={2} width="100%">
                <ImgContainer>
                    <Img src={year2023Svg} width="64px" />
                </ImgContainer>
                <DetailsStack spacing={1}>
                    <Typography
                        variant="subtitle1"
                        sx={{ wordBreak: 'break-word' }}
                        htmlColor={colorsPalette.blackSolid[100]}
                    >
                        {trans('your-wrap-up', {}, 'student-yearly-recap')}
                    </Typography>
                    <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                        {trans('unwrap-highlights', {}, 'student-yearly-recap')}
                    </Typography>
                </DetailsStack>
            </Stack>
        </AchievementItemContainer>
    );
};

const AchievementItemContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isClickable',
})<{ isClickable: boolean }>(({ theme, isClickable }) =>
    withCursorInteractiveStyles(isClickable)({
        display: 'flex',
        padding: theme.spacing(1, 3),
        color: colorsPalette.blackAlpha[70],
    }),
);

const ImgContainer = styled(Box)({
    width: 64,
    height: 64,
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
});

const DetailsStack = styled(Stack)(({ theme }) => ({
    flex: '1 1 auto',
    padding: theme.spacing(0.5, 0),
}));

import { useCallback, useEffect, useMemo, useState } from 'react';
import { actionFilter } from '@lingoda/analytics';
import { sectionNameSelector } from '@lingoda/auth';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { fetchLearningUnits, lessonsByModulesIdSelector } from '@lingoda/learning-units';
import { fetchModules, sortedModulesSelector } from '@lingoda/modules';
import { studentCurriculumIdSelector, studentModuleIdSelector } from '@lingoda/students';
import { Grid, Select, useUiEvent } from '@lingoda/ui';
import type { MenuProps as MuiMenuProps, SelectProps } from '@lingoda/ui';
import {
    lessonsBookingFilterSelector,
    moduleBookingFilterSelector,
    setFilter,
} from '@lingoda/booking';
import { FilterLabel } from './FilterLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps: Partial<MuiMenuProps> = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
        },
    },
};

interface Props {
    inline?: boolean;
    disabled?: boolean;
}

const fetchedModules: number[] = [];

export const ModuleFilter = ({ inline, disabled }: Props) => {
    const addEvent = useUiEvent();
    const dispatch = useDispatch();
    const modules = useSelector(sortedModulesSelector);
    const moduleId = useSelector(studentModuleIdSelector);
    const section = useSelector(sectionNameSelector);
    const curriculumId = useSelector(studentCurriculumIdSelector);
    const value = useSelector(moduleBookingFilterSelector);
    const [moduleState, setModuleState] = useState<number[] | undefined>(undefined);
    const lessons = useSelector(lessonsBookingFilterSelector);
    const lessonModules = useSelector(lessonsByModulesIdSelector(() => moduleState));
    const currentModules = useMemo(() => moduleState || value, [moduleState, value]);

    useEffect(() => {
        if (section && curriculumId) {
            dispatch(fetchModules({ section, curriculumId }));
        }
    }, [dispatch, curriculumId, section]);

    useEffect(() => {
        if (currentModules) {
            currentModules.forEach((moduleId) => {
                if (!fetchedModules.includes(moduleId)) {
                    fetchedModules.push(moduleId);
                    dispatch(fetchLearningUnits([moduleId]));
                }
            });
        }
    }, [currentModules, dispatch]);

    const handleModuleChange: SelectProps['onChange'] = useCallback(
        (e) => {
            const newValue = e.target.value as number[];

            setModuleState(newValue.length > 0 ? newValue : moduleId ? [moduleId] : []);
        },
        [moduleId],
    );

    const handleModuleClose = useCallback(() => {
        if (moduleState) {
            const filteredLesson =
                lessons && inline ? lessons.filter((item) => lessonModules.includes(item)) : [];
            void addEvent(actionFilter('Module'), {
                value: moduleState.join(','),
            });
            setModuleState(undefined);
            dispatch(
                setFilter({
                    modules: moduleState,
                    lessons: filteredLesson,
                }),
            );
        }
    }, [dispatch, setModuleState, inline, moduleState, addEvent, lessons, lessonModules]);

    return modules.length > 0 ? (
        inline ? (
            <Select
                multiple
                value={currentModules}
                onChange={handleModuleChange}
                onClose={handleModuleClose}
                MenuProps={MenuProps}
                disabled={disabled}
                fullWidth
                options={modules}
                getOptionValue={(module) => module.id}
                getOptionLabel={(module) => module.name}
                aria-label={trans('modules-filter', {}, 'student-common')}
            />
        ) : (
            <Grid container direction="column" alignItems="flex-start">
                <Grid item>
                    <FilterLabel>{trans('modules-filter', {}, 'student-common')}</FilterLabel>
                </Grid>
                <Grid item>
                    <Select
                        multiple
                        value={currentModules}
                        onChange={handleModuleChange}
                        onClose={handleModuleClose}
                        MenuProps={MenuProps}
                        disabled={disabled}
                        options={modules}
                        getOptionValue={(module) => module.id}
                        getOptionLabel={(module) => module.name}
                    />
                </Grid>
            </Grid>
        )
    ) : null;
};

import type { SectionName } from '@lingoda/sections';
import { createRecordFactory } from '@lingoda/utils';

export interface CurrentRoute {
    readonly route: string;
    readonly params: { [key: string]: string };
}

export interface RouteState {
    readonly currentSection?: SectionName;
    readonly currentRoute: CurrentRoute;
}

export const RouteRecordFactory = createRecordFactory<RouteState>({
    currentSection: undefined,
    currentRoute: undefined,
});

import { type AchievementFragment } from '@lingoda/graphql';
import { AchievementSlug } from '../models';
import { isAchievementActive } from './isAchievementActive';

export const shouldShowProgress = (
    currentItem: AchievementFragment,
    prevItem?: AchievementFragment,
) => {
    if (currentItem.slug === AchievementSlug.ONBOARDING_CHECKLIST) {
        return true;
    }

    return prevItem && prevItem.slug !== AchievementSlug.ONBOARDING_CHECKLIST
        ? isAchievementActive(prevItem) && !isAchievementActive(currentItem)
        : !isAchievementActive(currentItem);
};

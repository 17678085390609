import { type Achievement } from '@lingoda/graphql';
import { isAchievementSpecial } from './isAchievementSpecial';

export const getTopAchievements = (
    achievements: ReadonlyArray<Achievement>,
    limit = 3,
): Achievement[] => {
    // Right now, we have three slots available for top achievements.
    //
    // The first slot is reserved for the next upcoming achievement that the user can achieve.
    // If there are no upcoming achievements, the first slot is empty.
    //
    // The second and third slot are reserved for achievements, that are either claimable or
    // claimed. If there are no claimable or claimed achievements, the second and third
    // slots are empty.
    //
    // For more details, see https://lingoda.atlassian.net/browse/LW-23010
    let firstSlot: Achievement | null = null;
    let secondSlot: Achievement | null = null;
    let thirdSlot: Achievement | null = null;

    achievements.forEach((achievement) => {
        if (shouldGoToUpcomingAchievementSlot(achievement, firstSlot)) {
            firstSlot = achievement;

            return;
        }

        if (shouldGoToClaimedOrUnclaimedSlot(achievement, secondSlot)) {
            if (secondSlot === null) {
                secondSlot = achievement;

                return;
            }

            // Instead of overriding the achievement occupying the second slot,
            // we check the priorities and potentially it down to the third slot.
            // The same test needs to be repeated if the third slot is occupied
            // as well
            if (isHigherPriority(achievement, secondSlot)) {
                if (thirdSlot === null || isHigherPriority(secondSlot, thirdSlot)) {
                    thirdSlot = secondSlot;
                }
                secondSlot = achievement;
            }

            return;
        }

        if (shouldGoToClaimedOrUnclaimedSlot(achievement, thirdSlot)) {
            thirdSlot = achievement;
        }
    });

    return [
        firstSlot as Achievement | null,
        secondSlot as Achievement | null,
        thirdSlot as Achievement | null,
    ]
        .filter((slot): slot is Achievement => !!slot)
        .slice(0, limit);
};

const shouldGoToUpcomingAchievementSlot = (achievement: Achievement, slot: Achievement | null) => {
    if (isAchievementSpecial(achievement)) {
        return false;
    }

    if (achievement.achieved) {
        return false;
    }

    return slot === null || isAttendanceAchievementLater(slot, achievement);
};

const shouldGoToClaimedOrUnclaimedSlot = (achievement: Achievement, slot: Achievement | null) => {
    if (slot !== null && isAchievementSpecial(slot)) {
        return false;
    }

    if (isAchievementSpecial(achievement) && achievement.claimed) {
        return true;
    }

    if (!achievement.achieved) {
        return false;
    }

    return slot === null || isAttendanceAchievementLater(achievement, slot);
};

const isHigherPriority = (subject: Achievement, comparison: Achievement) => {
    if (isAchievementSpecial(subject) && !isAchievementSpecial(comparison)) {
        return true;
    }

    return isAttendanceAchievementLater(subject, comparison);
};

const isAttendanceAchievementLater = (subject: Achievement, comparison: Achievement) => {
    return subject.value > comparison.value;
};

import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
// Allow import of type only
// eslint-disable-next-line
import type { DebounceSettings } from 'lodash';

export const useDebouncedState = <T>(value: T, wait = 500, options?: DebounceSettings) => {
    const [debouncedValue, setState] = useState<T>(value);

    const debouncedSetState = useMemo(
        () => debounce((newState: T) => setState(newState), wait, options),
        [setState, wait, options],
    );

    useEffect(() => {
        debouncedSetState(value);
    }, [value, debouncedSetState]);

    return debouncedValue;
};

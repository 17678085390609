import type { MercureMessage, MercureMessageData } from './types';

export const getMercureMessageData = (msg: MercureMessage) => {
    const data: MercureMessageData = JSON.parse(msg.data);
    if (typeof data !== 'object' || data === null) {
        throw new Error(`'data' should be object, received \`${typeof data}\``);
    }

    const summary = JSON.parse(data.summary);
    if (typeof summary !== 'object' || summary === null) {
        throw new Error(`'summary' should be object, received \`${typeof summary}\``);
    }

    return summary;
};

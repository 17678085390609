import { FlashcardStatus, type FlashcardTotals } from '@lingoda/graphql';
import type { Reference } from '@apollo/client';
import type { FieldReadFunction } from '@apollo/client/cache/inmemory/policies';

const fieldPolicy: FieldReadFunction<Readonly<FlashcardTotals>> = (_, { args, readField }) => {
    const flashcardRefs = readField<Reference[]>({
        fieldName: 'vocabularyItems',
        args: { ...args },
    });

    return {
        totalCards: flashcardRefs?.length || null,
        learnedCards:
            flashcardRefs?.filter((flashcardRef) => {
                const status = readField('status', flashcardRef);

                return status === FlashcardStatus.GotIt;
            }).length || null,
        notSureCards:
            flashcardRefs?.filter((flashcardRef) => {
                const status = readField('status', flashcardRef);

                return status === FlashcardStatus.NotSure;
            }).length || null,
    };
};

export default fieldPolicy;

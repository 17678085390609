import { useCallback, useState } from 'react';
import { Hidden } from '@lingoda/ui';
import { useSelector } from '@lingoda/hooks';
import { isActiveSubscriptionPrivate } from '@lingoda/subscriptions';
import { ClassType } from '@lingoda/graphql';
import { BookingWidgetMobile } from './mobile/BookingWidgetMobile';
import { BookingWidgetDesktop } from './desktop/BookingWidgetDesktop';
import { type WidgetClassType } from './types';

export interface BookingWidgetProps {
    moduleId: number;
    lessonId: number;
    initialClassType?: WidgetClassType;
    mobilePortal?: Element | null;
}

export const BookingWidget = ({
    moduleId,
    lessonId,
    initialClassType,
    mobilePortal,
}: BookingWidgetProps) => {
    const hasPrivateSubscription = useSelector(isActiveSubscriptionPrivate);
    const [classType, setClassType] = useState<WidgetClassType>(
        initialClassType || (hasPrivateSubscription ? ClassType.Individual : ClassType.Group),
    );

    const handleClassTypeChange = useCallback((newClassType: WidgetClassType | null) => {
        if (newClassType) {
            setClassType(newClassType);
        }
    }, []);

    return (
        <>
            <Hidden smwDown>
                <BookingWidgetDesktop
                    classType={classType}
                    onClassTypeChange={handleClassTypeChange}
                    moduleId={moduleId}
                    lessonId={lessonId}
                />
            </Hidden>
            <Hidden smwUp>
                <BookingWidgetMobile
                    classType={classType}
                    onClassTypeChange={handleClassTypeChange}
                    moduleId={moduleId}
                    lessonId={lessonId}
                    portalContainer={mobilePortal}
                />
            </Hidden>
        </>
    );
};

import { createSelector } from 'reselect';
import type { ClassType } from '@lingoda/graphql';
import { add, startOf } from '@lingoda/dates';
import { localRoundHourSelector } from '@lingoda/time';
import minBookingDatesSelector from './minBookingDates';

export default (type: ClassType) =>
    createSelector(
        minBookingDatesSelector,
        // localRoundHourSelector will cause refresh hourly & on TZ change
        localRoundHourSelector,
        (bookingDates) =>
            bookingDates?.[type] && add({ hours: 1 }, startOf('hour', bookingDates[type])),
    );

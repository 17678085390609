import { createDate, format } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';

export interface ChallengeTexts {
    title: string;
    subtitle: string;
    subtitleLong: string;
    description: string;
    overview: string[];
}

export const getChallengeTexts = (challengeName: string, startDate: string): ChallengeTexts => {
    const subtitle = format('LLLL')(createDate(startDate));
    const subtitleLong = format('LLLL yyyy')(createDate(startDate));

    switch (challengeName) {
        case 'may-2024': {
            return {
                title: trans('may-2024-title', {}, 'student-challenges'),
                subtitle,
                subtitleLong,
                description: trans('may-2024-description', {}, 'student-challenges'),
                overview: [
                    trans('may-2024-overview-1', {}, 'student-challenges'),
                    trans('may-2024-overview-2', {}, 'student-challenges'),
                ],
            };
        }
        case 'april-2024': {
            return {
                title: trans('april-2024-title', {}, 'student-challenges'),
                subtitle,
                subtitleLong,
                description: trans('april-2024-description', {}, 'student-challenges'),
                overview: [
                    trans('april-2024-overview-1', {}, 'student-challenges'),
                    trans('april-2024-overview-2', {}, 'student-challenges'),
                ],
            };
        }
        case 'march-2024': {
            return {
                title: trans('march-2024-title', {}, 'student-challenges'),
                subtitle,
                subtitleLong,
                description: trans('march-2024-description', {}, 'student-challenges'),
                overview: [
                    trans('march-2024-overview-1', {}, 'student-challenges'),
                    trans('march-2024-overview-2', {}, 'student-challenges'),
                ],
            };
        }
        case 'february-2024': {
            return {
                title: trans('february-2024-title', {}, 'student-challenges'),
                subtitle,
                subtitleLong,
                description: trans('february-2024-description', {}, 'student-challenges'),
                overview: [
                    trans('february-2024-overview-1', {}, 'student-challenges'),
                    trans('february-2024-overview-2', {}, 'student-challenges'),
                ],
            };
        }
        case 'january-2024': {
            return {
                title: trans('january-2024-title', {}, 'student-challenges'),
                subtitle,
                subtitleLong,
                description: trans('january-2024-description', {}, 'student-challenges'),
                overview: [
                    trans('january-2024-overview-1', {}, 'student-challenges'),
                    trans('january-2024-overview-2', {}, 'student-challenges'),
                ],
            };
        }
        case 'december-2023': {
            return {
                title: trans('december-2023-title', {}, 'student-challenges'),
                subtitle,
                subtitleLong,
                description: trans('december-2023-description', {}, 'student-challenges'),
                overview: [
                    trans('december-2023-overview-1', {}, 'student-challenges'),
                    trans('december-2023-overview-2', {}, 'student-challenges'),
                ],
            };
        }
        default: {
            return {
                title: '',
                subtitle,
                subtitleLong,
                description: '',
                overview: [],
            };
        }
    }
};

import { Button, Typography } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { goTo, loginPath } from '@lingoda/router';
import { trans } from '@lingoda/i18n';
import XRoundIcon from './x-round-24.svg?component';

export const TokenExpiredStep = () => {
    const handleOnClickContinue = () => {
        goTo(loginPath());
    };

    return (
        <Grid container justifyContent={'center'} textAlign={'center'} rowSpacing={2}>
            <Grid>
                <XRoundIcon />
            </Grid>
            <Grid xs={12}>
                <Typography variant="h2">{trans('link-expired', {}, 'public-common')}</Typography>
            </Grid>
            <Grid xs={12}>
                <Typography>{trans('your-password-link-expired', {}, 'public-common')}</Typography>
            </Grid>
            <Grid marginTop={2} width={'100%'}>
                <Button
                    variant={'outlined'}
                    color={'grayscale'}
                    fullWidth
                    onClick={handleOnClickContinue}
                >
                    {trans('go-to-homepage', {}, 'public-common')}
                </Button>
            </Grid>
        </Grid>
    );
};

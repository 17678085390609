import { SvgIcon, type SvgIconProps } from '@lingoda/ui';

const Icon = (props: SvgIconProps) => (
    <SvgIcon {...props} className="heart-icon">
        <path d="M5.833 1.75a.75.75 0 1 0-.75.75 5 5 0 0 1 .848.074 3.55 3.55 0 0 1-.098-.824ZM19.167 1.75a.75.75 0 0 0-1.5 0c0 .279-.034.555-.098.824a5 5 0 0 1 .848-.074.75.75 0 0 0 .75-.75ZM9 7.083a.75.75 0 0 1 .75-.75H6.79A7.45 7.45 0 0 0 9 7.494v-.41ZM13.75 6.333a.75.75 0 0 1 .75.75v.411a7.45 7.45 0 0 0 2.21-1.16h-2.96ZM19.667 11.833h1.416c.028 0 .056 0 .084-.002v-.748a.75.75 0 0 0-.75-.75H14.5v1.5h5.167Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.825 2.741C15.081 1.671 16.75 1 18.417 1a.75.75 0 0 0-.75.75c0 .279-.034.555-.098.824a5.873 5.873 0 0 0-2.77 1.309c-.774.66-1.322 1.484-1.534 2.376a5.873 5.873 0 0 0 2.77-1.308c.773-.66 1.321-1.485 1.534-2.377a5 5 0 0 1 .848-.074.75.75 0 0 0 .75-.75c0 1.742-.902 3.27-2.159 4.342a7.261 7.261 0 0 1-.299.241H13.75a.75.75 0 0 1 .75.75v.411a7.45 7.45 0 0 0 2.21-1.16h3.707A2.083 2.083 0 0 1 22.5 8.416v2a1.417 1.417 0 0 1-1.333 1.414v-.748a.75.75 0 0 0-.75-.75H21V8.417a.583.583 0 0 0-.583-.584H14.5v2.5H13v-2.5h-2.5v2.5H13v1.5h-2.5V21H13v-9.167h1.5V21h4.583a.583.583 0 0 0 .584-.583v-8.584h1.416c.028 0 .056 0 .084-.002v8.586a2.083 2.083 0 0 1-2.084 2.083H4.417a2.083 2.083 0 0 1-2.084-2.083V11.83A1.417 1.417 0 0 1 1 10.417v-2a2.083 2.083 0 0 1 2.083-2.084h3.708A7.45 7.45 0 0 0 9 7.494v-.41a.75.75 0 0 1 .75-.75H6.79a7.215 7.215 0 0 1-.298-.242C5.235 5.02 4.333 3.492 4.333 1.75c0 .414.336.75.75.75a5 5 0 0 1 .848.074c.213.892.76 1.717 1.534 2.377a5.873 5.873 0 0 0 2.77 1.308c-.212-.892-.76-1.717-1.534-2.376a5.873 5.873 0 0 0-2.77-1.309 3.55 3.55 0 0 1-.098-.824.75.75 0 0 0-.75-.75c1.667 0 3.336.67 4.592 1.741 1.032.88 1.824 2.068 2.075 3.43.25-1.362 1.043-2.55 2.075-3.43ZM9 7.833H3.083a.583.583 0 0 0-.583.584v1.916H9v-2.5Zm0 4H3.833v8.584a.583.583 0 0 0 .584.583H9v-9.167Z"
        />
        <path d="M14.5 11.833v-1.5H13v1.5h1.5Z" />
    </SvgIcon>
);

export default Icon;

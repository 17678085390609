import { type Resolution } from '../theme/resolution';
import { getResolutionBoundaries } from '../utils';
import { type UseMediaQueryOptions, useMediaQuery } from './useMediaQuery';

export const useResolution = (resolution: Resolution, options?: UseMediaQueryOptions): boolean =>
    useMediaQuery((theme) => {
        const [from, to] = getResolutionBoundaries(resolution);
        if (!from && to) {
            return theme.breakpoints.down(to);
        }

        if (from && !to) {
            return theme.breakpoints.up(from);
        }

        if (from && to) {
            return theme.breakpoints.between(from, to);
        }

        return '';
    }, options);

import { createSelector } from 'reselect';
import { createNextModuleSelector, createPrevModuleSelector } from './prevNextModule';
import studentModuleSelector from './studentModule';

const studentModuleNameSelector = createSelector(
    studentModuleSelector,
    (currentModule) => currentModule?.name,
);

export const studentNextModuleSelector = createNextModuleSelector(studentModuleNameSelector);

export const studentPrevModuleSelector = createPrevModuleSelector(studentModuleNameSelector);

import { useCallback } from 'react';
import { getTime, toUTCString } from '@lingoda/dates';
import { Button, DatePicker, DateSelector, Stack, useResolution, useUiEvent } from '@lingoda/ui';
import { useMonthAppointmentDates } from '@lingoda/classes';
import { trans } from '@lingoda/i18n';
import { actionClick } from '@lingoda/analytics';
import { type BookingFilter, mapDatesToEvent } from '@lingoda/booking';
import { useBookingFilterDateSelector } from '../hooks';
import { FilterLabel } from './FilterLabel';
import type { FilterProps } from './model';

export interface MultipleDatesFilterProps extends FilterProps<BookingFilter['dates']> {
    timeSlots: BookingFilter['timeSlots'];
}

const MAX_DATES_SELECTED = 20;

export const MultipleDatesFilter = ({ value, onChange, timeSlots }: MultipleDatesFilterProps) => {
    const isMobile = useResolution('phablet');
    const addEvent = useUiEvent();
    const currentMonthDate = value[0] ? toUTCString(value[0]) : null;
    const { scheduledDays, loading, onMonthChange, refetchAppointmentDates } =
        useMonthAppointmentDates(currentMonthDate);

    const handleDateChange = useCallback(
        (newDates: Date[]) => {
            newDates.sort((date1, date2) => getTime(date1) - getTime(date2));
            void addEvent(actionClick('DateNavigatorSetDate'), {
                metadata: {
                    ...mapDatesToEvent(newDates),
                },
            });
            onChange({ dates: newDates });
        },
        [addEvent, onChange],
    );

    const handleReset = useCallback(() => handleDateChange([]), [handleDateChange]);

    const handleModalOpened = useCallback(async () => {
        void addEvent(actionClick('DateNavigator'));
        await refetchAppointmentDates();
    }, [addEvent, refetchAppointmentDates]);

    const { minDate, maxDate } = useBookingFilterDateSelector(null, timeSlots, onChange);

    if (isMobile) {
        return (
            <Stack width="auto" spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <FilterLabel>{trans('date-label', {}, 'public-common')}</FilterLabel>
                    <Button size="small" color="grayscale" variant="outlined" onClick={handleReset}>
                        {trans('btn-reset-date', {}, 'student-common')}
                    </Button>
                </Stack>
                <DatePicker
                    type="multiple"
                    dates={value}
                    onChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    dotColor="primary"
                    scheduledDays={scheduledDays}
                    onMonthChange={onMonthChange}
                    maxDatesSelected={MAX_DATES_SELECTED}
                    loading={loading}
                    scheduledDayBg="transparent"
                    withScroll={false}
                    showHoverStyles={false}
                />
            </Stack>
        );
    }

    const labelId = 'filter-multiple-date';

    const title = trans('select-dates', {}, 'student-common');

    return (
        <Stack spacing={1} alignItems="flex-start" width="auto">
            <FilterLabel id={labelId}>{trans('date-label', {}, 'public-common')}</FilterLabel>
            <DateSelector
                type="multiple"
                values={value}
                onChange={handleDateChange}
                scheduledDays={scheduledDays}
                onMonthChange={onMonthChange}
                onModalOpened={handleModalOpened}
                id={labelId}
                loading={loading}
                minDate={minDate}
                maxDate={maxDate}
                maxDatesSelected={MAX_DATES_SELECTED}
                title={title}
                placeholder={title}
            />
        </Stack>
    );
};

import type { ContentBlock } from '@lingoda/graphql';
import { Box, type BoxProps, DangerousHtml, Typography, type TypographyProps } from '@lingoda/ui';
import ContentBlockIcon from './ContentBlockIcon';

interface ContentBlockTypographyProps extends BoxProps {
    icon?: ContentBlock['icon'];
    text?: ContentBlock['text'];
    label?: ContentBlock['label'];
    variant?: TypographyProps['variant'];
    textColor?: TypographyProps['htmlColor'];
    iconColor?: TypographyProps['htmlColor'];
    showLabel?: boolean;
    display?: TypographyProps['display'];
    ['data-cy']?: string;
}

const ContentBlockTypography = ({
    icon,
    text,
    label,
    variant,
    textColor,
    iconColor,
    showLabel,
    display = 'inline-flex',
    'data-cy': dataCy,
    ...boxProps
}: ContentBlockTypographyProps) => (
    <Box component="span" display={display} data-cy={dataCy} {...boxProps}>
        {icon && (
            <Box component="span" display="inline-flex" flexShrink={0} mr={1} color={iconColor}>
                <ContentBlockIcon icon={icon} />
            </Box>
        )}

        <DangerousHtml
            as={Typography}
            component="span"
            display="inline"
            variant={variant}
            htmlColor={textColor}
        >
            {showLabel && label ? `${label}: ${text}` : text}
        </DangerousHtml>
    </Box>
);

export default ContentBlockTypography;

import merge from 'lodash/merge';
import { handleActionsImmutably } from '@lingoda/core';
import { assert } from '@lingoda/utils';
import { addStudentInfo, updateBillingInfo, updateCertificateInfo } from './actions';
import { StudentInfoFactory } from './models';
import type { StudentInfo } from './models';

export type State = GenericObject<StudentInfo>;

type UpdateBillingInfoAction = Partial<ReturnType<typeof updateBillingInfo.success>>;
type UpdateCertificateInfoAction = Partial<ReturnType<typeof updateCertificateInfo>>;
type AddStudentInfoAction = ReturnType<typeof addStudentInfo>;

type Payload = UpdateBillingInfoAction['payload'] &
    UpdateCertificateInfoAction['payload'] &
    AddStudentInfoAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${updateBillingInfo.success}`]: (state, { payload }: UpdateBillingInfoAction) => {
            assert(payload, 'Invalid argument');
            const studentInfoId = payload.meta.currentUser?.studentInfoId;
            assert(studentInfoId, 'Invalid argument');

            state[studentInfoId].invoiceAddress = payload.payload.invoiceAddress;

            return state;
        },
        [`${updateCertificateInfo}`]: (state, { payload, meta }: UpdateCertificateInfoAction) => {
            if (meta?.currentUser?.studentInfoId) {
                state[meta.currentUser.studentInfoId] = merge(
                    state[meta.currentUser.studentInfoId] || StudentInfoFactory({}),
                    payload?.studentInfo,
                );
            }

            return state;
        },
        [`${addStudentInfo}`]: (state: State, { payload }: AddStudentInfoAction) => {
            state[payload.id] = merge(state[payload.id] || StudentInfoFactory({}), payload);

            return state;
        },
    },
    {},
);

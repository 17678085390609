import { userSelector } from '@lingoda/auth';
import { useGoalSettingQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';

export const useGoalSetting = () => {
    const { data: goalSettingData, loading: goalSettingLoading } = useGoalSettingQuery({
        variables: {
            page: 0,
        },
    });
    const user = useSelector(userSelector);
    const loading = !user || goalSettingLoading;

    if (loading || !goalSettingData) {
        return { data: undefined, loading };
    }

    const hasSubscription = !!goalSettingData.subscriptions.currentSubscription;
    const isCorporateClient = user.isCorporateClient;
    const isSprintRunner = user.isSprintRunner;
    const anyClassWasAttended = !!goalSettingData.appointments.firstAppointmentDate;

    const isGoalSettingEnabled =
        hasSubscription && !isCorporateClient && !isSprintRunner && anyClassWasAttended;

    const goalWasSet = !!goalSettingData.activeGoal;

    return {
        data: {
            isGoalSettingEnabled,
            goalWasSet,
        },
        loading,
    };
};

import English from './flag-english.svg?component';
import French from './flag-french.svg?component';
import German from './flag-german.svg?component';
import Spanish from './flag-spanish.svg?component';
import Italian from './flag-italian.svg?component';
import BusinessEnglish from './flag-business-english.svg?component';
import type { FC, SVGProps } from 'react';

export const SectionIcons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
    english: English,
    german: German,
    spanish: Spanish,
    italian: Italian,
    french: French,
    'business-english': BusinessEnglish,
};

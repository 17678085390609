import { trans, transChoice } from '@lingoda/i18n';
import { NotificationBar } from '@lingoda/ui';
import { useTrialState } from '@lingoda/subscriptions';

export const TrialDaysLeftNotificationBar = () => {
    const [trialState] = useTrialState();

    if (trialState.state === 'not-trial' || trialState.state === 'loading') {
        return null;
    }

    if (trialState.state === 'finished') {
        return <NotificationBar title={trans('free-trial-ended', {}, 'student-common')} />;
    }

    const { days } = trialState;

    const title =
        days === 1
            ? trans('less-than-day-left-free-trial', {}, 'student-common')
            : transChoice('days-left-free-trial', days, { days }, 'student-common');

    return <NotificationBar title={title} />;
};

import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import store from 'store';
import { type ComponentProps, type PropsWithChildren } from 'react';
import { CssBaseline, ThemeProviderLocalized } from '@lingoda/ui/theme';
import * as muiLocales from '@lingoda/ui/theme/locale';
import { StudentSnackbarProvider } from '@lingoda/student-frontend/root/providers';
import { IntlProvider } from '@lingoda/i18n';

interface ContextProvidersProps {
    locale: ComponentProps<typeof IntlProvider>['locale'];
}

const ContextProviders = ({ children, locale }: PropsWithChildren<ContextProvidersProps>) => (
    <Provider store={store}>
        <IntlProvider locale={locale}>
            <ThemeProviderLocalized locales={muiLocales}>
                <CssBaseline />
                <StudentSnackbarProvider>
                    <HelmetProvider>{children}</HelmetProvider>
                </StudentSnackbarProvider>
            </ThemeProviderLocalized>
        </IntlProvider>
    </Provider>
);

export default ContextProviders;

import { getTimezone } from '@lingoda/dates';
import { Currency } from '../models';

export interface TimeZoneArgs {
    timezone: string;
}

export const isEuropeLocation = ({ timezone }: TimeZoneArgs) =>
    timezone.toLowerCase().includes('europe');

export const getDefaultCurrencyByTimeZone = ({ timezone }: TimeZoneArgs) =>
    isEuropeLocation({ timezone }) ? Currency.Eur : Currency.Usd;

export const getDefaultCurrency = () => getDefaultCurrencyByTimeZone({ timezone: getTimezone() });

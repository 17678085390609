import {
    Box,
    Button,
    Divider,
    Link,
    Paper,
    type PaperProps,
    Stack,
    Typography,
    colorsPalette,
    styled,
} from '@lingoda/ui';
import { ChevronLeft, ChevronRight } from '@lingoda/ui/icons/lingodaIcons';
import { useDispatch } from '@lingoda/hooks';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { trans, transChoice } from '@lingoda/i18n';
import { BubbleSpeechProgress } from '../BubbleSpeechProgress';
import { Chart } from './Chart';
import { ChartSkeleton } from './ChartSkeleton';
import type { ChartItem } from './Chart';

export interface WeeklyProgressLayoutProps {
    loading: boolean;
    currentGoal: number | null;
    monthRange: string;
    onNavigationLeft?: () => void;
    onNavigationRight?: () => void;
    chartItems: ChartItem[];
    page: number;
    bubbleSpeechText?: string;
    paperProps?: PaperProps;
}

export const WeeklyProgressLayout = ({
    loading,
    currentGoal,
    monthRange,
    onNavigationLeft,
    onNavigationRight,
    chartItems,
    page,
    bubbleSpeechText,
    paperProps,
}: WeeklyProgressLayoutProps) => {
    const dispatch = useDispatch();
    const handleOpenSetGoalDialog = () => {
        dispatch(
            showDialog(DialogComponent.SetGoalWeeklyClasses, {
                closable: true,
                notifyAfterSubmit: true,
                currentGoal,
            }),
        );
    };

    return (
        <Paper data-cy="Weekly progress" {...paperProps}>
            <HeaderBox>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="column" spacing={1}>
                        <Typography variant="h3">
                            {trans('weekly-progress', {}, 'student-common')}
                        </Typography>
                        {currentGoal ? (
                            <Typography variant="body3" htmlColor={colorsPalette.blackAlpha[70]}>
                                {[
                                    trans('weekly-progress-goal', {}, 'student-common'),
                                    ': ',
                                    transChoice(
                                        'weekly-progress-subtitle',
                                        currentGoal,
                                        { goal: currentGoal },
                                        'student-common',
                                    ),
                                ].join('')}
                            </Typography>
                        ) : null}
                    </Stack>
                    <Link to={'#'} onClick={handleOpenSetGoalDialog} underline="none" py={0.75}>
                        <Typography variant="body3">
                            {currentGoal
                                ? trans('weekly-progress-edit-goal', {}, 'student-common')
                                : trans('weekly-progress-set-goal', {}, 'student-common')}
                        </Typography>
                    </Link>
                </Stack>
            </HeaderBox>
            <Divider sx={{ marginTop: 2 }} />
            <NavigationBox>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="body3" htmlColor={colorsPalette.blackAlpha[70]}>
                        {monthRange}
                    </Typography>
                    <Stack direction="row">
                        <Button
                            variant="text"
                            size="small"
                            onClick={onNavigationLeft}
                            disabled={!onNavigationLeft}
                            sx={{ padding: 1 }}
                        >
                            <ChevronLeft
                                sx={{
                                    height: '12px',
                                    color: onNavigationLeft
                                        ? colorsPalette.blackAlpha[70]
                                        : colorsPalette.blackAlpha[20],
                                }}
                            />
                        </Button>
                        <Button
                            variant="text"
                            size="small"
                            onClick={onNavigationRight}
                            disabled={!onNavigationRight}
                            sx={{ padding: 1 }}
                        >
                            <ChevronRight
                                sx={{
                                    height: '12px',
                                    color: onNavigationRight
                                        ? colorsPalette.blackAlpha[70]
                                        : colorsPalette.blackAlpha[20],
                                }}
                            />
                        </Button>
                    </Stack>
                </Stack>
            </NavigationBox>
            <ContentBox>
                <Stack spacing={3}>
                    {loading ? <ChartSkeleton /> : <Chart items={chartItems} page={page} />}
                    {bubbleSpeechText && (
                        <BubbleSpeechProgress text={bubbleSpeechText} size="small" />
                    )}
                </Stack>
            </ContentBox>
        </Paper>
    );
};

const HeaderBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3, 3, 0),
}));

const NavigationBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5, 1.5, 0, 3),
}));

const ContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0, 3, 3, 3),
}));

import { ClassTilePlaceholder } from '../ClassTileItem/ClassTilePlaceholder';
import { ClassListItemPlaceholder } from '../ClassListItem/ClassListItemPlaceholder';
import { ListLayout } from './ListLayout';
import { TileLayout } from './TileLayout';
import type { CommonProps } from '../../types/props';

export enum BookClassVariant {
    Tile = 'tile',
    List = 'list',
}

export type BookClassElementProps = CommonProps & {
    variant?: BookClassVariant;
};

export const BookClassElement = ({
    variant = BookClassVariant.Tile,
    active = true,
    ...props
}: BookClassElementProps) => {
    if (!active) {
        return variant === BookClassVariant.Tile ? (
            <ClassTilePlaceholder />
        ) : (
            <ClassListItemPlaceholder />
        );
    }

    return variant === BookClassVariant.Tile ? (
        <TileLayout {...props} />
    ) : (
        <ListLayout {...props} />
    );
};

import { memo } from 'react';
import { Box, Typography, colorsPalette, styled } from '@lingoda/ui';
import { createDate, format } from '@lingoda/dates';
import type { AchievementFragment } from '@lingoda/graphql';
import { AchievementBadge } from '../AchievementBadge';
import StarsLayer from './stars-layer.svg?component';
import LingodaLogo from './LingodaLogo.svg?component';

export interface AchievementCardProps {
    achievement: AchievementFragment;
}

const AchievementCardComponent = ({ achievement }: AchievementCardProps) => {
    const { name: achieveName, claimed, achievedAt, rewardText, slug } = achievement;
    const date = achievedAt ? format('d MMM yyyy', createDate(achievedAt)) : 'Date';

    return (
        <CardContainer data-cy="Achievement card">
            <CardLayout claimed={false}>
                <AchievementBadge badgeSlug={slug} name={achieveName} />
            </CardLayout>
            <ClaimedCardLayout claimed visible={claimed}>
                <Box flex="1 1 auto" width="100%" display="flex" justifyContent="center">
                    <AchievementBadge badgeSlug={slug} active name={achieveName} zIndex={2} />
                </Box>
                <TypographyTitle fontFamily="Lingoda Bold" variant="h2">
                    {achieveName}
                </TypographyTitle>
                <TypographySubtitle fontFamily="Lingoda Semibold" variant="body1">
                    {rewardText}
                </TypographySubtitle>
                <CardFooter>
                    <Typography
                        fontFamily="Lingoda Regular"
                        variant="body3"
                        component="time"
                        width="100%"
                        className="hide-in-percy"
                    >
                        {date}
                    </Typography>
                    <LingodaLogo />
                </CardFooter>
                <StarsContainer>
                    <StarsLayer />
                </StarsContainer>
                <ShineLayer visible={claimed} />
            </ClaimedCardLayout>
        </CardContainer>
    );
};

export const AchievementCard = memo(AchievementCardComponent);

const CARD_WIDTH = 300;
const CARD_HEIGHT = 387;
const BAR_SIZE = 100;
const TRANSITION = 'all 1s ease-out';

const CardContainer = styled(Box)(({ theme }) => ({
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: theme.shadows[4],
    overflow: 'hidden',
    isolation: 'isolate',
}));

const CardLayout = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'claimed',
})<{
    claimed: boolean;
}>(({ claimed }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 'inherit',
    padding: '38px 18px 18px',
    ...(claimed && {
        '::before': {
            '@keyframes rotate': {
                from: { transform: 'rotate(0)' },
                to: { transform: 'rotate(360deg)' },
            },
            content: '""',
            position: 'absolute',
            top: '-50%',
            left: '-50%',
            right: 0,
            bottom: 0,
            zIndex: -1,
            width: '200%',
            height: '200%',
            background:
                'radial-gradient(140% 130% at 110% 0%, rgb(33, 55, 251) 25%, rgb(255, 128, 223) 75%)',
            animation: 'rotate 10s linear infinite',
        },
    }),
    ...(!claimed && {
        background: 'linear-gradient(83.8deg, #EDEDED -1.84%, #D1D1D1 66.51%, #C9C9C9 105.94%);',
    }),
}));

const ClaimedCardLayout = styled(CardLayout, {
    shouldForwardProp: (prop) => prop !== 'visible',
})<{ visible: boolean }>(({ visible }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    transition: TRANSITION,
    clipPath: getRevealPath(visible ? CARD_WIDTH + BAR_SIZE + CARD_HEIGHT : 0),
}));

const TypographyTitle = styled(Typography)({
    textAlign: 'center',
    width: '100%',
});

const TypographySubtitle = styled(Typography)({
    marginTop: '8px',
    textAlign: 'center',
    width: '100%',
});

const ShineLayer = styled(Box, { shouldForwardProp: (prop) => prop !== 'visible' })<{
    visible: boolean;
}>(({ visible }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 3,
    background: 'white',
    mixBlendMode: 'overlay',
    opacity: 0.8,
    transition: TRANSITION,
    clipPath: getShinePath(visible ? CARD_WIDTH + BAR_SIZE + CARD_HEIGHT : 0),
}));

const StarsContainer = styled(Box)({
    position: 'absolute',
    mixBlendMode: 'overlay',
    top: 0,
    left: 0,
    zIndex: 1,
});

const CardFooter = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    marginTop: '26px',
    color: colorsPalette.whiteAlpha[60],
}));

const getRevealPath = (x: number) =>
    `polygon(
    ${x}px 0px,
    ${x - CARD_HEIGHT}px ${CARD_HEIGHT}px,
    ${x - CARD_WIDTH - CARD_HEIGHT - BAR_SIZE}px ${CARD_HEIGHT}px,
    ${x - CARD_WIDTH - CARD_HEIGHT - BAR_SIZE}px 0px
    )`;

const getShinePath = (x: number) =>
    `polygon(
    ${x}px 0px,
    ${x - CARD_HEIGHT}px ${CARD_HEIGHT}px,
    ${x - CARD_HEIGHT - BAR_SIZE}px ${CARD_HEIGHT}px,
    ${x - BAR_SIZE}px 0px
    )`;

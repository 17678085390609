import { Box } from '@lingoda/ui';
import { ANIMATION_DURATION } from '../../constants';
import { useFlipAudioHandler } from '../../hooks';
import { type Variant } from '../../types';
import { FlashCard, FlashCardPreview, type FlashCardProps } from '../FlashCard';
import { Content } from '../FlashCardContent';
import { CardFaceMain, CardFaceTranslation } from '../FlashCardFaces';
import { type FlashcardType } from './types';

interface Props {
    flashcard: FlashcardType;
    flashcardPreview?: FlashcardType;
    isAudioEnabled: boolean;
    isNextCardLoading: boolean;
    isSwapped: boolean;
    onAudioClick?: () => void;
    targetLang: string;
    variant: Variant;
    shortcutControls?: FlashCardProps['shortcutControls'];
}

export const FlashCardControl = ({
    flashcard,
    flashcardPreview,
    isAudioEnabled,
    isNextCardLoading,
    isSwapped,
    onAudioClick,
    targetLang,
    variant,
    shortcutControls,
}: Props) => {
    const { handleFlipStart, handleFlipEnd } = useFlipAudioHandler(flashcard.audioUrls, {
        isDefaultTranslationFace: isSwapped,
    });

    return (
        <>
            {flashcardPreview && (
                <Box
                    display="flex"
                    position="absolute"
                    left={0}
                    right={0}
                    top={(theme) => theme.spacing(2)}
                    bottom={(theme) => theme.spacing(-2)}
                >
                    <FlashCardPreview
                        backFace={
                            <CardFaceTranslation
                                id={flashcardPreview.id}
                                targetLang={targetLang}
                                variant="none"
                            >
                                <Content
                                    isAudioEnabled={false}
                                    text={flashcardPreview.item || flashcardPreview.title}
                                />
                            </CardFaceTranslation>
                        }
                        frontFace={
                            <CardFaceMain
                                id={flashcardPreview.id}
                                section={flashcardPreview.sectionName}
                                variant="none"
                            >
                                <Content
                                    gender={flashcardPreview.gender}
                                    isAudioEnabled={false}
                                    sampleSentence={flashcardPreview.sampleSentenceOne}
                                    text={flashcardPreview.title}
                                    wordClass={flashcardPreview.wordClass}
                                />
                            </CardFaceMain>
                        }
                        isSwapped={isSwapped}
                    />
                </Box>
            )}
            <FlashCard
                animationDuration={ANIMATION_DURATION}
                backFace={
                    <CardFaceTranslation
                        id={flashcard.id}
                        targetLang={targetLang}
                        variant={variant}
                    >
                        <Content
                            audioUrl={flashcard.audioUrls?.translation}
                            isAudioEnabled={isAudioEnabled}
                            onAudioClick={onAudioClick}
                            text={flashcard.item || flashcard.title}
                        />
                    </CardFaceTranslation>
                }
                frontFace={
                    <CardFaceMain
                        id={flashcard.id}
                        section={flashcard.sectionName}
                        variant={variant}
                    >
                        <Content
                            audioUrl={flashcard.audioUrls?.target}
                            gender={flashcard.gender}
                            isAudioEnabled={isAudioEnabled}
                            onAudioClick={onAudioClick}
                            sampleSentence={flashcard.sampleSentenceOne}
                            text={flashcard.title}
                            wordClass={flashcard.wordClass}
                        />
                    </CardFaceMain>
                }
                isHidden={isNextCardLoading}
                isSwapped={isSwapped}
                onFlipEnd={isAudioEnabled ? handleFlipEnd : undefined}
                onFlipStart={isAudioEnabled ? handleFlipStart : undefined}
                variant={variant}
                shortcutControls={shortcutControls}
            />
        </>
    );
};

import { createSelector } from 'reselect';
import { studentSelector } from '@lingoda/students';
import { hasSubscriptionSelector } from '@lingoda/subscriptions';

export default createSelector(
    hasSubscriptionSelector,
    studentSelector,
    (hasSubscription, student) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        student ? hasSubscription || student.groupCredits! + student.individualCredits! > 0 : false,
);

import { Close } from '../../icons';
import { colorsPalette, styled } from '../../theme';
import { Button } from '../../buttons';
import { useResolution } from '../../hooks';
import { Grid } from '../Grid';
import { Box } from '../Box';
import { Typography } from '../Typography';
import type { PropsWithChildren, ReactNode } from 'react';

const Wrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(1, 2),
    backgroundColor: colorsPalette.primaryAlpha[20],
}));

const BgWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
}));

const CloseButton = styled(Button)(({ theme }) => ({
    transform: `translateX(-${theme.spacing(4)}px)`,
    color: theme.palette.text.primary,
    padding: 0,
    position: 'absolute',
    top: 2,
    right: 0,
}));

const TitleWrapper = styled(Grid)(({ theme }) => ({
    padding: `${theme.spacing(0.5, 3)}!important`,
}));

export interface Props {
    title?: string;
    actions?: ReactNode[];
    onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const NotificationBar = ({
    title,
    children,
    actions,
    onClose,
}: PropsWithChildren<Props>) => {
    const isPhablet = useResolution('phablet');

    return (
        <BgWrapper>
            <Wrapper>
                <Grid container spacing={1} justify="center">
                    <Grid item container justify="space-between" wrap="nowrap">
                        <Grid
                            item
                            container
                            flex="1 0 auto"
                            justify="center"
                            spacing={1}
                            alignItems="center"
                        >
                            <TitleWrapper item xs={12} lg="auto">
                                <Typography variant="subtitle1">{title}</Typography>
                            </TitleWrapper>
                            {!isPhablet &&
                                actions?.map((action, key) => (
                                    <Grid item key={key}>
                                        {action}
                                    </Grid>
                                ))}
                        </Grid>
                        {onClose && (
                            <CloseButton
                                variant="text"
                                size="small"
                                disableRipple
                                onClick={onClose}
                            >
                                <Close fontSize="large" />
                            </CloseButton>
                        )}
                    </Grid>

                    {isPhablet && (
                        <Grid
                            item
                            container
                            spacing={1}
                            justify="center"
                            alignItems="center"
                            width="auto"
                            margin="auto"
                        >
                            {actions?.map((action, key) => (
                                <Grid item xs={12} sm="auto" key={key}>
                                    {action}
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
                {children}
            </Wrapper>
        </BgWrapper>
    );
};

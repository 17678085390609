import type { AddPaymentApiAttributes } from '@lingoda/api';
import { createAction, makeActionTrackable } from '@lingoda/core';
import type { PaymentOptions } from '../models';

interface Props {
    currency: string;
}

export default makeActionTrackable(
    createAction<AddPaymentApiAttributes & Props & PaymentOptions>('payment/ADD_PAYMENT_METHOD'),
);

import { type BookingFilter } from '../models';
import { mapDatesToEvent } from './mapDatesToEvent';
import { mapTimeSlotsToEvent } from './mapTimeSlotsToEvent';

export const getBookClassEvent = (bookingFilter?: BookingFilter) => {
    return {
        metadata: bookingFilter
            ? {
                  ...mapDatesToEvent(bookingFilter.dates),
                  ...mapTimeSlotsToEvent(
                      bookingFilter.timeSlots.slots,
                      bookingFilter.timeSlots.extendHours,
                  ),
              }
            : {
                  ...mapDatesToEvent([]),
                  ...mapTimeSlotsToEvent([], -1),
              },
    };
};

import { Button, DialogInfo, Typography, styled } from '@lingoda/ui';
import { InfoFilledIcon16 } from '@lingoda/ui/icons/lingodaIcons';
import { useToggleState } from '@lingoda/hooks';
import { Translate, trans } from '@lingoda/i18n';

interface CheckoutBillingCancellationButtonProps {
    credits: number;
}

export const CheckoutBillingCancellationButton = ({
    credits,
}: CheckoutBillingCancellationButtonProps) => {
    const [isModalOpen, openModal, closeModal] = useToggleState(false);

    return (
        <>
            <DialogInfo
                isOpen={isModalOpen}
                onClose={closeModal}
                title={trans(
                    'teams-checkout-billing-cancellation-title',
                    {},
                    'student-learning-teams',
                )}
                isButtonVisible={false}
            >
                <StyledTypography variant="body1" component="div">
                    <Translate
                        component="div"
                        id="teams-checkout-billing-cancellation-dialog-content"
                        domain="student-learning-teams"
                        params={{
                            groupCredits: credits,
                        }}
                    />
                </StyledTypography>
            </DialogInfo>
            <Button variant="text" fullWidth onClick={openModal} endIcon={<InfoFilledIcon16 />}>
                {trans('teams-checkout-billing-cancellation-button', {}, 'student-learning-teams')}
            </Button>
        </>
    );
};

const StyledTypography = styled(Typography)({
    '& ol': {
        paddingLeft: '1.5rem',
        margin: 0,
        '& li': {
            marginBottom: '1rem',
        },
    },
});

import {
    updateInvoiceAddress as updateInvoiceAddressApi,
    updatePreferences as updatePreferencesApi,
    updateStudent as updateStudentApi,
} from '@lingoda/api';
import { updateProfile } from '@lingoda/auth';
import { addCallback, addTrackerCallback, clearCacheAction } from '@lingoda/core';
import { createDate, formatDateTime } from '@lingoda/dates';
import {
    disableMovedToNextModule,
    disableNotification,
    finishProductTour,
    setModule,
    setNotificationValue,
    setOnboarded,
    startTour,
    updateBillingInfo,
    updateCertificateInfo,
    updatePreferences,
    updateStudent,
} from './actions';
import { studentIdSelector, studentSelector } from './selectors';
import { isValidAttributes } from './utils';

export default () => {
    addCallback(disableNotification, (action, store) => {
        const student = studentSelector(store.getState());
        const studentId = student?.id;

        if (!studentId) {
            return;
        }

        const preferences = {
            notifications: {
                ...{
                    [`${action.payload}_disabled`]: true,
                },
                ...student?.preferences?.notifications,
            },
        };

        void updatePreferencesApi(studentId, preferences).then(({ data }) => {
            if (isValidAttributes(data)) {
                store.dispatch(updateStudent(data));
            }
        });
    });

    addTrackerCallback(disableMovedToNextModule, (_action, store) => {
        const studentId = studentIdSelector(store.getState());
        const preferences = {
            movedToNextModule: false,
        };

        if (!studentId) {
            return Promise.reject();
        }

        return updatePreferencesApi(studentId, preferences).then((result) => {
            if (isValidAttributes(result.data)) {
                store.dispatch(updateStudent(result.data));
            }

            return result;
        });
    });

    addTrackerCallback(finishProductTour, (_action, store) => {
        const studentId = studentIdSelector(store.getState());

        if (!studentId) {
            return Promise.reject();
        }

        return updatePreferencesApi(studentId, { isProductTourFinished: true }).then((result) => {
            if (isValidAttributes(result.data)) {
                store.dispatch(updateStudent(result.data));
            }

            return result;
        });
    });

    addTrackerCallback(startTour, (action, store) => {
        const student = studentSelector(store.getState());
        const studentId = student?.id;

        if (!studentId) {
            return Promise.reject();
        }

        const preferences = {
            notifications: {
                ...student?.preferences?.notifications,
                [action.payload]: formatDateTime(createDate()),
            },
        };

        return updatePreferencesApi(studentId, preferences).then(({ data }) => {
            if (isValidAttributes(data)) {
                store.dispatch(updateStudent(data));
            }
        });
    });

    addTrackerCallback(setNotificationValue, (action, store) => {
        const student = studentSelector(store.getState());
        const preferences = {
            notifications: {
                ...student?.preferences?.notifications,
                [action.payload.notification]: action.payload.value,
            },
        };

        if (!student?.id) {
            return Promise.reject();
        }

        return updatePreferencesApi(student.id, preferences).then(({ data }) => {
            if (isValidAttributes(data)) {
                store.dispatch(updateStudent(data));
            }
        });
    });

    addTrackerCallback(setModule, (action, store) => {
        const student = studentSelector(store.getState());
        if (!student) {
            return Promise.reject();
        }
        const studentId = student.id;
        const oldModuleId = student.moduleId;
        const { moduleId, clearCache } = action.payload;

        store.dispatch(updateStudent({ id: studentId, moduleId }));

        if (clearCache) {
            store.dispatch(clearCacheAction());
        }

        return updateStudentApi(studentId, { module: moduleId }).catch((result) => {
            store.dispatch(updateStudent({ id: studentId, moduleId: oldModuleId }));

            return Promise.reject(result);
        });
    });

    addTrackerCallback(updateBillingInfo, (action) => {
        const userId = action.meta.currentUser?.userId;
        if (!userId) {
            return Promise.reject('No user');
        }

        return updateInvoiceAddressApi(action.payload);
    });

    addTrackerCallback(updatePreferences, ({ payload }, store) => {
        const studentId = studentIdSelector(store.getState());
        if (!studentId) {
            return Promise.reject();
        }

        store.dispatch(updateStudent({ id: studentId, preferences: payload }));

        return updatePreferencesApi(studentId, payload);
    });

    addCallback(updateProfile.success, (action, store) => {
        const { changes } = action.payload.payload;

        store.dispatch(
            updateCertificateInfo({
                studentInfo: {
                    birthCity: changes.birthCity,
                    birthCountry: changes.birthCountry,
                },
            }),
        );
    });

    addTrackerCallback(setOnboarded, async (action, store) => {
        const studentId = studentIdSelector(store.getState());

        if (!studentId) {
            return;
        }

        const result = await updateStudentApi(studentId, { onboarded: action.payload });

        store.dispatch(
            updateStudent({
                id: studentId,
                onboarded: result.data.onboarded,
            }),
        );

        return result;
    });
};

import { useCallback } from 'react';
import { useDispatch, useSelector } from '@lingoda/hooks';
import { studentModuleSelector } from '@lingoda/modules';
import { useOrientationClassAvailable } from '@lingoda/orientation-class';
import { learningUnitsByModuleIdSelector } from '@lingoda/learning-units';
import { setFilter } from '../actions';

export const useFilterOrientationClasses = () => {
    const dispatch = useDispatch();
    const { isOrientationClassAvailable } = useOrientationClassAvailable();
    const studentModule = useSelector(studentModuleSelector);
    const studentModuleId = studentModule?.id;
    const learningUnits = useSelector(learningUnitsByModuleIdSelector(() => studentModuleId));
    const orientationLessonId = learningUnits[0]?.lessonIds[0];

    const setOrientationFilter = useCallback(() => {
        const modules = studentModuleId ? [studentModuleId] : [];
        const lessons = orientationLessonId ? [orientationLessonId] : [];
        if (isOrientationClassAvailable && modules.length && lessons.length) {
            setTimeout(() => dispatch(setFilter({ lessons, modules })));
        }
    }, [dispatch, studentModuleId, orientationLessonId, isOrientationClassAvailable]);

    return [setOrientationFilter];
};

import { useCallback, useState } from 'react';
import type { PaymentResponse } from '@lingoda/api';
import { clearCacheAction } from '@lingoda/core';
import { useDispatch } from '@lingoda/hooks';
import { goTo, resolvePath, studentPaymentPendingPath } from '@lingoda/router';
import type { SectionName } from '@lingoda/sections';
import { buildFormErrors, isFormError } from '@lingoda/utils';
import { trans } from '@lingoda/i18n';
import { PAYMENT_CREDIT_CARDS, PaymentMethodType, useElements, useStripe } from '../models';
import { isStripeError } from '../utils';
import withStripe from './withStripe';
import type { PaymentPurpose, PaymentRequest } from '../models';

interface PaymentMethod {
    type?: PaymentMethodType;
    value?: string;
}

interface PaymentRequestOptions {
    country?: string;
    amount: number;
    currency: string;
    section: SectionName;
    label: string;
}

type UpdatePaymentRequestOptions = Omit<PaymentRequestOptions, 'country' | 'section'>;

export interface StripeFormValues {
    email?: string;
    section?: SectionName;
    country?: string;
    name?: string;
    paymentMethod: PaymentMethod;
}

export interface PaymentFormData {
    couponCode: string;
    paymentMethod: string;
    termsAndConditions: boolean;
    email: string;
    password?: string;
    marketing?: boolean;
    privacy?: boolean;
    agreements: Record<string, boolean | undefined>;
    name?: string;
}

interface PaymentOptions {
    paymentPurpose: PaymentPurpose;
}

export interface PaymentCallbackReturn extends PaymentResponse {
    redirectUrl?: string;
}

interface CallbackProps {
    onSubmitPaymentCallback: (formData: PaymentFormData) => Promise<PaymentCallbackReturn | void>;
    fetchIntentSecretCallback: (formData: PaymentFormData) => Promise<PaymentCallbackReturn | void>;
}

export interface WithPaymentProps {
    submitPayment: (values: StripeFormValues, formData?: unknown, options?: PaymentOptions) => void;
    initPaymentRequest: (options: PaymentRequestOptions) => void;
    updatePaymentRequest: (options: UpdatePaymentRequestOptions) => void;
    paymentRequest?: PaymentRequest;
    errorPayment: string;
    validating: boolean;
    setValidating: (validating: boolean) => void;
    setErrorPayment: (error?: string) => void;
}

export const withPayment = <T extends Record<string, unknown>>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: React.ComponentType<any>,
): React.ComponentType<T & CallbackProps> =>
    withStripe((props: T & CallbackProps) => {
        const stripe = useStripe();
        const elements = useElements();
        const dispatch = useDispatch();
        const [errorPayment, setErrorPayment] = useState<string>();
        const [validating, setValidating] = useState(false);
        const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

        // this is just temporary, payment pending should be unified. first subscriptions should be
        // ported to the purchase endpoint
        const getPaymentPending = useCallback(
            (id?: string, _section?: SectionName, absolute?: boolean, options?: PaymentOptions) => {
                const path = studentPaymentPendingPath({
                    purchaseId: id,
                    paymentPurpose: options?.paymentPurpose,
                });

                return absolute ? `${window.location.origin}${resolvePath(path)}` : path;
            },
            [],
        );

        const submitStripeIntent = useCallback(
            async (
                { name, email, country, section, paymentMethod }: StripeFormValues,
                formData: unknown,
                shouldReturnUrl?: boolean,
                options?: PaymentOptions,
            ) => {
                const { fetchIntentSecretCallback } = props;

                setValidating(true);

                try {
                    if (!stripe) {
                        throw new Error('Stripe is not initialized');
                    }

                    const paymentResponse = await fetchIntentSecretCallback(
                        formData as PaymentFormData,
                    );

                    if (!paymentResponse) {
                        return;
                    }

                    const { purchaseId, secret } = paymentResponse.data;

                    const returnUrl = shouldReturnUrl
                        ? (getPaymentPending(purchaseId, section, true, options) as string)
                        : undefined;

                    switch (paymentMethod.type) {
                        case PaymentMethodType.PaymentRequest:
                        case PaymentMethodType.StoredCard:
                            const { error: storedCardError } = await stripe.confirmCardPayment(
                                secret,
                                {
                                    payment_method: paymentMethod.value,
                                },
                            );
                            if (storedCardError) {
                                throw storedCardError;
                            }
                            break;
                        case PaymentMethodType.Card:
                            const card = elements?.getElement('card');
                            if (!card) {
                                return;
                            }
                            const { error: newCardError } = await stripe.confirmCardPayment(
                                secret,
                                {
                                    payment_method: {
                                        card,
                                        billing_details: {
                                            name,
                                            email,
                                        },
                                    },
                                },
                            );

                            if (newCardError) {
                                throw newCardError;
                            }

                            break;
                        case PaymentMethodType.Sofort:
                            const { error: sofortError } = await stripe.confirmSofortPayment(
                                secret,
                                {
                                    payment_method: {
                                        sofort: {
                                            country: (country && country.toUpperCase()) as string,
                                        },
                                        billing_details: { name, email },
                                    },
                                    return_url: returnUrl,
                                },
                            );

                            if (sofortError) {
                                throw sofortError;
                            }

                            break;
                        case PaymentMethodType.StoredSepa:
                            // Charge should happen on the backend as otherwise we get "mandate information already stored" error
                            // So - do nothing.
                            break;
                        default:
                            throw new Error('Unsupported payment method');
                    }

                    dispatch(clearCacheAction());

                    if (paymentResponse.redirectUrl) {
                        goTo(paymentResponse.redirectUrl);
                    } else {
                        goTo(getPaymentPending(purchaseId, section, false, options));
                    }
                } catch (error) {
                    if (isFormError(error)) {
                        const message = error.messages
                            ? Object.values(buildFormErrors(error.messages))[0]
                            : error.message;
                        if (message) {
                            setErrorPayment(message as string);
                        }
                    } else if (isStripeError(error)) {
                        setErrorPayment(error.message || trans('masked-error', {}, 'errors'));
                    } else {
                        // in case of any other error, we just show generic error message
                        setErrorPayment(trans('masked-error', {}, 'errors'));
                    }
                } finally {
                    setValidating(false);
                }
            },
            [props, getPaymentPending, dispatch, stripe, elements],
        );

        const handleSubmitPayment = useCallback(
            (
                paymentValues: StripeFormValues,
                formData: PaymentFormData,
                paymentOptions?: PaymentOptions,
            ) => {
                const {
                    paymentMethod: { type },
                } = paymentValues;
                if (!type) {
                    void props.onSubmitPaymentCallback(formData);

                    return;
                }

                const shouldReturnUrl = true;
                void submitStripeIntent(paymentValues, formData, shouldReturnUrl, paymentOptions);
            },
            [props, submitStripeIntent],
        );

        const updatePaymentRequest = useCallback(
            ({ currency, label, amount }: UpdatePaymentRequestOptions) => {
                if (paymentRequest) {
                    paymentRequest.update({
                        currency: currency.toLowerCase(),
                        total: {
                            label,
                            amount,
                        },
                    });
                }
            },
            [paymentRequest],
        );

        const handleInitPaymentRequest = useCallback(
            ({ currency, amount, country, label, section }: PaymentRequestOptions) => {
                if (paymentRequest || !stripe) {
                    return;
                }

                const newPaymentRequest = stripe.paymentRequest({
                    country: country ? country.toUpperCase() : 'DE',
                    currency: currency.toLowerCase(),
                    total: {
                        label,
                        amount,
                    },
                });

                void newPaymentRequest.canMakePayment().then((result) => {
                    if (result) {
                        setPaymentRequest(newPaymentRequest);
                    }
                });

                newPaymentRequest.on('paymentmethod', ({ paymentMethod: { id }, complete }) => {
                    void submitStripeIntent(
                        {
                            section,
                            paymentMethod: {
                                type: PaymentMethodType.PaymentRequest,
                                value: id,
                            },
                        },
                        {
                            paymentMethod: PAYMENT_CREDIT_CARDS,
                        },
                    );

                    complete('success');
                });
            },
            [paymentRequest, stripe, submitStripeIntent],
        );

        return (
            <Component
                {...props}
                errorPayment={errorPayment}
                submitPayment={handleSubmitPayment}
                initPaymentRequest={handleInitPaymentRequest}
                updatePaymentRequest={updatePaymentRequest}
                setErrorPayment={setErrorPayment}
                validating={validating}
                setValidating={setValidating}
                paymentRequest={paymentRequest}
            />
        );
    });

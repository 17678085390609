import { Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import type { PropsWithChildren } from 'react';

export const ClassTileHeader = ({ children }: PropsWithChildren<unknown>) => (
    <Typography
        variant="subtitle2"
        sx={{ marginBottom: 1, color: colorsPalette.primarySolid[100] }}
    >
        {children}
    </Typography>
);

import { type PropsWithChildren } from 'react';
import { type Variant } from '../../types';
import { SectionFlag } from '../FlashCardContent';
import { CardFaceBase } from './CardFaceBase';

interface Props {
    id: string;
    section: SectionName;
    variant: Variant;
}

export const CardFaceMain = ({ children, id, section, variant }: PropsWithChildren<Props>) => {
    return (
        <CardFaceBase dataCy="flashcard-origin" variant={variant}>
            {children}
            {/* key fixes svg mask disappearance in Safari browser on cards change */}
            <SectionFlag key={id} section={section} width={16} height={16} />
        </CardFaceBase>
    );
};

import { type ButtonProps, IconButton } from '../../../buttons';
import { CloseIcon24 } from '../../../icons/Close';
import { colorsPalette } from '../../../theme';

export interface DialogCloseButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
    closeInvertedColor?: boolean;
}

export const DialogCloseButton = ({ closeInvertedColor, ...props }: DialogCloseButtonProps) => (
    <IconButton
        data-cy="dialog-dismiss-icon"
        sx={({ spacing }) => ({
            padding: spacing(1),
            color: closeInvertedColor
                ? colorsPalette.whiteSolid[100]
                : colorsPalette.blackAlpha[70],
        })}
        {...props}
    >
        <CloseIcon24 />
    </IconButton>
);

import { SvgIcon, type SvgIconProps } from '@lingoda/ui';

const StudyCalendar = (props: SvgIconProps) => (
    <SvgIcon {...props} className="rate-icon">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20.996V18.75h1.5v2.246c0 .111.074.21.181.24l2.5.714a.25.25 0 0 0 .138 0l2.5-.714a.25.25 0 0 0 .181-.24V18.75h1.5v2.246a1.75 1.75 0 0 1-1.27 1.682l-2.5.715c-.314.09-.646.09-.96 0l-2.5-.715A1.75 1.75 0 0 1 12 20.996Z"
        />
        <path d="M9 17.411a.75.75 0 0 0 .495.69l.99.36v-.965a.75.75 0 0 0-1.482-.167.755.755 0 0 0-.003.082Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5 19.009v-.259H19v.802l1.5-.543Zm-7 .543v-.802H12v.259l1.5.543Zm-4.516-2.056c0-.057.007-.113.019-.167a.75.75 0 0 0 .492.773l.99.358v2.536a.75.75 0 0 1-1.5 0v-3.5Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.533 14.055a.75.75 0 0 0-.566 0l-6.5 2.647a.75.75 0 0 0-.464.627.75.75 0 0 1 1.481.167v.964L12 19.01v-.259h1.5v.802l2.495.903a.75.75 0 0 0 .51 0L19 19.552v-.802h1.5v.259l2.505-.907a.75.75 0 0 0 .028-1.4l-6.5-2.647Zm-4.693 3.3 4.41 1.597 4.41-1.596-4.41-1.796-4.41 1.796Z"
        />
        <path d="M16.4 9.1V7.6H2.5v1.5h13.9Z" />
        <path d="M2.69 5.09a.65.65 0 0 1 .46-.19H5.2V3.4H3.15A2.15 2.15 0 0 0 1 5.55v11.198a2.15 2.15 0 0 0 2.15 2.15h4.2a.75.75 0 0 0 0-1.5h-4.2a.65.65 0 0 1-.65-.65V9.099h-.75V7.6h.75V5.55a.65.65 0 0 1 .19-.459ZM6.7 2.75a.75.75 0 1 0-1.5 0v.65h1.5v-.65ZM13.7 2.75a.75.75 0 0 0-1.5 0v.65h1.5v-.65ZM13.7 3.4h-1.5v1.5h1.5V3.4Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2 4.9H6.7V3.4h5.5v1.5Zm1.5 0v.65a.75.75 0 0 1-1.5 0V4.9h1.5Zm0 0V3.4h2.05a2.15 2.15 0 0 1 2.15 2.15v6.999a.75.75 0 0 1-1.5 0v-7a.65.65 0 0 0-.65-.65H13.7Zm-7 0H5.2v.65a.75.75 0 1 0 1.5 0V4.9Z"
        />
        <path d="M1.75 7.6v1.5h.75V7.6h-.75ZM5.2 3.4v1.5h1.5V3.4H5.2Z" />
    </SvgIcon>
);

export default StudyCalendar;

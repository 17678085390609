import { handleActionsImmutably } from '@lingoda/core';
import { addStartingModules, fetchStartingModules } from '../actions';
import { LoadedStatus, startingModuleFactory } from '../models';
import type { StartingModule } from '../models';

export type State = {
    modules: Readonly<GenericObject<StartingModule>>;
    status: LoadedStatus;
};

type StartingModulesAction = ReturnType<typeof addStartingModules>;

export default handleActionsImmutably<State, StartingModulesAction['payload']>(
    {
        [`${addStartingModules}`]: (state, { payload }: StartingModulesAction) => ({
            modules: payload.reduce(
                (result, startingModule) => {
                    result[startingModule.id] = startingModuleFactory(startingModule);

                    return result;
                },
                { ...state.modules },
            ),
            status: LoadedStatus.Loaded,
        }),
        [`${fetchStartingModules}`]: (state: State) => ({
            ...state,
            status: LoadedStatus.Loading,
        }),
        [`${fetchStartingModules.success}`]: (state: State) => ({
            ...state,
            status: LoadedStatus.Loaded,
        }),
    },
    {
        modules: {},
        status: LoadedStatus.Loading,
    },
);

import { forwardRef, useCallback } from 'react';
import { type CustomContentProps as SnackbarCustomContentProps } from 'notistack';
import { SnackbarContent, closeSnackbar } from 'notistack';

export {
    type CustomContentProps as SnackbarCustomContentProps,
    SnackbarContent,
    SnackbarProvider,
    useSnackbar,
    enqueueSnackbar,
    closeSnackbar,
} from 'notistack';
export type { SnackbarKey } from 'notistack';
export type SnackbarProps<T = unknown> = SnackbarCustomContentProps & T & { message: string };

export function withSnackbarAdapter<T>(
    Component: React.ComponentType<SnackbarProps<T> & { close: () => void }>,
) {
    const WrappedComponent = forwardRef<HTMLDivElement, SnackbarProps<T>>((props, ref) => {
        const handleClose = useCallback(() => closeSnackbar(props.id), [props.id]);

        return (
            <SnackbarContent ref={ref}>
                <Component {...props} close={handleClose} />
            </SnackbarContent>
        );
    });

    WrappedComponent.displayName = `SnackbarAdapter`;

    return WrappedComponent;
}

import {
    type SectionCurriculum,
    getCurriculumName,
    getSectionName,
    isCEFR,
} from '@lingoda/sections';
import { directRegisterSectionPath, resolvePath } from '@lingoda/router';
import { type QueryParams, buildUrl } from '@lingoda/urls';

export const getRegisterSectionUrl = (
    sectionCurriculum: SectionCurriculum,
    query?: QueryParams,
) => {
    const curriculumName = getCurriculumName(sectionCurriculum);
    const pathParams = {
        section: getSectionName(sectionCurriculum),
        curriculum: isCEFR(curriculumName) ? undefined : sectionCurriculum.slug,
    };

    return query
        ? buildUrl(resolvePath(directRegisterSectionPath(pathParams)), { query })
        : directRegisterSectionPath(pathParams);
};

import { createSelector } from 'reselect';
import { userSectionSelector } from '@lingoda/auth';
import studentModuleSelector from './studentModule';

enum SectionId {
    ENGLISH = 1,
    GERMAN = 2,
    SPANISH = 3,
    FRENCH = 4,
    ITALIAN = 5,
}

enum ModuleId {
    A1_1 = 12,
    A1_2 = 13,
    A2_1 = 14,
    B1_1 = 16,
    B1_2 = 17,
    B1_3 = 18,
    B2_1 = 21,
    C1_1 = 19,
}

const modulesWithOrientationEG: Partial<Record<number, number[]>> = {
    [SectionId.ENGLISH]: [
        ModuleId.A1_1,
        ModuleId.A2_1,
        ModuleId.B1_1,
        ModuleId.B1_2,
        ModuleId.B1_3,
        ModuleId.B2_1,
        ModuleId.C1_1,
    ],
    [SectionId.GERMAN]: [
        ModuleId.A1_1,
        ModuleId.A1_2,
        ModuleId.A2_1,
        ModuleId.B1_1,
        ModuleId.B2_1,
        ModuleId.C1_1,
    ],
    [SectionId.SPANISH]: [
        ModuleId.A1_1,
        ModuleId.A1_2,
        ModuleId.A2_1,
        ModuleId.B1_1,
        ModuleId.B2_1,
    ],
    [SectionId.FRENCH]: [ModuleId.A1_1, ModuleId.A1_2, ModuleId.A2_1, ModuleId.B1_1, ModuleId.B2_1],
    [SectionId.ITALIAN]: [ModuleId.A1_1],
};

const hasOrientationEG = (sectionId?: number, moduleId?: number) =>
    (sectionId && moduleId && (modulesWithOrientationEG[sectionId] ?? []).includes(moduleId)) ||
    false;

const defaultSelector = createSelector(
    userSectionSelector,
    studentModuleSelector,
    (section, module) => hasOrientationEG(section?.id, module?.id),
);

export default (moduleId?: number) =>
    moduleId === undefined
        ? defaultSelector
        : createSelector(userSectionSelector, (section) => hasOrientationEG(section?.id, moduleId));

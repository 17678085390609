import { useSelector } from '@lingoda/hooks';
import { addDuration, createDate } from '@lingoda/dates';
import type { ClassType } from '@lingoda/graphql';
import { sectionLeadTimesSelector } from '../selectors';

const defaultDuration = 'P2D';

const useSectionLeadTimesSelector = (classType: ClassType): Date => {
    const leadTimes = useSelector(sectionLeadTimesSelector);
    const timeDuration = leadTimes?.[classType] || defaultDuration;

    return addDuration(createDate(), timeDuration);
};

export default useSectionLeadTimesSelector;

import { produce } from 'immer';
import { createSelector } from 'reselect';
import { createDate, isAfter } from '@lingoda/dates';
import {
    AppointmentRecordExpandedFactory,
    AppointmentStatus,
    AppointmentStudentStatus,
} from '../models';
import { appointmentsSelector } from './state';
import type { Appointment, AppointmentExpanded } from '../models';

const isActive = (appointment: AppointmentExpanded) =>
    appointment.status === AppointmentStatus.ACTIVE;

const isCancellable = (appointment: AppointmentExpanded) =>
    appointment.status === AppointmentStatus.ACTIVE &&
    appointment.statusByStudent !== AppointmentStudentStatus.COMPLETED &&
    isAfter(appointment.startDate, createDate());

const isCompleted = (appointment: AppointmentExpanded) =>
    appointment.statusByStudent === AppointmentStudentStatus.COMPLETED;

export const expandAppointment = (appointment: Appointment) => {
    const appointmentExpanded = AppointmentRecordExpandedFactory(appointment);

    return produce(appointmentExpanded, (draft) => {
        draft.isActive = isActive(appointmentExpanded);
        draft.isCancellable = isCancellable(appointmentExpanded);
        draft.isCompleted = isCompleted(appointmentExpanded);
    });
};

export default createSelector(
    appointmentsSelector,
    (appointments): ReadonlyArray<AppointmentExpanded> =>
        Object.values(appointments).map((appointment) => expandAppointment(appointment)),
);

import { useEffect } from 'react';
import { DialogComponent, showDialog } from '@lingoda/dialogs';
import { useDispatch, usePersistentState, useSelector } from '@lingoda/hooks';
import { studentSelector, studentWeekStartPreference } from '@lingoda/students';
import { useWeeklyProgressQuery } from '@lingoda/graphql';
import type { WeekStart } from '@lingoda/dates';
import { useGoalSetting } from '../../hooks';
import { getWeeklyProgressToCelebrate } from './getWeeklyProgressToCelebrate';
import { getCelebrationWeekId } from './getCelebrationWeekId';

export const useGoalAchievedCelebrationDialog = () => {
    const dispatch = useDispatch();
    const firstName = useSelector(studentSelector)?.firstName;
    const weekStartsOn = useSelector(studentWeekStartPreference);
    const { data: goalSettingData, loading } = useGoalSetting();

    const [lastCelebratedWeekId, setLastCelebratedWeekId] = usePersistentState<string>(
        'lastCelebratedWeekId',
        undefined,
    );

    const skip = loading || !goalSettingData?.isGoalSettingEnabled;

    const { data } = useWeeklyProgressQuery({
        skip,
        variables: {
            page: 0,
        },
    });

    const weeklyProgress = data?.studentGoalWidgetData.weeklyProgress;

    useEffect(() => {
        if (!weeklyProgress || weekStartsOn === undefined || skip) {
            return;
        }

        const weekProgressToCelebrate = getWeeklyProgressToCelebrate(
            weeklyProgress.map((weekProgress, index) => ({
                weekId: getCelebrationWeekId(index, weekStartsOn as WeekStart),
                ...weekProgress,
            })),
            lastCelebratedWeekId,
        );

        if (weekProgressToCelebrate) {
            const { goal, attendance, longLabel } = weekProgressToCelebrate;
            dispatch(
                showDialog(DialogComponent.GoalAchievedCelebration, {
                    firstName,
                    goal,
                    attendance,
                    weekDuration: longLabel,
                }),
            );

            setLastCelebratedWeekId(weekProgressToCelebrate.weekId);
        }
    }, [
        weeklyProgress,
        dispatch,
        weekStartsOn,
        lastCelebratedWeekId,
        setLastCelebratedWeekId,
        firstName,
        skip,
    ]);
};

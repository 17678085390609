import { type SectionName } from '@lingoda/sections';
import { teamsRegisterSectionPath, useRouteMatcher } from '@lingoda/router';
import { useRegister } from '../../hooks';
import { Wrapper } from './Wrapper';
import { Content } from './Content';
import { FormContainer } from './FormContainer';
import { AuthHeader } from './AuthHeader';

interface Props {
    curriculumId?: number;
    section: SectionName;
}

export const RegisterLayout = ({ curriculumId, section }: Props) => {
    const { disabled, handleClick, handleSubmit, resourceOwners } = useRegister({
        curriculumId,
        section,
    });

    const isTeamsRegistration = useRouteMatcher({
        path: teamsRegisterSectionPath({ section }),
        matchPrefix: true,
    });

    return (
        <>
            <AuthHeader />
            <Wrapper>
                <Content isTeamsRegistration={isTeamsRegistration} />
                {section && (
                    <FormContainer
                        curriculumId={curriculumId}
                        disabled={disabled}
                        handleClick={handleClick}
                        handleSubmit={handleSubmit}
                        resourceOwners={resourceOwners}
                        section={section}
                    />
                )}
            </Wrapper>
        </>
    );
};

import { Stack } from '@lingoda/ui';
import { useParams } from '@lingoda/router';
import { trans, transChoice } from '@lingoda/i18n';
import { useBasicActivityChallengeDataSuspenseQuery } from '@lingoda/graphql';
import { ChallengesPaper } from '../ChallengesPaper';
import { ParticipantActivityItem } from './ParticipantActivityItem';

export const ParticipantActivity = () => {
    const { challengeName } = useParams<{ challengeName: string }>();
    const {
        data: { activityChallenge },
    } = useBasicActivityChallengeDataSuspenseQuery({
        variables: { challengeName },
    });
    const { currentParticipant } = activityChallenge;

    if (!currentParticipant) {
        return null;
    }

    const { classesAttendedCount, trainingSessionsCount, learnedWordsCount } = currentParticipant;

    if (!classesAttendedCount && !trainingSessionsCount && !learnedWordsCount) {
        return null;
    }

    return (
        <ChallengesPaper title={trans('your-activity', {}, 'student-challenges')}>
            <Stack direction={{ xs: 'column', smw: 'row' }} spacing={2}>
                {!!classesAttendedCount && (
                    <ParticipantActivityItem
                        value={classesAttendedCount}
                        label={transChoice(
                            'live-classes',
                            classesAttendedCount,
                            {},
                            'student-challenges',
                        )}
                    />
                )}

                {!!trainingSessionsCount && (
                    <ParticipantActivityItem
                        value={trainingSessionsCount}
                        label={transChoice(
                            'exercises',
                            trainingSessionsCount,
                            {},
                            'student-challenges',
                        )}
                    />
                )}
                {!!learnedWordsCount && (
                    <ParticipantActivityItem
                        value={learnedWordsCount}
                        label={transChoice(
                            'new-words',
                            learnedWordsCount,
                            {},
                            'student-challenges',
                        )}
                    />
                )}
            </Stack>
        </ChallengesPaper>
    );
};

import { addDays, isSameOrBefore } from '@lingoda/dates';
import { useConfigurationQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { localRoundMinuteSelector } from '@lingoda/time';
import { type Class } from '../models';

export const useIsRefundable = (klass?: Pick<Class, 'startDate' | 'teacherId'>) => {
    const now = useSelector(localRoundMinuteSelector);
    const { data } = useConfigurationQuery();

    if (!klass?.teacherId) {
        return true;
    }

    const refundDate = addDays(now, data?.config.classConfig.refundableDaysBeforeStart ?? 7);

    return isSameOrBefore(refundDate, klass.startDate);
};

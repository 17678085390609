import { handleActionsImmutably } from '@lingoda/core';
import { addTeachers } from './actions';
import { TeacherFactory } from './models';
import type { Teacher } from './models';

export type State = GenericObject<Teacher>;

type TeachersAction = ReturnType<typeof addTeachers>;

export default handleActionsImmutably(
    {
        [`${addTeachers}`]: (state: State, { payload }: TeachersAction): State =>
            (payload || []).reduce((newState, teacher) => {
                newState[teacher.id] = TeacherFactory(teacher);

                return newState;
            }, state),
    },
    {},
);

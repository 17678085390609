import { ShortcutButton } from '@lingoda/quiz-ui';
import { shortcutControlClasses } from '@lingoda/quiz-ui/ShortcutControl';
import { colorsPalette, styled } from '@lingoda/ui';

export const MissedButton = styled(ShortcutButton)({
    backgroundColor: colorsPalette.infoAlpha[70],
    color: 'white',

    [`.${shortcutControlClasses.badge}`]: {
        color: colorsPalette.infoCustom.dark,
    },

    '&:hover': {
        backgroundColor: colorsPalette.infoSolid[100],
    },
});

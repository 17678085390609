import { Box, Hidden, Tooltip } from '@lingoda/ui';
import { trans, transChoice } from '@lingoda/i18n';
import {
    KnownStat,
    KnownStatSkeleton,
    ReviewingStat,
    ReviewingStatSkeleton,
    StatCount,
} from '../../QuizStat/Stat';

interface Props {
    reviewing?: number;
    known?: number;
    knownDifInPeriod?: number;
    loading?: boolean;
}

export const Stats = (props: Props) => {
    if (props.loading) {
        return (
            <>
                <ReviewingStatSkeleton />
                <KnownStatSkeleton />
            </>
        );
    }

    return (
        <>
            <PracticePageReviewingStat {...props} />
            <PracticePageKnownStat {...props} />
        </>
    );
};

const PracticePageKnownStat = ({ known, knownDifInPeriod }: Props) => (
    <Box flex={1}>
        <Tooltip
            arrow
            placement="top-start"
            title={transChoice(
                'known-stat-tooltip',
                knownDifInPeriod ?? 0,
                {},
                'quiz-practice-dashboard',
            )}
        >
            <KnownStat
                count={<StatCount>{known}</StatCount>}
                difCount={knownDifInPeriod}
                indicatorLabel={
                    <Hidden mdDown>{trans('this-week', {}, 'quiz-practice-dashboard')}</Hidden>
                }
            />
        </Tooltip>
    </Box>
);

const PracticePageReviewingStat = ({ reviewing }: Props) => (
    <Box flex={1}>
        <Tooltip
            arrow
            placement="top-start"
            title={trans('reviewing-stat-tooltip', {}, 'quiz-practice-dashboard')}
            sx={{ flex: 1 }}
        >
            <ReviewingStat count={<StatCount>{reviewing}</StatCount>} />
        </Tooltip>
    </Box>
);

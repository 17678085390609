export const tabA11yProps = (namespace: string, id: string) => {
    return {
        id: getTabId(namespace, id),
        'aria-controls': getPanelId(namespace, id),
    };
};

export const tabPanelA11yProps = (namespace: string, id: string) => {
    return {
        id: getPanelId(namespace, id),
        'aria-labelledby': getTabId(namespace, id),
    };
};

const getTabId = (namespace: string, id: string) => `${namespace}-tab-${id}`;
const getPanelId = (namespace: string, id: string) => `${namespace}-tabpanel-${id}`;

import { useEffect } from 'react';

interface Options {
    disabled?: boolean;
}

const useWindowEventListener = <T extends keyof WindowEventMap>(
    eventName: T,
    callback: (event: WindowEventMap[T]) => void,
    { disabled }: Options = {},
) => {
    useEffect(() => {
        if (disabled) {
            return;
        }

        window.addEventListener(eventName, callback);

        return () => window.removeEventListener(eventName, callback);
    }, [disabled, eventName, callback]);
};

export default useWindowEventListener;

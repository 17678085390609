import {
    Children,
    type PropsWithChildren,
    type TransitionEventHandler,
    useEffect,
    useState,
} from 'react';
import { Box } from '@lingoda/ui';
import { useWindowEventListener } from '@lingoda/hooks';

// TODO: Without this offset, the timeout might be cleared before it
// can fire. This results in a skipped reset and visuals as
// described in https://lingoda.atlassian.net/browse/LW-22291.
// Adding an offset is very fragile, but hard to avoid with CSS
// animations. Investigate a better way of combining animations
const ANIMATION_OFFSET = 50;

export interface AnimationFlipProps {
    resetAnimation: boolean;
    duration: number;
    shortcutControls?: 'disabled' | 'enabled';
    onFlipStart?: () => void;
    onFlipEnd?: (isFlipped: boolean) => void;
}

export const AnimationFlip = ({
    children,
    duration,
    onFlipStart,
    onFlipEnd,
    resetAnimation,
    shortcutControls = 'enabled',
}: PropsWithChildren<AnimationFlipProps>) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [isReset, setIsReset] = useState(false);

    useEffect(() => {
        // if resetAnimation is true, we need to go back to the front side
        // without animation
        if (resetAnimation) {
            const timeout = setTimeout(() => {
                setIsReset(true);
                setIsFlipped(false);
            }, duration - ANIMATION_OFFSET);

            return () => clearTimeout(timeout);
        } else {
            setIsReset(false);
        }
    }, [duration, resetAnimation]);

    const handleOnClick = () => {
        onFlipStart?.();
        setIsFlipped((state) => !state);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Space') {
            event.preventDefault();
            handleOnClick();
        }
    };
    useWindowEventListener('keydown', handleKeyDown, { disabled: shortcutControls !== 'enabled' });

    const handleTransitionEnd: TransitionEventHandler = (event) => {
        // ignore transitions of descendants
        if (event.target === event.currentTarget) {
            onFlipEnd?.(isFlipped);
        }
    };

    const childrenArray = Children.toArray(children);
    const front = childrenArray[0];
    const back = childrenArray[1];

    return (
        <Box
            width="100%"
            display="flex"
            sx={{ cursor: 'pointer', perspective: '1000px' }}
            onClick={handleOnClick}
        >
            <Box
                width="100%"
                sx={{
                    display: 'grid',
                    transform: isFlipped ? 'rotateY(180deg)' : 'none',
                    transformStyle: 'preserve-3d',
                    transition: !isReset ? `transform ${duration}ms` : 'none',
                }}
                onTransitionEnd={handleTransitionEnd}
            >
                <Box
                    sx={{
                        backfaceVisibility: 'hidden',
                        gridArea: '1 / 1 / 2 / 2',
                        width: '100%',
                    }}
                >
                    {front}
                </Box>
                <Box
                    sx={{
                        backfaceVisibility: 'hidden',
                        gridArea: '1 / 1 / 2 / 2',
                        transform: 'rotateY(180deg)',
                        width: '100%',
                    }}
                >
                    {back}
                </Box>
            </Box>
        </Box>
    );
};

import isEqual from 'lodash/isEqual';

export const objectDiff = <T extends object>(objectOld: T, objectNext: T) => {
    const allFields = [
        ...new Set([...Object.keys(objectOld), ...Object.keys(objectNext)]),
    ] as unknown as ReadonlyArray<keyof T>;
    const diff = allFields.reduce((acc, fieldName) => {
        if (!isEqual(objectOld[fieldName], objectNext[fieldName])) {
            acc[fieldName] = objectNext[fieldName];
        }

        return acc;
    }, {} as Partial<T>);

    if (Object.keys(diff).length === 0) {
        return null;
    }

    return diff;
};

import { Box, Stack, Typography } from '@lingoda/ui';
import { AppointmentLabel } from '@lingoda/classes';
import { trans } from '@lingoda/i18n';
import { type AppointmentData } from '../../types';
import { ClassStatusHeader } from './ClassStatusHeader';

interface ClassStatusFinishedProps {
    variant: Variant;
}

export const ClassStatusFinished = ({ variant }: ClassStatusFinishedProps) => {
    const { color, title, subtitle } = variantToPropsMap[variant];

    return (
        <ClassStatusHeader color={color}>
            <Stack spacing={0.5}>
                <Box component="span">{title()}</Box>
                {subtitle && <Typography variant="body3">{subtitle()}</Typography>}
            </Stack>
        </ClassStatusHeader>
    );
};

type Variant =
    | 'pending-teacher-review'
    | 'pending-user-review'
    | 'reviews-done'
    | 'missed'
    | 'passed';

export const getVariant = (appointment?: AppointmentData): Variant => {
    switch (appointment?.label) {
        case AppointmentLabel.ClassMissed:
            return 'missed';
        case AppointmentLabel.TeacherFeedbackNotReady:
            return 'pending-teacher-review';
        case AppointmentLabel.TeacherFeedbackReady:
            return appointment.isRatedByStudent ? 'reviews-done' : 'pending-user-review';
        default:
            return 'passed';
    }
};

const variantToPropsMap = {
    'pending-teacher-review': {
        color: 'orange',
        title: () => trans('class-ended', {}, 'student-classes'),
        subtitle: () => trans('teacher-feedback-pending', {}, 'student-classes'),
    },
    'pending-user-review': {
        color: 'green',
        title: () => trans('class-attended', {}, 'student-classes'),
        subtitle: () => trans('rate-this-class-for-feedback', {}, 'student-common'),
    },
    'reviews-done': {
        color: 'green',
        title: () => trans('class-attended', {}, 'student-classes'),
        subtitle: () => trans('teacher-feedback-ready', {}, 'student-classes'),
    },
    missed: {
        color: 'gray',
        title: () => trans('class-missed', {}, 'student-classes'),
        subtitle: null,
    },
    passed: {
        color: 'gray',
        title: () => trans('class-passed', {}, 'student-classes'),
        subtitle: () => trans('find-upcoming-classes', {}, 'student-classes'),
    },
} as const;

import { useCallback, useEffect, useRef, useState } from 'react';

export const useAudio = (): UseAudioReturn => {
    const [audio, setAudio] = useState<HTMLAudioElement>();

    const [loadState, setLoadState] = useState<LoadState>(LoadState.Idle);
    const [playState, setPlayState] = useState<PlayState>(PlayState.Idle);

    useEffect(() => {
        if (!audio) {
            return;
        }

        const handleLoadStart = () => {
            setLoadState(LoadState.Loading);
        };

        const handleCanPlayThrough = () => {
            setLoadState(LoadState.Loaded);
        };

        const handleError = () => {
            setLoadState(LoadState.Error);
        };

        // `load()` was called
        const handleEmptied = () => {
            setLoadState(LoadState.Idle);
            setPlayState(PlayState.Idle);
        };

        const handlePause = () => {
            setPlayState(PlayState.Paused);
        };

        const handlePlay = () => {
            setPlayState(PlayState.Playing);
        };

        const handlePlaying = () => {
            setPlayState(PlayState.Playing);
        };

        const handleEnded = () => {
            setPlayState(PlayState.Finished);
        };

        audio.addEventListener('loadstart', handleLoadStart);
        audio.addEventListener('canplaythrough', handleCanPlayThrough);
        audio.addEventListener('error', handleError);
        audio.addEventListener('emptied', handleEmptied);
        audio.addEventListener('pause', handlePause);
        audio.addEventListener('play', handlePlay);
        audio.addEventListener('playing', handlePlaying);
        audio.addEventListener('ended', handleEnded);

        return () => {
            audio.removeEventListener('loadstart', handleLoadStart);
            audio.removeEventListener('canplaythrough', handleCanPlayThrough);
            audio.removeEventListener('error', handleError);
            audio.removeEventListener('emptied', handleEmptied);
            audio.removeEventListener('pause', handlePause);
            audio.removeEventListener('play', handlePlay);
            audio.removeEventListener('playing', handlePlaying);
            audio.removeEventListener('ended', handleEnded);

            if (isAudioPlaying(audio)) {
                audio.pause();
            }

            setLoadState(LoadState.Idle);
            setPlayState(PlayState.Idle);
        };
    }, [audio]);

    const audioRef = useRef(audio);
    useEffect(() => {
        audioRef.current = audio;
    }, [audio]);

    const play = useCallback((trackUrl: string) => {
        const audio = audioRef.current;

        if (audio && audio.currentSrc === trackUrl) {
            const isPlaying = isAudioPlaying(audio);

            if (isPlaying) {
                audio.currentTime = 0;

                return Promise.resolve();
            }

            return audio.play();
        }

        if (audio && isAudioPlaying(audio)) {
            audio.pause();
        }

        const newAudio = new Audio();
        newAudio.src = trackUrl;
        newAudio.load();

        setAudio(newAudio);

        return newAudio.play();
    }, []);

    const pause = useCallback(() => {
        const audio = audioRef.current;

        if (audio && isAudioPlaying(audio)) {
            audio.pause();
        }
    }, []);

    const stop = useCallback(() => {
        const audio = audioRef.current;

        if (audio && isAudioPlaying(audio)) {
            audio.pause();
        }

        setAudio(undefined);
    }, []);

    const preload = useCallback((trackUrl: string) => {
        const newAudio = new Audio();
        newAudio.src = trackUrl;
        newAudio.load();
    }, []);

    return {
        play,
        pause,
        stop,
        preload,
        currentSrc: audio?.currentSrc,
        loadState,
        playState,
    };
};

export interface UseAudioReturn {
    play: (trackUrl: string) => Promise<void>;
    pause: () => void;
    stop: () => void;
    preload: (trackUrl: string) => void;
    currentSrc?: string;
    playState: PlayState;
    loadState: LoadState;
}

export enum LoadState {
    Idle = 1,
    Loading,
    Error,
    Loaded,
}

export enum PlayState {
    Idle = 1,
    Playing,
    Paused,
    Finished,
}

const isAudioPlaying = (audio: HTMLAudioElement) => {
    return audio.currentTime && !audio.paused;
};

import { type DialogProps, default as MuiDialog } from '@mui/material/Dialog';

export { type DialogProps };

export const Dialog = (props: DialogProps) => {
    // `elevation` can be moved to `defaultProps` as part of `PaperProps` when https://github.com/mui/material-ui/pull/35088 merged
    const PaperProps = { elevation: 4, ...props.PaperProps };

    return <MuiDialog {...props} PaperProps={PaperProps} />;
};

import { useMemo } from 'react';
import { type AchievementFragment } from '@lingoda/graphql';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { shouldShowProgress } from '../../utils';
import { AchievementsBoardItem } from './AchievementsBoardItem';

export interface AchievementsBoardLayoutProps {
    achievements: ReadonlyArray<AchievementFragment>;
}

export const AchievementsBoardLayout = ({ achievements }: AchievementsBoardLayoutProps) => {
    const processedAchievements = useMemo(() => processAchievements(achievements), [achievements]);

    return (
        <Grid container spacing={{ xs: 2, sm: 3 }} data-cy="Achievements board">
            {processedAchievements.map(({ showProgress, ...achievement }) => (
                <Grid key={achievement.id} xs={6} sm={4} md={3}>
                    <AchievementsBoardItem achievement={achievement} showProgress={showProgress} />
                </Grid>
            ))}
        </Grid>
    );
};

const processAchievements = (achievements: ReadonlyArray<AchievementFragment>) => {
    return achievements.map((currentItem, index) => {
        const prevItem: AchievementFragment | undefined = achievements[index - 1];
        const showProgress = shouldShowProgress(currentItem, prevItem);

        return { ...currentItem, showProgress };
    });
};

import { Alert, DangerousHtml } from '@lingoda/ui';
import { transChoice } from '@lingoda/i18n';

interface Props {
    numberOfClassesInPausePeriod: number;
}

const ClassInPeriodAlert = ({ numberOfClassesInPausePeriod }: Props) => (
    <Alert severity="warning" variant="outlined">
        <DangerousHtml>
            {transChoice(
                'subscription-pause-class-in-period-warning',
                numberOfClassesInPausePeriod,
                { numberOfClasses: numberOfClassesInPausePeriod },
                'student-common',
            )}
        </DangerousHtml>
    </Alert>
);

export default ClassInPeriodAlert;

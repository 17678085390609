import { GeneratedClassDetailsTile } from './GeneratedClassDetailsTile';
import { ExistingClassDetailsTile } from './ExistingClassDetailsTile';
import { type PortalContainers } from './types';
import type { ExistingClassData, GeneratedClassData } from '../../types';

interface ClassDetailsTileProps {
    classItem: ExistingClassData | GeneratedClassData;
    onBooked?: (classId: string) => void;
    onCancelConfirm?: () => void;
    portalContainers?: PortalContainers;
}

export const ClassDetailsTile = ({
    classItem,
    onBooked,
    onCancelConfirm,
    portalContainers,
}: ClassDetailsTileProps) => {
    if (!classItem.isExisting) {
        return (
            <GeneratedClassDetailsTile
                generatedClass={classItem}
                onBooked={onBooked}
                portalContainers={portalContainers}
            />
        );
    }

    return (
        <ExistingClassDetailsTile
            classItem={classItem}
            onBooked={onBooked}
            onCancelConfirm={onCancelConfirm}
            portalContainers={portalContainers}
        />
    );
};

import {
    Box,
    Button,
    Stack,
    Typography,
    colorsPalette,
    styled,
    useResolution,
    withCursorInteractiveStyles,
} from '@lingoda/ui';
import { RouterLink } from '@lingoda/router';
import { trans } from '@lingoda/i18n';

export interface GroupClassItemLayoutProps {
    title: React.ReactNode;
    bodyText: React.ReactNode;
    onBookClick?: (event: React.MouseEvent) => void;
    bookVisible?: boolean;
    bookLoading?: boolean;
    bookDisabled?: boolean;
    linkTo?: string;
    isClickable?: boolean;
    role?: React.AriaRole;
}

export const GroupClassItemLayout = ({
    title,
    bodyText,
    bookVisible = true,
    bookLoading,
    bookDisabled,
    onBookClick,
    linkTo,
    role,
    isClickable = false,
    children,
}: React.PropsWithChildren<GroupClassItemLayoutProps>) => {
    const isInPortal = useResolution('phone-large');

    return (
        <WrapperBox
            role={role}
            isClickable={isClickable}
            {...(linkTo && {
                component: RouterLink,
                to: linkTo,
                target: isInPortal ? '_self' : '_blank',
            })}
        >
            <Stack direction="row" flexWrap="nowrap" spacing={1}>
                <Stack spacing={0.5} flexGrow={1}>
                    <Typography variant="subtitle1" textTransform="capitalize">
                        {title}
                    </Typography>
                    <Typography variant="body3" htmlColor={colorsPalette.blackAlpha[70]}>
                        {bodyText}
                    </Typography>
                    {children}
                </Stack>
                <Box display="flex" alignItems="center" flexShrink={0}>
                    {bookVisible && (
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            loading={bookLoading}
                            disabled={bookDisabled}
                            onClick={onBookClick}
                        >
                            {trans('btn-book', {}, 'student-common')}
                        </Button>
                    )}
                </Box>
            </Stack>
        </WrapperBox>
    );
};

const WrapperBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'isClickable' })<{
    isClickable: boolean;
}>(({ theme, isClickable }) =>
    withCursorInteractiveStyles(isClickable)({
        borderBottom: `1px solid ${colorsPalette.blackAlpha[16]}`,
        padding: theme.spacing(3),
        textDecoration: 'inherit',
        color: 'inherit',
    }),
);

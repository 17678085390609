import type { ButtonProps } from '@lingoda/ui';
import { Button, GradientButton } from '@lingoda/ui';
import { LockThick } from '@lingoda/ui/icons/lingodaIcons';
import { goTo, subscriptionsStorePath } from '@lingoda/router';
import type { FC } from 'react';

export interface BookButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
    outlined?: boolean;
    locked: boolean;
    onLockedRedirect?: () => void;
    children: string;
}

export const BookButton: FC<BookButtonProps> = ({
    outlined,
    locked,
    children,
    onLockedRedirect,
    ...rest
}) => {
    const handleLockedButtonClick: NonNullable<BookButtonProps['onClick']> = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onLockedRedirect?.();
        goTo(subscriptionsStorePath());
    };

    if (locked && outlined) {
        return (
            <GradientButton
                startIcon={
                    <LockThick
                        gradient={!rest.disabled && !rest.loading}
                        sx={{ marginBottom: '1px', width: 16, height: 16 }}
                    />
                }
                {...rest}
                onClick={handleLockedButtonClick}
                variant="outlined"
            >
                {children}
            </GradientButton>
        );
    }

    if (locked && !outlined) {
        return (
            <GradientButton
                startIcon={<LockThick sx={{ marginBottom: '1px', width: 16, height: 16 }} />}
                {...rest}
                onClick={handleLockedButtonClick}
            >
                {children}
            </GradientButton>
        );
    }

    return (
        <Button color="primary" variant={outlined ? 'outlined' : 'contained'} {...rest}>
            {children}
        </Button>
    );
};

import { type PropsWithChildren, useState } from 'react';
import { styled, theme } from '../../theme';
import { Backdrop } from '../Backdrop';
import { Box, type BoxProps } from '../Box';
import { GuidanceBanner } from './GuidanceBanner';
import { PopperContainer } from './PopperContainer';
import type { PopperProps } from '../../utils/Popper';

export interface GuidanceOverlayProps {
    open: boolean;
    title: string;
    body: string;
    onDismiss: () => void;
    popperProps?: Omit<PopperProps, 'open'>;
    icon?: React.ReactNode | false;
    wrapperSx?: BoxProps['sx'];
}

export const GuidanceOverlay = ({
    open,
    title,
    body,
    onDismiss,
    children,
    popperProps,
    icon,
    wrapperSx,
}: PropsWithChildren<GuidanceOverlayProps>) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    if (!open) {
        return <>{children}</>;
    }

    return (
        <>
            <Backdrop open sx={{ zIndex: overlayZIndex, pointerEvents: 'none' }} />
            <PopperContainer anchorEl={anchorEl} zIndex={overlayZIndex} popperProps={popperProps}>
                <GuidanceBanner title={title} body={body} onDismiss={onDismiss} icon={icon} />
            </PopperContainer>

            <ContentWrapper ref={setAnchorEl} sx={wrapperSx}>
                {children}
            </ContentWrapper>
        </>
    );
};

const ContentWrapper = styled(Box)({
    zIndex: theme.zIndex.tooltip,
    pointerEvents: 'none',
    position: 'relative',
});

const overlayZIndex = theme.zIndex.tooltip;

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const ChevronRight = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 12 18">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.22147 17.5279C0.782133 17.0886 0.782133 16.3763 1.22147 15.9369L7.92598 9.23242L1.22147 2.52792C0.782132 2.08858 0.782132 1.37627 1.22147 0.936928C1.66081 0.497587 2.37312 0.497587 2.81246 0.936928L11.108 9.23242L2.81246 17.5279C2.37312 17.9673 1.66081 17.9673 1.22147 17.5279Z"
            fill="currentColor"
        />
    </SvgIcon>
);

import { useCallback } from 'react';
import {
    studentFutureAppointmentsSelector,
    studentPastAppointmentsSelector,
} from '@lingoda/classes';
import { addDays, createDate, diffInUnits } from '@lingoda/dates';
import { usePersistentState, useSelector } from '@lingoda/hooks';
import { canRolloverCurrentSubscriptionSelector, currentSubscriptionSelector } from '../expanded';

export const useShowRolloverTrialBanner = () => {
    const [closedBanner, setCloseBanner] = usePersistentState<boolean>('SKIP_TRIAL_BANNER', false);
    const subscription = useSelector(currentSubscriptionSelector);
    const pastAppointments = useSelector(studentPastAppointmentsSelector);
    const futureAppointments = useSelector(studentFutureAppointmentsSelector);
    const canRolloverCurrentSubscription = useSelector(canRolloverCurrentSubscriptionSelector);

    const closeBanner = useCallback(() => {
        setCloseBanner(true);
    }, [setCloseBanner]);

    const showBanner =
        !closedBanner &&
        canRolloverCurrentSubscription &&
        (pastAppointments.length > 0 ||
            (futureAppointments.length > 0 &&
                diffInUnits('days', createDate(), addDays(subscription?.lastChargedAt as Date, 5)) >
                    0));

    return [showBanner, closeBanner] as const;
};

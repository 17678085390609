import { type PropsWithChildren } from 'react';
import { colorsPalette } from '../../theme';
import { Box } from '../Box';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import { ProgressBar, type ProgressBarProps } from './ProgressBar';

export interface ProgressBarWithLabelProps extends ProgressBarProps {
    sxBar?: ProgressBarProps['sx'];
    value: number;
}

export const ProgressBarWithLabel = ({
    children,
    sxBar,
    ...props
}: PropsWithChildren<ProgressBarWithLabelProps>) => (
    <Stack direction="row" alignItems="center" spacing={1}>
        <ProgressBar sx={sxBar} {...props} />
        {children || (
            <Box minWidth={35}>
                <Typography variant="subtitle2" htmlColor={colorsPalette.blackAlpha[70]}>
                    {Math.round(props.value)}%
                </Typography>
            </Box>
        )}
    </Stack>
);

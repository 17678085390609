import { type ReactNode, useCallback } from 'react';
import {
    type SvgIconProps,
    type Theme,
    ThemeProvider,
    createTheme,
    useResolution,
} from '@lingoda/ui';

export const ButtonResizeTheme = ({ children }: { children?: ReactNode }) => {
    const buttonSize = useResolution('phone') ? 'medium' : 'large';

    const theme = useCallback(
        (theme: Theme) =>
            createTheme(theme, {
                components: {
                    MuiButton: {
                        defaultProps: {
                            size: buttonSize,
                        },
                    },
                    MuiToggleButton: {
                        defaultProps: {
                            size: buttonSize,
                        },
                    },
                },
            }),
        [buttonSize],
    );

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const buttonIconResizeSx: SvgIconProps['sx'] = {
    '.MuiButton-sizeMedium &': {
        my: -0.25,
    },
};

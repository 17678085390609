import { Paper, Stack, Typography } from '@lingoda/ui';

interface Props {
    title?: string;
}

export const ChallengesPaper = ({ title, children }: React.PropsWithChildren<Props>) => {
    return (
        <Paper elevation={1} sx={{ padding: (theme) => theme.spacing(3) }}>
            <Stack spacing={2}>
                {title && <Typography variant="subtitle1">{title}</Typography>}
                {children}
            </Stack>
        </Paper>
    );
};

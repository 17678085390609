import { TimeFormat, weekStartDays, weekdays } from '@lingoda/dates';
import {
    Form,
    type FormHelper,
    type FormProps,
    FormWrapper,
    SelectField,
    Timezone,
    buildValidator,
} from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { DialogConfirm, type DialogConfirmProps, Grid } from '@lingoda/ui';
import { capitalize } from '@lingoda/utils';

export interface TimezoneFormValues {
    timezone: string;
    timeFormat: string | undefined;
    weekStart: number | undefined;
}

export const validate = (values: TimezoneFormValues) =>
    buildValidator({
        timezone: {
            required: true,
            timezone: true,
        },
        timeFormat: {
            required: true,
        },
        weekStart: {
            required: true,
        },
    })(values);

export interface TimezoneDialogProps
    extends Omit<DialogConfirmProps, 'onConfirm'>,
        TimezoneFormValues {
    onConfirm: (
        values: TimezoneFormValues,
        formikHelpers: FormHelper<TimezoneFormValues>,
    ) => Promise<void>;
}

export const TimezoneDialog = ({
    onConfirm,
    timeFormat,
    timezone,
    weekStart,
    ...dialogProps
}: TimezoneDialogProps) => (
    <FormWrapper
        validate={validate}
        onSubmit={onConfirm}
        enableReinitialize
        initialValues={{
            timezone,
            timeFormat,
            weekStart,
        }}
    >
        {(props: FormProps<TimezoneFormValues>) => (
            <DialogConfirm
                isOpen={dialogProps.isOpen}
                onConfirm={props.handleSubmit}
                onClose={dialogProps.onClose}
                title={trans('edit-timezone', {}, 'student-onboarding')}
                confirmLabel={trans('confirm', {}, 'student-onboarding')}
                onDismiss={dialogProps.onClose}
                scrollableContent={false}
                confirmStatus={
                    !props.isValid ? 'disabled' : props.isSubmitting ? 'loading' : 'enabled'
                }
                {...dialogProps}
            >
                <Form noValidate>
                    <Grid container direction="column" spacing={3}>
                        <Grid item xs={12} mt={1}>
                            <Timezone fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectField
                                label={trans('time-format-label', {}, 'public-student-profile')}
                                name="timeFormat"
                                fullWidth
                                options={Object.values(TimeFormat)}
                                getOptionValue={(timeFormat) => timeFormat}
                                getOptionLabel={getTranslatedTimeFormat}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectField
                                label={trans('week-start-label', {}, 'public-student-profile')}
                                name="weekStart"
                                fullWidth
                                options={weekStartDays}
                                getOptionValue={(weekStartDay) => weekStartDay}
                                getOptionLabel={(weekStartDay) =>
                                    capitalize(weekdays(false)[weekStartDay])
                                }
                            />
                        </Grid>
                    </Grid>
                </Form>
            </DialogConfirm>
        )}
    </FormWrapper>
);

const getTranslatedTimeFormat = (timeFormat: TimeFormat) => {
    switch (timeFormat) {
        case TimeFormat.Ampm:
            return trans('AM-PM-student-setting', {}, 'public-student-profile');
        case TimeFormat.Iso8601:
            return trans('24-hour-student-setting', {}, 'public-student-profile');
    }
};

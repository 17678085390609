import { Translate, trans } from '@lingoda/i18n';
import { DialogInfo } from '@lingoda/ui';
import { RevocationFormWrapper } from '../components/RevocationFormWrapper';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const RevocationDialog = ({ isOpen, onClose }: Props) => (
    <DialogInfo
        isOpen={isOpen}
        buttonLabel={trans('form-button-close', {}, 'public-common')}
        title={trans('revocation-header', {}, 'public-terms')}
        onClose={onClose}
    >
        <RevocationFormWrapper>
            <Translate id="revocation-content" domain="public-terms" />
        </RevocationFormWrapper>
    </DialogInfo>
);

import { CurrencyField } from '@lingoda/payments';
import { Box, Hidden, Stack, Tooltip, Typography } from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';
import type { Currency } from '@lingoda/currency';
import { trans } from '@lingoda/i18n';
import { Info } from '@lingoda/ui/icons';

interface Props {
    currency: Currency;
    isLoading?: boolean;
    totalPrice?: number;
    onChangeCurrency: (currency: Currency) => void;
}

const ExpirationMessage = () => (
    <Typography variant="body2" htmlColor={colorsPalette.blackAlpha[70]}>
        {trans('credits-expiration-info', {}, 'student-store')}

        <Tooltip
            component="span"
            title={trans('credits-active-subscription-warning', {}, 'student-store')}
        >
            <Info fontSize="small" />
        </Tooltip>
    </Typography>
);

const PurchaseTotal = ({ totalPrice = 0, currency, onChangeCurrency, isLoading }: Props) => (
    <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Stack spacing={0.5}>
                <Typography variant="overline">
                    {trans('credits-price-total', {}, 'student-store')}
                </Typography>
                <Hidden smDown>
                    <ExpirationMessage />
                </Hidden>
            </Stack>
            <Box display="flex" alignItems="center" gap={2}>
                <Box minWidth="3ch" textAlign="end" fontSize={24} fontWeight={600}>
                    {!isLoading && totalPrice}
                </Box>
                <Box minWidth={125}>
                    <CurrencyField
                        value={currency}
                        onChange={onChangeCurrency}
                        disabled={isLoading}
                        fullWidth
                    />
                </Box>
            </Box>
        </Box>
        <Hidden smUp>
            <ExpirationMessage />
        </Hidden>
    </Stack>
);

export default PurchaseTotal;

import { createSelector } from 'reselect';
import modulesSelector from './modules';
import type { Module } from '../models';
import type { State as ModulesState } from '../reducers/module';

export default createSelector(
    modulesSelector,
    (modules): ReadonlyArray<ModulesState['Module']> =>
        (Object.values(modules) as Module[]).sort(
            (moduleA, moduleB) => moduleA.position - moduleB.position,
        ),
);

import { handleActionsImmutably } from '@lingoda/core';
import { setCurrency } from './actions';
import type { CurrencyObj } from './models';

export type State = Readonly<CurrencyObj>;

type CurrencyAction = ReturnType<typeof setCurrency>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default handleActionsImmutably<State, any>(
    {
        [`${setCurrency}`]: (state, { payload }: CurrencyAction) => {
            state.currentCurrency = payload;

            return state;
        },
    },
    {
        currentCurrency: undefined,
    },
);

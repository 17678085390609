import type { WeekStart } from '@lingoda/dates';
import { createDate, diffInUnits, endOfWeek } from '@lingoda/dates';

export const shouldRemindToAdjustGoal = (
    lastGoalSetDate: string,
    numberOfGoals: number,
    numberOfGoalsAchieved: number,
    weekStartsOn: WeekStart,
) =>
    numberOfGoals === 4 &&
    numberOfGoalsAchieved === 0 &&
    diffInUnits('weeks', endOfWeek(createDate(), { weekStartsOn }), createDate(lastGoalSetDate)) >=
        3;

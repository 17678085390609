import { createSelector } from 'reselect';
import { getUnixTime } from '@lingoda/dates';
import notificationsSelector from './notifications';

export default createSelector(notificationsSelector, (notifications) =>
    notifications
        ? Object.values(notifications)
              .filter((notification) => notification.pending)
              .sort((a, b) => getUnixTime(b.timestamp) - getUnixTime(a.timestamp))
        : [],
);

import { type ReactNode } from 'react';
import { Box, type BoxProps, colorsPalette, gradients, styled } from '@lingoda/ui';

interface ClassStatusHeaderProps extends BoxProps {
    children?: ReactNode;
    color?: Color;
}

export const ClassStatusHeader = ({ color = 'gray', ...props }: ClassStatusHeaderProps) => {
    return <BoxStyled role="status" colorVariant={color} {...props} />;
};

type Color = 'purple' | 'gradient' | 'orange' | 'green' | 'gray';

const BoxStyled = styled(Box, { shouldForwardProp: (name) => name !== 'colorVariant' })<{
    colorVariant: Color;
}>(({ theme, colorVariant }) => ({
    ...theme.typography.subtitle2,
    ...colorStyles[colorVariant],
    width: '100%',
    padding: theme.spacing(1.5),
    textAlign: 'center',
}));

const colorStyles = {
    gradient: {
        background: gradients.darkPurpleToPrimaryDark,
        color: colorsPalette.whiteSolid[100],
    },
    gray: {
        backgroundColor: colorsPalette.blackSolid[3],
        color: colorsPalette.blackAlpha[70],
    },
    green: {
        backgroundColor: colorsPalette.positiveSolid[4],
        color: colorsPalette.positiveCustom.extradark,
    },
    purple: {
        backgroundColor: colorsPalette.purpleSolid[4],
        color: colorsPalette.purpleCustom.dark,
    },
    orange: {
        backgroundColor: colorsPalette.infoSolid[4],
        color: colorsPalette.infoCustom.extradark,
    },
};

import giftGoldenIcon from './gift-golden-56.svg?url';
import giftSilverIcon from './gift-silver-56.svg?url';
import giftBronzeIcon from './gift-bronze-56.svg?url';

export const getChallengeLockedPrizeIcons = (prizesLength: number): string[] => {
    return Array.from({ length: prizesLength }).map((_, index) =>
        getChallengeLockedPrizeIcon(index),
    );
};

export const getChallengeLockedPrizeIcon = (prizeIndex: number): string => {
    return lockedIcons[prizeIndex] || defaultIcon;
};

const lockedIcons = [giftBronzeIcon, giftSilverIcon, giftGoldenIcon];

const defaultIcon = giftBronzeIcon;

import useMediaQueryMaterial from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles';
import type { UseMediaQueryOptions } from '@mui/material/useMediaQuery';

export type { UseMediaQueryOptions } from '@mui/material/useMediaQuery';

type UseMediaQuery = (
    queryInput: string | ((theme: Theme) => string),
    options?: UseMediaQueryOptions,
) => boolean;

export const useMediaQuery: UseMediaQuery = (queryInput, options) =>
    // An option for Server Side Rendering (noSsr) has to be disabled, otherwise,
    // it will always return false the first time regardless of screen size
    // See more: https://github.com/mui/material-ui/issues/21048
    useMediaQueryMaterial(queryInput, { noSsr: true, ...options });

/** returns @media (max-width:0px) so it never happens */
export const getEmptyMediaQuery = (theme: Theme) => theme.breakpoints.down('xs');

import { useState } from 'react';
import { useCanImmediateCancel, useClassStateStudent, useJoinClass } from '@lingoda/classes';
import { trans } from '@lingoda/i18n';
import { showApolloErrorToast } from '@lingoda/toasts';
import { Grid, Portal, useResolution } from '@lingoda/ui';
import SplitButton from '@lingoda/ui/buttons/SplitButton';
import { SettingsPages, goTo, studentSettingsPath } from '@lingoda/router';
import { ClassType } from '@lingoda/graphql';
import { createDate } from '@lingoda/dates';
import { ClassStatusLive, ClassStatusPendingStart } from '../ClassStatusHeader';
import ClassTime from '../ClassTime';
import { Participants } from '../Participants';
import { TileBody } from '../TileBody';
import Title from '../Title';
import Zoom from '../Zoom';
import { useShouldShowConnectYourCalendar } from './useAnyCalendarEverConnected';
import { useDownloadCalendar } from './useDownloadCalendar';
import type { ExistingClassData } from '../../../../types';

export interface PostBookingClassDetailsProps {
    isImmediateCancel?: boolean;
    onCancelClick?: () => void;
    portalContainers?: { status?: Element | null; cta?: Element | null };
    classItem: ExistingClassData;
}

export const PostBookingClassDetails = ({
    onCancelClick,
    classItem,
    portalContainers,
}: PostBookingClassDetailsProps) => {
    const { uid: classId } = classItem;
    const startDate = createDate(classItem.startDate);
    const endDate = createDate(classItem.endDate);
    const isGroupClass = classItem.type === ClassType.Group;
    const { isLive, hasStarted } = useClassStateStudent(classId);
    const canImmediateCancel = useCanImmediateCancel(classId);
    const isClassJoined = classItem.appointment?.hasAttemptToJoin;
    const [hasJoinedClass, setHasJoinedClass] = useState(false);

    const onConnectToCalendarClick = () => {
        goTo(studentSettingsPath({ page: SettingsPages.CalendarConnection }));
    };

    const [joinClass, { loading: isJoiningClass }] = useJoinClass({
        onError: showApolloErrorToast,
    });

    const handleJoinClass = async (classId: string) => {
        await joinClass(classId);
        setHasJoinedClass(true);
    };

    const { shouldShowConnectYourCalendar } = useShouldShowConnectYourCalendar();
    const { shouldShowDownloadCalendarEvent, downloadCalendar } = useDownloadCalendar();

    const options = [
        {
            label: trans('join-class', {}, 'student-booking'),
            action: () => handleJoinClass(classId),
            disabled: !isLive,
            loading: isJoiningClass,
        },
        ...(shouldShowConnectYourCalendar
            ? [
                  {
                      label: trans('connect-your-calendar', {}, 'student-calendar'),
                      action: onConnectToCalendarClick,
                  },
              ]
            : []),
        ...(shouldShowDownloadCalendarEvent
            ? [
                  {
                      label: trans('download-calendar-event', {}, 'student-calendar'),
                      action: downloadCalendar,
                  },
              ]
            : []),
        {
            label: canImmediateCancel
                ? trans('btn-immediate-cancel-class', {}, 'student-common')
                : trans('cancel-class', {}, 'student-booking'),
            action: onCancelClick,
            disabled: hasStarted || isClassJoined || hasJoinedClass,
        },
    ];

    const button = <SplitButton variant="contained" fullWidth options={options} />;

    const status = isLive ? (
        <ClassStatusLive />
    ) : (
        startDate && <ClassStatusPendingStart startDate={startDate} />
    );

    const isInPortal = useResolution('phone-large');

    return (
        <>
            {status && (
                <Portal container={portalContainers?.status} disablePortal={!isInPortal}>
                    {status}
                </Portal>
            )}

            <TileBody>
                <Grid container>
                    <Title teacher={classItem.teacher} isGroupClass={isGroupClass} />
                    <ClassTime startDate={startDate} endDate={endDate} />
                    <Participants participants={classItem.students} />
                    <Zoom
                        title={
                            isLive
                                ? trans('class-has-started', {}, 'student-booking')
                                : trans('zoom-link-will-be-provided', {}, 'student-booking')
                        }
                    />

                    {isInPortal && portalContainers?.cta ? (
                        <Portal container={portalContainers?.cta}>{button}</Portal>
                    ) : (
                        <Grid item xs={12} marginTop={3}>
                            {button}
                        </Grid>
                    )}
                </Grid>
            </TileBody>
        </>
    );
};

import { createDate, set } from '@lingoda/dates';
import { type ClockInterval } from '@lingoda/clock';
import { useIntervalState } from '../useIntervalState';

interface Options {
    round?: boolean;
}

export const useIntervalDate = (clockInterval: ClockInterval, { round }: Options = {}) => {
    const [now] = useIntervalState<Date>(() => {
        const date = createDate();

        if (round) {
            switch (clockInterval) {
                case 'hour':
                    return set({ minutes: 0, seconds: 0, milliseconds: 0 }, date);
                case 'minute':
                    return set({ seconds: 0, milliseconds: 0 }, date);
                case 'second':
                    return set({ milliseconds: 0 }, date);
                default:
                    return date;
            }
        }

        return date;
    }, clockInterval);

    return now;
};

import { memo } from 'react';
import FormControl from '@lingoda/ui/fields/FormControl';
import type { TextFieldProps } from '@lingoda/ui/fields/TextField';
import TextField from '@lingoda/ui/fields/TextField';
import { CustomErrorMessage, useField } from '../../utils';
import type { FC } from 'react';
import type { CustomErrorMessageProps, FieldAttributes } from '../../utils';

type FormikTextFieldProps = TextFieldProps &
    FieldAttributes<unknown> & {
        renderErrorMessage?: (props: CustomErrorMessageProps) => React.ReactNode;
    };

const FormikTextField: FC<FormikTextFieldProps> = ({ renderErrorMessage, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <FormControl
            error={meta.touched && !!meta.error}
            fullWidth={props.fullWidth}
            required={props.required}
        >
            <TextField
                color="secondary"
                error={meta.touched && !!meta.error}
                {...props}
                {...field}
            />
            {renderErrorMessage ? renderErrorMessage({ meta }) : <CustomErrorMessage meta={meta} />}
        </FormControl>
    );
};

export default memo(FormikTextField);

import { useCallback, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { Box, Divider, Typography } from '../../layout';
import { colorsPalette, styled } from '../../theme';
import { MoonIcon, SunIcon } from '../../icons/lingodaIcons';
import { TimeSlotGroup } from './TimeSlotGroup';
import type { TimeSlotGroupProps } from './TimeSlotGroup';

type TimeSlotPickerMenuProps = {
    timeFormat: TimeFormat;
    disabledSlots?: number[];
    scheduledSlots?: number[];
    disabled?: boolean;
    slotsInRow?: 3 | 6;
} & (
    | {
          multiple: true;
          value: number[];
          onChange: (value: number[]) => void;
      }
    | {
          multiple?: false;
          value: number | undefined;
          onChange: (value: number) => void;
      }
);

export const TimeSlotPickerMenu = ({
    multiple,
    value,
    onChange,
    timeFormat,
    disabledSlots,
    scheduledSlots,
    disabled,
    slotsInRow,
}: TimeSlotPickerMenuProps) => {
    const a11y = useMemo(() => generateA11y(), []);

    const handleTimeSlotChange: TimeSlotGroupProps['onChange'] = useCallback(
        (timeSlot: number) => {
            if (multiple) {
                onChange(toggleHour(value, timeSlot));
            } else if (value !== timeSlot) {
                onChange(timeSlot);
            }
        },
        [multiple, onChange, value],
    );

    const handleIsSelected = useCallback(
        (timeSlot: number) => (multiple ? !!value.includes(timeSlot) : value === timeSlot),
        [multiple, value],
    );

    const handleIsDisabled = useCallback(
        (timeSlot: number) => disabled || disabledSlots?.includes(timeSlot) || false,
        [disabled, disabledSlots],
    );

    const handleIsScheduled = useCallback(
        (timeSlot: number) => scheduledSlots?.includes(timeSlot) || false,
        [scheduledSlots],
    );

    return (
        <Box>
            <TextDivider>
                <SunIcon />
                <Box ml={0.5}>
                    <Typography variant="subtitle1" id={a11y.sectionAmTitle}>
                        {`${trans('morning', {}, 'student-common')} ${
                            timeFormat === TimeFormat.Ampm ? '(AM)' : ''
                        }`}
                    </Typography>
                </Box>
            </TextDivider>
            <Box mt={2} role="region" aria-labelledby={a11y.sectionAmTitle}>
                <TimeSlotGroup
                    slots={morningSlots}
                    timeFormat={timeFormat}
                    isSelected={handleIsSelected}
                    isDisabled={handleIsDisabled}
                    isScheduled={handleIsScheduled}
                    onChange={handleTimeSlotChange}
                    slotsInRow={slotsInRow}
                />
            </Box>
            <TextDivider sx={{ mt: 4 }}>
                <MoonIcon />
                <Box ml={0.5}>
                    <Typography variant="subtitle1" id={a11y.sectionPmTitle}>
                        {`${trans('afternoon-evening', {}, 'student-common')} ${
                            timeFormat === TimeFormat.Ampm ? '(PM)' : ''
                        }`}
                    </Typography>
                </Box>
            </TextDivider>
            <Box mt={2} role="region" aria-labelledby={a11y.sectionPmTitle}>
                <TimeSlotGroup
                    slots={eveningSlots}
                    timeFormat={timeFormat}
                    isSelected={handleIsSelected}
                    isDisabled={handleIsDisabled}
                    isScheduled={handleIsScheduled}
                    onChange={handleTimeSlotChange}
                    slotsInRow={slotsInRow}
                />
            </Box>
        </Box>
    );
};

const slots = Array(12).fill(0);
const morningSlots = slots.map((_, index) => index);
const eveningSlots = slots.map((_, index) => 12 + index);

const TextDivider = styled(Divider)({
    backgroundColor: 'transparent',
    color: colorsPalette.blackAlpha[30],

    '.MuiDivider-wrapper': {
        alignItems: 'center',
        display: 'flex',
    },

    ':before, :after': {
        borderTopColor: colorsPalette.blackAlpha[16],
    },
});

const generateA11y = () => {
    return {
        sectionAmTitle: uniqueId('section-am-'),
        sectionPmTitle: uniqueId('section-pm-'),
    };
};

const toggleHour = (hours: number[], hour: number) =>
    hours.includes(hour) ? removeFromArray(hours, hour) : addToArray(hours, hour);

const addToArray = (array: number[], valueToAdd: number) =>
    [...array, valueToAdd].sort((a, b) => a - b);

const removeFromArray = (array: number[], valueToRemove: number) =>
    array.filter((value) => value !== valueToRemove);

import { useState } from 'react';
import { type RegisterUserParams, registerUser } from '@lingoda/api';
import { actionClick, getMarketingData } from '@lingoda/analytics';
import { fetchMe, sendFinishedUserRegistrationEvent } from '@lingoda/auth';
import { useDispatch } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { goTo, useRequiredParams } from '@lingoda/router';
import { Box, Grid, Typography, styled, useUiEvent } from '@lingoda/ui';
import { RegisterSocialForm } from '../../forms/RegisterSocialForm';

type ConfirmRegisterParam = Omit<RegisterUserParams, 'section'>;
interface Props {
    registerParams: Pick<ConfirmRegisterParam, 'timeFormat'> & Partial<ConfirmRegisterParam>;
    section: SectionName;
    email: string;
}

export const CompleteRegistration = ({ registerParams, section, email }: Props) => {
    const { key } = useRequiredParams<{ key: string }>();

    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const dispatch = useDispatch();
    const cjData = getMarketingData();
    const addEvent = useUiEvent();

    const handleRegister = async () => {
        void addEvent(actionClick('SignUpButton'));
        setSubmitting(true);
        const privacy = true;

        const marketing = false;

        const {
            curriculumId,
            module,
            timezone,
            timeFormat,
            weekStart,
            getAmbassadorShortCode,
            getAmbassadorCampaignId,
        } = registerParams;
        const { data } = await registerUser(key, {
            curriculumId,
            email,
            section,
            module,
            timezone,
            timeFormat,
            weekStart,
            cj: cjData,
            consent: {
                marketing_communication: marketing,
                privacy_policy: privacy,
            },
            getAmbassadorShortCode,
            getAmbassadorCampaignId,
        });

        await sendFinishedUserRegistrationEvent(data.user.id, email);

        setSubmitting(false);
        data.redirectUrl ? goTo(data.redirectUrl) : dispatch(fetchMe());
    };

    return (
        <BoxStyled maxWidth={450} p={2}>
            <Grid container justify="center" direction="column" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {trans('registration-header', {}, 'social-auth')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <RegisterSocialForm onSubmit={handleRegister} isSubmitting={isSubmitting} />
                </Grid>
            </Grid>
        </BoxStyled>
    );
};

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: `${theme.spacing(5)} auto ${theme.spacing(4)}`,
    [theme.breakpoints.only('xs')]: {
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
}));

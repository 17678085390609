import { type DataShape, getCjStorage, tagKeys } from './utils';

export const getCjData = () => {
    const storageData = parseStorage(getCjStorage());

    if (!storageData || !isStorageDataValid(storageData)) {
        return undefined;
    }

    return {
        aid: storageData.cj_aid,
        sid: storageData.cj_sid,
        pid: storageData.cj_pid,
    };
};

const parseStorage = (storageData: unknown): Partial<DataShape> | undefined => {
    if (typeof storageData !== 'object' || storageData === null) {
        return undefined;
    }

    const marketingDataTypes: Record<keyof DataShape, string> = {
        cj_aid: 'string',
        cj_pid: 'string',
        cj_sid: 'string',
        cj_exp: 'number',
    };

    return Object.fromEntries(
        tagKeys.map((key) => {
            const value = (storageData as Record<keyof DataShape, unknown>)[key];

            return [key, typeof value === marketingDataTypes[key] ? value : undefined];
        }),
    );
};

const isStorageDataValid = (storageData: Partial<DataShape>): storageData is DataShape => {
    if (!storageData?.cj_aid || !storageData?.cj_pid) {
        return false;
    }

    if (isDataExpired(storageData?.cj_exp || EXPIRATION_DATE_FALLBACK_FOR_INITIAL_RELEASE)) {
        return false;
    }

    return true;
};

// TODO: can be safely removed after specified date in const
const EXPIRATION_DATE_FALLBACK_FOR_INITIAL_RELEASE = Date.UTC(2022, 8, 1);

export const isDataExpired = (date: number) => Date.now() > date;

import { type BrowserOptions } from '@sentry/react';

export const beforeBreadcrumb: NonNullable<BrowserOptions['beforeBreadcrumb']> = (
    breadcrumb,
    hint,
) => {
    if (breadcrumb.type === 'http') {
        const { url } = breadcrumb.data || {};

        // Drop tracking tools requests that clutter sentry breadcrumbs
        if (typeof url === 'string' && IGNORE_HTTP_URLS.some((ignoreUrl) => ignoreUrl.test(url))) {
            return null;
        }
    }

    if (
        breadcrumb.category === 'ui.click' &&
        hint?.event instanceof PointerEvent &&
        hint.event.target instanceof HTMLElement
    ) {
        const { textContent } = hint.event.target;

        if (textContent) {
            breadcrumb.message = `${breadcrumb.message} "${truncate(textContent, 35)}"`;
        }
    }

    return breadcrumb;
};

const IGNORE_HTTP_URLS: RegExp[] = [
    /https:\/\/www.google-analytics.com/,
    /https:\/\/([a-z0-9]+[.])*nr-data.net/, // New Relic
    /https:\/\/([a-z0-9]+[.])*amazonaws.com/,
    /https:\/\/www.mczbf.com/, // CJ tracking
    /https:\/\/kinesis-testing.linguando.com/,
];

const truncate = (text: string, limit: number): string => {
    return text.length > limit ? text.slice(0, limit) + '...' : text;
};

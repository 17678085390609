import { TimeFormat } from '@lingoda/dates';
import { TIME_SLOT_VALUE_FORMAT, formatTimeSlotUtc } from '@lingoda/ui';
import {
    RANGE_SELECTED_ON_TIME_FILTER,
    TIMES_SELECTED_ON_TIME_FILTER,
    getRangeSelectedLabel,
} from '../models';
import type { BookClassEvent } from '../models';

export const mapTimeSlotsToEvent = (
    timeSlots: number[],
    extendHours: number,
): Partial<BookClassEvent> => {
    return {
        [TIMES_SELECTED_ON_TIME_FILTER]: timeSlots.map(toUtcTime).join(','),
        [RANGE_SELECTED_ON_TIME_FILTER]: getRangeSelectedLabel(extendHours),
    };
};

const toUtcTime = (timeSlot: number) =>
    formatTimeSlotUtc(TIME_SLOT_VALUE_FORMAT[TimeFormat.Iso8601], timeSlot);

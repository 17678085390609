import { createSelectorCreator, defaultMemoize } from 'reselect';
import type { Date } from '@lingoda/dates';
import { getHours, getMinutes, isSameHour, set, utcMinutesOffset } from '@lingoda/dates';
import { timeSelector } from './state';

const createEqualMomentHourSelector = createSelectorCreator(
    defaultMemoize,
    (a: unknown, b: unknown) => isSameHour(b as Date, a as Date),
);

export const selectorFactory = () =>
    createEqualMomentHourSelector(timeSelector as () => Date, (now) => {
        const minutesOffset = utcMinutesOffset();
        // As we need to support TZ with offset in minutes like Kolkata if we just
        // include the minute offset without decrementing the hour we might returning
        // a future hour which will cause data inconsistency when relaying on this selector
        const hour = getHours(now) - (minutesOffset && getMinutes(now) < minutesOffset ? 1 : 0);

        return set({ hours: hour, minutes: minutesOffset, seconds: 0, milliseconds: 0 }, now);
    });

export default selectorFactory();

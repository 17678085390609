import { actionClick } from '@lingoda/analytics';
import { useUiEvent } from '@lingoda/ui';

export const useTrackClaimRewardOnboarding = () => {
    const addEvent = useUiEvent();

    const trackClaimRewardOnboarding = () => {
        void addEvent(actionClick('ClaimReward-Onboarding'));
    };

    return { trackClaimRewardOnboarding };
};

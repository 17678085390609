import { type PropsWithChildren, useCallback } from 'react';
import { GuidanceOverlay, type SxProps, type Theme } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { useDispatch } from '@lingoda/hooks';
import { updatePreferences } from '@lingoda/students';

interface Props {
    open: boolean;
    popperPropsSx?: SxProps<Theme>;
}

export const MyCourseGuidanceOverlay = ({
    children,
    open,
    popperPropsSx = defaultPopperProps,
}: PropsWithChildren<Props>) => {
    const dispatch = useDispatch();

    const handleDismiss = useCallback(() => {
        dispatch(
            updatePreferences({
                dismissedWidgets: { myCourseGuidanceBanner: true },
            }),
        );
    }, [dispatch]);

    if (!open) {
        return <>{children}</>;
    }

    return (
        <GuidanceOverlay
            open
            title={trans('guidance-overlay-title', {}, 'student-common')}
            body={trans('guidance-overlay-body', {}, 'student-common')}
            onDismiss={handleDismiss}
            popperProps={{
                disablePortal: true,
                placement: 'top-start',
                modifiers: [
                    {
                        name: 'flip',
                        enabled: false,
                    },
                ],
                sx: popperPropsSx,
            }}
        >
            {children}
        </GuidanceOverlay>
    );
};

const defaultPopperProps = {
    position: 'relative !important',
    transform: 'none !important',
};

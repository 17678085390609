import uniqueId from 'lodash/uniqueId';
import { type ComponentProps, useMemo } from 'react';
import { Typography, colorsPalette } from '@lingoda/ui';
import { TextField, useFormContext } from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { type FormValues } from './Form';
import { OptionsField, OptionsSkeleton } from './OptionsField';
import { DialogSticky, type DialogStickyProps } from './DialogSticky';

interface DialogLayoutProps {
    isFormLoading?: boolean;
    subject: string;
    options: ComponentProps<typeof OptionsField>['options'];
    onDismiss: () => void;
    container?: DialogStickyProps['container'];
}

export const DialogLayout = (props: DialogLayoutProps) => {
    const { isFormLoading, options, subject, onDismiss, ...passProps } = props;

    const a11y = useMemo(() => getA11y(), []);

    const { isSubmitting, handleSubmit } = useFormContext<FormValues>();

    return (
        <DialogSticky
            isOpen
            onClose={onDismiss}
            title={trans('dialog-title', {}, 'quiz-report-item')}
            titleProps={{ id: a11y.titleId }}
            aria-labelledby={a11y.titleId}
            confirmLabel={trans('dialog-btn-confirm', {}, 'quiz-report-item')}
            confirmStatus={isSubmitting ? 'loading' : isFormLoading ? 'disabled' : 'enabled'}
            onConfirm={handleSubmit}
            dismissLabel={trans('dialog-btn-cancel', {}, 'quiz-report-item')}
            onDismiss={onDismiss}
            {...passProps}
        >
            <Typography
                variant="body1"
                fontStyle="italic"
                htmlColor={colorsPalette.blackAlpha[70]}
                mb={2}
            >
                {subject}
            </Typography>

            {isFormLoading ? <OptionsSkeleton length={4} /> : <OptionsField options={options} />}

            <TextField
                name="feedback"
                fullWidth
                multiline
                rows={3}
                placeholder={trans('dialog-input-comments', {}, 'quiz-report-item')}
                disabled={isFormLoading}
            />
        </DialogSticky>
    );
};

const getA11y = () => {
    const namespace = 'report-quiz-item-dialog';
    const titleId = uniqueId(`${namespace}-title`);

    return { titleId };
};

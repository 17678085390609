import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { getBaseFilter } from '../utils';
import { defaultBookingFilterSelector } from './defaultBookingFilter';
import { bookingFilterSelector } from './bookingFilter';

export const isBaseFilterDefaultSelector = createSelector(
    defaultBookingFilterSelector,
    bookingFilterSelector,
    (defaultFilters, filters) => isEqual(getBaseFilter(defaultFilters), getBaseFilter(filters)),
);

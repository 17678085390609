import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';
import type * as React from 'react';

const NavigateBefore: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.0303 6.46979C15.3232 6.76269 15.3232 7.23756 15.0303 7.53045L10.5607 12.0001L15.0303 16.4698C15.3232 16.7627 15.3232 17.2376 15.0303 17.5305C14.7374 17.8233 14.2626 17.8233 13.9697 17.5305L8.43934 12.0001L13.9697 6.46979C14.2626 6.1769 14.7374 6.1769 15.0303 6.46979Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export default NavigateBefore;

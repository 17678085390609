import { useEffect, useRef, useState } from 'react';
import { createDate } from '@lingoda/dates';
import { useScheduleCallback } from '@lingoda/hooks';
import { getStartSessionDate, isEndedClass, isInSessionClass } from '../../utils';

interface ClassData {
    startDate: Date;
    endDate: Date;
}

interface Callbacks {
    onStart?: () => void;
    onFinish?: () => void;
}

export const useClassSessionStatusLive = (
    { startDate, endDate }: ClassData,
    callbacks: Callbacks = {},
) => {
    const callbacksRef = useRef(callbacks);
    callbacksRef.current = callbacks;

    const scheduleCallback = useScheduleCallback();

    const [status, setStatus] = useState<ClassSessionStatus>(() =>
        getSessionState(startDate, endDate),
    );

    useEffect(() => {
        () => getSessionState(startDate, endDate);
    }, [startDate, endDate]);

    useEffect(() => {
        switch (status) {
            case ClassSessionStatus.Scheduled: {
                return scheduleCallback(() => {
                    setStatus(ClassSessionStatus.Live);
                    callbacksRef.current.onStart?.();
                }, getStartSessionDate(startDate));
            }
            case ClassSessionStatus.Live: {
                return scheduleCallback(() => {
                    setStatus(ClassSessionStatus.Finished);
                    callbacksRef.current.onFinish?.();
                }, endDate);
            }
        }
    }, [status, startDate, endDate, scheduleCallback]);

    return {
        status,
        isScheduled: status === ClassSessionStatus.Scheduled,
        isLive: status === ClassSessionStatus.Live,
        isFinished: status === ClassSessionStatus.Finished,
    };
};

export enum ClassSessionStatus {
    Scheduled = 'scheduled',
    Live = 'live',
    Finished = 'finished',
}

const getSessionState = (startDate: Date, endDate: Date) => {
    const now = createDate();

    if (isInSessionClass(startDate, endDate, now)) {
        return ClassSessionStatus.Live;
    } else if (isEndedClass(endDate, now)) {
        return ClassSessionStatus.Finished;
    } else {
        return ClassSessionStatus.Scheduled;
    }
};

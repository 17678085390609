import { getLang } from '@lingoda/env';
import { FormattedMessage, trans } from '@lingoda/i18n';
import { LingodaClassroomLogo } from '@lingoda/logotype';
import { Alert, Chip, Link, Stack, colorsPalette } from '@lingoda/ui';

const studentFaqUrl = `https://lingoda-students.elevio.help/${getLang()}/articles/154-how-do-i-attend-my-classes`;
const teacherFaqUrl =
    'https://lingodateachers.bettermode.io/hidden-articles-previous-lunch-4pzfwllt/post/new-in-house-digital-classroom-ueeGSQX5AMRbmae';

interface Props {
    isTeacher?: boolean;
}

export const LingodaNewClassroomAlert = ({ isTeacher }: Props) => {
    return (
        <Alert variant="outlined" severity="promotion" size="compact" icon={false}>
            <Stack direction="row" alignItems="center" mb={1} columnGap={0.5}>
                <LingodaClassroomLogo width={165} height={24} />
                <Chip label="BETA" color="promotion" />
            </Stack>
            <FormattedMessage
                message={trans('lingoda-new-classroom-alert', {}, 'student-common')}
                values={{
                    a: (text: string) => (
                        <Link
                            href={isTeacher ? teacherFaqUrl : studentFaqUrl}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ color: colorsPalette.purpleSolid[100] }}
                        >
                            {text}
                        </Link>
                    ),
                }}
            />
        </Alert>
    );
};

import { MultiToggleField } from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { Box, Typography, colorsPalette } from '@lingoda/ui';
import type { BookingExperienceQuestion } from './types';

interface ToggleQuestionProps {
    question: BookingExperienceQuestion;
}

export const BookingFeedbackToggleQuestion = ({ question }: ToggleQuestionProps) => (
    <>
        <MultiToggleField
            name={question.key}
            items={question.answers.map(({ value, translatedAnswer }) => ({
                value,
                label: translatedAnswer,
            }))}
            fullWidth
            exclusive
        />
        {question.screenPosition === 1 && (
            <Box display="flex" justifyContent="space-between">
                <Typography variant="body2" htmlColor={colorsPalette.negativeCustom.dark}>
                    {trans('not-satisfied', {}, 'student-common')}
                </Typography>
                <Typography variant="body2" htmlColor={colorsPalette.positiveCustom.dark}>
                    {trans('extremely-satisfied', {}, 'student-common')}
                </Typography>
            </Box>
        )}
    </>
);

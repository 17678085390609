import { Box, Typography, colorsPalette, styled } from '@lingoda/ui';

interface ActivityBlockProps {
    value: number;
    label: string;
}

export const ParticipantActivityItem = ({ value, label }: ActivityBlockProps) => {
    return (
        <StyledBox>
            <Typography variant="h2">{value}</Typography>
            <Typography
                variant="subtitle2"
                htmlColor={colorsPalette.blackAlpha[60]}
                marginLeft={0.5}
                marginBottom={0.25}
            >
                {label}
            </Typography>
        </StyledBox>
    );
};

const StyledBox = styled(Box)(({ theme }) => ({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    border: `1px solid ${colorsPalette.blackAlpha[16]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
}));

import { useEffect } from 'react';
import { trans } from '@lingoda/i18n';
import type { TokenDecoded } from '@lingoda/auth';
import { goTo, loginPath, useParams } from '@lingoda/router';
import { Layout } from './Layout';
import type { NewPasswordFlowType } from './types';

interface NewPasswordFormProps {
    type: NewPasswordFlowType;
}

export const NewPassword = ({ type }: NewPasswordFormProps) => {
    const title =
        type === 'SET'
            ? trans('set-your-password', {}, 'public-common')
            : trans('reset-password', {}, 'public-common');
    const { token } = useParams<{ token?: string }>();
    const decodedTokenJSON = token ? (JSON.parse(atob(token)) as TokenDecoded) : undefined;

    useEffect(() => {
        if (!token) {
            goTo(loginPath());
        }
    }, [token]);

    return !decodedTokenJSON ? null : <Layout title={title} decodedTokenJSON={decodedTokenJSON} />;
};

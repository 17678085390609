import { Children, type PropsWithChildren } from 'react';
import { Box, type BoxProps } from '@lingoda/ui';
import { MIN_WIDTH_TILE } from '../constants';

export const ClassGrid = ({ children, ...props }: PropsWithChildren<BoxProps>) => (
    <Box
        width="100%"
        display="grid"
        gridTemplateColumns={`repeat(auto-fill, minmax(${MIN_WIDTH_TILE}px, 1fr))`}
        gap={2}
        {...props}
    >
        {Children.map(children, (child, index) => child && <Box key={index}>{child}</Box>)}
    </Box>
);

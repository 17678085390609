import { createSelector } from 'reselect';
import { studentSelector } from '@lingoda/students';
import curriculumModulesSelector from './curriculumModules';
import expandedModulesSelector from './expandedModules';

const defaultModuleIds: number[] = [];
const studentModuleIds = createSelector(
    studentSelector,
    curriculumModulesSelector,
    (student, curriculumModules) =>
        (student && student.curriculumId && curriculumModules[student.curriculumId]) ||
        defaultModuleIds,
);

export default createSelector(studentModuleIds, expandedModulesSelector, (moduleIds, modules) =>
    modules.filter((item) => moduleIds.includes(item.id)),
);

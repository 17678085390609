import { useMemo } from 'react';
import type { AcceptedLang } from '@lingoda/env';
import { trans } from '@lingoda/i18n';

const congratsMessageKeys = [
    'congrats-msg-well-done',
    'congrats-msg-hurray',
    'congrats-msg-congrats',
    'congrats-msg-congratulations',
    'congrats-msg-great-job',
    'congrats-msg-way-to-go',
    'congrats-msg-keep-up-the-good-work',
    'congrats-msg-keep-it-up',
] as const;

type CongratsMessageKeys = (typeof congratsMessageKeys)[number];

export const useGetRandomizedCongrats = (firstName = '', locale?: AcceptedLang): string => {
    return useMemo<string>(() => {
        const translations: Record<CongratsMessageKeys, string> = {
            'congrats-msg-well-done': trans(
                'congrats-msg-well-done',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-hurray': trans(
                'congrats-msg-hurray',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-congrats': trans(
                'congrats-msg-congrats',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-congratulations': trans(
                'congrats-msg-congratulations',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-great-job': trans(
                'congrats-msg-great-job',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-way-to-go': trans(
                'congrats-msg-way-to-go',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-keep-up-the-good-work': trans(
                'congrats-msg-keep-up-the-good-work',
                { firstName },
                'student-common',
                locale,
            ),
            'congrats-msg-keep-it-up': trans(
                'congrats-msg-keep-it-up',
                { firstName },
                'student-common',
                locale,
            ),
        };
        const randomIndex = Math.floor(Math.random() * congratsMessageKeys.length);

        return translations[congratsMessageKeys[randomIndex]];
    }, [firstName, locale]);
};

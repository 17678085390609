import { useCallback } from 'react';
import type { CalendarType } from '@lingoda/graphql';
import { goTo } from '@lingoda/router';
import { useConnectCalendarLazyQuery } from '@lingoda/graphql';
import { showApolloErrorToast } from '@lingoda/toasts';
import type { ApolloError } from '@apollo/client/errors';

type Props = {
    onError?: (error: ApolloError) => void;
};

export const useConnectCalendar = (props?: Props) => {
    const [connect, { loading, error, variables }] = useConnectCalendarLazyQuery({
        onError: props?.onError || showApolloErrorToast,
    });

    const connectCalendar = useCallback(
        async (type: CalendarType) => {
            const { data } = await connect({ variables: { calendarType: type } });
            if (data?.calendarAuthRedirectUrl) {
                goTo(decodeURIComponent(data.calendarAuthRedirectUrl));
            }
        },
        [connect],
    );

    return {
        connectCalendar,
        loading,
        error,
        type: variables?.calendarType,
    };
};

import { ClassType } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import {
    Alert,
    Box,
    DialogInfo,
    type DialogInfoProps,
    LingodaClassIcon,
    Stack,
    Typography,
} from '@lingoda/ui';
import { colorsPalette } from '@lingoda/ui/theme';

const ClassCreditsType = ({ type }: { type: ClassType }) => (
    <Box display="flex" gap={1}>
        <LingodaClassIcon type={type} />
        <Stack>
            <Typography variant="subtitle1" htmlColor={colorsPalette.blackSolid[100]}>
                {type === ClassType.Group
                    ? trans('group-class-credits', {}, 'student-store')
                    : trans('private-class-credits', {}, 'student-store')}
            </Typography>
            <Typography variant="body1">
                {type === ClassType.Group
                    ? trans('group-class-credits-explanation', {}, 'student-store')
                    : trans('private-class-credits-explanation', {}, 'student-store')}
            </Typography>
        </Stack>
    </Box>
);

const CreditsTypeInfo = () => (
    <Stack gap={3}>
        <Typography variant="body1" htmlColor={colorsPalette.blackSolid[100]}>
            {trans('credits-store-types-info-subtitle', {}, 'student-store')}
        </Typography>
        <Stack gap={2}>
            <ClassCreditsType type={ClassType.Group} />
            <ClassCreditsType type={ClassType.Individual} />
        </Stack>
    </Stack>
);

export const CreditsTypeInfoAlert = () => (
    <Alert
        icon={false}
        severity="success"
        elevation={1}
        variant="outlined"
        title={trans('credits-store-types-info-title', {}, 'student-store')}
    >
        <CreditsTypeInfo />
    </Alert>
);

export const CreditsTypeInfoModal = (props: DialogInfoProps) => (
    <DialogInfo title={trans('credits-store-types-info-title', {}, 'student-store')} {...props}>
        <CreditsTypeInfo />
    </DialogInfo>
);

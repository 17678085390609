import { styled } from '../theme';
import { Box } from './Box';

interface Props {
    disabled?: boolean;
}

export const ScrollBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'disabled' })<Props>(
    ({ disabled }) => ({
        flex: '1 1 auto',
        overflow: disabled ? 'hidden' : 'auto',
    }),
);

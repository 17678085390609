import CheckRound from '@lingoda/ui/icons/CheckRound';
import { Box, Typography, colorsPalette, dvw, styled } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { Image } from './Image';
interface Props {
    isTeamsRegistration: boolean;
}
export const Content = ({ isTeamsRegistration }: Props) => {
    return (
        <ContentStyled>
            <ContainerStyled>
                <TypographyStyled variant="h1">
                    {trans('create-account-to-unlock-lingoda', {}, 'student-registration')}
                </TypographyStyled>
                <ListStyled>
                    <ListItemStyled>
                        <CheckRoundStyled htmlColorDefault={colorsPalette.primarySolid['100']} />
                        <ListItemTextStyled>
                            {isTeamsRegistration
                                ? trans('access-our-platform', {}, 'student-registration')
                                : trans('book-your-classes', {}, 'student-registration')}
                        </ListItemTextStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        <CheckRoundStyled htmlColorDefault={colorsPalette.primarySolid['100']} />
                        <ListItemTextStyled>
                            {trans('test-your-knowledge', {}, 'student-registration')}
                        </ListItemTextStyled>
                    </ListItemStyled>
                    <ListItemStyled>
                        <CheckRoundStyled htmlColorDefault={colorsPalette.primarySolid['100']} />
                        <ListItemTextStyled>
                            {trans('download-learning-materials', {}, 'student-registration')}
                        </ListItemTextStyled>
                    </ListItemStyled>
                </ListStyled>
            </ContainerStyled>
            <Image />
        </ContentStyled>
    );
};

const ContentStyled = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    display: 'grid',
    gridGap: '40px',
    [theme.breakpoints.down(991)]: {
        gridGap: '32px',
        gridTemplateColumns: '370px 1fr',
    },
    [theme.breakpoints.down(767)]: {
        gridGap: '24px',
        gridTemplateColumns: '295px 1fr',
    },
    [theme.breakpoints.down(588)]: {
        gridTemplateColumns: '1fr 160px',
    },
    [theme.breakpoints.down(450)]: {
        gridGap: '12px',
        gridTemplateColumns: '194px 1fr',
    },
    [theme.breakpoints.down(360)]: {
        gridTemplateColumns: '180px 1fr',
    },
    [theme.breakpoints.down(340)]: {
        gridTemplateColumns: '160px 1fr',
    },
}));

const ContainerStyled = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridGap: '40px',
    [theme.breakpoints.down(991)]: {
        gridGap: '32px',
    },
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
    color: colorsPalette.blackSolid['100'],
    fontSize: '40px',
    fontWeight: '700',
    letterSpacing: '-1.5px',
    lineHeight: '1.4',
    marginBlock: '-13px',
    [theme.breakpoints.down(1439)]: {
        fontSize: '38px',
        marginBlock: '-12px',
    },
    [theme.breakpoints.down(1199)]: {
        fontSize: '32px',
        marginBlock: '-9px',
    },
    [theme.breakpoints.down(991)]: {
        fontSize: '35px',
        letterSpacing: '0',
        lineHeight: '1.275',
        marginBlock: '-8px',
    },
    [theme.breakpoints.down(767)]: {
        fontSize: '28px',
        marginBlock: '-7px',
    },
    [theme.breakpoints.down(588)]: {
        fontSize: dvw('clamp(20px, 5.6dvw, 35px)'),
        lineHeight: '1.4',
        marginBlock: '-8px',
    },
    [theme.breakpoints.down(540)]: {
        fontSize: dvw('clamp(20px, 5.3dvw, 35px)'),
    },
    [theme.breakpoints.down(510)]: {
        fontSize: dvw('clamp(20px, 5dvw, 35px)'),
    },
    [theme.breakpoints.down(480)]: {
        fontSize: dvw('clamp(20px, 4.7dvw, 35px)'),
        marginBlock: '-6px',
    },
    [theme.breakpoints.down(460)]: {
        fontSize: dvw('clamp(20px, 4.5dvw, 35px)'),
    },
    [theme.breakpoints.down(450)]: {
        fontSize: '20px',
        lineHeight: '1.3',
        marginBlock: '-5px',
    },
    [theme.breakpoints.down(360)]: {
        fontSize: '18px',
        marginBlock: '-4px',
    },
    [theme.breakpoints.down(340)]: {
        fontSize: '16px',
    },
}));

const ListStyled = styled('ul')(({ theme }) => ({
    color: colorsPalette.blackSolid['100'],
    display: 'grid',
    fontSize: '16px',
    fontWeight: '600',
    gridGap: '16px',
    lineHeight: '1.5',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down(767)]: {
        fontSize: '15px',
    },
    [theme.breakpoints.down(588)]: {
        display: 'none',
    },
}));

const ListItemStyled = styled('li')(() => ({
    alignItems: 'flex-start',
    display: 'grid',
    gridGap: '12px',
    gridTemplateColumns: '22px 1fr',
}));

const ListItemTextStyled = styled('span')(() => ({
    marginBlock: '-6px -5px',
    opacity: '0.7',
    paddingTop: '6px',
}));

const CheckRoundStyled = styled(CheckRound)(() => ({
    height: '20px',
    width: '20px',
}));

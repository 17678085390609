import { cloneElement } from 'react';
import { Box, Grid, Stack, Typography } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import type { ButtonProps } from '@lingoda/ui';
import BaseBanner from './BaseBanner';

interface BannerProps {
    title: string;
    subtitle: string;
    button: React.ReactElement<ButtonProps>;
    illustration: React.ReactNode;
}

const StyledBanner = styled(BaseBanner)(({ theme }) => ({
    border: `1px solid ${colorsPalette.blackAlpha[8]}`,

    display: 'flex',
    flexDirection: 'row',

    padding: 0,

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const IllustrationContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
        right: '-80px',
    },
}));

const StoreBanner = ({ title, subtitle, button, illustration }: BannerProps) => (
    <StyledBanner>
        <IllustrationContainer>{illustration}</IllustrationContainer>

        <Box position="relative" flexBasis="">
            <Grid container spacing={3} p={3} pr={{ xs: 3, sm: 0 }}>
                <Grid item xs={12} sm={8}>
                    <Stack spacing={1}>
                        <Typography variant="h2">{title}</Typography>
                        <Typography variant="body1" htmlColor={colorsPalette.blackAlpha[70]}>
                            {subtitle}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {cloneElement(button, { ...button.props, fullWidth: true })}
                </Grid>
            </Grid>
        </Box>
    </StyledBanner>
);

export default StoreBanner;

import { injectAsyncReducer } from '@lingoda/core';
import { STORE_KEY } from './constants';
import reducers from './reducers';

export { default as reducers } from './reducers';
export type { State as TeachersState } from './reducers';

export * from './actions';
export * from './hooks';
export * from './models';
export * from './selectors';
export * from './constants';
export * from './components';
export * from './utils';

injectAsyncReducer(STORE_KEY, reducers);

import { useMemo } from 'react';
import { FormWrapper } from '@lingoda/forms';
import { type Maybe, useSubmitPracticeSatisfactionScoreMutation } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { showApolloErrorToast } from '@lingoda/toasts';
import { Box, Button, SlidePopupHeader, Stack, Typography } from '@lingoda/ui';
import { ToggleQuestion } from './ToggleQuestion';

interface Props {
    onClose: () => void;
    onSubmitComplete: () => void;
}

export const Step1 = ({ onClose, onSubmitComplete }: Props) => {
    const [submitScore] = useSubmitPracticeSatisfactionScoreMutation({
        onCompleted: () => {
            onSubmitComplete();
        },
        onError: showApolloErrorToast,
    });

    const initialState = useMemo(() => {
        return { [QUESTION_KEY]: null };
    }, []);

    const handleSubmit = (form: { [QUESTION_KEY]: Maybe<string> }) => {
        void submitScore({ variables: { score: Number(form[QUESTION_KEY]) } });
    };

    return (
        <FormWrapper initialValues={initialState} onSubmit={handleSubmit}>
            {({ submitForm, values }) => (
                <Stack spacing={4}>
                    <Stack spacing={3}>
                        <Box>
                            <SlidePopupHeader
                                title={trans(
                                    'how-satisfied-are-you',
                                    {},
                                    'practice-satisfaction-survey',
                                )}
                                onClose={onClose}
                                sx={{ marginBottom: 0.5 }}
                            />
                            <Typography variant="body3">
                                {trans('resource-list', {}, 'practice-satisfaction-survey')}
                            </Typography>
                        </Box>
                        <ToggleQuestion name={QUESTION_KEY} answers={ANSWERS} />
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography>
                            {trans('step-of-max-steps', { step: 1, maxSteps: 2 }, 'student-common')}
                        </Typography>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={!values[QUESTION_KEY]}
                            onClick={submitForm}
                        >
                            {trans('next', {}, 'student-common')}
                        </Button>
                    </Box>
                </Stack>
            )}
        </FormWrapper>
    );
};

const QUESTION_KEY = 'how_satisfied_are_you';

const ANSWERS = [
    {
        value: '1',
        translatedAnswer: '1',
    },
    {
        value: '2',
        translatedAnswer: '2',
    },
    {
        value: '3',
        translatedAnswer: '3',
    },
    {
        value: '4',
        translatedAnswer: '4',
    },
    {
        value: '5',
        translatedAnswer: '5',
    },
    {
        value: '6',
        translatedAnswer: '6',
    },
    {
        value: '7',
        translatedAnswer: '7',
    },
];

import { Weekdays, addWeeks, createDate, format, getWeek } from '@lingoda/dates';
import type { WeekStart } from '@lingoda/dates';

export const getCelebrationWeekId = (numberOfPastWeeks: number, weekStartsOn: WeekStart) => {
    const date = addWeeks(createDate(), -numberOfPastWeeks);

    return `${format('yyyy', date)}_week_${getWeekNumber(date, weekStartsOn)}`;
};

export const getWeekNumber = (date: Date, weekStartsOn: WeekStart) =>
    getWeek(date, {
        weekStartsOn,
        // Determine on which day of the week the first week of the year starts.
        // https://en.wikipedia.org/wiki/ISO_week_date#First_week
        firstWeekContainsDate:
            weekStartsOn === Weekdays.MONDAY ? Weekdays.THURSDAY : Weekdays.WEDNESDAY,
    });

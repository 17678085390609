import { useCallback } from 'react';
import type {
    CreateExtraCreditPurchaseMutationVariables,
    MutationUpdateExtraCreditPurchaseArgs,
} from '@lingoda/graphql';
import {
    useCreateExtraCreditPurchaseMutation,
    useUpdateExtraCreditPurchaseMutation,
} from '@lingoda/graphql';

export type UpdateOrCreateParams =
    | MutationUpdateExtraCreditPurchaseArgs
    | CreateExtraCreditPurchaseMutationVariables;

function isUpdateRequest(
    object: UpdateOrCreateParams,
): object is MutationUpdateExtraCreditPurchaseArgs {
    return Boolean((object as MutationUpdateExtraCreditPurchaseArgs).id);
}

const useCreateOrBuyAdditionalCredits = () => {
    const [createPurchase, { data: createData, loading: creating }] =
        useCreateExtraCreditPurchaseMutation();
    const [updatePurchase, { data: updateData, loading: updating }] =
        useUpdateExtraCreditPurchaseMutation();

    const handlePurchase = useCallback(
        (variables: UpdateOrCreateParams) => {
            if (isUpdateRequest(variables)) {
                void updatePurchase({ variables });
            } else {
                void createPurchase({ variables });
            }
        },
        [updatePurchase, createPurchase],
    );

    const isLoading = creating || updating;
    const purchase = updateData?.purchase || createData?.purchase || undefined;

    return [handlePurchase, purchase, isLoading] as const;
};

export default useCreateOrBuyAdditionalCredits;

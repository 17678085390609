import { useLearningTeamsModuleQuery } from '@lingoda/graphql';
import { useIsLearningTeams } from './useIsLearningTeams';

export const useLearningTeamsModule = () => {
    const isLearningTeams = useIsLearningTeams();
    const { data, loading } = useLearningTeamsModuleQuery({
        skip: !isLearningTeams,
    });

    return { loading, modules: data?.studentLearningTeamInfo?.modules || [] };
};

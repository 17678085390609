import { type ApolloError } from '@lingoda/graphql';
import { showToast, showToasts } from '@lingoda/toasts';
import { getPackagePurchaseErrorMessageOrToasts } from '../utils';

export const useCreatePackagePurchaseErrorHandlers = () => {
    const handleCreatePackagePurchaseError = (error: ApolloError) => {
        const messageOrToasts = getPackagePurchaseErrorMessageOrToasts(error);
        if (typeof messageOrToasts === 'string') {
            showToast('error', messageOrToasts);
        } else if (messageOrToasts.length) {
            showToasts(messageOrToasts);
        }
    };

    return [handleCreatePackagePurchaseError];
};

import { createSelector } from 'reselect';
import { STUDENT_STORE_KEY } from '../constants';
import type { Student } from '../models';

const studentsStateSelector = (state: GlobalState) => state[STUDENT_STORE_KEY];

export default createSelector(
    studentsStateSelector,
    (students: Student[]): ReadonlyArray<Student> => Object.values(students),
);

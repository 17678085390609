import { useEffect, useState } from 'react';
import { loadImg } from '@lingoda/ui';
import { type AchievementFragment } from '@lingoda/graphql';
import { getBadgeUrls } from '../utils';

export const useAchievementsBadgeLoading = (achievements: ReadonlyArray<AchievementFragment>) => {
    const [loading, setLoading] = useState(achievements.length > 0);

    useEffect(() => {
        if (!loading || !achievements.length) {
            return;
        }
        const urls = achievements.map(getActualBadgeUrl);
        void Promise.all(urls.map(loadImg)).finally(() => {
            setLoading(false);
        });
    }, [achievements, loading]);

    return loading;
};

export const useAchievementBadgeLoading = (achievement: AchievementFragment | null) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loading || !achievement) {
            return;
        }
        const { activeBadgeUrl, inactiveBadgeUrl } = getBadgeUrls(achievement.slug);
        const urls = [activeBadgeUrl, inactiveBadgeUrl];
        void Promise.all(urls.map(loadImg)).finally(() => {
            setLoading(false);
        });
    }, [achievement, loading]);

    return loading;
};

const getActualBadgeUrl = (achievement: AchievementFragment) => {
    const { activeBadgeUrl, inactiveBadgeUrl } = getBadgeUrls(achievement.slug);

    return achievement.claimed ? activeBadgeUrl : inactiveBadgeUrl;
};

// There is an issue with Intl.DateTimeFormat obsolete implementation that returns wrong value for some of timezones for older browser version
// (e.g. in Firefox version 97, for America/Monterrey it returns GTM-5 instead of GMT-6 in summer time - Mexico dropped DST in 2023)

import type { UrlLang } from '@lingoda/env';

// This polyfill fixes will force the browser to use the up to date implementation from ""@formatjs/intl-datetimeformat" package which is update frequently
export const polyfillTimezones = async (lang: UrlLang) => {
    // get browser timezone to restore after polyfill is applied
    // Since JS Engines do not expose default timezone, there's currently no way for us to detect local timezone after this polyfill is applied
    // https://formatjs.io/docs/polyfills/intl-datetimeformat/#default-timezone
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // As polyfill will apply its own implementation only when no previous exists (by default)
    // we should load a force logic before, otherwise polyfill will be skipped
    await import('@formatjs/intl-datetimeformat/polyfill-force');
    await Promise.all([
        import('@formatjs/intl-datetimeformat/add-all-tz'),
        mapLocaleToLocaleDataImport(lang),
    ]);

    if ('__setDefaultTimeZone' in Intl.DateTimeFormat) {
        (Intl.DateTimeFormat.__setDefaultTimeZone as (tz: string) => void)(browserTimeZone);
    }
};

const mapLocaleToLocaleDataImport = (locale: UrlLang) => {
    switch (locale) {
        case 'en':
            return import('@formatjs/intl-datetimeformat/locale-data/en');
        case 'de':
            return import('@formatjs/intl-datetimeformat/locale-data/de');
        case 'ru':
            return import('@formatjs/intl-datetimeformat/locale-data/ru');
        case 'fr':
            return import('@formatjs/intl-datetimeformat/locale-data/fr');
        case 'es':
            return import('@formatjs/intl-datetimeformat/locale-data/es');
        case 'it':
            return import('@formatjs/intl-datetimeformat/locale-data/it');
        case 'pl':
            return import('@formatjs/intl-datetimeformat/locale-data/pl');
        case 'tr':
            return import('@formatjs/intl-datetimeformat/locale-data/tr');
        case 'br':
            return import('@formatjs/intl-datetimeformat/locale-data/pt');
        default:
            assertExhaustedSwitch(locale);
            throw new Error(`Unsupported locale: ${locale}`);
    }
};

const assertExhaustedSwitch = (switchCase: never) => {
    console.error(new Error(`Unhandled switch case: ${JSON.stringify(switchCase)}`));
};

export const shouldApplyPolyfillTimezone = () => {
    return !!Object.entries(TIME_ZONE_CHECK_SET).find(
        ([timeZone, expectedOffset]) =>
            !isTimeZoneOffsetExpected(timeZone, expectedOffset, CHECK_DATE),
    );
};

export const isTimeZoneOffsetExpected = (
    timeZone: string,
    expectedOffset: string,
    checkDate: string,
) => {
    const midnightTime = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone,
    }).format(new Date(`${checkDate}${expectedOffset}`));

    return midnightTime === '00:00' || midnightTime === '24:00';
};

// Add more checks when errors, like TimeZoneMismatchError, are reported in Sentry.
// When one of those will got unexpected value, polyfill will be applied.
const TIME_ZONE_CHECK_SET: Record<string, string> = {
    'America/Monterrey': '-06:00',
    'Africa/Cairo': '+03:00',
    'America/Mexico_City': '-06:00',
};

const CHECK_DATE = '2023-09-06T00:00:00';

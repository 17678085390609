import { gaSet } from '@lingoda/analytics';
import { currentCurrencySelector } from '@lingoda/currency';
import type { GlobalState } from '../reducer';

let cachedCurrency: string;

export default (getState: () => GlobalState) => {
    const currencyCode = currentCurrencySelector(getState());

    if (cachedCurrency !== currencyCode && gaSet({ currencyCode })) {
        cachedCurrency = currencyCode as string;
    }
};

import { useMemo } from 'react';
import { createDate } from '@lingoda/dates';
import { useUpcomingAppointmentDatesQuery } from '@lingoda/graphql';
import { showToast } from '@lingoda/toasts';

export const useUpcomingAppointmentDates = () => {
    const query = useUpcomingAppointmentDatesQuery({
        fetchPolicy: 'network-only',
        onError: (error) => {
            showToast('error', error.message);
        },
    });

    const data = useMemo(
        () => query.data?.upcomingAppointmentDates?.map((dateString) => createDate(dateString)),
        [query.data],
    );

    return { ...query, data };
};

import { createRecordFactory } from '@lingoda/utils';
import type { Appointment } from './appointment';

export interface AppointmentExpanded extends Appointment {
    isActive?: boolean;
    isCancellable?: boolean;
    isCompleted?: boolean;
    isNewStudent?: boolean;
}

export const AppointmentRecordExpandedFactory = createRecordFactory<AppointmentExpanded>({
    classId: undefined,
    endDate: undefined,
    feedbackId: undefined,
    id: undefined,
    isActive: undefined,
    isCancellable: undefined,
    isCompleted: undefined,
    isNewStudent: undefined,
    moduleId: undefined,
    startDate: undefined,
    createdAt: undefined,
    status: undefined,
    statusByStudent: undefined,
    studentId: undefined,
});

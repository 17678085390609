import { useState } from 'react';
import { diffInUnits, format } from '@lingoda/dates';
import { Translate, trans, transChoice } from '@lingoda/i18n';
import { Box, DangerousHtml, DialogConfirm, Typography, useResolution } from '@lingoda/ui';
import Checkbox from '@lingoda/ui/fields/Checkbox';
import FormControlLabel from '@lingoda/ui/fields/FormControlLabel';
import { useSchedulePause } from '../../hooks';
import { type RangeState, type StepCommonProps } from '../../types';

export interface ConfirmationStepProps extends StepCommonProps {
    range: RangeState;
    numberOfClassesInPausePeriod: number;
    onDismiss: () => void;
    onPauseSuccess?: () => void;
}

const ConfirmationStep = ({
    range: { startDate, endDate },
    numberOfClassesInPausePeriod,
    isOpen,
    closeDialog,
    onDismiss,
    onPauseSuccess,
}: ConfirmationStepProps) => {
    const isMobile = useResolution('phone');
    const [schedulePause, { loading }] = useSchedulePause();

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    const handleDismiss = () => {
        onDismiss();
    };

    const handleConfirm = async () => {
        try {
            await schedulePause({ startDate, endDate });
            onPauseSuccess?.();
            closeDialog();
        } catch {}
    };

    return (
        <DialogConfirm
            isOpen={isOpen}
            title={trans('subscription-pause-confirmation-title', {}, 'student-common')}
            dismissLabel={trans('subscription-pause-change-dates', {}, 'student-common')}
            onDismiss={handleDismiss}
            confirmLabel={trans('subscription-pause-confirm-pause', {}, 'student-common')}
            onConfirm={handleConfirm}
            onClose={closeDialog}
            maxWidth="md"
            confirmStatus={loading ? 'loading' : isChecked ? 'enabled' : 'disabled'}
            scrollable={isMobile}
        >
            <Box maxWidth={584} pb={{ xs: 2, sm: 0 }}>
                <Typography variant="body1" lineHeight={1.5}>
                    <DangerousHtml>
                        {transChoice(
                            'subscription-pause-scheduled-period',
                            diffInUnits('days', endDate, startDate) + 1,
                            {
                                dateFrom: format('PPP', startDate),
                                dateTo: format('PPP', endDate),
                                numberOfDays: diffInUnits('days', endDate, startDate) + 1,
                            },
                            'student-common',
                        )}
                    </DangerousHtml>
                    <DangerousHtml>
                        {transChoice(
                            'subscription-pause-class-in-period-warning',
                            numberOfClassesInPausePeriod,
                            {
                                numberOfClasses: numberOfClassesInPausePeriod,
                            },
                            'student-common',
                        )}
                    </DangerousHtml>
                    <DangerousHtml>
                        {trans('subscription-pause-confirm-cancellation', {}, 'student-common')}
                    </DangerousHtml>
                </Typography>
                <FormControlLabel
                    sx={{ mt: 3, mr: 0 }}
                    control={
                        <Checkbox
                            sx={{ marginTop: -1, alignSelf: 'start' }}
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label={
                        <Typography variant="body1" lineHeight={1.5}>
                            <Translate
                                id="subscription-pause-accept-terms"
                                domain="student-common"
                            />
                        </Typography>
                    }
                />
            </Box>
        </DialogConfirm>
    );
};

export default ConfirmationStep;

import { type ReactNode } from 'react';
import { formatDistanceToNowStrict } from '@lingoda/dates';
import { useForceRenderInterval } from '@lingoda/hooks';

type FormatDistanceToNowStrictOptions = NonNullable<
    Parameters<typeof formatDistanceToNowStrict>[1]
>;

interface FormattedDistanceToNowStrictProps extends FormatDistanceToNowStrictOptions {
    date: Parameters<typeof formatDistanceToNowStrict>[0];
    updateIntervalMs?: number;
    children?: (message: string) => ReactNode;
}

export const FormattedDistanceToNowStrict = ({
    date,
    updateIntervalMs = 500,
    children: renderMessage = (m) => m,
    ...options
}: FormattedDistanceToNowStrictProps) => {
    useForceRenderInterval(updateIntervalMs);

    return <>{renderMessage(formatDistanceToNowStrict(date, options))}</>;
};

import Translator from 'bazinga-translator';
import { LANG_DEFAULT, getLang } from '@lingoda/env';

interface TranslatorWindow {
    Translator: typeof Translator;
}

declare const window: TranslatorWindow;

Translator.fallback = LANG_DEFAULT;
Translator.defaultDomain = 'messages';
Translator.locale = getLang();

// since bazinga translation relies on IIFE we need to make it available through the window object
if (window) {
    window.Translator = Translator;
}

export interface TransParams {
    [key: string]: string | number | ((chunks: string) => React.ReactChild);
}

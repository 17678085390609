import { httpGet } from '@lingoda/http';
import { buildUrl, getUrl } from '@lingoda/urls';
import type { SectionName } from '@lingoda/sections';
import type { ModuleSchema, ProductSchema, ProductVariantSchema, SectionSchema } from './common';

interface IncludedResponseAttributes {
    products?: ProductSchema[];
    sections?: SectionSchema[];
    modules?: ModuleSchema[];
}

interface ProductVariantResponse {
    data: ProductVariantSchema[];
    included?: IncludedResponseAttributes;
}

export interface ProductVariantsQueryParams {
    ids?: number[];
    productIds?: number[];
    currency?: string;
    section?: SectionName;
    type?: string;
    tags?: string[];
}

export const getProductVariants = (
    query: ProductVariantsQueryParams,
): Promise<ProductVariantResponse> =>
    httpGet(buildUrl(getUrl('api_product_variant_index'), { query }));

import { Grid } from '@lingoda/ui/layout/GridNext';
import { type useBasicActivityChallengeListDataSuspenseQuery } from '@lingoda/graphql';
import { ChallengeBoardItem } from './ChallengeBoardItem';

interface Props {
    activityChallenges: ReturnType<
        typeof useBasicActivityChallengeListDataSuspenseQuery
    >['data']['activityChallenges'];
}

export const MyChallengesBoardLayout = ({ activityChallenges }: Props) => {
    return (
        <Grid container spacing={{ xs: 2, sm: 3 }} data-cy="Challenges achievements board">
            {activityChallenges.map(
                ({ name, publicName, startDate, prizes, currentParticipant, challengeIcon }) => (
                    <Grid key={name} xs={6} sm={4} md={3}>
                        <ChallengeBoardItem
                            challengeName={name}
                            title={publicName}
                            startDate={startDate}
                            prizes={prizes}
                            completedActivities={currentParticipant?.completedActivities ?? 0}
                            challengeIconUrl={challengeIcon?.urls.original}
                        />
                    </Grid>
                ),
            )}
        </Grid>
    );
};

import { memo } from 'react';
import { BoxPerf, Paper, type PaperProps } from '@lingoda/ui';
import type { ClassId } from '@lingoda/classes';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { RouterLink } from '@lingoda/router';
import type { ElementType, MouseEventHandler, ReactNode } from 'react';
import type { CommonProps } from '../../types/props';

interface Props extends Pick<CommonProps, 'linkTo' | 'openInNewTab' | 'isPreview'> {
    classes?: { root: string };
    position?: 'top' | 'middle' | 'bottom';
    children?: ReactNode;
    classId?: ClassId;
    ribbon?: string;
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
}

const PaperStyled = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'position' && prop !== 'isPreview',
})<
    PaperProps & {
        position?: Props['position'];
        component: ElementType;
        isPreview?: Props['isPreview'];
    }
>(({ theme, position, isPreview }) => ({
    overflow: 'hidden',
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(3),
    border: `1px solid ${colorsPalette.blackAlpha[20]}`,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.common.white,
    minHeight: 80,
    cursor: isPreview ? 'not-allowed' : 'pointer',
    textDecoration: 'inherit',
    '&:hover': {
        backgroundColor: colorsPalette.primarySolid[12],
        borderColor: colorsPalette.primaryAlpha[20],
        '& .MuiAvatar-circular': {
            borderColor: colorsPalette.primarySolid[12],
        },
    },
    ...(position &&
        {
            top: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 0,
            },
            middle: {
                borderRadius: 0,
                borderBottom: 0,
            },
            bottom: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            },
        }[position]),
}));

const ClassListItemComponent = ({
    children,
    onMouseEnter,
    onMouseLeave,
    position,
    linkTo,
    openInNewTab,
    isPreview,
}: Props) => {
    return (
        <PaperStyled
            elevation={0}
            position={position}
            component="section"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            isPreview={isPreview}
            {...(linkTo && {
                component: RouterLink,
                to: linkTo,
                target: openInNewTab ? '_blank' : '_self',
            })}
            data-cy="class-item class-list-item"
        >
            <BoxPerf className="display-flex flex-1 align-items-baseline">{children}</BoxPerf>
        </PaperStyled>
    );
};

export const ClassListItem = memo(ClassListItemComponent);

import type { LearningUnitsResponse } from '@lingoda/api';
import { getLesson } from '@lingoda/api';
import { addCallback, addTrackerCallback } from '@lingoda/core';
import { addLearningUnits, fetchLearningUnits } from '@lingoda/learning-units';
import { addModules } from '@lingoda/modules';
import { addLessons, fetchLesson, updateLesson } from './actions';
import { lessonsStateSelector } from './selectors';

export default () => {
    addTrackerCallback(fetchLesson, async (action, store) => {
        const lessonId = action.payload;
        const lessons = lessonsStateSelector(store.getState());

        if (lessons[lessonId]) {
            return Promise.resolve();
        }

        const response = await getLesson(action.payload);

        store.dispatch(addLessons([response.data]));

        if (response.included) {
            store.dispatch(addLearningUnits(response.included.learningUnits));
        }

        return response;
    });

    addTrackerCallback(updateLesson, async (action, store) => {
        const response = await getLesson(action.payload);

        store.dispatch(addLessons([response.data]));

        if (response.included) {
            store.dispatch(addLearningUnits(response.included.learningUnits));
        }

        return response;
    });

    addCallback(fetchLearningUnits.success, (action, store) => {
        const { included } = action.payload.result as LearningUnitsResponse;

        if (included) {
            store.dispatch(addLessons(included.lessons));
            store.dispatch(addModules(included.modules));
        }

        return action.payload;
    });
};

import { createSelector } from 'reselect';
import { creditsSelector } from '@lingoda/credits';
import { isAfter } from '@lingoda/dates';
import studentSelector from './student';

export default createSelector(studentSelector, creditsSelector, (student, credits) =>
    credits && student?.creditIds
        ? student.creditIds
              .map((creditId) => credits[creditId])
              .filter((creditId) => !!creditId)
              .sort((a, b) => (isAfter(a.expireAt, b.expireAt) ? 1 : -1))
        : [],
);

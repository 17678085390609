import { default as MuiChip, type ChipProps as MuiChipProps } from '@mui/material/Chip';
import { CloseIcon16 } from '../../icons/lingodaIcons';
import { type ChipColor } from '../../theme';

export interface ChipProps extends Omit<MuiChipProps, 'size' | 'variant' | 'color'> {
    color: ChipColor;
}

export const Chip = (props: ChipProps) => {
    return <MuiChip deleteIcon={<CloseIcon16 />} {...props} size="small" variant="filled" />;
};

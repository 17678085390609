import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const Lock: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1198 13.9381C10.6185 13.4394 11.2948 13.1592 12 13.1592C12.7053 13.1592 13.3816 13.4394 13.8803 13.9381C14.379 14.4367 14.6591 15.1131 14.6591 15.8183C14.6591 16.5236 14.379 17.1999 13.8803 17.6986C13.3816 18.1973 12.7053 18.4774 12 18.4774C11.2948 18.4774 10.6185 18.1973 10.1198 17.6986C9.6211 17.1999 9.34095 16.5236 9.34095 15.8183C9.34095 15.1131 9.6211 14.4367 10.1198 13.9381ZM12 14.6592C11.6926 14.6592 11.3978 14.7814 11.1804 14.9987C10.9631 15.2161 10.8409 15.5109 10.8409 15.8183C10.8409 16.1257 10.9631 16.4205 11.1804 16.6379C11.3978 16.8553 11.6926 16.9774 12 16.9774C12.3074 16.9774 12.6023 16.8553 12.8196 16.6379C13.037 16.4205 13.1591 16.1257 13.1591 15.8183C13.1591 15.5109 13.037 15.2161 12.8196 14.9987C12.6023 14.7814 12.3074 14.6592 12 14.6592Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.75 12.0909V19.5455C19.75 21.0642 18.5188 22.2955 17 22.2955H7.00003C5.48125 22.2955 4.25003 21.0642 4.25003 19.5455V12.0909C4.25003 10.6409 5.37223 9.45307 6.79549 9.34842V6.90913C6.79549 5.5288 7.34382 4.205 8.31986 3.22896C9.2959 2.25292 10.6197 1.70459 12 1.70459C13.3804 1.70459 14.7041 2.25292 15.6802 3.22896C16.6562 4.205 17.2046 5.5288 17.2046 6.90913V9.34843C18.6278 9.45308 19.75 10.641 19.75 12.0909ZM5.75003 12.0909C5.75003 11.4006 6.30967 10.8409 7.00003 10.8409H17C17.6904 10.8409 18.25 11.4006 18.25 12.0909V19.5455C18.25 20.2358 17.6904 20.7955 17 20.7955H7.00003C6.30967 20.7955 5.75003 20.2358 5.75003 19.5455V12.0909ZM9.38052 4.28962C10.0753 3.59489 11.0175 3.20459 12 3.20459C12.9825 3.20459 13.9248 3.59489 14.6195 4.28962C15.3143 4.98436 15.7046 5.92662 15.7046 6.90913V9.34093H8.29549V6.90913C8.29549 5.92662 8.68579 4.98436 9.38052 4.28962Z"
            fill="currentColor"
        />
    </SvgIcon>
);

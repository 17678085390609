import { trans, transChoice } from '@lingoda/i18n';
import { getChallengeLockedPrizeIcon } from '../assets/icons';

interface Params {
    challengeName: string;
    prizeIndex: number;
    prizeValue: number;
}

export const getChallengePrizeAchievedNotificationContent = ({
    challengeName,
    prizeIndex,
    prizeValue,
}: Params) => {
    const description = transChoice(
        'activity-challenge-notification-description',
        prizeValue,
        { activitiesCount: prizeValue },
        'student-challenges',
    );
    const iconUrl = getChallengeLockedPrizeIcon(prizeIndex);

    switch (challengeName) {
        case 'may-2024': {
            return {
                header: trans('may-2024-title', {}, 'student-challenges'),
                description,
                iconUrl,
            };
        }
        case 'april-2024': {
            return {
                header: trans('april-2024-title', {}, 'student-challenges'),
                description,
                iconUrl,
            };
        }
        case 'march-2024': {
            return {
                header: trans('march-2024-title', {}, 'student-challenges'),
                description,
                iconUrl,
            };
        }
        case 'february-2024': {
            return {
                header: trans('february-2024-title', {}, 'student-challenges'),
                description,
                iconUrl,
            };
        }
        case 'january-2024': {
            return {
                header: trans('january-2024-title', {}, 'student-challenges'),
                description,
                iconUrl,
            };
        }
        case 'december-2023': {
            return {
                header: trans('december-2023-title', {}, 'student-challenges'),
                description,
                iconUrl,
            };
        }
        default: {
            return {
                header: '',
                description,
                iconUrl,
            };
        }
    }
};

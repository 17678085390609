import { createAction } from '@lingoda/core';

export { default as addAppointments } from './addAppointments';
export { cleanStudentClassAppointment } from './cleanStudentClassAppointment';
export { closeRateClassNotification } from './closeRateClassNotification';
export { default as fetchAppointments } from './fetchAppointments';
export { default as removeAppointment } from './removeAppointment';
export { default as updateAppointment } from './updateAppointment';
export { default as addClasses } from './addClasses';
export { default as handleClassesResponse } from './handleClassesResponse';
export { default as removeClass } from './removeClass';
export { default as fetchClassesDone } from './fetchClassesDone';

export const addStudentAppointmentIds = createAction<string[]>(
    'studentAppointment/ADD_SHOW_APPOINTMENT_IDS',
);

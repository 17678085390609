import { injectAsyncReducer } from '@lingoda/core';
import {
    CURRICULUM_MODULE_STORE_KEY,
    MODULE_STORE_KEY,
    STARTING_MODULE_STORE_KEY,
} from './constants';
import curriculumModule from './reducers/curriculumModule';
import module from './reducers/module';
import startingModule from './reducers/startingModule';
import initSideEffects from './side-effects';

export * from './actions';
export * from './models';
export * from './selectors';
export * from './utils';
export { default as modulesReducer } from './reducers/module';

injectAsyncReducer(MODULE_STORE_KEY, module);
injectAsyncReducer(STARTING_MODULE_STORE_KEY, startingModule);
injectAsyncReducer(CURRICULUM_MODULE_STORE_KEY, curriculumModule);

initSideEffects();

import { Box, Button, Hidden, Img, Paper, Typography } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { studentStorePath } from '@lingoda/router';
import { useSelector } from '@lingoda/hooks';
import { sectionNameSelector } from '@lingoda/auth';
import { trans } from '@lingoda/i18n';
import splashImage from './splash.png';

const BannerGradient = styled(Box)({
    position: 'relative',
    background: `linear-gradient(261.14deg, ${colorsPalette.pinkSolid[100]} -8.56%, ${colorsPalette.purpleSolid[100]} 50%, ${colorsPalette.primarySolid[100]} 125.64%)`,
    borderRadius: 'inherit',
});

const FadedImageContainer = styled(Box)({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    maskImage: 'linear-gradient(to right, rgba(0, 0, 0, 0), 30% , rgba(0, 0, 0, 1))',
    borderTopRightRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
});

export const SubscribeLingodaBanner = () => {
    const sectionName = useSelector(sectionNameSelector);

    return (
        <Paper elevation={1}>
            <BannerGradient>
                <Hidden lgDown>
                    <FadedImageContainer>
                        <Img src={splashImage} height="100%" alt="splash" />
                    </FadedImageContainer>
                </Hidden>
                <Box position="relative" p={[4, 6]} maxWidth={{ lg: '65%' }}>
                    <Box display="flex" flexDirection="column" gap={2} mb={3}>
                        <Typography variant="h2" htmlColor={colorsPalette.whiteSolid[100]}>
                            {trans(
                                'subscribe-banner-title',
                                {
                                    language: trans(
                                        /* @ignore */ `section-name-${sectionName}`,
                                        {},
                                        'student-store',
                                    ),
                                },
                                'student-store',
                            )}
                        </Typography>
                        <Typography variant="body1" htmlColor={colorsPalette.whiteSolid[100]}>
                            {trans('subscribe-banner-body', {}, 'student-store')}
                        </Typography>
                    </Box>
                    <Button color="primary" variant="contained" inverted href={studentStorePath()}>
                        {trans('subscribe-banner-button', {}, 'student-store')}
                    </Button>
                </Box>
            </BannerGradient>
        </Paper>
    );
};

import { RouterLink, loginPath } from '@lingoda/router';
import { trans } from '@lingoda/i18n';
import { Typography, colorsPalette, styled } from '@lingoda/ui';

export const Login = () => {
    return (
        <>
            <TextStyled variant="body1">
                <TextInnerStyled>
                    {trans('already-have-an-account', {}, 'public-common')}{' '}
                    <RouterLinkStyled aria-label="Log in" to={loginPath()}>
                        {trans('form-login-button', {}, 'public-common')}
                    </RouterLinkStyled>
                </TextInnerStyled>
            </TextStyled>
            <SeparatorStyled>
                <SeparatorInnerStyled>or</SeparatorInnerStyled>
            </SeparatorStyled>
        </>
    );
};

const RouterLinkStyled = styled(RouterLink)(() => ({
    color: colorsPalette.primarySolid[100],
    fontWeight: '600',
    textDecoration: 'none',
    '&:hover': {
        color: colorsPalette.primaryCustom.dark,
    },
}));

const SeparatorStyled = styled(Typography)(() => ({
    alignItems: 'center',
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'center',
    position: 'relative',
    '&::before': {
        content: '""',
        backgroundColor: colorsPalette.blackAlpha[20],
        height: '1px',
        position: 'absolute',
        width: '100%',
    },
}));

const SeparatorInnerStyled = styled('span')(() => ({
    backgroundColor: colorsPalette.whiteSolid[100],
    marginBlock: '-7px -5px',
    padding: '8px 10px',
    position: 'relative',
}));

const TextStyled = styled(Typography)(() => ({
    color: colorsPalette.blackSolid[100],
    position: 'relative',
    textAlign: 'center',
}));

const TextInnerStyled = styled('span')(() => ({
    display: 'block',
    marginBlock: '-5px -6px',
}));

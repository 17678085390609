import { handleActionsImmutably } from '@lingoda/core';
import { clearFilter, setFilter } from '../actions';
import type { BookingFilter } from '../models';

type SetFilterAction = ReturnType<typeof setFilter>;

export type State = Partial<BookingFilter>;

export default handleActionsImmutably<State, SetFilterAction['payload']>(
    {
        [`${setFilter}`]: (state: State, { payload }: SetFilterAction) => ({
            ...state,
            ...payload,
        }),
        [`${clearFilter}`]: () => ({}),
    },
    {},
);

import { type CurriculumId, type SectionName } from '@lingoda/sections';
import { type FormMessages } from '@lingoda/utils';
import {
    type FormProps,
    Form as LingodaForm,
    PasswordTextField,
    TextField,
    buildValidator,
    withFormWrapper,
} from '@lingoda/forms';
import { trans } from '@lingoda/i18n';
import { Button, Stack, styled } from '@lingoda/ui';

export interface FormValues {
    curriculumId?: CurriculumId;
    email: string;
    fullName: string;
    marketing: boolean;
    password: string;
    privacy: boolean;
    section: SectionName;
}

interface OwnProps {
    curriculumId?: CurriculumId;
    disabled: boolean;
    onSubmit: (values: FormValues) => Promise<unknown>;
    section: SectionName;
}

type Props = OwnProps & FormProps<FormValues>;

const Form = ({ disabled, isSubmitting, isValid }: Props) => {
    return (
        <LingodaForm noValidate>
            <Stack rowGap={1}>
                <TextField
                    autoComplete="name"
                    fullWidth
                    name="fullName"
                    placeholder={trans('name-required', {}, 'student-registration')}
                    readOnly={isSubmitting ?? disabled}
                    type="text"
                    variant="outlined"
                />
                <TextField
                    autoComplete="email"
                    fullWidth
                    name="email"
                    placeholder={trans('email-required', {}, 'student-registration')}
                    readOnly={isSubmitting ?? disabled}
                    type="email"
                    variant="outlined"
                />
                <PasswordTextField
                    autoComplete="new-password"
                    fullWidth
                    name="password"
                    placeholder={trans('password-required', {}, 'student-registration')}
                    readOnly={isSubmitting ?? disabled}
                    reveal={true}
                    variant="outlined"
                />
                <ButtonStyled
                    color="primary"
                    variant="contained"
                    disabled={!isValid ?? disabled}
                    fullWidth
                    loading={isSubmitting}
                    type="submit"
                >
                    {trans('create-account', {}, 'student-registration')}
                </ButtonStyled>
            </Stack>
        </LingodaForm>
    );
};

export default withFormWrapper<OwnProps, FormValues>({
    mapPropsToValues: ({ section, curriculumId }) => ({
        curriculumId,
        email: '',
        fullName: '',
        marketing: false,
        password: '',
        privacy: true,
        section,
    }),
    isInitialValid: false,
    validate: buildValidator({
        curriculumId: {
            required: false,
        },
        email: {
            required: true,
            email: true,
        },
        fullName: {
            required: true,
        },
        password: {
            required: true,
            password: true,
        },
        privacy: {
            required: trans('form-validation-privacy', {}, 'public-common'),
        },
        section: {
            required: true,
        },
    }),
    handleSubmit: (values, bag) => {
        bag.props.onSubmit(values).catch((errors: FormMessages) => {
            if (errors.token) {
                bag.resetForm();

                return;
            }
            bag.setErrors(errors);
            bag.setSubmitting(false);
        });
    },
})(Form);

const ButtonStyled = styled(Button)(() => ({
    marginTop: '10px',
}));

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const LockThick = ({ gradient, ...props }: SvgIconProps & { gradient?: boolean }) => (
    <SvgIcon {...props} viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 3C7.40326 3 6.83097 3.23705 6.40901 3.65901C5.98705 4.08097 5.75 4.65326 5.75 5.25V6H10.25V5.25C10.25 4.65326 10.0129 4.08097 9.59099 3.65901C9.16903 3.23705 8.59674 3 8 3ZM11.75 6V5.25C11.75 4.25544 11.3549 3.30161 10.6516 2.59835C9.94839 1.89509 8.99456 1.5 8 1.5C7.00544 1.5 6.05161 1.89509 5.34835 2.59835C4.64509 3.30161 4.25 4.25544 4.25 5.25V6H3.5C3.08579 6 2.75 6.33579 2.75 6.75V12.75C2.75 13.1642 3.08579 13.5 3.5 13.5H12.5C12.9142 13.5 13.25 13.1642 13.25 12.75V6.75C13.25 6.33579 12.9142 6 12.5 6H11.75ZM4.25 7.5V12H11.75V7.5H4.25Z"
            fill={gradient ? 'url(#purpleToPrimary)' : 'currentColor'}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99933 8.75C8.1307 8.74974 8.26083 8.7754 8.38227 8.82551C8.50392 8.87571 8.61445 8.94945 8.7075 9.0425C8.80055 9.13555 8.87429 9.24608 8.92449 9.36773C8.97452 9.48896 9.00017 9.61886 9 9.75C9.00017 9.88114 8.97452 10.011 8.92449 10.1323C8.87429 10.2539 8.80055 10.3644 8.7075 10.4575C8.61445 10.5505 8.50392 10.6243 8.38227 10.6745C8.26083 10.7246 8.1307 10.7503 7.99933 10.75C7.73436 10.7498 7.48027 10.6445 7.29289 10.4571C7.10536 10.2696 7 10.0152 7 9.75C7 9.48478 7.10536 9.23043 7.29289 9.04289C7.48027 8.85551 7.73436 8.75018 7.99933 8.75Z"
            fill={gradient ? 'url(#purpleToPrimary)' : 'currentColor'}
        />
        <defs>
            <linearGradient
                id="purpleToPrimary"
                x1="19.7885"
                y1="-4.62903"
                x2="-1.7171"
                y2="-1.6365"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#153BFF" />
                <stop offset="1" stopColor="#9900CC" />
            </linearGradient>
        </defs>
    </SvgIcon>
);

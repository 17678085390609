import { forwardRef, useCallback, useState } from 'react';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { useToggleState } from '@lingoda/hooks';
import { Box } from '../../layout/Box';
import { styled } from '../../theme';
import { useResolution } from '../../hooks';
import { Popper, type PopperProps } from '../../utils/Popper';
import { ClickAwayListener } from '../../utils/ClickAwayListener';
import { TimeSlotInput } from './TimeSlotInput';
import { TimeSlotPickerMenu } from './TimeSlotPickerMenu';
import { TimeSlotPickerDialog } from './TimeSlotPickerDialog';

export interface TimeSlotPickerProps {
    name: string;
    label?: string;
    mobileTitle?: string;
    mobileConfirmLabel?: string;
    value?: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
    disabledSlots?: number[];
    scheduledSlots?: number[];
    loading?: boolean;
    timeFormat: TimeFormat;
    popperMaxWidth?: number;
}

const TimeSlotPickerComponent = (
    {
        name,
        label,
        mobileTitle,
        mobileConfirmLabel,
        value,
        disabled,
        disabledSlots,
        scheduledSlots,
        loading,
        timeFormat,
        onChange,
        popperMaxWidth = 328,
    }: TimeSlotPickerProps,
    inputRef: React.Ref<HTMLInputElement>,
) => {
    const isMobile = useResolution('phone');
    const [isOpen, openTimeSlotPicker, closeTimeSlotPicker] = useToggleState(false);
    const [anchorElement, setAnchorElement] = useState<PopperProps['anchorEl'] | null>(null);

    const toggleMenu = () => {
        if (isOpen) {
            closeTimeSlotPicker();
        } else {
            openTimeSlotPicker();
        }
    };

    const handleInputClick = (ev: React.MouseEvent<HTMLDivElement>) => {
        const popperRef = ev.currentTarget as unknown as PopperProps['anchorEl'];
        if (isOpen) {
            setAnchorElement(null);
        } else {
            setAnchorElement(popperRef);
        }
        toggleMenu();
    };

    const handleChange = useCallback(
        (newTimeSlot: number) => {
            onChange(newTimeSlot);
            closeTimeSlotPicker();
        },
        [closeTimeSlotPicker, onChange],
    );

    return (
        <Box>
            <TimeSlotInput
                name={name}
                label={label || trans('time-label', {}, 'public-common')}
                value={value}
                disabled={disabled}
                loading={loading}
                timeFormat={timeFormat}
                opened={isOpen}
                onClick={handleInputClick}
                ref={inputRef}
            />
            {isMobile && isOpen && (
                <TimeSlotPickerDialog
                    value={value}
                    disabled={disabled}
                    disabledSlots={disabledSlots}
                    scheduledSlots={scheduledSlots}
                    loading={loading}
                    timeFormat={timeFormat}
                    onChange={handleChange}
                    onClose={closeTimeSlotPicker}
                    isOpen={isOpen}
                    title={mobileTitle}
                    confirmLabel={mobileConfirmLabel}
                />
            )}
            {!isMobile && isOpen && (
                <ClickAwayListener onClickAway={closeTimeSlotPicker}>
                    <PopperStyled
                        open={isOpen}
                        placement="bottom-start"
                        anchorEl={anchorElement}
                        modifiers={[
                            { name: 'arrow', enabled: false },
                            { name: 'flip', enabled: true },
                        ]}
                        sx={{ p: 2, maxWidth: popperMaxWidth }}
                    >
                        <TimeSlotPickerMenu
                            timeFormat={timeFormat}
                            value={value}
                            disabledSlots={disabledSlots}
                            scheduledSlots={scheduledSlots}
                            onChange={handleChange}
                            disabled={disabled || loading}
                        />
                    </PopperStyled>
                </ClickAwayListener>
            )}
        </Box>
    );
};

export const TimeSlotPicker = forwardRef<HTMLInputElement, TimeSlotPickerProps>(
    TimeSlotPickerComponent,
);

const PopperStyled = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.tooltip,
    borderRadius: 4,
    boxShadow: theme.shadows[3],
    background: theme.palette.common.white,
}));

import { SvgIcon, type SvgIconProps } from '@lingoda/ui';

const PayInRates = (props: SvgIconProps) => (
    <SvgIcon {...props} className="rate-icon">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 11.25a3.25 3.25 0 1 0 0 6.5 3.25 3.25 0 0 0 0-6.5Zm0 1.5a1.75 1.75 0 1 0 0 3.5 1.75 1.75 0 0 0 0-3.5Z"
        />
        <path d="M5 10a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.333 7.25c-1.132 0-2.083.9-2.083 2.05v10.4c0 1.15.95 2.05 2.083 2.05h17.334c1.132 0 2.083-.9 2.083-2.05V9.3c0-1.15-.95-2.05-2.083-2.05H3.333Zm0 1.5a.567.567 0 0 0-.583.55v10.4c0 .286.243.55.583.55h17.334c.34 0 .583-.264.583-.55V9.3a.567.567 0 0 0-.583-.55H3.333Z"
        />
        <path d="M19 17a1 1 0 1 1 0 2 1 1 0 0 1 0-2ZM3 4.25a.75.75 0 0 0 0 1.5h18a.75.75 0 0 0 0-1.5H3ZM6 1.25a.75.75 0 0 0 0 1.5h12a.75.75 0 0 0 0-1.5H6Z" />
    </SvgIcon>
);

export default PayInRates;

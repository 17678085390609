import { useEffect, useState } from 'react';
import { useToggleState } from '@lingoda/hooks';
import { Grid, Loader, ToggleButton } from '@lingoda/ui';
import { getAnswersOnboardingSurvey } from '@lingoda/api';

interface Props {
    onSelect: (answer: string) => void;
    currentValue: string | undefined;
}

export const OnboardingSurvey = ({ onSelect, currentValue }: Props) => {
    const [answers, loading] = useOnboardingSurveyAnswers();

    return loading ? (
        <Loader />
    ) : (
        <Grid item xs={12} container spacing={2} justify="center">
            {answers.map((answer) => (
                <Grid item xs={12} md="auto" key={answer}>
                    <ToggleButton
                        fullWidth
                        selected={answer === currentValue}
                        onClick={() => onSelect(answer)}
                        value={answer}
                    >
                        {answer}
                    </ToggleButton>
                </Grid>
            ))}
        </Grid>
    );
};

const useOnboardingSurveyAnswers = () => {
    const [answers, setAnswers] = useState<string[]>([]);
    const [loading, statLoading, finishLoading] = useToggleState();

    useEffect(() => {
        statLoading();
        void getAnswersOnboardingSurvey()
            .then(({ data }) => {
                setAnswers(data);
            })
            .finally(finishLoading);
    }, [statLoading, finishLoading]);

    return [answers, loading] as const;
};

import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from '@lingoda/hooks';
import { STATUS_FAILED, STATUS_PENDING, STATUS_SUCCESS, requestsSelector } from '../request';
import type { ActionMeta } from '../store/createAction';

interface Meta {
    id: string;
}

type Callback = (input: unknown) => void;

export const useRequestTracker = () => {
    const requests = useSelector(requestsSelector);

    const handlersRef = useRef<{ [key: string]: { resolve: Callback; reject: Callback } }>({});
    const handlers = handlersRef.current;

    const trackRequest = useCallback(
        async <A extends ActionMeta<unknown, M>, M extends Meta>(
            action: Promise<A> | A | string,
        ) => {
            const actionId =
                typeof action === 'string' ? action : (await Promise.resolve(action)).meta.id;

            return new Promise((resolve, reject) => {
                handlers[actionId] = { resolve, reject };
            });
        },
        [handlers],
    );

    useEffect(() => {
        for (const id of Object.keys(handlers)) {
            // Match IDs to requests in store and proceed only if we have matching request
            if (!requests[id]) {
                continue;
            }

            // Fetch latest request object
            const curr = requests[id];
            if (curr.status === STATUS_PENDING) {
                continue;
            }

            const handler = handlers[id];
            delete handlers[id];

            try {
                if (curr.status === STATUS_SUCCESS) {
                    handler.resolve(curr.response);
                } else if (curr.status === STATUS_FAILED) {
                    handler.reject(curr.response);
                }
            } catch {}
        }
    }, [requests, handlers]);

    return trackRequest;
};

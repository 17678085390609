import { createSelector } from 'reselect';
import pendingNotificationsSelector from './pendingNotifications';
import { notificationsShowTypeSelector } from './notificationsShowType';

export const pendingVisibleNotificationsSelector = createSelector(
    pendingNotificationsSelector,
    notificationsShowTypeSelector,
    (notifications, showType) => {
        return filterVisibleNotifications(notifications, showType);
    },
);

export const filterVisibleNotifications = (
    notifications: ReturnType<typeof pendingNotificationsSelector>,
    showType: ReturnType<typeof notificationsShowTypeSelector>,
) => {
    if (showType === 'All') {
        return notifications;
    }

    if (showType === 'None') {
        return [];
    }

    return notifications.filter((notification) => !notification.seen);
};

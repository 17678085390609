import { fadeColor } from '../utils';

const appleSolid = {
    100: '#000',
};

const appleAlpha = fadeColor(appleSolid[100], 80);

const facebookSolid = {
    100: '#1877F2',
};

const facebookAlpha = fadeColor(facebookSolid[100], 80);

const linkedInSolid = {
    100: '#0A66C2',
};

const linkedInAlpha = fadeColor(linkedInSolid[100], 80);

const twitterSolid = {
    100: '#1DA1F2',
};

const twitterAlpha = fadeColor(twitterSolid[100], 80);

const whatsappSolid = {
    100: '#25D366',
};

const whatsappAlpha = fadeColor(whatsappSolid[100], 80);

const facebookMessengerSolid = {
    100: '#0695FF',
};

const facebookMessengerAlpha = fadeColor(facebookMessengerSolid[100], 80);

export const colorsPaletteThirdParty = {
    appleSolid,
    appleAlpha,
    facebookSolid,
    facebookAlpha,
    linkedInSolid,
    linkedInAlpha,
    twitterSolid,
    twitterAlpha,
    whatsappSolid,
    whatsappAlpha,
    facebookMessengerSolid,
    facebookMessengerAlpha,
} as const;

import { type BookingFilter } from '@lingoda/booking';
import {
    ClassTypeFilter,
    type FilterProps,
    MultipleDatesFilter,
    TimeSlotFilter,
} from '@lingoda/booking-filter';
import type { TimeFormat } from '@lingoda/dates';
import { trans } from '@lingoda/i18n';
import { Box, Button, Stack } from '@lingoda/ui';

export interface BookingFilterDesktopSlotsProps extends FilterProps<BookingFilter> {
    onReset: () => void;
    timeFormat: TimeFormat;
}

export const BookingFilterDesktopSlots = ({
    value,
    onChange,
    onReset,
    timeFormat,
}: BookingFilterDesktopSlotsProps) => {
    return (
        <Stack
            direction="row"
            gap={{ xs: 2 }}
            alignItems="flex-end"
            justifyContent="flex-start"
            flexWrap="wrap"
        >
            <Box flex={1} minWidth={200}>
                <MultipleDatesFilter
                    value={value.dates}
                    onChange={onChange}
                    timeSlots={value.timeSlots}
                />
            </Box>
            <Box flex={1} minWidth={200}>
                <TimeSlotFilter
                    value={value.timeSlots}
                    onChange={onChange}
                    timeFormat={timeFormat}
                />
            </Box>
            <ClassTypeFilter value={value.classType} onChange={onChange} />
            <Button color="primary" variant="outlined" onClick={onReset}>
                {trans('btn-clear-all', {}, 'student-common')}
            </Button>
        </Stack>
    );
};

import { createDate, differenceInHours } from '../date-fns';

export const getRemainingTimeUnits = (startDate: Date, now: Date = createDate()) => {
    const hours = differenceInHours(startDate, now);

    if (hours >= 1) {
        return 'hour';
    }

    return 'minute';
};

import { Box, Skeleton, Typography, styled } from '@lingoda/ui';
import { ButtonCta, EntryPaper } from './Entry';

export const EntrySkeleton = () => {
    return (
        <PaperStyled disabled>
            <Content>
                <Box>
                    <Typography variant="h2" mb={0.5}>
                        <Skeleton variant="text" width="180px" />
                    </Typography>

                    <Typography variant="body2">
                        <Skeleton variant="text" width="220px" />
                    </Typography>
                </Box>

                <Skeleton variant="rounded">
                    <ButtonCta>placeholder</ButtonCta>
                </Skeleton>
            </Content>

            <SkeletonBackground variant="rounded" />
        </PaperStyled>
    );
};

const SkeletonBackground = styled(Skeleton)(() => ({
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}));

const PaperStyled = styled(EntryPaper)(() => ({
    height: '100%',
    justifyContent: 'space-between',
}));

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

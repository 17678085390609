import { addCurrentUserInfo, createAction, makeActionTrackable } from '@lingoda/core';

interface Payload {
    studentInfo: {
        birthCity?: string;
        birthCountry?: string;
    };
}

export default makeActionTrackable(
    addCurrentUserInfo(createAction<Payload>('app/student/UPDATE_CERTIFICATE_INFO')),
);

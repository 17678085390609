import { useDispatch, useStepper, useTimeout } from '@lingoda/hooks';
import { trans } from '@lingoda/i18n';
import { updatePreferences } from '@lingoda/students';
import { SlidePopupSuccessLayout, ViewCase, ViewSwitch } from '@lingoda/ui';
import { createDate, formatIso } from '@lingoda/dates';
import { Step1 } from './Step1';
import { Step2 } from './Step2';

interface Props {
    onClose: () => void;
}

export const Content = ({ onClose }: Props) => {
    const dispatch = useDispatch();
    const stepper = useStepper({ steps: SCREENS_AMOUNT });

    const handleClose = () => {
        dispatch(
            updatePreferences({
                dismissedWidgets: { practiceSatisfactionSurvey: formatIso(createDate()) },
            }),
        );
        onClose();
    };

    useTimeout(handleClose, stepper.isFinished ? 2000 : null);

    const handleNext = () => {
        stepper.next();
    };

    const handleSubmit = () => {
        stepper.goTo(SCREENS_AMOUNT);
    };

    return (
        <ViewSwitch value={stepper.currentStep}>
            <ViewCase value={0}>
                <Step1 onClose={handleClose} onSubmitComplete={handleNext} />
            </ViewCase>

            <ViewCase value={1}>
                <Step2 onClose={handleClose} onSubmitComplete={handleSubmit} />
            </ViewCase>

            <ViewCase value={SCREENS_AMOUNT}>
                <SlidePopupSuccessLayout
                    message={trans('feedback-submitted', {}, 'student-common')}
                />
            </ViewCase>
        </ViewSwitch>
    );
};

const SCREENS_AMOUNT = 2;

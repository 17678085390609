import { type ComponentProps, useMemo } from 'react';
import {
    SessionExerciseType,
    useFlashcardReportDialogSubmitMutation,
    useReportItemDialogOptionsQuery,
} from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { showApolloErrorToast, showToast } from '@lingoda/toasts';
import { ReportContentDialog } from '@lingoda/quiz-ui';
import type { VocabularyItemType } from '../types';

interface ReportItemDialogProps {
    item: VocabularyItemType;
    close: () => void;
}

export const ReportItemDialog = ({ item, close }: ReportItemDialogProps) => {
    const [options] = useQuery();

    const handleSubmitSuccess = () => {
        showToast('success', trans('success-msg', {}, 'quiz-report-item'));
        close();
    };

    const [submit] = useFlashcardReportDialogSubmitMutation({
        onCompleted: handleSubmitSuccess,
        onError: showApolloErrorToast,
    });

    const handleSubmit: ComponentProps<typeof ReportContentDialog>['onSubmit'] = async (values) => {
        const answersIds = values.options.map((option) => option.id);

        return await submit({
            variables: {
                vocabularyItemId: item.id,
                answersIds,
                feedback: values.feedback,
            },
        });
    };

    return (
        <ReportContentDialog
            isFormLoading={options.length < 1}
            subject={''}
            options={options}
            onSubmit={handleSubmit}
            onDismiss={close}
        />
    );
};

const useQuery = () => {
    const { data } = useReportItemDialogOptionsQuery({
        variables: {
            exerciseType: SessionExerciseType.Flashcard,
        },
        onError: showApolloErrorToast,
    });

    const options = useMemo(() => {
        const optionsData = data?.quizFeedbackQuestions?.[0]?.answers || [];

        return optionsData.filter((item): item is NonNullable<typeof item> => !!item);
    }, [data]);

    return [options] as const;
};

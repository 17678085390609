import { useEffect } from 'react';
import ConfirmationStep from './ConfirmationStep';
import type { ConfirmationStepProps } from './ConfirmationStep';

type ConfirmationStepProxyProps = Omit<ConfirmationStepProps, 'range'> & {
    range?: ConfirmationStepProps['range'];
};

const ConfirmationStepProxy = ({ range, onDismiss, ...props }: ConfirmationStepProxyProps) => {
    useEffect(() => {
        if (!range) {
            onDismiss();
        }
    }, [range, onDismiss]);

    if (!range) {
        return null;
    }

    return <ConfirmationStep {...props} range={range} onDismiss={onDismiss} />;
};

export default ConfirmationStepProxy;

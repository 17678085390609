import { useCallback } from 'react';
import { trans } from '@lingoda/i18n';
import { LingodaClassIcon, Stack, ToggleButton, ToggleButtonGroup, useUiEvent } from '@lingoda/ui';
import { ClassType } from '@lingoda/graphql';
import { actionFilter } from '@lingoda/analytics';
import { FilterLabel } from './FilterLabel';
import type { FilterProps } from './model';

export const ClassTypeFilter = ({ value, onChange }: FilterProps<ClassType>) => {
    const addEvent = useUiEvent();

    const handleChange = useCallback(
        (_e: React.FormEvent<HTMLElement>, classType: ClassType) => {
            if (!classType) {
                return;
            }

            void addEvent(actionFilter('Group/Private'), {
                value: classType,
            });

            onChange({ classType });
        },
        [addEvent, onChange],
    );

    return (
        <Stack width="auto" spacing={1}>
            <FilterLabel>{trans('group-or-private-filter', {}, 'student-common')}</FilterLabel>
            <ToggleButtonGroup value={value} onChange={handleChange} exclusive>
                {classTypes.map((classType) => (
                    <ToggleButton
                        key={classType}
                        value={classType}
                        sx={{ height: 48 }}
                        aria-label={`Select ${classType} class type`}
                    >
                        <LingodaClassIcon type={classType} />
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Stack>
    );
};

const classTypes = [ClassType.Group, ClassType.Individual];

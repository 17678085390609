import { useMemo } from 'react';
import { Box, styled } from '@lingoda/ui';
import { type Prize } from '../../types';
import { usePrizeIcons } from '../../hooks';

interface Props {
    prizes: ReadonlyArray<Prize>;
    completedActivities: number;
    label: string;
    challengeIconUrl: string | undefined;
}

export const ChallengeBoardPrizes = ({
    prizes,
    completedActivities,
    label,
    challengeIconUrl,
}: Props) => {
    const userPrizes = useMemo(
        () => prizes.filter(({ value }) => completedActivities >= value),
        [completedActivities, prizes],
    );

    const { unlockedIcons } = usePrizeIcons(userPrizes);

    const containerSize = 80;

    if (!unlockedIcons.length) {
        return (
            <ImgStyled
                src={challengeIconUrl}
                width={containerSize}
                height={containerSize}
                alt={label}
            />
        );
    }

    if (unlockedIcons.length === 3) {
        const imgSize = 56;

        return (
            <Box position="relative" width={120} height={containerSize}>
                <ImgStyled
                    src={unlockedIcons[0]}
                    width={imgSize}
                    height={imgSize}
                    alt={userPrizes[0].noun}
                    sx={{ position: 'absolute', bottom: 0, left: 0, zIndex: 3 }}
                />
                <ImgStyled
                    src={unlockedIcons[1]}
                    width={imgSize}
                    height={imgSize}
                    alt={userPrizes[1].noun}
                    sx={{ position: 'absolute', top: 0, left: 32, zIndex: 2 }}
                />
                <ImgStyled
                    src={unlockedIcons[2]}
                    width={imgSize}
                    height={imgSize}
                    alt={userPrizes[2].noun}
                    sx={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1 }}
                />
            </Box>
        );
    }

    if (unlockedIcons.length === 2) {
        const imgSize = 56;

        return (
            <Box position="relative" width={120} height={containerSize}>
                <ImgStyled
                    src={unlockedIcons[0]}
                    width={imgSize}
                    height={imgSize}
                    alt={userPrizes[0].noun}
                    sx={{ position: 'absolute', bottom: 0, left: 16, zIndex: 2 }}
                />
                <ImgStyled
                    src={unlockedIcons[1]}
                    width={imgSize}
                    height={imgSize}
                    alt={userPrizes[1].noun}
                    sx={{ position: 'absolute', top: 0, right: 16, zIndex: 1 }}
                />
            </Box>
        );
    }

    return (
        <ImgStyled
            src={unlockedIcons[0]}
            width={containerSize}
            height={containerSize}
            alt={userPrizes[0].noun}
        />
    );
};

const ImgStyled = styled('img')({});

import { type ReactNode } from 'react';
import {
    Box,
    type BoxProps,
    GradientButton,
    Img,
    Paper,
    Typography,
    colorsPalette,
    styled,
} from '@lingoda/ui';

interface EntryProps {
    disabled?: boolean;
    children?: ReactNode;
    contentSx?: BoxProps['sx'];
    bgImage?: string;
}

export const Entry = ({ children, disabled, contentSx, bgImage }: EntryProps) => {
    return (
        <EntryPaper disabled={disabled} elevation={0} data-cy="quiz-entry">
            <Content sx={contentSx}>{children}</Content>

            <ImageContainer>
                <Image src={bgImage} aria-hidden="true" />
            </ImageContainer>
        </EntryPaper>
    );
};

export const EntryPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '10rem',
    padding: theme.spacing(2),
    backgroundImage: `linear-gradient(251.22deg, #F5DEC4 0%, #F5C4E8 99.86%);`,

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
        minHeight: '14.75rem',
    },

    ...(disabled && {
        backgroundColor: colorsPalette.blackSolid[8],
        backgroundImage: 'none',
    }),
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    display: 'none',
    marginRight: theme.spacing(-3),
    marginBottom: theme.spacing(-3),

    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexShrink: 1,
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },

    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(-4),
        marginBottom: theme.spacing(-4),
    },
}));

const Image = styled(Img)(({ theme }) => ({
    width: 'auto',
    transition: theme.transitions.create(['right', 'height'], { easing: 'linear' }),
}));

const Content = styled(Box)(({ theme }) => ({
    position: 'relative',
    flex: '1 0 12rem',
    maxWidth: '21rem',
    marginRight: theme.spacing(1),
}));

export const ButtonCta = styled(GradientButton)(({ theme }) => ({
    minWidth: '10rem',
    background: 'linear-gradient(251.22deg, #FFA133 0%, #FF4DD2 99.86%);',
    [theme.breakpoints.up('sm')]: {
        minWidth: '11.25rem',
    },

    '&:hover': {
        background: 'linear-gradient(251.22deg, #EA830B 0%, #FF0BC2 99.86%);',
    },
}));

export const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.h2,

    [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
    },
}));

export const extendSlots = (slots: number[], extendHours: number) =>
    slots
        .flatMap((slot) => extendSlot(slot, extendHours))
        .filter(isInRange(0, 23))
        .filter(unique)
        .sort(increasing);

const extendSlot = (slot: number, extendHours: number) =>
    Array(extendHours * 2 + 1)
        .fill(0)
        .map((_, index) => slot - extendHours + index);

const isInRange = (min: number, max: number) => (value: number) => value >= min && value <= max;

const unique = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

const increasing = (a: number, b: number) => a - b;

import { useCallback, useEffect, useMemo } from 'react';
import { useDebouncedState, useDispatch, useIntervalDate } from '@lingoda/hooks';
import {
    useBookingSearchResultsQuery,
    useBookingSuggestedResultsQuery,
    useCacheCleaner,
} from '@lingoda/graphql';
import { goTo, studentClassDetailsPath } from '@lingoda/router';
import { createDate } from '@lingoda/dates';
import {
    type BookingFilter,
    adaptBookingFilterToGqlVariables,
    isBookingFilterValid,
    revalidateBookingFilter,
    useGetNextLessons,
} from '@lingoda/booking';
import { useShowCalendarConnectionDialog } from '@lingoda/calendar-connection/hooks';
import { getInactiveLessonIdsFromError } from '../../utils';

type Options = {
    isEmbedded?: boolean;
    bookingFilter: BookingFilter;
    onBookingFilterChange: (newFilter: Partial<BookingFilter>) => void;
};

export const useBookingSearchResults = ({
    isEmbedded,
    bookingFilter,
    onBookingFilterChange,
}: Options) => {
    const dispatch = useDispatch();
    const [clearCache] = useCacheCleaner();
    const debouncedFilter = useDebouncedState(bookingFilter);
    const isFilterValid = isBookingFilterValid(debouncedFilter); // NOTE: don't memoize this function because it has time dependency
    const now = useIntervalDate('hour', { round: true });
    const nowTimestamp = now.getTime();

    const clearClassesCache = useCallback(() => {
        clearCache({
            id: 'ROOT_QUERY',
            fieldName: 'classes',
        });
    }, [clearCache]);

    useEffect(() => {
        if (!isFilterValid) {
            dispatch(revalidateBookingFilter());
        }
    }, [dispatch, isFilterValid]);

    useEffect(() => {
        dispatch(revalidateBookingFilter());
    }, [dispatch, nowTimestamp]);

    useEffect(() => {
        clearClassesCache();
    }, [clearClassesCache, debouncedFilter]);

    const {
        data: availableClassesData,
        loading: availableClassesLoading,
        refetch: availableClassesRefetch,
        fetchMore,
    } = useBookingSearchResultsQuery({
        fetchPolicy: 'network-only',
        variables: {
            bookingFilter: adaptBookingFilterToGqlVariables(debouncedFilter, {
                skipFilteringOutGeneratedClasses: isEmbedded,
            }),
        },
        skip: !isFilterValid,
        onError: (error) => {
            const inactiveLessonIds = getInactiveLessonIdsFromError(error);

            if (!inactiveLessonIds.length) {
                return;
            }
            const newLessons = bookingFilter.lessons.filter(
                (lessonId) => !inactiveLessonIds.includes(lessonId),
            );

            onBookingFilterChange({
                lessons: newLessons,
            });
        },
    });

    const { shouldShowCalendarConnectDialog, showCalendarConnectDialog } =
        useShowCalendarConnectionDialog();

    const availableClasses = useMemo(
        () => availableClassesData?.classes || [],
        [availableClassesData?.classes],
    );

    const {
        data: suggestedClassesData,
        loading: suggestedClassesLoading,
        refetch: suggestedClassesRefetch,
    } = useBookingSuggestedResultsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            nextAvailableClassDateBookingFilter: adaptBookingFilterToGqlVariables(debouncedFilter, {
                skipFilteringOutGeneratedClasses: isEmbedded,
            }),
            suggestedClassesBookingFilter: {
                ...adaptBookingFilterToGqlVariables(debouncedFilter, {
                    skipFilteringOutGeneratedClasses: isEmbedded,
                }),
                includeCurrent: false,
                slotsType: 'multiDateSuggestedClasses',
            },
        },
        skip:
            availableClassesLoading ||
            availableClasses.length > 0 ||
            !isFilterValid ||
            !debouncedFilter.dates.length,
    });

    const loading = availableClassesLoading || suggestedClassesLoading;

    const suggestedClasses = useMemo(
        () => suggestedClassesData?.suggestedClasses || [],
        [suggestedClassesData?.suggestedClasses],
    );

    const nextAvailableDate = useMemo(
        () =>
            suggestedClassesData?.nextAvailableClassDate
                ? createDate(suggestedClassesData?.nextAvailableClassDate)
                : undefined,
        [suggestedClassesData?.nextAvailableClassDate],
    );

    const refetchClasses = useCallback(
        (...args: Parameters<typeof availableClassesRefetch>) => {
            clearClassesCache();

            return availableClassesRefetch(...args);
        },
        [clearClassesCache, availableClassesRefetch],
    );

    const openClassDetailsPage = useCallback((classId: string) => {
        goTo(studentClassDetailsPath({ class_id: classId }));
    }, []);

    const getNextLessonsList = useGetNextLessons();

    const handleOnAvailableClassBooked = useCallback(
        (classId: string, lessonId?: number) => {
            if (shouldShowCalendarConnectDialog) {
                showCalendarConnectDialog();
            }
            if (isEmbedded) {
                openClassDetailsPage(classId);
            } else if (lessonId) {
                const nextLessons = getNextLessonsList(lessonId);
                if (nextLessons) {
                    onBookingFilterChange({ lessons: nextLessons });
                } else {
                    void refetchClasses();
                }
            }
        },
        [
            getNextLessonsList,
            isEmbedded,
            onBookingFilterChange,
            openClassDetailsPage,
            refetchClasses,
            showCalendarConnectDialog,
            shouldShowCalendarConnectDialog,
        ],
    );
    const handleSuggestedClassBooked = useCallback(
        (classId: string, lessonId?: number) => {
            if (shouldShowCalendarConnectDialog) {
                showCalendarConnectDialog();
            }
            if (isEmbedded) {
                openClassDetailsPage(classId);
            } else if (lessonId) {
                const nextLessons = getNextLessonsList(lessonId);
                if (nextLessons) {
                    onBookingFilterChange({ lessons: nextLessons });
                } else {
                    void suggestedClassesRefetch();
                }
            }
        },
        [
            getNextLessonsList,
            isEmbedded,
            onBookingFilterChange,
            openClassDetailsPage,
            suggestedClassesRefetch,
            showCalendarConnectDialog,
            shouldShowCalendarConnectDialog,
        ],
    );

    const fetchMoreClasses = useCallback(
        async (startDate: Date) => {
            if (debouncedFilter.dates.length > 0) {
                return [];
            }

            const filterExtraParams = {
                startDate,
                skipFilteringOutGeneratedClasses: isEmbedded,
            };
            const { data } = await fetchMore({
                variables: {
                    bookingFilter: adaptBookingFilterToGqlVariables(
                        debouncedFilter,
                        filterExtraParams,
                    ),
                },
            });

            return data.classes || [];
        },
        [fetchMore, debouncedFilter, isEmbedded],
    );

    return {
        fetchMoreClasses,
        handleOnAvailableClassBooked,
        handleSuggestedClassBooked,
        clearClassesCache,
        availableClasses,
        suggestedClasses,
        nextAvailableDate,
        loading,
        debouncedFilter,
    };
};

import { useGoBack } from '@lingoda/router';
import {
    BackButtonBar,
    Box,
    FramePageContainer,
    Hidden,
    Stack,
    Typography,
    colorsPalette,
    styled,
    useResolution,
} from '@lingoda/ui';
import { ClockIcon24, UserGroup24 } from '@lingoda/ui/icons/lingodaIcons';
import { transChoice } from '@lingoda/i18n';
import type { BackgroundThemeEnum } from '@lingoda/graphql';
import { resolveProtocol } from '@lingoda/urls';
import { getChallengeStatus, getChallengeStatusText } from '../../utils';
import { JoinButton } from '../JoinButton';

interface TopSectionLayoutProps {
    startDate: string;
    endDate: string;
    joined: boolean;
    participants: number;
    title: string;
    subtitle: string;
    onJoinClick: () => void;
    joiningLoading: boolean;
    desktopImageUrl: string | undefined;
    mobileImageUrl: string | undefined;
    desktopBackgroundTheme: BackgroundThemeEnum;
    mobileBackgroundTheme: BackgroundThemeEnum;
}

export const TopSectionLayout = ({
    title,
    subtitle,
    startDate,
    endDate,
    joined,
    participants,
    onJoinClick,
    joiningLoading,
    desktopImageUrl,
    mobileImageUrl,
    desktopBackgroundTheme,
    mobileBackgroundTheme,
}: TopSectionLayoutProps) => {
    const { canGoBack, goBack } = useGoBack();

    const status = getChallengeStatus(startDate, endDate);
    const statusText = getChallengeStatusText(status, startDate, endDate);

    const isMobile = useResolution('phone');
    const theme = isMobile ? mobileBackgroundTheme : desktopBackgroundTheme;

    return (
        <Box position="relative">
            <Hidden smUp>
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    zIndex={-1}
                    style={{
                        backgroundImage: `url(${resolveProtocol(mobileImageUrl ?? '')})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
            </Hidden>
            <Box pt={{ xs: 0, sm: 1 }} pb={{ xs: 0, sm: 1 }}>
                {canGoBack ? (
                    <FramePageContainer>
                        <BackButtonBar
                            goBack={goBack}
                            inverted={isMobile && desktopBackgroundTheme === 'dark'}
                        />
                    </FramePageContainer>
                ) : (
                    <Box mt={6} />
                )}
            </Box>
            <FramePageContainer
                px={{ xs: 2, sm: 3, md: 6 }}
                pt={{ xs: 28, smn: 32, sm: 0 }}
                maxWidth="smw"
            >
                <StackStyled
                    spacing={{ xs: 1.5, sm: 1 }}
                    pt={{ xs: 0, sm: 4 }}
                    px={{ xs: 0, sm: 3 }}
                    pb={{ xs: 4, lg: 4 }}
                    position="relative"
                    alignItems={{ xs: 'center', sm: 'normal' }}
                    textAlign={{ xs: 'center', sm: 'left' }}
                    minHeight={{ xs: undefined, sm: 324 }}
                    borderRadius={{ xs: 0, sm: 2 }}
                    boxShadow={{ xs: undefined, sm: '0px 8px 8px rgba(0, 0, 0, 0.1)' }}
                    backgroundImage={`url(${resolveProtocol(desktopImageUrl ?? '')})`}
                    color={theme === 'dark' ? colorsPalette.whiteSolid[100] : 'inherited'}
                >
                    <Typography
                        variant="subtitle1"
                        textTransform="uppercase"
                        htmlColor={
                            theme === 'dark'
                                ? colorsPalette.whiteAlpha[70]
                                : colorsPalette.blackAlpha[70]
                        }
                    >
                        {subtitle}
                    </Typography>
                    <Typography variant="h1" maxWidth={{ sm: 400 }}>
                        {title}
                    </Typography>
                    <Stack
                        spacing={2}
                        paddingY={2}
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems={{ xs: 'center', sm: 'normal' }}
                    >
                        <Stack spacing={1} direction="row">
                            <ClockIcon24 />
                            <Typography variant="body1">{statusText}</Typography>
                        </Stack>
                        <Stack spacing={1} direction="row">
                            <UserGroup24 />
                            <Typography variant="body1">
                                {transChoice(
                                    'participants',
                                    participants,
                                    { participants },
                                    'student-challenges',
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                    <JoinButton
                        onJoinClick={onJoinClick}
                        joined={joined}
                        joiningLoading={joiningLoading}
                        status={status}
                        inverted={theme === 'dark'}
                    />
                </StackStyled>
            </FramePageContainer>
        </Box>
    );
};

const StackStyled = styled(Stack, {
    shouldForwardProp: (name) => name !== 'backgroundImage',
})<{ backgroundImage: string | undefined }>(({ theme, backgroundImage }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
}));

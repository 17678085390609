import { useBasicActivityChallengeListDataQuery } from '@lingoda/graphql';
import { Box, Stack, Typography } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { showApolloErrorToast } from '@lingoda/toasts';
import { MyChallengesBoardLayout } from './MyChallengesBoardLayout';

export const MyChallengesBoard = () => {
    const { data } = useBasicActivityChallengeListDataQuery({
        variables: {
            canJoinOnly: false,
        },
        onError: showApolloErrorToast,
    });

    const activityChallenges = data?.activityChallenges ?? [];

    if (!activityChallenges?.length) {
        return null;
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h3">{trans('challenges', {}, 'student-challenges')}</Typography>
            <Box>
                <MyChallengesBoardLayout activityChallenges={activityChallenges} />
            </Box>
        </Stack>
    );
};

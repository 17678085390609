import { useCallback, useState } from 'react';

const MINIMUM_GROUP_CREDITS = 5;

const useCredits = () => {
    const [groupCredits, setGroupCredits] = useState<number>(0);
    const [privateCredits, setPrivateCredits] = useState<number>(0);

    const changeCredits = useCallback(
        (action: string) => () => {
            switch (action) {
                case 'increaseGroup':
                    return setGroupCredits(
                        groupCredits < MINIMUM_GROUP_CREDITS
                            ? MINIMUM_GROUP_CREDITS
                            : groupCredits + 1,
                    );
                case 'decreaseGroup':
                    return setGroupCredits(
                        groupCredits <= MINIMUM_GROUP_CREDITS ? 0 : groupCredits - 1,
                    );
                case 'increasePrivate':
                    return setPrivateCredits(privateCredits + 1);
                case 'decreasePrivate':
                    return setPrivateCredits(privateCredits - 1);
            }
        },
        [setGroupCredits, setPrivateCredits, groupCredits, privateCredits],
    );

    return {
        groupCredits,
        privateCredits,
        changeCredits,
        totalCredits: groupCredits + privateCredits,
    };
};

export default useCredits;

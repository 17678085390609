import { type CSSObject, type Theme } from '@mui/material/styles';
import { colorsPalette } from './colorsPalette';
import { shadowElevationsFactory } from './common';

export const focusOutlineStyles = ({ palette: { mode }, shadows }: Theme, styles?: CSSObject) => ({
    outline: '2px solid',
    outlineOffset: '2px',
    outlineColor: mode === 'dark' ? colorsPalette.whiteAlpha[60] : colorsPalette.primaryAlpha[40],
    boxShadow:
        mode === 'dark'
            ? shadowElevationsFactory.level1(colorsPalette.whiteSolid[100])
            : shadows[1],
    ...styles,
});

export const muiFocusVisibleOutlineMixin = (theme: Theme, styles?: CSSObject) => ({
    '&.Mui-focusVisible': { ...focusOutlineStyles(theme), ...styles },
});

import { useEffect, useState } from 'react';
import Confetti from 'react-dom-confetti';
import { Backdrop } from '../../layout/Backdrop';

export const ConfettiBackdrop = () => {
    const [confettiActive, setConfettiActive] = useState(false);

    useEffect(() => {
        setConfettiActive(true);
    }, []);

    return (
        <Backdrop
            sx={{
                background:
                    'linear-gradient(90deg, rgba(173, 187, 255, 0.6) 0%, rgba(255, 153, 230, 0.6) 100%)',
                backdropFilter: 'blur(10px)',
            }}
            open
        >
            <Confetti active={confettiActive} config={confettiConfig} />
        </Backdrop>
    );
};

const confettiConfig = {
    angle: 90,
    spread: 110,
    startVelocity: 50,
    elementCount: 200,
    dragFriction: 0.05,
    duration: 10000,
    stagger: 0,
    perspective: '1000px',
    width: '15px',
    height: '15px',
    colors: [
        '#eb89a0',
        '#fd70dc',
        '#fb4347',
        '#e3a7ef',
        '#b980e4',
        '#9003cb',
        '#9380ef',
        '#7d6bf3',
        '#5537ed',
        '#f09d3f',
    ],
};

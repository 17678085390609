import { type PropsWithChildren } from 'react';
import { Card, colorsPalette } from '@lingoda/ui';

interface Props {
    isBackFace: boolean;
}

export const CardFace = ({ isBackFace, children }: PropsWithChildren<Props>) => {
    return (
        <Card
            elevation={1}
            sx={{
                height: '100%',
                display: 'flex',
                backgroundColor: isBackFace
                    ? colorsPalette.infoSolid[12]
                    : colorsPalette.infoSolid[4],
                border: `1px solid ${colorsPalette.infoAlpha[24]}`,
                borderRadius: 6,
            }}
        >
            {children}
        </Card>
    );
};

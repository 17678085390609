import { Box } from '../../layout/Box';
import { styled } from '../../theme';
import {
    type DatePickerDotColor,
    datePickerBase,
    datePickerDesktop,
    datePickerMobile,
} from './styles';

interface CalendarContainerProps {
    loading?: boolean;
    dotColor: DatePickerDotColor;
    scheduledDayBg?: string;
    showHoverStyles?: boolean;
    isMobile: boolean;
}

export const CalendarContainer = styled(Box, {
    shouldForwardProp: (prop) =>
        !['loading', 'dotColor', 'scheduledDayBg', 'showHoverStyles', 'isMobile'].includes(
            prop as string,
        ),
})<CalendarContainerProps>(
    ({ theme, loading, dotColor, scheduledDayBg, showHoverStyles = true, isMobile }) => ({
        ...datePickerBase(theme, { dotColor, scheduledDayBg }, loading),
        ...(isMobile
            ? {
                  display: 'flex',
                  flexDirection: 'column',
                  ...datePickerMobile(theme),
              }
            : datePickerDesktop({ showHoverStyles })),
    }),
);

import { useEffect, useState } from 'react';
import { trans } from '@lingoda/i18n';
import { Box, Button, Loader, Stack, TextField, Typography } from '@lingoda/ui';
import { capitalize } from '@lingoda/utils';
import SocialIcon from '@lingoda/ui/icons/SocialIcon';
import { registerUser } from '@lingoda/api';
import { getDefaultTimeFormat, useRegisterQueryParams } from '@lingoda/register';
import { useDispatch, useSelector } from '@lingoda/hooks';
import {
    fetchMe,
    isAuthStatusAuthenticatedSelector,
    sendFinishedUserRegistrationEvent,
} from '@lingoda/auth';
import {
    goTo,
    loginPath,
    removeRedirectPathStorage,
    studentHomePath,
    useParams,
    useRedirectPath,
} from '@lingoda/router';
import { getMarketingData } from '@lingoda/analytics';
import { Content } from '../RegisterLayout/Content';
import { Wrapper } from '../RegisterLayout/Wrapper';
import { CurvedLineStyled, RegisterFormWrapperStyled } from '../common';
import { Legal } from '../common/Legal';
import {
    getSocialRegisterSessionInfo,
    removeSocialRegisterSessionInfo,
} from '../../utils/socialRegisterSessionInfoStorage';
import type { UserSocialInfoSchema } from '../../types';

export const CompleteSocialRegister = () => {
    const dispatch = useDispatch();
    const [socialRegisterInfo] = useState<UserSocialInfoSchema | null>(
        getSocialRegisterSessionInfo(),
    );
    const [redirectPath] = useRedirectPath();
    const isLoggedIn = useSelector(isAuthStatusAuthenticatedSelector);

    useEffect(() => {
        if (isLoggedIn) {
            if (redirectPath !== undefined) {
                removeRedirectPathStorage();
                goTo(redirectPath);
            } else {
                goTo(studentHomePath());
            }
        }
        // replace buy `ensureUnauthenticated` HOC when it's moved to packages, it should work with redirect automatically

        if (!socialRegisterInfo) {
            goTo(loginPath());
        }
    }, [isLoggedIn, redirectPath, socialRegisterInfo]);

    const cjData = getMarketingData();
    const { section } = useParams<{
        section: SectionName;
    }>();

    const registerParams = useRegisterQueryParams({
        timeFormat: getDefaultTimeFormat(),
    });

    const handleRegister = async () => {
        if (!socialRegisterInfo) {
            return;
        }

        const { key, email } = socialRegisterInfo;

        const {
            curriculumId,
            module,
            timezone,
            timeFormat,
            weekStart,
            getAmbassadorShortCode,
            getAmbassadorCampaignId,
        } = registerParams;

        try {
            const { data } = await registerUser(key, {
                section,
                email,
                curriculumId,
                module,
                timezone,
                timeFormat,
                weekStart,
                cj: cjData,
                consent: {
                    marketing_communication: false,
                    privacy_policy: true,
                },
                getAmbassadorShortCode,
                getAmbassadorCampaignId,
            });

            await sendFinishedUserRegistrationEvent(data.user.id, email);

            data.redirectUrl ? goTo(data.redirectUrl) : dispatch(fetchMe());
            removeSocialRegisterSessionInfo();
        } catch (error) {
            // it means that session is changed and we can not use this data to create a user, need a fresh start
            removeSocialRegisterSessionInfo();
            goTo(loginPath());
        }
    };

    if (!socialRegisterInfo) {
        return <Loader />;
    }

    const { email, resourceOwnerName } = socialRegisterInfo;

    return (
        <Wrapper>
            <Content isTeamsRegistration={false} />
            <RegisterFormWrapperStyled>
                <CurvedLineStyled />
                <Box display="flex" justifyContent="center">
                    <Box padding={2}>
                        <SocialIcon vendor={resourceOwnerName} fontSize="large" />
                    </Box>
                </Box>
                <Typography variant="h3" align="center">
                    {trans(
                        'create-an-account-by-linking-social',
                        { socialAccount: capitalize(resourceOwnerName) },
                        'student-registration',
                    )}
                </Typography>
                <Stack spacing={2} mt={1} mb={1}>
                    <TextField
                        disabled
                        value={email}
                        label={trans('form-label-email', {}, 'public-common')}
                        fullWidth
                    />
                    <Button onClick={handleRegister} fullWidth variant="contained" color="primary">
                        {trans('create-account', {}, 'student-registration')}
                    </Button>
                </Stack>
                <Legal />
            </RegisterFormWrapperStyled>
        </Wrapper>
    );
};

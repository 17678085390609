import { useCallback } from 'react';
import { trans } from '@lingoda/i18n';
import { studentAchievementsPath } from '@lingoda/router';
import { Button, Stack, Typography, useUiEvent } from '@lingoda/ui';
import { actionClick } from '@lingoda/analytics';

export const TopAchievementsHeader = () => {
    const addEvent = useUiEvent();
    const onAllAchievementClick = useCallback(() => {
        void addEvent(actionClick('ViewAllAchievements'));
    }, [addEvent]);

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="h3">
                {trans('achievements', {}, 'student-achievements')}
            </Typography>
            <Button
                variant="text"
                color="primary"
                size="small"
                onClick={onAllAchievementClick}
                href={studentAchievementsPath()}
            >
                {trans('btn-view-all', {}, 'public-common')}
            </Button>
        </Stack>
    );
};

import { colorsPalette, styled } from '@lingoda/ui/theme';
import { Box, DangerousHtml } from '@lingoda/ui';

interface Props {
    children?: string;
}

const Wrapper = styled(Box)(({ theme }) => ({
    [`& table`]: {
        border: `1px solid ${colorsPalette.blackSolid[100]}`,
        borderCollapse: 'collapse',
    },
    [`& table th`]: {
        padding: theme.spacing(2),
        fontWeight: 'bold',
        background: colorsPalette.blackSolid[20],
        borderBottom: `1px solid ${colorsPalette.blackSolid[100]}`,
    },
    [`& table td`]: {
        padding: theme.spacing(2),
        borderLeft: `1px solid ${colorsPalette.blackSolid[100]}`,
        borderBottom: '1px solid #cccccc',
    },
    [`& table tr:last-child td`]: {
        borderBottom: `1px solid ${colorsPalette.blackSolid[100]}`,
    },
}));

export const TeacherAgreementsBody = ({ children }: Props) => {
    return (
        <Wrapper>
            <DangerousHtml>{children}</DangerousHtml>
        </Wrapper>
    );
};

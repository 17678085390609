import type { Date } from '@lingoda/dates';
import type { ClassType } from '@lingoda/graphql';
import { createRecordFactory } from '@lingoda/utils';
import { ClassLeadTimeSchemaFactory } from './leadTime';
import type { StartDateLeadTimes } from './leadTime';

export type MinimalBookingDates = Record<ClassType, Date | undefined>;

export interface ClassesVarsAttributes {
    minBookingDates?: MinimalBookingDates;
    leadTimes: StartDateLeadTimes;
}

export const MinimalBookingDatesFactory = createRecordFactory<MinimalBookingDates>({
    group: undefined,
    individual: undefined,
    trial: undefined,
});

export const ClassesVarsFactory = createRecordFactory<ClassesVarsAttributes>({
    minBookingDates: undefined,
    leadTimes: ClassLeadTimeSchemaFactory({}),
});

import { Checkbox } from '@lingoda/forms';
import { SlidePopupHeader, Stack, Typography } from '@lingoda/ui';
import { AnswerFieldType } from './types';
import { BookingFeedbackToggleQuestion } from './BookingFeedbackToggleQuestion';
import type { BookingExperienceQuestion } from './types';

interface BookingFeedbackQuestionProps {
    question: BookingExperienceQuestion;
    index: number;
    onClose: () => void;
}

export const BookingFeedbackQuestion = ({
    question,
    index,
    onClose,
}: BookingFeedbackQuestionProps) => {
    return (
        <>
            {index === 0 ? (
                <SlidePopupHeader title={question.translatedQuestion} onClose={onClose} />
            ) : (
                <Typography variant="subtitle1">{question.translatedQuestion}</Typography>
            )}
            {question.fieldType === AnswerFieldType.toggle && (
                <BookingFeedbackToggleQuestion question={question} />
            )}
            {question.fieldType === AnswerFieldType.multipleChoiceSelect && (
                <Stack>
                    {question.answers.map(({ translatedAnswer, value }) => (
                        <Checkbox
                            key={value}
                            name={question.key}
                            value={value}
                            label={translatedAnswer}
                        />
                    ))}
                </Stack>
            )}
        </>
    );
};

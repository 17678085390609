import { trans } from '@lingoda/i18n';
import { Typography, type TypographyProps, colorsPalette } from '@lingoda/ui';

export type GenderType = 'feminine' | 'masculine' | 'masculine_feminine' | 'neutral';

interface WordGenderProps extends TypographyProps {
    gender: GenderType;
}

export const WordGender = ({ gender, ...typographyProps }: WordGenderProps) => {
    return (
        <Typography
            variant="subtitle2"
            fontStyle="italic"
            htmlColor={genderColors[gender]}
            display="inline"
            component="span"
            {...typographyProps}
        >
            {trans(/* @ignore */ `gender-${gender}`, {}, 'student-common')}
        </Typography>
    );
};

const genderColors: Record<GenderType, string> = {
    feminine: colorsPalette.pinkSolid[100],
    neutral: colorsPalette.positiveSolid[100],
    masculine: colorsPalette.primarySolid[100],
    masculine_feminine: colorsPalette.positiveSolid[100],
};

import { type Ref, useImperativeHandle, useRef } from 'react';
import { useForkRef } from '../../utils';
import type { SvgIconProps } from '../../layout/SvgIcon';

export interface PlayAudioIconAnimation {
    play: () => void;
}

export const useAnimation = ({
    ref,
    animationRef,
}: {
    ref: Ref<SVGSVGElement>;
    animationRef: Ref<PlayAudioIconAnimation> | undefined;
}) => {
    const innerRef = useRef<SVGSVGElement>();
    const forkRef = useForkRef(ref, innerRef);

    useImperativeHandle(
        animationRef,
        () => {
            return {
                play: () => {
                    const element = innerRef.current;

                    if (!element) {
                        return;
                    }

                    element.classList.remove(ANIMATE_CLASS_NAME);
                    // Trigger browser reflow to trigger animation:
                    // https://css-tricks.com/restart-css-animation/#aa-update-another-javascript-method-to-restart-a-css-animation
                    // https://stackoverflow.com/questions/18147915/get-width-height-of-svg-element
                    void element.getBBox();
                    element.classList.add(ANIMATE_CLASS_NAME);
                },
            };
        },
        [],
    );

    return { sx: animationSx, forkRef };
};

const ANIMATE_CLASS_NAME = 'animate';

const animationSx: SvgIconProps['sx'] = {
    '.inner, .outer': {
        animationDuration: '0.001s, var(--fallback-duration)',
        animationTimingFunction: 'ease-in, var(--fallback-timing-function)',

        '@supports (animation-timing-function: linear(0, 1))': {
            animationTimingFunction: 'ease-in, var(--bounce-timing-function)',
            animationDuration: '0.001s, var(--bounce-duration)',
        },
    },

    [`&.${ANIMATE_CLASS_NAME}`]: {
        '.inner, .outer': {
            animationName: 'play-icon-charge, play-icon-release',
        },
    },

    '.inner': {
        '--scale-value': 0.15,

        transformOrigin: '65% 50%',
    },

    '.outer': {
        '--scale-value': 0.6,

        transformOrigin: '50% 50%',
    },

    '@keyframes play-icon-charge': {
        to: {
            transform: 'scale(var(--scale-value))',
        },
    },

    '@keyframes play-icon-release': {
        from: {
            transform: 'scale(var(--scale-value))',
        },
    },

    '--bounce-duration': '0.8s',
    '--bounce-timing-function': `linear(
        0, 0.01099 0.8%, 0.04441 1.64%, 0.18053 3.47%, 0.89323 9.601%, 1.13277 12.091%,
        1.27943 14.441%, 1.32115 15.612%, 1.3432 16.812%, 1.34708 17.612%, 1.3429 18.432%,
        1.33059 19.292%, 1.30995 20.202%, 1.24651 22.132%, 1.05191 26.813%, 0.97026 29.123%,
        0.9086 31.693%, 0.89094 32.963%, 0.88142 34.263%, 0.88072 35.944%, 0.89232 37.784%,
        0.91469 39.744%, 0.98155 44.374%, 1.00946 46.635%, 1.03086 49.145%, 1.04082 51.645%,
        1.04151 53.425%, 1.03738 55.376%, 0.99732 64.066%, 0.9899 66.467%, 0.98613 68.837%,
        0.98697 72.917%, 1.00062 81.428%, 1.0046 85.829%, 0.99935)`,

    '--fallback-duration': '0.2s',
    '--fallback-timing-function': 'cubic-bezier(0.34, 1.56, 0.64, 1)',
};

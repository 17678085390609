import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const PasswordIcon = (props: SvgIconProps) => (
    <SvgIcon viewBox="0 0 24 24" {...props}>
        <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M.12 12s4.325-7 11.895-7c7.57 0 11.895 7 11.895 7s-4.325 7-11.895 7C4.445 19 .12 12 .12 12z"
        />
        <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M12.015 15c1.97 0 3.569-1.343 3.569-3s-1.598-3-3.57-3-3.568 1.343-3.568 3 1.598 3 3.569 3z"
        />
    </SvgIcon>
);

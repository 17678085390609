import { SvgIcon, type SvgIconProps } from '@lingoda/ui';

const PriceTag = (props: SvgIconProps) => (
    <SvgIcon {...props} className="price-tag-icon">
        <path d="m14.101 9.607-.626.626c-.755-.393-1.678-.25-2.31.382-.416.417-.595.862-.563 1.407.035.471.113.793.33 1.374.316.888.324 1.285.034 1.575-.184.184-.385.289-.597.31-.265.021-.495-.076-.723-.303-.43-.431-.416-.925.05-1.762l.165-.297-1.346-.33-.1.152c-.557.846-.595 1.85-.115 2.667l-.62.62.917.916.625-.625c.365.218.768.318 1.201.298.581-.024 1.095-.255 1.529-.688l.007-.008c.489-.532.654-1.05.555-1.731-.06-.428-.128-.646-.265-1.055l-.178-.561c-.029-.1-.054-.234-.085-.403l-.003-.015c-.038-.31.046-.46.203-.617.233-.233.6-.246.817-.028.238.238.338.472.182 1.027l-.075.265 1.303.279.072-.222c.206-.63.172-1.203-.098-1.706l.63-.631-.916-.916Z" />
        <path d="m24 1.506-.99-.997-2.71 2.695-8.786-1.02L0 13.683 10.794 24.49l11.514-11.5-1.01-8.799L24 1.506Zm-3.166 10.971L10.795 22.503l-8.807-8.819L12.027 3.658l6.998.813-1.407 1.399a1.983 1.983 0 0 0-.808-.17c-.53 0-1.03.206-1.405.58a1.99 1.99 0 0 0 1.405 3.396c.531 0 1.03-.206 1.405-.581a1.992 1.992 0 0 0 .402-2.237l1.412-1.403.805 7.022ZM17.222 8.1a.58.58 0 0 1-.824 0 .584.584 0 1 1 .824 0Z" />
    </SvgIcon>
);

export default PriceTag;

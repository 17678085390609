import { FormattedNumber } from 'react-intl';
import { getNumberOfDigits } from '../getNumberOfDigits';
import type { FC } from 'react';

type Props = Omit<
    React.ComponentProps<typeof FormattedNumber>,
    'value' | 'style' | 'minimumFractionDigits' | 'maximumFractionDigits'
> & { value: number };

export const FormattedCurrency: FC<Props> = ({ value, ...props }) => {
    const digits = getNumberOfDigits(value);

    return (
        <FormattedNumber
            {...props}
            value={value}
            style="currency"
            minimumFractionDigits={digits}
            maximumFractionDigits={digits}
        />
    );
};

import { type PropsWithChildren, forwardRef, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import { IconButton } from '../../buttons';
import { CloseIcon24 } from '../../icons/Close';
import { colorsPalette, styled } from '../../theme';
import { Slide, type TransitionProps } from '../../transitions';
import { AppBar } from '../AppBar';
import { Box } from '../Box';
import { Dialog } from '../Dialog';
import { ScrollBox } from '../ScrollBox';
import { Toolbar } from '../Toolbar';
import { Typography } from '../Typography';

const StyledDialog = styled(Dialog)({
    '& .MuiDialog-paper': {
        borderRadius: 0,
        overflow: 'hidden',
    },
});

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface Props extends PropsWithChildren<unknown> {
    headerContent?: React.ReactNode;
    title?: string;
    titleCentered?: boolean;
    open: boolean;
    onClose?: () => void;
}

export const FullScreenOverlay = ({
    children,
    headerContent,
    open,
    title,
    titleCentered,
    onClose,
}: Props) => {
    const a11y = useMemo(() => generateA11y(), []);

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            fullScreen
            TransitionComponent={Transition}
            aria-labelledby={title ? a11y.titleId : undefined}
        >
            <AppBar
                elevation={1}
                sx={{
                    position: 'static',
                    backgroundColor: colorsPalette.whiteSolid[100],
                    color: colorsPalette.blackSolid[100],
                    zIndex: (theme) => theme.zIndex.modal,
                }}
            >
                <Toolbar>
                    <TitleButtonBox ml={-1.5} mr={{ xs: 1, sm: 2 }}>
                        <IconButton onClick={onClose} aria-label="Close">
                            <CloseIcon24
                                sx={{ fontSize: '2rem', color: colorsPalette.blackAlpha[70] }}
                            />
                        </IconButton>
                    </TitleButtonBox>
                    <Box flex={1}>
                        {title && (
                            <Title
                                component="h1"
                                id={a11y.titleId}
                                textAlign={titleCentered ? 'center' : 'start'}
                            >
                                {title}
                            </Title>
                        )}
                    </Box>
                    {titleCentered && <TitleButtonBox ml={{ xs: 1, sm: 2 }} mr={-1.5} />}
                </Toolbar>
                {headerContent}
            </AppBar>
            <ScrollBox>{children}</ScrollBox>
        </StyledDialog>
    );
};

const TitleButtonBox = styled(Box)({
    width: 56,
    height: 56,
});

const Title = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle1,
    [theme.breakpoints.only('xs')]: {
        ...theme.typography.subtitle1,
    },
    [theme.breakpoints.up('sm')]: {
        ...theme.typography.h2,
    },
    [theme.breakpoints.up('md')]: {
        ...theme.typography.h1,
    },
}));

const generateA11y = () => {
    return {
        titleId: uniqueId('overlay-title-'),
    };
};

import { memo } from 'react';
import { type CheckboxProps } from '@lingoda/ui/fields/Checkbox';
import { type FormControlLabelProps } from '@lingoda/ui/fields/FormControlLabel';
import { CustomErrorMessage, Field, type FieldProps } from '../../utils';
import { Layout } from './Layout';

interface Props extends InheritedProps {
    checkboxProps?: CheckboxProps;
    showError?: boolean;
}

type InheritedProps = Omit<FormControlLabelProps, 'control'>;

const FormikCheckbox = ({ checkboxProps, value = false, showError = true, ...rest }: Props) => (
    <Field {...rest} value={value}>
        {({
            field: { name, value: providedValue },
            form: { errors, setFieldValue, setFieldTouched },
            meta,
        }: FieldProps) => {
            const onChange = (e: React.ChangeEvent<HTMLInputElement>, _checked: boolean) => {
                checkboxProps?.onChange?.(e, _checked);

                if (Array.isArray(providedValue)) {
                    if (providedValue.includes(value)) {
                        const nextValue = providedValue.filter((_value) => _value !== value);
                        setFieldValue(e.target.name, nextValue);
                    } else {
                        const nextValue = providedValue.concat(value);
                        setFieldValue(e.target.name, nextValue);
                    }
                } else {
                    // We're providing own onChange because MaterialUI checkbox doesn't support
                    // boolean values and we're adding support for that here
                    setFieldTouched(e.target.name, true);
                    setFieldValue(
                        e.target.name,
                        typeof value === 'boolean' ? _checked : _checked ? value : undefined,
                    );
                }
            };

            const checked = Array.isArray(providedValue)
                ? providedValue.includes(value)
                : !!providedValue;

            return (
                <Layout
                    checkboxProps={checkboxProps}
                    checked={checked}
                    errors={errors}
                    name={name}
                    onChange={onChange}
                    {...rest}
                >
                    {showError && <CustomErrorMessage meta={meta} />}
                </Layout>
            );
        }}
    </Field>
);

export default memo(FormikCheckbox);

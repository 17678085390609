import { memo } from 'react';
import { Grid, Skeleton, Stack, Typography } from '@lingoda/ui';
import { ClassListItemSkeleton } from './ClassListItem/ClassListItemSkeleton';

interface Props {
    count: number;
}

const ClassListSkeletonComponent = ({ count }: Props) => (
    <Grid container spacing={2} data-cy="Class placeholder">
        <Grid item xs={12}>
            <Typography variant="body1">
                <Skeleton variant="text" width={230} />
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Stack spacing={0}>
                {[...Array(count).keys()].map((key) => (
                    <ClassListItemSkeleton key={key} />
                ))}
            </Stack>
        </Grid>
    </Grid>
);

export const ClassListSkeleton = memo(ClassListSkeletonComponent);

import { createSelector } from 'reselect';
import { classesSelector } from './classes';
import type { ClassId } from '../constants';

export const classByIdSelector = (selector: (state: unknown, props: unknown) => ClassId) =>
    createSelector(
        classesSelector,
        selector,
        (classes, id) => (id && classes.find((klass) => klass.id === id)) || undefined,
    );

import { SvgIcon, type SvgIconProps } from '../../layout/SvgIcon';
import { styled } from '../../theme';

const Svg = styled(SvgIcon)(({ theme }) => ({
    fontSize: theme.typography.body1.fontSize,
    width: '1em',
    height: '1em',
}));

export const Icon: React.FC<SvgIconProps> = (props) => (
    <Svg viewBox="0 0 16 16" {...props}>
        <rect rx="2" x="0.5" y="0.5" width="15" height="15" fillOpacity="0" stroke="currentColor" />
    </Svg>
);

export const IconChecked: React.FC<SvgIconProps> = (props) => (
    <Svg viewBox="0 0 16 16" {...props}>
        <rect width="16" height="16" rx="2" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9881 3.93057C13.3026 4.20014 13.339 4.67361 13.0694 4.98811L6.54236 12.603L2.96967 9.03034C2.67678 8.73745 2.67678 8.26258 2.96967 7.96968C3.26256 7.67679 3.73744 7.67679 4.03033 7.96968L6.45764 10.397L11.9306 4.01192C12.2001 3.69743 12.6736 3.661 12.9881 3.93057Z"
            fill="white"
        />
    </Svg>
);

export const IconIndeterminate: React.FC<SvgIconProps> = (props) => (
    <Svg viewBox="0 0 16 16" {...props}>
        <rect width="16" rx="2" height="16" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75 8C2.75 7.58579 3.08579 7.25 3.5 7.25H12.5C12.9142 7.25 13.25 7.58579 13.25 8C13.25 8.41421 12.9142 8.75 12.5 8.75H3.5C3.08579 8.75 2.75 8.41421 2.75 8Z"
            fill="white"
        />
    </Svg>
);

export const IconCheckedDisabled: React.FC<SvgIconProps> = (props) => (
    <Svg viewBox="0 0 16 16" {...props}>
        <rect width="16" stroke="currentColor" height="16" rx="2" fillOpacity="0.04" />
        <rect
            x="0"
            y="0"
            width="16"
            height="16"
            rx="1"
            stroke="currentColor"
            fillOpacity="0"
            strokeOpacity="0.2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9881 3.93057C13.3026 4.20014 13.339 4.67361 13.0694 4.98811L6.54236 12.603L2.96967 9.03034C2.67678 8.73745 2.67678 8.26258 2.96967 7.96968C3.26256 7.67679 3.73744 7.67679 4.03033 7.96968L6.45764 10.397L11.9306 4.01192C12.2001 3.69743 12.6736 3.661 12.9881 3.93057Z"
            fillOpacity="1"
        />
    </Svg>
);

export default Icon;

import { offsetLimitPagination } from '@apollo/client/utilities';
import { PracticeItemStatus } from '@lingoda/graphql';
import type { FieldPolicy, Reference } from '@apollo/client';

const practiceItems: FieldPolicy<Reference[]> = {
    ...offsetLimitPagination(['type', 'status', 'moduleId']),
    read(existing, { readField, args }) {
        if (existing) {
            return existing.filter(
                (item) =>
                    readField('isCompleted', item) ===
                    (args?.status === PracticeItemStatus.Completed),
            );
        }
    },
};

export default practiceItems;

import { FeatureFlag } from '@lingoda/graphql';
import { hasFeature } from './utils';

export const isBookBeforeTrialEnabled = () => hasFeature(FeatureFlag.BookClassBeforeTrialStart);

export const isNoCreditCardTrialEnabled = () =>
    hasFeature(FeatureFlag.SkipCreditCardForTrialDirectPurchase);

export const isEasyGeneratorEnabled = () => hasFeature(FeatureFlag.EasyGenerator);

export const isWritingPracticeEnabled = () => hasFeature(FeatureFlag.WritingPractice);

export const isFlashcardsPageEnabled = () => hasFeature(FeatureFlag.Flashcards);

export const isPracticeSectionEnabled = () => hasFeature(FeatureFlag.PracticeSection);

export const isLivePracticeEnabled = () => hasFeature(FeatureFlag.LivePractice);

export const isSelfLearningLimitAccessEnabled = () =>
    hasFeature(FeatureFlag.SelfLearningLimitAccess);

export const isFlashNotificationsEnabled = () => !hasFeature(FeatureFlag.HideFlashNotifications);

export const isShareCertificateEnabled = () => hasFeature(FeatureFlag.ShareCertificate);

export const isEmailVerificationEnabled = () => hasFeature(FeatureFlag.EmailVerification);

export const isLeadConversionFlowEnabled = () => hasFeature(FeatureFlag.LeadConversionFlow); // ??

export const isMyCourseGuidanceBannerEnabled = () => hasFeature(FeatureFlag.MyCourseGuidanceBanner);

export const isNewCoursePageEnabled = () => hasFeature(FeatureFlag.MyCourseUpgrade); // it's feature logic now

export const isPracticeSatisfactionSurveyEnabled = () =>
    hasFeature(FeatureFlag.PracticeSatisfactionSurvey);

export const isQuizEngineEnabled = () => hasFeature(FeatureFlag.QuizEngine);

export const isNudgeForPlanningEnabled = () => hasFeature(FeatureFlag.NudgeForPlanning);

export const isOnboardingChecklistEnabled = () => hasFeature(FeatureFlag.OnboardingChecklist);

export const isVocabAudioEnabled = () => hasFeature(FeatureFlag.VocabAudio);

export const isVocabErrorReportingEnabled = () => hasFeature(FeatureFlag.VocabTrainerContentErrors);

export const isSprintBookingPreviewEnabled = () => hasFeature(FeatureFlag.SprintPrePayment);

export const isVocabularyGoalSettingEnabled = () => hasFeature(FeatureFlag.VocabularyGoalSetting);

export const isAfterBookingFeedbackSurveyEnabled = () => false; // we may want to enable it in the future

export const isMixedCreditsEnabled = () => hasFeature(FeatureFlag.MixedCredits);

export const isStudentProfilePageEnabled = () => hasFeature(FeatureFlag.StudentProfile);

// ===== DEV FLAGS =====

export const isIgnoreStrictModeEnabled = () => localStorage.getItem('ignoreStrictMode') === 'true';

export const isRequirePolyfillTimezonesTest = () =>
    localStorage.getItem('require_polyfill_timezones') === 'true';

export const isDualVideoClassroomEnforced = () =>
    localStorage.getItem('new_classroom_enforce_dual_video') === 'true';

export const isClassroomDebugLogsEnabled = () =>
    localStorage.getItem('new_classroom_debug_logs') === 'true';

export const getTestClassroomEndDate = () =>
    localStorage.getItem('new_classroom_test_class_end_date');

export const isDevClassroomPreviewVirtualBackgroundEnabled = () =>
    localStorage.getItem('new_classroom_preview_virtual_background') === 'true'; // should be removed after resolving bug: https://lingoda.atlassian.net/browse/LW-27400.

// we'll enable it at the end of 2024
export const isYearlyRecapEnabled = () => localStorage.getItem('yearly_recap_enabled') === 'true';

import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';
import type * as React from 'react';

const NavigateAfter: React.FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.96967 17.5305C8.67678 17.2376 8.67678 16.7627 8.96967 16.4698L13.4393 12.0001L8.96967 7.53045C8.67678 7.23756 8.67678 6.76268 8.96967 6.46979C9.26256 6.1769 9.73744 6.1769 10.0303 6.46979L15.5607 12.0001L10.0303 17.5305C9.73744 17.8233 9.26256 17.8233 8.96967 17.5305Z"
            fill="currentColor"
        />
    </SvgIcon>
);

export default NavigateAfter;

import { createSelector } from 'reselect';
import { expandClassesWithAppointmentSelector, sortByStartDate } from '@lingoda/classes';
import type { ClassId } from '@lingoda/classes';

export const filterExpanded = (selector: (_state: unknown) => ReadonlyArray<ClassId> | undefined) =>
    createSelector(selector, expandClassesWithAppointmentSelector, (classIds, classes) =>
        (classes?.filter((classItem) => classIds?.includes(classItem.id)) || []).sort(
            sortByStartDate(),
        ),
    );

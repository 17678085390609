import { Box, LingodaClassIcon, Typography } from '@lingoda/ui';
import { transChoice } from '@lingoda/i18n';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { ClassType } from '@lingoda/graphql';

interface CreditBoxProps {
    credits: number;
    isGroup: boolean;
}

interface Props {
    groupCredits: number;
    privateCredits: number;
    showAll?: boolean;
}

const ClassCreditsGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
    },
}));

const ClassCreditStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${colorsPalette.blackSolid[20]}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    color: colorsPalette.blackAlpha[70],
    [theme.breakpoints.up('sm')]: {
        '&:not(:first-of-type)': {
            marginLeft: -1,
            borderLeftColor: 'transparent',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '&:not(:last-of-type)': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    [theme.breakpoints.down('sm')]: {
        flex: 1,
    },
    [theme.breakpoints.only('xs')]: {
        '&:not(:first-of-type)': {
            marginTop: -1,
            borderTopColor: 'transparent',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
        },
        '&:not(:last-of-type)': {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
    },
}));

const ClassCredits = ({ credits, isGroup }: CreditBoxProps) => (
    <ClassCreditStyled>
        <LingodaClassIcon type={isGroup ? ClassType.Group : ClassType.Individual} />
        <Box ml={{ xs: 2, sm: 1 }}>
            <Typography variant="h4" htmlColor={colorsPalette.blackSolid[100]}>
                {credits}
            </Typography>
            <Typography variant="body3">
                {isGroup
                    ? transChoice('group-class-credits', credits, {}, 'public-common')
                    : transChoice('individual-class-credits', credits, {}, 'public-common')}
            </Typography>
        </Box>
    </ClassCreditStyled>
);

export const UserCredits = ({ groupCredits, privateCredits, showAll }: Props) => {
    const emptyCredits = groupCredits + privateCredits === 0;
    const showGroupCredits = showAll || groupCredits > 0 || emptyCredits;
    const showPrivateCredits = showAll || privateCredits > 0 || emptyCredits;

    return (
        <ClassCreditsGroup>
            {showGroupCredits && <ClassCredits credits={groupCredits} isGroup />}
            {showPrivateCredits && <ClassCredits credits={privateCredits} isGroup={false} />}
        </ClassCreditsGroup>
    );
};

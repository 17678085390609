import { Stack } from '@lingoda/ui';
import { showApolloErrorToast } from '@lingoda/toasts';
import { useTopAchievements } from '../../hooks/useAchievements';
import { TopAchievementsLayout, type TopAchievementsLayoutProps } from './TopAchievementsLayout';
import { TopAchievementsHeader } from './TopAchievementsHeader';

interface Props extends LayoutProps {
    isYearlyRecapVisible: boolean;
}

type LayoutProps = Pick<TopAchievementsLayoutProps, 'paperProps'>;

export const TopAchievements = ({ isYearlyRecapVisible, paperProps }: Props) => {
    const { achievements, loading } = useTopAchievements({
        onError: showApolloErrorToast,
        isYearlyRecapVisible,
    });

    if (!achievements.length && !isYearlyRecapVisible) {
        return null; // avoid rendering empty stack
    }

    return (
        <Stack component="section" spacing={1.5} data-cy="Top achievements">
            <TopAchievementsHeader />
            <TopAchievementsLayout
                achievements={achievements}
                loading={loading}
                isYearlyRecapVisible={isYearlyRecapVisible}
                paperProps={paperProps}
            />
        </Stack>
    );
};

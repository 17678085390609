import { Translate, trans } from '@lingoda/i18n';
import { DialogInfo, Link, Stack, Typography } from '@lingoda/ui';
import { type AcceptedLang, getLang } from '@lingoda/env';
import { format } from '@lingoda/dates';
import { RevocationFormWrapper } from '../components/RevocationFormWrapper';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const TermsDialog: React.FC<Props> = (props) => {
    const lang = getLang();
    const config = pdfConfig[lang];
    const { name, url } = config;

    return (
        <DialogInfo
            isOpen={props.isOpen}
            buttonLabel={trans('form-button-close', {}, 'public-common')}
            title={trans('header', {}, 'public-terms')}
            onClose={props.onClose}
        >
            <RevocationFormWrapper>
                <Translate id="content" domain="public-terms" />
                <Link href={url} target="_blank" download={name}>
                    {trans('btn-download', {}, 'student-common')}
                </Link>
                <Stack>
                    <Typography variant="body1">
                        {trans('latest-update', {}, 'public-terms')}:{' '}
                    </Typography>
                    <Typography variant="h4">{formatDocumentDate(documentLastUpdate)}</Typography>
                </Stack>
            </RevocationFormWrapper>
        </DialogInfo>
    );
};

const formatDocumentDate = format('cccc, d MMM yyyy');

type PdfConfig = {
    name: string;
    url: string;
};

const pdfConfig: Record<AcceptedLang, PdfConfig> = {
    en: {
        name: '[EN] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BEN%5D+Lingoda_T%26C.pdf',
    },
    de: {
        name: '[DE] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BDE%5D+Lingoda_T%26C.pdf',
    },
    es: {
        name: '[ES] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BES%5D+Lingoda_T%26C.pdf',
    },
    ru: {
        name: '[EN] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BEN%5D+Lingoda_T%26C.pdf',
    },
    fr: {
        name: '[FR] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BFR%5D+Lingoda_T%26C.pdf',
    },
    pl: {
        name: '[PL] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BPL%5D+Lingoda_T%26C.pdf',
    },
    pt_BR: {
        name: '[PT-BR] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BPT-BR%5D+Lingoda_T%26C.pdf',
    },
    tr: {
        name: '[TR] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BTR%5D+Lingoda_T%26C.pdf',
    },
    it: {
        name: '[IT] Lingoda_T&C.pdf',
        url: 'https://s3.eu-west-1.amazonaws.com/static.lingoda.com/downloads/terms-conditions/%5BIT%5D+Lingoda_T%26C.pdf',
    },
};

const documentLastUpdate = new Date('October 01, 2022');

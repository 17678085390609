import groupBy from 'lodash/groupBy';
import { createDate, formatDate } from '@lingoda/dates';
import { type BookableClassFragment, type ProvideBookableClassSlotsInput } from '@lingoda/graphql';
import { extendSlots, formatRangeHour, formatTitleDate } from './utils';
import type { BookableClass, BookingFilter } from './models';

interface ExtraParams {
    skipFilteringOutGeneratedClasses?: boolean;
    startDate?: Date;
}

export const adaptBookingFilterToGqlVariables = (
    filters: BookingFilter,
    extraParams: ExtraParams = {},
): ProvideBookableClassSlotsInput => {
    const { timeSlots } = filters;

    const { startDate = createDate(), skipFilteringOutGeneratedClasses = false } = extraParams;
    const extendedSlots = extendSlots(timeSlots.slots, timeSlots.extendHours);

    // timeSlotsFiltered is used when date is not selected and we should show infinite scroll
    const slotsType =
        filters.dates.length > 1
            ? 'multiDate'
            : filters.dates.length === 1
              ? 'exactDateFiltered'
              : 'timeSlotsFiltered';

    const dates = filters.dates.length ? filters.dates : [startDate];

    return {
        classTypes: [filters.classType],
        slotsType,
        exactTimeSlots: extendedSlots.map(formatRangeHour),
        lessonIds: filters.lessons,
        moduleIds: filters.modules,
        skipFilteringOutGeneratedClasses,
        dates: dates.map(formatDate),
    };
};

export const parseBookableClass = (classItem: BookableClassFragment): BookableClass => {
    return {
        ...classItem,
        startDate: createDate(classItem.startDate),
    };
};

const getEmptyDates = (dates: Date[]) =>
    dates.reduce<Record<string, BookableClass[]>>((acc, dateField) => {
        acc[formatTitleDate(dateField)] = [];

        return acc;
    }, {});

const groupClasses = (classes: BookableClass[]) =>
    groupBy(classes, (item) => formatTitleDate(item.startDate));

export const processSearchClasses = (
    classes: ReadonlyArray<BookableClassFragment>,
    filterDates: Date[] = [],
) => {
    const formattedClasses = classes.map(parseBookableClass);
    const grouped = {
        ...getEmptyDates(filterDates),
        ...groupClasses(formattedClasses),
    };

    return grouped;
};

import { useEffect } from 'react';
import { createDate, format, getTimezone } from '@lingoda/dates';
import { useConfigurationQuery } from '@lingoda/graphql';
import { captureError } from '@lingoda/monitor';
import { useSelector } from '@lingoda/hooks';
import { currentVisitorTimezoneSelector } from '@lingoda/auth';
import { getBrowserTimeZone } from '../logic/getBrowserTimeZone';

export const useTimezoneCheck = () => {
    const userStoreTimeZone = useSelector(currentVisitorTimezoneSelector);
    const { loading, data } = useConfigurationQuery();

    useEffect(() => {
        if (loading || !data) {
            return;
        }

        const userDefaultTimezone = getTimezone();
        const userTimeZoneOffset = format('OOOO', createDate());
        const apiTimeZone = data.config.timezones.find(
            (timezone) => timezone.tz === userDefaultTimezone,
        );
        const doesBrowserOffsetMatchApi = !!apiTimeZone?.label.includes(userTimeZoneOffset);

        if (!doesBrowserOffsetMatchApi) {
            captureError(
                new TimeZoneMismatchError(
                    `Browser time zone offset '${userTimeZoneOffset}' for user timezone '${userDefaultTimezone}' should be same as from api '${apiTimeZone?.label}'`,
                ),
                {
                    data: {
                        apiTimeZone,
                        browserDate: new Date().toString(),
                        browserTimeZoneFixed: getBrowserTimeZone(),
                        getBrowserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        userDate: createDate().toString(),
                        userDefaultTimezone,
                        userStoreTimeZone,
                        userTimeZoneOffset,
                    },
                },
            );
        }
    }, [data, loading, userStoreTimeZone]);
};

class TimeZoneMismatchError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'TimeZoneMismatchError';
    }
}

import { SvgIcon } from '../layout/SvgIcon';
import type { SvgIconProps } from '../layout/SvgIcon';

export const ColouredCalendarIcon = (props: SvgIconProps) => (
    <SvgIcon width="56" height="56" viewBox="0 0 56 56" fill="none" {...props}>
        <g clipPath="url(#clip0_1649_12757)">
            <path
                d="M3.71566 15.7936L3.7156 45.3574C3.71559 51.2353 8.48054 56.0002 14.3584 56.0002L41.6415 56.0002C47.5194 56.0002 52.2843 51.2353 52.2844 45.3574L52.2844 15.7936H3.71566Z"
                fill="url(#paint0_linear_1649_12757)"
            />
            <path
                d="M3.71558 15.4532C3.71558 10.9415 7.37304 7.28406 11.8848 7.28406H44.1036C48.6153 7.28406 52.2728 10.9415 52.2728 15.4532V20.9588H3.71558V15.4532Z"
                fill="url(#paint1_linear_1649_12757)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.1532 0.230225C39.7562 0.230225 41.0558 1.52976 41.0558 3.13281V10.873C41.0558 12.4761 39.7562 13.7756 38.1532 13.7756C36.5501 13.7756 35.2506 12.4761 35.2506 10.873V3.13281C35.2506 1.52976 36.5501 0.230225 38.1532 0.230225Z"
                fill="#1A2B7B"
            />
            <path
                d="M17.8352 0.230225C19.4383 0.230225 20.7378 1.52976 20.7378 3.13281V10.873C20.7378 12.4761 19.4383 13.7756 17.8352 13.7756C16.2321 13.7756 14.9326 12.4761 14.9326 10.873V3.13281C14.9326 1.52976 16.2321 0.230225 17.8352 0.230225Z"
                fill="#1A2B7B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.2127 28.6037C21.2127 27.0446 19.9487 25.7806 18.3896 25.7806H13.1649C11.6057 25.7806 10.3418 27.0446 10.3418 28.6037V33.8284C10.3418 35.3876 11.6057 36.6515 13.1649 36.6515H18.3896C19.9487 36.6515 21.2127 35.3876 21.2127 33.8284V28.6037ZM33.8202 28.6038C33.8202 27.0446 32.5562 25.7807 30.9971 25.7807H25.7724C24.2133 25.7807 22.9493 27.0446 22.9493 28.6038V33.8284C22.9493 35.3876 24.2133 36.6515 25.7724 36.6515H30.9971C32.5562 36.6515 33.8202 35.3876 33.8202 33.8284V28.6038ZM10.3418 41.2113C10.3418 39.6522 11.6058 38.3882 13.1649 38.3882H18.3896C19.9487 38.3882 21.2127 39.6522 21.2127 41.2113V46.436C21.2127 47.9952 19.9487 49.2591 18.3896 49.2591H13.1649C11.6058 49.2591 10.3418 47.9952 10.3418 46.436V41.2113ZM33.8202 41.2113C33.8202 39.6522 32.5562 38.3882 30.9971 38.3882H25.7724C24.2133 38.3882 22.9493 39.6522 22.9493 41.2113V46.436C22.9493 47.9952 24.2133 49.2591 25.7724 49.2591H30.9971C32.5562 49.2591 33.8202 47.9952 33.8202 46.436V41.2113ZM46.454 41.2113C46.454 39.6522 45.1901 38.3882 43.6309 38.3882H38.4063C36.8471 38.3882 35.5832 39.6522 35.5832 41.2113V46.436C35.5832 47.9952 36.8471 49.2591 38.4063 49.2591H43.6309C45.1901 49.2591 46.454 47.9952 46.454 46.436V41.2113ZM35.5832 28.6038C35.5832 27.0446 36.8471 25.7807 38.4063 25.7807H43.6309C45.1901 25.7807 46.454 27.0446 46.454 28.6038V33.8284C46.454 35.3876 45.1901 36.6515 43.6309 36.6515H38.4063C36.8471 36.6515 35.5832 35.3876 35.5832 33.8284V28.6038Z"
                fill="white"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_1649_12757"
                x1="3.71558"
                y1="56.0002"
                x2="41.2335"
                y2="7.2831"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.0401478" stopColor="#D0D8FF" />
                <stop offset="0.941805" stopColor="#FFD6F5" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1649_12757"
                x1="26.5"
                y1="7"
                x2="23"
                y2="21"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#7B79E7" />
                <stop offset="1" stopColor="#BD61E1" />
            </linearGradient>
            <clipPath id="clip0_1649_12757">
                <rect width="56" height="56" fill="white" />
            </clipPath>
        </defs>
    </SvgIcon>
);

import './index.css';
import type { FC } from 'react';

export interface BoxPerfProps extends React.DOMAttributes<HTMLDivElement> {
    dynamic?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

export const BoxPerf: FC<BoxPerfProps> = ({ children, className, ...props }) => (
    <div {...props} className={`box ${className}`}>
        {children}
    </div>
);

import { type PropsWithChildren } from 'react';
import { isUserEmailVerifiedSelector } from '@lingoda/auth';
import { isEmailVerificationEnabled } from '@lingoda/feature-flags';
import { useSelector } from '@lingoda/hooks';
import {
    Redirect,
    matchPathMemoized,
    studentEmailVerificationPath,
    useLocation,
} from '@lingoda/router';

export const EmailVerificationFrame = ({ children }: PropsWithChildren<unknown>) => {
    return isEmailVerificationEnabled() ? (
        <RestrictedAccess>{children}</RestrictedAccess>
    ) : (
        <>{children}</>
    );
};

const RestrictedAccess = ({ children }: PropsWithChildren<unknown>) => {
    const { pathname } = useLocation();
    const isEmailVerified = useSelector(isUserEmailVerifiedSelector);

    const hasAccess = isEmailVerified || matchPathMemoized(allowedRoutes, pathname);

    return hasAccess ? <>{children}</> : <Redirect to={studentEmailVerificationPath()} />;
};

const allowedRoutes = [studentEmailVerificationPath()];

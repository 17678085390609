import { createSelector } from 'reselect';
import modulesWithProgress from './modulesWithProgress';

/**
 * Selects the list of expanded user modules, optionally filtering by a "moduleId" to return only 1
 */
export default createSelector(modulesWithProgress, (modules) => (moduleId: number | undefined) => {
    if (!moduleId) {
        return modules;
    }

    return modules.filter((item) => moduleId === item.id);
});

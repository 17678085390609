import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';

export const FlagEnglishIcon16: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_3680_16640"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
        >
            <path
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
                fill="#C4C4C4"
            />
        </mask>
        <g mask="url(#mask0_3680_16640)">
            <circle cx="8" cy="8" r="8" fill="white" />
            <rect x="10" width="6" height="6" fill="#0A17A7" />
            <rect width="6" height="6" fill="#0A17A7" />
            <rect y="10" width="6" height="6" fill="#0A17A7" />
            <rect x="10" y="10" width="6" height="6" fill="#0A17A7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.05024 1.63604L1.63603 3.05025L6.58577 7.99999L1.63602 12.9497L3.05024 14.364L7.99999 9.41421L12.9497 14.364L14.364 12.9497L9.4142 7.99999L14.3639 3.05025L12.9497 1.63604L7.99999 6.58578L3.05024 1.63604Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 0H7V7L0 7V9H7V16H9V9H16V7L9 7V0Z"
                fill="#E6273E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.05534 5.34832L2.69658 1.98956L1.98947 2.69667L5.34823 6.05543C5.54349 6.25069 5.86007 6.25069 6.05534 6.05543C6.2506 5.86016 6.2506 5.54358 6.05534 5.34832ZM14.0104 2.69667L13.3033 1.98956L9.94451 5.34832C9.74925 5.54358 9.74925 5.86016 9.94451 6.05543C10.1398 6.25069 10.4564 6.25069 10.6516 6.05543L14.0104 2.69667ZM6.05536 9.94449C5.8601 9.74923 5.54351 9.74923 5.34825 9.94449L1.98949 13.3033L2.6966 14.0104L6.05536 10.6516C6.25062 10.4563 6.25062 10.1398 6.05536 9.94449ZM9.94454 9.94457C10.1398 9.74931 10.4564 9.74931 10.6516 9.94457L14.0104 13.3033L13.3033 14.0104L9.94454 10.6517C9.74928 10.4564 9.74928 10.1398 9.94454 9.94457Z"
                fill="#E6273E"
            />
        </g>
    </SvgIcon>
);

export const FlagEnglishIcon24: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        {...props}
        sx={{ fill: 'none', ...props.sx }}
    >
        <mask
            id="mask0_3678_16689"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
        >
            <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                fill="#C4C4C4"
            />
        </mask>
        <g mask="url(#mask0_3678_16689)">
            <circle cx="12" cy="12" r="12" fill="white" />
            <rect x="15.5" width="8.5" height="8.5" fill="#0A17A7" />
            <rect width="8.5" height="8.5" fill="#0A17A7" />
            <rect y="15.5" width="8.5" height="8.5" fill="#0A17A7" />
            <rect x="15.5" y="15.5" width="8.5" height="8.5" fill="#0A17A7" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.57539 2.45406L2.45407 4.57538L9.87869 12L2.45407 19.4246L4.57539 21.5459L12 14.1213L19.4246 21.5459L21.546 19.4246L14.1213 12L21.546 4.57538L19.4246 2.45406L12 9.87868L4.57539 2.45406Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 0H10V10H0V14H10V24H14V14H24V10H14V0Z"
                fill="#E6273E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.8388 3.86827L20.1317 3.16116L15.5355 7.75736C15.3403 7.95262 15.3403 8.2692 15.5355 8.46447C15.7308 8.65973 16.0474 8.65973 16.2426 8.46447L20.8388 3.86827ZM8.46447 15.5355C8.2692 15.3403 7.95262 15.3403 7.75736 15.5355L3.16117 20.1317L3.86827 20.8388L8.46447 16.2426C8.65973 16.0474 8.65973 15.7308 8.46447 15.5355ZM3.86827 3.16116L8.46447 7.75736C8.65973 7.95262 8.65973 8.2692 8.46447 8.46446C8.2692 8.65972 7.95262 8.65973 7.75736 8.46446L3.16117 3.86827L3.86827 3.16116ZM16.2426 15.5355C16.0474 15.3403 15.7308 15.3403 15.5355 15.5355C15.3403 15.7308 15.3403 16.0474 15.5355 16.2426L20.1317 20.8388L20.8388 20.1317L16.2426 15.5355Z"
                fill="#E6273E"
            />
        </g>
    </SvgIcon>
);

import { createRecordFactory } from '@lingoda/utils';
import type { Module } from '../models';

export interface ModuleExpanded extends Module {
    completedPercentage: number;
    completed: boolean;
    hasCertificate: boolean;
}

export const moduleExpandedFactory = createRecordFactory<ModuleExpanded>({
    completedPercentage: undefined,
    completed: undefined,
    id: undefined,
    name: undefined,
    longName: undefined,
    code: undefined,
    description: undefined,
    position: undefined,
    cefrLevelName: undefined,
    learningUnitIds: undefined,
    completedClasses: undefined,
    totalClasses: undefined,
    hasCertificate: undefined,
});

export default (module: Module): ModuleExpanded =>
    module &&
    moduleExpandedFactory({
        ...module,
        completedPercentage:
            module.totalClasses > 0
                ? Math.round((module.completedClasses / module.totalClasses) * 100)
                : 0,
        completed: module.completedClasses === module.totalClasses,
    });

import { useChange } from '@lingoda/hooks';
import {
    studentClassDetailsPath,
    studentCourseOverviewPath,
    studentLessonDetailsPath,
    studentPracticePath,
    useRouteMatcher,
} from '@lingoda/router';
import { SlidePopup, withSnackbarAdapter } from '@lingoda/ui';
import { Content } from './Content';

declare module 'notistack' {
    interface VariantOverrides {
        practiceSatisfaction: true;
    }
}

export const PracticeSatisfactionSurveyPopup = withSnackbarAdapter(({ close }) => {
    const paths = [
        { path: studentLessonDetailsPath(undefined as never), matchPrefix: true },
        { path: studentClassDetailsPath(undefined as never), matchPrefix: true },
        { path: studentCourseOverviewPath(undefined as never), matchPrefix: true },
        { path: studentPracticePath({ status: null as never }), matchPrefix: true },
    ];
    const isRouteMatching = useRouteMatcher(paths);

    useChange(() => {
        if (!isRouteMatching) {
            close();
        }
    }, [isRouteMatching]);

    return (
        <SlidePopup>
            <Content onClose={close} />
        </SlidePopup>
    );
});

import { useConfigurationQuery } from '@lingoda/graphql';

export const useTimezone = () => {
    const { data, loading } = useConfigurationQuery();
    const timezones = data?.config.timezones || [];
    const timezoneLabels = timezones.reduce(
        (sum, current) => ({
            ...sum,
            [current.tz]: current.label,
        }),
        {} as Record<string, string>,
    );

    return {
        loading,
        timezones,
        timezoneLabels,
    };
};

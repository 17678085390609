import { type Middleware } from 'redux';
import { type Dispatch } from 'store';
import { type GlobalState } from 'store/reducer';
import { type Action } from '@lingoda/core';
import type { NotificationHandler } from './displayNotification/_types';

export type { Notification as NotificationType } from './displayNotification/_types';

const handleUpdateModule = import('./displayNotification/_handleUpdate');

export const addNotificationHandler = async (handler: NotificationHandler) => {
    (await handleUpdateModule).addNotificationHandler(handler);
};

const handleUpdate = async (action: Action<unknown>, state: GlobalState, dispatch: Dispatch) => {
    (await handleUpdateModule).default(action, state, dispatch);
};

const middleware: Middleware =
    (store) =>
    (next) =>
    <T extends Action<unknown>>(action: T) => {
        const result = next(action);

        const state: GlobalState = store.getState();
        void handleUpdate(action, state, store.dispatch);

        return result;
    };

export default middleware;

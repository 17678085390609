import { ChevronLeft, ChevronRight } from '@lingoda/ui/icons/lingodaIcons';
import { Button, Stack, Tooltip, colorsPalette, styled, useResolution } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { type BookingFilter } from '@lingoda/booking';
import { useBookingFilterDateSelector } from '@lingoda/booking-filter';

interface DateNavigationProps {
    onFilterChange: (newFilter: Partial<BookingFilter>) => void;
    bookingFilter: BookingFilter;
}

export const DayNavigation = ({ bookingFilter, onFilterChange }: DateNavigationProps) => {
    const oneDate = bookingFilter.dates[0];

    const { selectPrevDate, selectNextDate, isPrevDateDisabled, isNextDateDisabled } =
        useBookingFilterDateSelector(oneDate, bookingFilter.timeSlots, onFilterChange);
    const isMobile = useResolution('phone');

    return (
        <Stack spacing={1} direction="row">
            <Tooltip
                title={trans('previous-date', {}, 'student-common')}
                placement="top"
                arrow
                disableHoverListener={isMobile}
            >
                <DayNavigationButton
                    color="primary"
                    variant="outlined"
                    onClick={selectPrevDate}
                    disabled={isPrevDateDisabled}
                    aria-label={trans('previous-date', {}, 'student-common')}
                >
                    <ChevronLeft sx={{ height: '12px' }} />
                </DayNavigationButton>
            </Tooltip>
            <Tooltip
                title={trans('next-date', {}, 'student-common')}
                placement="top"
                arrow
                disableHoverListener={isMobile}
            >
                <DayNavigationButton
                    color="primary"
                    variant="outlined"
                    onClick={selectNextDate}
                    disabled={isNextDateDisabled}
                    aria-label={trans('next-date', {}, 'student-common')}
                >
                    <ChevronRight sx={{ height: '12px' }} />
                </DayNavigationButton>
            </Tooltip>
        </Stack>
    );
};

const DayNavigationButton = styled(Button)(({ theme }) => ({
    width: theme.spacing(5),
    minWidth: theme.spacing(5),
    height: theme.spacing(5),
    padding: 0,
    borderColor: colorsPalette.blackAlpha[20],
    color: colorsPalette.primarySolid[100],
    '&.Mui-disabled': {
        color: colorsPalette.blackAlpha[30],
    },
}));

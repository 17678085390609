import { createSelector } from 'reselect';
import { diffInUnits, isBefore } from '@lingoda/dates';
import { localRoundMinuteSelector } from '@lingoda/time';
import { ClassStatus } from '../models';

const diffInMinutes = diffInUnits('minutes');

export default (classItem?: { startDate: Date; endDate?: Date; status?: ClassStatus }) =>
    createSelector(localRoundMinuteSelector, (now) =>
        Boolean(
            classItem?.status === ClassStatus.Scheduled &&
                classItem?.startDate &&
                diffInMinutes(classItem.startDate, now) <= 5 &&
                classItem?.endDate &&
                isBefore(now, classItem.endDate),
        ),
    );

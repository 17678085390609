import { trans } from '@lingoda/i18n';
import { CountryField, TextField } from '@lingoda/forms';
import { Grid } from '@lingoda/ui';
import { FormControl } from '@lingoda/ui/fields';

const countryCodesIncluded = ['at', 'be', 'de', 'it', 'nl', 'es'];

export const SofortFields = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <TextField
                name="name"
                label={trans('form-label-name', {}, 'public-common')}
                fullWidth
            />
        </Grid>
        <Grid item xs={12}>
            <FormControl fullWidth>
                <CountryField
                    name="country"
                    label={trans('form-label-country', {}, 'public-common')}
                    countryCodesIncluded={countryCodesIncluded}
                    fullWidth
                />
            </FormControl>
        </Grid>
    </Grid>
);

import { SvgIcon } from '../layout/SvgIcon';
import { colorsPaletteThirdParty } from '../theme';
import type { SvgIconProps } from '../layout/SvgIcon';

const FacebookIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        viewBox="0 0 16 16"
        htmlColorDefault={colorsPaletteThirdParty.facebookSolid[100]}
        {...props}
    >
        <path d="M16 8a8 8 0 1 0-9.25 7.903v-5.59H4.719V8H6.75V6.237c0-2.005 1.194-3.112 3.022-3.112.875 0 1.79.156 1.79.156V5.25h-1.008c-.994 0-1.304.617-1.304 1.25V8h2.219l-.355 2.313H9.25v5.59A8.002 8.002 0 0 0 16 8Z" />
    </SvgIcon>
);

export default FacebookIcon;

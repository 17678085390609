import { Box, colorsPalette, styled } from '@lingoda/ui';

interface Props {
    bgColor?: string;
    stickyFilterHeight?: number;
}

export const ClassesListHeader = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'stickyFilterHeight',
})<Props>(({ theme, bgColor, stickyFilterHeight }) => ({
    position: 'sticky',
    top: stickyFilterHeight ? stickyFilterHeight - 1 : -1,
    background: bgColor || theme.palette.background.default,
    zIndex: 1,
    padding: theme.spacing(3, 0, 1.5),
    color: colorsPalette.blackAlpha[70],
}));

import { useDispatch } from '@lingoda/hooks';
import { useStudentNotificationsLazyQuery } from '@lingoda/graphql';
import { showAllNotificationsContainer } from '../actions';
import { useEventSourceNotificationListener } from './useEventSourceNotificationListener';

export const useEventSourceNotificationsListener = () => {
    const dispatch = useDispatch();
    const [, { refetch }] = useStudentNotificationsLazyQuery();

    useEventSourceNotificationListener(
        [
            'class_booking_error',
            'challenge-started',
            'two-weeks-left-until-challenge-end',
            'two-weeks-left-until-challenge-end-for-unjoined',
            'prize-achieved',
            'expired_calendar_auth_error',
        ],
        async () => {
            await refetch();
            dispatch(showAllNotificationsContainer());
        },
    );
};

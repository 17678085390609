import { ClassType } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { Box, Divider, LingodaClassIcon, Skeleton, Typography } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';
import { MultiplyIcon } from '@lingoda/ui/icons/MultiplyIcon';

interface Props {
    isLoading?: boolean;
    orientation?: 'column' | 'row';
    groupCredits?: number;
    privateCredits?: number;
}

const StyledCreditTab = styled(Box)(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
    columnGap: theme.spacing(1),
    flex: '0.5',
}));

const ClassTypeText = styled(Typography)(({ theme }) => ({
    ...theme.typography.smallest,
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'uppercase',
    color: colorsPalette.blackAlpha[70],
    lineHeight: 1.8,
}));

interface CreditTabProps {
    type: ClassType;
    credits?: number;
    isLoading?: boolean;
}

const CreditTab = ({ type, credits, isLoading }: CreditTabProps) => (
    <StyledCreditTab>
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" columnGap={0.5}>
                {isLoading ? (
                    <Skeleton variant="circular" width={22} height={22} />
                ) : (
                    <>
                        <LingodaClassIcon size={16} type={type} />
                        <MultiplyIcon
                            sx={{
                                width: (theme) => theme.spacing(1),
                                height: (theme) => theme.spacing(1),
                            }}
                        />
                    </>
                )}
                <Typography variant="subtitle1">
                    {isLoading ? <Skeleton variant="text" width="2ch" /> : credits}
                </Typography>
            </Box>
            <ClassTypeText>
                {isLoading ? (
                    <Skeleton variant="text" width="10ch" />
                ) : type === ClassType.Group ? (
                    trans('form-label-group-classes', {}, 'public-common')
                ) : (
                    trans('form-label-private-classes', {}, 'public-common')
                )}
            </ClassTypeText>
        </Box>
    </StyledCreditTab>
);

export const CreditAmounts = ({ groupCredits, privateCredits, orientation, isLoading }: Props) => (
    <Box
        display="flex"
        borderRadius={1}
        border={`1px solid ${colorsPalette.blackSolid[20]}`}
        flexDirection={orientation}
    >
        <CreditTab credits={groupCredits} type={ClassType.Group} isLoading={isLoading} />
        <Divider flexItem orientation={orientation === 'row' ? 'vertical' : 'horizontal'} />
        <CreditTab credits={privateCredits} type={ClassType.Individual} isLoading={isLoading} />
    </Box>
);

import { useResetFlashcardStatusMutation } from '@lingoda/graphql';
import { useToggleState } from '@lingoda/hooks';
import { useHistory } from '@lingoda/router';
import { Congratulations } from './Congratulations';
import { NiceWork } from './NiceWork';

export interface FinishedProps {
    correctWordsInSession: number;
    correctWordsInTotal: number;
    learningUnitId: number;
    totalWords: number;
    onContinue?: () => Promise<void>;
    onReset?: () => Promise<void>;
}

export const Finished = ({
    correctWordsInSession,
    correctWordsInTotal,
    learningUnitId,
    totalWords,
    onContinue,
    onReset,
}: FinishedProps) => {
    const [isLoading, showLoader, hideLoader] = useToggleState(false);
    const [resetFlashcards] = useResetFlashcardStatusMutation();

    const history = useHistory();

    const handleResetClick = async () => {
        showLoader();
        await resetFlashcards({
            variables: {
                learningUnitId,
            },
        });

        await onReset?.();
        hideLoader();
    };

    const handleContinueClick = async () => {
        showLoader();
        await onContinue?.();
        hideLoader();
    };

    const handleDoneClick = () => {
        history.goBack();
    };

    return correctWordsInTotal === totalWords ? (
        <Congratulations
            totalWords={totalWords}
            onDone={handleDoneClick}
            onReset={handleResetClick}
        />
    ) : (
        <NiceWork
            correctWordsInSession={correctWordsInSession}
            correctWordsInTotal={correctWordsInTotal}
            totalWords={totalWords}
            onContinue={handleContinueClick}
            onReset={handleResetClick}
            loading={isLoading}
        />
    );
};

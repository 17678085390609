import { createRecordFactory } from '@lingoda/utils';
import type { Price } from './price';
import type { ProductId } from './product';

export type ProductVariantId = number;

export interface ProductVariant {
    readonly id: ProductVariantId;
    readonly productId: ProductId;
    readonly price: Price;
    readonly sectionId: number;
    readonly productName: string;
    readonly description: string | null;
    readonly pricePerClass: Price;
}

export default createRecordFactory<ProductVariant>({
    id: undefined,
    productId: undefined,
    price: undefined,
    sectionId: undefined,
    productName: undefined,
    description: undefined,
    pricePerClass: undefined,
});

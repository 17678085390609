import { SvgIcon, type SvgIconProps } from '../layout/SvgIcon';
import { colorsPaletteThirdParty } from '../theme';

export const WhatsAppIconOutlined: React.FC<SvgIconProps> = (props) => (
    <SvgIcon
        width="16"
        height="16"
        viewBox="0 0 16 16"
        htmlColorDefault={colorsPaletteThirdParty.whatsappSolid[100]}
        {...props}
    >
        <path d="M13.6623 2.34209C12.1534 0.832495 10.1469 0.000494824 8.0092 -0.000305176C3.60418 -0.000305176 0.019201 3.58289 0.0176009 7.98769C0.0168009 9.39569 0.384819 10.7701 1.08405 11.9813L0 15.9997L4.18661 15.0101C5.35387 15.6469 6.66753 15.9821 8.0052 15.9829H8.0084C12.4126 15.9829 15.9976 12.3989 16 7.9949C16.0016 5.8597 15.1712 3.85249 13.6623 2.34209ZM11.919 10.8429C11.7526 11.3093 10.9373 11.7589 10.5709 11.7917C10.2045 11.8253 9.86129 11.9573 8.17481 11.2925C6.14511 10.4925 4.86344 8.41169 4.76424 8.2789C4.66423 8.1453 3.949 7.19649 3.949 6.21409C3.949 5.23169 4.46502 4.74849 4.64823 4.54929C4.83144 4.34929 5.04745 4.2997 5.18106 4.2997C5.31386 4.2997 5.44747 4.2997 5.56348 4.30449C5.70588 4.31009 5.86349 4.31729 6.0131 4.64929C6.19071 5.04449 6.57873 6.03169 6.62833 6.13169C6.67793 6.23169 6.71153 6.3485 6.64513 6.4813C6.57873 6.6141 6.54513 6.69729 6.44592 6.81409C6.34592 6.93089 6.23631 7.07409 6.14671 7.16369C6.0467 7.2629 5.9427 7.37169 6.0587 7.57089C6.17551 7.77089 6.57553 8.4245 7.16916 8.9533C7.9324 9.6333 8.57483 9.84369 8.77484 9.9445C8.97485 10.0445 9.09085 10.0277 9.20766 9.8941C9.32446 9.7613 9.70688 9.3117 9.83969 9.1117C9.9725 8.9117 10.1061 8.9453 10.2893 9.01169C10.4725 9.0781 11.4542 9.56129 11.6534 9.66129C11.8534 9.76129 11.9862 9.81089 12.0358 9.8941C12.0854 9.97649 12.0854 10.3765 11.919 10.8429Z" />
    </SvgIcon>
);

export const WhatsAppIcon: React.FC<SvgIconProps> = (props) => (
    <SvgIcon width="48" height="48" viewBox="0 0 48 48" {...props}>
        <rect width="48" height="48" rx="4" fill={colorsPaletteThirdParty.whatsappSolid[100]} />
        <g clipPath="url(#clip0_1409_17995)">
            <g clipPath="url(#clip1_1409_17995)">
                <path
                    d="M29.6623 18.3421C28.1534 16.8325 26.1469 16.0005 24.0092 15.9997C19.6042 15.9997 16.0192 19.5829 16.0176 23.9877C16.0168 25.3957 16.3848 26.7701 17.0841 27.9813L16 31.9997L20.1866 31.0101C21.3539 31.6469 22.6675 31.9821 24.0052 31.9829H24.0084C28.4126 31.9829 31.9976 28.3989 32 23.9949C32.0016 21.8597 31.1712 19.8525 29.6623 18.3421ZM27.919 26.8429C27.7526 27.3093 26.9373 27.7589 26.5709 27.7917C26.2045 27.8253 25.8613 27.9573 24.1748 27.2925C22.1451 26.4925 20.8634 24.4117 20.7642 24.2789C20.6642 24.1453 19.949 23.1965 19.949 22.2141C19.949 21.2317 20.465 20.7485 20.6482 20.5493C20.8314 20.3493 21.0475 20.2997 21.1811 20.2997C21.3139 20.2997 21.4475 20.2997 21.5635 20.3045C21.7059 20.3101 21.8635 20.3173 22.0131 20.6493C22.1907 21.0445 22.5787 22.0317 22.6283 22.1317C22.6779 22.2317 22.7115 22.3485 22.6451 22.4813C22.5787 22.6141 22.5451 22.6973 22.4459 22.8141C22.3459 22.9309 22.2363 23.0741 22.1467 23.1637C22.0467 23.2629 21.9427 23.3717 22.0587 23.5709C22.1755 23.7709 22.5755 24.4245 23.1692 24.9533C23.9324 25.6333 24.5748 25.8437 24.7748 25.9445C24.9748 26.0445 25.0909 26.0277 25.2077 25.8941C25.3245 25.7613 25.7069 25.3117 25.8397 25.1117C25.9725 24.9117 26.1061 24.9453 26.2893 25.0117C26.4725 25.0781 27.4542 25.5613 27.6534 25.6613C27.8534 25.7613 27.9862 25.8109 28.0358 25.8941C28.0854 25.9765 28.0854 26.3765 27.919 26.8429Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1409_17995">
                <rect width="16" height="16" fill="white" transform="translate(16 16)" />
            </clipPath>
            <clipPath id="clip1_1409_17995">
                <rect width="16" height="16" fill="white" transform="translate(16 16)" />
            </clipPath>
        </defs>
    </SvgIcon>
);

import { sectionNameSelector } from '@lingoda/auth';
import { useOrientationModulesQuery } from '@lingoda/graphql';
import { useSelector } from '@lingoda/hooks';
import { studentModuleSelector } from '@lingoda/modules';

export const useOrientationClassAvailable = () => {
    const sectionName = useSelector(sectionNameSelector) || ('' as unknown as SectionName);
    const module = useSelector(studentModuleSelector);

    const { data, loading: orientationModulesLoading } = useOrientationModulesQuery({
        variables: { sectionName },
    });

    const orientationModules = data?.orientationModules || [];

    const loading = orientationModulesLoading;

    const isOrientationClassAvailable = !loading
        ? !!module?.code && orientationModules.includes(module.code)
        : undefined;

    return { loading, isOrientationClassAvailable };
};

import { createSelector } from 'reselect';
import { sectionNameSelector } from '@lingoda/auth';
import { ClassType } from '@lingoda/graphql';
import { SectionDateLeadTimesFactory } from '../models';
import leadTimesSelector from './leadTimes';

export default createSelector(leadTimesSelector, sectionNameSelector, (leadTimes, section) =>
    Object.values(ClassType).reduce((record, type) => {
        const classTypeDuration = leadTimes?.[type];

        return classTypeDuration && section
            ? {
                  ...record,
                  [type]: classTypeDuration[section],
              }
            : record;
    }, SectionDateLeadTimesFactory({})),
);

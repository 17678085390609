import { getStorageValue, removeStorageValue, setStorageValue } from '@lingoda/utils';
import type { Notification } from './models';

type NotificationKeyData = Pick<Notification<unknown>, 'type' | 'payload'>;

// Generates uniq key per different notification but same per same notification content (type, payload).
const getNotificationKey = (notification: NotificationKeyData) => {
    return JSON.stringify({
        type: notification.type,
        payload: notification.payload,
    });
};

const SEEN_NOTIFICATIONS_KEY = 'lingoda_seen_notifications';

export const checkNotificationSeen = (notification: NotificationKeyData) => {
    const seenNotifications =
        getStorageValue<Record<string, boolean>>(SEEN_NOTIFICATIONS_KEY) || {};

    return seenNotifications[getNotificationKey(notification)];
};

export const saveSeenNotifications = (notifications: NotificationKeyData[]) => {
    const nextSeenNotifications = notifications.reduce(
        (acc, notification) => {
            acc[getNotificationKey(notification)] = true;

            return acc;
        },
        {} as Record<string, boolean>,
    );

    setStorageValue(SEEN_NOTIFICATIONS_KEY, nextSeenNotifications);
};

export const clearSeenNotifications = () => {
    removeStorageValue(SEEN_NOTIFICATIONS_KEY);
};

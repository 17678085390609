import { Button, styled } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import type { ChallengeStatus } from '../utils/getChallengeStatus';

interface JoinButtonProps {
    joined: boolean;
    status: ChallengeStatus;
    joiningLoading: boolean;
    onJoinClick: () => void;
    inverted?: boolean;
}

export const JoinButton = ({
    joined,
    status,
    joiningLoading,
    onJoinClick,
    inverted,
}: JoinButtonProps) => {
    return (
        <JoinButtonComponent
            variant="contained"
            disabled={joined || status === 'ended'}
            loading={joiningLoading}
            onClick={onJoinClick}
            fullWidth
            inverted={inverted}
            sx={{ visibility: !joined && status === 'ended' ? 'hidden' : 'visible' }}
        >
            {joined
                ? trans('btn-challenge-joined', {}, 'student-challenges')
                : trans('btn-join-challenge', {}, 'student-challenges')}
        </JoinButtonComponent>
    );
};

const JoinButtonComponent = styled(Button)(({ theme, disabled }) => ({
    [theme.breakpoints.up('sm')]: {
        maxWidth: 240,
    },
    ...(disabled && {
        opacity: 0.8,
    }),
}));

import { useCallback, useEffect, useRef, useState } from 'react';
import { capitalize as capitalizeFunc, setInterval } from '@lingoda/utils';
import {
    createDate,
    durationToUnix,
    formatDistanceStrict,
    formatDurationToCounter,
    formatDurationToHuman,
    isBefore,
    relativeDiffInUnits,
} from '../../date-fns';
import type { PropsWithChildren } from 'react';

interface Props {
    countdown: Date;
    format: FirstItem<Parameters<typeof relativeDiffInUnits>>;
    humanize?: boolean;
    onFinish?: () => void;
    children: (remaining: string) => React.ReactNode;
    maxLimit?: number;
    useDistance?: boolean;
    capitalize?: boolean;
}

export const Timer = ({
    capitalize,
    countdown,
    format,
    humanize,
    maxLimit,
    onFinish,
    useDistance,
    children,
}: PropsWithChildren<Props>) => {
    const [content, setContent] = useState('');

    const intervalID = useRef(-1);
    const tick = useRef(() => {
        if (!countdown) {
            return;
        }

        const now = createDate();

        if (!isBefore(now, countdown)) {
            clearInterval(intervalID.current);
            if (onFinish) {
                onFinish();
            }
            const content = formatDistanceStrict(now, countdown, {
                unit: 'minute',
            });

            setContent(content);

            return;
        }

        const duration = relativeDiffInUnits(format, countdown, now);

        if (maxLimit && durationToUnix(duration) >= maxLimit) {
            return;
        }

        if (duration?.days && duration?.days >= 1) {
            setContent(
                useDistance
                    ? formatDistanceStrict(countdown, now, {
                          addSuffix: true,
                          roundingMethod: 'ceil',
                      })
                    : formatDurationToHuman(duration, ['days', 'hours']),
            );

            return;
        }

        if (humanize || useDistance) {
            setContent(
                useDistance
                    ? formatDistanceStrict(countdown, now, {
                          addSuffix: true,
                          roundingMethod: 'ceil',
                      })
                    : formatDurationToHuman(duration, format),
            );

            return;
        }

        setContent(formatDurationToCounter(duration));
    });

    const init = useCallback(() => {
        if (!countdown) {
            return;
        }

        clearInterval(intervalID.current);
        intervalID.current = setInterval(tick.current, 1000);
        tick.current();

        return () => clearInterval(intervalID.current);
    }, [countdown]);

    useEffect(() => {
        init();
    }, [init]);

    const result = capitalize ? capitalizeFunc(content) : content;

    return <>{children(result)}</>;
};

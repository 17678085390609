import { Stack } from '@lingoda/ui';
import type { BasicActivityChallengeListDataQuery } from '@lingoda/graphql';
import { ActiveChallengeWidget } from './ActiveChallengeWidget';

interface Props {
    activityChallenges: BasicActivityChallengeListDataQuery['activityChallenges'] | undefined;
}

export const ActiveChallengeWidgets = ({ activityChallenges }: Props) => {
    if (!activityChallenges?.length) {
        return null;
    }

    return (
        <Stack spacing={2}>
            {activityChallenges.map((challenge) => (
                <ActiveChallengeWidget
                    key={challenge.name}
                    challengeName={challenge.name}
                    title={challenge.publicName}
                    description={challenge.description}
                    userStatus={challenge.currentParticipant?.status}
                    startDate={challenge.startDate}
                    endDate={challenge.endDate}
                    prizes={challenge.prizes}
                    completedActivities={challenge.currentParticipant?.completedActivities ?? 0}
                    challengeIconUrl={challenge.challengeIcon?.urls.original}
                />
            ))}
        </Stack>
    );
};

import { createDate, format } from '@lingoda/dates';
import { trans, transChoice } from '@lingoda/i18n';
import { getDaysLeft } from './getDaysLeft';
import { type ChallengeStatus } from './getChallengeStatus';

export const getChallengeStatusText = (
    status: ChallengeStatus,
    startDate: string,
    endDate: string,
) => {
    if (status === 'upcoming') {
        const startsOn = startsOnFormat(createDate(startDate));

        return `${trans('starts-on', {}, 'student-challenges')} ${startsOn}`;
    }

    if (status === 'ended') {
        return trans('challenge-completed', {}, 'student-challenges');
    }

    const days = getDaysLeft(createDate(endDate));

    return transChoice('days-left', days, { days }, 'student-challenges');
};

const startsOnFormat = format('d MMMM');
